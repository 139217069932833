import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Row } from "react-bootstrap";

function AddvendorP() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const [data, setdata] = useState([]);
  const [vendordetails, setvendordetails] = useState("");
  const [percentage, setpercentage] = useState();
  const [show, setShow] = useState(false);

  const [allVendor, setallVendor] = useState([]);

  console.log("vendordetails", vendordetails, "percentage", percentage);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const [vendordetails1, setvendordetails1] = useState("");
  const [percentage1, setpercentage1] = useState();
  const [editdata, seteditdata] = useState({});

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    seteditdata(item);
  };

  useEffect(() => {
    getallVendor();
  }, []);

  const getallVendor = () => {
    axios
      .get("https://spazo.co.in/api/vendor/allVendors")
      .then(function (response) {
        console.log(response.data);
        setallVendor(
          response.data.allVendors.filter(
            (item) => item.approved === "Approved" && item.type === "Spa"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addVendorP = async () => {
    if (!percentage || !vendordetails) {
      // alert("Please enter name");
      notify2("Please fill all the fields");
    } else {
      try {
        const config = {
          url: "/addVendorpercentage",
          method: "post",
          baseURL: "https://spazo.co.in/api/vendor",
          data: { vendorid: vendordetails, Vendorpercentage: percentage },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            notify("Data Added successfully");
            handleClose();
            getallVendor();
          } else {
            notify1(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        notify1("Unable to add Details, Please try after some time");
      }
    }
  };

  const editVendorP = async () => {
    try {
      const config = {
        url: "/addVendorpercentage",
        method: "post",
        baseURL: "https://spazo.co.in/api/vendor",
        data: {
          vendorid: editdata._id,
          Vendorpercentage: percentage1
            ? percentage1
            : editdata?.Vendorpercentage,
        },
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          notify("Data Updated successfully");
          handleClose1();
          getallVendor();
        } else {
          notify1(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      notify1("Unable to add Details, Please try after some time");
    }
  };

  const arr = [];
  for (let i = 0; i < allVendor.length; i++) {
    if (allVendor[i].Vendorpercentage) {
      arr.push(allVendor[i]);
    }
  }

  console.log("arr", arr);
  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "3",
        value: 3,
      },
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: arr.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },

    {
      dataField: "businessName",
      text: "Business Name",
      formatter: (value, row) => (
        <p>{row?.businessName ? row?.businessName : "-"}</p>
      ),
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (value, row) => <p>{row?.name ? row?.name : "-"}</p>,
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (value, row) => <p>{row?.email ? row?.email : "-"}</p>,
    },
    {
      dataField: "phoneNumber",
      text: "Phone Number",
      formatter: (value, row) => (
        <p>{row?.phoneNumber ? row?.phoneNumber : "-"}</p>
      ),
    },
    {
      dataField: "Vendorpercentage",
      text: "Vendor %",
      formatter: (value, row) => (
        <p>{row?.Vendorpercentage ? row?.Vendorpercentage : "-"}</p>
      ),
    },
    {
      dataField: "",
      text: "Action",
      formatter: (value, row) => (
        <span className="dite">
          <FaRegEdit onClick={() => handleShow1(row)} />
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="never-sspa">
        <section>
          <div className="spa-admin-0 mb-2">
            <div className="diff-admin px-3">
              <div className="admin-name-text">
                <h4>Add % for Vendor(Spa)</h4>
              </div>
            </div>
          </div>

          <ToastContainer
            position="top-center"
            autoClose={30000}
            closeOnClick
            pauseOnHover
            draggable
          />
          <div
            style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}
          >
            <ToolkitProvider
              keyField="id"
              data={arr}
              columns={columns}
              // rowClasses={rowClasses}
              search
              exportCSV
            >
              {(props) => (
                <div>
                  <SearchBar {...props.searchProps} />
                  <ExportCSVButton
                    {...props.csvProps}
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                    }}
                  >
                    Download Excel
                  </ExportCSVButton>
                  <button
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                      border: "none",
                      padding: "6px",
                      borderRadius: "5px",
                      marginLeft: "467px",
                    }}
                    onClick={handleShow}
                  >
                    +Add
                  </button>
                  <hr></hr>
                  <br></br>
                  <div style={{ overflowX: "scroll", width: "100%" }}>
                    <BootstrapTable
                      striped
                      hover
                      condensed
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </section>
      </div>

      {/* modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add % to Spa Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-spa">
            <Row>
              <Col md={6}>
                <label>Vendor Name *</label>
              </Col>
              <Col md={6}>
                <select
                  style={{ width: "83%" }}
                  onChange={(e) => setvendordetails(e.target.value)}
                >
                  <option>Select</option>
                  {allVendor?.map((item) => (
                    <option value={item._id}>{item?.businessName}</option>
                  ))}
                </select>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={6}>
                <label>Add % *</label>
              </Col>
              <Col md={6}>
                <input
                  placeholder=""
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => setpercentage(e.target.value)}
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addVendorP}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Vendor %</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-spa">
            <Row>
              <Col md={6}>
                <label>Vendor Name </label>
              </Col>
              <Col md={6}>
                <select
                  style={{ width: "83%" }}
                  onChange={(e) => setvendordetails1(e.target.value)}
                >
                  <option>{editdata?.businessName}</option>
                </select>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={6}>
                <label>Add % *</label>
              </Col>
              <Col md={6}>
                <input
                  placeholder={editdata?.Vendorpercentage}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => setpercentage1(e.target.value)}
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={editVendorP}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddvendorP;
