import React from "react";
import "../Styles/topvendor.css";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";

function Adds() {
  const [data, setdata] = useState([]);

  useEffect(() => {
    getallBanners();
  }, []);

  const getallBanners = () => {
    axios
      .get("https://spazo.co.in/api/admin/getBanner2")
      .then(function (response) {
        console.log(response.data);
        setdata(response.data.bannerList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <section>
        <div
          className="add-itm"
          style={{
            position: "relative",
          }}
        >
          <div
            className="add-item-0"
            style={{
              backgroundImage: `url("https://spazo.co.in/Banner2/${
                data[data.length - 1]?.bannerimg
              }")`,
              height: "400px",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundAttachment: "fixed",
            }}
          >
            <div className="container">
              <div
                className="stress"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: " translate(-50%, -50%)",
                }}
              >
                <h4>{data[data.length - 1]?.title}</h4>
                <p>{data[data.length - 1]?.subtitle}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Adds;
