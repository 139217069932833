import React, { useEffect, useState } from "react";
import Newheader2 from "./Newheader2";
import { Col, Modal, Row } from "react-bootstrap";
import NewFooter from "./NewFooter";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import axios from "axios";
import { BiSolidCarGarage } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import parse from "html-react-parser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function Cityproduct() {
  const location = useLocation();
  const { type, address } = location.state;

  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const currentDate = new Date();

  const [selectedType, setselectedType] = useState();
  const [category, setcategory] = useState([]);
  const [Service, setService] = useState([]);
  const [Topbrands, setTopbrands] = useState([]);
  const [Starhotels, setStarhotels] = useState([]);
  const [Mallsandshopping, setMallsandshopping] = useState([]);

  const [SelectTopBrands, setSelectTopBrands] = useState("");
  const [SelectTopRated, setSelectTopRated] = useState(false);

  const [seletedAmount, setseletedAmount] = useState();
  const [Servicefilter, setServicefilter] = useState("");

  const [categoryfilter, setcategoryfilter] = useState("");
  const [value, setValue] = React.useState([0, 10000]);

  const [Vendor, setVendor] = useState([]);

  const [catdata, setcatdata] = useState([]);

  console.log("type", type, selectedType);
  // useEffect(() => {
  //   if (categoryfilter) {
  //     const xyz = Vendor?.filter((person) =>
  //       person.service.some(
  //         (hobby) => hobby.Service_Category === categoryfilter
  //       )
  //     );
  //     setcatdata(xyz);
  //   }
  // }, [categoryfilter]);

  // console.log("SelectTopBrands", SelectTopBrands);
  // console.log("Topbrands", Topbrands);
  // console.log("Mallsandshopping", Mallsandshopping);
  // console.log("Starhotels", Starhotels);
  console.log("catdata", catdata);

  // Step 1: Calculate average rating and return updated items
  const updatedCatdata = Vendor?.map((item) => {
    if (item?.reviews && item?.reviews?.length > 0) {
      const totalRatings = item?.reviews?.reduce(
        (sum, review) => sum + review?.rating,
        0
      );
      item.averageRating = totalRatings / item?.reviews?.length; // Add averageRating to item
    } else {
      item.averageRating = 0; // Default if no reviews
    }
    return item;
  });

  // Step 2: Remove duplicates by converting the `reviews` array to a JSON string for comparison
  const uniqueCatdata = updatedCatdata?.filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          JSON.stringify(t.businessName) ===
            JSON.stringify(item.businessName) &&
          JSON.stringify(t.address) === JSON.stringify(item.address)
      )
  );

  // Step 3: Sort by the average ratings in descending order
  const sortRatingWise = uniqueCatdata?.sort(
    (a, b) => b.averageRating - a.averageRating
  );

  // Step 1: Calculate average rating and return updated items
  const updatedCatdata1 = catdata?.map((item) => {
    if (item?.reviews && item?.reviews?.length > 0) {
      const totalRatings = item?.reviews?.reduce(
        (sum, review) => sum + review?.rating,
        0
      );
      item.averageRating = totalRatings / item?.reviews?.length; // Add averageRating to item
    } else {
      item.averageRating = 0; // Default if no reviews
    }
    return item;
  });

  // Step 2: Remove duplicates by converting the `reviews` array to a JSON string for comparison
  const uniqueCatdata1 = updatedCatdata1?.filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          JSON.stringify(t.businessName) ===
            JSON.stringify(item.businessName) &&
          JSON.stringify(t.address) === JSON.stringify(item.address)
      )
  );

  // Step 3: Sort by the average ratings in descending order
  const sortRatingWise1 = uniqueCatdata1?.sort(
    (a, b) => b.averageRating - a.averageRating
  );

  console.log("sortRatingWise", sortRatingWise);
  console.log("updatedCatdata", updatedCatdata);

  console.log("sortRatingWise1", sortRatingWise1);
  console.log("updatedCatdata1", updatedCatdata1);

  useEffect(() => {
    if (SelectTopBrands === "Top Brands") {
      if (seletedAmount && Servicefilter && categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];
        const catfilter = Topbrands?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );
        console.log("catfilter", catfilter);

        const serfilter = catfilter?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        var filterarray = [];
        if (serfilter?.length > 0) {
          for (let i = 0; i < serfilter?.length; i++) {
            for (let j = 0; j < serfilter[i]?.service?.length; j++) {
              const gst =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                serfilter[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(serfilter[i]);
              }
            }
          }
        }
        const uniqueCat = filterarray?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );
        setcatdata(uniqueCat);
      } else if (Servicefilter && categoryfilter && !seletedAmount) {
        const xyz = Topbrands?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );

        const xyz1 = xyz?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        const uniqueCat = xyz1?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );
        setcatdata(uniqueCat);
      } else if (categoryfilter && !Servicefilter && !seletedAmount) {
        const xyz = Topbrands?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );
        const uniqueCat = xyz?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );

        setcatdata(uniqueCat);
      } else if (Servicefilter && !categoryfilter && !seletedAmount) {
        const xyz = Topbrands?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        // console.log('xyz', Servicefilter, xyz);

        const uniqueCat = xyz?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );
        setcatdata(uniqueCat);
      } else if (seletedAmount && !Servicefilter && !categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];
        var filterarray = [];

        if (Topbrands?.length > 0) {
          for (let i = 0; i < Topbrands?.length; i++) {
            for (let j = 0; j < Topbrands[i]?.service?.length; j++) {
              const gst =
                (Topbrands[i]?.service[j]?.Price *
                  Topbrands[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (Topbrands[i]?.service[j]?.Price *
                  Topbrands[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (Topbrands[i]?.service[j]?.Price *
                  Topbrands[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                Topbrands[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(Topbrands[i]);
              }
            }
          }
        }

        // console.log("filterarray", filterarray);
        const uniqueCat = filterarray?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );
        setcatdata(uniqueCat);
      } else if (seletedAmount && !Servicefilter && categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];

        const catfilter = Topbrands?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );
        var filterarray = [];
        if (catfilter?.length > 0) {
          for (let i = 0; i < catfilter?.length; i++) {
            for (let j = 0; j < catfilter[i]?.service?.length; j++) {
              const gst =
                (catfilter[i]?.service[j]?.Price *
                  catfilter[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (catfilter[i]?.service[j]?.Price *
                  catfilter[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (catfilter[i]?.service[j]?.Price *
                  catfilter[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                catfilter[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(catfilter[i]);
              }
            }
          }
        }
        const uniqueCat = filterarray?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );
        setcatdata(uniqueCat);
      } else if (seletedAmount && Servicefilter && !categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];

        const serfilter = Topbrands?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        var filterarray = [];
        if (serfilter?.length > 0) {
          for (let i = 0; i < serfilter?.length; i++) {
            for (let j = 0; j < serfilter[i]?.service?.length; j++) {
              const gst =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                serfilter[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(serfilter[i]);
              }
            }
          }
        }
        const uniqueCat = filterarray?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );
        setcatdata(uniqueCat);
      } else if (!Servicefilter && !categoryfilter && !seletedAmount) {
        const uniqueCat = Topbrands?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );
        setcatdata(uniqueCat);
      }
    } else if (SelectTopBrands === "In Malls And Shopping Hubs") {
      if (seletedAmount && Servicefilter && categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];
        const catfilter = Mallsandshopping?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );

        const serfilter = catfilter?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        var filterarray = [];
        if (serfilter?.length > 0) {
          for (let i = 0; i < serfilter?.length; i++) {
            for (let j = 0; j < serfilter[i]?.service?.length; j++) {
              const gst =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                serfilter[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(serfilter[i]);
              }
            }
          }
        }
        const uniqueCat = filterarray?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );
        setcatdata(uniqueCat);
      } else if (Servicefilter && categoryfilter && !seletedAmount) {
        const xyz = Mallsandshopping?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );

        const xyz1 = xyz?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        const uniqueCat = xyz1?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );
        setcatdata(uniqueCat);
      } else if (categoryfilter && !Servicefilter && !seletedAmount) {
        const xyz = Mallsandshopping?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );
        const uniqueCat = xyz?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );
        setcatdata(uniqueCat);
      } else if (Servicefilter && !categoryfilter && !seletedAmount) {
        const xyz = Mallsandshopping?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        // console.log('xyz', Servicefilter, xyz);
        const uniqueCat = xyz?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );

        setcatdata(uniqueCat);
      } else if (seletedAmount && !Servicefilter && !categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];
        var filterarray = [];

        if (Mallsandshopping?.length > 0) {
          for (let i = 0; i < Mallsandshopping?.length; i++) {
            for (let j = 0; j < Mallsandshopping[i]?.service?.length; j++) {
              const gst =
                (Mallsandshopping[i]?.service[j]?.Price *
                  Mallsandshopping[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (Mallsandshopping[i]?.service[j]?.Price *
                  Mallsandshopping[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (Mallsandshopping[i]?.service[j]?.Price *
                  Mallsandshopping[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                Mallsandshopping[i]?.service[j]?.Price +
                gst +
                servicetax -
                discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(Mallsandshopping[i]);
              }
            }
          }
        }

        // console.log("filterarray", filterarray);
        const uniqueCat = filterarray?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );
        setcatdata(uniqueCat);
      } else if (seletedAmount && !Servicefilter && categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];

        const catfilter = Mallsandshopping?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );
        var filterarray = [];
        if (catfilter?.length > 0) {
          for (let i = 0; i < catfilter?.length; i++) {
            for (let j = 0; j < catfilter[i]?.service?.length; j++) {
              const gst =
                (catfilter[i]?.service[j]?.Price *
                  catfilter[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (catfilter[i]?.service[j]?.Price *
                  catfilter[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (catfilter[i]?.service[j]?.Price *
                  catfilter[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                catfilter[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(catfilter[i]);
              }
            }
          }
        }
        const uniqueCat = filterarray?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );
        setcatdata(uniqueCat);
      } else if (seletedAmount && Servicefilter && !categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];

        const serfilter = Mallsandshopping?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        var filterarray = [];
        if (serfilter?.length > 0) {
          for (let i = 0; i < serfilter?.length; i++) {
            for (let j = 0; j < serfilter[i]?.service?.length; j++) {
              const gst =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                serfilter[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(serfilter[i]);
              }
            }
          }
        }
        const uniqueCat = filterarray?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );
        setcatdata(uniqueCat);
      } else if (!Servicefilter && !categoryfilter && !seletedAmount) {
        const uniqueCat = Mallsandshopping?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );

        setcatdata(uniqueCat);
      }
    } else if (SelectTopBrands === "At 5 Star Hotels") {
      if (seletedAmount && Servicefilter && categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];
        const catfilter = Starhotels?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );

        const serfilter = catfilter?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        var filterarray = [];
        if (serfilter?.length > 0) {
          for (let i = 0; i < serfilter?.length; i++) {
            for (let j = 0; j < serfilter[i]?.service?.length; j++) {
              const gst =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                serfilter[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(serfilter[i]);
              }
            }
          }
        }
        const uniqueCat = filterarray?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );
        setcatdata(uniqueCat);
      } else if (Servicefilter && categoryfilter && !seletedAmount) {
        const xyz = Starhotels?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );

        const xyz1 = xyz?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        const uniqueCat = xyz1?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );
        setcatdata(uniqueCat);
      } else if (categoryfilter && !Servicefilter && !seletedAmount) {
        const xyz = Starhotels?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );
        const uniqueCat = xyz?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );

        setcatdata(uniqueCat);
      } else if (Servicefilter && !categoryfilter && !seletedAmount) {
        const xyz = Starhotels?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        // console.log('xyz', Servicefilter, xyz);
        const uniqueCat = xyz?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );

        setcatdata(uniqueCat);
      } else if (seletedAmount && !Servicefilter && !categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];
        var filterarray = [];

        if (Starhotels?.length > 0) {
          for (let i = 0; i < Starhotels?.length; i++) {
            for (let j = 0; j < Starhotels[i]?.service?.length; j++) {
              const gst =
                (Starhotels[i]?.service[j]?.Price *
                  Starhotels[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (Starhotels[i]?.service[j]?.Price *
                  Starhotels[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (Starhotels[i]?.service[j]?.Price *
                  Starhotels[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                Starhotels[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(Starhotels[i]);
              }
            }
          }
        }

        // console.log("filterarray", filterarray);
        const uniqueCat = filterarray?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );
        setcatdata(uniqueCat);
      } else if (seletedAmount && !Servicefilter && categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];

        const catfilter = Starhotels?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );
        var filterarray = [];
        if (catfilter?.length > 0) {
          for (let i = 0; i < catfilter?.length; i++) {
            for (let j = 0; j < catfilter[i]?.service?.length; j++) {
              const gst =
                (catfilter[i]?.service[j]?.Price *
                  catfilter[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (catfilter[i]?.service[j]?.Price *
                  catfilter[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (catfilter[i]?.service[j]?.Price *
                  catfilter[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                catfilter[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(catfilter[i]);
              }
            }
          }
        }
        const uniqueCat = filterarray?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );
        setcatdata(uniqueCat);
      } else if (seletedAmount && Servicefilter && !categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];

        const serfilter = Starhotels?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        var filterarray = [];
        if (serfilter?.length > 0) {
          for (let i = 0; i < serfilter?.length; i++) {
            for (let j = 0; j < serfilter[i]?.service?.length; j++) {
              const gst =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                serfilter[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(serfilter[i]);
              }
            }
          }
        }
        const uniqueCat = filterarray?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );
        setcatdata(uniqueCat);
      } else if (!Servicefilter && !categoryfilter && !seletedAmount) {
        const uniqueCat = Starhotels?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                JSON.stringify(t.businessName) ===
                  JSON.stringify(item.businessName) &&
                JSON.stringify(t.address) === JSON.stringify(item.address)
            )
        );

        setcatdata(uniqueCat);
      }
    } else if (seletedAmount && Servicefilter && categoryfilter) {
      var xyz = seletedAmount?.split("-");
      var seletedMinAmount = xyz[0];
      var seletedMaxAmount = xyz[1];
      const catfilter = Vendor?.filter((person) =>
        person.service.some(
          (hobby) => hobby.Service_Category === categoryfilter
        )
      );

      const serfilter = catfilter?.filter((person) =>
        person.service.some((hobby) => hobby.ServiceName === Servicefilter)
      );
      var filterarray = [];
      if (serfilter?.length > 0) {
        for (let i = 0; i < serfilter?.length; i++) {
          for (let j = 0; j < serfilter[i]?.service?.length; j++) {
            const gst =
              (serfilter[i]?.service[j]?.Price *
                serfilter[i]?.service[j]?.GST) /
              100;
            const servicetax =
              (serfilter[i]?.service[j]?.Price *
                serfilter[i]?.service[j]?.servicetax) /
              100;
            const discount =
              (serfilter[i]?.service[j]?.Price *
                serfilter[i]?.service[j]?.Discount) /
              100;

            const total_amount =
              serfilter[i]?.service[j]?.Price + gst + servicetax - discount;

            if (
              parseInt(seletedMinAmount) <= total_amount &&
              total_amount <= parseInt(seletedMaxAmount)
            ) {
              filterarray?.push(serfilter[i]);
            }
          }
        }
      }
      const uniqueCat = filterarray?.filter(
        (item, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              JSON.stringify(t.businessName) ===
                JSON.stringify(item.businessName) &&
              JSON.stringify(t.address) === JSON.stringify(item.address)
          )
      );
      setcatdata(uniqueCat);
    } else if (Servicefilter && categoryfilter && !seletedAmount) {
      const xyz = Vendor?.filter((person) =>
        person.service.some(
          (hobby) => hobby.Service_Category === categoryfilter
        )
      );

      const xyz1 = xyz?.filter((person) =>
        person.service.some((hobby) => hobby.ServiceName === Servicefilter)
      );
      const uniqueCat = xyz1?.filter(
        (item, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              JSON.stringify(t.businessName) ===
                JSON.stringify(item.businessName) &&
              JSON.stringify(t.address) === JSON.stringify(item.address)
          )
      );
      setcatdata(uniqueCat);
    } else if (categoryfilter && !Servicefilter && !seletedAmount) {
      const xyz = Vendor?.filter((person) =>
        person.service.some(
          (hobby) => hobby.Service_Category === categoryfilter
        )
      );
      const uniqueCat = xyz?.filter(
        (item, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              JSON.stringify(t.businessName) ===
                JSON.stringify(item.businessName) &&
              JSON.stringify(t.address) === JSON.stringify(item.address)
          )
      );

      setcatdata(uniqueCat);
    } else if (Servicefilter && !categoryfilter && !seletedAmount) {
      const xyz = Vendor?.filter((person) =>
        person.service.some((hobby) => hobby.ServiceName === Servicefilter)
      );
      // console.log('xyz', Servicefilter, xyz);

      const uniqueCat = xyz?.filter(
        (item, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              JSON.stringify(t.businessName) ===
                JSON.stringify(item.businessName) &&
              JSON.stringify(t.address) === JSON.stringify(item.address)
          )
      );

      setcatdata(uniqueCat);
    } else if (seletedAmount && !Servicefilter && !categoryfilter) {
      var xyz = seletedAmount?.split("-");
      var seletedMinAmount = xyz[0];
      var seletedMaxAmount = xyz[1];
      var filterarray = [];

      if (Vendor?.length > 0) {
        for (let i = 0; i < Vendor?.length; i++) {
          for (let j = 0; j < Vendor[i]?.service?.length; j++) {
            const gst =
              (Vendor[i]?.service[j]?.Price * Vendor[i]?.service[j]?.GST) / 100;
            const servicetax =
              (Vendor[i]?.service[j]?.Price *
                Vendor[i]?.service[j]?.servicetax) /
              100;
            const discount =
              (Vendor[i]?.service[j]?.Price * Vendor[i]?.service[j]?.Discount) /
              100;

            const total_amount =
              Vendor[i]?.service[j]?.Price + gst + servicetax - discount;

            if (
              parseInt(seletedMinAmount) <= total_amount &&
              total_amount <= parseInt(seletedMaxAmount)
            ) {
              filterarray?.push(Vendor[i]);
            }
          }
        }
      }

      // console.log("filterarray", filterarray);
      const uniqueCat = filterarray?.filter(
        (item, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              JSON.stringify(t.businessName) ===
                JSON.stringify(item.businessName) &&
              JSON.stringify(t.address) === JSON.stringify(item.address)
          )
      );
      setcatdata(uniqueCat);
    } else if (seletedAmount && !Servicefilter && categoryfilter) {
      var xyz = seletedAmount?.split("-");
      var seletedMinAmount = xyz[0];
      var seletedMaxAmount = xyz[1];

      const catfilter = Vendor?.filter((person) =>
        person.service.some(
          (hobby) => hobby.Service_Category === categoryfilter
        )
      );
      var filterarray = [];
      if (catfilter?.length > 0) {
        for (let i = 0; i < catfilter?.length; i++) {
          for (let j = 0; j < catfilter[i]?.service?.length; j++) {
            const gst =
              (catfilter[i]?.service[j]?.Price *
                catfilter[i]?.service[j]?.GST) /
              100;
            const servicetax =
              (catfilter[i]?.service[j]?.Price *
                catfilter[i]?.service[j]?.servicetax) /
              100;
            const discount =
              (catfilter[i]?.service[j]?.Price *
                catfilter[i]?.service[j]?.Discount) /
              100;

            const total_amount =
              catfilter[i]?.service[j]?.Price + gst + servicetax - discount;

            if (
              parseInt(seletedMinAmount) <= total_amount &&
              total_amount <= parseInt(seletedMaxAmount)
            ) {
              filterarray?.push(catfilter[i]);
            }
          }
        }
      }
      const uniqueCat = filterarray?.filter(
        (item, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              JSON.stringify(t.businessName) ===
                JSON.stringify(item.businessName) &&
              JSON.stringify(t.address) === JSON.stringify(item.address)
          )
      );
      setcatdata(uniqueCat);
    } else if (seletedAmount && Servicefilter && !categoryfilter) {
      var xyz = seletedAmount?.split("-");
      var seletedMinAmount = xyz[0];
      var seletedMaxAmount = xyz[1];

      const serfilter = Vendor?.filter((person) =>
        person.service.some((hobby) => hobby.ServiceName === Servicefilter)
      );
      var filterarray = [];
      if (serfilter?.length > 0) {
        for (let i = 0; i < serfilter?.length; i++) {
          for (let j = 0; j < serfilter[i]?.service?.length; j++) {
            const gst =
              (serfilter[i]?.service[j]?.Price *
                serfilter[i]?.service[j]?.GST) /
              100;
            const servicetax =
              (serfilter[i]?.service[j]?.Price *
                serfilter[i]?.service[j]?.servicetax) /
              100;
            const discount =
              (serfilter[i]?.service[j]?.Price *
                serfilter[i]?.service[j]?.Discount) /
              100;

            const total_amount =
              serfilter[i]?.service[j]?.Price + gst + servicetax - discount;

            if (
              parseInt(seletedMinAmount) <= total_amount &&
              total_amount <= parseInt(seletedMaxAmount)
            ) {
              filterarray?.push(serfilter[i]);
            }
          }
        }
      }
      const uniqueCat = filterarray?.filter(
        (item, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              JSON.stringify(t.businessName) ===
                JSON.stringify(item.businessName) &&
              JSON.stringify(t.address) === JSON.stringify(item.address)
          )
      );
      setcatdata(uniqueCat);
    } else if (!Servicefilter && !categoryfilter && !seletedAmount) {
      const uniqueCat = Vendor?.filter(
        (item, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              JSON.stringify(t.businessName) ===
                JSON.stringify(item.businessName) &&
              JSON.stringify(t.address) === JSON.stringify(item.address)
          )
      );

      setcatdata(uniqueCat);
    }
  }, [
    SelectTopBrands,
    SelectTopRated,
    seletedAmount,
    categoryfilter,
    Servicefilter,
  ]);

  // console.log("newarry", categoryfilter);

  const [show, setShow] = useState(false);
  const [cupondata, setcupondata] = useState({});
  const [promocode, setpromocode] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setcupondata(data);
  };

  const [image, setimage] = useState();
  const [iddata, setiddata] = useState({});
  const changePrice = (event, newValue) => {
    setValue(newValue);
  };

  // copy to clipboard
  const [isCopied, setIsCopied] = React.useState(false);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(text);
      // alert("Copied!");
      notify("Copied!");
    });
  };

  useEffect(() => {
    if ((type, address)) {
      getallVendor();
      getMallandShopping();
      getstarhotels();
      getTopbrands();
      getallCategory();
      getallservices();
    }
    getPromocode();
  }, [selectedType, SelectTopBrands, SelectTopRated]);

  const getallCategory = () => {
    axios
      .get("https://spazo.co.in/api/admin/getcategory")
      .then(function (response) {
        console.log(response.data);
        setcategory(
          response.data.category?.filter(
            (cat) => cat?.cattype === (selectedType ? selectedType : type)
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getallservices = () => {
    axios
      .get("https://spazo.co.in/api/admin/getAdminservice")
      .then(function (response) {
        console.log("Service", response.data);
        setService(
          response.data.Adminservice?.filter(
            (item) => item?.type === (selectedType ? selectedType : type)
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getTopbrands = () => {
    axios
      .get("https://spazo.co.in/api/admin/getTopbrandswithserveice")
      .then(function (response) {
        // console.log("Topbrandsdsdfdsfds", response.data.Topbrand);

        const newtypefilterarray = [];
        if (response.data.Topbrand?.length > 0) {
          for (let i = 0; i < response.data.Topbrand?.length; i++) {
            newtypefilterarray.push(response.data.Topbrand[i].vendors[0]);
          }
        }

        // console.log("newtypefilterarray", newtypefilterarray);

        const typefilter = newtypefilterarray?.filter(
          (item) => item.type === (selectedType ? selectedType : type)
        );

        // console.log("typefilter", typefilter);

        const locationFilter =
          address === "Bengaluru"
            ? typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  (item.address.toLowerCase().includes("bengaluru") ||
                    item.address.toLowerCase().includes("bangalore"))
              )
            : address === "Mysore"
            ? typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  (item.address.toLowerCase().includes("mysore") ||
                    item.address.toLowerCase().includes("mysuru"))
              )
            : address === "Gurgaon"
            ? typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  (item.address.toLowerCase().includes("gurugram") ||
                    item.address.toLowerCase().includes("gurgaon"))
              )
            : (locationFilter = typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  item.address.toLowerCase().includes(address.toLowerCase())
              ));

        // console.log("locationFilter", locationFilter);

        setTopbrands(locationFilter);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getstarhotels = () => {
    axios
      .get("https://spazo.co.in/api/admin/getStarHotelswithserveice")
      .then(function (response) {
        // console.log("response.data.starHotels", response.data.starHotels);

        const newtypefilterarray = [];
        if (response.data.starHotels?.length > 0) {
          for (let i = 0; i < response.data.starHotels?.length; i++) {
            newtypefilterarray.push(response.data.starHotels[i]?.vendors[0]);
          }
        }

        const typefilter = newtypefilterarray?.filter(
          (item) => item.type === (selectedType ? selectedType : type)
        );

        // console.log("newtypefilterarray", newtypefilterarray);

        const locationFilter =
          address === "Bengaluru"
            ? typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  (item.address.toLowerCase().includes("bengaluru") ||
                    item.address.toLowerCase().includes("bangalore"))
              )
            : address === "Mysore"
            ? typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  (item.address.toLowerCase().includes("mysore") ||
                    item.address.toLowerCase().includes("mysuru"))
              )
            : address === "Gurgaon"
            ? typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  (item.address.toLowerCase().includes("gurugram") ||
                    item.address.toLowerCase().includes("gurgaon"))
              )
            : (locationFilter = typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  item.address.toLowerCase().includes(address.toLowerCase())
              ));

        // console.log("locationFilter", locationFilter);

        setStarhotels(locationFilter);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getMallandShopping = () => {
    axios
      .get("https://spazo.co.in/api/admin/getMallsandShoppingwithserveice")
      .then(function (response) {
        const newtypefilterarray = [];
        if (response.data.MallsandShopping?.length > 0) {
          for (let i = 0; i < response.data.MallsandShopping?.length; i++) {
            newtypefilterarray.push(
              response.data.MallsandShopping[i].vendors[0]
            );
          }
        }

        const typefilter = newtypefilterarray?.filter(
          (item) => item.type === (selectedType ? selectedType : type)
        );

        // console.log("newtypefilterarray", newtypefilterarray);

        const locationFilter =
          address === "Bengaluru"
            ? typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  (item.address.toLowerCase().includes("bengaluru") ||
                    item.address.toLowerCase().includes("bangalore"))
              )
            : address === "Mysore"
            ? typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  (item.address.toLowerCase().includes("mysore") ||
                    item.address.toLowerCase().includes("mysuru"))
              )
            : address === "Gurgaon"
            ? typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  (item.address.toLowerCase().includes("gurugram") ||
                    item.address.toLowerCase().includes("gurgaon"))
              )
            : (locationFilter = typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  item.address.toLowerCase().includes(address.toLowerCase())
              ));

        // console.log("locationFilter", locationFilter);

        setMallsandshopping(locationFilter);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getallVendor = () => {
    axios
      .get("https://spazo.co.in/api/vendor/allVendors")
      .then(function (response) {
        console.log(response.data);
        if (address === "Bengaluru") {
          setVendor(
            response.data.allVendors?.filter(
              (item) =>
                item.approved === "Approved" &&
                item.isBlocked === false &&
                item?.type === (selectedType ? selectedType : type) &&
                (item.address.toLowerCase().includes("bengaluru") ||
                  item.address.toLowerCase().includes("bangalore"))
            )
          );
        } else if (address === "Mysore") {
          setVendor(
            response.data.allVendors?.filter(
              (item) =>
                item.approved === "Approved" &&
                item.isBlocked === false &&
                item?.type === (selectedType ? selectedType : type) &&
                (item.address.toLowerCase().includes("mysore") ||
                  item.address.toLowerCase().includes("mysuru"))
            )
          );
        } else if (address === "Gurgaon") {
          setVendor(
            response.data.allVendors?.filter(
              (item) =>
                item.approved === "Approved" &&
                item.isBlocked === false &&
                item?.type === (selectedType ? selectedType : type) &&
                (item.address.toLowerCase().includes("gurugram") ||
                  item.address.toLowerCase().includes("gurgaon"))
            )
          );
        } else {
          setVendor(
            response.data.allVendors?.filter(
              (item) =>
                item.approved === "Approved" &&
                item.isBlocked === false &&
                item?.type === (selectedType ? selectedType : type) &&
                item.address.toLowerCase().includes(address.toLowerCase())
            )
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log("Vendor", Vendor, address, type);
  const getPromocode = () => {
    axios
      .get("https://spazo.co.in/api/admin/getPromocode")
      .then(function (response) {
        console.log("Promocode", response.data);
        setpromocode(
          response.data.PromocodeList.filter(
            (item) =>
              item.Status === "Valid" &&
              new Date(item.Startdate) <= currentDate &&
              item?.promocodeType === (selectedType ? selectedType : type)
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [search1, setsearch1] = useState([]);
  console.log(search1);

  function handlefilter(event) {
    const searchword = event.target.value;
    const newfilter = Vendor?.filter((value) => {
      return value?.businessName
        ?.toLowerCase()
        .includes(searchword?.toLowerCase());
    });
    if (searchword == "") {
      setsearch1([]);
    } else {
      setsearch1(newfilter);
    }
  }

  console.log("promocode", promocode);
  return (
    <>
      <div>
        <Newheader2 />
        <Row className="me-0 ">
          <ToastContainer
            position="top-center"
            autoClose={30000}
            closeOnClick
            pauseOnHover
            draggable
          />
          <Col
            className="p-3"
            md={3}
            style={{
              borderRight: "1px solid rgb(217 212 208)",
              // overflowY: "scroll",
              // overflowX: "hidden",
              // height: "100vh",
              // paddingBottom: "30%",
            }}
          >
            {promocode?.length > 0 ? (
              <>
                <p
                  style={{
                    color: "#e22217",
                    fontWeight: "700",
                    fontSize: "22px",
                  }}
                >
                  Promos & Filters
                </p>
                <Carousel
                  autoPlay={true}
                  infiniteLoop={true}
                  showIndicators={false}
                >
                  {promocode?.map((prom) => {
                    const today = new Date(); // Get today's date
                    const endDate = new Date(prom?.Enddate); // Convert Enddate to a Date object

                    // Set the time of both dates to 00:00:00 to only compare the date part
                    today.setHours(0, 0, 0, 0);
                    endDate.setHours(0, 0, 0, 0);

                    const status = endDate < today ? "Expired" : "Valid";
                    return (
                      <div
                        style={{
                          boxShadow: "3px -10px 10px #cbc6c6",
                          padding: "10px",
                          backgroundColor: "white",
                        }}
                      >
                        <p>Win Rs {prom?.amount} instant off code</p>
                        {/* <p
                style={{
                  backgroundColor: "#e22217",
                  color: "white",
                  padding: "3px",
                  textAlign: "center",
                }}
              >
                NBLUCKY
              </p> */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              border: "1px dotted gray",
                              padding: "10px",
                              marginBottom: "0px",
                              color: "#e22217",
                            }}
                          >
                            {prom?.Promocode}
                          </p>
                          {status === "Expired" ? (
                            ""
                          ) : (
                            <button
                              style={{ borderColor: "gray", padding: "10px" }}
                              onClick={() => handleCopy(prom?.Promocode)}
                            >
                              Copy
                            </button>
                          )}
                        </div>

                        <div className="mt-3">
                          {status === "Expired" ? (
                            <span>Sorry..! Validity Expired</span>
                          ) : (
                            <span>
                              Valid till{" "}
                              {moment(prom?.Enddate).format("MMM Do YY")}
                            </span>
                          )}
                          {/* <span
                          style={{ color: "#e22217", marginLeft: "10px" }}
                          onClick={() => handleShow(prom)}
                        >
                          Know more
                        </span> */}
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
                <hr></hr>
              </>
            ) : (
              <></>
            )}

            <div>
              <p
                style={{
                  color: "#e22217",
                  fontWeight: "700",
                  fontSize: "22px",
                }}
              >
                Categories
              </p>
              <ul
                className="example"
                // style={{
                //   boxShadow: "3px 4px 10px #cbc6c6",
                //   padding: "20px",
                //   backgroundColor: "white",
                // }}
              >
                {category?.map((cat) => (
                  <li
                    style={{
                      fontWeight:
                        categoryfilter === cat?.catname ? "bold" : "500",
                      color:
                        categoryfilter === cat?.catname ? "#e22217" : "black",
                    }}
                    onClick={() => setcategoryfilter(cat?.catname)}
                  >
                    {cat?.catname}
                  </li>
                ))}
              </ul>
            </div>
            <hr></hr>
            <div
              style={{
                height: "400px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <p
                style={{
                  color: "#e22217",
                  fontWeight: "700",
                  fontSize: "22px",
                }}
              >
                Service's
              </p>
              <ul className="example">
                {Service?.map((ser) => (
                  <li
                    onClick={() => setServicefilter(ser?.servicename)}
                    style={{
                      fontWeight:
                        Servicefilter === ser?.servicename ? "bold" : "500",
                      color:
                        Servicefilter === ser?.servicename
                          ? "#e22217"
                          : "black",
                    }}
                  >
                    {ser?.servicename}
                  </li>
                ))}
              </ul>
            </div>
            <hr></hr>
            <div>
              <p
                style={{
                  color: "#e22217",
                  fontWeight: "700",
                  fontSize: "22px",
                }}
              >
                Price Filter
              </p>
              <input
                type="radio"
                id="html"
                name="price"
                value="0-499"
                onChange={(e) => setseletedAmount(e.target.value)}
              />
              &nbsp;
              <label for="html" style={{ color: "black", fontSize: "16px" }}>
                Under ₹ 500
              </label>
              <br />
              <input
                type="radio"
                id="css"
                name="price"
                value="500-999"
                onChange={(e) => setseletedAmount(e.target.value)}
              />
              &nbsp;
              <label for="css" style={{ color: "black", fontSize: "16px" }}>
                ₹ 500 - ₹ 999
              </label>
              <br />
              <input
                type="radio"
                id="javascript"
                name="price"
                value="1000-1499"
                onChange={(e) => setseletedAmount(e.target.value)}
              />
              &nbsp;
              <label
                for="javascript"
                style={{ color: "black", fontSize: "16px" }}
              >
                ₹ 1000 - ₹ 1499
              </label>
              <br />
              <input
                type="radio"
                id="css"
                name="price"
                value="1500-1999"
                onChange={(e) => setseletedAmount(e.target.value)}
              />
              &nbsp;
              <label for="css" style={{ color: "black", fontSize: "16px" }}>
                ₹ 1500 - ₹ 1999
              </label>
              <br />
              <input
                type="radio"
                id="css"
                name="price"
                value="2000-2499"
                onChange={(e) => setseletedAmount(e.target.value)}
              />
              &nbsp;
              <label for="css" style={{ color: "black", fontSize: "16px" }}>
                ₹ 2000 - ₹ 2499
              </label>
              <br />
              <input
                type="radio"
                id="css"
                name="price"
                value="2500-1000000"
                onChange={(e) => setseletedAmount(e.target.value)}
              />
              &nbsp;
              <label for="css" style={{ color: "black", fontSize: "16px" }}>
                Greater than ₹ 2500
              </label>
            </div>

            <hr></hr>
            <div>
              <p
                style={{
                  color: "#e22217",
                  fontWeight: "700",
                  fontSize: "22px",
                }}
              >
                Filter By
              </p>
              <input
                type="radio"
                id="TopBrands"
                name="Brands"
                value="Top Brands"
                onChange={(e) => setSelectTopBrands(e.target.value)}
              />
              <label
                htmlFor="TopBrands"
                style={{ color: "black", fontSize: "16px", marginLeft: "10px" }}
              >
                Top Brands
              </label>
              <br></br>
              <input
                type="radio"
                id="Malls"
                name="Brands"
                value="In Malls And Shopping Hubs"
                onChange={(e) => setSelectTopBrands(e.target.value)}
              />
              <label
                htmlFor="Malls"
                style={{ color: "black", fontSize: "16px", marginLeft: "10px" }}
              >
                In Malls And Shopping Hubs
              </label>
              <br></br>
              <input
                type="radio"
                id="StarHotels"
                name="Brands"
                value="At 5 Star Hotels"
                onChange={(e) => setSelectTopBrands(e.target.value)}
              />
              <label
                htmlFor="StarHotels"
                style={{ color: "black", fontSize: "16px", marginLeft: "10px" }}
              >
                At 5 Star Hotels
              </label>
              <br></br>
              <input
                type="checkbox"
                value={SelectTopRated}
                onChange={() => setSelectTopRated(!SelectTopRated)}
              />
              <label
                style={{ color: "black", fontSize: "16px", marginLeft: "10px" }}
              >
                Top Rated
              </label>
            </div>
          </Col>
          <Col md={9}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: "25px", fontWeight: "bold" }}>{address}</p>
            </div>
            <div className="cityspsearch">
              <div>
                <input
                  type="text"
                  placeholder={`Search ${
                    selectedType ? selectedType : type
                  }...`}
                  id="search-text-input"
                  // id="A"
                  required
                  onChange={handlefilter}
                  className="cityspsearchinput"
                />

                {search1.length !== 0 ? (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 1000,
                      background: "white",
                      // borderBottom: "2px solid #de2822",
                      padding: "2px",
                      // marginTop: "50px",
                      width: "500px",
                      // marginLeft: "8px",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    {search1.slice(0, 2)?.map((item) => {
                      return (
                        <div>
                          <Link
                            to="/Newproductdetails"
                            state={{ item: item }}
                            style={{
                              textDecoration: "none",
                              color: "gray",
                            }}
                          >
                            <p>{item?.businessName}</p>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div
                id="button-holder"
                style={{ marginTop: "12px", display: "none" }}
              >
                <label style={{ border: "none" }}>
                  {search1.length == 0 ? (
                    <img src="../search.png" alt="search" />
                  ) : (
                    <i
                      class="far fa-window-close"
                      style={{ marginTop: "10px", color: "gray" }}
                    ></i>
                  )}
                </label>
              </div>
              <div style={{ marginTop: "10px" }}>
                <select
                  onChange={(e) => setselectedType(e.target.value)}
                  className="cityselecttype"
                >
                  <option>{type}</option>
                  {type === "Spa" ? "" : <option value="Spa">Spa</option>}
                  {type === "Salon" ? "" : <option value="Salon">Salon</option>}
                  {type === "Beauty Care Products" ? (
                    ""
                  ) : (
                    <option value="Beauty Care Products">
                      Beauty Care Products
                    </option>
                  )}
                </select>
              </div>
            </div>
            <hr></hr>

            {!seletedAmount &&
            !Servicefilter &&
            !categoryfilter &&
            !SelectTopBrands ? (
              <>
                {Vendor?.length > 0 ? (
                  <>
                    {SelectTopRated
                      ? sortRatingWise?.map((item) => (
                          <>
                            <Row>
                              <Col md={6}>
                                <Row>
                                  <Col md={2}></Col>
                                  <Col md={8} style={{ padding: "2px" }}>
                                    <img
                                      src={
                                        iddata._id === item._id
                                          ? image
                                            ? image
                                            : "https://spazo.co.in/Vendor/" +
                                              item?.spaimage1
                                          : "https://spazo.co.in/Vendor/" +
                                            item?.spaimage1
                                      }
                                      style={{
                                        width: "100%",
                                        height: "300px",
                                        // objectFit: "cover",
                                        // overflow: "hidden",
                                      }}
                                    />
                                  </Col>
                                  <Col md={2}>
                                    <div>
                                      <img
                                        src={
                                          "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                        }
                                        style={{
                                          width: "70px",
                                          height: "70px",
                                          margin: "2px",
                                          // objectFit: "cover",
                                          // overflow: "hidden",
                                        }}
                                        onClick={(e) => {
                                          setimage(
                                            "https://spazo.co.in/Vendor/" +
                                              item?.spaimage1
                                          );
                                          setiddata(item);
                                        }}
                                      />
                                      <img
                                        src={
                                          "https://spazo.co.in/Vendor/" +
                                          item?.spaimage2
                                        }
                                        style={{
                                          width: "70px",
                                          height: "70px",
                                          margin: "2px",
                                          // objectFit: "cover",
                                          // overflow: "hidden",
                                        }}
                                        onClick={(e) => {
                                          setimage(
                                            "https://spazo.co.in/Vendor/" +
                                              item?.spaimage2
                                          );
                                          setiddata(item);
                                        }}
                                      />
                                      <img
                                        src={
                                          "https://spazo.co.in/Vendor/" +
                                          item?.spaimage3
                                        }
                                        style={{
                                          width: "70px",
                                          height: "70px",
                                          margin: "2px",
                                          // objectFit: "cover",
                                          // overflow: "hidden",
                                        }}
                                        onClick={(e) => {
                                          setimage(
                                            "https://spazo.co.in/Vendor/" +
                                              item?.spaimage3
                                          );
                                          setiddata(item);
                                        }}
                                      />
                                      <img
                                        src={
                                          "https://spazo.co.in/Vendor/" +
                                          item?.spaimage4
                                        }
                                        style={{
                                          width: "70px",
                                          height: "70px",
                                          margin: "2px",
                                          // objectFit: "cover",
                                          // overflow: "hidden",
                                        }}
                                        onClick={(e) => {
                                          setimage(
                                            "https://spazo.co.in/Vendor/" +
                                              item?.spaimage4
                                          );
                                          setiddata(item);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <img></img>
                              </Col>
                              <Col md={6}>
                                <p
                                  style={{
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                    marginBottom: "0px",
                                  }}
                                >
                                  {item?.businessName}
                                </p>
                                <p>{item?.address}</p>
                                <div className="ratingparking">
                                  <div style={{ marginTop: "15px" }}>
                                    <span>Ratings :</span>&nbsp;
                                    <span
                                      style={{
                                        backgroundColor: "#e22217 ",
                                        color: "white",
                                        padding: "0px 5px",
                                        width: "10%",
                                      }}
                                    >
                                      {isNaN(
                                        item?.reviews?.reduce(
                                          (a, rev) => a + rev.rating,
                                          0
                                        ) / item?.reviews?.length
                                      )
                                        ? 0
                                        : (
                                            item?.reviews?.reduce(
                                              (a, rev) => a + rev.rating,
                                              0
                                            ) / item?.reviews?.length
                                          )?.toFixed(1)}
                                      &nbsp;
                                      <i class="fas fa-star"></i>
                                    </span>
                                  </div>
                                  <div>
                                    <span>Parking :</span>
                                    {item?.parking === "4-Wheeler" ? (
                                      <i
                                        class="fas fa-car"
                                        style={{
                                          fontSize: "30px",
                                          margin: "3px",
                                        }}
                                      ></i>
                                    ) : item?.parking === "2-Wheeler" ? (
                                      <i
                                        class="fas fa-motorcycle"
                                        style={{
                                          fontSize: "30px",
                                          margin: "3px",
                                        }}
                                      ></i>
                                    ) : item?.parking === "Both" ? (
                                      <>
                                        <i
                                          class="fas fa-car"
                                          style={{
                                            fontSize: "30px",
                                            margin: "3px",
                                          }}
                                        ></i>
                                        <i
                                          class="fas fa-motorcycle"
                                          style={{
                                            fontSize: "30px",
                                            margin: "3px",
                                          }}
                                        ></i>
                                      </>
                                    ) : (
                                      item?.parking
                                    )}
                                  </div>
                                </div>

                                {/* <div className="mt-5">
                      <span
                        style={{
                          color: "#e22217",
                          fontSize: "30px",
                          fontWeight: "700",
                        }}
                      >
                        ₹2000
                      </span>
                      &nbsp;&nbsp;
                      <span
                        style={{
                          fontSize: "18px",
                          textDecoration: "line-through",
                        }}
                      >
                        ₹4000
                      </span>
                    </div> */}
                                <div className="mt-5">
                                  <Link
                                    style={{
                                      padding: "10px 20px",
                                      fontWeight: "700",
                                      textDecoration: "none",
                                      border: "1px solid black",
                                      backgroundColor: "#1ab64f",
                                      color: "white",
                                      borderColor: "#1ab64f",
                                    }}
                                    to="/Newproductdetails"
                                    state={{ item: item }}
                                  >
                                    View Details
                                  </Link>
                                  {/* <Link
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor: "transparent",
                                  fontWeight: "700",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                  margin: "10px",
                                }}
                                // onClick={() => window.location.assign("/Checkout")}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                Book Now
                              </Link> */}
                                </div>
                              </Col>
                            </Row>
                            <hr></hr>
                          </>
                        ))
                      : Vendor?.map((item) => (
                          <>
                            <Row>
                              <Col md={6}>
                                <Row>
                                  <Col md={2}></Col>
                                  <Col md={8} style={{ padding: "2px" }}>
                                    <img
                                      src={
                                        iddata._id === item._id
                                          ? image
                                            ? image
                                            : "https://spazo.co.in/Vendor/" +
                                              item?.spaimage1
                                          : "https://spazo.co.in/Vendor/" +
                                            item?.spaimage1
                                      }
                                      style={{
                                        width: "100%",
                                        height: "300px",
                                        // objectFit: "cover",
                                        // overflow: "hidden",
                                      }}
                                    />
                                  </Col>
                                  <Col md={2}>
                                    <div>
                                      <img
                                        src={
                                          "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                        }
                                        style={{
                                          width: "70px",
                                          height: "70px",
                                          margin: "2px",
                                          // objectFit: "cover",
                                          // overflow: "hidden",
                                        }}
                                        onClick={(e) => {
                                          setimage(
                                            "https://spazo.co.in/Vendor/" +
                                              item?.spaimage1
                                          );
                                          setiddata(item);
                                        }}
                                      />
                                      <img
                                        src={
                                          "https://spazo.co.in/Vendor/" +
                                          item?.spaimage2
                                        }
                                        style={{
                                          width: "70px",
                                          height: "70px",
                                          margin: "2px",
                                          // objectFit: "cover",
                                          // overflow: "hidden",
                                        }}
                                        onClick={(e) => {
                                          setimage(
                                            "https://spazo.co.in/Vendor/" +
                                              item?.spaimage2
                                          );
                                          setiddata(item);
                                        }}
                                      />
                                      <img
                                        src={
                                          "https://spazo.co.in/Vendor/" +
                                          item?.spaimage3
                                        }
                                        style={{
                                          width: "70px",
                                          height: "70px",
                                          margin: "2px",
                                          // objectFit: "cover",
                                          // overflow: "hidden",
                                        }}
                                        onClick={(e) => {
                                          setimage(
                                            "https://spazo.co.in/Vendor/" +
                                              item?.spaimage3
                                          );
                                          setiddata(item);
                                        }}
                                      />
                                      <img
                                        src={
                                          "https://spazo.co.in/Vendor/" +
                                          item?.spaimage4
                                        }
                                        style={{
                                          width: "70px",
                                          height: "70px",
                                          margin: "2px",
                                          // objectFit: "cover",
                                          // overflow: "hidden",
                                        }}
                                        onClick={(e) => {
                                          setimage(
                                            "https://spazo.co.in/Vendor/" +
                                              item?.spaimage4
                                          );
                                          setiddata(item);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <img></img>
                              </Col>
                              <Col md={6}>
                                <p
                                  style={{
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                    marginBottom: "0px",
                                  }}
                                >
                                  {item?.businessName}
                                </p>
                                <p>{item?.address}</p>
                                <div className="ratingparking">
                                  <div style={{ marginTop: "15px" }}>
                                    <span>Ratings :</span>&nbsp;
                                    <span
                                      style={{
                                        backgroundColor: "#e22217 ",
                                        color: "white",
                                        padding: "0px 5px",
                                        width: "10%",
                                      }}
                                    >
                                      {isNaN(
                                        item?.reviews?.reduce(
                                          (a, rev) => a + rev.rating,
                                          0
                                        ) / item?.reviews?.length
                                      )
                                        ? 0
                                        : (
                                            item?.reviews?.reduce(
                                              (a, rev) => a + rev.rating,
                                              0
                                            ) / item?.reviews?.length
                                          )?.toFixed(1)}
                                      &nbsp;
                                      <i class="fas fa-star"></i>
                                    </span>
                                  </div>
                                  <div>
                                    <span>Parking :</span>
                                    {item?.parking === "4-Wheeler" ? (
                                      <i
                                        class="fas fa-car"
                                        style={{
                                          fontSize: "30px",
                                          margin: "3px",
                                        }}
                                      ></i>
                                    ) : item?.parking === "2-Wheeler" ? (
                                      <i
                                        class="fas fa-motorcycle"
                                        style={{
                                          fontSize: "30px",
                                          margin: "3px",
                                        }}
                                      ></i>
                                    ) : item?.parking === "Both" ? (
                                      <>
                                        <i
                                          class="fas fa-car"
                                          style={{
                                            fontSize: "30px",
                                            margin: "3px",
                                          }}
                                        ></i>
                                        <i
                                          class="fas fa-motorcycle"
                                          style={{
                                            fontSize: "30px",
                                            margin: "3px",
                                          }}
                                        ></i>
                                      </>
                                    ) : (
                                      item?.parking
                                    )}
                                  </div>
                                </div>

                                {/* <div className="mt-5">
                      <span
                        style={{
                          color: "#e22217",
                          fontSize: "30px",
                          fontWeight: "700",
                        }}
                      >
                        ₹2000
                      </span>
                      &nbsp;&nbsp;
                      <span
                        style={{
                          fontSize: "18px",
                          textDecoration: "line-through",
                        }}
                      >
                        ₹4000
                      </span>
                    </div> */}
                                <div className="mt-5">
                                  <Link
                                    style={{
                                      padding: "10px 20px",
                                      fontWeight: "700",
                                      textDecoration: "none",
                                      border: "1px solid black",
                                      backgroundColor: "#1ab64f",
                                      color: "white",
                                      borderColor: "#1ab64f",
                                    }}
                                    to="/Newproductdetails"
                                    state={{ item: item }}
                                  >
                                    View Details
                                  </Link>
                                  {/* <Link
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor: "transparent",
                                  fontWeight: "700",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                  margin: "10px",
                                }}
                                // onClick={() => window.location.assign("/Checkout")}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                Book Now
                              </Link> */}
                                </div>
                              </Col>
                            </Row>
                            <hr></hr>
                          </>
                        ))}
                  </>
                ) : (
                  <p>Coming Soon.....</p>
                )}
              </>
            ) : (
              <>
                {catdata?.length > 0 ? (
                  <>
                    {SelectTopRated
                      ? sortRatingWise1?.map((item) => (
                          <>
                            <Row>
                              <Col md={6}>
                                <Row>
                                  <Col md={2}></Col>
                                  <Col md={8} style={{ padding: "2px" }}>
                                    <img
                                      src={
                                        iddata._id === item._id
                                          ? image
                                            ? image
                                            : "https://spazo.co.in/Vendor/" +
                                              item?.spaimage1
                                          : "https://spazo.co.in/Vendor/" +
                                            item?.spaimage1
                                      }
                                      style={{
                                        width: "100%",
                                        height: "300px",
                                        // objectFit: "cover",
                                        // overflow: "hidden",
                                      }}
                                    />
                                  </Col>
                                  <Col md={2}>
                                    <div>
                                      <img
                                        src={
                                          "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                        }
                                        style={{
                                          width: "70px",
                                          height: "70px",
                                          margin: "2px",
                                          // objectFit: "cover",
                                          // overflow: "hidden",
                                        }}
                                        onClick={(e) => {
                                          setimage(
                                            "https://spazo.co.in/Vendor/" +
                                              item?.spaimage1
                                          );
                                          setiddata(item);
                                        }}
                                      />
                                      <img
                                        src={
                                          "https://spazo.co.in/Vendor/" +
                                          item?.spaimage2
                                        }
                                        style={{
                                          width: "70px",
                                          height: "70px",
                                          margin: "2px",
                                          // objectFit: "cover",
                                          // overflow: "hidden",
                                        }}
                                        onClick={(e) => {
                                          setimage(
                                            "https://spazo.co.in/Vendor/" +
                                              item?.spaimage2
                                          );
                                          setiddata(item);
                                        }}
                                      />
                                      <img
                                        src={
                                          "https://spazo.co.in/Vendor/" +
                                          item?.spaimage3
                                        }
                                        style={{
                                          width: "70px",
                                          height: "70px",
                                          margin: "2px",
                                          // objectFit: "cover",
                                          // overflow: "hidden",
                                        }}
                                        onClick={(e) => {
                                          setimage(
                                            "https://spazo.co.in/Vendor/" +
                                              item?.spaimage3
                                          );
                                          setiddata(item);
                                        }}
                                      />
                                      <img
                                        src={
                                          "https://spazo.co.in/Vendor/" +
                                          item?.spaimage4
                                        }
                                        style={{
                                          width: "70px",
                                          height: "70px",
                                          margin: "2px",
                                          // objectFit: "cover",
                                          // overflow: "hidden",
                                        }}
                                        onClick={(e) => {
                                          setimage(
                                            "https://spazo.co.in/Vendor/" +
                                              item?.spaimage4
                                          );
                                          setiddata(item);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <img></img>
                              </Col>
                              <Col md={6}>
                                <p
                                  style={{
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                    marginBottom: "0px",
                                  }}
                                >
                                  {item?.businessName}
                                </p>
                                <p>{item?.address}</p>
                                <div className="ratingparking">
                                  <div style={{ marginTop: "15px" }}>
                                    <span>Ratings :</span>&nbsp;
                                    <span
                                      style={{
                                        backgroundColor: "#e22217 ",
                                        color: "white",
                                        padding: "0px 5px",
                                        width: "10%",
                                      }}
                                    >
                                      {isNaN(
                                        item?.reviews?.reduce(
                                          (a, rev) => a + rev.rating,
                                          0
                                        ) / item?.reviews?.length
                                      )
                                        ? 0
                                        : (
                                            item?.reviews?.reduce(
                                              (a, rev) => a + rev.rating,
                                              0
                                            ) / item?.reviews?.length
                                          )?.toFixed(1)}
                                      &nbsp;
                                      <i class="fas fa-star"></i>
                                    </span>
                                  </div>
                                  <div>
                                    <span>Parking :</span>
                                    {item?.parking === "4-Wheeler" ? (
                                      <i
                                        class="fas fa-car"
                                        style={{
                                          fontSize: "30px",
                                          margin: "3px",
                                        }}
                                      ></i>
                                    ) : item?.parking === "2-Wheeler" ? (
                                      <i
                                        class="fas fa-motorcycle"
                                        style={{
                                          fontSize: "30px",
                                          margin: "3px",
                                        }}
                                      ></i>
                                    ) : item?.parking === "Both" ? (
                                      <>
                                        <i
                                          class="fas fa-car"
                                          style={{
                                            fontSize: "30px",
                                            margin: "3px",
                                          }}
                                        ></i>
                                        <i
                                          class="fas fa-motorcycle"
                                          style={{
                                            fontSize: "30px",
                                            margin: "3px",
                                          }}
                                        ></i>
                                      </>
                                    ) : (
                                      item?.parking
                                    )}
                                  </div>
                                </div>

                                {/* <div className="mt-5">
                        <span
                          style={{
                            color: "#e22217",
                            fontSize: "30px",
                            fontWeight: "700",
                          }}
                        >
                          ₹2000
                        </span>
                        &nbsp;&nbsp;
                        <span
                          style={{
                            fontSize: "18px",
                            textDecoration: "line-through",
                          }}
                        >
                          ₹4000
                        </span>
                      </div> */}
                                <div className="mt-5">
                                  <Link
                                    style={{
                                      padding: "10px 20px",
                                      fontWeight: "700",
                                      textDecoration: "none",
                                      border: "1px solid black",
                                      backgroundColor: "#1ab64f",
                                      color: "white",
                                      borderColor: "#1ab64f",
                                    }}
                                    to="/Newproductdetails"
                                    state={{ item: item }}
                                  >
                                    View Details
                                  </Link>
                                  {/* <Link
                                  style={{
                                    padding: "10px 20px",
                                    backgroundColor: "transparent",
                                    fontWeight: "700",
                                    backgroundColor: "#1ab64f",
                                    color: "white",
                                    borderColor: "#1ab64f",
                                    margin: "10px",
                                  }}
                                  // onClick={() => window.location.assign("/Checkout")}
                                  to="/Newproductdetails"
                                  state={{ item: item }}
                                >
                                  Book Now
                                </Link> */}
                                </div>
                              </Col>
                            </Row>
                            <hr></hr>
                          </>
                        ))
                      : catdata?.map((item) => (
                          <>
                            <Row>
                              <Col md={6}>
                                <Row>
                                  <Col md={2}></Col>
                                  <Col md={8} style={{ padding: "2px" }}>
                                    <img
                                      src={
                                        iddata._id === item._id
                                          ? image
                                            ? image
                                            : "https://spazo.co.in/Vendor/" +
                                              item?.spaimage1
                                          : "https://spazo.co.in/Vendor/" +
                                            item?.spaimage1
                                      }
                                      style={{
                                        width: "100%",
                                        height: "300px",
                                        // objectFit: "cover",
                                        // overflow: "hidden",
                                      }}
                                    />
                                  </Col>
                                  <Col md={2}>
                                    <div>
                                      <img
                                        src={
                                          "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                        }
                                        style={{
                                          width: "70px",
                                          height: "70px",
                                          margin: "2px",
                                          // objectFit: "cover",
                                          // overflow: "hidden",
                                        }}
                                        onClick={(e) => {
                                          setimage(
                                            "https://spazo.co.in/Vendor/" +
                                              item?.spaimage1
                                          );
                                          setiddata(item);
                                        }}
                                      />
                                      <img
                                        src={
                                          "https://spazo.co.in/Vendor/" +
                                          item?.spaimage2
                                        }
                                        style={{
                                          width: "70px",
                                          height: "70px",
                                          margin: "2px",
                                          // objectFit: "cover",
                                          // overflow: "hidden",
                                        }}
                                        onClick={(e) => {
                                          setimage(
                                            "https://spazo.co.in/Vendor/" +
                                              item?.spaimage2
                                          );
                                          setiddata(item);
                                        }}
                                      />
                                      <img
                                        src={
                                          "https://spazo.co.in/Vendor/" +
                                          item?.spaimage3
                                        }
                                        style={{
                                          width: "70px",
                                          height: "70px",
                                          margin: "2px",
                                          // objectFit: "cover",
                                          // overflow: "hidden",
                                        }}
                                        onClick={(e) => {
                                          setimage(
                                            "https://spazo.co.in/Vendor/" +
                                              item?.spaimage3
                                          );
                                          setiddata(item);
                                        }}
                                      />
                                      <img
                                        src={
                                          "https://spazo.co.in/Vendor/" +
                                          item?.spaimage4
                                        }
                                        style={{
                                          width: "70px",
                                          height: "70px",
                                          margin: "2px",
                                          // objectFit: "cover",
                                          // overflow: "hidden",
                                        }}
                                        onClick={(e) => {
                                          setimage(
                                            "https://spazo.co.in/Vendor/" +
                                              item?.spaimage4
                                          );
                                          setiddata(item);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <img></img>
                              </Col>
                              <Col md={6}>
                                <p
                                  style={{
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                    marginBottom: "0px",
                                  }}
                                >
                                  {item?.businessName}
                                </p>
                                <p>{item?.address}</p>
                                <div className="ratingparking">
                                  <div style={{ marginTop: "15px" }}>
                                    <span>Ratings :</span>&nbsp;
                                    <span
                                      style={{
                                        backgroundColor: "#e22217 ",
                                        color: "white",
                                        padding: "0px 5px",
                                        width: "10%",
                                      }}
                                    >
                                      {isNaN(
                                        item?.reviews?.reduce(
                                          (a, rev) => a + rev.rating,
                                          0
                                        ) / item?.reviews?.length
                                      )
                                        ? 0
                                        : (
                                            item?.reviews?.reduce(
                                              (a, rev) => a + rev.rating,
                                              0
                                            ) / item?.reviews?.length
                                          )?.toFixed(1)}
                                      &nbsp;
                                      <i class="fas fa-star"></i>
                                    </span>
                                  </div>
                                  <div>
                                    <span>Parking :</span>
                                    {item?.parking === "4-Wheeler" ? (
                                      <i
                                        class="fas fa-car"
                                        style={{
                                          fontSize: "30px",
                                          margin: "3px",
                                        }}
                                      ></i>
                                    ) : item?.parking === "2-Wheeler" ? (
                                      <i
                                        class="fas fa-motorcycle"
                                        style={{
                                          fontSize: "30px",
                                          margin: "3px",
                                        }}
                                      ></i>
                                    ) : item?.parking === "Both" ? (
                                      <>
                                        <i
                                          class="fas fa-car"
                                          style={{
                                            fontSize: "30px",
                                            margin: "3px",
                                          }}
                                        ></i>
                                        <i
                                          class="fas fa-motorcycle"
                                          style={{
                                            fontSize: "30px",
                                            margin: "3px",
                                          }}
                                        ></i>
                                      </>
                                    ) : (
                                      item?.parking
                                    )}
                                  </div>
                                </div>

                                {/* <div className="mt-5">
                      <span
                        style={{
                          color: "#e22217",
                          fontSize: "30px",
                          fontWeight: "700",
                        }}
                      >
                        ₹2000
                      </span>
                      &nbsp;&nbsp;
                      <span
                        style={{
                          fontSize: "18px",
                          textDecoration: "line-through",
                        }}
                      >
                        ₹4000
                      </span>
                    </div> */}
                                <div className="mt-5">
                                  <Link
                                    style={{
                                      padding: "10px 20px",
                                      fontWeight: "700",
                                      textDecoration: "none",
                                      border: "1px solid black",
                                      backgroundColor: "#1ab64f",
                                      color: "white",
                                      borderColor: "#1ab64f",
                                    }}
                                    to="/Newproductdetails"
                                    state={{ item: item }}
                                  >
                                    View Details
                                  </Link>
                                  {/* <Link
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor: "transparent",
                                  fontWeight: "700",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                  margin: "10px",
                                }}
                                // onClick={() => window.location.assign("/Checkout")}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                Book Now
                              </Link> */}
                                </div>
                              </Col>
                            </Row>
                            <hr></hr>
                          </>
                        ))}
                  </>
                ) : (
                  <p>Coming soon....</p>
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
      <Modal show={show} onHide={handleClose} className="modal1111">
        <Modal.Header closeButton style={{ backgroundColor: "#f3f5f7" }}>
          <Modal.Title>Offers details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              border: "1px dotted gray",
              margin: "10px",
              overflow: "hidden",
            }}
          >
            <Row>
              <Col
                md={4}
                style={{
                  borderRight: "1px dotted gray",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "25px",
                    fontWeight: "bold",
                    marginTop: "100%",
                  }}
                >
                  Win Rs {cupondata?.amount} instant off code
                </p>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      border: "1px dotted gray",
                      padding: "10px",
                      marginBottom: "0px",
                      color: "#e22217",
                    }}
                  >
                    {cupondata?.Promocode}
                  </p>
                  <button
                    style={{ borderColor: "gray", padding: "10px" }}
                    onClick={() => handleCopy(cupondata?.Promocode)}
                  >
                    Copy
                  </button>
                </div>
              </Col>
              <Col md={8} style={{ padding: "2px" }}>
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  Terms & Conditions
                </p>
                <div style={{ textAlign: "justify", fontSize: "16px" }}>
                  {cupondata?.TandC ? parse(cupondata?.TandC) : ""}
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <NewFooter />
    </>
  );
}

export default Cityproduct;
