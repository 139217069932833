import axios from "axios";
import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CreateSubadmin() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [contactnum, setcontactnum] = useState();
  const [password, setpassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setPasswordVisible(!passwordVisible);
  };

  const [banner1, setbanner1] = useState(false);
  const [banner2, setbanner2] = useState(false);
  const [banner3, setbanner3] = useState(false);
  const [category, setcategory] = useState(false);
  const [Adminservice, setAdminservice] = useState(false);
  const [Advantages, setAdvantages] = useState(false);
  const [Promocode, setPromocode] = useState(false);
  const [TopBrands, setTopBrands] = useState(false);
  const [StarHotels, setStarHotels] = useState(false);
  const [MallandShopping, setMallandShopping] = useState(false);
  const [OffDutyTherapist, setOffDutyTherapist] = useState(false);
  const [Customers, setCustomers] = useState(false);
  const [Message, setMessage] = useState(false);

  //Spa
  const [Vendor, setVendor] = useState(false);
  const [editVendorRequest, seteditVendorRequest] = useState(false);
  const [AdminTherapist, setAdminTherapist] = useState(false);
  const [SpaDeleteTherapist, setSpaDeleteTherapist] = useState(false);
  const [service, setservice] = useState(false);
  const [Reviews, setReviews] = useState(false);
  const [Booking, setBooking] = useState(false);
  const [Vendorpercentage, setVendorpercentage] = useState(false);
  const [Accountdetails, setAccountdetails] = useState(false);
  const [DaywiseAccountdetails, setDaywiseAccountdetails] = useState(false);

  //Salon
  const [SalonVendor, setSalonVendor] = useState(false);
  const [SaloneditVendorRequest, setSaloneditVendorRequest] = useState(false);
  const [SalonAdminTherapist, setSalonAdminTherapist] = useState(false);
  const [SalonDeleteTherapist, setSalonDeleteTherapist] = useState(false);
  const [Salonservice, setSalonservice] = useState(false);
  const [SalonReviews, setSalonReviews] = useState(false);
  const [SalonBooking, setSalonBooking] = useState(false);
  const [SalonVendorpercentage, setSalonVendorpercentage] = useState(false);
  const [SalonAccountdetails, setSalonAccountdetails] = useState(false);
  const [SalonDaywiseAccountdetails, setSalonDaywiseAccountdetails] =
    useState(false);

  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.target;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    alert("You have entered an invalid email address!");
    return false;
  }

  function validatename(inputtxt) {
    var phoneno = /^[a-zA-Z]{2,30}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid name!");
      return false;
    }
  }

  function phonenumber(inputtxt) {
    var phoneno = /^[6-9]\d{9}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid mobile number!");
      return false;
    }
  }

  function CheckPassword(inputtxt) {
    var decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    if (inputtxt.match(decimal)) {
      return true;
    } else {
      alert(
        "Password must be 8 letter, contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character..!"
      );
      return false;
    }
  }

  const addMember = async (e) => {
    e.preventDefault();
    if (!name || !email || !password || !contactnum) {
      alert(
        "Sub-Admin Name, Email, Contact No. and Password are mandatory field"
      );
    } else if (
      banner1 ||
      category ||
      Adminservice ||
      Advantages ||
      Promocode ||
      TopBrands ||
      StarHotels ||
      MallandShopping ||
      OffDutyTherapist ||
      Customers ||
      Message ||
      //Spa
      Vendor ||
      editVendorRequest ||
      AdminTherapist ||
      SpaDeleteTherapist ||
      service ||
      Reviews ||
      Booking ||
      Vendorpercentage ||
      Accountdetails ||
      DaywiseAccountdetails ||
      //Salon
      SalonVendor ||
      SaloneditVendorRequest ||
      SalonAdminTherapist ||
      SalonDeleteTherapist ||
      Salonservice ||
      SalonReviews ||
      SalonBooking ||
      SalonVendorpercentage ||
      SalonAccountdetails ||
      SalonDaywiseAccountdetails
    ) {
      try {
        if (
          validatename(name) &&
          ValidateEmail(email) &&
          phonenumber(contactnum) &&
          CheckPassword(password)
        ) {
          const config = {
            url: "/addsubadmin",
            method: "post",
            baseURL: "https://spazo.co.in/api/admin",
            data: {
              role: "Sub-Admin",
              name: name,
              email: email,
              password: password,
              contactnum: contactnum,
              banner1: banner1,
              // banner2: banner2,
              // banner3: banner3,
              category: category,
              Adminservice: Adminservice,
              Advantages: Advantages,
              Promocode: Promocode,
              TopBrands: TopBrands,
              StarHotels: StarHotels,
              MallandShopping: MallandShopping,
              OffDutyTherapist: OffDutyTherapist,
              Customers: Customers,
              Message: Message,

              //Spa
              Vendor: Vendor,
              editVendorRequest: editVendorRequest,
              AdminTherapist: AdminTherapist,
              SpaDeleteTherapist: SpaDeleteTherapist,
              service: service,
              Reviews: Reviews,
              Booking: Booking,
              Vendorpercentage: Vendorpercentage,
              Accountdetails: Accountdetails,
              DaywiseAccountdetails: DaywiseAccountdetails,
              //salon
              SalonVendor: SalonVendor,
              SaloneditVendorRequest: SaloneditVendorRequest,
              SalonAdminTherapist: SalonAdminTherapist,
              SalonDeleteTherapist: SalonDeleteTherapist,
              Salonservice: Salonservice,
              SalonReviews: SalonReviews,
              SalonBooking: SalonBooking,
              SalonVendorpercentage: SalonVendorpercentage,
              SalonAccountdetails: SalonAccountdetails,
              SalonDaywiseAccountdetails: SalonDaywiseAccountdetails,
            },
          };
          await axios(config).then(function (res) {
            if ((res.status = 200)) {
              console.log("success");
              notify("Data Added successfully");
              // alert("Details Added");
              window.location.reload();
            }
          });
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          notify1(error.response.data.error);
          // alert(error.response.data.error);
        }
      }
    } else {
      alert("Please select the access details");
    }
  };

  return (
    <div style={{ paddingLeft: "30px" }}>
      <h4>Sub-Admin Creation</h4>

      <ToastContainer
        position="top-center"
        autoClose={30000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container>
        <div className="addsubadmin">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="addsubadmin1">
              <div>
                <Row className="mt-2">
                  <Col md={6}>
                    <label style={{ fontSize: "16px", color: "black" }}>
                      Name <span style={{ color: "red" }}>*</span>
                    </label>
                  </Col>
                  <Col md={6}>
                    <input
                      placeholder="Name"
                      onChange={(e) => setname(e.target.value)}
                      style={{
                        border: "1px solid",
                        padding: "2px",
                        borderRadius: "5px",
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={6}>
                    <label style={{ fontSize: "16px", color: "black" }}>
                      Email<span style={{ color: "red" }}>*</span>
                    </label>
                  </Col>
                  <Col md={6}>
                    <input
                      placeholder="Email ID"
                      onChange={(e) => setemail(e.target.value)}
                      style={{
                        border: "1px solid",
                        padding: "2px",
                        borderRadius: "5px",
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={6}>
                    <label style={{ fontSize: "16px", color: "black" }}>
                      Contact No.<span style={{ color: "red" }}>*</span>
                    </label>
                  </Col>
                  <Col md={6}>
                    {" "}
                    <input
                      placeholder="Contact No."
                      maxlength="10"
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => setcontactnum(e.target.value)}
                      style={{
                        border: "1px solid",
                        padding: "2px",
                        borderRadius: "5px",
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={6}>
                    <label style={{ fontSize: "16px", color: "black" }}>
                      Password<span style={{ color: "red" }}>*</span>
                    </label>
                  </Col>
                  <Col md={6}>
                    <div style={{ display: "flex" }}>
                      <input
                        type={passwordVisible ? "text" : "password"}
                        placeholder="Password"
                        onChange={(e) => setpassword(e.target.value)}
                        style={{
                          border: "1px solid",
                          padding: "2px",
                          borderRadius: "5px",
                        }}
                      />
                      {passwordVisible ? (
                        <button
                          style={{
                            border: "1px solid black",
                            borderLeft: "none",
                            paddingRight: "9px",
                            borderRadius: "0px 5px 5px 0px",
                            marginLeft: "-23px",
                            backgroundColor: "white",
                          }}
                          onClick={(e) => togglePasswordVisibility(e)}
                        >
                          <i
                            class="fa fa-eye"
                            aria-hidden="true"
                            style={{ color: "#e22217" }}
                          ></i>
                        </button>
                      ) : (
                        <button
                          style={{
                            border: "1px solid black",
                            borderLeft: "none",
                            paddingRight: "9px",
                            borderRadius: "0px 5px 5px 0px",
                            marginLeft: "-23px",
                            backgroundColor: "white",
                          }}
                          onClick={(e) => togglePasswordVisibility(e)}
                        >
                          <i
                            class="fa fa-eye-slash"
                            aria-hidden="true"
                            style={{ color: "#e22217" }}
                          ></i>
                        </button>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
          <div className="addsubadmincheckbox">
            <div>
              <input type="checkbox" onChange={() => setbanner1(!banner1)} />
              <label style={{ color: "black" }}> &nbsp;Banner</label>
              <br></br>

              <input type="checkbox" onChange={() => setcategory(!category)} />
              <label style={{ color: "black" }}> &nbsp;Category</label>
              <br></br>

              <input
                type="checkbox"
                onChange={() => setAdminservice(!Adminservice)}
              />
              <label style={{ color: "black" }}> &nbsp;Adminservice</label>
              <br></br>
              <input
                type="checkbox"
                onChange={() => setAdvantages(!Advantages)}
              />
              <label style={{ color: "black" }}>&nbsp;Advantages</label>
              <br></br>
              <input
                type="checkbox"
                onChange={() => setPromocode(!Promocode)}
              />
              <label style={{ color: "black" }}>&nbsp;Promocode</label>
              <br></br>
              <input
                type="checkbox"
                onChange={() => setTopBrands(!TopBrands)}
              />
              <label style={{ color: "black" }}> &nbsp;Top Brands</label>
              <br></br>
            </div>
            <div>
              <input
                type="checkbox"
                onChange={() => setStarHotels(!StarHotels)}
              />
              <label style={{ color: "black" }}> &nbsp;Star Hotels</label>
              <br></br>
              <input
                type="checkbox"
                onChange={() => setMallandShopping(!MallandShopping)}
              />
              <label style={{ color: "black" }}> &nbsp;Mall and Shopping</label>
              <br></br>
              <input
                type="checkbox"
                onChange={() => setOffDutyTherapist(!OffDutyTherapist)}
              />
              <label style={{ color: "black" }}>&nbsp;Off Duty Therapist</label>
              <br></br>
              <input
                type="checkbox"
                onChange={() => setCustomers(!Customers)}
              />
              <label style={{ color: "black" }}> &nbsp;Customers</label>
              <br></br>
              <input type="checkbox" onChange={() => setMessage(!Message)} />
              <label style={{ color: "black" }}> &nbsp;Message</label>
              <br></br>
            </div>
          </div>
          <hr></hr>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              color: "#e22217",
              marginLeft: "10%",
            }}
          >
            Spa :
          </p>
          <div className="addsubadmincheckbox">
            <div>
              <input type="checkbox" onChange={() => setVendor(!Vendor)} />
              <label style={{ color: "black" }}> &nbsp;Vendor</label>
              <br></br>
              <input
                type="checkbox"
                onChange={() => seteditVendorRequest(!editVendorRequest)}
              />
              <label style={{ color: "black" }}>
                &nbsp;Edit Vendor Request
              </label>
              <br></br>
              <input
                type="checkbox"
                onChange={() => setAdminTherapist(!AdminTherapist)}
              />
              <label style={{ color: "black" }}>&nbsp;Therapist</label>
              <br></br>
              <input
                type="checkbox"
                onChange={() => setSpaDeleteTherapist(!SpaDeleteTherapist)}
              />
              <label style={{ color: "black" }}>&nbsp;Delete Therapist</label>
              <br></br>
              <input type="checkbox" onChange={() => setservice(!service)} />
              <label style={{ color: "black" }}> &nbsp;Vendor-Service</label>
              <br></br>
            </div>
            <div>
              <input type="checkbox" onChange={() => setReviews(!Reviews)} />
              <label style={{ color: "black" }}> &nbsp;Reviews</label>
              <br></br>
              <input type="checkbox" onChange={() => setBooking(!Booking)} />
              <label style={{ color: "black" }}> &nbsp;Booking</label>
              <br></br>
              <input
                type="checkbox"
                onChange={() => setVendorpercentage(!Vendorpercentage)}
              />
              <label style={{ color: "black" }}>&nbsp;Vendor %</label>
              <br></br>
              <input
                type="checkbox"
                onChange={() => setAccountdetails(!Accountdetails)}
              />
              <label style={{ color: "black" }}>&nbsp;Account details</label>
              <br></br>
              <input
                type="checkbox"
                onChange={() =>
                  setDaywiseAccountdetails(!DaywiseAccountdetails)
                }
              />
              <label style={{ color: "black" }}>
                &nbsp;Daywise account details
              </label>
              <br></br>
            </div>
          </div>
          <hr></hr>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              color: "#e22217",
              marginLeft: "10%",
            }}
          >
            Salon :
          </p>
          <div className="addsubadmincheckbox">
            <div>
              <input
                type="checkbox"
                onChange={() => setSalonVendor(!SalonVendor)}
              />
              <label style={{ color: "black" }}> &nbsp;Vendor</label>
              <br></br>
              <input
                type="checkbox"
                onChange={() =>
                  setSaloneditVendorRequest(!SaloneditVendorRequest)
                }
              />
              <label style={{ color: "black" }}>
                &nbsp;Edit Vendor Request
              </label>
              <br></br>
              <input
                type="checkbox"
                onChange={() => setSalonAdminTherapist(!SalonAdminTherapist)}
              />
              <label style={{ color: "black" }}>&nbsp;Therapist</label>
              <br></br>
              <input
                type="checkbox"
                onChange={() => setSalonDeleteTherapist(!SalonDeleteTherapist)}
              />
              <label style={{ color: "black" }}>&nbsp;Delete Therapist</label>
              <br></br>
              <input
                type="checkbox"
                onChange={() => setSalonservice(!Salonservice)}
              />
              <label style={{ color: "black" }}> &nbsp;Vendor-Service</label>
              <br></br>
              <input
                type="checkbox"
                onChange={() => setSalonReviews(!SalonReviews)}
              />
              <label style={{ color: "black" }}> &nbsp;Reviews</label>
              <br></br>
            </div>
            <div>
              <input
                type="checkbox"
                onChange={() => setSalonBooking(!SalonBooking)}
              />
              <label style={{ color: "black" }}> &nbsp;Booking</label>
              <br></br>
              <input
                type="checkbox"
                onChange={() =>
                  setSalonVendorpercentage(!SalonVendorpercentage)
                }
              />
              <label style={{ color: "black" }}>&nbsp;Vendor %</label>
              <br></br>
              <input
                type="checkbox"
                onChange={() => setSalonAccountdetails(!SalonAccountdetails)}
              />
              <label style={{ color: "black" }}>&nbsp;Account details</label>
              <br></br>
              <input
                type="checkbox"
                onChange={() =>
                  setSalonDaywiseAccountdetails(!SalonDaywiseAccountdetails)
                }
              />
              <label style={{ color: "black" }}>
                &nbsp;Daywise account details
              </label>
              <br></br>
            </div>
          </div>
          <div className="addsubadminbtn">
            <button
              onClick={(e) => addMember(e)}
              className="admindelbtn"
              style={{
                padding: "8px 20px",
                fontSize: "15px",
                border: "none",
                backgroundColor: "#e22217",
                color: "white",
                borderRadius: "3px",
                fontWeight: "500",
              }}
            >
              Add
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default CreateSubadmin;
