import React from "react";
import "../Styles/topvendor.css";
import OwlCarousel from "react-owl-carousel";
import Spajson from "./Spa";
import { useState } from "react";
import { AiFillStar } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import axios from "axios";
import { useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import { Link } from "react-router-dom";

function TopVendor() {
  const responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 4,
    },
  };

  const [product, setproduct] = useState(Spajson);
  const [vendors, setvendors] = useState([]);

  const reviews = vendors?.filter((item) => {
    const sjdfhs = item?.reviews?.reduce((a, data) => a + data.rating, 0);
    const asds = sjdfhs / item?.reviews?.length;
    const test = asds >= 4;
    return test;
  });

  useEffect(() => {
    getallVendor();
  }, []);

  const getallVendor = () => {
    axios
      .get("https://spazo.co.in/api/vendor/allVendors")
      .then(function (response) {
        // console.log(response.data);
        setvendors(
          response.data.allVendors?.filter(
            (item) => item.approved === "Approved"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <section>
        <div
          className="container-fluid"
          data-aos="fade-up"
          data-aos-delay="800"
          data-aos-duration="3000"
        >
          <div className="item-d">
            <div className="de-top">
              <h4>Top Rated Spas In Your City</h4>
            </div>
            <div className="de-top-1">
              <a href="/product">
                <h6>View All offers</h6>
              </a>
            </div>
          </div>
          <div className="spa-ve">
            <div className="default-spa">
              <OwlCarousel
                className="owl-theme"
                // loop
                margin={15}
                items={4}
                nav={false}
                dots={false}
                responsive={responsive}
              >
                {reviews?.map((item, index) => {
                  return (
                    <div class="item mt-3 mb-3">
                      <Link to="/product-details" state={{ dis: item }}>
                        <div className="massage-0">
                          <div className="customer">
                            <img
                              src={
                                "https://spazo.co.in/Vendor/" + item?.spaimage1
                              }
                              alt="spa-images"
                            />
                            <div className="cust-ti pt-2">
                              <h6>{item.businessName}</h6>
                              <div
                                className="location"
                                style={{ color: "black" }}
                              >
                                <span>
                                  <GoLocation />
                                </span>{" "}
                                {item.address}
                              </div>
                              {/* <div className="massage-type">{item.vendor}</div> */}
                              <div className="tit-p">
                                <div
                                  className="rating"
                                  style={{
                                    color: "#FF9529",
                                    backgroundColor: " white",

                                    position: "absolute",
                                    top: " 53%",
                                    border: "1px solid white",
                                  }}
                                >
                                  <ReactStars
                                    count={5}
                                    value={
                                      item?.reviews?.reduce(
                                        (a, data) => a + data.rating,
                                        0
                                      ) / item?.reviews?.length
                                    }
                                    size={20}
                                    activeColor="#ffd700"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TopVendor;
