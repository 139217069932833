import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ReactStars from "react-rating-stars-component";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { FaRegEdit } from "react-icons/fa";
import { AiFillCheckCircle, AiFillStar } from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { Col, Row } from "react-bootstrap";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AdminTherapist() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);

  const [data, setdata] = useState([]);
  const [arraylength, setarraylength] = useState([]);
  const [AllEmployee, setAllEmployee] = useState([]);
  const [reasonFR, setreasonFR] = useState("");

  const [show, setShow] = useState(false);

  const [approvedata, setapprovedata] = useState({});
  const [rejectdata, setrejectdata] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setShow(true);
    setapprovedata(item);
    handleClose2();
  };

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setrejectdata(item);
    handleClose2();
  };

  const [show2, setShow2] = useState(false);
  const [view, setview] = useState({});

  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setview(item);
  };

  const [show3, setShow3] = useState(false);
  const [viewReason, setviewReason] = useState({});

  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setviewReason(item);
  };

  const [showrevive, setShowrevive] = useState(false);
  const [reviwe, setreviwe] = useState({});

  const handleCloserevive = () => setShowrevive(false);
  const handleShowrevive = (item) => {
    setShowrevive(true);
    setreviwe(item);
  };

  const [showduty, setShowduty] = useState(false);
  const [dutydata, setdutydata] = useState(false);

  const handleCloseduty = () => setShowduty(false);
  const handleShowduty = (item) => {
    setShowduty(true);
    setdutydata(item);
  };

  useEffect(() => {
    getAllEmployee();
  }, []);

  const getAllEmployee = () => {
    axios
      .get("https://spazo.co.in/api/Vendor/getAllEmployee")
      .then(function (response) {
        console.log("employee", response.data);
        setarraylength(response.data.Employee?.filter((item) => item?.EmpId));
        setdata(
          response.data.Employee?.filter(
            (item) => item?.vendor[item.vendor?.length - 1]?.Type === "Spa"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const approveEmployee = async (data1) => {
    try {
      const config = {
        url: "/approveEmployee",
        method: "post",
        baseURL: "https://spazo.co.in/api/Vendor",
        data: {
          EmpId: data1?.EmpId
            ? data1?.EmpId
            : "SPAZO" + " " + (arraylength?.length + 1),
          id: data1?.id,
        },
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          notify("Therapist Approved successfully");
          // alert("Therapist Rejected Successfully");
          // window.location.reload();
          getAllEmployee();
          handleClose();
        } else {
          notify1(res.status.error);
          // alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      notify1("Unable to Approve Details, Please try after some time");
      // alert("Unable to add Details");
    }
  };

  console.log("approvedata", approvedata);

  const rejectEmployee = async (data) => {
    if (!reasonFR) {
      alert("Please give the reason for rejection");
    } else {
      try {
        const config = {
          url: "/rejectEmployee",
          method: "post",
          baseURL: "https://spazo.co.in/api/Vendor",
          data: {
            reasonFR: reasonFR,
            id: data?.id,
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            notify("Therapist Rejected Successfully");
            // alert("Therapist Rejected Successfully");
            // window.location.reload();
            getAllEmployee();
            handleClose1();
          } else {
            notify1(res.status.error);
            // alert(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        notify1("Unable to Reject Details, Please try after some time");
        // alert("Unable to add Details");
      }
    }
  };

  const newdata = [];

  for (let i = 0; i < data?.length; i++) {
    newdata.push({
      id: data[i]._id,
      profile: data[i].profile,
      adharF: data[i].adharF,
      adharB: data[i].adharB,
      PanF: data[i].PanF,
      PanB: data[i].PanB,
      VendorId: data[i].VendorId,
      EmpId: data[i].EmpId,
      name: data[i].name,
      age: data[i].age,
      Email: data[i].Email,
      contactnumber: data[i].contactnumber,
      Specialization: data[i].Specialization,
      Gender: data[i].Gender,
      Status: data[i].Status,
      reasonFR: data[i].reasonFR,
      vailability: data[i].vailability,
      VendorName: data[i].vendor[data[i].vendor?.length - 1].Vendor_Name,
      VendorBusinessName: data[i].vendor[data[i].vendor?.length - 1].Spa_Name,
      VendorContactNumber:
        data[i].vendor[data[i].vendor?.length - 1].Spa_Contact_Number,
      VendorEmailId: data[i].vendor[data[i].vendor?.length - 1].SPa_Email_ID,
      therapistreviews: data[i].therapistreviews,
      AdditionalImageArray: data[i]?.AdditionalImageArray,
      Duties: data[i]?.Duties,
    });
  }

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "3",
        value: 3,
      },
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: newdata.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },

    {
      dataField: "Image",
      text: "Emp Profile",
      formatter: (value, row) => (
        <img
          src={"https://spazo.co.in/Employee/" + row?.profile}
          width={100}
          height={80}
          onClick={() =>
            window.open("https://spazo.co.in/Employee/" + row.profile)
          }
        />
      ),
    },
    {
      dataField: "EmpId",
      text: "EmpId",
      formatter: (value, row) => <p>{row?.EmpId ? row?.EmpId : "-"}</p>,
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (value, row) => {
        const rev = row?.therapistreviews?.reduce((a, b) => a + b.rating, 0);
        return (
          <>
            <p>{row?.name ? row?.name : "-"}</p>
            <div style={{ display: "flex" }}>
              <AiFillStar color="Gold" style={{ marginTop: "7px" }} /> :
              <p style={{ marginBottom: "0px", fontWeight: "bold" }}>
                {rev?.toFixed(1)}
              </p>
            </div>
            <button
              style={{ border: "none" }}
              onClick={() => handleShowrevive(row)}
            >
              <i
                class="fa fa-eye"
                aria-hidden="true"
                style={{ color: "#e22217" }}
              ></i>
            </button>
          </>
        );
      },
    },
    {
      dataField: "age",
      text: "Age",
      formatter: (value, row) => <p>{row?.age ? row?.age : "-"}</p>,
    },
    {
      dataField: "Email",
      text: "Email",
      formatter: (value, row) => {
        return <p>{row?.Email ? row?.Email : "-"}</p>;
      },
    },
    {
      dataField: "contactnumber",
      text: "Contact Number",
      formatter: (value, row) => (
        <p>{row?.contactnumber ? row?.contactnumber : "-"}</p>
      ),
    },
    {
      dataField: "Specialization",
      text: "Specialization",
      formatter: (value, row) => (
        <p>{row?.Specialization ? row?.Specialization : "-"}</p>
      ),
    },
    {
      dataField: "Gender",
      text: "Gender",
      formatter: (value, row) => <p>{row?.Gender ? row?.Gender : "-"}</p>,
    },
    {
      dataField: "adharF",
      text: "Aadhaar Card",
      formatter: (value, row) => (
        <>
          <div style={{ gap: "10px", display: "flex" }}>
            <img
              src={"https://spazo.co.in/Employee/" + row?.adharF}
              width={100}
              height={80}
              onClick={() =>
                window.open("https://spazo.co.in/Employee/" + row.adharF)
              }
            />
            <img
              src={"https://spazo.co.in/Employee/" + row?.adharB}
              width={100}
              height={80}
              onClick={() =>
                window.open("https://spazo.co.in/Employee/" + row.adharB)
              }
            />
          </div>
        </>
      ),
    },
    {
      dataField: "PanF",
      text: "Pan Card",
      formatter: (value, row) => (
        <>
          <div style={{ gap: "10px", display: "flex" }}>
            <img
              src={"https://spazo.co.in/Employee/" + row?.PanF}
              width={100}
              height={80}
              onClick={() =>
                window.open("https://spazo.co.in/Employee/" + row.PanF)
              }
            />
            <img
              src={"https://spazo.co.in/Employee/" + row?.PanB}
              width={100}
              height={80}
              onClick={() =>
                window.open("https://spazo.co.in/Employee/" + row.PanB)
              }
            />
          </div>
        </>
      ),
    },
    {
      dataField: "",
      text: "Additional Images",
      formatter: (value, row) => (
        <>
          <div style={{ gap: "10px", display: "flex" }}>
            {row?.AdditionalImageArray?.length > 0
              ? row?.AdditionalImageArray?.map((item) => (
                  <img
                    src={
                      "https://spazo.co.in/Employee/" + item?.AdditionalImage
                    }
                    width={100}
                    height={80}
                    onClick={() =>
                      window.open(
                        "https://spazo.co.in/Employee/" + item.AdditionalImage
                      )
                    }
                  />
                ))
              : "--"}
          </div>
        </>
      ),
    },
    {
      dataField: "VendorBusinessName",
      text: "Spa Name",
      formatter: (value, row) => (
        <p>{row?.VendorBusinessName ? row?.VendorBusinessName : "-"}</p>
      ),
    },
    {
      dataField: "VendorName",
      text: "Vendor Name",
      formatter: (value, row) => (
        <p>{row?.VendorName ? row?.VendorName : "-"}</p>
      ),
    },
    {
      dataField: "VendorContactNumber",
      text: "Spa Contact Number",
      formatter: (value, row) => (
        <p>{row?.VendorContactNumber ? row?.VendorContactNumber : "-"}</p>
      ),
    },
    {
      dataField: "VendorEmailId",
      text: "Spa Email ID",
      formatter: (value, row) => (
        <p>{row?.VendorEmailId ? row?.VendorEmailId : "-"}</p>
      ),
    },
    {
      dataField: "Status",
      text: "View",
      formatter: (value, row) => (
        <>
          {row?.Status === "Approved" ? (
            <button style={{ border: "none" }} onClick={() => handleShow2(row)}>
              <i
                class="fa fa-eye"
                aria-hidden="true"
                style={{ color: "#e22217" }}
              ></i>
            </button>
          ) : row?.Status === "Pending" ? (
            "-"
          ) : (
            <p
              onClick={() => handleShow3(row)}
              style={{ color: "red", fontWeight: "bold" }}
            >
              Reason
            </p>
          )}
        </>
      ),
    },
    {
      dataField: "",
      text: "Duty",
      formatter: (value, row) => (
        <>
          <button
            style={{ border: "none", marginLeft: "25px" }}
            onClick={() => handleShowduty(row)}
          >
            <i
              class="fa fa-eye"
              aria-hidden="true"
              style={{
                color: "#e22217",
                backgroundColor: "transparent",
                alignSelf: "center",
              }}
            ></i>
          </button>
        </>
      ),
    },
    {
      dataField: "-",
      text: "Action",
      formatter: (value, row) => (
        <>
          {row?.Status === "Pending" ? (
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <span className="dite">
                <AiFillCheckCircle onClick={() => handleShow(row)} />
              </span>
              /
              <span className="dite">
                <AiFillCloseCircle onClick={() => handleShow1(row)} />
              </span>
            </div>
          ) : row?.Status === "Approved" ? (
            <span
              style={{
                border: "1px solid green",
                backgroundColor: "green",
                borderRadius: "50%",
                height: "35px",
                width: "35px",
                fontSize: "20px",
                color: "white",
                textAlign: "center",
                display: "block",
              }}
            >
              <AiFillCheckCircle />
            </span>
          ) : (
            <>
              <span
                style={{
                  border: "1px solid red",
                  backgroundColor: "red",
                  borderRadius: "50%",
                  height: "35px",
                  width: "35px",
                  fontSize: "20px",
                  color: "white",
                  textAlign: "center",
                  display: "block",
                }}
              >
                <AiFillCloseCircle onClick={() => handleShow(row)} />
              </span>
            </>
          )}
        </>
      ),
    },
  ];

  console.log("newdata", newdata);

  return (
    <>
      <div className="never-sspa">
        <section>
          <div className="spa-admin-0 mb-2">
            <div className="diff-admin px-3">
              <div className="admin-name-text">
                <h4>All Therapist Details</h4>
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={30000}
            closeOnClick
            pauseOnHover
            draggable
          />
          <div
            style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}
          >
            <ToolkitProvider
              keyField="id"
              data={newdata}
              columns={columns}
              // rowClasses={rowClasses}
              search
              exportCSV
            >
              {(props) => (
                <div>
                  <SearchBar {...props.searchProps} />
                  <ExportCSVButton
                    {...props.csvProps}
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                    }}
                  >
                    Download Excel
                  </ExportCSVButton>

                  <hr></hr>
                  <br></br>

                  <div style={{ overflowX: "scroll", width: "100%" }}>
                    <BootstrapTable
                      striped
                      hover
                      condensed
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </section>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ alignItems: "center", display: "contents" }}>
          <p style={{ textAlign: "center" }}>
            Are you sure you want to approve the Therapist{" "}
          </p>
          <Button
            variant="secondary"
            onClick={() => approveEmployee(approvedata)}
          >
            Yes
          </Button>
        </Modal.Body>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ alignItems: "center", display: "contents" }}>
          <p style={{ textAlign: "center" }}>
            If you want to reject the Therapist, please give the reason for
            rejection
          </p>
          <textarea
            style={{ margin: "10px" }}
            onChange={(e) => setreasonFR(e.target.value)}
          />
          <Button variant="primary" onClick={() => rejectEmployee(rejectdata)}>
            Yes
          </Button>
        </Modal.Body>
      </Modal>

      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton style={{ color: "#e22217" }}>
          Reason For Rejection
        </Modal.Header>
        <Modal.Body style={{ alignItems: "center", display: "contents" }}>
          <p style={{ textAlign: "center" }}>{viewReason?.reasonFR}</p>
          <Button variant="secondary" onClick={handleClose3}>
            Ok
          </Button>
        </Modal.Body>
      </Modal>

      <Modal show={show2} onHide={handleClose2} className="servicemodal1">
        <Modal.Header
          closeButton
          style={{ color: "#e22217", fontSize: "20px" }}
        >
          Therapist Details
        </Modal.Header>
        <Modal.Body style={{ alignItems: "center", display: "contents" }}>
          <div style={{ margin: "20px" }}>
            <Row>
              <Col md={6}>
                <span style={{ fontWeight: "bold" }}>Name :</span> {view?.name}
              </Col>
              <Col md={6}>
                {" "}
                <span style={{ fontWeight: "bold" }}>Employee Id :</span>{" "}
                {view?.EmpId}
              </Col>
            </Row>

            <Row>
              <table style={{ margin: "5px" }}>
                <thead style={{ backgroundColor: "#e22217", color: "white" }}>
                  <th className="tablexxxx">date1</th>
                  <th className="tablexxxx">Working-Status</th>
                  {/* <th className="tablexxxx">From_Time</th>
                  <th className="tablexxxx">To_Time</th> */}
                  <th className="tablexxxx">Booking-Status</th>
                  <th className="tablexxxx">Reason</th>
                </thead>
                <tbody>
                  {view?.vailability?.map((item) => (
                    <>
                      <tr
                        style={{
                          color:
                            item?.Availability === "Not" ? "#e22217" : "Green",
                        }}
                      >
                        <td className="tablexxxx">
                          {moment(item?.date1).format("DD/MM/YYYY")}
                        </td>
                        <td className="tablexxxx">
                          {item?.Availability === "Not" ? (
                            <>
                              &nbsp; <i class="fas fa-times"></i>
                            </>
                          ) : (
                            <>
                              &nbsp; <i class="fas fa-check"></i>
                            </>
                          )}
                        </td>
                        {/* <td className="tablexxxx">
                              {item?.From_Time ? item?.From_Time : "-"}
                            </td>
                            <td className="tablexxxx">
                              {item?.To_Time ? item?.To_Time : "-"}
                            </td> */}
                        <td
                          className="tablexxxx"
                          style={{
                            color:
                              item?.Availability === "Available"
                                ? "Green"
                                : "rgb(226, 34, 23)",
                          }}
                        >
                          {item?.Availability
                            ? item?.Availability
                            : "Not-Available"}
                        </td>
                        <td className="tablexxxx">
                          {item?.Reason ? item?.Reason : "-"}
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showrevive} onHide={handleCloserevive}>
        <Modal.Header closeButton>
          <Modal.Title> Therapist Reviews - {reviwe?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {reviwe?.therapistreviews?.map((rev) => (
              <>
                <div
                  style={{
                    padding: "10px",
                    borderBottom: "1px solid gray",
                  }}
                >
                  <h6>{rev?.Username}</h6>
                  <p style={{ marginBottom: "0px" }}>{rev?.review}</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <ReactStars
                      count={5}
                      value={rev?.rating}
                      size={20}
                      activeColor="#ffd700"
                    />
                    <p
                      style={{
                        textAlign: "right",
                        color: "#e84141",
                        fontWeight: 600,
                      }}
                    >
                      {moment(rev?.createdAt).format("DD-MM-YYYY")}
                    </p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloserevive}>Ok</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showduty} onHide={handleCloseduty} className="servicemodal1">
        <Modal.Header
          closeButton
          style={{ color: "#e22217", fontSize: "20px" }}
        >
          Therapist Duty Details
        </Modal.Header>
        <Modal.Body style={{ alignItems: "center", display: "contents" }}>
          <div style={{ margin: "20px" }}>
            <Row>
              <Col md={6}>
                <span style={{ fontWeight: "bold" }}>Name :</span>{" "}
                {dutydata?.name}
              </Col>
              <Col md={6}>
                {" "}
                <span style={{ fontWeight: "bold" }}>Employee Id :</span>{" "}
                {dutydata?.EmpId}
              </Col>
            </Row>

            <Row>
              <table style={{ margin: "5px" }}>
                <thead style={{ backgroundColor: "#e22217", color: "white" }}>
                  <th className="tablexxxx">Date / Time</th>
                  <th className="tablexxxx">Working Status</th>
                </thead>
                <tbody>
                  {dutydata?.Duties?.map((item) => (
                    <>
                      <tr>
                        <td className="tablexxxx">{item?.workingDate}</td>
                        <td className="tablexxxx">
                          {item?.Duty === true ? "On" : "Off"}
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseduty}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminTherapist;
