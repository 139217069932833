const Spajson = [
  {
    id: 1,
    image: "../../Assets/images/ve-spa1.webp",
    title: "Body Raaga Wellness Spa",
    location: "Whitefield",
    vendor: "Massage Offer",
    price: 3700,
    discountprice: 1499,
    discount: "59%",
  },
  {
    id: 2,
    image: "../../Assets/images/va-spa2.webp",
    title: "Body Raaga Wellness Spa",
    location: "Whitefield",
    vendor: "Massage Offer",
    price: 3700,
    discountprice: 1499,
    discount: "59%",
  },
  {
    id: 3,
    image: "../../Assets/images/va-spa3.webp",
    title: "Body Raaga Wellness Spa",
    location: "Whitefield",
    vendor: "Massage Offer",
    price: 3700,
    discountprice: 1499,
    discount: "59%",
  },
  {
    id: 4,
    image: "../../Assets/images/va-spa4.webp",
    title: "Body Raaga Wellness Spa",
    location: "Whitefield",
    vendor: "Massage Offer",
    price: 3700,
    discountprice: 1499,
    discount: "59%",
  },
  {
    id: 5,
    image: "../../Assets/images/va-spa5.webp",
    title: "Body Raaga Wellness Spa",
    location: "Whitefield",
    vendor: "Massage Offer",
    price: 3700,
    discountprice: 1499,
    discount: "59%",
  },
  {
    id: 2,
    image: "../../Assets/images/va-spa2.webp",
    title: "Body Raaga Wellness Spa",
    location: "Whitefield",
    vendor: "Massage Offer",
    price: 3700,
    discountprice: 1499,
    discount: "59%",
  },
];

export default Spajson;
