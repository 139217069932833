import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { FaAddressBook, FaRegEdit } from "react-icons/fa";
import { AiFillCheckCircle, AiFillStar } from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { Col, Row } from "react-bootstrap";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditVendorRequest() {
  const formdata = new FormData();
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);

  const [show, setShow] = useState(false);
  const [approvedata, setapprovedata] = useState({});
  const [rejectdata, setrejectdata] = useState({});

  const [Employee, setEmployee] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setShow(true);
    setapprovedata(item);
    handleClose2();
  };

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setrejectdata(item);
    handleClose2();
  };

  const [show2, setShow2] = useState(false);
  const [view, setview] = useState({});

  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setview(item);
  };

  const [data, setdata] = useState([]);
  const [reasonFR, setreasonFR] = useState("");

  console.log("data", data);

  useEffect(() => {
    getprofileupdaterequest();
    getEmployee();
  }, []);

  const getprofileupdaterequest = () => {
    axios
      .get("https://spazo.co.in/api/Vendor/getprofileupdaterequest")
      .then(function (response) {
        console.log(response.data);
        setdata(
          response.data.profile?.filter(
            (item) => item.Editapproved === "Pending" && item?.type === "Spa"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const ApproveVendor = async (data) => {
    try {
      const config = {
        url: "/approveEditVendor/" + data?._id,
        method: "post",
        baseURL: "https://spazo.co.in/api/vendor",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          console.log("res.status.data", res.data);
          VendorProfileupdate(res.data);
          notify("Vendor Approved successfully");
          // alert("Vendor Approved successfully");
          // window.location.reload();
          getprofileupdaterequest();
          getEmployee();
          handleClose();
        } else {
          notify1(res.status.error);
          // alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      notify1("Unable to Approve Details, Please try after some time");
      // alert("Unable to add Details");
    }
  };

  const RejectEditVendor = async (data) => {
    if (!reasonFR) {
      notify1("Please give the reason for rejection");
      // alert("Please give the reason for rejection");
    } else {
      try {
        const config = {
          url: "/rejectEditVendor",
          method: "post",
          baseURL: "https://spazo.co.in/api/vendor",
          data: {
            reasonFR: reasonFR,
            id: data?._id,
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            notify("Vendor Rejected Successfully");
            // alert("Vendor Rejected Successfully");
            // window.location.reload();
            getprofileupdaterequest();
            getEmployee();
            handleClose1();
          } else {
            notify1(res.status.error);
            // alert(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        notify1("Unable to Rejecte Details, Please try after some time");
        // alert("Unable to add Details");
      }
    }
  };

  const VendorProfileupdate = async (data) => {
    const originalObject = data;
    const extractedData = originalObject.data;
    console.log(
      "newDataObject",
      extractedData.AdditionalImageArray.concat(view?.AdditionalImageArray)
    );
    try {
      const config = {
        url: "/VendorProfileupdate",
        method: "post",
        baseURL: "https://spazo.co.in/api/vendor",
        data: {
          VendorID: extractedData.VendorID,
          businessName: extractedData.businessName,
          name: extractedData.name,
          email: extractedData.email,
          phoneNumber: extractedData.phoneNumber,
          address: extractedData.address,
          pincode: extractedData.pincode,
          Glink: extractedData.Glink,
          shoptype: extractedData.shoptype,
          Adhar: extractedData.Adhar,
          Pan: extractedData.Pan,
          passbook: extractedData.passbook,
          gst: extractedData.gst,
          spa: extractedData.spa,
          other: extractedData.other,
          sun: extractedData.sun,
          mon: extractedData.mon,
          tue: extractedData.tue,
          wed: extractedData.wed,
          turs: extractedData.turs,
          frid: extractedData.frid,
          sat: extractedData.sat,
          formattedStartTime: extractedData.formattedStartTime,
          formattedEndTime: extractedData.formattedEndTime,
          parking: extractedData.parking,
          about: extractedData.about,
          profile: extractedData.profile,
          logo: extractedData.logo,
          spaimage1: extractedData.spaimage1,
          spaimage2: extractedData.spaimage2,
          spaimage3: extractedData.spaimage3,
          spaimage4: extractedData.spaimage4,
          // AdditionalImageArray: extractedData.AdditionalImageArray.concat(
          //   view?.AdditionalImageArray
          // ),
          AdditionalImageArray: extractedData.AdditionalImageArray,
        },
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
        } else {
          alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      alert("Unable to add Details");
    }
  };

  const getEmployee = () => {
    axios
      .get("https://spazo.co.in/api/Vendor/getAllEmployee")
      .then(function (response) {
        console.log(response.data);
        setEmployee(response.data.Employee);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "3",
        value: 3,
      },
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "Image",
      text: "Spa owner",
      formatter: (value, row) => (
        <img
          src={"https://spazo.co.in/Vendor/" + row.profile}
          width={100}
          height={80}
          onClick={() =>
            window.open("https://spazo.co.in/Vendor/" + row.profile)
          }
        />
      ),
    },
    {
      dataField: "businessName",
      text: "Business Name",
      formatter: (value, row) => {
        const rev = row?.reviews?.reduce((a, item) => a + item.rating, 0);
        const rev1 = rev / row?.reviews?.length;

        console.log("rev", rev);
        return (
          <>
            <p style={{ marginBottom: "0px" }}>
              {row?.businessName ? row?.businessName : "-"}
            </p>

            <div>
              <div style={{ display: "flex" }}>
                <AiFillStar color="Gold" style={{ marginTop: "7px" }} /> :
                <p style={{ marginBottom: "0px", fontWeight: "bold" }}>
                  {row?.reviews?.length > 0 ? rev1 : ""}
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <FaAddressBook
                  color="Green"
                  style={{ marginTop: "7px", marginBottom: "10px" }}
                />{" "}
                :
                <p style={{ marginBottom: "0px", fontWeight: "bold" }}>
                  {row?.orders?.length}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "name",
      text: "Owner Name",
      formatter: (value, row) => <p>{row.name ? row.name : ""}</p>,
    },
    {
      dataField: "phoneNumber",
      text: "Phone Number",
      formatter: (value, row) => (
        <p>{row?.phoneNumber ? row?.phoneNumber : "-"}</p>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (value, row) => <p>{row?.email ? row?.email : "-"}</p>,
    },
    {
      dataField: "phoneNumber",
      text: "Phone Number",
      formatter: (value, row) => (
        <p>{row?.phoneNumber ? row?.phoneNumber : "-"}</p>
      ),
    },
    {
      dataField: "address",
      text: "Address",
      formatter: (value, row) => <p>{row?.address ? row?.address : "-"}</p>,
    },
    {
      dataField: "pincode",
      text: "Pincode",
      formatter: (value, row) => <p>{row?.pincode ? row?.pincode : "-"}</p>,
    },
    {
      dataField: "Glink",
      text: "Glink",
      formatter: (value, row) => (
        <a
          href={row?.Glink}
          target="_blank"
          style={{ overflowX: "scroll", width: "150px", display: "grid" }}
        >
          {row?.Glink ? row?.Glink : "-"}
        </a>
      ),
    },
    {
      dataField: "-",
      text: "View",
      formatter: (value, row) => (
        <>
          {/* {row?.approved === "Pending" ? (
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <span className="dite">
                <AiFillCheckCircle onClick={() => handleShow(row)} />
              </span>
              /
              <span className="dite">
                <AiFillCloseCircle onClick={() => handleShow1(row)} />
              </span>
            </div>
          ) : row?.approved === "Approved" ? (
            <span
              style={{
                border: "1px solid green",
                backgroundColor: "green",
                borderRadius: "50%",
                height: "35px",
                width: "35px",
                fontSize: "20px",
                color: "white",
                textAlign: "center",
                display: "block",
              }}
            >
              <AiFillCheckCircle />
            </span>
          ) : (
            <>
              <span
                style={{
                  border: "1px solid red",
                  backgroundColor: "red",
                  borderRadius: "50%",
                  height: "35px",
                  width: "35px",
                  fontSize: "20px",
                  color: "white",
                  textAlign: "center",
                  display: "block",
                }}
              >
                <AiFillCloseCircle onClick={() => handleShow(row)} />
              </span>
            </>
          )} */}
          <button style={{ border: "none" }} onClick={() => handleShow2(row)}>
            <i
              class="fa fa-eye"
              aria-hidden="true"
              style={{ color: "#e22217" }}
            ></i>
          </button>
        </>
      ),
    },
    {
      dataField: "approved",
      text: "Status",
      formatter: (value, row) => (
        <>
          <p
            style={{
              backgroundColor:
                row?.Editapproved === "Pending"
                  ? "#e22217"
                  : row?.Editapproved === "Approved"
                  ? "Green"
                  : "Red",
              color: "white",
            }}
          >
            {row?.Editapproved}
          </p>
        </>
      ),
    },
  ];

  console.log(
    "view?.workingdaysChange",
    view?.workingdaysChange ? "sjncksd" : ""
  );
  return (
    <>
      <div className="never-sspa">
        <section>
          <div className="spa-admin-0 mb-2">
            <div className="diff-admin px-3">
              <div className="admin-name-text">
                <h4>Edit Vendor Request</h4>
              </div>
            </div>
          </div>

          <ToastContainer
            position="top-center"
            autoClose={30000}
            closeOnClick
            pauseOnHover
            draggable
          />

          <div
            style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}
          >
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              // rowClasses={rowClasses}
              search
              exportCSV
            >
              {(props) => (
                <div>
                  <SearchBar {...props.searchProps} />
                  <ExportCSVButton
                    {...props.csvProps}
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                    }}
                  >
                    Download Excel
                  </ExportCSVButton>
                  {/* <button
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                      border: "none",
                      padding: "6px",
                      borderRadius: "5px",
                      marginLeft: "467px",
                    }}
                    onClick={handleShow}
                  >
                    +Add
                  </button> */}
                  <hr></hr>
                  <br></br>
                  <div style={{ overflowX: "scroll", width: "100%" }}>
                    <BootstrapTable
                      striped
                      hover
                      condensed
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </section>
      </div>

      {/* modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ alignItems: "center", display: "contents" }}>
          <p style={{ textAlign: "center" }}>
            Are you sure you want to approve the Spa Vendor{" "}
          </p>
          <Button
            variant="secondary"
            onClick={() => ApproveVendor(approvedata)}
          >
            Yes
          </Button>
        </Modal.Body>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ alignItems: "center", display: "contents" }}>
          <p style={{ textAlign: "center" }}>
            If you want to reject the Vendor, please give the reason for
            rejection
          </p>
          <textarea
            style={{ margin: "10px" }}
            onChange={(e) => setreasonFR(e.target.value)}
          />
          <Button
            variant="primary"
            onClick={() => RejectEditVendor(rejectdata)}
          >
            Yes
          </Button>
        </Modal.Body>
      </Modal>

      <Modal show={show2} onHide={handleClose2} className="modal111">
        <Modal.Header
          closeButton
          style={{ color: "#e22217", fontSize: "20px", fontWeight: 600 }}
        >
          Profile Details &nbsp;
        </Modal.Header>
        <Modal.Body style={{ alignItems: "center", display: "contents" }}>
          {view?.approved === "Rejected" && view?.update === false ? (
            <>
              <div
                style={{
                  margin: "20px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <p style={{ fontWeight: "bold" }}>
                  Your Profile has been rejected due to :{" "}
                </p>
                <p>{view?.reasonFR}</p>
              </div>
            </>
          ) : view?.approved === "Rejected" && view?.update === true ? (
            <>
              <div
                style={{
                  margin: "20px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <p style={{ fontWeight: "bold" }}>
                  Your Profile has been rejected due to :{" "}
                </p>
                <p>{view?.reasonFR}</p>
              </div>
              <hr></hr>
              <div style={{ margin: "20px" }}>
                <Row style={{ justifyContent: "space-between" }}>
                  <Col md={6} className="profile-col">
                    <Row>
                      <Col
                        md={4}
                        style={{ color: "#e22217", fontWeight: "bold" }}
                      >
                        Shop-Name
                      </Col>
                      <Col md={8} style={{ fontWeight: "500" }}>
                        {view?.businessName}
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={4}
                        style={{ color: "#e22217", fontWeight: "bold" }}
                      >
                        Owner-Name
                      </Col>
                      <Col md={8} style={{ fontWeight: "500" }}>
                        {view?.name}
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={4}
                        style={{ color: "#e22217", fontWeight: "bold" }}
                      >
                        Email-Id
                      </Col>
                      <Col md={8} style={{ fontWeight: "500" }}>
                        {view?.email}
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={4}
                        style={{ color: "#e22217", fontWeight: "bold" }}
                      >
                        Contact No.
                      </Col>
                      <Col md={8} style={{ fontWeight: "500" }}>
                        {view?.phoneNumber}
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={4}
                        style={{ color: "#e22217", fontWeight: "bold" }}
                      >
                        Address
                      </Col>
                      <Col md={8} style={{ fontWeight: "500" }}>
                        {view?.address}
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={4}
                        style={{ color: "#e22217", fontWeight: "bold" }}
                      >
                        Pincode
                      </Col>
                      <Col md={8} style={{ fontWeight: "500" }}>
                        {view?.pincode}
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={4}
                        style={{ color: "#e22217", fontWeight: "bold" }}
                      >
                        Location
                      </Col>
                      <Col md={8} style={{ fontWeight: "500" }}>
                        <a href={view?.Glink}>{view?.Glink}</a>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} className="profile-col" style={{ width: "49%" }}>
                    <Row>
                      <Col
                        md={6}
                        style={{ color: "#e22217", fontWeight: "bold" }}
                      >
                        Spa Type
                      </Col>
                      <Col md={6} style={{ fontWeight: "500" }}>
                        {view?.shoptype}
                      </Col>
                    </Row>
                    {/* <Row>
                  <Col md={6} style={{ color: "#e22217", fontWeight: "bold" }}>
                    No. of Emp. Working
                  </Col>
                  <Col md={6} style={{ fontWeight: "500" }}>
                    {view?.noofEmp}
                  </Col>
                </Row> */}
                    <Row>
                      <Col
                        md={6}
                        style={{ color: "#e22217", fontWeight: "bold" }}
                      >
                        Working Time
                      </Col>
                      <Col md={6} style={{ fontWeight: "500" }}>
                        {view?.formattedStartTime} - {view?.formattedEndTime}
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={6}
                        style={{ color: "#e22217", fontWeight: "bold" }}
                      >
                        Working Days
                      </Col>
                      <Col md={6} style={{ fontWeight: "500" }}>
                        <input type="checkbox" checked={view?.mon} />
                        &nbsp;
                        <label
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          Mon
                        </label>{" "}
                        &nbsp;
                        <input type="checkbox" checked={view?.tue} />
                        &nbsp;
                        <label
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          Tue
                        </label>{" "}
                        &nbsp;
                        <input type="checkbox" checked={view?.wed} />
                        &nbsp;
                        <label
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          wed
                        </label>{" "}
                        <br></br>
                        <input type="checkbox" checked={view?.turs} />
                        &nbsp;
                        <label
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          Tur
                        </label>{" "}
                        &nbsp;
                        <input type="checkbox" checked={view?.frid} />
                        &nbsp;
                        <label
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          Fri
                        </label>{" "}
                        &nbsp;
                        <input type="checkbox" checked={view?.sat} />
                        &nbsp;
                        <label
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          Sat
                        </label>{" "}
                        <br></br>
                        <input type="checkbox" checked={view?.sun} />
                        &nbsp;
                        <label
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          Sun
                        </label>{" "}
                        &nbsp;
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col
                        md={6}
                        style={{ color: "#e22217", fontWeight: "bold" }}
                      >
                        Basic Price
                      </Col>
                      <Col md={6} style={{ fontWeight: "500" }}>
                        {view?.basicprice} Rs/-
                      </Col>
                    </Row> */}
                    {/* <Row>
                      <Col
                        md={6}
                        style={{ color: "#e22217", fontWeight: "bold" }}
                      >
                        Min. Discount (%)
                      </Col>
                      <Col md={6} style={{ fontWeight: "500" }}>
                        {view?.mindiscount} %
                      </Col>
                    </Row> */}
                    <Row>
                      <Col
                        md={6}
                        style={{ color: "#e22217", fontWeight: "bold" }}
                      >
                        Parking
                      </Col>
                      <Col md={6} style={{ fontWeight: "500" }}>
                        {view?.parking}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* <Row className="mt-3 profile-col">
                  <p
                    style={{
                      color: "#e22217",
                      fontWeight: "bold",
                    }}
                  >
                    Aadhaar Card
                  </p>
                  {view?.Adhar?.map((item) => (
                    <>
                      <img
                        src={"https://spazo.co.in/Vendor/" + item?.adharF1}
                        style={{ width: "100px", height: "100px" }}
                        onClick={() =>
                          window.open(
                            "https://spazo.co.in/Vendor/" + item?.adharF1
                          )
                        }
                      />
                      <img
                        src={"https://spazo.co.in/Vendor/" + item?.adharB1}
                        style={{ width: "100px", height: "100px" }}
                        onClick={() =>
                          window.open(
                            "https://spazo.co.in/Vendor/" + item?.adharB1
                          )
                        }
                      />
                    </>
                  ))}
                  {view?.adharB1 ? (
                    <img
                      src={"https://spazo.co.in/Vendor/" + view?.adharB1}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + view?.adharB1
                        )
                      }
                    />
                  ) : (
                    ""
                  )}
                  {view?.adharF2 ? (
                    <img
                      src={"https://spazo.co.in/Vendor/" + view?.adharF2}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + view?.adharF2
                        )
                      }
                    />
                  ) : (
                    ""
                  )}
                  {view?.adharB2 ? (
                    <img
                      src={"https://spazo.co.in/Vendor/" + view?.adharB2}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + view?.adharB2
                        )
                      }
                    />
                  ) : (
                    ""
                  )}
                  {view?.adharF3 ? (
                    <img
                      src={"https://spazo.co.in/Vendor/" + view?.adharF3}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + view?.adharF3
                        )
                      }
                    />
                  ) : (
                    ""
                  )}
                  {view?.adharB3 ? (
                    <img
                      src={"https://spazo.co.in/Vendor/" + view?.adharB3}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + view?.adharB3
                        )
                      }
                    />
                  ) : (
                    ""
                  )}
                  {view?.adharF4 ? (
                    <img
                      src={"https://spazo.co.in/Vendor/" + view?.adharF4}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + view?.adharF4
                        )
                      }
                    />
                  ) : (
                    ""
                  )}
                  {view?.adharB4 ? (
                    <img
                      src={"https://spazo.co.in/Vendor/" + view?.adharB4}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + view?.adharB4
                        )
                      }
                    />
                  ) : (
                    ""
                  )}
                </Row> */}
                <Row className="mt-3 profile-col">
                  <Col md={2}>
                    <p
                      style={{
                        color: "#e22217",
                        fontWeight: "bold",
                      }}
                    >
                      Pan Card
                    </p>

                    <img
                      src={"https://spazo.co.in/Vendor/" + view?.Pan}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() =>
                        window.open("https://spazo.co.in/Vendor/" + view?.Pan)
                      }
                    />
                  </Col>

                  <Col md={2}>
                    <p
                      style={{
                        color: "#e22217",
                        fontWeight: "bold",
                      }}
                    >
                      Bank PassBook
                    </p>

                    <img
                      src={"https://spazo.co.in/Vendor/" + view?.passbook}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + view?.passbook
                        )
                      }
                    />
                  </Col>

                  <Col md={2}>
                    <p
                      style={{
                        color: "#e22217",
                        fontWeight: "bold",
                      }}
                    >
                      GST Certificate
                    </p>

                    <img
                      src={"https://spazo.co.in/Vendor/" + view?.gst}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() =>
                        window.open("https://spazo.co.in/Vendor/" + view?.gst)
                      }
                    />
                  </Col>

                  <Col md={2}>
                    <p
                      style={{
                        color: "#e22217",
                        fontWeight: "bold",
                      }}
                    >
                      Spa Certificate
                    </p>

                    <img
                      src={"https://spazo.co.in/Vendor/" + view?.spa}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() =>
                        window.open("https://spazo.co.in/Vendor/" + view?.spa)
                      }
                    />
                  </Col>

                  <Col md={4}>
                    <p
                      style={{
                        color: "#e22217",
                        fontWeight: "bold",
                      }}
                    >
                      Other Certificate
                    </p>

                    <img
                      src={"https://spazo.co.in/Vendor/" + view?.other}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() =>
                        window.open("https://spazo.co.in/Vendor/" + view?.other)
                      }
                    />
                  </Col>
                </Row>

                <Row
                  style={{ justifyContent: "space-between" }}
                  className="mb-5"
                >
                  <Col md={8}>
                    <Row className="mt-3 profile-col">
                      <p
                        style={{
                          color: "#e22217",
                          fontWeight: "bold",
                        }}
                      >
                        Spa-Images
                      </p>

                      <Col md={3}>
                        <img
                          src={"https://spazo.co.in/Vendor/" + view?.spaimage1}
                          style={{ width: "100px", height: "100px" }}
                          onClick={() =>
                            window.open(
                              "https://spazo.co.in/Vendor/" + view?.spaimage1
                            )
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <img
                          src={"https://spazo.co.in/Vendor/" + view?.spaimage2}
                          style={{ width: "100px", height: "100px" }}
                          onClick={() =>
                            window.open(
                              "https://spazo.co.in/Vendor/" + view?.spaimage2
                            )
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <img
                          src={"https://spazo.co.in/Vendor/" + view?.spaimage3}
                          style={{ width: "100px", height: "100px" }}
                          onClick={() =>
                            window.open(
                              "https://spazo.co.in/Vendor/" + view?.spaimage3
                            )
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <img
                          src={"https://spazo.co.in/Vendor/" + view?.spaimage4}
                          style={{ width: "100px", height: "100px" }}
                          onClick={() =>
                            window.open(
                              "https://spazo.co.in/Vendor/" + view?.spaimage4
                            )
                          }
                        />
                      </Col>
                      <Row className="mt-2">
                        {view?.AdditionalImageArray?.length > 0 ? (
                          <>
                            {view?.AdditionalImageArray?.map((item) => (
                              <Col md={3}>
                                <img
                                  src={
                                    "https://spazo.co.in/Vendor/" +
                                    item?.AdditionalImage
                                  }
                                  style={{ width: "100px", height: "100px" }}
                                  onClick={() =>
                                    window.open(
                                      "https://spazo.co.in/Vendor/" +
                                        item?.AdditionalImage
                                    )
                                  }
                                />
                              </Col>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </Row>
                    </Row>
                  </Col>

                  <Col md={4} style={{ width: "32%" }}>
                    {" "}
                    <Row className="mt-3 profile-col">
                      <p
                        style={{
                          color: "#e22217",
                          fontWeight: "bold",
                        }}
                      >
                        About
                      </p>
                      {view?.about ? (
                        <p style={{ overflowY: "scroll", height: "190px" }}>
                          {parse(view?.about)}
                        </p>
                      ) : (
                        ""
                      )}
                    </Row>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <div style={{ margin: "20px" }}>
              <Row className="mb-3 profile-col">
                <span style={{ color: "#e22217", fontWeight: "bold" }}>
                  Edit requiest sent for below content :{" "}
                </span>

                <span style={{ fontWeight: "bold" }}>
                  {view?.logoChange ? "Business Logo, " : ""}
                  {view?.profileChange ? "Profile Pic, " : ""}
                  {view?.businessNameChange ? "Shop-Name, " : ""}
                  {view?.nameChange ? "Owner-Name, " : ""}
                  {view?.emailChange ? "Email-Id, " : ""}
                  {view?.phoneNumberChange ? "Contact No., " : ""}
                  {view?.addressChange ? "Address, " : ""}
                  {view?.pincodeChange ? "Pincode, " : ""}
                  {view?.GlinkChange ? "Location, " : ""}
                  {view?.formattedStartTimeChange ? "Working Time, " : ""}
                  {view?.formattedEndTimeChange ? "Working Time, " : ""}
                  {view?.workingdaysChange ? "Working Days, " : ""}
                  {view?.parkingChange ? "Parking, " : ""}
                  {view?.PanChange ? "Pan Card, " : ""}
                  {view?.passbookChange ? "Bank PassBook, " : ""}
                  {view?.gstChange ? "GST Certificate, " : ""}
                  {view?.spaChange ? "Spa Certificate, " : ""}
                  {view?.otherChange ? "Other Certificate, " : ""}
                  {view?.spaimage1Change ? "Road side View, " : ""}
                  {view?.spaimage2Change ? "Lobby View, " : ""}
                  {view?.spaimage3Change ? "Corner View, " : ""}
                  {view?.spaimage4Change ? "Therapy Room View, " : ""}
                  {view?.AdditionalImageChange ? "Other Images, " : ""}
                  {view?.aboutChange ? "About, " : ""}
                </span>
              </Row>
              <Row style={{ justifyContent: "space-between" }}>
                <div
                  className="profile-col"
                  style={{ display: "flex", gap: "20px", marginBottom: "10px" }}
                >
                  <div>
                    <img
                      src={"https://spazo.co.in/Vendor/" + view?.logo}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        alignSelf: "center",
                      }}
                      onClick={() =>
                        window.open("https://spazo.co.in/Vendor/" + view?.logo)
                      }
                    />
                    <p style={{ color: "black", size: "10px" }}>
                      Business Logo
                    </p>
                  </div>
                  <div>
                    <img
                      src={"https://spazo.co.in/Vendor/" + view?.profile}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        alignSelf: "center",
                      }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + view?.profile
                        )
                      }
                    />
                    <p>Profiles</p>
                  </div>
                </div>
                <Col md={6} className="profile-col">
                  <Row>
                    <Col
                      md={4}
                      style={{ color: "#e22217", fontWeight: "bold" }}
                    >
                      Shop-Name
                    </Col>
                    <Col md={8} style={{ fontWeight: "500" }}>
                      {view?.businessName}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={4}
                      style={{ color: "#e22217", fontWeight: "bold" }}
                    >
                      Owner-Name
                    </Col>
                    <Col md={8} style={{ fontWeight: "500" }}>
                      {view?.name}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={4}
                      style={{ color: "#e22217", fontWeight: "bold" }}
                    >
                      Email-Id
                    </Col>
                    <Col md={8} style={{ fontWeight: "500" }}>
                      {view?.email}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={4}
                      style={{ color: "#e22217", fontWeight: "bold" }}
                    >
                      Contact No.
                    </Col>
                    <Col md={8} style={{ fontWeight: "500" }}>
                      {view?.phoneNumber}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={4}
                      style={{ color: "#e22217", fontWeight: "bold" }}
                    >
                      Address
                    </Col>
                    <Col md={8} style={{ fontWeight: "500" }}>
                      {view?.address}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={4}
                      style={{ color: "#e22217", fontWeight: "bold" }}
                    >
                      Pincode
                    </Col>
                    <Col md={8} style={{ fontWeight: "500" }}>
                      {view?.pincode}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={4}
                      style={{ color: "#e22217", fontWeight: "bold" }}
                    >
                      Location
                    </Col>
                    <Col md={8} style={{ fontWeight: "500" }}>
                      <a href={view?.Glink}>{view?.Glink}</a>
                    </Col>
                  </Row>
                </Col>
                <Col md={6} className="profile-col" style={{ width: "49%" }}>
                  <Row>
                    <Col
                      md={6}
                      style={{ color: "#e22217", fontWeight: "bold" }}
                    >
                      Spa Type
                    </Col>
                    <Col md={6} style={{ fontWeight: "500" }}>
                      {view?.shoptype}
                    </Col>
                  </Row>
                  {/* <Row>
                  <Col md={6} style={{ color: "#e22217", fontWeight: "bold" }}>
                    No. of Emp. Working
                  </Col>
                  <Col md={6} style={{ fontWeight: "500" }}>
                    {view?.noofEmp}
                  </Col>
                </Row> */}
                  <Row>
                    <Col
                      md={6}
                      style={{ color: "#e22217", fontWeight: "bold" }}
                    >
                      Working Time
                    </Col>
                    <Col md={6} style={{ fontWeight: "500" }}>
                      {view?.formattedStartTime} - {view?.formattedEndTime}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={6}
                      style={{ color: "#e22217", fontWeight: "bold" }}
                    >
                      Working Days
                    </Col>
                    <Col md={6} style={{ fontWeight: "500" }}>
                      <input type="checkbox" checked={view?.mon} />
                      &nbsp;
                      <label
                        style={{
                          color: "black",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                        Mon
                      </label>{" "}
                      &nbsp;
                      <input type="checkbox" checked={view?.tue} />
                      &nbsp;
                      <label
                        style={{
                          color: "black",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                        Tue
                      </label>{" "}
                      &nbsp;
                      <input type="checkbox" checked={view?.wed} />
                      &nbsp;
                      <label
                        style={{
                          color: "black",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                        wed
                      </label>{" "}
                      <br></br>
                      <input type="checkbox" checked={view?.turs} />
                      &nbsp;
                      <label
                        style={{
                          color: "black",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                        Tur
                      </label>{" "}
                      &nbsp;
                      <input type="checkbox" checked={view?.frid} />
                      &nbsp;
                      <label
                        style={{
                          color: "black",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                        Fri
                      </label>{" "}
                      &nbsp;
                      <input type="checkbox" checked={view?.sat} />
                      &nbsp;
                      <label
                        style={{
                          color: "black",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                        Sat
                      </label>{" "}
                      <br></br>
                      <input type="checkbox" checked={view?.sun} />
                      &nbsp;
                      <label
                        style={{
                          color: "black",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                        Sun
                      </label>{" "}
                      &nbsp;
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col
                      md={6}
                      style={{ color: "#e22217", fontWeight: "bold" }}
                    >
                      Basic Price
                    </Col>
                    <Col md={6} style={{ fontWeight: "500" }}>
                      {view?.basicprice} Rs/-
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={6}
                      style={{ color: "#e22217", fontWeight: "bold" }}
                    >
                      Min. Discount (%)
                    </Col>
                    <Col md={6} style={{ fontWeight: "500" }}>
                      {view?.mindiscount} %
                    </Col>
                  </Row> */}
                  <Row>
                    <Col
                      md={6}
                      style={{ color: "#e22217", fontWeight: "bold" }}
                    >
                      Parking
                    </Col>
                    <Col md={6} style={{ fontWeight: "500" }}>
                      {view?.parking}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* <Row className="mt-3 profile-col">
                <p
                  style={{
                    color: "#e22217",
                    fontWeight: "bold",
                  }}
                >
                  Aadhaar Card
                </p>
                {view?.Adhar?.map((item) => (
                  <>
                    <img
                      src={"https://spazo.co.in/Vendor/" + item?.adharF1}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + item?.adharF1
                        )
                      }
                    />
                    <img
                      src={"https://spazo.co.in/Vendor/" + item?.adharB1}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + item?.adharB1
                        )
                      }
                    />
                  </>
                ))}
                {view?.adharB1 ? (
                  <img
                    src={"https://spazo.co.in/Vendor/" + view?.adharB1}
                    style={{ width: "100px", height: "100px" }}
                    onClick={() =>
                      window.open(
                        "https://spazo.co.in/Vendor/" + view?.adharB1
                      )
                    }
                  />
                ) : (
                  ""
                )}
                {view?.adharF2 ? (
                  <img
                    src={"https://spazo.co.in/Vendor/" + view?.adharF2}
                    style={{ width: "100px", height: "100px" }}
                    onClick={() =>
                      window.open(
                        "https://spazo.co.in/Vendor/" + view?.adharF2
                      )
                    }
                  />
                ) : (
                  ""
                )}
                {view?.adharB2 ? (
                  <img
                    src={"https://spazo.co.in/Vendor/" + view?.adharB2}
                    style={{ width: "100px", height: "100px" }}
                    onClick={() =>
                      window.open(
                        "https://spazo.co.in/Vendor/" + view?.adharB2
                      )
                    }
                  />
                ) : (
                  ""
                )}
                {view?.adharF3 ? (
                  <img
                    src={"https://spazo.co.in/Vendor/" + view?.adharF3}
                    style={{ width: "100px", height: "100px" }}
                    onClick={() =>
                      window.open(
                        "https://spazo.co.in/Vendor/" + view?.adharF3
                      )
                    }
                  />
                ) : (
                  ""
                )}
                {view?.adharB3 ? (
                  <img
                    src={"https://spazo.co.in/Vendor/" + view?.adharB3}
                    style={{ width: "100px", height: "100px" }}
                    onClick={() =>
                      window.open(
                        "https://spazo.co.in/Vendor/" + view?.adharB3
                      )
                    }
                  />
                ) : (
                  ""
                )}
                {view?.adharF4 ? (
                  <img
                    src={"https://spazo.co.in/Vendor/" + view?.adharF4}
                    style={{ width: "100px", height: "100px" }}
                    onClick={() =>
                      window.open(
                        "https://spazo.co.in/Vendor/" + view?.adharF4
                      )
                    }
                  />
                ) : (
                  ""
                )}
                {view?.adharB4 ? (
                  <img
                    src={"https://spazo.co.in/Vendor/" + view?.adharB4}
                    style={{ width: "100px", height: "100px" }}
                    onClick={() =>
                      window.open(
                        "https://spazo.co.in/Vendor/" + view?.adharB4
                      )
                    }
                  />
                ) : (
                  ""
                )}
              </Row> */}
              <Row className="mt-3 profile-col">
                <Col md={2}>
                  <p
                    style={{
                      color: "#e22217",
                      fontWeight: "bold",
                    }}
                  >
                    Pan Card
                  </p>

                  <img
                    src={"https://spazo.co.in/Vendor/" + view?.Pan}
                    style={{ width: "100px", height: "100px" }}
                    onClick={() =>
                      window.open("https://spazo.co.in/Vendor/" + view?.Pan)
                    }
                  />
                </Col>

                <Col md={2}>
                  <p
                    style={{
                      color: "#e22217",
                      fontWeight: "bold",
                    }}
                  >
                    Bank PassBook
                  </p>

                  <img
                    src={"https://spazo.co.in/Vendor/" + view?.passbook}
                    style={{ width: "100px", height: "100px" }}
                    onClick={() =>
                      window.open(
                        "https://spazo.co.in/Vendor/" + view?.passbook
                      )
                    }
                  />
                </Col>

                <Col md={2}>
                  <p
                    style={{
                      color: "#e22217",
                      fontWeight: "bold",
                    }}
                  >
                    GST Certificate
                  </p>

                  <img
                    src={"https://spazo.co.in/Vendor/" + view?.gst}
                    style={{ width: "100px", height: "100px" }}
                    onClick={() =>
                      window.open("https://spazo.co.in/Vendor/" + view?.gst)
                    }
                  />
                </Col>

                <Col md={2}>
                  <p
                    style={{
                      color: "#e22217",
                      fontWeight: "bold",
                    }}
                  >
                    Spa Certificate
                  </p>

                  <img
                    src={"https://spazo.co.in/Vendor/" + view?.spa}
                    style={{ width: "100px", height: "100px" }}
                    onClick={() =>
                      window.open("https://spazo.co.in/Vendor/" + view?.spa)
                    }
                  />
                </Col>

                <Col md={4}>
                  <p
                    style={{
                      color: "#e22217",
                      fontWeight: "bold",
                    }}
                  >
                    Other Certificate
                  </p>

                  <img
                    src={"https://spazo.co.in/Vendor/" + view?.other}
                    style={{ width: "100px", height: "100px" }}
                    onClick={() =>
                      window.open("https://spazo.co.in/Vendor/" + view?.other)
                    }
                  />
                </Col>
              </Row>

              <Row style={{ justifyContent: "space-between" }} className="mb-5">
                <Col md={8}>
                  <Row className="mt-3 profile-col">
                    <p
                      style={{
                        color: "#e22217",
                        fontWeight: "bold",
                      }}
                    >
                      Spa-Images
                    </p>

                    <Col md={3}>
                      <img
                        src={"https://spazo.co.in/Vendor/" + view?.spaimage1}
                        style={{ width: "100px", height: "100px" }}
                        onClick={() =>
                          window.open(
                            "https://spazo.co.in/Vendor/" + view?.spaimage1
                          )
                        }
                      />
                    </Col>
                    <Col md={3}>
                      <img
                        src={"https://spazo.co.in/Vendor/" + view?.spaimage2}
                        style={{ width: "100px", height: "100px" }}
                        onClick={() =>
                          window.open(
                            "https://spazo.co.in/Vendor/" + view?.spaimage2
                          )
                        }
                      />
                    </Col>
                    <Col md={3}>
                      <img
                        src={"https://spazo.co.in/Vendor/" + view?.spaimage3}
                        style={{ width: "100px", height: "100px" }}
                        onClick={() =>
                          window.open(
                            "https://spazo.co.in/Vendor/" + view?.spaimage3
                          )
                        }
                      />
                    </Col>
                    <Col md={3}>
                      <img
                        src={"https://spazo.co.in/Vendor/" + view?.spaimage4}
                        style={{ width: "100px", height: "100px" }}
                        onClick={() =>
                          window.open(
                            "https://spazo.co.in/Vendor/" + view?.spaimage4
                          )
                        }
                      />
                    </Col>
                    <p
                      style={{
                        color: "#e22217",
                        fontWeight: "bold",
                      }}
                    >
                      Spa other Images
                    </p>
                    <Row className="mt-2">
                      {view?.AdditionalImageArray?.length > 0 ? (
                        <>
                          {view?.AdditionalImageArray?.map((item) => (
                            <Col md={3}>
                              <img
                                src={
                                  "https://spazo.co.in/Vendor/" +
                                  item?.AdditionalImage
                                }
                                style={{ width: "100px", height: "100px" }}
                                onClick={() =>
                                  window.open(
                                    "https://spazo.co.in/Vendor/" +
                                      item?.AdditionalImage
                                  )
                                }
                              />
                            </Col>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </Row>
                  </Row>
                </Col>

                <Col md={4} style={{ width: "32%" }}>
                  {" "}
                  <Row className="mt-3 profile-col">
                    <p
                      style={{
                        color: "#e22217",
                        fontWeight: "bold",
                      }}
                    >
                      About
                    </p>
                    {view?.about ? (
                      <p style={{ overflowY: "scroll", height: "190px" }}>
                        {parse(view?.about)}
                      </p>
                    ) : (
                      ""
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {view?.approved === "Rejected" && view?.update === false ? (
            <Button variant="secondary" onClick={handleClose2}>
              Ok
            </Button>
          ) : (
            <>
              <Button variant="secondary" onClick={() => handleShow(view)}>
                Approve
              </Button>
              <Button variant="dark" onClick={() => handleShow1(view)}>
                Reject
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditVendorRequest;
