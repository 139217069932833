import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { AiOutlineLeft } from "react-icons/ai";
import { BiCalendar } from "react-icons/bi";
import {
  BsFillFilePersonFill,
  BsFillPersonFill,
  BsShieldCheck,
} from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import { isAfter, isBefore, isToday, addDays } from "date-fns";
import moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-horizontal-datepicker";
import { format } from "date-fns-tz";

function VendorSelectDate() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const location = useLocation();
  const { item, Therapist } = location.state;

  const [Order, setOrder] = useState([]);
  const [selecteddate, setselecteddate] = useState(new Date());
  const [pickuptime, setpickuptime] = useState("");

  const selectedDay = (val) => {
    setselecteddate(val);
  };

  useEffect(() => {
    getVendorOrder();
  }, []);

  const getVendorOrder = () => {
    axios
      .get("https://spazo.co.in/api/customer/getVendorOrder/" + item._id)
      .then(function (response) {
        setOrder(response.data.Order);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const alldate = [
    { id: 0, time: "00:00" },
    { id: 15, time: "00:15" },
    { id: 30, time: "00:30" },
    { id: 45, time: "00:45" },
    { id: 100, time: "01:00" },
    { id: 115, time: "01:15" },
    { id: 130, time: "01:30" },
    { id: 145, time: "01:45" },
    { id: 200, time: "02:00" },
    { id: 215, time: "02:15" },
    { id: 230, time: "02:30" },
    { id: 245, time: "02:45" },
    { id: 300, time: "03:00" },
    { id: 315, time: "03:15" },
    { id: 330, time: "03:30" },
    { id: 345, time: "03:45" },
    { id: 400, time: "04:00" },
    { id: 415, time: "04:15" },
    { id: 430, time: "04:30" },
    { id: 445, time: "04:45" },
    { id: 500, time: "05:00" },
    { id: 515, time: "05:15" },
    { id: 530, time: "05:30" },
    { id: 545, time: "05:45" },
    { id: 600, time: "06:00" },
    { id: 615, time: "06:15" },
    { id: 630, time: "06:30" },
    { id: 645, time: "06:45" },
    { id: 700, time: "07:00" },
    { id: 715, time: "07:15" },
    { id: 730, time: "07:30" },
    { id: 745, time: "07:45" },
    { id: 800, time: "08:00" },
    { id: 815, time: "08:15" },
    { id: 830, time: "08:30" },
    { id: 845, time: "08:45" },
    { id: 900, time: "09:00" },
    { id: 915, time: "09:15" },
    { id: 930, time: "09:30" },
    { id: 945, time: "09:45" },
    { id: 1000, time: "10:00" },
    { id: 1015, time: "10:15" },
    { id: 1030, time: "10:30" },
    { id: 1045, time: "10:45" },
    { id: 1100, time: "11:00" },
    { id: 1115, time: "11:15" },
    { id: 1130, time: "11:30" },
    { id: 1145, time: "11:45" },
    { id: 1200, time: "12:00" },
    { id: 1215, time: "12:15" },
    { id: 1230, time: "12:30" },
    { id: 1245, time: "12:45" },
    { id: 1300, time: "13:00" },
    { id: 1315, time: "13:15" },
    { id: 1330, time: "13:30" },
    { id: 1345, time: "13:45" },
    { id: 1400, time: "14:00" },
    { id: 1415, time: "14:15" },
    { id: 1430, time: "14:30" },
    { id: 1445, time: "14:45" },
    { id: 1500, time: "15:00" },
    { id: 1515, time: "15:15" },
    { id: 1530, time: "15:30" },
    { id: 1545, time: "15:45" },
    { id: 1600, time: "16:00" },
    { id: 1615, time: "16:15" },
    { id: 1630, time: "16:30" },
    { id: 1645, time: "16:45" },
    { id: 1700, time: "17:00" },
    { id: 1715, time: "17:15" },
    { id: 1730, time: "17:30" },
    { id: 1745, time: "17:45" },
    { id: 1800, time: "18:00" },
    { id: 1815, time: "18:15" },
    { id: 1830, time: "18:30" },
    { id: 1845, time: "18:45" },
    { id: 1900, time: "19:00" },
    { id: 1915, time: "19:15" },
    { id: 1930, time: "19:30" },
    { id: 1945, time: "19:45" },
    { id: 2000, time: "20:00" },
    { id: 2015, time: "20:15" },
    { id: 2030, time: "20:30" },
    { id: 2045, time: "20:45" },
    { id: 2100, time: "21:00" },
    { id: 2115, time: "21:15" },
    { id: 2130, time: "21:30" },
    { id: 2145, time: "21:45" },
    { id: 2200, time: "22:00" },
    { id: 2215, time: "22:15" },
    { id: 2230, time: "22:30" },
    { id: 2245, time: "22:45" },
    { id: 2300, time: "23:00" },
    { id: 2315, time: "23:15" },
    { id: 2330, time: "23:30" },
    { id: 2345, time: "23:45" },
  ];

  //Display time should be within spa start time and end time
  const [amm, setamm1] = useState([]);

  useEffect(() => {
    let today = new Date();
    const [timePart, period] = item?.formattedStartTime.split(" ");
    const [hours, minutes] = timePart.split(":");
    let hour = parseInt(hours, 10);
    const minute = parseInt(minutes, 10);

    // Handle AM and PM
    if (period === "PM" && hour < 12) {
      hour += 12;
    }
    const time24Hour = `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`;

    const [timePart1, period1] = item?.formattedEndTime.split(" ");
    const [hours1, minutes1] = timePart1.split(":");
    let hours2 = parseInt(hours1, 10);
    const minute1 = parseInt(minutes1, 10);

    if (period1 === "PM" && hours2 < 12) {
      hours2 += 12;
    }
    const time24Hour1 = `${hours2.toString().padStart(2, "0")}:${minute1
      .toString()
      .padStart(2, "0")}`;

    if (
      moment(today).format("DD/MM/YYYY") ==
      moment(selecteddate).format("DD/MM/YYYY")
    ) {
      const later = moment(today.getTime()).format("hh:mm A");
      const [timePart2, period2] = later.split(" ");
      const [hours2, minutes2] = timePart2.split(":");
      let hours3 = parseInt(hours2, 10);
      const minute3 = parseInt(minutes2, 10);

      if (period2 === "PM" && hours3 < 12) {
        hours3 += 12;
      }
      const time24Hour2 = `${hours3.toString().padStart(2, "0")}:${minute3
        .toString()
        .padStart(2, "0")}`;

      if (time24Hour <= time24Hour2) {
        setamm1(
          alldate?.filter((ele) => {
            return ele.time >= time24Hour2 && ele.time <= time24Hour1;
          })
        );
      } else {
        setamm1(
          alldate?.filter(
            (ele) => ele.time >= time24Hour && ele.time <= time24Hour1
          )
        );
      }
    } else {
      setamm1(
        alldate?.filter(
          (ele) => ele.time >= time24Hour && ele.time <= time24Hour1
        )
      );
    }
  }, [selecteddate]);

  const [endTime, setendTime] = useState("");
  const [ReasonForBlock, setReasonForBlock] = useState("");

  //For selected date therapist is available or not
  const therapistavailability = Therapist?.vailability?.find(
    (item) =>
      moment(item.date1).format("DD/MM/YYYY") ===
      moment(selecteddate).format("DD/MM/YYYY")
  );

  let timearray = [];

  //   for (let i = 0; i < amm?.length; i++) {
  //     Therapist?.orders?.map((item) => {
  //       if (item.date == moment(selecteddate).format("DD/MM/YYYY")) {
  //         const [timePart, period] = item.fromTime.split(" ");
  //         const [hours, minutes] = timePart.split(":");

  //         const hour = parseInt(hours, 10);
  //         const minute = parseInt(minutes, 10);
  //         var hour1 = "";
  //         if (period === "PM" && hour < 12) {
  //           hour1 = hour + 12;
  //         } else {
  //           hour1 = hour;
  //         }

  //         var fromtime = hour1 + ":" + minute;
  //         const [EtimePart, Eperiod] = item.toTime.split(" ");
  //         const [Ehours, Eminutes] = EtimePart.split(":");

  //         const Ehour = parseInt(Ehours, 10);
  //         const Eminute = parseInt(Eminutes, 10);
  //         var Ehour1 = "";
  //         if (Eperiod === "PM" && Ehour < 12) {
  //           Ehour1 = Ehour + 12;
  //         } else {
  //           Ehour1 = Ehour;
  //         }
  //         var Endtime = Ehour1 + ":" + Eminute;
  //         if (!(fromtime <= amm[i]?.time && amm[i]?.time <= Endtime)) {
  //           if (
  //             !timearray.some(
  //               (item) => JSON.stringify(item) === JSON.stringify(amm[i])
  //             )
  //           ) {
  //             timearray.push(amm[i]);
  //           }
  //         }
  //       }
  //     });
  //   }

  for (let i = 0; i < amm?.length; i++) {
    let matchFound = false; // Flag to track if a match is found

    if (Therapist?.orders) {
      for (const order of Therapist.orders) {
        if (order.date === moment(selecteddate).format("DD/MM/YYYY")) {
          const [timePart, period] = order.fromTime.split(" ");
          const [hours, minutes] = timePart.split(":");
          const hour = parseInt(hours, 10);
          const minute = parseInt(minutes, 10);
          var hour1 = period === "PM" && hour < 12 ? hour + 12 : hours;
          var fromtime = hour1 + ":" + minute;

          const [EtimePart, Eperiod] = order.toTime.split(" ");
          const [Ehours, Eminutes] = EtimePart.split(":");
          const Ehour = parseInt(Ehours, 10);
          const Eminute = parseInt(Eminutes, 10);
          var Ehour1 = Eperiod === "PM" && Ehour < 12 ? Ehour + 12 : Ehours;
          var Endtime = Ehour1 + ":" + Eminute;

          // Check if the time in amm[i] falls within the time range of any order
          if (fromtime <= amm[i].time && amm[i].time <= Endtime) {
            matchFound = true;
            break; // Exit the loop, as we found a match
          }
        }
      }
    }
    if (!matchFound) {
      // Push amm[i] into timearray if no match was found
      timearray.push(amm[i]);
    }
  }

  const placeorder = async (e) => {
    if (!pickuptime) {
      notify2("Please select Start Time");
    } else if (!endTime) {
      notify2("Please select end Time");
    } else if (!ReasonForBlock) {
      notify2("Please enter reason for block");
    } else {
      try {
        const config = {
          url: "/AddOrder",
          method: "post",
          baseURL: "https://spazo.co.in/api/customer",
          headers: { "content-type": "application/json" },
          data: {
            date: moment(selecteddate).format("DD/MM/YYYY"),
            fromTime: pickuptime,
            toTime: endTime,
            Therapist: Therapist?.EmpId,
            ReasonForBlock: ReasonForBlock,
            Type: "OfflineBooking",

            // Service_id: "",
            ServiceName: "",
            Service_Category: "",
            Service_Duration: "",
            ValidFor: "",
            slots: 0,
            Price: 0,
            Discount: 0,
            promocode: "",
            CouponDiscount: 0,
            TotalAmount: 0,
            TotalDiscount: 0,
            TotalPayableAmount: 0,
            TotalAmountPaid: 0,
            // vendorId: "",
            VendorBusinessName: "",
            VendorName: "",
            VendorEmailID: "",
            VendorContactNo: 0,
            VendorAddress: "",
            VendorPincode: 0,
            VendorGlink: "",
            // userID: "",
            userName: "",
            userEmailID: "",
            userContactNumber: 0,
            paymentMethod: "",
            paymentID: "",
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          notify("Slot blocked successfully");
          window.location.assign("VendorEmployee");
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          // alert(error.response.data.error);
          notify(error.response.data.error);
        }
      }
    }
  };

  console.log("timearray", timearray);
  console.log("Therapist", Therapist);
  console.log("therapistavailability", therapistavailability);
  console.log("amm", amm);
  console.log(
    "shjs",
    Therapist?.orders?.find(
      (item) => item.date === moment(selecteddate).format("DD/MM/YYYY")
    )
      ? "yes"
      : "no"
  );
  console.log("pickuptime", pickuptime);
  return (
    <>
      <div
        className="row me-0 pt-2 pb-2"
        style={{
          boxShadow: "3px 4px 8px #cbc6c6",
          justifyContent: "space-between",
        }}
      >
        <Col md={2}>
          <div
            className="log0"
            style={{ paddingLeft: "20px" }}
            // onClick={() => window.location.assign("/NewHome")}
          >
            <img src="./logo.jpg" style={{ width: "110px", height: "38px" }} />
          </div>
        </Col>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={30000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container style={{ margin: "5%" }}>
        {/* <Link to="/SelectTherapist" state={{ item: item }}> */}
        {/* <AiOutlineLeft
          style={{ color: "#e22217", fontSize: "28px", marginBottom: "4px" }}
        /> */}
        <span
          style={{
            color: "#e22217",
            fontSize: "22px",
            fontWeight: "bold",
          }}
        >
          Select time
        </span>
        {/* </Link> */}

        <Row className="me-0">
          <Col md={8} className="mt-2">
            <DatePicker
              getSelectedDay={selectedDay}
              endDate={1000}
              labelFormat={"MMMM yyyy"}
              color={"#e22217"}
            />

            {therapistavailability?.Availability === "Available" ? (
              <>
                {pickuptime ? (
                  <>
                    <p
                      style={{
                        color: "#e22217",
                        margin: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Select End Time
                    </p>
                    {Therapist?.orders?.find(
                      (item) =>
                        item.date === moment(selecteddate).format("DD/MM/YYYY")
                    ) ? (
                      <>
                        {timearray?.length !== 0 ? (
                          <>
                            {timearray?.map((item) => (
                              <div
                                className="timeselect"
                                onClick={() =>
                                  setendTime(
                                    moment(item?.time, ["h:mm A"]).format(
                                      "hh:mm A"
                                    )
                                  )
                                }
                              >
                                <p>
                                  {moment(item?.time, ["h:mm A"]).format(
                                    "hh:mm A"
                                  )}
                                </p>
                              </div>
                            ))}
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                display: "grid",
                                justifyContent: "center",
                                margin: "15%",
                              }}
                            >
                              <div
                                style={{
                                  margin: "20px",
                                  display: "grid",
                                  justifyContent: "center",
                                }}
                              >
                                <i
                                  class="fas fa-calendar-times"
                                  style={{
                                    fontSize: "30px",
                                    color: "rgb(226, 34, 23)",
                                  }}
                                ></i>
                              </div>
                              <p
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  marginBottom: "0px",
                                  textAlign: "center",
                                }}
                              >
                                {Therapist?.name}
                                {/* ({Therapist?.Gender === "Female" ? "She" : "He"}) */}
                                is fully booked on{" "}
                                {moment(selecteddate).format("MMMM Do YYYY")}
                              </p>
                              <p
                                style={{
                                  marginBottom: "0px",
                                  textAlign: "center",
                                }}
                              >
                                but you can book for another day
                              </p>
                              <Link
                                to="/SelectTherapist"
                                state={{ item: item }}
                                style={{
                                  color: "black",
                                  textAlign: "center",
                                  border: "1px solid black",
                                  margin: "20px",
                                  backgroundColor: "transparent",
                                  padding: "10px",
                                  fontWeight: "bold",
                                }}
                              >
                                Try with other Therapist
                              </Link>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {amm?.map((item) => (
                          <div
                            className="timeselect"
                            onClick={() =>
                              setendTime(
                                moment(item?.time, ["h:mm A"]).format("hh:mm A")
                              )
                            }
                          >
                            <p>
                              {moment(item?.time, ["h:mm A"]).format("hh:mm A")}
                            </p>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <p
                      style={{
                        color: "#e22217",
                        margin: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Select Start Time
                    </p>
                    {Therapist?.orders?.find(
                      (item) =>
                        item.date === moment(selecteddate).format("DD/MM/YYYY")
                    ) ? (
                      <>
                        {timearray?.length > 0 ? (
                          <>
                            {timearray?.map((item) => (
                              <div
                                className="timeselect"
                                onClick={() =>
                                  setpickuptime(
                                    moment(item?.time, ["h:mm A"]).format(
                                      "hh:mm A"
                                    )
                                  )
                                }
                              >
                                <p>
                                  {moment(item?.time, ["h:mm A"]).format(
                                    "hh:mm A"
                                  )}
                                </p>
                              </div>
                            ))}
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                display: "grid",
                                justifyContent: "center",
                                margin: "15%",
                              }}
                            >
                              <div
                                style={{
                                  margin: "20px",
                                  display: "grid",
                                  justifyContent: "center",
                                }}
                              >
                                <i
                                  class="fas fa-calendar-times"
                                  style={{
                                    fontSize: "30px",
                                    color: "rgb(226, 34, 23)",
                                  }}
                                ></i>
                              </div>
                              <p
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  marginBottom: "0px",
                                  textAlign: "center",
                                }}
                              >
                                {Therapist?.name}
                                {/* ({Therapist?.Gender === "Female" ? "She" : "He"}) */}
                                is fully booked on{" "}
                                {moment(selecteddate).format("MMMM Do YYYY")}
                              </p>
                              <p
                                style={{
                                  marginBottom: "0px",
                                  textAlign: "center",
                                }}
                              >
                                but you can book for another day
                              </p>
                              <Link
                                to="/SelectTherapist"
                                state={{ item: item }}
                                style={{
                                  color: "black",
                                  textAlign: "center",
                                  border: "1px solid black",
                                  margin: "20px",
                                  backgroundColor: "transparent",
                                  padding: "10px",
                                  fontWeight: "bold",
                                }}
                              >
                                Try with other Therapist
                              </Link>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {amm?.map((item) => (
                          <div
                            className="timeselect"
                            onClick={() =>
                              setpickuptime(
                                moment(item?.time, ["h:mm A"]).format("hh:mm A")
                              )
                            }
                          >
                            <p>
                              {moment(item?.time, ["h:mm A"]).format("hh:mm A")}
                            </p>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                )}
              </>
            ) : therapistavailability?.Availability === "Not" ? (
              <>
                <div
                  style={{
                    display: "grid",
                    justifyContent: "center",
                    margin: "15%",
                  }}
                >
                  <div
                    style={{
                      margin: "20px",
                      display: "grid",
                      justifyContent: "center",
                    }}
                  >
                    <i
                      class="fas fa-calendar-times"
                      style={{
                        fontSize: "30px",
                        color: "rgb(226, 34, 23)",
                      }}
                    ></i>
                  </div>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginBottom: "0px",
                      textAlign: "center",
                    }}
                  >
                    {Therapist?.name}
                    {/* ( {Therapist?.Gender === "Female" ? "She" : "He"})  */}
                    is fully booked on{" "}
                    {moment(selecteddate).format("MMMM Do YYYY")}
                  </p>
                  <p
                    style={{
                      marginBottom: "0px",
                      textAlign: "center",
                    }}
                  >
                    but you can book for another day
                  </p>
                  <Link
                    to="/SelectTherapist"
                    state={{ item: item }}
                    style={{
                      color: "black",
                      textAlign: "center",
                      border: "1px solid black",
                      margin: "20px",
                      backgroundColor: "transparent",
                      padding: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Try with other Therapist
                  </Link>
                </div>
              </>
            ) : (
              ""
            )}
          </Col>
          <Col md={4}>
            <div
              style={{
                border: "1px solid rgb(217 212 208)",
                padding: "10px",
                margin: "10px",
                borderRadius: "5px",
                // position: "fixed",
                // width: "25%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                <img
                  src={"https://spazo.co.in/Employee/" + Therapist?.profile}
                  width={50}
                  height={50}
                  onClick={() =>
                    window.open(
                      "https://spazo.co.in/Employee/" + Therapist.profile
                    )
                  }
                  style={{ borderRadius: "50%" }}
                />
                <div>
                  <p style={{ marginBottom: "0px", fontWeight: "700" }}>
                    {Therapist?.name}
                  </p>
                  <p style={{ marginBottom: "0px" }}>
                    {Therapist?.Specialization}
                  </p>
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <i class="fas fa-calendar-week" style={{ margin: "5px" }}></i>
                <div>
                  <p style={{ marginBottom: "0px" }}>
                    {moment(selecteddate).format("MMMM Do YYYY")}
                  </p>
                  {pickuptime ? (
                    <p style={{ marginBottom: "0px" }}>
                      {pickuptime} - {endTime}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <hr></hr>
              <p>reason for block</p>
              <textarea
                placeholder="Please enter reason for block"
                style={{ width: "100%", height: "80px" }}
                onChange={(e) => setReasonForBlock(e.target.value)}
              />
            </div>
            {pickuptime && endTime ? (
              <button
                style={{
                  backgroundColor: "rgb(226, 34, 23)",
                  color: "white",
                  border: "none",
                  marginTop: "30px",
                  padding: "5px 20px",
                  borderRadius: "5px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={placeorder}
              >
                Block
              </button>
            ) : (
              <button
                style={{
                  backgroundColor: "transparent",
                  color: "gray",
                  border: "1px solid gray",
                  marginTop: "30px",
                  padding: "5px 20px",
                  borderRadius: "5px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Block
              </button>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default VendorSelectDate;
