import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaEye, FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import moment from "moment";

function AdminDaywiseAccountDetails() {
  const Input = styled("input")({
    display: "none",
  });

  const user = JSON.parse(sessionStorage.getItem("user"));
  const [allVendors, setallVendors] = useState([]);
  const [Order, setOrder] = useState([]);
  const [selectedDate, setselectedDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [vendordata, setvendordata] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setvendordata(data);
  };
  const data = [];

  useEffect(() => {
    getallVendor();
  }, []);

  useEffect(() => {
    if (Object.keys(vendordata).length > 0) {
      getVendorOrder();
    }
  }, [vendordata]);

  const getallVendor = () => {
    axios
      .get("https://spazo.co.in/api/vendor/allVendors")
      .then(function (response) {
        console.log(response.data);
        setallVendors(
          response.data.allVendors?.filter((item) => item?.type === "Spa")
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getVendorOrder = () => {
    axios
      .get("https://spazo.co.in/api/customer/getVendorOrder/" + vendordata._id)
      .then(function (response) {
        console.log("orderghsag", response.data);
        setOrder(
          response.data.Order?.filter(
            (item) => item?.BookingStatus !== "Cancelled"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  for (let i = 0; i < Order.length; i++) {
    const vendorshare =
      Order[i].TotalAmount -
      Order[i].Discount -
      ((Order[i].TotalAmount - Order[i].Discount) *
        Order[i].vendors[0]?.Vendorpercentage) /
        100;

    //Online payment 5%
    const adminshare = ((Order[i].TotalAmount - Order[i].Discount) * 5) / 100;
    // vendor %
    const adminshare1 =
      ((Order[i].TotalAmount - Order[i].Discount) *
        Order[i].vendors[0]?.Vendorpercentage) /
      100;
    const adminshare2 =
      Order[i].paymentMethod === "Pay At Spa"
        ? adminshare1 - Order[i].CouponDiscount
        : adminshare1 - Order[i].CouponDiscount - adminshare;

    data.push({
      Booking_Id: Order[i]._id,
      Booking_Date: moment(Order[i].createdAt).format("DD/MM/YYYY"),
      Spa_Name: Order[i].VendorBusinessName,
      Spa_Email: Order[i].VendorEmailID,
      Contact_Number: Order[i].VendorContactNo,
      Spa_Address: Order[i].VendorAddress,
      Spa_Discount: Order[i].Discount,
      Basic_Price: Order[i].Price,
      Total_Price: Order[i].TotalAmount,
      TotalPayableAmount: Order[i].TotalPayableAmount,
      TotalAmountPaid: Order[i].TotalAmountPaid,
      CouponDiscount: Order[i].CouponDiscount,
      OnlinePayment_Discount:
        Order[i].paymentMethod === "Pay At Spa"
          ? 0
          : ((Order[i].TotalAmount - Order[i].Discount) * 5) / 100,

      Vendor_percentage: Order[i].vendors[0]?.Vendorpercentage,
      Payment_Method: Order[i].paymentMethod,
      Admin_Discount_Percentage:
        Order[i].vendors[0]?.Vendorpercentage -
        (Order[i].paymentMethod === "Pay At Spa" ? 0 : 5),

      Vendor_Share: vendorshare,
      Admin_Share: adminshare2,
    });
  }

  // const datewisefilter = data?.filter(
  //   (item) => item?.Booking_Date === moment(selectedDate).format("DD/MM/YYYY")
  // );

  // const adminshare = datewisefilter?.reduce((a, b) => a + b.Admin_Share, 0);
  // const vendorshare = datewisefilter?.reduce((a, b) => a + b.Total_Amount, 0);
  // const totalamountpaid = datewisefilter?.reduce(
  //   (a, b) => a + b.TotalAmountPaid,
  //   0
  // );
  // const recieve =
  //   totalamountpaid !== 0
  //     ? totalamountpaid - adminshare > 0
  //       ? totalamountpaid - adminshare
  //       : 0
  //     : 0;
  // const pay =
  //   totalamountpaid !== 0
  //     ? totalamountpaid - vendorshare > 0
  //       ? totalamountpaid - vendorshare
  //       : 0
  //     : adminshare;

  // console.log("adminshare", adminshare);
  // console.log("vendorshare", vendorshare);
  // console.log("totalamountpaid", totalamountpaid);
  // console.log("pay", pay);
  // console.log("recieve", recieve);
  // console.log("datewisefilter", datewisefilter);

  //   const offlinepaymentdata = data?.filter(
  //     (item) => item.Payment_Method === "Pay At Spa"
  //   );

  //   const onlinepaymentdata = data?.filter(
  //     (item) => item.Payment_Method === "Online Payment"
  //   );

  //   const pay20Pdata = data?.filter((item) => item.Payment_Method === "Pay 20%");

  //Daywise filter according to payment method
  const datewisefilter = data?.filter(
    (item) => item?.Booking_Date === moment(selectedDate).format("DD/MM/YYYY")
  );

  const payatspa_datewisefilter = datewisefilter?.filter(
    (item) => item?.Payment_Method === "Pay At Spa"
  );
  const onlinePayment_datewisefilter = datewisefilter?.filter(
    (item) => item?.Payment_Method === "Online Payment"
  );
  const pay20P_datewisefilter = datewisefilter?.filter(
    (item) => item?.Payment_Method === "Pay 20%"
  );

  //Caluclatation

  //Admin share
  const total_adminshare = datewisefilter?.reduce(
    (a, b) => a + b.Admin_Share,
    0
  );

  // console.log("total_adminshare", total_adminshare);

  //Vendor Share
  const total_Vendor_Share = datewisefilter?.reduce(
    (a, b) => a + b.Vendor_Share,
    0
  );

  // console.log("data", data);
  // console.log("total_Vendor_Share", total_Vendor_Share);

  //Final
  const total_Amount_Should_Pay = datewisefilter?.reduce(
    (a, b) => a + b.TotalPayableAmount,
    0
  );

  const total_Amount_paid = datewisefilter?.reduce(
    (a, b) => a + b.TotalAmountPaid,
    0
  );

  const Vendor_to_Admin =
    total_Amount_paid == total_adminshare
      ? 0
      : total_Amount_paid < total_adminshare
      ? Math.abs(total_Amount_paid - total_adminshare)
      : 0;

  const Admin_to_Vendor =
    total_Amount_paid == total_adminshare
      ? 0
      : total_Amount_paid > total_adminshare
      ? total_Amount_paid - total_adminshare
      : 0;

  // console.log("Vendor_to_Admin", Vendor_to_Admin);
  // console.log("Admin_to_Vendor", Admin_to_Vendor);
  // console.log("total_Amount_Should_Pay", total_Amount_Should_Pay);
  // console.log("total_Amount_paid", total_Amount_paid);

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: allVendors?.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },

    {
      dataField: "businessName",
      text: "Spa Name",
      formatter: (value, row) => (
        <p>{row?.businessName ? row?.businessName : "-"}</p>
      ),
    },
    {
      dataField: "email",
      text: "Spa Email",
      formatter: (value, row) => <p>{row?.email ? row?.email : "-"}</p>,
    },
    {
      dataField: "phoneNumber",
      text: "Contact Number",
      formatter: (value, row) => (
        <p>{row?.phoneNumber ? row?.phoneNumber : "-"}</p>
      ),
    },
    {
      dataField: "address",
      text: "Spa Address",
      formatter: (value, row) => (
        <p style={{ overflowY: "scroll", height: "60px" }}>
          {row?.address ? row?.address : "-"}
        </p>
      ),
    },
    {
      dataField: "",
      text: "View",
      formatter: (value, row) => (
        <button
          onClick={() => handleShow(row)}
          style={{ border: "none", backgroundColor: "transparent" }}
        >
          <i class="fas fa-eye" style={{ color: "rgb(226, 34, 23)" }}></i>
        </button>
      ),
    },
  ];

  const columns1 = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "Booking_Id",
      text: "Booking Id",
      formatter: (value, row) => <p>{row?.Booking_Id}</p>,
    },
    {
      dataField: "Booking_Date",
      text: "Booking Date",
      formatter: (value, row) => (
        <p>{row?.Booking_Date ? row?.Booking_Date : "-"}</p>
      ),
    },
    {
      dataField: "Total_Price",
      text: "Total Price",
      formatter: (value, row) => (
        <p>{row?.Total_Price ? row?.Total_Price : "-"}</p>
      ),
    },

    {
      dataField: "Spa_Discount",
      text: "Spa Discount",
      formatter: (value, row) => (
        <p>{row?.Spa_Discount ? row?.Spa_Discount : "-"}</p>
      ),
    },
    {
      dataField: "Vendor_percentage",
      text: "Vendor Percentage",
      formatter: (value, row) => (
        <p>{row?.Vendor_percentage ? row?.Vendor_percentage : "-"}</p>
      ),
    },
    {
      dataField: "TotalPayableAmount",
      text: "Total Amount Should Pay",
      formatter: (value, row) => (
        <p>{row?.TotalPayableAmount ? row?.TotalPayableAmount : "-"}</p>
      ),
    },
    {
      dataField: "TotalAmountPaid",
      text: "Total Amount Paid",
      formatter: (value, row) => (
        <p>{row?.TotalAmountPaid ? row?.TotalAmountPaid : "-"}</p>
      ),
    },
    {
      dataField: "Vendor_Share",
      text: "Vendor Share",
      formatter: (value, row) => (
        <p>{row?.Vendor_Share ? row?.Vendor_Share : "-"}</p>
      ),
    },
    {
      dataField: "CouponDiscount",
      text: "Coupon Discount",
      formatter: (value, row) => <p>{row?.CouponDiscount}</p>,
    },
    {
      dataField: "OnlinePayment_Discount",
      text: "Online Payment Discount",
      formatter: (value, row) => <p>{row?.OnlinePayment_Discount}</p>,
    },

    {
      dataField: "Admin_Share",
      text: "Admin Share",
      formatter: (value, row) => (
        <p>{row?.Admin_Share ? row?.Admin_Share : "-"}</p>
      ),
    },
    {
      dataField: "Payment_Method",
      text: "Payment Method",
      formatter: (value, row) => (
        <p
          style={{
            backgroundColor:
              row?.Payment_Method === "Pay At Spa"
                ? "#e22217"
                : row?.Payment_Method === "Online Payment"
                ? "Green"
                : "rgb(132, 207, 7)",
            color: "white",
          }}
        >
          {row?.Payment_Method}
        </p>
      ),
    },
  ];

  // console.log("Order", Order);
  // console.log("vendordata", vendordata);
  return (
    <div className="never-sspa">
      <section>
        <div className="spa-admin-0 mb-2">
          <div className="diff-admin px-3">
            <div className="admin-name-text">
              <h4>Daywise Account Details</h4>
            </div>
          </div>
        </div>
        <div style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}>
          <ToolkitProvider
            keyField="id"
            data={allVendors}
            columns={columns}
            // rowClasses={rowClasses}
            search
            exportCSV
          >
            {(props) => (
              <div>
                <SearchBar {...props.searchProps} />
                {/* <ExportCSVButton
                  {...props.csvProps}
                  style={{
                    backgroundColor: "#e22217",
                    color: "white",
                    marginLeft: "10px",
                  }}
                >
                  Download Excel
                </ExportCSVButton>
             */}
                <hr></hr>
                <br></br>
                <div style={{ overflowX: "scroll", width: "100%" }}>
                  <BootstrapTable
                    striped
                    hover
                    condensed
                    {...props.baseProps}
                    pagination={paginationFactory(options)}
                  />
                </div>
              </div>
            )}
          </ToolkitProvider>
        </div>

        <Modal show={show} onHide={handleClose} className="modalAccount">
          <Modal.Header closeButton>
            <Modal.Title>Daywise Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}
            >
              <ToolkitProvider
                keyField="id"
                data={datewisefilter}
                columns={columns1}
                // rowClasses={rowClasses}
                search
                exportCSV
              >
                {(props) => (
                  <div>
                    <div>
                      <p
                        style={{
                          color: "#e22217",
                          fontWeight: "bold",
                          fontSize: "20px",
                        }}
                      >
                        Vendor Name : {vendordata?.businessName}
                      </p>
                    </div>
                    <SearchBar {...props.searchProps} />
                    <input
                      type="date"
                      style={{ margin: "10px" }}
                      value={selectedDate}
                      onChange={(e) => setselectedDate(e.target.value)}
                    />
                    <ExportCSVButton
                      {...props.csvProps}
                      style={{
                        backgroundColor: "#e22217",
                        color: "white",
                        marginLeft: "10px",
                      }}
                    >
                      Download Excel
                    </ExportCSVButton>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "20px",
                            marginBottom: "0px",
                          }}
                        >
                          Admin share :
                          <b style={{ color: "#e22217" }}>
                            {total_adminshare?.toFixed(2)}
                          </b>
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "20px",
                            marginBottom: "0px",
                          }}
                        >
                          Vendor share :{" "}
                          <b style={{ color: "#e22217" }}>
                            {total_Vendor_Share?.toFixed(2)}
                          </b>
                        </p>
                      </div>
                      <div>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "20px",
                            marginBottom: "0px",
                          }}
                        >
                          Total amount should recieve :{" "}
                          <b style={{ color: "#e22217" }}>
                            {Vendor_to_Admin?.toFixed(2)}
                          </b>
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "20px",
                            marginBottom: "0px",
                          }}
                        >
                          Total amount should pay :{" "}
                          <b style={{ color: "#e22217" }}>
                            {Admin_to_Vendor?.toFixed(2)}
                          </b>
                        </p>
                      </div>
                    </div>
                    <hr></hr>
                    <br></br>
                    <div style={{ overflowX: "scroll", width: "100%" }}>
                      <BootstrapTable
                        striped
                        hover
                        condensed
                        {...props.baseProps}
                        pagination={paginationFactory(options)}
                      />
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </div>
  );
}

export default AdminDaywiseAccountDetails;
