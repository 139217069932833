import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BiBuildingHouse } from "react-icons/bi";
import { BsFillFilePersonFill } from "react-icons/bs";
import { IoCallOutline } from "react-icons/io5";
function Newheader() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [logout, setlogout] = useState(false);

  const logout1 = () => {
    sessionStorage.removeItem("user");
    alert("Signout Success....!");
    window.location.assign("/");
  };

  return (
    <>
      <div>
        <div className="row me-0">
          <Col md={6}>
            <div
              className="log0"
              style={{ paddingLeft: "20px" }}
              onClick={() => window.location.assign("/NewHome")}
            >
              <img
                src="./logo.jpg"
                style={{ width: "110px", height: "38px" }}
              />
            </div>
          </Col>
          <Col md={6}>
            <Row>
              <Col
                md={4}
                style={{ borderLeft: "1px solid #eeeeee" }}
                className="pt-2"
              >
                <div
                  style={{ display: "flex", gap: "10px" }}
                  onClick={() => window.location.assign("/business")}
                >
                  <BiBuildingHouse size={30} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>SPAZO for Business</p>
                    {/* <p>Trusted by 5000 Corporates</p> */}
                  </div>
                </div>
              </Col>
              <Col
                md={4}
                className="pt-2"
                style={{ borderLeft: "1px solid #eeeeee" }}
              >
                <div style={{ display: "flex", gap: "10px" }}>
                  <IoCallOutline size={25} style={{ marginTop: "2px" }} />
                  <div>
                    <p
                      style={{
                        marginBottom: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      0144-6401611
                    </p>
                    <p
                      style={{
                        marginBottom: "0px",
                        fontSize: "15px",
                      }}
                    >
                      Call us to Book now
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                md={4}
                className="pt-2"
                style={{ borderLeft: "1px solid #eeeeee" }}
              >
                {user ? (
                  <>
                    <div
                      style={{ display: "flex", gap: "10px" }}
                      onClick={() => setlogout(!logout)}
                    >
                      <BsFillFilePersonFill size={25} />
                      <>Welcome, {user?.name}</>
                    </div>
                    {logout ? (
                      <>
                        <div
                          style={{
                            backgroundColor: "#e22217",
                            color: "white",
                            margin: "10px",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                          onClick={() =>
                            window.location.assign("/Customerprofile")
                          }
                        >
                          My-Profile
                        </div>
                        <div
                          style={{
                            backgroundColor: "#e22217",
                            color: "white",
                            margin: "10px",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                          onClick={logout1}
                        >
                          Logout
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={() => window.location.assign("/login")}
                  >
                    <BsFillFilePersonFill size={25} />

                    <p
                      style={{
                        marginBottom: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      Login / Signup
                    </p>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    </>
  );
}

export default Newheader;
