import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { FaEye, FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { Col, Row } from "react-bootstrap";
import parse from "html-react-parser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Service() {
  const formdata = new FormData();
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [Viewshow, setViewShow] = useState(false);
  const [Viewdata, setViewdata] = useState({});
  const handleViewClose = () => setViewShow(false);
  const handleViewShow = (row) => {
    setViewShow(true);
    setViewdata(row);
  };

  const [data, setdata] = useState([]);
  const [reasonFR, setreasonFR] = useState("");

  console.log("service", data);

  useEffect(() => {
    getallservices();
  }, []);

  const getallservices = () => {
    axios
      .get("https://spazo.co.in/api/vendor/getService")
      .then(function (response) {
        console.log(response.data);
        setdata(
          response.data.Service?.filter(
            (item) => item?.vendors[0]?.type === "Spa"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const ApproveService = async (data) => {
    try {
      const config = {
        url: "/approveService/" + data?._id,
        method: "post",
        baseURL: "https://spazo.co.in/api/vendor",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          // alert("Service Approved successfully");
          // window.location.reload();
          notify("Service Approved successfully");
          getallservices();
          handleClose();
          handleViewClose();
        } else {
          // alert(res.status.error);
          notify1(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      // alert("Unable to add Details");
      notify1("Unable to add Details, Please try after some time");
    }
  };

  const RejectService = async (data) => {
    if (!reasonFR) {
      notify1("Please give the reason for rejection");
      // alert("Please give the reason for rejection");
    } else {
      try {
        const config = {
          url: "/rejectService",
          method: "post",
          baseURL: "https://spazo.co.in/api/vendor",
          data: {
            reasonFR: reasonFR,
            id: data?._id,
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            // alert("Service Rejected Successfully");
            // window.location.reload();
            notify("Service Rejected Successfully");
            getallservices();
            handleClose1();
            handleViewClose();
          } else {
            // alert(res.status.error);
            notify1(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        notify1("Unable to add Details, Please try after some time");
        // alert("Unable to add Details");
      }
    }
  };

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "3",
        value: 3,
      },
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "Image",
      text: "Image",
      formatter: (value, row) => (
        <img
          src={
            "https://spazo.co.in/Service/" +
            row?.Service_ImageArray[0]?.Service_Image
          }
          width={100}
          height={80}
        />
      ),
    },
    {
      dataField: "Service_Category",
      text: "Category",
      formatter: (value, row) => (
        <p>{row?.Service_Category ? row?.Service_Category : "-"}</p>
      ),
    },
    {
      dataField: "ServiceName",
      text: "Service Name",
      formatter: (value, row) => (
        <p>{row?.ServiceName ? row?.ServiceName : "-"}</p>
      ),
    },
    {
      dataField: "Service_Duration",
      text: "Duration",
      formatter: (value, row) => (
        <p>{row?.Service_Duration ? row?.Service_Duration : "-"}</p>
      ),
    },
    {
      dataField: "ValidFor",
      text: "ValidFor",
      formatter: (value, row) => <p>{row?.ValidFor ? row?.ValidFor : "-"}</p>,
    },
    {
      dataField: "Discount",
      text: "Discount (%)",
      formatter: (value, row) => <p>{row?.Discount ? row?.Discount : "-"}</p>,
    },
    {
      dataField: "Price",
      text: "Price",
      formatter: (value, row) => <p>{row?.Price ? row?.Price : "-"}</p>,
    },
    {
      dataField: "VendorBusinessName",
      text: "Spa Name",
      formatter: (value, row) => (
        <p>{row?.VendorBusinessName ? row?.VendorBusinessName : "-"}</p>
      ),
    },
    {
      dataField: "VendorName",
      text: "Spa Contact Person Name",
      formatter: (value, row) => (
        <p>{row?.VendorName ? row?.VendorName : "-"}</p>
      ),
    },
    {
      dataField: "VendorEmailID",
      text: "Spa Email ID",
      formatter: (value, row) => (
        <p>{row?.VendorEmailID ? row?.VendorEmailID : "-"}</p>
      ),
    },
    {
      dataField: "VendorContactNo",
      text: "Spa Contact No",
      formatter: (value, row) => (
        <p>{row?.VendorContactNo ? row?.VendorContactNo : "-"}</p>
      ),
    },
    {
      dataField: "VendorAddress",
      text: "Spa Address",
      formatter: (value, row) => (
        <p>{row?.VendorAddress ? row?.VendorAddress : "-"}</p>
      ),
    },
    {
      dataField: "VendorGlink",
      text: "Spa Location",
      formatter: (value, row) => (
        <a
          href={row?.VendorGlink}
          target="_blank"
          style={{ overflowX: "scroll", width: "150px", display: "grid" }}
        >
          {row?.VendorGlink ? row?.VendorGlink : "-"}
        </a>
      ),
    },
    {
      dataField: "Approve",
      text: "Status",
      formatter: (value, row) => (
        <p
          style={{
            color:
              row?.Approve === "Orange"
                ? "Yellow"
                : row?.Approve === "Approved"
                ? "Green"
                : "Red",
          }}
        >
          {row?.Approve ? row?.Approve : "-"}
        </p>
      ),
    },
    {
      dataField: "",
      text: "View",
      formatter: (value, row) => (
        <FaEye
          style={{ color: "#e84141" }}
          onClick={() => handleViewShow(row)}
        />
      ),
    },
  ];

  return (
    <>
      <div className="never-sspa">
        <section>
          <div className="spa-admin-0 mb-2">
            <div className="diff-admin px-3">
              <div className="admin-name-text">
                <h4>Spa Service</h4>
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={30000}
            closeOnClick
            pauseOnHover
            draggable
          />
          <div
            style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}
          >
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              // rowClasses={rowClasses}
              search
              exportCSV
            >
              {(props) => (
                <div>
                  <SearchBar {...props.searchProps} />
                  <ExportCSVButton
                    {...props.csvProps}
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                    }}
                  >
                    Download Excel
                  </ExportCSVButton>

                  <hr></hr>
                  <br></br>
                  <div style={{ overflowX: "scroll", width: "100%" }}>
                    <BootstrapTable
                      striped
                      hover
                      condensed
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </section>
      </div>

      <Modal show={Viewshow} onHide={handleViewClose} className="servicemodal">
        <Modal.Header closeButton>
          <h6>Service Details</h6>
        </Modal.Header>
        <Modal.Body style={{ alignItems: "center", display: "contents" }}>
          <div style={{ margin: "20px" }}>
            <Row>
              <Col md={6} style={{ borderRight: "2px solid #e22217" }}>
                <div>
                  <Row>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Category
                    </Col>
                    <Col md={6}>{Viewdata?.Service_Category}</Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Service Name
                    </Col>
                    <Col md={6}>{Viewdata?.ServiceName}</Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Service Duration
                    </Col>
                    <Col md={6}>{Viewdata?.Service_Duration} Min</Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Service Valid For
                    </Col>
                    <Col md={6}>{Viewdata?.ValidFor}</Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Slots
                    </Col>
                    <Col md={6}>{Viewdata?.slots}</Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Price
                    </Col>
                    <Col md={6}>{Viewdata?.Price}Rs</Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ color: "#e22217" }}>
                      GST
                    </Col>
                    <Col md={6}>{Viewdata?.GST} %</Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Service-Tax
                    </Col>
                    <Col md={6}>{Viewdata?.servicetax} %</Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Discount
                    </Col>
                    <Col md={6}>{Viewdata?.Discount} %</Col>
                  </Row>

                  <Row>
                    {Viewdata?.Service_ImageArray?.map((item) => (
                      <Col md={3}>
                        <img
                          src={
                            "https://spazo.co.in/Service/" + item?.Service_Image
                          }
                          width={80}
                          height={80}
                          style={{ borderRadius: "50%" }}
                        />
                      </Col>
                    ))}
                  </Row>
                  {Viewdata?.advantages?.length > 0 ? (
                    <>
                      <h6>Advantages</h6>
                      <table style={{ margin: "20px", width: "80%" }}>
                        <th
                          style={{ color: "#e22217", fontSize: "15px" }}
                          className="tablexxxx"
                        >
                          Name
                        </th>
                        <th
                          style={{ color: "#e22217", fontSize: "15px" }}
                          className="tablexxxx"
                        >
                          Duration
                        </th>
                        <tbody>
                          {Viewdata?.advantages?.map((adv) => (
                            <tr>
                              <td className="tablexxxx">{adv?.adv_name}</td>
                              <td className="tablexxxx">{adv?.adv_duration}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    ""
                  )}

                  {Viewdata?.slots?.length > 0 ? (
                    <>
                      <h6>Slots</h6>
                      <table style={{ margin: "20px", width: "80%" }}>
                        <th
                          style={{ color: "#e22217", fontSize: "15px" }}
                          className="tablexxxx"
                        >
                          Start Time
                        </th>
                        <th
                          style={{ color: "#e22217", fontSize: "15px" }}
                          className="tablexxxx"
                        >
                          End Time
                        </th>
                        <th
                          style={{ color: "#e22217", fontSize: "15px" }}
                          className="tablexxxx"
                        >
                          Total Slots
                        </th>
                        <tbody>
                          {Viewdata?.slots?.map((slot) => (
                            <tr>
                              <td className="tablexxxx">
                                {slot?.slot_from_time}
                              </td>
                              <td className="tablexxxx">
                                {slot?.slot_To_time}
                              </td>
                              <td className="tablexxxx">
                                {slot?.no_Booking_slot}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    ""
                  )}
                  {Viewdata?.Inclusive?.length > 0 ? (
                    <>
                      <h6>Inclusive</h6>
                      <table style={{ margin: "20px", width: "80%" }}>
                        <th
                          style={{ color: "#e22217", fontSize: "15px" }}
                          className="tablexxxx"
                        >
                          Name
                        </th>

                        <tbody>
                          {Viewdata?.Inclusive?.map((inc) => (
                            <tr>
                              <td className="tablexxxx">
                                {inc?.inclusive_name}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              {Viewdata?.vendors?.length > 0 ? (
                <Col md={6}>
                  <h6>Spa Details</h6>
                  <div>
                    <Row>
                      <Col md={6} style={{ color: "#e22217" }}>
                        Name
                      </Col>
                      <Col md={6}>{Viewdata?.vendors[0]?.businessName}</Col>
                    </Row>
                    <Row>
                      <Col md={6} style={{ color: "#e22217" }}>
                        Email-ID
                      </Col>
                      <Col md={6}>{Viewdata?.vendors[0]?.email}</Col>
                    </Row>
                    <Row>
                      <Col md={6} style={{ color: "#e22217" }}>
                        Contact-NO.
                      </Col>
                      <Col md={6}>{Viewdata?.vendors[0]?.phoneNumber}</Col>
                    </Row>
                    <Row>
                      <Col md={6} style={{ color: "#e22217" }}>
                        Address
                      </Col>
                      <Col md={6}>{Viewdata?.vendors[0]?.address}</Col>
                    </Row>
                    <Row>
                      <Col md={6} style={{ color: "#e22217" }}>
                        Pincode
                      </Col>
                      <Col md={6}>{Viewdata?.vendors[0]?.pincode}</Col>
                    </Row>
                    <Row>
                      <Col md={6} style={{ color: "#e22217" }}>
                        Location
                      </Col>
                      <Col md={6} style={{ overflow: "hidden" }}>
                        <a href={Viewdata?.vendors[0]?.Glink} target="_blank">
                          {Viewdata?.vendors[0]?.Glink}
                        </a>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} style={{ color: "rgb(255, 72, 165)" }}>
                        Working Time
                      </Col>
                      <Col md={6} style={{ fontWeight: "500" }}>
                        {Viewdata?.vendors[0]?.formattedStartTime} -{" "}
                        {Viewdata?.vendors[0]?.formattedEndTime}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} style={{ color: "rgb(255, 72, 165)" }}>
                        Working Days
                      </Col>
                      <Col md={6} style={{ fontWeight: "500" }}>
                        <input
                          type="checkbox"
                          checked={Viewdata?.vendors[0]?.mon}
                        />
                        &nbsp;
                        <label
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          Mon
                        </label>{" "}
                        &nbsp;
                        <input
                          type="checkbox"
                          checked={Viewdata?.vendors[0]?.tue}
                        />
                        &nbsp;
                        <label
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          Tue
                        </label>{" "}
                        &nbsp;
                        <input
                          type="checkbox"
                          checked={Viewdata?.vendors[0]?.wed}
                        />
                        &nbsp;
                        <label
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          wed
                        </label>{" "}
                        <br></br>
                        <input
                          type="checkbox"
                          checked={Viewdata?.vendors[0]?.turs}
                        />
                        &nbsp;
                        <label
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          Tur
                        </label>{" "}
                        &nbsp;
                        <input
                          type="checkbox"
                          checked={Viewdata?.vendors[0]?.frid}
                        />
                        &nbsp;
                        <label
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          Fri
                        </label>{" "}
                        &nbsp;
                        <input
                          type="checkbox"
                          checked={Viewdata?.vendors[0]?.sat}
                        />
                        &nbsp;
                        <label
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          Sat
                        </label>{" "}
                        <br></br>
                        <input
                          type="checkbox"
                          checked={Viewdata?.vendors[0]?.sun}
                        />
                        &nbsp;
                        <label
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          Sun
                        </label>{" "}
                        &nbsp;
                      </Col>
                    </Row>
                    <p style={{ color: "rgb(255, 72, 165)" }}>About us</p>
                    <p style={{ overflowY: "scroll", height: "150px" }}>
                      {parse(Viewdata?.vendors[0]?.about)}
                    </p>
                  </div>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {Viewdata?.Approve === "Approved" ? (
            <Button variant="secondary" onClick={handleViewClose}>
              Ok
            </Button>
          ) : (
            <Modal.Footer>
              <Button variant="secondary" onClick={handleShow}>
                Approve
              </Button>
              <Button variant="primary" onClick={handleShow1}>
                Reject
              </Button>
            </Modal.Footer>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ alignItems: "center", display: "contents" }}>
          <p style={{ textAlign: "center" }}>
            Are you sure you want to approve the Service
          </p>
          <Button variant="secondary" onClick={() => ApproveService(Viewdata)}>
            Yes
          </Button>
        </Modal.Body>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ alignItems: "center", display: "contents" }}>
          <p style={{ textAlign: "center" }}>
            If you want to reject the Service, please give the reason for
            rejection
          </p>
          <textarea
            style={{ margin: "10px" }}
            onChange={(e) => setreasonFR(e.target.value)}
          />
          <Button variant="secondary" onClick={() => RejectService(Viewdata)}>
            Reject
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Service;
