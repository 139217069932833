import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { AiOutlineLeft } from "react-icons/ai";
import { BiCalendar } from "react-icons/bi";
import {
  BsFillFilePersonFill,
  BsFillPersonFill,
  BsShieldCheck,
} from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import { isAfter, isBefore, isToday, addDays, format } from "date-fns";
import moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SelectTherapist() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const location = useLocation();
  const { item, service, type } = location.state;
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [Employee, setEmployee] = useState([]);
  const [Therapist, setTherapist] = useState("No Preference");

  useEffect(() => {
    getVendorEmployee();
  }, []);

  const getVendorEmployee = () => {
    axios
      .get("https://spazo.co.in/api/Vendor/getAllEmployee")
      .then(function (response) {
        // console.log(response.data);
        if (type === "New") {
          setEmployee(
            response.data?.Employee?.filter((emp) => {
              return (
                emp?.EmpId &&
                emp?.vendor[emp.vendor?.length - 1]?.VendorId === item?._id &&
                emp?.isDeleted === false &&
                emp?.Status === "Approved" &&
                emp?.DeletedEmployee === "No"
              );
            })
          );
        } else if (type === "brand") {
          setEmployee(
            response.data?.Employee?.filter((emp) => {
              return (
                emp?.EmpId &&
                emp?.vendor[emp.vendor?.length - 1]?.VendorId ===
                  item?.vendors[0]?._id &&
                emp?.isDeleted === false &&
                emp?.Status === "Approved" &&
                emp?.DeletedEmployee === "No"
              );
            })
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // const filterEmployee = Employee?.filter((item) =>
  //   item?.Duties?.find(
  //     (due) =>
  //       due?.workingDate === moment().format("DD/MM/YYYY") && due?.Duty === true
  //   )
  // );

  const filterEmployee = [];

  for (let i = 0; i < Employee.length; i++) {
    console.log(
      "ssd",
      moment(
        Employee[i]?.Duties[Employee[i]?.Duties?.length - 1]?.workingDate
      ).format("DD/MM/YYYY"),
      moment().format("DD/MM/YYYY"),
      moment(
        Employee[i]?.Duties[Employee[i]?.Duties?.length - 1]?.workingDate
      ).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY"),
      Employee[i]?.Duties[Employee[i]?.Duties?.length - 1]?.Duty === true
    );
    if (
      moment(
        Employee[i]?.Duties[Employee[i]?.Duties?.length - 1]?.workingDate
      ).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY") &&
      Employee[i]?.Duties[Employee[i]?.Duties?.length - 1]?.Duty === true
    ) {
      filterEmployee?.push(Employee[i]);
    }
  }

  // console.log("filterEmployee", filterEmployee);
  // console.log("item", Employee);
  const price = service?.Price;
  const GST = (price * service?.GST) / 100;
  const ServiceTax = (price * service?.servicetax) / 100;
  const Discount = (price * service?.Discount) / 100;
  const TotalAmount = price + GST + ServiceTax;

  // console.log("Therapist", Therapist);
  return (
    <>
      <div
        className="row me-0 pt-2 pb-2"
        style={{
          boxShadow: "3px 4px 8px #cbc6c6",
          justifyContent: "space-between",
        }}
      >
        <Col md={2}>
          <div
            className="log0"
            style={{ paddingLeft: "20px" }}
            onClick={() => window.location.assign("/NewHome")}
          >
            <img src="./logo.jpg" style={{ width: "110px", height: "38px" }} />
          </div>
        </Col>
        {!user ? (
          <Col md={2}>
            <div
              style={{ display: "flex", gap: "10px" }}
              onClick={() => window.location.assign("/login")}
            >
              <BsFillFilePersonFill size={25} />
              <p
                style={{
                  marginBottom: "0px",
                  fontWeight: "bold",
                }}
              >
                Login / Signup
              </p>
            </div>
          </Col>
        ) : (
          ""
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={30000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container style={{ margin: "5%" }}>
        {type === "New" ? (
          <Link to="/Newproductdetails" state={{ item: item }}>
            <AiOutlineLeft
              style={{
                color: "#e22217",
                fontSize: "28px",
                marginBottom: "4px",
              }}
            />
            <span
              style={{
                color: "#e22217",
                fontSize: "22px",
                fontWeight: "bold",
              }}
            >
              Select Therapist
            </span>
          </Link>
        ) : (
          <Link to="/Brandproductdetails" state={{ item: item }}>
            <AiOutlineLeft
              style={{
                color: "#e22217",
                fontSize: "28px",
                marginBottom: "4px",
              }}
            />
            <span
              style={{
                color: "#e22217",
                fontSize: "22px",
                fontWeight: "bold",
              }}
            >
              Select Therapist
            </span>
          </Link>
        )}

        <Row className="me-0">
          <Col md={8}>
            <Row>
              <Col md={3} className="mt-4">
                <div
                  style={{
                    border: "1px solid",
                    padding: "30px 5px",
                    justifyContent: "center",
                    display: "grid",
                    borderRadius: "10px",
                    borderColor:
                      Therapist == "No Preference" ? "#e22217" : "#848585",
                  }}
                  onClick={() => setTherapist("No Preference")}
                >
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      padding: "5px",
                    }}
                  >
                    <i class="fas fa-users"></i>
                  </div>
                  <p
                    style={{
                      marginBottom: "0px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Any Therapist
                  </p>
                  <p style={{ marginBottom: "0px", textAlign: "center" }}>
                    Maximum availability
                  </p>
                </div>
              </Col>
              {Employee?.map((item) => (
                <Col md={3} className="mt-4">
                  <div
                    style={{
                      border: "1px solid",
                      padding: "30px 5px",
                      justifyContent: "center",
                      display: "grid",
                      borderRadius: "10px",
                      borderColor:
                        Therapist?._id === item?._id ? "#e22217" : "#848585",
                    }}
                    onClick={() => setTherapist(item)}
                  >
                    {item?.ViewProfileImageToCustomer ? (
                      <img
                        src={"https://spazo.co.in/Employee/" + item?.profile}
                        width={50}
                        height={50}
                        style={{
                          borderRadius: "50%",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                      />
                    ) : (
                      <img
                        src="/Assets/profile.jpg"
                        width={50}
                        height={50}
                        style={{
                          borderRadius: "50%",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                      />
                    )}
                    <p
                      style={{
                        marginBottom: "0px",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {item?.name}
                    </p>
                    <p style={{ marginBottom: "0px", textAlign: "center" }}>
                      {item?.Specialization}
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
          <Col md={4}>
            <div
              style={{
                border: "1px solid rgb(217 212 208)",
                padding: "15px",
                margin: "10px",
                borderRadius: "5px",
              }}
            >
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginBottom: "0px",
                  color: "#e22217",
                }}
              >
                {service?.VendorBusinessName}
              </p>
              <p
                style={{
                  fontSize: "16px",
                  marginBottom: "0px",
                }}
              >
                {service?.VendorAddress}
              </p>
              <div style={{ marginTop: "8px" }}>
                {/* <span>Ratings :</span>&nbsp; */}
                <span
                  style={{
                    backgroundColor: "#1ab64f",
                    color: "white",
                    padding: "0px 5px",
                    width: "10%",
                  }}
                >
                  {isNaN(
                    item?.reviews?.reduce((a, rev) => a + rev.rating, 0) /
                      item?.reviews?.length
                  )
                    ? 0
                    : (
                        item?.reviews?.reduce((a, rev) => a + rev.rating, 0) /
                        item?.reviews?.length
                      )?.toFixed(1)}
                  {/* {(
                    item?.reviews?.reduce((a, rev) => a + rev.rating, 0) /
                    item?.reviews?.length
                  )?.toFixed(1)} */}
                  &nbsp;
                  <i class="fas fa-star"></i>
                </span>
                <h5 style={{ marginTop: "20px" }}>
                  {" "}
                  {service?.ServiceName}({service?.Service_Duration} min)
                  {service?.advantages?.map((adv) => (
                    <>
                      <span>
                        + {adv.adv_name} ({adv.adv_duration} min)
                      </span>
                    </>
                  ))}
                </h5>

                {Therapist == "No Preference" ? (
                  <div style={{ display: "flex" }}>
                    <i class="fas fa-user" style={{ margin: "5px" }}></i>
                    <p>No Preference</p>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    {Therapist?.ViewProfileImageToCustomer ? (
                      <img
                        src={
                          "https://spazo.co.in/Employee/" + Therapist?.profile
                        }
                        width={50}
                        height={50}
                        onClick={() =>
                          window.open(
                            "https://spazo.co.in/Employee/" + Therapist.profile
                          )
                        }
                        style={{ borderRadius: "50%" }}
                      />
                    ) : (
                      <img
                        src="/Assets/profile.jpg"
                        width={50}
                        height={50}
                        style={{
                          borderRadius: "50%",
                        }}
                      />
                    )}

                    <div>
                      <p style={{ marginBottom: "0px", fontWeight: "700" }}>
                        {Therapist?.name}
                      </p>
                      <p style={{ marginBottom: "0px" }}>
                        {Therapist?.Specialization}
                      </p>
                    </div>
                  </div>
                )}
                <hr></hr>
                <div>
                  <Row className="me-0">
                    <Col md={6}>Price</Col>
                    <Col md={6}>
                      <p
                        style={{
                          textAlign: "right",
                          marginBottom: "0px",
                          fontWeight: "bold",
                        }}
                      >
                        {price}
                      </p>
                    </Col>
                  </Row>{" "}
                  <Row className="me-0">
                    <Col md={6}>GST</Col>
                    <Col md={6}>
                      <p
                        style={{
                          textAlign: "right",
                          fontWeight: "bold",
                          marginBottom: "0px",
                        }}
                      >
                        {GST}
                      </p>
                    </Col>
                  </Row>{" "}
                  <Row className="me-0">
                    <Col md={6}>Service Tax</Col>
                    <Col md={6}>
                      <p
                        style={{
                          textAlign: "right",
                          fontWeight: "bold",
                          marginBottom: "0px",
                        }}
                      >
                        {ServiceTax}
                      </p>
                    </Col>
                  </Row>{" "}
                  <Row className="me-0">
                    <Col md={6}>Discount</Col>
                    <Col md={6}>
                      <p
                        style={{
                          textAlign: "right",
                          marginBottom: "0px",
                          fontWeight: "bold",
                        }}
                      >
                        {Discount}
                      </p>
                    </Col>
                  </Row>{" "}
                  <hr></hr>
                  <Row className="me-0">
                    <Col md={6}>Total Amount</Col>
                    <Col md={6}>
                      <p
                        style={{
                          textAlign: "right",
                          marginBottom: "0px",
                          fontWeight: "bold",
                        }}
                      >
                        {TotalAmount}
                      </p>
                    </Col>
                  </Row>
                  <Row className="me-0">
                    <Col md={6}>Total Discount</Col>
                    <Col md={6}>
                      <p
                        style={{
                          textAlign: "right",
                          marginBottom: "0px",
                          fontWeight: "bold",
                        }}
                      >
                        {Discount}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
              <Link
                style={{
                  backgroundColor: "rgb(226, 34, 23)",
                  color: "white",
                  border: "none",
                  marginTop: "30px",
                  padding: "5px 20px",
                  borderRadius: "5px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
                to="/Selectdate"
                state={{
                  item: item,
                  service: service,
                  Therapist: Therapist,
                  type: type,
                }}
              >
                Continue
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SelectTherapist;
