import React from "react";

function Allimages() {
  document.title = "SPA-All images";
  return (
    <>
      <div className="continer-fluid">
        <div className="all-images-0">
          <div className="diff-pordduct-09">
            <img
              src="../../Assets/images/photo1.webp"
              alt="spa-images"
              className="dif-img"
            />
            <div className="row me-0">
              <div className="col-md-6 p-0">
                <img
                  src="../../Assets/images/photo2.webp"
                  alt="spa-images"
                  className="dif-img-1"
                />
              </div>
              <div className="col-md-6 p-0">
                <img
                  src="../../Assets/images/photo3.webp"
                  alt="spa-images"
                  className="dif-img-1"
                />
              </div>
            </div>
            <div>
              <img
                src="../../Assets/images/photo4.webp"
                alt="spa-images"
                className="dif-img"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Allimages;
