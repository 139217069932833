import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function SideBar() {
  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const [dashboard, setdashboard] = useState(true);
  const [Sub_Admin, setSub_Admin] = useState(false);
  const [CreateSubadmin, setCreateSubadmin] = useState(false);
  const [Subadmindetails, setSubadmindetails] = useState(false);
  const [banner, setbanner] = useState(false);
  const [vendor, setvendor] = useState(false);
  const [editVendorRequest, seteditVendorRequest] = useState(false);
  const [AdminTherapist, setAdminTherapist] = useState(false);
  const [category, setcategory] = useState(false);
  const [Advantages, setAdvantages] = useState(false);
  const [Promocode, setPromocode] = useState(false);
  const [Adminservice, setAdminservice] = useState(false);
  const [service, setservice] = useState(false);
  const [reviews, setreviews] = useState(false);
  const [booking, setbooking] = useState(false);
  const [addvendor, setaddvendor] = useState(false);
  const [account, setaccount] = useState(false);
  const [DaywiseAccountDetails, setDaywiseAccountDetails] = useState(false);
  const [Customer, setCustomer] = useState(false);
  const [message, setmessage] = useState(false);
  const [spa, setspa] = useState(false);
  const [salon, setsalon] = useState(false);
  const [beautycareproducts, setbeautycareproducts] = useState(false);

  //Salon

  const [SalonVendor, setSalonVendor] = useState(false);
  const [AllSalonVendor, setAllSalonVendor] = useState([]);
  const [Salonservice, setSalonservice] = useState(false);
  const [AllSalonservice, setAllSalonservice] = useState([]);
  const [SalonTherapist, setSalonTherapist] = useState(false);
  const [AllSalonTherapist, setAllSalonTherapist] = useState([]);
  const [SalonVendorP, setSalonVendorP] = useState(false);
  const [SalonBooking, setSalonBooking] = useState(false);
  const [SalonDaywiseAccountDetails, setSalonDaywiseAccountDetails] =
    useState(false);
  const [SalonReview, setSalonReview] = useState(false);

  const [vendordata, setvendordata] = useState([]);
  const [Employeedata, setEmployeedata] = useState([]);
  const [profileupdaterequest, setprofileupdaterequest] = useState([]);
  const [Salonprofileupdaterequest, Salonsetprofileupdaterequest] = useState(
    []
  );
  const [allservices, setallservices] = useState([]);

  const [SpaDeleteTherapist, setSpaDeleteTherapist] = useState([]);
  const [SalonDeleteTherapist, setSalonDeleteTherapist] = useState([]);

  useEffect(() => {
    getallVendor();
    getEmployee();
    getprofileupdaterequest();
    getallservices();
    getDeletedEmployee();
  }, []);

  const getallVendor = () => {
    axios
      .get("https://spazo.co.in/api/vendor/allVendors")
      .then(function (response) {
        console.log(response.data);
        setvendordata(
          response.data.allVendors?.filter(
            (item) => item.approved === "Pending" && item?.type === "Spa"
          )
        );
        setAllSalonVendor(
          response.data.allVendors?.filter(
            (item) => item.approved === "Pending" && item?.type === "Salon"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getEmployee = () => {
    axios
      .get("https://spazo.co.in/api/Vendor/getAllEmployee")
      .then(function (response) {
        console.log(response.data);
        setEmployeedata(
          response.data.Employee.filter(
            (item) =>
              item.Status === "Pending" && item?.vendor[0]?.Type === "Spa"
          )
        );
        setAllSalonTherapist(
          response.data.Employee.filter(
            (item) =>
              item.Status === "Pending" && item?.vendor[0]?.Type === "Salon"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getprofileupdaterequest = () => {
    axios
      .get("https://spazo.co.in/api/Vendor/getprofileupdaterequest")
      .then(function (response) {
        // console.log("editrequest", response.data);
        setprofileupdaterequest(
          response.data.profile?.filter(
            (item) => item.Editapproved === "Pending" && item?.type === "Spa"
          )
        );
        Salonsetprofileupdaterequest(
          response.data.profile?.filter(
            (item) => item.Editapproved === "Pending" && item?.type === "Salon"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getallservices = () => {
    axios
      .get("https://spazo.co.in/api/vendor/getService")
      .then(function (response) {
        console.log(response.data);
        setallservices(
          response.data.Service?.filter(
            (item) =>
              item.Approve === "Pending" && item?.vendors[0]?.type === "Spa"
          )
        );
        setAllSalonservice(
          response.data.Service?.filter(
            (item) =>
              item.Approve === "Pending" && item?.vendors[0]?.type === "Salon"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getDeletedEmployee = () => {
    axios
      .get("https://spazo.co.in/api/Vendor/getDeletedEmployee")
      .then(function (response) {
        // console.log("allDeletedEmployee", response.data.allDeletedEmployee);

        setSpaDeleteTherapist(
          response.data.allDeletedEmployee?.filter(
            (item) =>
              item?.therapist[0]?.vendor[item.therapist[0]?.vendor?.length - 1]
                ?.Type === "Spa"
          )
        );
        setSalonDeleteTherapist(
          response.data.allDeletedEmployee?.filter(
            (item) =>
              item?.therapist[0]?.vendor[item.therapist[0]?.vendor?.length - 1]
                ?.Type === "Salon"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="si09">
      <div className="lo-ad d-flex">
        <img
          src="./logo.jpg"
          alt="adminlogo"
          style={{
            width: "160px",
            height: "63px",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "10px",
          }}
        />
        {/* <div className="mt-3" style={{ lineHeight: "25px" }}>
          <div
            style={{
              fontFamily: "Piazzolla, serif",
              fontSize: "32px",
              fontWeight: "800",
            }}
          >
            SPA{" "}
          </div>
          <div style={{ fontFamily: "revert", fontSize: "15px" }}>Admin</div>
        </div> */}
      </div>

      <ul className="example">
        <Link to="/dashboard">
          <li className={`a-ele ${dashboard ? "active-0" : ""}`}>Dashboard</li>
        </Link>
        {admin?.Subadmin ? (
          <Link to="" onClick={() => setSub_Admin(!Sub_Admin)}>
            <li className={`a-ele ${Sub_Admin ? "active-0" : "null"}`}>
              Sub-Admin &nbsp;&nbsp;<i class="fas fa-caret-down"></i>
            </li>
          </Link>
        ) : (
          ""
        )}
        {Sub_Admin ? (
          <>
            <Link to="/createSubadmin">
              <li
                className={`a-ele ${CreateSubadmin ? "active-0" : "null"}`}
                style={{ backgroundColor: "Green" }}
              >
                Create-Subadmin
              </li>
            </Link>
            <Link to="/subadmindetails">
              <li
                className={`a-ele ${banner ? "active-0" : "null"}`}
                style={{ backgroundColor: "Green" }}
              >
                Subadmin Details
              </li>
            </Link>
          </>
        ) : (
          <></>
        )}

        {/* {admin?.banner1 || admin?.banner2 || admin?.banner3 ? (
          <Link to="#">
            <li
              className={`a-ele ${banner ? "active-0" : "null"}`}
              onClick={() => {
                setdashboard(false);
                setSub_Admin(false);
                setvendor(false);
                seteditVendorRequest(false);
                setCreateSubadmin(false);
                setSubadmindetails(false);
                setbanner(!banner);
                setcategory(false);
                setAdvantages(false);
                setPromocode(false);
                setAdminservice(false);
                setservice(false);
                setreviews(false);
                setbooking(false);
                setaddvendor(false);
                setaccount(false);
                setDaywiseAccountDetails(false)
                setCustomer(false);
                setmessage(false);
              }}
            >
              Banner
            </li>
          </Link>
        ) : (
          ""
        )} */}
        {/* {banner ? (
          <> */}
        {admin?.banner1 ? (
          <Link to="/banner">
            <li className={`a-ele ${banner ? "active-0" : "null"}`}>Banner</li>
          </Link>
        ) : (
          ""
        )}
        {/* {admin?.banner2 ? (
              <Link to="/banner1">
                <li
                  className={`a-ele ${banner ? "active-0" : "null"}`}
                  onClick={() => {
                    setdashboard(false);
                    setSub_Admin(false);
                    setvendor(false);
                    seteditVendorRequest(false);
                     setAdminTherapist(false);
                    setCreateSubadmin(false);
                    setSubadmindetails(false);
                    setbanner(true);
                    setcategory(false);
                    setAdvantages(false);
                    setPromocode(false);
                    setAdminservice(false);
                    setservice(false);
                    setreviews(false);
                    setbooking(false);
                    setaddvendor(false);
                    setaccount(false);
                    setDaywiseAccountDetails(false)
                    setCustomer(false);
                    setmessage(false);
                  }}
                >
                  Banner-2
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.banner3 ? (
              <Link to="/banner2">
                <li
                  className={`a-ele ${banner ? "active-0" : "null"}`}
                  onClick={() => {
                    setdashboard(false);
                    setSub_Admin(false);
                    setvendor(false);
                    seteditVendorRequest(false);
                     setAdminTherapist(false);
                    setCreateSubadmin(false);
                    setSubadmindetails(false);
                    setbanner(true);
                    setcategory(false);
                    setAdvantages(false);
                    setPromocode(false);
                    setAdminservice(false);
                    setservice(false);
                    setreviews(false);
                    setbooking(false);
                    setaddvendor(false);
                    setaccount(false);
                    setDaywiseAccountDetails(false)
                    setCustomer(false);
                    setmessage(false);
                  }}
                >
                  Banner-3
                </li>
              </Link>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )} */}
        {admin?.category ? (
          <Link to="/category">
            <li className={`a-ele ${category ? "active-0" : "null"}`}>
              Category
            </li>
          </Link>
        ) : (
          ""
        )}
        {admin?.Adminservice ? (
          <Link to="/Adminservice">
            <li className={`a-ele ${Adminservice ? "active-0" : "null"}`}>
              Service
            </li>
          </Link>
        ) : (
          ""
        )}
        {admin?.Advantages ? (
          <Link to="/Advantages">
            <li className={`a-ele ${Advantages ? "active-0" : "null"}`}>
              Advantages
            </li>
          </Link>
        ) : (
          ""
        )}
        {admin?.Promocode ? (
          <Link to="/Promocode">
            <li className={`a-ele ${Promocode ? "active-0" : "null"}`}>
              Promo Code
            </li>
          </Link>
        ) : (
          ""
        )}
        {admin?.TopBrands ? (
          <Link to="/topbrands">
            <li className={`a-ele ${Customer ? "active-0" : "null"}`}>
              Top Brands
            </li>
          </Link>
        ) : (
          ""
        )}
        {admin?.StarHotels ? (
          <Link to="/StarHotels">
            <li className={`a-ele ${Customer ? "active-0" : "null"}`}>
              Star Hotels
            </li>
          </Link>
        ) : (
          ""
        )}
        {admin?.MallandShopping ? (
          <Link to="/MallandShopping">
            <li className={`a-ele ${Customer ? "active-0" : "null"}`}>
              Mall and Shopping
            </li>
          </Link>
        ) : (
          ""
        )}

        {admin?.Customers ? (
          <Link to="/customers">
            <li className={`a-ele ${Customer ? "active-0" : "null"}`}>
              Customers
            </li>
          </Link>
        ) : (
          ""
        )}
        {admin?.OffDutyTherapist ? (
          <Link to="/OffdutyTherapist">
            <li className={`a-ele ${Customer ? "active-0" : "null"}`}>
              Off Duty Therapist
            </li>
          </Link>
        ) : (
          ""
        )}
        <Link to="" onClick={() => setspa(!spa)}>
          <li className={`a-ele ${Sub_Admin ? "active-0" : "null"}`}>
            Spa &nbsp;&nbsp;<i class="fas fa-caret-down"></i>
          </li>
        </Link>

        {spa ? (
          <>
            {admin?.Vendor ? (
              <Link to="/vendor">
                <li
                  className={`a-ele ${vendor ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                >
                  Vendor{" "}
                  {vendordata?.length > 0 ? (
                    <span
                      style={{
                        backgroundColor: "white",
                        color: "#e22217",
                        padding: "3px 10px",
                        textAlign: "center",
                        borderRadius: "50%",
                      }}
                    >
                      {vendordata?.length}
                    </span>
                  ) : (
                    ""
                  )}
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.editVendorRequest ? (
              <Link to="/editVendorRequest">
                <li
                  className={`a-ele ${editVendorRequest ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                >
                  Edit Vendor Request
                  {profileupdaterequest?.length > 0 ? (
                    <span
                      style={{
                        backgroundColor: "white",
                        color: "#e22217",
                        padding: "3px 10px",
                        textAlign: "center",
                        borderRadius: "50%",
                      }}
                    >
                      {profileupdaterequest?.length}
                    </span>
                  ) : (
                    ""
                  )}
                </li>
              </Link>
            ) : (
              ""
            )}

            {admin?.AdminTherapist ? (
              <Link to="/AdminTherapist">
                <li
                  className={`a-ele ${AdminTherapist ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                >
                  Therapist{" "}
                  {Employeedata?.length > 0 ? (
                    <span
                      style={{
                        backgroundColor: "white",
                        color: "#e22217",
                        padding: "3px 10px",
                        textAlign: "center",
                        borderRadius: "50%",
                      }}
                    >
                      {Employeedata?.length}
                    </span>
                  ) : (
                    ""
                  )}
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.SpaDeleteTherapist ? (
              <Link to="/DeleteTherapist">
                <li
                  className={`a-ele ${AdminTherapist ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                >
                  Delete Therapist{" "}
                  {SpaDeleteTherapist?.length > 0 ? (
                    <span
                      style={{
                        backgroundColor: "white",
                        color: "#e22217",
                        padding: "3px 10px",
                        textAlign: "center",
                        borderRadius: "50%",
                      }}
                    >
                      {SpaDeleteTherapist?.length}
                    </span>
                  ) : (
                    ""
                  )}
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.service ? (
              <Link to="/service">
                <li
                  className={`a-ele ${service ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                >
                  Vendor Service{" "}
                  {allservices?.length > 0 ? (
                    <span
                      style={{
                        backgroundColor: "white",
                        color: "#e22217",
                        padding: "3px 10px",
                        textAlign: "center",
                        borderRadius: "50%",
                      }}
                    >
                      {allservices?.length}
                    </span>
                  ) : (
                    ""
                  )}
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.Reviews ? (
              <Link to="/reviews">
                <li
                  className={`a-ele ${reviews ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                >
                  Reviews
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.Booking ? (
              <Link to="/booking">
                <li
                  className={`a-ele ${booking ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                >
                  Booking
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.Vendorpercentage ? (
              <Link to="/AddvendorP">
                <li
                  className={`a-ele ${addvendor ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                >
                  Add Vendor %
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.Accountdetails ? (
              <Link to="/AdminAccountdetails">
                <li
                  className={`a-ele ${account ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                >
                  Account Details
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.DaywiseAccountdetails ? (
              <Link to="/AdminDaywiseAccountDetails">
                <li
                  className={`a-ele ${
                    DaywiseAccountDetails ? "active-0" : "null"
                  }`}
                  style={{ backgroundColor: "Green" }}
                >
                  Daywise Account Details
                </li>
              </Link>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        <Link to="" onClick={() => setsalon(!salon)}>
          <li className={`a-ele ${Sub_Admin ? "active-0" : "null"}`}>
            Salon &nbsp;&nbsp;<i class="fas fa-caret-down"></i>
          </li>
        </Link>

        {salon ? (
          <>
            {admin?.SalonVendor ? (
              <Link to="/SalonVendor">
                <li
                  className={`a-ele ${vendor ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                >
                  Vendor{" "}
                  {AllSalonVendor?.length > 0 ? (
                    <span
                      style={{
                        backgroundColor: "white",
                        color: "#e22217",
                        padding: "3px 10px",
                        textAlign: "center",
                        borderRadius: "50%",
                      }}
                    >
                      {AllSalonVendor?.length}
                    </span>
                  ) : (
                    ""
                  )}
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.SaloneditVendorRequest ? (
              <Link to="/SalonEditVendorRequest">
                <li
                  className={`a-ele ${editVendorRequest ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                >
                  Edit Vendor Request
                  {Salonprofileupdaterequest?.length > 0 ? (
                    <span
                      style={{
                        backgroundColor: "white",
                        color: "#e22217",
                        padding: "3px 10px",
                        textAlign: "center",
                        borderRadius: "50%",
                      }}
                    >
                      {Salonprofileupdaterequest?.length}
                    </span>
                  ) : (
                    ""
                  )}
                </li>
              </Link>
            ) : (
              ""
            )}

            {admin?.SalonAdminTherapist ? (
              <Link to="/SalonTherapist">
                <li
                  className={`a-ele ${AdminTherapist ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                >
                  Therapist{" "}
                  {AllSalonTherapist?.length > 0 ? (
                    <span
                      style={{
                        backgroundColor: "white",
                        color: "#e22217",
                        padding: "3px 10px",
                        textAlign: "center",
                        borderRadius: "50%",
                      }}
                    >
                      {AllSalonTherapist?.length}
                    </span>
                  ) : (
                    ""
                  )}
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.SalonDeleteTherapist ? (
              <Link to="/SalonDeleteTherapist">
                <li
                  className={`a-ele ${AdminTherapist ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                >
                  Delete Therapist{" "}
                  {SalonDeleteTherapist?.length > 0 ? (
                    <span
                      style={{
                        backgroundColor: "white",
                        color: "#e22217",
                        padding: "3px 10px",
                        textAlign: "center",
                        borderRadius: "50%",
                      }}
                    >
                      {SalonDeleteTherapist?.length}
                    </span>
                  ) : (
                    ""
                  )}
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.Salonservice ? (
              <Link to="/Salonservice">
                <li
                  className={`a-ele ${service ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                >
                  Vendor Service{" "}
                  {AllSalonservice?.length > 0 ? (
                    <span
                      style={{
                        backgroundColor: "white",
                        color: "#e22217",
                        padding: "3px 10px",
                        textAlign: "center",
                        borderRadius: "50%",
                      }}
                    >
                      {AllSalonservice?.length}
                    </span>
                  ) : (
                    ""
                  )}
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.SalonReviews ? (
              <Link to="/SalonReview">
                <li
                  className={`a-ele ${reviews ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                >
                  Reviews
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.SalonBooking ? (
              <Link to="/SalonBooking">
                <li
                  className={`a-ele ${booking ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                >
                  Booking
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.SalonVendorpercentage ? (
              <Link to="/SalonVendorP">
                <li
                  className={`a-ele ${addvendor ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                >
                  Add Vendor %
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.SalonAccountdetails ? (
              <Link to="/Salonaccountdetails">
                <li
                  className={`a-ele ${account ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                >
                  Account Details
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.SalonDaywiseAccountdetails ? (
              <Link to="/SalonDaywiseAccountDetails">
                <li
                  className={`a-ele ${account ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                >
                  Daywise Account Details
                </li>
              </Link>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        {admin?.Subadmin ? (
          <Link to="">
            <li className={`a-ele ${Sub_Admin ? "active-0" : "null"}`}>
              Beauty care products &nbsp;&nbsp;<i class="fas fa-caret-down"></i>
            </li>
          </Link>
        ) : (
          ""
        )}

        {/* {beautycareproducts ? (
          <>
            {admin?.Vendor ? (
              <Link to="/vendor">
                <li
                  className={`a-ele ${vendor ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                  onClick={() => {
                    setdashboard(false);
                    setSub_Admin(false);
                    setCreateSubadmin(false);
                    setSubadmindetails(false);
                    setvendor(true);
                    seteditVendorRequest(false);
                    setAdminTherapist(false);
                    setbanner(false);
                    setcategory(false);
                    setAdvantages(false);
                    setPromocode(false);
                    setAdminservice(false);
                    setservice(false);
                    setreviews(false);
                    setbooking(false);
                    setaddvendor(false);
                    setaccount(false);
                    setDaywiseAccountDetails(false);
                    setCustomer(false);
                    setmessage(false);
                    setspa(false);
                    setsalon(false);
                  }}
                >
                  Vendor{" "}
                  {vendordata?.length > 0 ? (
                    <span
                      style={{
                        backgroundColor: "white",
                        color: "#e22217",
                        padding: "3px 10px",
                        textAlign: "center",
                        borderRadius: "50%",
                      }}
                    >
                      {vendordata?.length}
                    </span>
                  ) : (
                    ""
                  )}
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.editVendorRequest ? (
              <Link to="/editVendorRequest">
                <li
                  className={`a-ele ${editVendorRequest ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                  onClick={() => {
                    setdashboard(false);
                    setSub_Admin(false);
                    setCreateSubadmin(false);
                    setSubadmindetails(false);
                    setvendor(false);
                    seteditVendorRequest(true);
                    setAdminTherapist(false);
                    setbanner(false);
                    setcategory(false);
                    setAdvantages(false);
                    setPromocode(false);
                    setAdminservice(false);
                    setservice(false);
                    setreviews(false);
                    setbooking(false);
                    setaddvendor(false);
                    setaccount(false);
                    setDaywiseAccountDetails(false);
                    setCustomer(false);
                    setmessage(false);
                    setspa(false);
                    setsalon(false);
                  }}
                >
                  Edit Vendor Request
                  {profileupdaterequest?.length > 0 ? (
                    <span
                      style={{
                        backgroundColor: "white",
                        color: "#e22217",
                        padding: "3px 10px",
                        textAlign: "center",
                        borderRadius: "50%",
                      }}
                    >
                      {profileupdaterequest?.length}
                    </span>
                  ) : (
                    ""
                  )}
                </li>
              </Link>
            ) : (
              ""
            )}

            {admin?.AdminTherapist ? (
              <Link to="/AdminTherapist">
                <li
                  className={`a-ele ${AdminTherapist ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                  onClick={() => {
                    setdashboard(false);
                    setSub_Admin(false);
                    setCreateSubadmin(false);
                    setSubadmindetails(false);
                    setvendor(false);
                    seteditVendorRequest(false);
                    setAdminTherapist(true);
                    setbanner(false);
                    setcategory(false);
                    setAdvantages(false);
                    setPromocode(false);
                    setAdminservice(false);
                    setservice(false);
                    setreviews(false);
                    setbooking(false);
                    setaddvendor(false);
                    setaccount(false);
                    setDaywiseAccountDetails(false);
                    setCustomer(false);
                    setmessage(false);
                    setspa(false);
                    setsalon(false);
                  }}
                >
                  Therapist{" "}
                  {Employeedata?.length > 0 ? (
                    <span
                      style={{
                        backgroundColor: "white",
                        color: "#e22217",
                        padding: "3px 10px",
                        textAlign: "center",
                        borderRadius: "50%",
                      }}
                    >
                      {Employeedata?.length}
                    </span>
                  ) : (
                    ""
                  )}
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.service ? (
              <Link to="/service">
                <li
                  className={`a-ele ${service ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                  onClick={() => {
                    setdashboard(false);
                    setSub_Admin(false);
                    setvendor(false);
                    seteditVendorRequest(false);
                    setAdminTherapist(false);
                    setCreateSubadmin(false);
                    setSubadmindetails(false);
                    setbanner(false);
                    setcategory(false);
                    setAdvantages(false);
                    setPromocode(false);
                    setAdminservice(false);
                    setservice(true);
                    setreviews(false);
                    setbooking(false);
                    setaddvendor(false);
                    setaccount(false);
                    setDaywiseAccountDetails(false);
                    setCustomer(false);
                    setmessage(false);
                    setspa(false);
                    setsalon(false);
                  }}
                >
                  Vendor Service{" "}
                  {allservices?.length > 0 ? (
                    <span
                      style={{
                        backgroundColor: "white",
                        color: "#e22217",
                        padding: "3px 10px",
                        textAlign: "center",
                        borderRadius: "50%",
                      }}
                    >
                      {allservices?.length}
                    </span>
                  ) : (
                    ""
                  )}
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.Reviews ? (
              <Link to="/reviews">
                <li
                  className={`a-ele ${reviews ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                  onClick={() => {
                    setdashboard(false);
                    setSub_Admin(false);
                    setvendor(false);
                    seteditVendorRequest(false);
                    setAdminTherapist(false);
                    setCreateSubadmin(false);
                    setSubadmindetails(false);
                    setbanner(false);
                    setcategory(false);
                    setAdvantages(false);
                    setPromocode(false);
                    setAdminservice(false);
                    setservice(false);
                    setreviews(true);
                    setbooking(false);
                    setaddvendor(false);
                    setaccount(false);
                    setDaywiseAccountDetails(false);
                    setCustomer(false);
                    setmessage(false);
                    setspa(false);
                    setsalon(false);
                  }}
                >
                  Reviews
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.Booking ? (
              <Link to="/booking">
                <li
                  className={`a-ele ${booking ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                  onClick={() => {
                    setdashboard(false);
                    setSub_Admin(false);
                    setvendor(false);
                    seteditVendorRequest(false);
                    setAdminTherapist(false);
                    setCreateSubadmin(false);
                    setSubadmindetails(false);
                    setbanner(false);
                    setcategory(false);
                    setAdvantages(false);
                    setPromocode(false);
                    setAdminservice(false);
                    setservice(false);
                    setreviews(false);
                    setbooking(true);
                    setaddvendor(false);
                    setaccount(false);
                    setDaywiseAccountDetails(false);
                    setCustomer(false);
                    setmessage(false);
                    setspa(false);
                    setsalon(false);
                  }}
                >
                  Booking
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.Vendorpercentage ? (
              <Link to="/AddvendorP">
                <li
                  className={`a-ele ${addvendor ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                  onClick={() => {
                    setdashboard(false);
                    setSub_Admin(false);
                    setvendor(false);
                    seteditVendorRequest(false);
                    setAdminTherapist(false);
                    setCreateSubadmin(false);
                    setSubadmindetails(false);
                    setbanner(false);
                    setcategory(false);
                    setAdvantages(false);
                    setPromocode(false);
                    setAdminservice(false);
                    setservice(false);
                    setreviews(false);
                    setbooking(false);
                    setaddvendor(true);
                    setaccount(false);
                    setDaywiseAccountDetails(false);
                    setCustomer(false);
                    setmessage(false);
                    setspa(false);
                    setsalon(false);
                  }}
                >
                  Add Vendor %
                </li>
              </Link>
            ) : (
              ""
            )}
            {admin?.Accountdetails ? (
              <Link to="/AdminAccountdetails">
                <li
                  className={`a-ele ${account ? "active-0" : "null"}`}
                  style={{ backgroundColor: "Green" }}
                  onClick={() => {
                    setdashboard(false);
                    setSub_Admin(false);
                    setvendor(false);
                    seteditVendorRequest(false);
                    setAdminTherapist(false);
                    setCreateSubadmin(false);
                    setSubadmindetails(false);
                    setbanner(false);
                    setcategory(false);
                    setAdvantages(false);
                    setPromocode(false);
                    setAdminservice(false);
                    setservice(false);
                    setreviews(false);
                    setbooking(false);
                    setaddvendor(false);
                    setaccount(true);
                    setDaywiseAccountDetails(false);
                    setCustomer(false);
                    setmessage(false);
                    setspa(false);
                    setsalon(false);
                  }}
                >
                  Account Details
                </li>
              </Link>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )} */}

        {admin?.Message ? (
          <Link to="/message">
            <li
              className={`a-ele ${message ? "active-0" : "null"}`}
              onClick={() => {
                setdashboard(false);
                setSub_Admin(false);
                setvendor(false);
                seteditVendorRequest(false);
                setAdminTherapist(false);
                setCreateSubadmin(false);
                setSubadmindetails(false);
                setbanner(false);
                setcategory(false);
                setAdvantages(false);
                setPromocode(false);
                setAdminservice(false);
                setservice(false);
                setreviews(false);
                setbooking(false);
                setaddvendor(false);
                setaccount(false);
                setDaywiseAccountDetails(false);
                setCustomer(false);
                setmessage(true);
                setspa(false);
                setsalon(false);
                setbeautycareproducts(false);
              }}
            >
              Message
            </li>
          </Link>
        ) : (
          ""
        )}
      </ul>
    </div>
  );
}

export default SideBar;
