import React from "react";
import "../Styles/login.css";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";

function Register() {
  document.title = "SPA-Register";
  const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    "& > :not(style) + :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));
  

  return (
    <>
      <div className="login">
        <div className="row me-0">
          <div className="col-md-6 p-0">
            <div className="fresh" style={{padding:"20px"}}>
              <img
                src="./logo.jpg"
                alt="logo"
                style={{ width: "100px", height: "100px" }}
              />
              <div className="customers">
                <h6>SPA for customers</h6>
                <p>
                  Create an account in to book and manage your
                  appointments.
                </p>
              </div>
              <div className="cust-login mb-2">
                <input type="text" placeholder="Enter your Name" />
              </div>
              <div className="cust-login mb-2">
                <input type="text" placeholder="Enter your eamil address" />
              </div>
              <div className="cust-login mb-2">
                <input type="text" placeholder="Enter your Mobile Number" />
              </div>
              <div className="cust-login mb-2">
                <input type="text" placeholder="Enter your Password" />
              </div>
              <div className="cust-login-0 mb-2">
                <button>Register</button>
              </div>
              <Root>
                {" "}
                <Divider>OR</Divider>
              </Root>
              <div className="face">
                <div className="facebook">
                  <img
                    src="../Assets/images/facebook-png.webp"
                    style={{ width: "30px" }}
                  />
                </div>
                <div className="facebook-0">Continue with Facebook</div>
              </div>
              <div className="face">
                <div className="facebook">
                  <img
                    src="../Assets/images/google.png"
                    style={{ width: "25px" }}
                  />
                </div>
                <div className="facebook-0">Continue with Google</div>
              </div>
              <div className="face2">
                <div className="fee">
                  Don't Have an account ? <a href="/login">Login</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0">
            <div className="spa-login">
              <img
                src="../../Assets/images/login.jpg"
                alt="spa-login"
                style={{ width: "100%", minHeight: "50rem",height:"auto" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
