import React, { useEffect, useState } from "react";
import "../Styles/product.css";
import { Link, useLocation } from "react-router-dom";
import parse from "html-react-parser";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { RiMotorbikeFill } from "react-icons/ri";
import { AiFillCar } from "react-icons/ai";
import { IoAddCircleSharp, IoLocationSharp } from "react-icons/io5";
import ReactStars from "react-rating-stars-component";
import axios from "axios";
import moment from "moment/moment";

function ProductDetails() {
  document.title = "SPA-Body Raaga Wellness Spa";

  const location = useLocation();
  const { dis } = location.state;

  console.log("dis", dis);
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [deals, setdeals] = useState(true);
  const [about, setabout] = useState(false);
  const [gallary, setgallary] = useState(false);
  const [parking, setparking] = useState(false);
  const [reviews, setreviews] = useState(false);

  const [workingdays, setworkingdays] = useState(false);

  const [array1, setarray1] = useState([]);
  const [slots, setslots] = useState([]);
  const [test, settest] = useState(false);

  const [rating, setrating] = useState();
  const [review, setreview] = useState("");

  const [reviewarray, setreviewarray] = useState([]);

  useEffect(() => {
    setslots(array1);
  }, [test]);

  const bookslot = (obj) => {
    console.log("sajnfjkds");
    array1.push(obj);
    setslots(array1);
    settest(!test);
  };

  const totalamount =
    slots?.length > 0
      ? slots?.reduce(
          (a, item) => a + (item?.Price - (item?.Price * item?.Discount) / 100),
          0
        )
      : 0;

  const ratingChanged = (newRating) => {
    console.log(newRating);
    setrating(newRating);
  };

  useEffect(() => {
    getreview();
  }, []);

  const addreview = async (e) => {
    if (!user) {
      alert("Please login to give feedback");
    } else {
      if (!rating) {
        alert("Please give a ratings");
      } else if (!review) {
        alert("Please add your valuable feedback");
      } else {
        try {
          const config = {
            url: "/AddReview",
            method: "post",
            baseURL: "https://spazo.co.in/api/customer",
            headers: { "content-type": "application/json" },
            data: {
              rating: rating,
              review: review,
              name: user?.name,
              vendorID: dis?._id,
            },
          };
          let res = await axios(config);
          if (res.status === 200) {
            alert("Your feedback added successfully");
            window.location.reload();
          }
        } catch (error) {
          console.log(error.response);
          if (error.response) {
            alert(error.response.data.error);
          }
        }
      }
    }
  };

  const getreview = () => {
    axios
      .get("https://spazo.co.in/api/customer/getVendorReview/" + dis._id)
      .then(function (response) {
        console.log(response.data);
        setreviewarray(response.data.Review);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log("reviewarray", reviewarray);
  return (
    <>
      <div className="pro-diffrent" style={{ backgroundColor: "#ffda77" }}>
        <div className="container-fluid">
          <div className="pet">
            <div className="pet-title">
              <h2>{dis.businessName}</h2>
              <div className="spa-all-images">
                {/* <div className="see-all">
                  <a href="/all-images">See All Images</a>
                </div> */}
                <div className="row">
                  <div className="col-md-7 p-0">
                    <img
                      src={"https://spazo.co.in/Vendor/" + dis?.spaimage1}
                      alt="spa-images"
                      className="dif-img"
                    />
                  </div>
                  <div className="col-md-5 p-0">
                    <img
                      src={"https://spazo.co.in/Vendor/" + dis?.spaimage2}
                      alt="spa-images"
                      className="dif-img-1"
                    />
                    <img
                      src={"https://spazo.co.in/Vendor/" + dis?.spaimage3}
                      alt="spa-images"
                      className="dif-img-1"
                    />
                  </div>
                </div>
              </div>

              <div className="feature">
                <div className="spa-feat">
                  <ul style={{ padding: "0px" }}>
                    <li
                      className={`tab-feature ${deals ? "active-spa" : "null"}`}
                      onClick={() => {
                        setdeals(true);
                        setabout(false);
                        setgallary(false);
                        setparking(false);
                        setreviews(false);
                      }}
                    >
                      Deals
                    </li>
                    <li
                      className={`tab-feature ${about ? "active-spa" : "null"}`}
                      onClick={() => {
                        setdeals(false);
                        setabout(true);
                        setgallary(false);
                        setparking(false);
                        setreviews(false);
                      }}
                    >
                      About
                    </li>
                    <li
                      className={`tab-feature ${
                        gallary ? "active-spa" : "null"
                      }`}
                      onClick={() => {
                        setdeals(false);
                        setabout(false);
                        setgallary(true);
                        setparking(false);
                        setreviews(false);
                      }}
                    >
                      Gallary
                    </li>
                    <li
                      className={`tab-feature ${
                        parking ? "active-spa" : "null"
                      }`}
                      onClick={() => {
                        setdeals(false);
                        setabout(false);
                        setgallary(false);
                        setparking(true);
                        setreviews(false);
                      }}
                    >
                      Parking
                    </li>
                    <li
                      className={`tab-feature ${
                        reviews ? "active-spa" : "null"
                      }`}
                      onClick={() => {
                        setdeals(false);
                        setabout(false);
                        setgallary(false);
                        setparking(false);
                        setreviews(true);
                      }}
                    >
                      Reviews
                    </li>
                  </ul>
                </div>
                <div className="row">
                  <div className="col-md-8">
                    {deals ? (
                      <>
                        <div className="dealscroll">
                          {dis?.service?.map((serv) => (
                            <>
                              <div className="feature-details-spa">
                                <div className="feature-details mb-2">
                                  <div className="body-diff">
                                    <h6>
                                      {serv?.ServiceName} (
                                      {serv?.Service_Duration} mins)
                                      {serv?.advantages?.length > 0
                                        ? serv?.advantages?.map((adv) => (
                                            <span>
                                              + {adv?.adv_name} (
                                              {adv?.adv_duration} mins)
                                            </span>
                                          ))
                                        : ""}
                                    </h6>
                                    {/* <div style={{ color: "green" }}>
                                    Free cancellation
                                  </div> */}
                                    <div className="feture-valide mb-2">
                                      <div className="free-diff">
                                        Valid for :{" "}
                                        <span>{serv?.ValidFor}</span>
                                      </div>
                                      <div className="free-diff">
                                        Valid on :{" "}
                                        <span
                                          onClick={() => setworkingdays(true)}
                                        >
                                          Click Here to Check
                                        </span>
                                        {workingdays ? (
                                          <>
                                            <div
                                              style={{
                                                border:
                                                  "1px solid rgb(255, 72, 165)",
                                                padding: "10px",
                                                borderRadius: "10px",
                                                backgroundColor:
                                                  "rgb(255, 72, 165)",
                                              }}
                                            >
                                              <Row>
                                                <Col md={4}>
                                                  <input
                                                    type="checkbox"
                                                    checked={dis?.sun}
                                                  />
                                                  <label
                                                    style={{
                                                      color: "white",
                                                      fontSize: "18px",
                                                    }}
                                                  >
                                                    Sun
                                                  </label>
                                                </Col>
                                                <Col md={4}>
                                                  <input
                                                    type="checkbox"
                                                    checked={dis?.mon}
                                                  />
                                                  <label
                                                    style={{
                                                      color: "white",
                                                      fontSize: "18px",
                                                    }}
                                                  >
                                                    Mon
                                                  </label>
                                                </Col>
                                                <Col md={4}>
                                                  <input
                                                    type="checkbox"
                                                    checked={dis?.tue}
                                                  />
                                                  <label
                                                    style={{
                                                      color: "white",
                                                      fontSize: "18px",
                                                    }}
                                                  >
                                                    Tue
                                                  </label>
                                                </Col>
                                              </Row>

                                              <Row>
                                                <Col md={4}>
                                                  <input
                                                    type="checkbox"
                                                    checked={dis?.wed}
                                                  />
                                                  <label
                                                    style={{
                                                      color: "white",
                                                      fontSize: "18px",
                                                    }}
                                                  >
                                                    Wed
                                                  </label>
                                                </Col>
                                                <Col md={4}>
                                                  <input
                                                    type="checkbox"
                                                    checked={dis?.turs}
                                                  />
                                                  <label
                                                    style={{
                                                      color: "white",
                                                      fontSize: "18px",
                                                    }}
                                                  >
                                                    Thu
                                                  </label>
                                                </Col>
                                                <Col md={4}>
                                                  <input
                                                    type="checkbox"
                                                    checked={dis?.frid}
                                                  />
                                                  <label
                                                    style={{
                                                      color: "white",
                                                      fontSize: "18px",
                                                    }}
                                                  >
                                                    Fri
                                                  </label>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col md={4}>
                                                  <input
                                                    type="checkbox"
                                                    checked={dis?.sat}
                                                  />
                                                  <label
                                                    style={{
                                                      color: "white",
                                                      fontSize: "18px",
                                                    }}
                                                  >
                                                    Sat
                                                  </label>
                                                </Col>
                                                <Col md={4}>{""}</Col>
                                                <Col md={4}>
                                                  <span
                                                    style={{ color: "white" }}
                                                    onClick={() =>
                                                      setworkingdays(
                                                        !workingdays
                                                      )
                                                    }
                                                  >
                                                    Close
                                                  </span>
                                                </Col>
                                              </Row>
                                            </div>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="free-diff">
                                        Timings :{" "}
                                        <span>
                                          {dis?.formattedStartTime}-
                                          {dis?.formattedEndTime}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="price">
                                    <div
                                      className="discount-p"
                                      style={{ fontSize: "15px" }}
                                    >
                                      {serv?.Discount}% OFF
                                    </div>
                                    <div>
                                      <s>₹{serv?.Price}</s>
                                    </div>
                                    <div className="discount-price">
                                      ₹
                                      {serv?.Price -
                                        (serv?.Price * serv?.Discount) / 100}
                                    </div>
                                  </div>
                                </div>
                                <div className="iop-0">
                                  <div
                                    className="iop-d"
                                    // onClick={() => bookslot(serv)}
                                  >
                                    <Link
                                      to="/order-summary"
                                      state={{ slots: serv }}
                                      style={{
                                        textDecoration: "none",
                                      }}
                                    >
                                      Book Slot
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}

                          {/* 2nd */}
                          {/* <div className="feature-details-spa">
                          <div className="feature-details mb-2">
                            <div className="body-diff">
                              <h6>
                                Swedish Full Body Massage (50 mins) + Shower (10
                                mins) + Welcome Drink +Spa Tea (Special Ayurveda
                                Tea)
                              </h6>
                              <div style={{ color: "green" }}>
                                Free cancellation
                              </div>
                              <div className="feture-valide mb-2">
                                <div className="free-diff">
                                  Valid for : <span>1person</span>
                                </div>
                                <div className="free-diff">
                                  Valid on : <span>Mon-Fri</span>
                                </div>
                                <div className="free-diff">
                                  Timings : <span>11am-5pm</span>
                                </div>
                              </div>
                            </div>
                            <div className="price">
                              <div
                                className="discount-p"
                                style={{ fontSize: "15px" }}
                              >
                                45% OFF
                              </div>
                              <div>
                                <s>₹2,720</s>
                              </div>
                              <div className="discount-price">₹1,499</div>
                            </div>
                          </div>
                          <div className="iop-0">
                            <div className="iop-d">Book Slot</div>
                          </div>
                        </div> */}
                        </div>{" "}
                      </>
                    ) : (
                      <>
                        {about ? (
                          <>
                            <div className="efrig dealscroll">
                              {parse(dis.about)}
                            </div>
                          </>
                        ) : (
                          <>
                            {gallary ? (
                              <>
                                {dis?.spaimage1 ? (
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      dis?.spaimage1
                                    }
                                    width="150px"
                                    height="100px"
                                    style={{ margin: "10px" }}
                                  />
                                ) : (
                                  ""
                                )}
                                {dis?.spaimage2 ? (
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      dis?.spaimage2
                                    }
                                    width="150px"
                                    height="100px"
                                    style={{ margin: "10px" }}
                                  />
                                ) : (
                                  ""
                                )}
                                {dis?.spaimage3 ? (
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      dis?.spaimage3
                                    }
                                    width="150px"
                                    height="100px"
                                    style={{ margin: "10px" }}
                                  />
                                ) : (
                                  ""
                                )}
                                {dis?.spaimage4 ? (
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      dis?.spaimage4
                                    }
                                    width="150px"
                                    height="100px"
                                    style={{ margin: "10px" }}
                                  />
                                ) : (
                                  ""
                                )}

                                {dis?.service?.map((sev) => (
                                  <img
                                    src={
                                      "https://spazo.co.in/Service/" +
                                      sev?.Service_Image
                                    }
                                    width="150px"
                                    height="100px"
                                    style={{ margin: "10px" }}
                                  />
                                ))}
                              </>
                            ) : (
                              <>
                                {parking ? (
                                  <>
                                    {dis?.parking === "Both" ? (
                                      <>
                                        <div
                                          style={{
                                            display: "flex",
                                            margin: "10px",
                                            gap: "20px",
                                          }}
                                        >
                                          <div>
                                            <RiMotorbikeFill
                                              style={{
                                                fontSize: "80px",
                                                color: "#e84141",
                                              }}
                                            />
                                            <p style={{ color: "#e84141" }}>
                                              2-Wheeler
                                            </p>
                                          </div>
                                          <div>
                                            <AiFillCar
                                              style={{
                                                fontSize: "80px",
                                                color: "#e84141",
                                              }}
                                            />
                                            <p style={{ color: "#e84141" }}>
                                              4-Wheeler
                                            </p>
                                          </div>
                                        </div>
                                      </>
                                    ) : dis?.parking === "2-Wheeler" ? (
                                      <>
                                        <RiMotorbikeFill
                                          style={{
                                            fontSize: "80px",
                                            color: "rgb(255, 72, 165)",
                                          }}
                                        />
                                        <p style={{ color: "#e84141" }}>
                                          2-Wheeler
                                        </p>
                                      </>
                                    ) : dis?.parking === "4-Wheeler" ? (
                                      <>
                                        <AiFillCar
                                          style={{
                                            fontSize: "80px",
                                            color: "rgb(255, 72, 165)",
                                          }}
                                        />
                                        <p style={{ color: "#e84141" }}>
                                          4-Wheeler
                                        </p>
                                      </>
                                    ) : dis?.parking === "NO Parking" ? (
                                      <>{dis?.parking}</>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {reviews ? (
                                      <>
                                        <button
                                          onClick={handleShow}
                                          style={{
                                            backgroundColor: "#e22217",
                                            color: "white",
                                            border: "none",
                                            marginLeft: "20px",
                                            padding: "5px",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <IoAddCircleSharp /> Add Reviews
                                        </button>
                                        {reviewarray?.map((rev) => (
                                          <>
                                            <div style={{ padding: "20px" }}>
                                              <h6>{rev?.name}</h6>
                                              <p>{rev?.review}</p>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <ReactStars
                                                  count={5}
                                                  value={rev?.rating}
                                                  size={20}
                                                  activeColor="#ffd700"
                                                />
                                                <p
                                                  style={{
                                                    textAlign: "right",
                                                    color: "#e84141",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {moment(
                                                    rev?.createdAt
                                                  ).format("DD-MM-YYYY")}
                                                </p>
                                              </div>
                                            </div>

                                            <hr></hr>
                                          </>
                                        ))}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <Modal
                    show={show}
                    onHide={handleClose}
                    className="modalreview"
                  >
                    <h3 style={{ color: "#e22217", textAlign: "center" }}>
                      Add Reviews
                    </h3>
                    <Modal.Body>
                      <img
                        src="./logo.jpg"
                        style={{
                          width: "80px",
                          height: "80px",
                          display: "grid",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                      <h6 style={{ textAlign: "center" }}>{user?.name}</h6>
                      <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={24}
                        activeColor="#ffd700"
                      />
                      <textarea
                        placeholder="Additional Comments..."
                        style={{
                          backgroundColor: "#fbd7d4",
                          border: "1px solid #e22217",
                          borderRadius: "10px",
                          width: "80%",
                          height: "100px",
                          marginLeft: "auto",
                          marginRight: "auto",
                          display: "flex",
                        }}
                        onChange={(e) => setreview(e.target.value)}
                      />
                      <br></br>
                      <div
                        style={{
                          width: "80%",
                          justifyContent: "space-between",
                          display: "flex",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <Button variant="primary" onClick={handleClose}>
                          Not Now
                        </Button>
                        <Button variant="secondary" onClick={addreview}>
                          Submit Review
                        </Button>
                      </div>
                    </Modal.Body>
                  </Modal>

                  <div className="col-md-4">
                    <div className="book-cart">
                      <div className="book-f">Contact Us</div>
                      <div>
                        <p style={{ textAlign: "center" }}>{dis?.address}</p>
                        <p style={{ textAlign: "center" }}>- {dis?.pincode}</p>
                        <p style={{ textAlign: "center" }}>{dis?.email}</p>
                        <p style={{ textAlign: "center" }}>
                          {dis?.phoneNumber}
                        </p>
                      </div>
                      <hr />
                      <div>
                        <IoLocationSharp />
                        <a href={dis?.Glink} target="_blank">
                          {dis?.Glink}
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-4">
                    <div className="book-cart">
                      <div className="book-f">YOUR ORDERS</div>
                      {slots?.map((order) => (
                        <div className="order-details">
                          <div className="title-p">{order?.ServiceName}</div>
                          <div className="title-p">×2</div>
                          <div className="title-p">
                            {order?.Price -
                              (order?.Price * order?.Discount) / 100}
                          </div>
                        </div>
                      ))}
                      <div className="order-details">
                        <div className="title-p">Swedish Full Bod..</div>
                        <div className="title-p">×2</div>
                        <div className="title-p">1398</div>
                      </div>
                      <hr />
                      <div className="order-details p-0">
                        <div className="title-pp">Total</div>
                        <div className="title-pp">{totalamount.toFixed(2)}</div>
                      </div>
                      {slots?.length > 0 ? (
                        <div className="cust-login-0 mt-3">
                          <button style={{ width: "359px" }}>
                            <Link
                              to="/order-summary"
                              state={{ slots: slots }}
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              Buy Now
                            </Link>
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductDetails;
