import React, { useState } from "react";
import "../Styles/footer.css";
import { FaFacebookF } from "react-icons/fa";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="container-fluid">
          <div className="spa-footer">
            <div className="row me-0">
              <div className="col-md-4">
                <div className="footer-spa to">
                  <img
                    src="./logo.jpg"
                    alt="footer-logo"
                    style={{ width: "200px", height: "100px" }}
                  />
                  <p></p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer-spa">
                  <div className="veer">Company</div>
                  <ul style={{ padding: "0px" }}>
                    <a
                      href="home"
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      <li className="spa-00">Home</li>
                    </a>
                    <a href="">
                      <li className="spa-00">About us</li>
                    </a>
                    <a href="/contactUS">
                      <li className="spa-00">Contact us</li>
                    </a>
                    <a href="">
                      <li className="spa-00">Policy</li>
                    </a>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer-spa">
                  <div className="app-o">
                    <img
                      src="../Assets/images/google-play.svg"
                      alt="google-play"
                      style={{
                        width: "151px",
                        height: "100px",
                        marginRight: "10px",
                      }}
                    />
                    <img
                      src="../Assets/images/app-store.svg"
                      alt="google-play"
                      style={{ width: "151px", height: "100px" }}
                    />
                  </div>
                  <div className="social-media">
                    <ul style={{ display: "flex", gap: "25px" }}>
                      <li>
                        <a href="">
                          <FaFacebookF style={{ color: "white" }} />
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <BiLogoInstagramAlt style={{ color: "white" }} />
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <FaTwitter style={{ color: "white" }} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
