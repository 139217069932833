import React, { useEffect, useState } from "react";
import "../Styles/home.css";
import "swiper/css/navigation";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import TopVendor from "./TopVendor";
import Slider1 from "./Slider1";
import TopDiscount from "./TopDiscount";
import Adds from "./Adds";
import TrendingSpa from "./TrendingSpa";
import Footer from "./Footer";
import Budget from "./Budget";
import RelaxingMassage from "./RelaxingMassage";
import axios from "axios";
import Carousel from "react-bootstrap/Carousel";
import Slider from "react-slick";
import { Link } from "react-router-dom";

function Home() {
  document.title = "SPA-Home";
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
  };

  const [banner, setbanner] = useState([]);
  const [category, setcategory] = useState([]);
  const [type, settype] = useState("Spa");

  const Spacat = category?.filter((item) => item.cattype === "Spa");
  const Saloncat = category?.filter((item) => item.cattype === "Salon");
  const Bcpcat = category?.filter(
    (item) => item.cattype === "Beauty Care Products"
  );

  const Spabanner = banner?.filter((item) => item.bannertype === "Spa");
  const Salonbanner = banner?.filter((item) => item.bannertype === "Salon");
  const Bcpbanner = banner?.filter(
    (item) => item.bannertype === "Beauty Care Products"
  );

  useEffect(() => {
    getallBanners();
    getallCategory();
  }, []);

  const getallBanners = () => {
    axios
      .get("https://spazo.co.in/api/admin/getbanner")
      .then(function (response) {
        console.log(response.data);
        setbanner(response.data.bannerList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getallCategory = () => {
    axios
      .get("https://spazo.co.in/api/admin/getcategory")
      .then(function (response) {
        console.log(response.data);
        setcategory(response.data.category);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <section>
        <div className="cate">
          <div>
            <div
              style={{
                width: "70%",
                backgroundColor: "#e22217",
                borderRadius: "10px",
                marginLeft: "auto",
                marginRight: "auto",
                padding: "10px",
              }}
            >
              <ul style={{ display: "flex", justifyContent: "space-between" }}>
                <li style={{ width: "25%" }} onClick={(e) => settype("Spa")}>
                  <img
                    src="../../Assets/images/spa4.webp"
                    alt="spa-category"
                    style={{
                      width: "50px",
                      height: "50px",
                      marginLeft: "32%",
                    }}
                  />
                  <div
                    className="mt-2"
                    style={{ color: "white", textAlign: "center" }}
                  >
                    Spa-Deals
                  </div>
                </li>

                <div style={{ borderLeft: "1px dotted white" }}></div>
                <li style={{ width: "25%" }} onClick={(e) => settype("Salon")}>
                  <img
                    src="../../Assets/salon.jpg"
                    alt="spa-category"
                    style={{
                      width: "50px",
                      height: "50px",
                      marginLeft: "38%",
                    }}
                  />
                  <div
                    className="mt-2"
                    style={{ color: "white", textAlign: "center" }}
                  >
                    Salon-Deals
                  </div>
                </li>
                <div style={{ borderLeft: "1px dotted white" }}></div>
                <li
                  onClick={(e) => settype("Beauty Care Products")}
                  style={{ width: "25%" }}
                >
                  <img
                    src="../../Assets/images/spa3.webp"
                    alt="spa-category"
                    style={{
                      width: "50px",
                      height: "50px",
                      marginLeft: "35%",
                    }}
                  />
                  <div
                    className="mt-2"
                    style={{ color: "white", textAlign: "center" }}
                  >
                    Beauty care Products
                  </div>
                </li>
              </ul>
            </div>

            <div className="spa-cate mt-3">
              {/* <ul className="pro-0">
                <a href="" style={{ width: "25%" }}>
                  <li>
                    <img
                      src="../../Assets/images/spa4.webp"
                      alt="spa-category"
                      style={{ width: "50px", height: "50px" }}
                    />
                    <div className="mt-2">Spa-Deals</div>
                  </li>
                </a>
                <a href="" style={{ width: "25%" }}>
                  <li>
                    <img
                      src="../../Assets/salon.jpg"
                      alt="spa-category"
                      style={{ width: "50px", height: "50px" }}
                    />
                    <div className="mt-2">Salon-Deals</div>
                  </li>
                </a>
                <a href="" style={{ width: "25%" }}>
                  <li>
                    <img
                      src="../../Assets/images/spa3.webp"
                      alt="spa-category"
                      style={{ width: "50px", height: "50px" }}
                    />
                    <div className="mt-2">Beauty care Products</div>
                  </li>
                </a>
               
              </ul> */}
              <Slider {...settings}>
                {type === "Spa"
                  ? Spacat?.map((cat) => (
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/CategoryProduct"
                        state={{ cat: cat?.catname }}
                      >
                        <div className="rslick">
                          <img
                            src={"https://spazo.co.in/Category/" + cat.catimage}
                            alt="spa-category"
                            style={{ width: "40px", height: "40px" }}
                          />
                          <div
                            className="mt-2"
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            {cat.catname}
                          </div>
                        </div>
                      </Link>
                    ))
                  : type === "Salon"
                  ? Saloncat?.map((cat) => (
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/CategoryProduct"
                        state={{ cat: cat?.catname }}
                      >
                        <div className="rslick">
                          <img
                            src={"https://spazo.co.in/Category/" + cat.catimage}
                            alt="spa-category"
                            style={{ width: "40px", height: "40px" }}
                          />
                          <div
                            className="mt-2"
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            {cat.catname}
                          </div>
                        </div>
                      </Link>
                    ))
                  : type === "Beauty Care Products"
                  ? Bcpcat?.map((cat) => (
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/CategoryProduct"
                        state={{ cat: cat?.catname }}
                      >
                        <div className="rslick">
                          <img
                            src={"https://spazo.co.in/Category/" + cat.catimage}
                            alt="spa-category"
                            style={{ width: "40px", height: "40px" }}
                          />
                          <div
                            className="mt-2"
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            {cat.catname}
                          </div>
                        </div>
                      </Link>
                    ))
                  : ""}
              </Slider>
            </div>
          </div>

          <Carousel>
            {type === "Spa"
              ? Spabanner?.map((item) => (
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={"https://spazo.co.in/Banner/" + item.bannerimg}
                      alt="First slide"
                      style={{
                        backgroundPosition: "center",
                        backggroundSize: "cover",
                        height: "501px",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    />
                    <Carousel.Caption>
                      <div className="text-p">
                        <h3 className="animate__animated animate__fadeInDown">
                          {item.title}
                        </h3>
                        <h2 className="animate__animated tracking-in-contract">
                          {item.subtitle}
                        </h2>
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item>
                ))
              : type === "Salon"
              ? Salonbanner?.map((item) => (
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={"https://spazo.co.in/Banner/" + item.bannerimg}
                      alt="First slide"
                      style={{
                        backgroundPosition: "center",
                        backggroundSize: "cover",
                        height: "501px",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    />
                    <Carousel.Caption>
                      <div className="text-p">
                        <h3 className="animate__animated animate__fadeInDown">
                          {item.title}
                        </h3>
                        <h2 className="animate__animated tracking-in-contract">
                          {item.subtitle}
                        </h2>
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item>
                ))
              : type === "Beauty Care Products"
              ? Bcpbanner?.map((item) => (
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={"https://spazo.co.in/Banner/" + item.bannerimg}
                      alt="First slide"
                      style={{
                        backgroundPosition: "center",
                        backggroundSize: "cover",
                        height: "501px",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    />
                    <Carousel.Caption>
                      <div className="text-p">
                        <h3 className="animate__animated animate__fadeInDown">
                          {item.title}
                        </h3>
                        <h2 className="animate__animated tracking-in-contract">
                          {item.subtitle}
                        </h2>
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item>
                ))
              : ""}
          </Carousel>
        </div>
      </section>
      <Budget />
      <TopVendor />
      <Slider1 />
      <RelaxingMassage />
      <TopDiscount />
      <Adds />
      <TrendingSpa />
      <Footer />
    </>
  );
}

export default Home;
