import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Country, State, City } from "country-state-city";
import moment from "moment";

function Customers() {
  const [data, setdata] = useState([]);
  const [start, setstart] = useState();
  const [end, setend] = useState();
  const [filterdata, setfilterdata] = useState([]);

  const CountryList = Country.getAllCountries();
  const [block, setblock] = useState({});
  const [blockreason, setblockreason] = useState("");
  const [unblock, setunblock] = useState({});

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setblock(data);
  };

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (data) => {
    setShow1(true);
    setunblock(data);
  };

  const [show2, setShow2] = useState(false);
  const [breason, setbreason] = useState({});
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (data) => {
    setShow2(true);
    setbreason(data);
  };

  useEffect(() => {
    getCustomer();
  }, []);

  const getCustomer = () => {
    axios
      .get("https://spazo.co.in/api/customer/getCustomer")
      .then(function (response) {
        console.log(response.data);
        setdata(response.data.Customer);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const BlockCustomers = async (data) => {
    if (!blockreason) {
      alert("Please give the reason for rejection");
    } else {
      try {
        const config = {
          url: "/BlockCustomers",
          method: "post",
          baseURL: "https://spazo.co.in/api/customer",
          data: {
            blockreason: blockreason,
            id: data?._id,
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            alert("Customer Blocked Successfully");
            window.location.reload();
          } else {
            alert(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        alert("Unable to add Details");
      }
    }
  };

  const UnBlockCustomers = async (data) => {
    try {
      const config = {
        url: "/UnBlockCustomers",
        method: "post",
        baseURL: "https://spazo.co.in/api/customer",
        data: {
          id: data?._id,
        },
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          alert("Customer Un-Blocked Successfully");
          window.location.reload();
        } else {
          alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      alert("Unable to add Details");
    }
  };

  useEffect(() => {
    const filtered = data?.filter((item) => {
      if (start && end) {
        return (
          moment(item?.createdAt).isSameOrAfter(moment(start), "day") &&
          moment(item?.createdAt).isSameOrBefore(moment(end), "day")
        );
      } else if (start) {
        return moment(item?.createdAt).isSameOrAfter(moment(start), "day");
      } else if (end) {
        return moment(item?.createdAt).isSameOrBefore(moment(end), "day");
      }
      return true; // Return true if neither start nor end date is set
    });

    setfilterdata(filtered);
  }, [start, end]);

  // console.log(
  //   "xdvgfdg",
  //   moment(item?.createdAt).format("DD/MM/YYYY"),
  //   moment(start).format("DD/MM/YYYY"),
  //   moment(end).format("DD/MM/YYYY"),
  //   moment(item?.createdAt).format("DD/MM/YYYY") >=
  //     moment(start).format("DD/MM/YYYY") &&
  //     moment(item?.createdAt).format("DD/MM/YYYY") <=
  //       moment(end).format("DD/MM/YYYY")
  // );

  console.log("filterdata", filterdata, start, end);

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: start && end ? filterdata?.length : data?.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "createdAt",
      text: "Register date",
      formatter: (value, row) => (
        <p>
          {row?.createdAt ? moment(row?.createdAt).format("DD/MM/YYYY") : "-"}
        </p>
      ),
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (value, row) => <p>{row?.name ? row?.name : "-"}</p>,
    },
    {
      dataField: "email",
      text: "Email Id",
      formatter: (value, row) => <p>{row?.email ? row?.email : ""}</p>,
    },
    {
      dataField: "phoneNumber",
      text: "Contact Num",
      formatter: (value, row) => (
        <p>{row?.phoneNumber ? row?.phoneNumber : ""}</p>
      ),
    },
    {
      dataField: "city",
      text: "City",
      formatter: (value, row) => <p>{row?.city ? row?.city : ""}</p>,
    },
    {
      dataField: "state",
      text: "State",
      formatter: (value, row) => {
        const StateList = State.getAllStates();
        const Stat = StateList?.find(
          (item) =>
            item.isoCode === row?.state && item.countryCode === row?.country
        );

        return (
          <p>
            {Stat?.name ? Stat?.name : ""}( {row?.state})
          </p>
        );
      },
    },
    {
      dataField: "country",
      text: "Country",
      formatter: (value, row) => {
        const cont = CountryList?.find((item) => item.isoCode === row?.country);

        return (
          <p>
            {cont?.name ? cont?.name : ""} ({row?.country})
          </p>
        );
      },
    },
    {
      dataField: "Pincode",
      text: "Pincode",
      formatter: (value, row) => <p>{row?.Pincode ? row?.Pincode : ""}</p>,
    },
    {
      dataField: "Blockstatus",
      text: "Action",
      formatter: (value, row) => (
        <>
          {row?.Blockstatus ? (
            <>
              <div style={{ gap: "10px", display: "flex" }}>
                <button
                  style={{
                    backgroundColor: "#e22217",
                    color: "white",
                    border: "none",
                    padding: "10px",
                  }}
                  onClick={() => handleShow1(row)}
                >
                  Unblock
                </button>
                <button
                  style={{ border: "none" }}
                  onClick={() => handleShow2(row)}
                >
                  <i
                    class="fa fa-eye"
                    aria-hidden="true"
                    style={{ color: "#e22217" }}
                  ></i>
                </button>
              </div>
            </>
          ) : (
            <button
              style={{
                backgroundColor: "Green",
                color: "white",
                border: "none",
                padding: "10px",
              }}
              onClick={() => handleShow(row)}
            >
              Block
            </button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="never-sspa">
        <section>
          <div className="spa-admin-0 mb-2">
            <div className="diff-admin px-3">
              <div className="admin-name-text">
                <h4>Customers</h4>
              </div>
            </div>
          </div>

          <div
            style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}
          >
            <ToolkitProvider
              keyField="id"
              data={start && end ? filterdata : data}
              columns={columns}
              // rowClasses={rowClasses}
              search
              exportCSV
            >
              {(props) => (
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <SearchBar {...props.searchProps} />
                      <ExportCSVButton
                        {...props.csvProps}
                        style={{
                          backgroundColor: "#e22217",
                          color: "white",
                          marginLeft: "10px",
                        }}
                      >
                        Download Excel
                      </ExportCSVButton>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ marginLeft: "10px" }}>
                        <p style={{ marginBottom: "0px" }}>Start Date</p>
                        <input
                          type="date"
                          onChange={(e) => setstart(e.target.valueAsDate)}
                        />
                      </div>
                      <div style={{ marginLeft: "10px" }}>
                        <p style={{ marginBottom: "0px" }}>End Date</p>
                        <input
                          type="date"
                          onChange={(e) => setend(e.target.valueAsDate)}
                        />
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <br></br>
                  <div
                    style={{
                      overflowX: "scroll",
                      width: "100%",
                      paddingBottom: "12%",
                    }}
                  >
                    <BootstrapTable
                      striped
                      hover
                      condensed
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </section>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>If you want to Block the Customer, Please Enter the Reason</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <textarea
              style={{ width: "100%" }}
              onChange={(e) => setblockreason(e.target.value)}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => BlockCustomers(block)}>
            Block
          </Button>
          <Button variant="dark" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>Are you sure, You want to Unblock the Customer</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              variant="secondary"
              onClick={() => UnBlockCustomers(unblock)}
            >
              Yes
            </Button>
            <Button variant="dark" onClick={handleClose1}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>Reason for Block</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{breason?.blockreason}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Customers;
