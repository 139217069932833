import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { BiLogOutCircle } from "react-icons/bi";
import { BsFillChatDotsFill, BsWhatsapp } from "react-icons/bs";
import { GrContact } from "react-icons/gr";

function AdminHeader() {
  const admin = JSON.parse(sessionStorage.getItem("admin"));

  const logout = () => {
    sessionStorage.removeItem("admin");
    alert("Signout Success....!");
    window.location.assign("/admin");
  };

  return (
    <>
      <div
        className="header"
        style={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          backgroundColor: "rgb(226, 34, 23)",
        }}
      >
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand href="#">
              {/* <img
                src="./man.png"
                alt="logo"
                style={{
                  width: "75px",
                  height: "75px",
                  borderRadius: "50%",
                  border: "2px solid black",
                }}
              /> */}
              <div
                style={{ fontSize: "17px", fontWeight: "600", color: "white" }}
              >
                {admin.fullName}
                {/* <div
                  className="d-block"
                  style={{ fontSize: "14px", fontWeight: "600" }}
                >
                  ID:38572147
                </div> */}
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="ms-auto my-2 my-lg-0"
                // style={{ maxHeight: "100px" }}
                navbarScroll
              >
                {/* <Nav.Link href="#">
                  <BsFillChatDotsFill
                    style={{ fontSize: "30px", paddingRight: "5px" }}
                  />
                  Contact admin
                </Nav.Link>
                <Nav.Link href="#">
                  <BsWhatsapp
                    style={{
                      fontSize: "25px",
                      color: "green",
                      paddingRight: "5px",
                    }}
                  />
                  Chat with admin
                </Nav.Link> */}
                <Nav.Link href="#" onClick={logout}>
                  <BiLogOutCircle
                    style={{ fontSize: "25px", color: "white" }}
                  />
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default AdminHeader;
