import React from "react";
import Vendorheader from "./Vendorheader";
import Vendorsidebar from "./Vendorsidebar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaEye, FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import { isAfter, isBefore, isToday, addDays, format } from "date-fns";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Vendorservices() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const Input = styled("input")({
    display: "none",
  });

  const formdata = new FormData();
  const user = JSON.parse(sessionStorage.getItem("user"));

  const formatTimeTo12hr = (time) => {
    const [hours, minutes] = time?.split(":");
    const parsedHours = parseInt(hours, 10);

    // Determine AM or PM
    const period = parsedHours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    const displayHours = parsedHours % 12 || 12;

    // Combine the formatted time
    const formattedTime = `${displayHours}:${minutes} ${period}`;
    return formattedTime;
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [Viewshow, setViewShow] = useState(false);
  const [Viewdata, setViewdata] = useState({});
  const handleViewClose = () => setViewShow(false);
  const handleViewShow = (row) => {
    setViewShow(true);
    setViewdata(row);
  };

  const [Editshow, setEditShow] = useState(false);
  const [Editdata, setEditdata] = useState({});
  const handleEditClose = () => setEditShow(false);
  const handleEditShow = (row) => {
    setEditShow(true);
    setEditdata(row);
  };

  const [data, setdata] = useState([]);
  const [category, setcategory] = useState([]);
  const [service, setservice] = useState([]);
  const [Advantages, setAdvantages] = useState([]);

  const [Service_Category, setService_Category] = useState("");
  const [ServiceName, setServiceName] = useState("");
  const [Service_Duration, setService_Duration] = useState("");
  const [Price, setPrice] = useState();
  const [Discount, setDiscount] = useState();
  const [servicetax, setservicetax] = useState();
  const [GST, setGST] = useState(0);
  const [ValidFor, setValidFor] = useState("");
  const [Service_Image, setService_Image] = useState([]);
  const [displayimage, setdisplayimage] = useState([]);

  useEffect(() => {
    if (Service_Image) {
      const xyz = [];
      for (let i = 0; i < Object.keys(Service_Image).length; i++) {
        xyz.push({ Service_Image: Service_Image[i] });
      }

      setdisplayimage(xyz);
    }
  }, [Service_Image]);

  console.log("Service_Image", Service_Image);

  const [advantages, setadvantages] = useState([]);
  const [array1, setarray1] = useState([]);
  const [adv_name, setadv_name] = useState("");
  const [adv_duration, setadv_duration] = useState("");

  const today = new Date();
  const maxDate = addDays(today, 7); // Today + 7 days
  const [selectedDate, setSelectedDate] = useState(today);

  const [slots, setslots] = useState([]);
  const [slotsarray, setslotsarray] = useState([]);
  const [slotdate, setslotdate] = useState();
  const [slotfromtime, setslotfromtime] = useState();
  const [slottotime, setslottotime] = useState();
  const [no_Booking_slot, setno_Booking_slot] = useState();

  const slot_from_time = slotfromtime ? formatTimeTo12hr(slotfromtime) : "";
  const slot_To_time = slottotime ? formatTimeTo12hr(slottotime) : "";

  const [Inclusive, setInclusive] = useState([]);
  const [inclusivearray, setinclusivearray] = useState([]);
  const [inclusive_name, setinclusive_name] = useState("");

  const [Service_Category1, setService_Category1] = useState("");
  const [ServiceName1, setServiceName1] = useState("");
  const [Service_Duration1, setService_Duration1] = useState("");
  const [Price1, setPrice1] = useState();
  const [Discount1, setDiscount1] = useState();
  const [servicetax1, setservicetax1] = useState();
  const [GST1, setGST1] = useState(0);
  const [ValidFor1, setValidFor1] = useState("");
  const [Service_Image1, setService_Image1] = useState([]);
  const [displayimage1, setdisplayimage1] = useState([]);

  const [advantages1, setadvantages1] = useState([]);
  const [array11, setarray11] = useState([]);
  const [adv_name1, setadv_name1] = useState("");
  const [adv_duration1, setadv_duration1] = useState("");

  const [slots1, setslots1] = useState([]);
  const [slotsarray1, setslotsarray1] = useState([]);
  const [slotdate1, setslotdate1] = useState();
  const [slotfromtime1, setslotfromtime1] = useState();
  const [slottotime1, setslottotime1] = useState();
  const [no_Booking_slot1, setno_Booking_slot1] = useState();

  const slot_from_time1 = slotfromtime1 ? formatTimeTo12hr(slotfromtime1) : "";
  const slot_To_time1 = slottotime1 ? formatTimeTo12hr(slottotime1) : "";

  const [Inclusive1, setInclusive1] = useState([]);
  const [inclusivearray1, setinclusivearray1] = useState([]);
  const [inclusive_name1, setinclusive_name1] = useState("");

  const [show2, setShow2] = useState(false);
  const [deletedata, setdeletedata] = useState({});

  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setdeletedata(item);
  };

  useEffect(() => {
    if (Service_Image1) {
      const xyz = [];
      for (let i = 0; i < Object.keys(Service_Image1).length; i++) {
        xyz.push({ Service_Image: Service_Image1[i] });
      }
      setdisplayimage1(xyz);
    }
  }, [Service_Image1]);

  const handleDateChange = (event) => {
    const selected = new Date(event.target.value);

    if (isAfter(selected, today) || isToday(selected)) {
      setSelectedDate(selected);
      setslotdate(selected);
      // if (isBefore(selected, maxDate) || isToday(selected)) {
      //   setSelectedDate(selected);
      //   setslotdate(selected);
      // } else {
      //   // alert("Selected date must be within the next 7 days.");
      //   notify2("Selected date must be within the next 7 days.");
      // }
    } else {
      alert("Selected date must be today or a future date.");
      // notify2("Selected date must be today or a future date.");
    }
  };

  useEffect(() => {
    getallCategory();
    getVendorService();
    getAdminservice();
    getallAdvantages();
  }, []);

  const getallCategory = () => {
    axios
      .get("https://spazo.co.in/api/admin/getcategory")
      .then(function (response) {
        console.log(response.data);
        setcategory(response.data.category);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getallAdvantages = () => {
    axios
      .get("https://spazo.co.in/api/admin/getAdvantages")
      .then(function (response) {
        console.log(response.data);
        setAdvantages(response.data.AdvantagesList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getAdminservice = () => {
    axios
      .get("https://spazo.co.in/api/admin/getAdminservice")
      .then(function (response) {
        console.log("service", response.data);
        setservice(response.data.Adminservice);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addadvantages = () => {
    if (!adv_name || !adv_duration) {
      // alert("Please fill all the fields");
      notify2("Please fill all the fields");
    } else {
      let obj = {
        adv_name: adv_name,
        adv_duration: adv_duration,
      };
      array1.push(obj);
      setadvantages(array1);
      setadv_name();
      setadv_duration();
    }
  };

  const addslots = () => {
    if (!slotdate || !slot_from_time || !slot_To_time || !no_Booking_slot) {
      // alert("Please fill all the fields");
      notify2("Please fill all the fields");
    } else {
      let obj = {
        slotdate: moment(slotdate).format("DD/MM/YYYY"),
        slot_from_time: slot_from_time,
        slot_To_time: slot_To_time,
        no_Booking_slot: no_Booking_slot,
      };
      slotsarray.push(obj);
      setslots(slotsarray);
      setslotfromtime();
      setslottotime();
      setno_Booking_slot();
    }
  };

  const addinclusive = () => {
    if (!inclusive_name) {
      // alert("Please Enter Inclusive");
      notify2("Please Enter Inclusive");
    } else {
      let obj = {
        inclusive_name: inclusive_name,
      };
      inclusivearray.push(obj);
      setInclusive(inclusivearray);
      setinclusive_name("");
    }
  };

  const addservice = async () => {
    if (
      !Service_Category ||
      !ServiceName ||
      !Service_Duration ||
      !Price ||
      !servicetax ||
      !GST ||
      !Discount ||
      Service_Image?.length == 0
    ) {
      // alert("Please fill all the fields");
      notify2("Please fill all the fields");
    } else {
      // formdata.append("VendorId", user._id);
      // formdata.append("VendorName", user.name);
      // formdata.append("VendorBusinessName", user.businessName);
      // formdata.append("VendorEmailID", user.email);
      // formdata.append("VendorContactNo", user.phoneNumber);
      // formdata.append("VendorAddress", user.address);
      // formdata.append("VendorPincode", user.pincode);
      // formdata.append("VendorGlink", user.Glink);

      // formdata.append("Service_Category", Service_Category);
      // formdata.append("ServiceName", ServiceName);
      // formdata.append("Service_Duration", Service_Duration);
      // formdata.append("Price", Price);
      // formdata.append("servicetax", servicetax);
      // formdata.append("GST", GST);
      // formdata.append("Discount", Discount);
      // formdata.append("ValidFor", ValidFor);
      // formdata.append("Service_ImageArray", Service_Image);
      // formdata.append("advantages", JSON.stringify(advantages));
      // formdata.append("slots", JSON.stringify(slots));
      // formdata.append("Inclusive", JSON.stringify(Inclusive));
      try {
        const config = {
          url: "/addService",
          method: "post",
          baseURL: "https://spazo.co.in/api/vendor",
          headers: { "content-type": "application/json" },
          data: {
            VendorId: user._id,
            VendorName: user.name,
            VendorBusinessName: user.businessName,
            VendorEmailID: user.email,
            VendorContactNo: user.phoneNumber,
            VendorAddress: user.address,
            VendorPincode: user.pincode,
            VendorGlink: user.Glink,
            Service_Category: Service_Category,
            ServiceName: ServiceName,
            Service_Duration: Service_Duration,
            Price: Price,
            servicetax: servicetax,
            GST: GST,
            Discount: Discount,
            ValidFor: ValidFor,
            slots: no_Booking_slot,
            advantages: advantages,
            // slots: JSON.stringify(slots),
            Inclusive: Inclusive,
            // Service_ImageArray: Service_Image,
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            addserviceImage(res.data.id._id);
            // alert("Data Added successfully");
            notify("Data Added successfully");
            getallCategory();
            getVendorService();
            getAdminservice();
            getallAdvantages();
            handleClose();
            setService_Image([]);
            window.location.reload();
          } else {
            // alert(res.status.error);
            notify1(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        // alert("Unable to add Details");
        notify1("Unable to add Details, Please try after some time");
      }
    }
  };

  let form2 = new FormData();
  const addserviceImage = async (id) => {
    displayimage.forEach(async (adharObj, index) => {
      form2.append("id", id);
      form2.append("Service_Image", adharObj.Service_Image);
      try {
        const config = {
          url: "/AddserviceImage",
          method: "post",
          baseURL: "https://spazo.co.in/api/vendor",
          data: form2,
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            // alert("Data Added successfully");
          } else {
            // alert(res.status.error);
            notify1(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        // alert("Unable to add Details");
        notify1("Unable to add Details, Please try after some time");
      }
    });
  };

  console.log("Service_Image", Service_Image);
  const getVendorService = () => {
    axios
      .get("https://spazo.co.in/api/vendor/getVendorService/" + user._id)
      .then(function (response) {
        console.log(response.data);
        setdata(response.data.Service);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteService = async () => {
    try {
      const config = {
        url: "/deleteService/" + deletedata?._id,
        method: "delete",
        baseURL: "https://spazo.co.in/api/vendor",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          // alert("Data deleted successfully");
          // getallCategory();
          // getVendorService();
          // getAdminservice();
          // getallAdvantages();
          // handleClose2();
          notify("Data deleted successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1000); // Adjust delay as needed
        } else {
          // alert(res.status.error);
          notify1(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      // alert("Unable to add Details");
      notify1("Unable to add Details, Please try after some time");
    }
  };

  const addadvantages1 = () => {
    if (!adv_name1 || !adv_duration1) {
      // alert("Please fill all the fields");
      notify2("Please fill all the fields");
    } else {
      let obj = {
        adv_name: adv_name1,
        adv_duration: adv_duration1,
      };
      array11.push(obj);
      setadvantages1(array11);
      setadv_name1();
      setadv_duration1();
    }
  };

  const addslots1 = () => {
    if (!slotdate1 || !slot_from_time1 || !slot_To_time1 || !no_Booking_slot1) {
      // alert("Please fill all the fields");
      notify2("Please fill all the fields");
    } else {
      let obj = {
        slot_from_time: slot_from_time1,
        slot_To_time: slot_To_time1,
        no_Booking_slot: no_Booking_slot1,
        slotdate: slotdate1,
      };
      slotsarray1.push(obj);
      setslots1(slotsarray1);
      setslotfromtime1();
      setslottotime1();
      setno_Booking_slot1();
    }
  };

  const addinclusive1 = () => {
    if (!inclusive_name1) {
      // alert("Please Enter Inclusive");
      notify2("Please Enter Inclusive");
    } else {
      let obj = {
        inclusive_name: inclusive_name1,
      };
      inclusivearray1.push(obj);
      setInclusive1(inclusivearray1);
      setinclusive_name1("");
    }
  };

  const editservice = async () => {
    // formdata.append("id", Editdata?._id);
    // formdata.append(
    //   "Service_Category",
    //   Service_Category1 ? Service_Category1 : Editdata?.Service_Category
    // );
    // formdata.append(
    //   "ServiceName",
    //   ServiceName1 ? ServiceName1 : Editdata?.ServiceName
    // );
    // formdata.append(
    //   "Service_Duration",
    //   Service_Duration1 ? Service_Duration1 : Editdata?.Service_Duration
    // );
    // formdata.append("Price", Price1 ? Price1 : Editdata?.Price);
    // formdata.append(
    //   "servicetax",
    //   servicetax1 ? servicetax1 : Editdata?.servicetax
    // );
    // formdata.append("GST", GST1 ? GST1 : Editdata?.GST);
    // formdata.append("Discount", Discount1 ? Discount1 : Editdata?.Discount);
    // formdata.append("ValidFor", ValidFor1 ? ValidFor1 : Editdata?.ValidFor);
    // formdata.append(
    //   "Service_Image",
    //   Service_Image1 ? Service_Image1 : Editdata?.Service_Image
    // );
    // formdata.append(
    //   "advantages",
    //   advantages1?.length > 0
    //     ? JSON.stringify(advantages1)
    //     : JSON.stringify(Editdata?.advantages)
    // );
    // formdata.append(
    //   "slots",
    //   slots1?.length > 0
    //     ? JSON.stringify(slots1)
    //     : JSON.stringify(Editdata?.slots)
    // );
    // formdata.append(
    //   "Inclusive",
    //   Inclusive1?.length > 0
    //     ? JSON.stringify(Inclusive1)
    //     : JSON.stringify(Editdata?.Inclusive)
    // );
    try {
      const config = {
        url: "/editService",
        method: "post",
        baseURL: "https://spazo.co.in/api/vendor",
        headers: { "content-type": "application/json" },
        data: {
          id: Editdata?._id,
          Service_Category: Service_Category1
            ? Service_Category1
            : Editdata?.Service_Category,
          ServiceName: ServiceName1 ? ServiceName1 : Editdata?.ServiceName,
          Service_Duration: Service_Duration1
            ? Service_Duration1
            : Editdata?.Service_Duration,
          Price: Price1 ? Price1 : Editdata?.Price,
          servicetax: servicetax1 ? servicetax1 : Editdata?.servicetax,
          GST: GST1 ? GST1 : Editdata?.GST,
          Discount: Discount1 ? Discount1 : Editdata?.Discount,
          ValidFor: ValidFor1 ? ValidFor1 : Editdata?.ValidFor,
          slots: no_Booking_slot1
            ? no_Booking_slot1
            : Editdata?.no_Booking_slot,
          // Service_Image: Service_Image1
          //   ? Service_Image1
          //   : Editdata?.Service_Image,
          advantages:
            advantages1?.length > 0 ? advantages1 : Editdata?.advantages,
          // slots:
          //   slots1?.length > 0
          //     ? JSON.stringify(slots1)
          //     : JSON.stringify(Editdata?.slots),
          Inclusive: Inclusive1?.length > 0 ? Inclusive1 : Editdata?.Inclusive,
        },
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          editserviceImage(res.data.id._id);
          // alert("Data Added successfully");
          // window.location.reload();
          getallCategory();
          getVendorService();
          getAdminservice();
          getallAdvantages();
          notify("Data Added successfully");
          handleEditClose();
          handleViewClose();
        } else {
          // alert(res.status.error);
          notify1(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      // alert("Unable to add Details");
      notify1("Unable to add Details, Please try after some time");
    }
  };

  const editserviceImage = async (id) => {
    displayimage1.forEach(async (adharObj, index) => {
      form2.append("id", id);
      form2.append("Service_Image", adharObj.Service_Image);
      try {
        const config = {
          url: "/AddserviceImage",
          method: "post",
          baseURL: "https://spazo.co.in/api/vendor",
          data: form2,
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            // alert("Data Added successfully");
          } else {
            // alert(res.status.error);
            notify1(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        // alert("Unable to add Details");
        notify1("Unable to add Details, Please try after some time");
      }
    });
  };

  const deleteServiceImage = async (serviceId, ImageId) => {
    try {
      const config = {
        url: "/deleteServiceImage/" + serviceId?._id + "/" + ImageId?._id,
        method: "delete",
        baseURL: "https://spazo.co.in/api/vendor",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          window.location.reload();
          console.log(res);
        } else {
          // alert(res.status.error);
          notify1(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      // alert("Unable to add Details");
      notify1("Unable to add Details, Please try after some time");
    }
  };

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "3",
        value: 3,
      },
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "Image",
      text: "Image",
      formatter: (value, row) => (
        <img
          src={
            "https://spazo.co.in/Service/" +
            row?.Service_ImageArray[0]?.Service_Image
          }
          width={100}
          height={80}
        />
      ),
    },
    {
      dataField: "Service_Category",
      text: "Category",
      formatter: (value, row) => (
        <p>{row?.Service_Category ? row?.Service_Category : "-"}</p>
      ),
    },
    {
      dataField: "ServiceName",
      text: "Service Name",
      formatter: (value, row) => (
        <p>{row?.ServiceName ? row?.ServiceName : "-"}</p>
      ),
    },
    {
      dataField: "Service_Duration",
      text: "Duration",
      formatter: (value, row) => (
        <p>{row?.Service_Duration ? row?.Service_Duration : "-"}</p>
      ),
    },
    {
      dataField: "ValidFor",
      text: "Valid For",
      formatter: (value, row) => <p>{row?.ValidFor ? row?.ValidFor : "-"}</p>,
    },
    {
      dataField: "slots",
      text: "Slot",
      formatter: (value, row) => <p>{row?.slots ? row?.slots : "-"}</p>,
    },
    {
      dataField: "Price",
      text: "Price",
      formatter: (value, row) => <p>{row?.Price ? row?.Price : "-"}</p>,
    },
    {
      dataField: "Discount",
      text: "Discount (%)",
      formatter: (value, row) => <p>{row?.Discount ? row?.Discount : "-"}</p>,
    },
    {
      dataField: "GST",
      text: "GST (%)",
      formatter: (value, row) => <p>{row?.GST ? row?.GST : "-"}</p>,
    },
    {
      dataField: "servicetax",
      text: "service Tax (%)",
      formatter: (value, row) => (
        <p>{row?.servicetax ? row?.servicetax : "-"}</p>
      ),
    },
    {
      dataField: "Approve",
      text: "Status",
      formatter: (value, row) => (
        <p style={{ backgroundColor: "Green", color: "white" }}>
          {row?.Approve ? row?.Approve : "-"}
        </p>
      ),
    },
    {
      dataField: "",
      text: "View",
      formatter: (value, row) => (
        <FaEye
          style={{ color: "#e84141" }}
          onClick={() => handleViewShow(row)}
        />
      ),
    },
    {
      dataField: "",
      text: "Action",
      formatter: (value, row) => (
        <RiDeleteBin2Fill
          onClick={() => handleShow2(row)}
          style={{ color: "red" }}
        />
      ),
    },
  ];

  console.log("data", data);
  console.log("Service_Image", Service_Image);
  return (
    <div className="never-sspa">
      <section>
        <div className="spa-admin-0 mb-2">
          <div className="diff-admin px-3">
            <div className="admin-name-text m-3">
              <h4>Service</h4>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={30000}
          closeOnClick
          pauseOnHover
          draggable
        />
        <div style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}>
          <div
            style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}
          >
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              // rowClasses={rowClasses}
              search
              exportCSV
            >
              {(props) => (
                <div>
                  <SearchBar {...props.searchProps} />
                  <ExportCSVButton
                    {...props.csvProps}
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                    }}
                  >
                    Download Excel
                  </ExportCSVButton>
                  <button
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                      border: "none",
                      padding: "6px",
                      borderRadius: "5px",
                      marginLeft: "467px",
                    }}
                    onClick={handleShow}
                  >
                    +Add
                  </button>
                  <hr></hr>
                  <br></br>
                  <div style={{ overflowX: "scroll", width: "100%" }}>
                    <BootstrapTable
                      striped
                      hover
                      condensed
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
          <Modal show={show} onHide={handleClose} className="servicemodal1">
            <Modal.Header closeButton>
              <Modal.Title>
                <h6>Service</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <Row>
                  <Col md={4}>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Category
                    </Col>
                    <Col md={6}>
                      <select
                        style={{ width: "178px" }}
                        onChange={(e) => setService_Category(e.target.value)}
                      >
                        <option>Select</option>
                        {category
                          ?.filter((item) => item.cattype === user?.type)
                          ?.map((cat) => (
                            <option value={cat.catname}>{cat.catname}</option>
                          ))}
                      </select>
                    </Col>
                  </Col>
                  <Col md={4}>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Service Name
                    </Col>
                    <Col md={6}>
                      <select
                        style={{ width: "178px" }}
                        onChange={(e) => setServiceName(e.target.value)}
                      >
                        <option>Select</option>
                        {service
                          ?.filter(
                            (item) =>
                              item.type === user?.type &&
                              item.catname === Service_Category
                          )
                          ?.map((item) => (
                            <option value={item.servicename}>
                              {item.servicename}
                            </option>
                          ))}
                      </select>
                    </Col>
                  </Col>
                  <Col md={4} style={{ color: "#e22217" }}>
                    <Col md={6}>Duration(Min.)</Col>
                    <Col md={6}>
                      <select
                        style={{ width: "178px" }}
                        onChange={(e) => setService_Duration(e.target.value)}
                      >
                        <option>Select</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                        <option value={25}>25</option>
                        <option value={30}>30</option>
                        <option value={35}>35</option>
                        <option value={40}>40</option>
                        <option value={45}>45</option>
                        <option value={50}>50</option>
                        <option value={55}>55</option>
                        <option value={60}>60</option>
                        <option value={65}>65</option>
                        <option value={70}>70</option>
                        <option value={75}>75</option>
                        <option value={80}>80</option>
                        <option value={85}>85</option>
                        <option value={90}>90</option>
                        <option value={95}>95</option>
                        <option value={100}>100</option>
                      </select>
                    </Col>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={4}>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Price
                    </Col>
                    <Col md={6}>
                      <input
                        placeholder="Price"
                        type="Number"
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </Col>
                  </Col>
                  <Col md={4}>
                    <Col md={6} style={{ color: "#e22217" }}>
                      GST (%)
                    </Col>
                    <Col md={6}>
                      <input
                        type="Number"
                        placeholder="GST"
                        onChange={(e) => setGST(e.target.value)}
                      />
                    </Col>
                  </Col>
                  <Col md={4}>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Service Tax (%)
                    </Col>
                    <Col md={6}>
                      <input
                        type="Number"
                        placeholder="Service Tax"
                        onChange={(e) => setservicetax(e.target.value)}
                      />
                    </Col>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={4}>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Discount (%)
                    </Col>
                    <Col md={6}>
                      <input
                        type="Number"
                        placeholder="Discount"
                        onChange={(e) => setDiscount(e.target.value)}
                      />
                    </Col>
                  </Col>
                  <Col md={4}>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Valid For
                    </Col>
                    <Col md={6}>
                      <select
                        style={{ width: "178px" }}
                        onChange={(e) => setValidFor(e.target.value)}
                      >
                        <option>Select</option>
                        <option value="Person">Person</option>
                        <option value="Couple">Couple</option>
                      </select>
                    </Col>
                  </Col>
                  <Col md={4}>
                    <Col md={12} style={{ color: "#e22217" }}>
                      Number of Slot
                    </Col>
                    <Col md={12}>
                      <input
                        type="number"
                        placeholder="Number of Slot"
                        onChange={(e) => setno_Booking_slot(e.target.value)}
                      />
                    </Col>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <Col md={12} style={{ color: "#e22217" }}>
                      Image(Select Multiple)
                    </Col>
                    <Col md={12}>
                      <input
                        type="file"
                        multiple
                        onChange={(e) => setService_Image(e.target.files)}
                      />
                    </Col>
                  </Col>
                  {displayimage?.map((item) => (
                    <>
                      <img
                        src={URL.createObjectURL(item.Service_Image)}
                        alt="avathar"
                        style={{
                          width: "90px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                    </>
                  ))}
                </Row>
                <p
                  style={{
                    color: "#e22217",
                    marginTop: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Add Other Advantages
                </p>
                {advantages?.length > 0 ? (
                  <Row className="mt-2" style={{ justifyContent: "center" }}>
                    <table className="tablexxxx" style={{ width: "75%" }}>
                      <th className="tablexxxx" style={{ color: "#e22217" }}>
                        Name
                      </th>
                      <th className="tablexxxx" style={{ color: "#e22217" }}>
                        Duration
                      </th>
                      <tbody className="tablexxxx">
                        {advantages?.map((adv) => (
                          <tr>
                            <td className="tablexxxx">{adv.adv_name}</td>
                            <td className="tablexxxx">{adv.adv_duration}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Row>
                ) : (
                  ""
                )}
                <Row className="mt-2">
                  <Col md={4}>
                    <Col md={4} style={{ color: "#e22217" }}>
                      Name
                    </Col>
                    <Col md={8}>
                      {/* <input
                        placeholder="Name"
                        onChange={(e) => setadv_name(e.target.value)}
                      /> */}
                      <select
                        onChange={(e) => setadv_name(e.target.value)}
                        style={{ width: "100%" }}
                      >
                        <option>Select</option>
                        {Advantages?.filter(
                          (item) => item.type === user?.type
                        )?.map((item) => (
                          <option>{item.name}</option>
                        ))}
                      </select>
                    </Col>
                  </Col>
                  <Col md={4}>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Duration (Min.)
                    </Col>
                    <Col md={6}>
                      {/* <input
                        type="number"
                        placeholder="Duration"
                        onChange={(e) => setadv_duration(e.target.value)}
                      /> */}
                      <select
                        style={{ width: "178px" }}
                        onChange={(e) => setadv_duration(e.target.value)}
                      >
                        <option>Select</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                        <option value={25}>25</option>
                        <option value={30}>30</option>
                        <option value={35}>35</option>
                        <option value={40}>40</option>
                        <option value={45}>45</option>
                        <option value={50}>50</option>
                        <option value={55}>55</option>
                        <option value={60}>60</option>
                      </select>
                    </Col>
                  </Col>
                  <Col md={3}>
                    <Button variant="secondary" onClick={addadvantages}>
                      Add
                    </Button>
                  </Col>
                </Row>

                {/* <p
                  style={{
                    color: "#e22217",
                    marginTop: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Add Slots
                </p>
                {slots?.length > 0 ? (
                  <Row className="mt-2" style={{ justifyContent: "center" }}>
                    <table className="tablexxxx" style={{ width: "75%" }}>
                      <th className="tablexxxx" style={{ color: "#e22217" }}>
                        Date
                      </th>
                      <th className="tablexxxx" style={{ color: "#e22217" }}>
                        From Time
                      </th>
                      <th className="tablexxxx" style={{ color: "#e22217" }}>
                        To Time
                      </th>
                      <th className="tablexxxx" style={{ color: "#e22217" }}>
                        NO. Of Booking
                      </th>
                      <tbody className="tablexxxx">
                        {slots?.map((adv) => (
                          <tr>
                            <td className="tablexxxx">{adv.slotdate}</td>
                            <td className="tablexxxx">{adv.slot_from_time}</td>
                            <td className="tablexxxx">{adv.slot_To_time}</td>
                            <td className="tablexxxx">{adv.no_Booking_slot}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Row>
                ) : (
                  ""
                )}

                <Row className="mt-3">
                  <Col md={4}>
                    <span style={{ color: "#e22217" }}>Select Data</span> &nbsp;
                    <input
                      type="date"
                      value={format(selectedDate, "yyyy-MM-dd")}
                      onChange={handleDateChange}
                    />
                  </Col>
                  <Col md={4}>
                    <span style={{ color: "#e22217" }}>Select from Time</span>{" "}
                    &nbsp;
                    <input
                      type="time"
                      onChange={(e) => setslotfromtime(e.target.value)}
                    />
                  </Col>
                  <Col md={4}>
                    <span style={{ color: "#e22217" }}>Select To Time</span>{" "}
                    &nbsp;
                    <input
                      type="time"
                      onChange={(e) => setslottotime(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4} style={{ color: "#e22217" }}>
                    No. of Booking in Each Slot
                  </Col>
                  <Col md={4}>
                    <input
                      type="number"
                      placeholder="No. of Booking in Each Slot"
                      onChange={(e) => setno_Booking_slot(e.target.value)}
                    />
                  </Col>
                  <Col md={3}>
                    <Button variant="secondary" onClick={addslots}>
                      Add
                    </Button>
                  </Col>
                </Row> */}

                <p
                  style={{
                    color: "#e22217",
                    marginTop: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Add Inclusive
                </p>

                {Inclusive?.length > 0 ? (
                  <Row className="mt-2" style={{ justifyContent: "center" }}>
                    <table className="tablexxxx" style={{ width: "75%" }}>
                      <th className="tablexxxx" style={{ color: "#e22217" }}>
                        Inclusive
                      </th>
                      <tbody className="tablexxxx">
                        {Inclusive?.map((inc) => (
                          <tr>
                            <td className="tablexxxx">{inc.inclusive_name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Row>
                ) : (
                  ""
                )}

                <Row className="mt-3">
                  <Col md={4} style={{ color: "#e22217" }}>
                    Inclusive
                  </Col>
                  <Col md={4}>
                    <input
                      placeholder="Inclusive"
                      onChange={(e) => setinclusive_name(e.target.value)}
                    />
                  </Col>
                  <Col md={3}>
                    <Button variant="secondary" onClick={addinclusive}>
                      Add
                    </Button>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={addservice}>
                Add
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={Viewshow} onHide={handleViewClose}>
            <Modal.Header closeButton>
              <h6>Service Details</h6>
            </Modal.Header>
            <Modal.Body style={{ alignItems: "center", display: "contents" }}>
              <div style={{ margin: "20px" }}>
                <Row>
                  <Row>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Category
                    </Col>
                    <Col md={6}>{Viewdata?.Service_Category}</Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Service Name
                    </Col>
                    <Col md={6}>{Viewdata?.ServiceName}</Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Service Duration
                    </Col>
                    <Col md={6}>{Viewdata?.Service_Duration} Min</Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Service Valid For
                    </Col>
                    <Col md={6}>{Viewdata?.ValidFor}</Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Slots
                    </Col>
                    <Col md={6}>{Viewdata?.slots}</Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Price
                    </Col>
                    <Col md={6}>{Viewdata?.Price} Rs</Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Service-Tax
                    </Col>
                    <Col md={6}>{Viewdata?.servicetax} %</Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ color: "#e22217" }}>
                      GST
                    </Col>
                    <Col md={6}>{Viewdata?.GST} %</Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Discount
                    </Col>
                    <Col md={6}>{Viewdata?.Discount} %</Col>
                  </Row>
                </Row>
                <Row>
                  {Viewdata?.Service_ImageArray?.map((item) => (
                    <Col md={4}>
                      <img
                        src={
                          "https://spazo.co.in/Service/" + item?.Service_Image
                        }
                        width={100}
                        height={90}
                        style={{ borderRadius: "50%" }}
                      />
                    </Col>
                  ))}
                </Row>
                {Viewdata?.advantages?.length > 0 ? (
                  <>
                    <h6>Advantages</h6>
                    <table style={{ margin: "20px", width: "80%" }}>
                      <th
                        style={{ color: "#e22217", fontSize: "15px" }}
                        className="tablexxxx"
                      >
                        Name
                      </th>
                      <th
                        style={{ color: "#e22217", fontSize: "15px" }}
                        className="tablexxxx"
                      >
                        Duration
                      </th>
                      <tbody>
                        {Viewdata?.advantages?.map((adv) => (
                          <tr>
                            <td className="tablexxxx">{adv?.adv_name}</td>
                            <td className="tablexxxx">{adv?.adv_duration}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  ""
                )}
                {Viewdata?.slots?.length > 0 ? (
                  <>
                    <h6>Slots</h6>
                    <table style={{ margin: "20px", width: "80%" }}>
                      <th
                        style={{ color: "#e22217", fontSize: "15px" }}
                        className="tablexxxx"
                      >
                        Date
                      </th>
                      <th
                        style={{ color: "#e22217", fontSize: "15px" }}
                        className="tablexxxx"
                      >
                        Start Time
                      </th>
                      <th
                        style={{ color: "#e22217", fontSize: "15px" }}
                        className="tablexxxx"
                      >
                        End Time
                      </th>
                      <th
                        style={{ color: "#e22217", fontSize: "15px" }}
                        className="tablexxxx"
                      >
                        Total Slots
                      </th>
                      <tbody>
                        {Viewdata?.slots?.map((slot) => (
                          <tr>
                            <td className="tablexxxx">{slot?.slotdate}</td>
                            <td className="tablexxxx">
                              {slot?.slot_from_time}
                            </td>
                            <td className="tablexxxx">{slot?.slot_To_time}</td>
                            <td className="tablexxxx">
                              {slot?.no_Booking_slot}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  ""
                )}
                {Viewdata?.Inclusive?.length > 0 ? (
                  <>
                    <h6>Inclusive</h6>
                    <table style={{ margin: "20px", width: "80%" }}>
                      <th
                        style={{ color: "#e22217", fontSize: "15px" }}
                        className="tablexxxx"
                      >
                        Name
                      </th>

                      <tbody>
                        {Viewdata?.Inclusive?.map((inc) => (
                          <tr>
                            <td className="tablexxxx">{inc?.inclusive_name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  ""
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleViewClose}>
                Ok
              </Button>
              <Button
                variant="primary"
                onClick={() => handleEditShow(Viewdata)}
              >
                Edit
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={Editshow}
            onHide={handleEditClose}
            className="servicemodal1"
          >
            <Modal.Header closeButton>
              <h6>Edit Service Details</h6>
            </Modal.Header>
            <Modal.Body style={{ alignItems: "center", display: "contents" }}>
              <div style={{ margin: "20px" }}>
                <Row>
                  <Col md={4}>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Category
                    </Col>
                    <Col md={6}>
                      <select
                        style={{ width: "178px" }}
                        onChange={(e) => setService_Category1(e.target.value)}
                      >
                        <option>{Editdata?.Service_Category}</option>
                        {/* {category
                          ?.filter((item) => item.cattype === user?.type)
                          ?.map((cat) => (
                            <option value={cat.catname}>{cat.catname}</option>
                          ))} */}
                      </select>
                    </Col>
                  </Col>
                  <Col md={4}>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Service Name
                    </Col>
                    <Col md={6}>
                      {/* <input
                        placeholder={Editdata?.ServiceName}
                        onChange={(e) => setServiceName1(e.target.value)}
                      /> */}

                      <select
                        style={{ width: "178px" }}
                        onChange={(e) => setServiceName1(e.target.value)}
                      >
                        <option>{Editdata?.ServiceName}</option>
                        {/* {service
                          ?.filter(
                            (item) =>
                              item.type === user?.type &&
                              item.catname === Service_Category
                          )
                          ?.map((cat) => (
                            <option value={cat.catname}>{cat.catname}</option>
                          ))} */}
                      </select>
                    </Col>
                  </Col>
                  <Col md={4} style={{ color: "#e22217" }}>
                    <Col md={6}>Duration(Min.)</Col>
                    <Col md={6}>
                      {/* <input
                        type="number"
                        placeholder={Editdata?.Service_Duration}
                        onChange={(e) => setService_Duration1(e.target.value)}
                      /> */}
                      <select
                        style={{ width: "178px" }}
                        onChange={(e) => setService_Duration1(e.target.value)}
                      >
                        <option>{Editdata?.Service_Duration}</option>
                        {/* <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                        <option value={60}>60</option> */}
                      </select>
                    </Col>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={4}>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Price
                    </Col>
                    <Col md={6}>
                      <input
                        placeholder={Editdata?.Price}
                        type="Number"
                        onChange={(e) => setPrice1(e.target.value)}
                      />
                    </Col>
                  </Col>
                  <Col md={4}>
                    <Col md={6} style={{ color: "#e22217" }}>
                      GST (%)
                    </Col>
                    <Col md={6}>
                      <input
                        type="Number"
                        placeholder={Editdata?.GST}
                        onChange={(e) => setGST1(e.target.value)}
                      />
                    </Col>
                  </Col>
                  <Col md={4}>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Service Tax (%)
                    </Col>
                    <Col md={6}>
                      <input
                        type="Number"
                        placeholder={Editdata?.servicetax}
                        onChange={(e) => setservicetax1(e.target.value)}
                      />
                    </Col>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Discount (%)
                    </Col>
                    <Col md={6}>
                      <input
                        type="Number"
                        placeholder={Editdata?.Discount}
                        onChange={(e) => setDiscount1(e.target.value)}
                      />
                    </Col>
                  </Col>

                  <Col md={4}>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Valid For
                    </Col>
                    <Col md={6}>
                      <select
                        style={{ width: "178px" }}
                        onChange={(e) => setValidFor1(e.target.value)}
                      >
                        <option>{Editdata?.ValidFor}</option>
                        {/* <option value="Person">Person</option>
                        <option value="Couple">Couple</option> */}
                      </select>
                    </Col>
                  </Col>
                  <Col md={4}>
                    <Col md={12} style={{ color: "#e22217" }}>
                      Number of Slot
                    </Col>
                    <Col md={12}>
                      <input
                        type="number"
                        placeholder={Editdata?.slots}
                        onChange={(e) => setno_Booking_slot1(e.target.value)}
                      />
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <p style={{ color: "#e22217" }}>Image</p>

                  {Editdata?.Service_ImageArray?.map((item) => (
                    <Col md={2}>
                      <img
                        src={
                          "https://spazo.co.in/Service/" + item?.Service_Image
                        }
                        width={90}
                        height={90}
                        style={{ borderRadius: "50%" }}
                      />

                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                          position: "absolute",
                          marginLeft: "-20px",
                        }}
                        onClick={() => deleteServiceImage(Editdata, item)}
                      >
                        <i
                          class="fas fa-times-circle"
                          size={30}
                          style={{ color: "#e22217" }}
                        ></i>
                      </button>
                    </Col>
                  ))}
                  {displayimage1?.map((item) => (
                    <>
                      <img
                        src={URL.createObjectURL(item.Service_Image)}
                        alt="avathar"
                        style={{
                          width: "110px",
                          height: "90px",
                          borderRadius: "50px",
                        }}
                      />
                    </>
                  ))}

                  <label htmlFor="setService_Image">
                    <Input
                      accept="image/*"
                      id="setService_Image"
                      type="file"
                      multiple
                      onChange={(e) => setService_Image1(e.target.files)}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQueVhIdH7EKL6sLlHuCK4etHw1nctOIza_Zg&usqp=CAU"
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                </Row>
                <hr></hr>
                <h6>Add Other Advantages</h6>
                {advantages1?.length > 0 ? (
                  <>
                    <Row className="mt-2" style={{ justifyContent: "center" }}>
                      <table className="tablexxxx" style={{ width: "75%" }}>
                        <th className="tablexxxx" style={{ color: "#e22217" }}>
                          Name
                        </th>
                        <th className="tablexxxx" style={{ color: "#e22217" }}>
                          Duration
                        </th>
                        <tbody className="tablexxxx">
                          {advantages1?.map((adv) => (
                            <tr>
                              <td className="tablexxxx">{adv.adv_name}</td>
                              <td className="tablexxxx">{adv.adv_duration}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Row>
                  </>
                ) : (
                  <>
                    {Editdata?.advantages?.length > 0 ? (
                      <Row
                        className="mt-2"
                        style={{ justifyContent: "center" }}
                      >
                        <table className="tablexxxx" style={{ width: "75%" }}>
                          <th
                            className="tablexxxx"
                            style={{ color: "#e22217" }}
                          >
                            Name
                          </th>
                          <th
                            className="tablexxxx"
                            style={{ color: "#e22217" }}
                          >
                            Duration
                          </th>
                          <tbody className="tablexxxx">
                            {Editdata?.advantages?.map((adv) => (
                              <tr>
                                <td className="tablexxxx">{adv.adv_name}</td>
                                <td className="tablexxxx">
                                  {adv.adv_duration}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Row>
                    ) : (
                      ""
                    )}
                  </>
                )}

                <Row className="mt-2">
                  <Col md={4}>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Name
                    </Col>
                    <Col md={6}>
                      {/* <input
                        placeholder="Name"
                        onChange={(e) => setadv_name1(e.target.value)}
                      /> */}
                      <select onChange={(e) => setadv_name1(e.target.value)}>
                        <option>Select</option>
                        {Advantages?.map((item) => (
                          <option>{item.name}</option>
                        ))}
                      </select>
                    </Col>
                  </Col>
                  <Col md={4}>
                    <Col md={6} style={{ color: "#e22217" }}>
                      Duration (Min.)
                    </Col>
                    <Col md={6}>
                      {/* <input
                        type="number"
                        placeholder="Duration"
                        onChange={(e) => setadv_duration1(e.target.value)}
                      /> */}
                      <select
                        style={{ width: "178px" }}
                        onChange={(e) => setadv_duration1(e.target.value)}
                      >
                        <option>Select</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                        <option value={60}>60</option>
                      </select>
                    </Col>
                  </Col>
                  <Col md={3}>
                    <Button variant="secondary" onClick={addadvantages1}>
                      Add
                    </Button>
                  </Col>
                </Row>
                <hr></hr>
                {/* <h6>Add Slots</h6>

                {slots1?.length > 0 ? (
                  <>
                    <Row className="mt-2" style={{ justifyContent: "center" }}>
                      <table className="tablexxxx" style={{ width: "75%" }}>
                        <th className="tablexxxx" style={{ color: "#e22217" }}>
                          Date
                        </th>
                        <th className="tablexxxx" style={{ color: "#e22217" }}>
                          From Time
                        </th>
                        <th className="tablexxxx" style={{ color: "#e22217" }}>
                          To Time
                        </th>
                        <th className="tablexxxx" style={{ color: "#e22217" }}>
                          NO. Of Booking
                        </th>
                        <tbody className="tablexxxx">
                          {slots1?.map((adv) => (
                            <tr>
                              <td className="tablexxxx">{adv.slotdate}</td>
                              <td className="tablexxxx">
                                {adv.slot_from_time}
                              </td>
                              <td className="tablexxxx">{adv.slot_To_time}</td>
                              <td className="tablexxxx">
                                {adv.no_Booking_slot}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Row>
                  </>
                ) : (
                  <>
                    {Editdata?.slots?.length > 0 ? (
                      <Row
                        className="mt-2"
                        style={{ justifyContent: "center" }}
                      >
                        <table className="tablexxxx" style={{ width: "75%" }}>
                          <th
                            className="tablexxxx"
                            style={{ color: "#e22217" }}
                          >
                            Date
                          </th>
                          <th
                            className="tablexxxx"
                            style={{ color: "#e22217" }}
                          >
                            From Time
                          </th>
                          <th
                            className="tablexxxx"
                            style={{ color: "#e22217" }}
                          >
                            To Time
                          </th>
                          <th
                            className="tablexxxx"
                            style={{ color: "#e22217" }}
                          >
                            NO. Of Booking
                          </th>
                          <tbody className="tablexxxx">
                            {Editdata?.slots?.map((adv) => (
                              <tr>
                                <td className="tablexxxx">{adv.slotdate}</td>
                                <td className="tablexxxx">
                                  {adv.slot_from_time}
                                </td>
                                <td className="tablexxxx">
                                  {adv.slot_To_time}
                                </td>
                                <td className="tablexxxx">
                                  {adv.no_Booking_slot}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Row>
                    ) : (
                      ""
                    )}
                  </>
                )}

                <Row className="mt-3">
                  <Col md={4}>
                    <span style={{ color: "#e22217" }}>Select Date</span> &nbsp;
                    <input
                      type="date"
                      onChange={(e) => setslotdate1(e.target.value)}
                    />
                  </Col>
                  <Col md={4}>
                    <span style={{ color: "#e22217" }}>Select from Time</span>{" "}
                    &nbsp;
                    <input
                      type="time"
                      onChange={(e) => setslotfromtime1(e.target.value)}
                    />
                  </Col>
                  <Col md={4}>
                    <span style={{ color: "#e22217" }}>Select To Time</span>{" "}
                    &nbsp;
                    <input
                      type="time"
                      onChange={(e) => setslottotime1(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4} style={{ color: "#e22217" }}>
                    No. of Booking in Each Slot
                  </Col>
                  <Col md={4}>
                    <input
                      type="number"
                      placeholder="No. of Booking in Each Slot"
                      onChange={(e) => setno_Booking_slot1(e.target.value)}
                    />
                  </Col>
                  <Col md={3}>
                    <Button variant="secondary" onClick={addslots1}>
                      Add
                    </Button>
                  </Col>
                </Row>
                <hr></hr> */}

                <h6>Add Inclusive</h6>
                {Inclusive1?.length > 0 ? (
                  <>
                    <Row className="mt-2" style={{ justifyContent: "center" }}>
                      <table className="tablexxxx" style={{ width: "75%" }}>
                        <th className="tablexxxx" style={{ color: "#e22217" }}>
                          Inclusive
                        </th>
                        <tbody className="tablexxxx">
                          {Inclusive1?.map((inc) => (
                            <tr>
                              <td className="tablexxxx">
                                {inc.inclusive_name}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Row>
                  </>
                ) : (
                  <>
                    {Editdata?.Inclusive?.length > 0 ? (
                      <Row
                        className="mt-2"
                        style={{ justifyContent: "center" }}
                      >
                        <table className="tablexxxx" style={{ width: "75%" }}>
                          <th
                            className="tablexxxx"
                            style={{ color: "#e22217" }}
                          >
                            Inclusive
                          </th>
                          <tbody className="tablexxxx">
                            {Editdata?.Inclusive?.map((inc) => (
                              <tr>
                                <td className="tablexxxx">
                                  {inc.inclusive_name}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Row>
                    ) : (
                      ""
                    )}
                  </>
                )}

                <Row className="mt-3">
                  <Col md={4} style={{ color: "#e22217" }}>
                    Inclusive
                  </Col>
                  <Col md={4}>
                    <input
                      placeholder="Inclusive"
                      onChange={(e) => setinclusive_name1(e.target.value)}
                    />
                  </Col>
                  <Col md={3}>
                    <Button variant="secondary" onClick={addinclusive1}>
                      Add
                    </Button>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary">Ok</Button>
              <Button variant="primary" onClick={editservice}>
                Update
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Service</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure, you want to delete data</Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose2}>
                Close
              </Button>
              <Button variant="primary" onClick={deleteService}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </section>
    </div>
  );
}

export default Vendorservices;
