import axios from "axios";
import React, { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";

function Vendorsidebar() {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [Dashboard, setDashboard] = useState(true);
  const [Employee, setEmployee] = useState(false);
  const [Booking_History, setBooking_History] = useState(false);
  const [Profile, setProfile] = useState(false);
  const [Service, setService] = useState(false);
  const [VendorOffdutyTherapist, setVendorOffdutyTherapist] = useState(false);
  const [Account, setAccount] = useState(false);
  const [DaywiseAccount, setDaywiseAccount] = useState(false);
  const [Logout, setLogout] = useState(false);

  const Vendorsignout = () => {
    try {
      axios
        .get("https://spazo.co.in/api/vendor/Vendorsignout/" + user._id)
        .then(function (res) {
          if (res.status == 200) {
            sessionStorage.removeItem("user");
            alert("Signout Success....!");
            window.location.assign("/");
            return;
          } else {
            alert("Signout Unsuccessfully");
            return;
          }
        });
    } catch (error) {
      console.warn(error);
      alert("Signout Unsuccessfully");
    }
  };

  return (
    // <div>
    //   <Sidebar>
    //     <Menu
    //       menuItemStyles={{
    //         button: {
    //           // the active class will be added automatically by react router
    //           // so we can use it to style the active menu item
    //           [`&.active`]: {
    //             backgroundColor: "#e22217",
    //             color: "white",
    //           },
    //         },
    //       }}
    //     >
    //       {/* <SubMenu label="Charts">
    //         <MenuItem> Pie charts </MenuItem>
    //         <MenuItem> Line charts </MenuItem>
    //       </SubMenu> */}
    //       <MenuItem component={<Link to="/Vendordashboard" />}>
    //         Dashboard
    //       </MenuItem>
    //       <MenuItem component={<Link to="/Vendorprofile" />}>Profile</MenuItem>
    //       <MenuItem component={<Link to="/VendorEmployee" />}>
    //         Employee
    //       </MenuItem>{" "}
    //       <MenuItem component={<Link to="/Vendorservices" />}>Service</MenuItem>{" "}
    //       <MenuItem component={<Link to="/vendorBooking" />}>
    //         Booking History
    //       </MenuItem>{" "}
    //       <MenuItem component={<Link to="/VendorAccount" />}>Account</MenuItem>
    //       <MenuItem component={<Link onClick={Vendorsignout} />}>
    //         Logout
    //       </MenuItem>
    //     </Menu>
    //   </Sidebar>

    // </div>

    <div className="si09">
      <div className="lo-ad d-flex">
        <img
          src="./logo.jpg"
          alt="adminlogo"
          style={{
            width: "160px",
            height: "63px",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "10px",
          }}
        />
      </div>
      <ul className="">
        <Link to="/Vendordashboard">
          <li
            className={`a-ele ${Dashboard ? "active-0" : ""}`}
            onClick={() => {
              setDashboard(true);
              setEmployee(false);
              setBooking_History(false);
              setProfile(false);
              setService(false);
              setVendorOffdutyTherapist(false);
              setAccount(false);
              setDaywiseAccount(false);
              setLogout(false);
            }}
          >
            Dashboard
          </li>
        </Link>

        <Link to="/Vendorprofile">
          <li
            className={`a-ele ${Profile ? "active-0" : "null"}`}
            onClick={() => {
              setDashboard(false);
              setEmployee(false);
              setBooking_History(false);
              setProfile(true);
              setService(false);
              setVendorOffdutyTherapist(false);
              setAccount(false);
              setDaywiseAccount(false);
              setLogout(false);
            }}
          >
            Profile
          </li>
        </Link>

        <Link to="/VendorEmployee">
          <li
            className={`a-ele ${Employee ? "active-0" : "null"}`}
            onClick={() => {
              setDashboard(false);
              setEmployee(true);
              setBooking_History(false);
              setProfile(false);
              setService(false);
              setVendorOffdutyTherapist(false);
              setAccount(false);
              setDaywiseAccount(false);
              setLogout(false);
            }}
          >
            Therapist
          </li>
        </Link>
        <Link to="/Vendorservices">
          <li
            className={`a-ele ${Service ? "active-0" : "null"}`}
            onClick={() => {
              setDashboard(false);
              setEmployee(false);
              setBooking_History(false);
              setProfile(false);
              setService(true);
              setVendorOffdutyTherapist(false);
              setAccount(false);
              setDaywiseAccount(false);
              setLogout(false);
            }}
          >
            Service
          </li>
        </Link>
        <Link to="/VendorOffdutyTherapist">
          <li
            className={`a-ele ${VendorOffdutyTherapist ? "active-0" : "null"}`}
            onClick={() => {
              setDashboard(false);
              setEmployee(false);
              setBooking_History(false);
              setProfile(false);
              setService(false);
              setVendorOffdutyTherapist(true);
              setAccount(false);
              setDaywiseAccount(false);
              setLogout(false);
            }}
          >
            Off-Duty Therapist
          </li>
        </Link>
        <Link to="/vendorBooking">
          <li
            className={`a-ele ${Booking_History ? "active-0" : "null"}`}
            onClick={() => {
              setDashboard(false);
              setEmployee(false);
              setBooking_History(true);
              setProfile(false);
              setService(false);
              setVendorOffdutyTherapist(false);
              setAccount(false);
              setDaywiseAccount(false);
              setLogout(false);
            }}
          >
            Booking
          </li>
        </Link>

        <Link to="/VendorAccount">
          <li
            className={`a-ele ${Account ? "active-0" : "null"}`}
            onClick={() => {
              setDashboard(false);
              setEmployee(false);
              setBooking_History(false);
              setProfile(false);
              setService(false);
              setVendorOffdutyTherapist(false);
              setAccount(true);
              setDaywiseAccount(false);
              setLogout(false);
            }}
          >
            Account Details
          </li>
        </Link>
        <Link to="/VendorDaywiseAccountDetails">
          <li
            className={`a-ele ${DaywiseAccount ? "active-0" : "null"}`}
            onClick={() => {
              setDashboard(false);
              setEmployee(false);
              setBooking_History(false);
              setProfile(false);
              setService(false);
              setVendorOffdutyTherapist(false);
              setAccount(false);
              setDaywiseAccount(true);
              setLogout(false);
            }}
          >
            Daywise Account Details
          </li>
        </Link>

        <Link onClick={() => setLogout(true)}>
          <li
            className={`a-ele ${Logout ? "active-0" : "null"}`}
            onClick={() => {
              setDashboard(false);
              setEmployee(false);
              setBooking_History(false);
              setProfile(false);
              setService(false);
              setVendorOffdutyTherapist(false);
              setAccount(false);
              setDaywiseAccount(false);
              setLogout(true);
            }}
          >
            LogOut
          </li>
        </Link>

        {Logout ? (
          <>
            <Link onClick={Vendorsignout}>
              <li
                className={`a-ele ${Logout ? "active-0" : "null"}`}
                style={{ backgroundColor: "Green" }}
              >
                Logout
              </li>
            </Link>
            <Link onClick={() => setLogout(false)}>
              <li
                className={`a-ele ${Logout ? "active-0" : "null"}`}
                style={{ backgroundColor: "Green" }}
              >
                cancel
              </li>
            </Link>
          </>
        ) : (
          <></>
        )}
      </ul>
    </div>
  );
}

export default Vendorsidebar;
