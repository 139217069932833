import React, { useEffect, useState } from "react";
import "../Styles/product.css";
import Form from "react-bootstrap/Form";
import Spajson from "./Spa";
import { AiFillStar } from "react-icons/ai";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";

function CategoryProduct() {
  document.title = "SPA-Product";

  const location = useLocation();
  const { cat } = location.state;

  const [category, setcategory] = useState([]);
  const [vendor, setvendor] = useState([]);
  const [filterdata, setfilterdata] = useState([]);
  const [categoryfilter, setcategoryfilter] = useState("");
  const [test, settest] = useState(false);

  const filt = vendor.filter((person) =>
    person.service.some((hobby) => hobby.Service_Category === cat)
  );

  useEffect(() => {
    getallCategory();
    getallVendor();
  }, []);

  useEffect(() => {
    setfilterdata(filt);
    if (categoryfilter) {
      const filttt = vendor?.filter((person) =>
        person.service.some(
          (hobby) => hobby.Service_Category === categoryfilter
        )
      );
      setfilterdata(filttt);
      settest(true);
    }
  }, [categoryfilter]);

  const getallCategory = () => {
    axios
      .get("https://spazo.co.in/api/admin/getcategory")
      .then(function (response) {
        console.log(response.data);
        setcategory(response.data.category);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getallVendor = () => {
    axios
      .get("https://spazo.co.in/api/vendor/getVendorWithService")
      .then(function (response) {
        console.log(response.data);
        setvendor(
          response.data.vendor?.filter((item) => item.approved === "Approved")
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log("cat", cat, filterdata);
  return (
    <>
      <div
      //  style={{ backgroundColor: "#fbd7d4" }}
      >
        <section>
          <div className="product-spa mx-5">
            <div className="container-fluid">
              <div className="left-right">
                <div className="left-0">
                  <div className="de-top">
                    <h4>Filter</h4>
                  </div>

                  <div className="categories">
                    {/* Location */}
                    <div className="spa-item">
                      <h6>Location</h6>
                    </div>
                    <div className="search-pro mb-3">
                      <input
                        type="text"
                        placeholder="Search for Location...."
                      />
                    </div>

                    <p>
                      <hr />
                    </p>
                    {/* Categories */}
                    <div className="spa-item">
                      <h6>Categories</h6>
                    </div>
                    <ul className="p-0 scrollhide">
                      {category?.map((cat) => (
                        <li onClick={() => setcategoryfilter(cat?.catname)}>
                          {cat?.catname}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="right-0" style={{ width: "75%" }}>
                  {/* <div className="spa-item">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setpricefilter(e.target.value)}
                      style={{ width: "150px" }}
                    >
                      <option value="">Filter as per price</option>
                      <option value="High to Low">High to Low</option>
                      <option value="Low to High">Low to High</option>
                    </Form.Select>
                  </div> */}
                  <div className="different mt-5">
                    <div className="row">
                      {test
                        ? filterdata?.map((item, index) => {
                            return (
                              <div className="col-md-4">
                                <div class="item mt-3 mb-3">
                                  <div className="massage-0">
                                    <Link
                                      to="/product-details"
                                      state={{ dis: item }}
                                    >
                                      <div className="customer">
                                        <img
                                          src={
                                            "https://spazo.co.in/Vendor/" +
                                            item.spaimage1
                                          }
                                          alt="spa-images"
                                          // height="185px"
                                          // style={{ height: "185px" }}
                                        />
                                        <div className="cust-ti pt-2 product-90">
                                          <h6>{item.businessName}</h6>
                                          <div className="location">
                                            {item.address}
                                          </div>
                                          {/* <div className="massage-type">
                                    {item.vendor}
                                  </div> */}
                                          <div
                                            className="rating"
                                            style={{ color: "#FF9529" }}
                                          >
                                            <span>
                                              <AiFillStar />
                                            </span>
                                            <span>
                                              <AiFillStar />
                                            </span>
                                            <span>
                                              <AiFillStar />
                                            </span>
                                            <span>
                                              <AiFillStar />
                                            </span>
                                            <span>
                                              <AiFillStar />
                                            </span>
                                          </div>
                                          <div className="tit-p">
                                            <div className="price">
                                              <div>
                                                <s>₹{item.basicprice}</s>
                                              </div>
                                              <div className="discount-price">
                                                ₹
                                                {item.basicprice -
                                                  (item.basicprice *
                                                    item.mindiscount) /
                                                    100}
                                              </div>
                                            </div>
                                            <div className="discount">
                                              {item.mindiscount} % off
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : filt?.map((item, index) => {
                            return (
                              <div className="col-md-4">
                                <div class="item mt-3 mb-3">
                                  <div className="massage-0">
                                    <Link
                                      to="/product-details"
                                      state={{ dis: item }}
                                    >
                                      <div className="customer">
                                        <img
                                          src={
                                            "https://spazo.co.in/Vendor/" +
                                            item.spaimage1
                                          }
                                          alt="spa-images"
                                          // height="185px"
                                          // style={{ height: "185px" }}
                                        />
                                        <div className="cust-ti pt-2 product-90">
                                          <h6>{item.businessName}</h6>
                                          <div className="location">
                                            {item.address}
                                          </div>
                                          {/* <div className="massage-type">
                                        {item.vendor}
                                      </div> */}
                                          <div
                                            className="rating"
                                            style={{ color: "#FF9529" }}
                                          >
                                            <span>
                                              <AiFillStar />
                                            </span>
                                            <span>
                                              <AiFillStar />
                                            </span>
                                            <span>
                                              <AiFillStar />
                                            </span>
                                            <span>
                                              <AiFillStar />
                                            </span>
                                            <span>
                                              <AiFillStar />
                                            </span>
                                          </div>
                                          <div className="tit-p">
                                            <div className="price">
                                              <div>
                                                <s>₹{item.basicprice}</s>
                                              </div>
                                              <div className="discount-price">
                                                ₹
                                                {item.basicprice -
                                                  (item.basicprice *
                                                    item.mindiscount) /
                                                    100}
                                              </div>
                                            </div>
                                            <div className="discount">
                                              {item.mindiscount} % off
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default CategoryProduct;
