import axios from "axios";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AdminLogin() {
  const notify = (data) => {
    toast.success(data, {
      position: "top-center", // Position the toast
      autoClose: 5000, // Automatically close after 5 seconds
      hideProgressBar: false, // Show the progress bar
      closeOnClick: true, // Close the toast on click
      pauseOnHover: true, // Pause auto-close on hover
      draggable: true, // Allow dragging to close
      // theme: "colored", // Use a colored theme
    });
  };
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const [email, setemail] = useState("");
  const [pwd, setpwd] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const login = async (e) => {
    if (!email | !pwd) {
      notify2("Please Fill All The Field");
      // alert("Please Fill All The Field");
    } else {
      try {
        const config = {
          url: "/adminLogin",
          method: "post",
          baseURL: "https://spazo.co.in/api/admin",
          headers: { "content-type": "application/json" },
          data: {
            email: email,
            pwd: pwd,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          if (res.data.admin.isBlocked) {
            notify2("You are blocked by Admin");
          } else {
            notify("Login Success");
            console.log(res.data);
            console.log(res.data.success);
            // alert("Login Success");
            setTimeout(() => {
              sessionStorage.setItem("admin", JSON.stringify(res.data.admin));
              window.location.assign("/dashboard");
            }, 1000); // Adjust delay as needed
          }
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          notify1(error.response.data.error);
          // alert(error.response.data.error);
        }
      }
    }
  };

  return (
    <>
      {/* <div
        className="login"
        style={{
          backgroundImage: "url('../../Assets/images/spa-beauty.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
        }}
      >
        <div className="row me-0">
          <div className="col-md-12 p-0">
            <div className="fresh">
              <img
                src="./logo.jpg"
                alt="logo"
                style={{ width: "100px", height: "100px" }}
              />
              <div className="customers">
                <h6>SPA for Admin</h6>
                <p>Create an account or log in to manage your website.</p>
              </div>
              <div className="cust-login mb-3">
                <input
                  type="text"
                  placeholder="Enter your eamil address"
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
              <div className="cust-login mb-3">
                <input
                  type={passwordShown ? "text" : "password"}
                  placeholder="Enter your Password"
                  onChange={(e) => setpwd(e.target.value)}
                />

                {passwordShown ? (
                  <button
                    onClick={togglePassword}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      marginLeft: "-27px",
                      paddingRight: "10px",
                    }}
                  >
                    <i
                      class="fa fa-eye-slash"
                      aria-hidden="true"
                      style={{ color: "#e22217" }}
                    ></i>
                  </button>
                ) : (
                  <button
                    onClick={togglePassword}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      marginLeft: "-27px",
                      paddingRight: "10px",
                    }}
                  >
                    <i
                      class="fa fa-eye"
                      aria-hidden="true"
                      style={{ color: "#e22217" }}
                    ></i>
                  </button>
                )}
              </div>
              <div className="cust-login-0 mb-3">
                <button onClick={login}>
                  
                  Login
                </button>
              </div>
            </div>
          </div>
       
        </div>
      </div> */}

      <div className="admincontainer">
        <img
          src="../../Assets/images/spa-beauty.jpg"
          alt="Snow"
          style={{ width: "100%", height: "100vh" }}
        />

        <ToastContainer
          position="top-center"
          autoClose={30000}
          closeOnClick
          pauseOnHover
          draggable
        />

        <div className="centered">
          <div className="fresh">
            <img
              src="./logo.jpg"
              alt="logo"
              style={{ width: "170px", height: "100px" }}
            />
            <div className="customers">
              <h6>For Admin/Sub-Admin</h6>
              <p>Log in to manage your website.</p>
            </div>
            <div className="cust-login mb-3">
              <input
                type="text"
                placeholder="Enter your email address"
                onChange={(e) => setemail(e.target.value)}
              />
            </div>
            <div className="cust-login mb-3">
              <input
                type={passwordShown ? "text" : "password"}
                placeholder="Enter your Password"
                onChange={(e) => setpwd(e.target.value)}
              />

              {passwordShown ? (
                <button
                  onClick={togglePassword}
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    marginLeft: "-27px",
                    paddingRight: "10px",
                  }}
                >
                  <i
                    class="fa fa-eye"
                    aria-hidden="true"
                    style={{ color: "#e22217" }}
                  ></i>
                </button>
              ) : (
                <button
                  onClick={togglePassword}
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    marginLeft: "-27px",
                    paddingRight: "10px",
                  }}
                >
                  <i
                    class="fa fa-eye-slash"
                    aria-hidden="true"
                    style={{ color: "#e22217" }}
                  ></i>
                </button>
              )}
            </div>
            <div className="cust-login-0 mb-3">
              <button onClick={login}>Login</button>
            </div>
            <a
              href="#"
              onClick={() => window.location.assign("/AdminEnterEmail")}
              style={{ alignSelf: "flex-end", marginRight: "10px" }}
            >
              Forgot Password..?
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminLogin;
