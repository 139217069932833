import "./App.css";
import Home from "./Component/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Screen from "./Component/Screen";
import "aos/dist/aos.css";
import Aos from "aos";
import Product from "./Component/Product";
import Topheader from "./Component/Topheader";
import ProductDetails from "./Component/ProductDetails";
import Footer from "./Component/Footer";
import Allimages from "./Component/Allimages";
import Login from "./Component/Login";
import Register from "./Component/Register";
import Business from "./Component/Vendor/Business";
import CheckoutPage from "./Component/CheckoutPage";
import AdminLogin from "./Admin/AdminLogin";
import Dashboard from "./Admin/Dashboard";
import AdminDashboard from "./Admin/AdminDashboard";
import Banner from "./Admin/Banner";
import Service from "./Admin/Service";
import Massage from "./Admin/Massage";
import Category from "./Admin/Category";
import Vendor from "./Admin/Vendor";
import VHome from "./Component/Vendor/VHome";
import Myprofile from "./Component/Vendor/Myprofile";
import Updateprofile from "./Component/Vendor/Updateprofile";
import Updateprofile1 from "./Component/Vendor/Updateprofile1";
import Updateprofile2 from "./Component/Vendor/Updateprofile2";
import Updateprofile3 from "./Component/Vendor/Updateprofile3";
import Vendordashboard from "./Component/Vendor/Vendordashboard";
import Vendorprofile from "./Component/Vendor/Vendorprofile";
import Vendorservices from "./Component/Vendor/Vendorservices";
import Reviews from "./Admin/Reviews";
import Banner2 from "./Admin/Banner2";
import Banner1 from "./Admin/Banner1";
import VendorBooking from "./Component/Vendor/VendorBooking";
import VendorAccount from "./Component/Vendor/VendorAccount";
import CategoryProduct from "./Component/CategoryProduct";
import BudgetProduct from "./Component/BudgetProduct";
import ServiceProduct from "./Component/ServiceProduct";
import Booking from "./Admin/Booking";
import Customers from "./Admin/Customers";
import ContactUS from "./Component/Contactus";
import Message from "./Admin/Message";
import Homescreen from "./Component/Homescreen";
import VendorEmployee from "./Component/Vendor/VendorEmployee";
import Employee from "./Admin/Employee";
import Vendordashbordmain from "./Component/Vendor/Vendordashbordmain";
import Vendorheader from "./Component/Vendor/Vendorheader";
import Adminservice from "./Admin/Adminservice";
import CreateSubadmin from "./Admin/CreateSubadmin";
import Subadmindetails from "./Admin/Subadmindetails";
import EditVendorRequest from "./Admin/EditVendorRequest";
import Advantages from "./Admin/Advantages";
import NewHome from "./Component/NewUI/NewHome";
import Newproduct from "./Component/NewUI/Newproduct";
import Newproductdetails from "./Component/NewUI/Newproductdetails";
import Checkout from "./Component/NewUI/Checkout";
import NewFooter from "./Component/NewUI/NewFooter";
import Catproduct from "./Component/NewUI/Catproduct";
import Serviceproduct from "./Component/NewUI/Serviceproduct";
import Promocode from "./Admin/Promocode";
import AddvendorP from "./Admin/AddvendorP";
import AdminAccountdetails from "./Admin/AdminAccountdetails";
import AdminTherapist from "./Admin/AdminTherapist";
import SelectTherapist from "./Component/NewUI/SelectTherapist";
import Selectdate from "./Component/NewUI/Selectdate";
import VendorDaywiseAccountDetails from "./Component/Vendor/VendorDaywiseAccountDetails";
import AdminDaywiseAccountDetails from "./Admin/AdminDaywiseAccountDetails";
import SalonVendor from "./Admin/SalonVendor";
import Salonservice from "./Admin/Salonservice";
import SalonTherapist from "./Admin/SalonTherapist";
import SalonVendorP from "./Admin/SalonVendorP";
import SalonBooking from "./Admin/SalonBooking";
import SalonDaywiseAccountDetails from "./Admin/SalonDaywiseAccountDetails";
import SalonReview from "./Admin/SalonReview";
import VendorSelectTherapist from "./Component/Vendor/VendorSelectTherapist";
import VendorSelectDate from "./Component/Vendor/VendorSelectDate";
import Salonaccountdetails from "./Admin/Salonaccountdetails";
import OffdutyTherapist from "./Admin/OffdutyTherapist";
import VendorOffdutyTherapist from "./Component/Vendor/VendorOffdutyTherapist";
import SalonEditVendorRequest from "./Admin/SalonEditVendorRequest";
import Topbrands from "./Admin/Topbrands";
import StarHotels from "./Admin/StarHotels";
import MallandShopping from "./Admin/MallandShopping";
import NewbudgetProducts from "./Component/NewUI/NewbudgetProducts";
import Customerprofile from "./Component/NewUI/Customerprofile";
import EnterEmail from "./Component/EnterEmail";
import VerifyOtp from "./Component/VerifyOtp";
import ChangePassword from "./Component/ChangePassword";
import VendorEnterEmail from "./Component/Vendor/VendorEnterEmail";
import VendorverifyOtp from "./Component/Vendor/VendorverifyOtp";
import VendorChangepassword from "./Component/Vendor/VendorChangepassword";
import Cityproduct from "./Component/NewUI/Cityproduct";
import AdminEnterEmail from "./Admin/AdminEnterEmail";
import AdminverifyOtp from "./Admin/AdminverifyOtp";
import AdminChangepassword from "./Admin/AdminChangepassword";
import Brandproductdetails from "./Component/NewUI/Brandproductdetails";
import Privacy from "./Component/NewUI/Privacy";
import TermsCondition from "./Component/NewUI/TermsCondition";
import DeleteTherapist from "./Admin/DeleteTherapist";
import SalonDeleteTherapist from "./Admin/SalonDeleteTherapist";

function App() {
  Aos.init();
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Screen />} />
          <Route
            path="/homescreen"
            exact
            element={
              <>
                <Homescreen />
              </>
            }
          />
          <Route
            path="/home"
            exact
            element={
              <>
                {" "}
                <Topheader />
                <Home />
              </>
            }
          />
          <Route
            path="/EnterEmail"
            exact
            element={
              <>
                <EnterEmail />
              </>
            }
          />
          <Route
            path="/VendorEnterEmail"
            exact
            element={
              <>
                <VendorEnterEmail />
              </>
            }
          />
          <Route
            path="/VerifyOtp"
            exact
            element={
              <>
                <VerifyOtp />
              </>
            }
          />
          <Route
            path="/VendorverifyOtp"
            exact
            element={
              <>
                <VendorverifyOtp />
              </>
            }
          />
          <Route
            path="/ChangePassword"
            exact
            element={
              <>
                <ChangePassword />
              </>
            }
          />
          <Route
            path="/VendorChangepassword"
            exact
            element={
              <>
                <VendorChangepassword />
              </>
            }
          />
          <Route
            path="/NewHome"
            exact
            element={
              <>
                <NewHome />
              </>
            }
          />
          <Route
            path="/Privacy"
            exact
            element={
              <>
                <Privacy />
                <NewFooter />
              </>
            }
          />
          <Route
            path="/TermsCondition"
            exact
            element={
              <>
                <TermsCondition />
                <NewFooter />
              </>
            }
          />
          <Route
            path="/Customerprofile"
            exact
            element={
              <>
                <Customerprofile />
                <NewFooter />
              </>
            }
          />
          <Route
            path="/Newproductdetails"
            exact
            element={
              <>
                <Newproductdetails />
              </>
            }
          />
          <Route
            path="/Brandproductdetails"
            exact
            element={
              <>
                <Brandproductdetails />
              </>
            }
          />
          <Route
            path="/NewbudgetProducts"
            exact
            element={
              <>
                <NewbudgetProducts />
              </>
            }
          />
          <Route
            path="/Newproduct"
            exact
            element={
              <>
                <Newproduct />
              </>
            }
          />
          <Route
            path="/Cityproduct"
            exact
            element={
              <>
                <Cityproduct />
              </>
            }
          />
          <Route
            path="/Catproduct"
            exact
            element={
              <>
                <Catproduct />
              </>
            }
          />
          <Route
            path="/Serviceproduct"
            exact
            element={
              <>
                <Serviceproduct />
              </>
            }
          />
          <Route
            path="/Checkout"
            exact
            element={
              <>
                <Checkout />
              </>
            }
          />
          <Route
            path="/SelectTherapist"
            exact
            element={
              <>
                <SelectTherapist />
              </>
            }
          />
          <Route
            path="/VendorSelectTherapist"
            exact
            element={
              <>
                <VendorSelectTherapist />
              </>
            }
          />
          <Route
            path="/Selectdate"
            exact
            element={
              <>
                <Selectdate />
              </>
            }
          />
          <Route
            path="/VendorSelectDate"
            exact
            element={
              <>
                <VendorSelectDate />
              </>
            }
          />
          <Route
            path="/VHome"
            exact
            element={
              <>
                {/* <Topheader /> */}
                <Vendorheader />
                <VHome />
                {/* <Footer /> */}
                <NewFooter />
              </>
            }
          />
          {/* <Route
            path="/Vendordashboard"
            exact
            element={
              <>
                <Vendordashboard />
                // <Footer />
   
   <NewFooter />             </>
            }
          /> */}
          <Route
            path="/Vendordashboard"
            element={
              <Vendordashbordmain
                children={
                  <>
                    <Vendordashboard />
                  </>
                }
              />
            }
          />
          <Route
            path="/Vendorprofile"
            element={
              <Vendordashbordmain
                children={
                  <>
                    <Vendorprofile />
                    {/* <Footer /> */}
                    <NewFooter />
                  </>
                }
              />
            }
          />
          <Route
            path="/Vendorservices"
            element={
              <Vendordashbordmain
                children={
                  <>
                    <Vendorservices />
                    {/* <Footer /> */}
                    <NewFooter />
                  </>
                }
              />
            }
          />
          <Route
            path="/VendorAccount"
            element={
              <Vendordashbordmain
                children={
                  <>
                    <VendorAccount />
                    {/* <Footer /> */}
                    <NewFooter />
                  </>
                }
              />
            }
          />
          <Route
            path="/VendorDaywiseAccountDetails"
            element={
              <Vendordashbordmain
                children={
                  <>
                    <VendorDaywiseAccountDetails />
                    {/* <Footer /> */}
                    <NewFooter />
                  </>
                }
              />
            }
          />
          <Route
            path="/vendorBooking"
            element={
              <Vendordashbordmain
                children={
                  <>
                    <VendorBooking />
                    {/* <Footer /> */}
                    <NewFooter />
                  </>
                }
              />
            }
          />
          <Route
            path="/VendorEmployee"
            element={
              <Vendordashbordmain
                children={
                  <>
                    <VendorEmployee />
                    {/* <Footer /> */}
                    <NewFooter />
                  </>
                }
              />
            }
          />
          <Route
            path="/VendorOffdutyTherapist"
            element={
              <Vendordashbordmain
                children={
                  <>
                    <VendorOffdutyTherapist />
                    {/* <Footer /> */}
                    <NewFooter />
                  </>
                }
              />
            }
          />
          {/* <Route
            path="/VendorAccount"
            exact
            element={
              <>
                <VendorAccount />
                // <Footer />
   
   <NewFooter />             </>
            }
          /> */}
          {/* <Route
            path="/vendorBooking"
            exact
            element={
              <>
                <VendorBooking />
                // <Footer />
   
   <NewFooter />             </>
            }
          />{" "} */}
          {/* <Route
            path="/VendorEmployee"
            exact
            element={
              <>
                <VendorEmployee />
                // <Footer />
   
   <NewFooter />             </>
            }
          /> */}
          {/* <Route
            path="/Vendorprofile"
            exact
            element={
              <>
                <Vendorprofile />
                // <Footer />
   
   <NewFooter />             </>
            }
          /> */}
          {/* <Route
            path="/Vendorservices"
            exact
            element={
              <>
                <Vendorservices />
                // <Footer />
   
   <NewFooter />             </>
            }
          /> */}
          <Route
            path="/myprofile"
            exact
            element={
              <>
                {/* <Topheader /> */}
                <Vendorheader />
                <Myprofile />
                {/* <Footer /> */}
                <NewFooter />
              </>
            }
          />
          <Route
            path="/updateprofile"
            exact
            element={
              <>
                {/* <Topheader /> */}
                <Vendorheader />
                <Updateprofile />
                {/* <Footer /> */}
                <NewFooter />
              </>
            }
          />
          <Route
            path="/updateprofile1"
            exact
            element={
              <>
                {/* <Topheader /> */}
                <Vendorheader />
                <Updateprofile1 />
                {/* <Footer /> */}
                <NewFooter />
              </>
            }
          />
          <Route
            path="/updateprofile2"
            exact
            element={
              <>
                {/* <Topheader /> */}
                <Vendorheader />
                <Updateprofile2 />
                {/* <Footer /> */}
                <NewFooter />
              </>
            }
          />
          <Route
            path="/updateprofile3"
            exact
            element={
              <>
                {/* <Topheader /> */}
                <Vendorheader />
                <Updateprofile3 />
                {/* <Footer /> */}
                <NewFooter />
              </>
            }
          />
          <Route
            path="/product"
            exact
            element={
              <>
                <Topheader />
                <Product />
                {/* <Footer /> */}
                <NewFooter />
              </>
            }
          />
          <Route
            path="/CategoryProduct"
            exact
            element={
              <>
                <Topheader />
                <CategoryProduct />
              </>
            }
          />
          <Route
            path="/budgetProduct"
            exact
            element={
              <>
                <Topheader />
                <BudgetProduct />
              </>
            }
          />
          {/* <Route
            path="/serviceProduct"
            exact
            element={
              <>
                <Topheader />
                <ServiceProduct />
              </>
            }
          /> */}
          <Route
            path="/product-details"
            exact
            element={
              <>
                <Topheader />
                <ProductDetails />
              </>
            }
          />
          <Route
            path="/all-images"
            exact
            element={
              <>
                <Allimages />
              </>
            }
          />
          <Route
            path="/login"
            exact
            element={
              <>
                <Login />
              </>
            }
          />
          <Route
            path="/register"
            exact
            element={
              <>
                <Register />
              </>
            }
          />
          <Route
            path="/business"
            exact
            element={
              <>
                <Business />
              </>
            }
          />
          <Route
            path="/order-summary"
            exact
            element={
              <>
                <Topheader />
                <CheckoutPage />
                {/* <Footer /> */}
                <NewFooter />
              </>
            }
          />
          <Route
            path="/contactUS"
            exact
            element={
              <>
                <ContactUS />
              </>
            }
          />
          {/* Admin */}
          <Route
            path="/admin"
            exact
            element={
              <>
                <AdminLogin />
              </>
            }
          />
          <Route
            path="/dashboard"
            element={
              <Dashboard
                children={
                  <>
                    <AdminDashboard />
                  </>
                }
              />
            }
          />
          <Route
            path="/OffdutyTherapist"
            element={
              <Dashboard
                children={
                  <>
                    <OffdutyTherapist />
                  </>
                }
              />
            }
          />
          <Route
            path="/vendor"
            element={
              <Dashboard
                children={
                  <>
                    <Vendor />
                  </>
                }
              />
            }
          />
          <Route
            path="/AdminEnterEmail"
            exact
            element={
              <>
                <AdminEnterEmail />
              </>
            }
          />
          <Route
            path="/AdminverifyOtp"
            exact
            element={
              <>
                <AdminverifyOtp />
              </>
            }
          />
          <Route
            path="/AdminChangepassword"
            exact
            element={
              <>
                <AdminChangepassword />
              </>
            }
          />
          <Route
            path="/SalonVendor"
            element={
              <Dashboard
                children={
                  <>
                    <SalonVendor />
                  </>
                }
              />
            }
          />
          <Route
            path="/Salonservice"
            element={
              <Dashboard
                children={
                  <>
                    <Salonservice />
                  </>
                }
              />
            }
          />
          <Route
            path="/AdminTherapist"
            element={
              <Dashboard
                children={
                  <>
                    <AdminTherapist />
                  </>
                }
              />
            }
          />

          <Route
            path="/DeleteTherapist"
            element={
              <Dashboard
                children={
                  <>
                    <DeleteTherapist />
                  </>
                }
              />
            }
          />
          <Route
            path="/SalonDeleteTherapist"
            element={
              <Dashboard
                children={
                  <>
                    <SalonDeleteTherapist />
                  </>
                }
              />
            }
          />

          <Route
            path="/SalonTherapist"
            element={
              <Dashboard
                children={
                  <>
                    <SalonTherapist />
                  </>
                }
              />
            }
          />
          <Route
            path="/SalonVendorP"
            element={
              <Dashboard
                children={
                  <>
                    <SalonVendorP />
                  </>
                }
              />
            }
          />
          <Route
            path="/SalonBooking"
            element={
              <Dashboard
                children={
                  <>
                    <SalonBooking />
                  </>
                }
              />
            }
          />
          <Route
            path="/Salonaccountdetails"
            element={
              <Dashboard
                children={
                  <>
                    <Salonaccountdetails />
                  </>
                }
              />
            }
          />
          <Route
            path="/SalonDaywiseAccountDetails"
            element={
              <Dashboard
                children={
                  <>
                    <SalonDaywiseAccountDetails />
                  </>
                }
              />
            }
          />
          <Route
            path="/SalonReview"
            element={
              <Dashboard
                children={
                  <>
                    <SalonReview />
                  </>
                }
              />
            }
          />
          <Route
            path="/editVendorRequest"
            element={
              <Dashboard
                children={
                  <>
                    <EditVendorRequest />
                  </>
                }
              />
            }
          />

          <Route
            path="/topbrands"
            element={
              <Dashboard
                children={
                  <>
                    <Topbrands />
                  </>
                }
              />
            }
          />

          <Route
            path="/StarHotels"
            element={
              <Dashboard
                children={
                  <>
                    <StarHotels />
                  </>
                }
              />
            }
          />
          <Route
            path="/MallandShopping"
            element={
              <Dashboard
                children={
                  <>
                    <MallandShopping />
                  </>
                }
              />
            }
          />
          <Route
            path="/SalonEditVendorRequest"
            element={
              <Dashboard
                children={
                  <>
                    <SalonEditVendorRequest />
                  </>
                }
              />
            }
          />

          <Route
            path="/banner"
            element={
              <Dashboard
                children={
                  <>
                    <Banner />
                  </>
                }
              />
            }
          />
          <Route
            path="/banner1"
            element={
              <Dashboard
                children={
                  <>
                    <Banner1 />
                  </>
                }
              />
            }
          />
          <Route
            path="/banner2"
            element={
              <Dashboard
                children={
                  <>
                    <Banner2 />
                  </>
                }
              />
            }
          />
          <Route
            path="/createSubadmin"
            element={
              <Dashboard
                children={
                  <>
                    <CreateSubadmin />
                  </>
                }
              />
            }
          />
          <Route
            path="/subadmindetails"
            element={
              <Dashboard
                children={
                  <>
                    <Subadmindetails />
                  </>
                }
              />
            }
          />
          <Route
            path="/category"
            element={
              <Dashboard
                children={
                  <>
                    <Category />
                  </>
                }
              />
            }
          />
          <Route
            path="/Promocode"
            element={
              <Dashboard
                children={
                  <>
                    <Promocode />
                  </>
                }
              />
            }
          />
          <Route
            path="/Adminservice"
            element={
              <Dashboard
                children={
                  <>
                    <Adminservice />
                  </>
                }
              />
            }
          />
          <Route
            path="/Advantages"
            element={
              <Dashboard
                children={
                  <>
                    <Advantages />
                  </>
                }
              />
            }
          />
          <Route
            path="/service"
            element={
              <Dashboard
                children={
                  <>
                    <Service />
                  </>
                }
              />
            }
          />
          <Route
            path="/Employee"
            element={
              <Dashboard
                children={
                  <>
                    <Employee />
                  </>
                }
              />
            }
          />
          <Route
            path="/reviews"
            element={
              <Dashboard
                children={
                  <>
                    <Reviews />
                  </>
                }
              />
            }
          />
          <Route
            path="/booking"
            element={
              <Dashboard
                children={
                  <>
                    <Booking />
                  </>
                }
              />
            }
          />
          <Route
            path="/AddvendorP"
            element={
              <Dashboard
                children={
                  <>
                    <AddvendorP />
                  </>
                }
              />
            }
          />
          <Route
            path="/AdminAccountdetails"
            element={
              <Dashboard
                children={
                  <>
                    <AdminAccountdetails />
                  </>
                }
              />
            }
          />
          <Route
            path="/AdminDaywiseAccountDetails"
            element={
              <Dashboard
                children={
                  <>
                    <AdminDaywiseAccountDetails />
                  </>
                }
              />
            }
          />
          <Route
            path="/customers"
            element={
              <Dashboard
                children={
                  <>
                    <Customers />
                  </>
                }
              />
            }
          />
          <Route
            path="/message"
            element={
              <Dashboard
                children={
                  <>
                    <Message />
                  </>
                }
              />
            }
          />
          <Route
            path="/massage"
            element={
              <Dashboard
                children={
                  <>
                    <Massage />
                  </>
                }
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
