import React, { useEffect, useState } from "react";
import "../Styles/product.css";
import Form from "react-bootstrap/Form";
import Spajson from "./Spa";
import { AiFillStar } from "react-icons/ai";
import axios from "axios";
import { Link } from "react-router-dom";

function Product() {
  document.title = "SPA-Product";
  const [category, setcategory] = useState([]);
  const [vendor, setvendor] = useState([]);

  const [categoryfilter, setcategoryfilter] = useState("");
  const [categoryfilterdata, setcategoryfilterdata] = useState([]);

  const [pricefilter, setpricefilter] = useState("");
  const [pricefilterdata, setpricefilterdata] = useState([]);

  // useEffect(() => {
  //   if (categoryfilter) {
  //     const filteredData = vendor.filter((person) =>
  //       person.service.some(
  //         (hobby) => hobby.Service_Category === categoryfilter
  //       )
  //     );
  //     setcategoryfilterdata(filteredData);
  //   }
  // }, [categoryfilter, pricefilter]);

  useEffect(() => {
    if (categoryfilter) {
      const filteredData = vendor.filter((person) =>
        person.service.some(
          (hobby) => hobby.Service_Category === categoryfilter
        )
      );
      setpricefilterdata(filteredData);
    }
  }, [categoryfilter]);

  useEffect(() => {
    getallCategory();
    getallVendor();
  }, []);

  const getallCategory = () => {
    axios
      .get("https://spazo.co.in/api/admin/getcategory")
      .then(function (response) {
        console.log(response.data);
        setcategory(response.data.category);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getallVendor = () => {
    axios
      .get("https://spazo.co.in/api/vendor/getVendorWithService")
      .then(function (response) {
        console.log(response.data);
        setvendor(
          response.data.vendor?.filter((item) => item.approved === "Approved")
        );
        setpricefilterdata(
          response.data.vendor?.filter((item) => item.approved === "Approved")
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log("categoryfilter", categoryfilter, vendor);
  console.log("pricefilter", pricefilter);
  console.log("categoryfilterdata", categoryfilterdata);
  console.log("pricefilterdata", pricefilterdata);
  return (
    <>
      <div
      // style={{ backgroundColor: "#ffda77" }}
      >
        <section>
          <div className="product-spa mx-5">
            <div className="container-fluid">
              <div className="left-right">
                <div className="left-0">
                  <div className="de-top">
                    <h4>Filter</h4>
                  </div>

                  <div className="categories">
                    {/* Location */}
                    <div className="spa-item">
                      <h6>Location</h6>
                    </div>
                    <div className="search-pro mb-3">
                      <input
                        type="text"
                        placeholder="Search for Location...."
                      />
                    </div>
                    {/* <ul className="p-0">
                      <div className="check-spa mb-2 ">
                        <div>
                          <input
                            id="cb1"
                            type="checkbox"
                            className="product-diff"
                          />
                          <label>Mg road</label>
                        </div>
                        <div>(11)</div>
                      </div>
                      <div className="check-spa mb-2 ">
                        <div>
                          <input
                            id="cb1"
                            type="checkbox"
                            className="product-diff"
                          />
                          <label>Jp Nagar</label>
                        </div>
                        <div>(11)</div>
                      </div>
                      <div className="check-spa mb-2 ">
                        <div>
                          <input
                            id="cb1"
                            type="checkbox"
                            className="product-diff"
                          />
                          <label>Whitefield</label>
                        </div>
                        <div>(11)</div>
                      </div>
                      <div className="check-spa mb-2 ">
                        <div>
                          <input
                            id="cb1"
                            type="checkbox"
                            className="product-diff"
                          />
                          <label>Hoodi</label>
                        </div>
                        <div>(11)</div>
                      </div>
                      <div className="check-spa mb-2 ">
                        <div>
                          <input
                            id="cb1"
                            type="checkbox"
                            className="product-diff"
                          />
                          <label>Electronic city</label>
                        </div>
                        <div>(11)</div>
                      </div>
                      <div className="check-spa mb-2 ">
                        <div>
                          <input
                            id="cb1"
                            type="checkbox"
                            className="product-diff"
                          />
                          <label>BEL</label>
                        </div>
                        <div>(11)</div>
                      </div>
                      <div className="check-spa mb-2 ">
                        <div>
                          <input
                            id="cb1"
                            type="checkbox"
                            className="product-diff"
                          />
                          <label>HRS Layout</label>
                        </div>
                        <div>(11)</div>
                      </div>
                    </ul> */}
                    <p>
                      <hr />
                    </p>
                    {/* Categories */}
                    <div className="spa-item">
                      <h6>Categories</h6>
                    </div>
                    <ul className="p-0 scrollhide">
                      {category?.map((cat) => (
                        <li onClick={() => setcategoryfilter(cat?.catname)}>
                          {cat?.catname}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="right-0" style={{ width: "75%" }}>
                  <div className="spa-item">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setpricefilter(e.target.value)}
                      style={{ width: "150px" }}
                    >
                      <option value="">Filter as per price</option>
                      <option value="High to Low">High to Low</option>
                      <option value="Low to High">Low to High</option>
                    </Form.Select>
                  </div>
                  <div className="different">
                    {/* <div className="row">
                      {product?.map((item, index) => {
                        return (
                          <div className="col-md-4">
                            <div class="item mt-3 mb-3">
                              <div className="massage-0">
                                <a href="/product-details">
                                  <div className="customer">
                                    <img src={item.image} alt="spa-images" />
                                    <div className="cust-ti pt-2 product-90">
                                      <h6>{item.title}</h6>
                                      <div className="location">
                                        {" "}
                                        {item.location}
                                      </div>
                                      <div className="massage-type">
                                        {item.vendor}
                                      </div>
                                      <div
                                        className="rating"
                                        style={{ color: "#FF9529" }}
                                      >
                                        <span>
                                          <AiFillStar />
                                        </span>
                                        <span>
                                          <AiFillStar />
                                        </span>
                                        <span>
                                          <AiFillStar />
                                        </span>
                                        <span>
                                          <AiFillStar />
                                        </span>
                                        <span>
                                          <AiFillStar />
                                        </span>
                                      </div>
                                      <div className="tit-p">
                                        <div className="price">
                                          <div>
                                            <s>₹{item.price}</s>
                                          </div>
                                          <div className="discount-price">
                                            ₹{item.discountprice}
                                          </div>
                                        </div>
                                        <div className="discount">
                                          {item.discount} off
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div> */}

                    <div className="row">
                      {pricefilter == "High to Low"
                        ? pricefilterdata
                            ?.sort((a, b) =>
                              a.basicprice -
                                (a.basicprice * a.mindiscount) / 100 <
                              b.basicprice -
                                (b.basicprice * b.mindiscount) / 100
                                ? 1
                                : a.basicprice -
                                    (a.basicprice * a.mindiscount) / 100 >
                                  b.basicprice -
                                    (b.basicprice * b.mindiscount) / 100
                                ? -1
                                : 0
                            )
                            .map((item) => {
                              return (
                                <div className="col-md-4">
                                  <div class="item mt-3 mb-3">
                                    <div className="massage-0">
                                      <Link
                                        to="/product-details"
                                        state={{ dis: item }}
                                      >
                                        <div className="customer">
                                          <img
                                            src={
                                              "https://spazo.co.in/Vendor/" +
                                              item.spaimage1
                                            }
                                            alt="spa-images"
                                            // height="185px"
                                            // style={{ height: "185px" }}
                                          />
                                          <div className="cust-ti pt-2 product-90">
                                            <h6>{item.businessName}</h6>
                                            <div className="location">
                                              {item.address}
                                            </div>
                                            {/* <div className="massage-type">
                                        {item.vendor}
                                      </div> */}
                                            <div
                                              className="rating"
                                              style={{ color: "#FF9529" }}
                                            >
                                              <span>
                                                <AiFillStar />
                                              </span>
                                              <span>
                                                <AiFillStar />
                                              </span>
                                              <span>
                                                <AiFillStar />
                                              </span>
                                              <span>
                                                <AiFillStar />
                                              </span>
                                              <span>
                                                <AiFillStar />
                                              </span>
                                            </div>
                                            <div className="tit-p">
                                              <div className="price">
                                                <div>
                                                  <s>₹{item.basicprice}</s>
                                                </div>
                                                <div className="discount-price">
                                                  ₹
                                                  {item.basicprice -
                                                    (item.basicprice *
                                                      item.mindiscount) /
                                                      100}
                                                </div>
                                              </div>
                                              <div className="discount">
                                                {item.mindiscount} % off
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                        : pricefilter == "Low to High"
                        ? pricefilterdata
                            ?.sort((a, b) =>
                              a.basicprice -
                                (a.basicprice * a.mindiscount) / 100 >
                              b.basicprice -
                                (b.basicprice * b.mindiscount) / 100
                                ? 1
                                : a.basicprice -
                                    (a.basicprice * a.mindiscount) / 100 <
                                  b.basicprice -
                                    (b.basicprice * b.mindiscount) / 100
                                ? -1
                                : 0
                            )
                            .map((item) => {
                              return (
                                <div className="col-md-4">
                                  <div class="item mt-3 mb-3">
                                    <div className="massage-0">
                                      <Link
                                        to="/product-details"
                                        state={{ dis: item }}
                                      >
                                        <div className="customer">
                                          <img
                                            src={
                                              "https://spazo.co.in/Vendor/" +
                                              item.spaimage1
                                            }
                                            alt="spa-images"
                                            // height="185px"
                                            // style={{ height: "185px" }}
                                          />
                                          <div className="cust-ti pt-2 product-90">
                                            <h6>{item.businessName}</h6>
                                            <div className="location">
                                              {item.address}
                                            </div>
                                            {/* <div className="massage-type">
                                        {item.vendor}
                                      </div> */}
                                            <div
                                              className="rating"
                                              style={{ color: "#FF9529" }}
                                            >
                                              <span>
                                                <AiFillStar />
                                              </span>
                                              <span>
                                                <AiFillStar />
                                              </span>
                                              <span>
                                                <AiFillStar />
                                              </span>
                                              <span>
                                                <AiFillStar />
                                              </span>
                                              <span>
                                                <AiFillStar />
                                              </span>
                                            </div>
                                            <div className="tit-p">
                                              <div className="price">
                                                <div>
                                                  <s>₹{item.basicprice}</s>
                                                </div>
                                                <div className="discount-price">
                                                  ₹
                                                  {item.basicprice -
                                                    (item.basicprice *
                                                      item.mindiscount) /
                                                      100}
                                                </div>
                                              </div>
                                              <div className="discount">
                                                {item.mindiscount} % off
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                        : pricefilterdata?.map((item) => {
                            return (
                              <div className="col-md-4">
                                <div class="item mt-3 mb-3">
                                  <div className="massage-0">
                                    <Link
                                      to="/product-details"
                                      state={{ dis: item }}
                                    >
                                      <div className="customer">
                                        <img
                                          src={
                                            "https://spazo.co.in/Vendor/" +
                                            item.spaimage1
                                          }
                                          alt="spa-images"
                                          // height="185px"
                                          // style={{ height: "185px" }}
                                        />
                                        <div className="cust-ti pt-2 product-90">
                                          <h6>{item.businessName}</h6>
                                          <div className="location">
                                            {item.address}
                                          </div>
                                          {/* <div className="massage-type">
                                      {item.vendor}
                                    </div> */}
                                          <div
                                            className="rating"
                                            style={{ color: "#FF9529" }}
                                          >
                                            <span>
                                              <AiFillStar />
                                            </span>
                                            <span>
                                              <AiFillStar />
                                            </span>
                                            <span>
                                              <AiFillStar />
                                            </span>
                                            <span>
                                              <AiFillStar />
                                            </span>
                                            <span>
                                              <AiFillStar />
                                            </span>
                                          </div>
                                          <div className="tit-p">
                                            <div className="price">
                                              <div>
                                                <s>₹{item.basicprice}</s>
                                              </div>
                                              <div className="discount-price">
                                                ₹
                                                {item.basicprice -
                                                  (item.basicprice *
                                                    item.mindiscount) /
                                                    100}
                                              </div>
                                            </div>
                                            <div className="discount">
                                              {item.mindiscount} % off
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Product;
