import React from "react";
import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { AiFillPhone, AiTwotoneMail } from "react-icons/ai";
import { BiCurrentLocation } from "react-icons/bi";
import {
  IoBusiness,
  IoLocationSharp,
  IoPersonCircleSharp,
} from "react-icons/io5";
import { RiUserLocationFill } from "react-icons/ri";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Updateprofile3() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const Input = styled("input")({
    display: "none",
  });

  const user = JSON.parse(sessionStorage.getItem("user"));
  const formdata = new FormData();

  const [image1, setimage1] = useState("");
  const [image2, setimage2] = useState("");
  const [image3, setimage3] = useState("");
  const [image4, setimage4] = useState("");
  const [AdditionalImage, setAdditionalImage] = useState("");

  const [array1, setarray1] = useState([]);
  const [AdditionalImageArray, setAdditionalImageArray] = useState([]);

  const addAdditionalImage = () => {
    if (!AdditionalImage) {
      notify2("Please Upload Image");
    } else {
      let obj = {
        AdditionalImage: AdditionalImage,
      };
      array1.push(obj);
      setAdditionalImageArray(array1);
      setAdditionalImage("");
    }
  };

  let form2 = new FormData();
  const update4 = async () => {
    formdata.append("userId", user._id);
    formdata.append("spaimage1", image1);
    formdata.append("spaimage2", image2);
    formdata.append("spaimage3", image3);
    formdata.append("spaimage4", image4);
    AdditionalImageArray.forEach(async (ImageObj, index) => {
      form2.append(`id`, user._id);
      form2.append(`AdditionalImage`, ImageObj.AdditionalImage);
      const config = {
        url: "/uploadAdditionalImage",
        method: "post",
        baseURL: "https://spazo.co.in/api/vendor",
        data: form2,
      };
      await axios(config);
    });

    if (!image1 || !image2 || !image3 || !image4) {
      notify2("Please upload all 4 Images");
    } else {
      try {
        const config = {
          url: "/vendorupdate4",
          method: "post",
          baseURL: "https://spazo.co.in/api/vendor",
          data: formdata,
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            // alert("Data Added successfully");
            notify("Data Added successfully");
            sessionStorage.setItem("user", JSON.stringify(res.data.user));
            window.location.assign("Vendordashboard");
          } else {
            // alert(res.status.error);
            notify1(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        // alert("Unable to add Details");
        notify1("Unable to add Details, Please try after some time");
      }
    }
  };

  console.log("image1", image1, image2, image3, image4);
  return (
    <>
      <div
        style={{
          paddingTop: "8%",
          paddingBottom: "8%",
        }}
      >
        <ToastContainer
          position="top-center"
          autoClose={30000}
          closeOnClick
          pauseOnHover
          draggable
        />
        <div className="admin-name-text">
          <h4 style={{ textDecoration: "underline", textAlign: "center" }}>
            Update Your Profile Details
          </h4>
        </div>
        <div className="mt-4 updateprofile1">
          <h6>
            Upload{" "}
            {user?.type === "Spa"
              ? "Spa"
              : user?.type === "Salon"
              ? "Salon"
              : "Beauty Care Products"}{" "}
            Images
          </h6>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <label htmlFor="image1">
                <Input
                  accept="image/*"
                  id="image1"
                  type="file"
                  onChange={(e) => setimage1(e.target.files[0])}
                />
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <img
                    src={
                      image1
                        ? URL.createObjectURL(image1)
                        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQueVhIdH7EKL6sLlHuCK4etHw1nctOIza_Zg&usqp=CAU"
                    }
                    alt="avathar"
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50px",
                    }}
                  />
                  <PhotoCamera
                    style={{
                      color: "black",
                      margin: "40px 0px 0px -20px",
                    }}
                  />
                </IconButton>
              </label>
              <p style={{ textAlign: "center" }}>Road side View</p>
            </div>
            <div>
              <label htmlFor="image2">
                <Input
                  accept="image/*"
                  id="image2"
                  type="file"
                  onChange={(e) => setimage2(e.target.files[0])}
                />
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <img
                    src={
                      image2
                        ? URL.createObjectURL(image2)
                        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQueVhIdH7EKL6sLlHuCK4etHw1nctOIza_Zg&usqp=CAU"
                    }
                    alt="avathar"
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50px",
                    }}
                  />
                  <PhotoCamera
                    style={{
                      color: "black",
                      margin: "40px 0px 0px -20px",
                    }}
                  />
                </IconButton>
              </label>
              <p style={{ textAlign: "center" }}>Lobby Reception Desk View</p>
            </div>
            <div>
              <label htmlFor="image3">
                <Input
                  accept="image/*"
                  id="image3"
                  type="file"
                  onChange={(e) => setimage3(e.target.files[0])}
                />
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <img
                    src={
                      image3
                        ? URL.createObjectURL(image3)
                        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQueVhIdH7EKL6sLlHuCK4etHw1nctOIza_Zg&usqp=CAU"
                    }
                    alt="avathar"
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50px",
                    }}
                  />
                  <PhotoCamera
                    style={{
                      color: "black",
                      margin: "40px 0px 0px -20px",
                    }}
                  />
                </IconButton>
              </label>
              <p style={{ textAlign: "center" }}>Corner View</p>
            </div>
            <div>
              <label htmlFor="image4">
                <Input
                  accept="image/*"
                  id="image4"
                  type="file"
                  onChange={(e) => setimage4(e.target.files[0])}
                />
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <img
                    src={
                      image4
                        ? URL.createObjectURL(image4)
                        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQueVhIdH7EKL6sLlHuCK4etHw1nctOIza_Zg&usqp=CAU"
                    }
                    alt="avathar"
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50px",
                    }}
                  />
                  <PhotoCamera
                    style={{
                      color: "black",
                      margin: "40px 0px 0px -20px",
                    }}
                  />
                </IconButton>
              </label>
              <p style={{ textAlign: "center" }}>Therapy Room View</p>
            </div>
          </div>

          <div>
            <div>
              {AdditionalImageArray?.map((item) => (
                <>
                  <img
                    src={URL.createObjectURL(item.AdditionalImage)}
                    alt="avathar"
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50px",
                    }}
                  />
                </>
              ))}
              <br></br>
              <div style={{ display: "flex", gap: "10px" }}>
                <div>
                  <label htmlFor="icon-button-file1">
                    <Input
                      accept="image/*"
                      id="icon-button-file1"
                      type="file"
                      onChange={(e) => setAdditionalImage(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          AdditionalImage
                            ? URL.createObjectURL(AdditionalImage)
                            : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQueVhIdH7EKL6sLlHuCK4etHw1nctOIza_Zg&usqp=CAU"
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                  <p>Additional Images</p>
                </div>
              </div>

              <button
                style={{
                  border: "none",
                  padding: "0px 18px 8px 18px",
                  backgroundColor: "#e22217",
                  color: "white",
                  marginLeft: "30px",
                  borderRadius: "50%",
                  fontSize: "30px",
                  textAlign: "center",
                }}
                onClick={addAdditionalImage}
              >
                +
              </button>
            </div>
          </div>
          <Button
            variant="secondary"
            style={{
              margin: "20px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            onClick={update4}
          >
            <i class="fas fa-save"></i>&nbsp;Save
          </Button>
        </div>
      </div>
    </>
  );
}

export default Updateprofile3;
