import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

function Homescreen() {
  return (
    <Row>
      {/* <Col md={6} style={{ backgroundColor: "#ffda77" }}> */}
      <Col md={6} style={{ backgroundColor: "white" }}>
        <img
          src="./logo.jpg"
          alt="spa"
          style={{ width: "122px", height: "60px", margin: "10px" }}
        />
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            padding: "29% 0px",
          }}
        >
          <i
            class="fas fa-store-alt"
            style={{
              color: "#e22217",
              fontSize: "50px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          ></i>
          <p
            style={{
              color: "#e22217",
              textAlign: "center",
              fontSize: "20px",
              marginBottom: "0px",
            }}
          >
            Vendor
          </p>
          <p
            style={{ color: "#e22217", textAlign: "center", fontSize: "15px" }}
          >
            To make Business With SpazO
          </p>

          <button
            style={{
              backgroundColor: "#e22217",
              color: "white",
              border: "none",
              padding: "10px 20px",
              marginTop: "30px",
            }}
            onClick={() => window.location.assign("/business")}
          >
            Get Started
          </button>
        </div>
      </Col>
      <Col md={6} style={{ backgroundColor: "#e22217" }}>
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            padding: "35% 0px",
          }}
        >
          <i
            class="fas fa-users"
            style={{
              color: "white",
              fontSize: "50px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            size={100}
          ></i>
          <p
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "20px",
              marginBottom: "0px",
            }}
          >
            User
          </p>
          <p style={{ color: "white", textAlign: "center", fontSize: "15px" }}>
            Booking Services through SpazO
          </p>
          <button
            style={{
              backgroundColor: "white",
              color: "#e22217",
              border: "none",
              padding: "10px 20px",
              marginTop: "30px",
            }}
            onClick={() => window.location.assign("/login")}
          >
            Get Started
          </button>
        </div>
      </Col>
    </Row>
  );
}

export default Homescreen;
