import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { AiFillPhone, AiTwotoneMail } from "react-icons/ai";
import { BiCurrentLocation } from "react-icons/bi";
import {
  IoBusiness,
  IoLocationSharp,
  IoPersonCircleSharp,
} from "react-icons/io5";
import { RiUserLocationFill } from "react-icons/ri";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Updateprofile() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);
  const Input = styled("input")({
    display: "none",
  });

  const user = JSON.parse(sessionStorage.getItem("user"));
  const formdata = new FormData();

  const [shoptype, setshoptype] = useState("");
  const [type, settype] = useState("");

  const [adharF1, setadharF1] = useState("");
  const [adharB1, setadharB1] = useState("");

  const [adharF2, setadharF2] = useState("");
  const [adharB2, setadharB2] = useState("");

  const [adharF3, setadharF3] = useState("");
  const [adharB3, setadharB3] = useState("");

  const [adharF4, setadharF4] = useState("");
  const [adharB4, setadharB4] = useState("");

  const [uploadPan, setuploadPan] = useState(false);
  const [uploadpassbook, setuploadpassbook] = useState(false);
  const [uploadgst, setuploadgst] = useState(false);
  const [uploadspa, setuploadspa] = useState(false);
  const [uploadanyother, setuploadanyother] = useState(false);

  const [Pan, setPan] = useState("");
  const [passbook, setpassbook] = useState("");
  const [gst, setgst] = useState("");
  const [spa, setspa] = useState("");
  const [other, setother] = useState("");

  const [array1, setarray1] = useState([]);
  const [Adhar, setAdhar] = useState([]);

  const addAdhar = () => {
    if (!adharF1 || !adharB1) {
      notify2("Please Upload Both the sides");
      // alert("Please Upload Both the sides");
    } else {
      let obj = {
        adharF1: adharF1,
        adharB1: adharB1,
      };
      array1.push(obj);
      setAdhar(array1);
      setadharF1("");
      setadharB1("");
    }
  };

  useEffect(() => {
    setAdhar([]);
  }, [shoptype]);

  console.log("Adhar", Adhar);
  let form2 = new FormData();
  const update1 = async () => {
    formdata.append("userId", user._id);
    formdata.append("shoptype", shoptype);
    formdata.append("type", type);
    Adhar.forEach(async (adharObj, index) => {
      form2.append(`id`, user._id);
      form2.append(`adharF1`, adharObj.adharF1);
      form2.append(`adharB1`, adharObj.adharB1);
      const config = {
        url: "/uploadAdharCard",
        method: "post",
        baseURL: "https://spazo.co.in/api/vendor",
        data: form2,
      };
      await axios(config);
    });
    // formdata.append("adharF1", adharF1);
    // formdata.append("adharB1", adharB1);
    // formdata.append("adharF2", adharF2);
    // formdata.append("adharB2", adharB2);
    // formdata.append("adharF3", adharF3);
    // formdata.append("adharB3", adharB3);
    // formdata.append("adharF4", adharF4);
    // formdata.append("adharB4", adharB4);
    formdata.append("Pan", Pan);
    formdata.append("passbook", passbook);
    formdata.append("gst", gst);
    formdata.append("spa", spa);
    formdata.append("other", other);
    if (!shoptype) {
      // alert("Please upload Pan Card");
      notify2("Please Select Shop type");
    } else if (!type) {
      // alert("Please upload Pan Card");
      notify2("Please Select Category type");
    } else if (!Pan) {
      // alert("Please upload Pan Card");
      notify2("Please upload Pan Card");
    } else if (!passbook) {
      // alert("Please Upload your Passbook");
      notify2("Please Upload your Passbook");
    } else if (!gst) {
      // alert("Please Upload your Passbook");
      notify2("Please Upload your GST Certificate");
    } else if (!spa) {
      // alert("Please Upload your Passbook");
      notify2("Please Upload your Spa Certificate");
    } else if (shoptype === "Pvt Ltd" || shoptype === "Partner Ship") {
      if (Adhar?.length < 2) {
        // alert("Please upload min 2 pairs of Aadhaar card");
        notify2("Please upload min 2 Directory of Aadhaar card");
      } else {
        try {
          const config = {
            url: "/vendorupdate1",
            method: "post",
            baseURL: "https://spazo.co.in/api/vendor",
            data: formdata,
          };
          await axios(config).then(function (res) {
            if ((res.status = 200)) {
              // alert("Data Added successfully");
              notify("Data Added successfully");
              sessionStorage.setItem("user", JSON.stringify(res.data.user));
              window.location.assign("updateprofile1");
            } else {
              // alert(res.status.error);
              notify1(res.status.error);
            }
          });
        } catch (error) {
          console.log(error);
          // alert("Unable to add Details");
          notify1("Unable to add Details, Please try after some time");
        }
      }
    } else if (shoptype === "Properiter Ship") {
      if (Adhar?.length < 1) {
        // alert("Please upload min 1 pairs of Aadhaar card");
        notify2("Please upload min 1 Directory of Aadhaar card");
      } else {
        try {
          const config = {
            url: "/vendorupdate1",
            method: "post",
            baseURL: "https://spazo.co.in/api/vendor",
            data: formdata,
          };
          await axios(config).then(function (res) {
            if ((res.status = 200)) {
              // alert("Data Added successfully");
              notify("Data Added successfully");
              sessionStorage.setItem("user", JSON.stringify(res.data.user));
              window.location.assign("updateprofile1");
            } else {
              // alert(res.status.error);
              notify1(res.status.error);
            }
          });
        } catch (error) {
          console.log(error);
          // alert("Unable to add Details");
          notify1("Unable to add Details, Please try after some time");
        }
      }
    }
  };

  console.log("shoptype", user);

  return (
    <>
      <div
        style={{
          // backgroundColor: "#ffda77",
          // height: "100vh",
          paddingTop: "8%",
          paddingBottom: "8%",
        }}
      >
        <ToastContainer
          position="top-center"
          autoClose={30000}
          closeOnClick
          pauseOnHover
          draggable
        />
        <div className="admin-name-text">
          <h4 style={{ textDecoration: "underline", textAlign: "center" }}>
            Update Your Profile Details
          </h4>
        </div>
        <div className="mt-4 updateprofile">
          <div style={{ margin: "5%" }}>
            <Row>
              <Col md={4}>
                Select Shop Type <span style={{ color: "red" }}>*</span>
              </Col>
              <Col md={8}>
                <select
                  onChange={(e) => setshoptype(e.target.value)}
                  className="upselect"
                >
                  <option>Select</option>
                  <option value="Pvt Ltd">Pvt Ltd</option>
                  <option value="Partner Ship">Partner Ship</option>
                  <option value="Properiter Ship">Properiter Ship</option>
                </select>
              </Col>
            </Row>

            {shoptype === "Pvt Ltd" ? (
              <>
                <div>
                  <h5>
                    Upload your Aadhaar card (Min 2 Directory)
                    <span style={{ color: "red" }}>*</span>
                  </h5>
                  <div
                  // className="adhharr"
                  // style={{ justifyContent: "space-between" }}
                  >
                    {Adhar?.map((item) => (
                      <>
                        <img
                          src={URL.createObjectURL(item.adharF1)}
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <img
                          src={URL.createObjectURL(item.adharB1)}
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                            marginRight: "20px",
                          }}
                        />
                      </>
                    ))}
                    <br></br>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <div>
                        <label htmlFor="icon-button-file1">
                          <Input
                            accept="image/*"
                            id="icon-button-file1"
                            type="file"
                            onChange={(e) => setadharF1(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                adharF1
                                  ? URL.createObjectURL(adharF1)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                                //  `https://otutee.in/${

                                //   }`
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>AadhaarFront1</p>
                      </div>
                      <div>
                        <label htmlFor="icon-button-file2">
                          <Input
                            accept="image/*"
                            id="icon-button-file2"
                            type="file"
                            onChange={(e) => setadharB1(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                adharB1
                                  ? URL.createObjectURL(adharB1)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>AadhaarBack1</p>
                      </div>
                    </div>

                    <button
                      style={{
                        border: "none",
                        padding: "0px 18px 8px 18px",
                        // height: "30px",
                        backgroundColor: "#e22217",
                        color: "white",
                        // marginTop: "10px",
                        marginLeft: "30px",
                        borderRadius: "50%",
                        fontSize: "30px",
                        textAlign: "center",
                      }}
                      onClick={addAdhar}
                    >
                      +
                    </button>
                    {/* <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <label htmlFor="icon-button-file3">
                        <Input
                          accept="image/*"
                          id="icon-button-file3"
                          type="file"
                          onChange={(e) => setadharF2(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharF2
                                ? URL.createObjectURL(adharF2)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharFront2</p>
                    </div>
                    <div>
                      <label htmlFor="icon-button-file4">
                        <Input
                          accept="image/*"
                          id="icon-button-file4"
                          type="file"
                          onChange={(e) => setadharB2(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharB2
                                ? URL.createObjectURL(adharB2)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharBack2</p>
                    </div>
                  </div>

                  <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <label htmlFor="icon-button-file5">
                        <Input
                          accept="image/*"
                          id="icon-button-file5"
                          type="file"
                          onChange={(e) => setadharF3(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharF3
                                ? URL.createObjectURL(adharF3)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharFront3</p>
                    </div>
                    <div>
                      <label htmlFor="icon-button-file6">
                        <Input
                          accept="image/*"
                          id="icon-button-file6"
                          type="file"
                          onChange={(e) => setadharB3(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharB3
                                ? URL.createObjectURL(adharB3)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharBack3</p>
                    </div>
                  </div> */}

                    {/* <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <label htmlFor="icon-button-file7">
                        <Input
                          accept="image/*"
                          id="icon-button-file7"
                          type="file"
                          onChange={(e) => setadharF4(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharF4
                                ? URL.createObjectURL(adharF4)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharFront4</p>
                    </div>
                    <div>
                      <label htmlFor="icon-button-file8">
                        <Input
                          accept="image/*"
                          id="icon-button-file8"
                          type="file"
                          onChange={(e) => setadharB4(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharB4
                                ? URL.createObjectURL(adharB4)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharBack4</p>
                    </div>
                  </div> */}
                  </div>
                </div>
              </>
            ) : shoptype === "Partner Ship" ? (
              <>
                {/* <div>
                <h6>Upload your Adhar card</h6>
                <div
                  className="adhharr"
                  style={{ justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <label htmlFor="icon-button-file1">
                        <Input
                          accept="image/*"
                          id="icon-button-file1"
                          type="file"
                          onChange={(e) => setadharF1(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharF1
                                ? URL.createObjectURL(adharF1)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AadharFront1</p>
                    </div>
                    <div>
                      <label htmlFor="icon-button-file2">
                        <Input
                          accept="image/*"
                          id="icon-button-file2"
                          type="file"
                          onChange={(e) => setadharB1(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharB1
                                ? URL.createObjectURL(adharB1)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharBack1</p>
                    </div>
                  </div>

                  <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <label htmlFor="icon-button-file3">
                        <Input
                          accept="image/*"
                          id="icon-button-file3"
                          type="file"
                          onChange={(e) => setadharF2(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharF2
                                ? URL.createObjectURL(adharF2)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharFront2</p>
                    </div>
                    <div>
                      <label htmlFor="icon-button-file4">
                        <Input
                          accept="image/*"
                          id="icon-button-file4"
                          type="file"
                          onChange={(e) => setadharB2(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharB2
                                ? URL.createObjectURL(adharB2)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharBack2</p>
                    </div>
                  </div>

                  <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <label htmlFor="icon-button-file5">
                        <Input
                          accept="image/*"
                          id="icon-button-file5"
                          type="file"
                          onChange={(e) => setadharF3(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharF3
                                ? URL.createObjectURL(adharF3)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharFront3</p>
                    </div>
                    <div>
                      <label htmlFor="icon-button-file6">
                        <Input
                          accept="image/*"
                          id="icon-button-file6"
                          type="file"
                          onChange={(e) => setadharB3(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharB3
                                ? URL.createObjectURL(adharB3)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharBack3</p>
                    </div>
                  </div>
                </div>
              </div> */}
                <div>
                  <h5>
                    Upload your Aadhaar card (Min 2 Directory)
                    <span style={{ color: "red" }}>*</span>
                  </h5>
                  <div
                  // className="adhharr"
                  // style={{ justifyContent: "space-between" }}
                  >
                    {Adhar?.map((item) => (
                      <>
                        <img
                          src={URL.createObjectURL(item.adharF1)}
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <img
                          src={URL.createObjectURL(item.adharB1)}
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                            marginRight: "20px",
                          }}
                        />
                      </>
                    ))}
                    <br></br>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <div>
                        <label htmlFor="icon-button-file1">
                          <Input
                            accept="image/*"
                            id="icon-button-file1"
                            type="file"
                            onChange={(e) => setadharF1(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                adharF1
                                  ? URL.createObjectURL(adharF1)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                                //  `https://otutee.in/${

                                //   }`
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>AadhaarFront1</p>
                      </div>
                      <div>
                        <label htmlFor="icon-button-file2">
                          <Input
                            accept="image/*"
                            id="icon-button-file2"
                            type="file"
                            onChange={(e) => setadharB1(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                adharB1
                                  ? URL.createObjectURL(adharB1)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>AadhaarBack1</p>
                      </div>
                    </div>

                    <button
                      style={{
                        border: "none",
                        padding: "0px 18px 8px 18px",
                        // height: "30px",
                        backgroundColor: "#e22217",
                        color: "white",
                        // marginTop: "10px",
                        marginLeft: "30px",
                        borderRadius: "50%",
                        fontSize: "30px",
                        textAlign: "center",
                      }}
                      onClick={addAdhar}
                    >
                      +
                    </button>
                  </div>
                </div>
              </>
            ) : shoptype === "Properiter Ship" ? (
              <>
                {/* <div>
                <h6>Upload your Adhar card</h6>
                <div className="adhharr">
                  <div style={{ display: "flex", gap: "10px", margin: "20px" }}>
                    <div>
                      <label htmlFor="icon-button-file1">
                        <Input
                          accept="image/*"
                          id="icon-button-file1"
                          type="file"
                          onChange={(e) => setadharF1(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharF1
                                ? URL.createObjectURL(adharF1)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AadharFront1</p>
                    </div>
                    <div>
                      <label htmlFor="icon-button-file2">
                        <Input
                          accept="image/*"
                          id="icon-button-file2"
                          type="file"
                          onChange={(e) => setadharB1(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharB1
                                ? URL.createObjectURL(adharB1)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharBack1</p>
                    </div>
                  </div>

                  <div style={{ display: "flex", gap: "10px", margin: "20px" }}>
                    <div>
                      <label htmlFor="icon-button-file3">
                        <Input
                          accept="image/*"
                          id="icon-button-file3"
                          type="file"
                          onChange={(e) => setadharF2(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharF2
                                ? URL.createObjectURL(adharF2)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharFront2</p>
                    </div>
                    <div>
                      <label htmlFor="icon-button-file4">
                        <Input
                          accept="image/*"
                          id="icon-button-file4"
                          type="file"
                          onChange={(e) => setadharB2(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharB2
                                ? URL.createObjectURL(adharB2)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharBack2</p>
                    </div>
                  </div>
                </div>
              </div> */}
                <div>
                  <h5>
                    Upload your Aadhaar card (Min 1 Directory){" "}
                    <span style={{ color: "red" }}>*</span>
                  </h5>
                  <div
                  // className="adhharr"
                  // style={{ justifyContent: "space-between" }}
                  >
                    {Adhar?.map((item) => (
                      <>
                        <img
                          src={URL.createObjectURL(item.adharF1)}
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <img
                          src={URL.createObjectURL(item.adharB1)}
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                            marginRight: "20px",
                          }}
                        />
                      </>
                    ))}
                    <br></br>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <div>
                        <label htmlFor="icon-button-file1">
                          <Input
                            accept="image/*"
                            id="icon-button-file1"
                            type="file"
                            onChange={(e) => setadharF1(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                adharF1
                                  ? URL.createObjectURL(adharF1)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                                //  `https://otutee.in/${

                                //   }`
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>AadhaarFront1</p>
                      </div>
                      <div>
                        <label htmlFor="icon-button-file2">
                          <Input
                            accept="image/*"
                            id="icon-button-file2"
                            type="file"
                            onChange={(e) => setadharB1(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                adharB1
                                  ? URL.createObjectURL(adharB1)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>AadhaarBack1</p>
                      </div>
                    </div>

                    <button
                      style={{
                        border: "none",
                        padding: "0px 18px 8px 18px",
                        // height: "30px",
                        backgroundColor: "#e22217",
                        color: "white",
                        // marginTop: "10px",
                        marginLeft: "30px",
                        borderRadius: "50%",
                        fontSize: "30px",
                        textAlign: "center",
                      }}
                      onClick={addAdhar}
                    >
                      +
                    </button>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <Row className="mt-3">
              <Col md={4}>
                Select Shop Category <span style={{ color: "red" }}>*</span>
              </Col>
              <Col md={8}>
                <select
                  onChange={(e) => settype(e.target.value)}
                  className="upselect"
                >
                  <option>Select</option>
                  <option value="Spa">Spa</option>
                  <option value="Salon">Salon</option>
                  <option value="Beauty Care Products">
                    Beauty Care Products
                  </option>
                </select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>
                Upload Pan Card <span style={{ color: "red" }}>*</span>
              </Col>
              <Col md={8}>
                {uploadPan ? (
                  <div>
                    <div className="adhharr">
                      <div
                        style={{ display: "flex", gap: "10px", margin: "20px" }}
                      >
                        <div>
                          <label htmlFor="pan">
                            <Input
                              accept="image/*"
                              id="pan"
                              type="file"
                              onChange={(e) => setPan(e.target.files[0])}
                            />
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              <img
                                src={
                                  Pan
                                    ? URL.createObjectURL(Pan)
                                    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuLZEq581LWPeg56csL0uAsZmXRs6fKolb3Q&usqp=CAU"
                                }
                                alt="avathar"
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  borderRadius: "50px",
                                }}
                              />
                              <PhotoCamera
                                style={{
                                  color: "black",
                                  margin: "40px 0px 0px -20px",
                                }}
                              />
                            </IconButton>
                          </label>
                          <p>PanCard</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <button className="upbtn" onClick={() => setuploadPan(true)}>
                    Upload Pan
                  </button>
                )}
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={4}>
                Upload Bank Passbook <span style={{ color: "red" }}>*</span>
              </Col>
              <Col md={8}>
                {uploadpassbook ? (
                  <div>
                    <div className="adhharr">
                      <div>
                        <label htmlFor="passbook">
                          <Input
                            accept="image/*"
                            id="passbook"
                            type="file"
                            onChange={(e) => setpassbook(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                passbook
                                  ? URL.createObjectURL(passbook)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3iq7tNoElQXMSN8BqkvZIusXruO2DDWx5QQ&usqp=CAU"
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>Bank Passbook</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <button
                    className="upbtn"
                    onClick={() => setuploadpassbook(true)}
                  >
                    Upload Bank Passbook
                  </button>
                )}
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={4}>
                Upload GSTCertificate <span style={{ color: "red" }}>*</span>
              </Col>
              <Col md={8}>
                {uploadgst ? (
                  <div>
                    <div className="adhharr">
                      <div>
                        <label htmlFor="gstc">
                          <Input
                            accept="image/*"
                            id="gstc"
                            type="file"
                            onChange={(e) => setgst(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                gst
                                  ? URL.createObjectURL(gst)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFHb7plEMRK0DFPxz6DgiTUiBsPDN8hxUGlA&usqp=CAU"
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>GST Certificate</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <button className="upbtn" onClick={() => setuploadgst(true)}>
                    Upload GST Certificate
                  </button>
                )}
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={4}>
                Upload{" "}
                {type === "Spa"
                  ? "Spa"
                  : type === "Salon"
                  ? "Salon"
                  : "company"}{" "}
                Certificate <span style={{ color: "red" }}>*</span>
              </Col>
              <Col md={8}>
                {uploadspa ? (
                  <div>
                    <div className="adhharr">
                      <div>
                        <label htmlFor="spac">
                          <Input
                            accept="image/*"
                            id="spac"
                            type="file"
                            onChange={(e) => setspa(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                spa
                                  ? URL.createObjectURL(spa)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9nGPedYu-JMCMjJhhdSRS6zCpN3rIc4zc-w&usqp=CAU"
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>
                          {type === "Spa"
                            ? "Spa"
                            : type === "Salon"
                            ? "Salon"
                            : "company"}{" "}
                          Certificate
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <button className="upbtn" onClick={() => setuploadspa(true)}>
                    Upload{" "}
                    {type === "Spa"
                      ? "Spa"
                      : type === "Salon"
                      ? "Salon"
                      : "company"}{" "}
                    Certificate
                  </button>
                )}
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={4}>Upload if any other Certificate</Col>
              <Col md={8}>
                {uploadanyother ? (
                  <div>
                    <div className="adhharr">
                      <div>
                        <label htmlFor="other">
                          <Input
                            accept="image/*"
                            id="other"
                            type="file"
                            onChange={(e) => setother(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                other
                                  ? URL.createObjectURL(other)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReXigEzVzPiuiSbCLninx_j0hHkj4gwS4bJQ&usqp=CAU"
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>Certificate</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <button
                    className="upbtn"
                    onClick={() => setuploadanyother(true)}
                  >
                    Upload ifany other Certificate
                  </button>
                )}
              </Col>
            </Row>

            <Button variant="secondary" className="savbtn" onClick={update1}>
              <i class="fas fa-save"></i>&nbsp; Save & Continue
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Updateprofile;
