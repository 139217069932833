import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";

function Massage() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  return (
    <>
      <div className="never-sspa">
        <section>
          <div className="spa-admin-0 mb-2">
            <div className="diff-admin px-3">
              <div className="admin-name-text">
                <h4>Massage</h4>
              </div>
              <div className="admin-name" onClick={handleShow}>
                +Add
              </div>
            </div>
          </div>

          <div className="spa-table px-3">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S.N.</th>
                  <th>Images</th>
                  <th>Title</th>

                  <th style={{ width: "100px" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Mark</td>
                  <td>Otto</td>

                  <td>
                    <div
                      style={{
                        display: "flex",
                        gap: "21px",
                      }}
                    >
                      {" "}
                      <span className="dite">
                        {" "}
                        <FaRegEdit onClick={handleShow1} />
                      </span>
                      <span className="dite">
                        <RiDeleteBin2Fill />
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </section>
      </div>

      {/* modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Massage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-spa">
            <div className="differ-spa mb-2">
              <label>Title</label>
              <br />
              <input type="text" placeholder="Title" />
            </div>

            <div className="differ-spa mb-2">
              <label>Image</label>
              <br />
              <input type="file" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Massage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-spa">
            <div className="differ-spa mb-2">
              <label>Title</label>
              <br />
              <input type="text" placeholder="Title" />
            </div>

            <div className="differ-spa mb-2">
              <label>Image</label>
              <br />
              <input type="file" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose1}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Massage;
