import axios from "axios";
import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import Vendorheader from "./Vendorheader";
import Vendorsidebar from "./Vendorsidebar";
import Button from "react-bootstrap/Button";

function Vendordashboard() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [order, setorder] = useState([]);
  const [service, setservice] = useState([]);
  const [Therapist, setTherapist] = useState([]);

  const [data, setdata] = useState([]);
  const getallVendor = () => {
    axios
      .get("https://spazo.co.in/api/vendor/allVendors")
      .then(function (response) {
        console.log(response.data);
        setdata(response.data.allVendors);
        sessionStorage.setItem(
          "user",
          JSON.stringify(
            response.data.allVendors.find((item) => item._id === user._id)
          )
        );
        // window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    // Make an initial API call
    getallVendor();

    // Start the interval for subsequent API calls
    const intervalId = setInterval(getallVendor, 60000); // 60000ms = 1 minute

    return () => {
      // Clear the interval when the component unmounts
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    getVendorOrder();
    getVendorService();
    getVendorEmployee();
  }, []);

  const getVendorOrder = () => {
    axios
      .get("https://spazo.co.in/api/customer/getVendorOrder/" + user._id)
      .then(function (response) {
        console.log(response.data);
        setorder(response.data.Order);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getVendorService = () => {
    axios
      .get("https://spazo.co.in/api/vendor/getVendorService/" + user._id)
      .then(function (response) {
        console.log(response.data);
        setservice(response.data.Service);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getVendorEmployee = () => {
    axios
      .get("https://spazo.co.in/api/Vendor/getAllEmployee")
      .then(function (response) {
        console.log(response.data);
        setTherapist(
          response.data?.Employee?.filter(
            (item) =>
              item?.isDeleted === false &&
              item?.vendor[item.vendor?.length - 1].VendorId === user?._id
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {user?.approved === "Approved" ? (
        <>
          {/* {window.location.assign("/Vendordashboard")} */}
          <div className="never-sspa p-3">
            <div className="row mt-2 pt-3">
              <div className="col-md-9">
                <h6>Dashboard</h6>
                <br></br>
                <div className="row">
                  <div className="col-3">
                    <Card
                      style={{
                        // width: "200px",
                        boxShadow: "0px 0px 22px #ffda77",
                      }}
                      onClick={() => window.location.assign("Vendorservices")}
                    >
                      <Card.Body>
                        <Card.Title style={{ fontSize: "15px" }}>
                          Services
                        </Card.Title>
                        <Card.Text
                          style={{
                            backgroundColor: "#e22217",
                            width: "20%",
                            borderRadius: "50%",
                            textAlign: "center",
                            color: "white",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          {service?.length}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-3">
                    <Card
                      style={{
                        // width: "200px",
                        boxShadow: "0px 0px 22px #ffda77",
                      }}
                      onClick={() => window.location.assign("VendorEmployee")}
                    >
                      <Card.Body>
                        <Card.Title style={{ fontSize: "15px" }}>
                          No. of Therapist
                        </Card.Title>
                        <Card.Text
                          style={{
                            backgroundColor: "#e22217",
                            width: "20%",
                            borderRadius: "50%",
                            textAlign: "center",
                            color: "white",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          {Therapist?.length}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-3">
                    <Card
                      style={{
                        // width: "200px",
                        boxShadow: "0px 0px 22px #ffda77",
                      }}
                      onClick={() => window.location.assign("vendorBooking")}
                    >
                      <Card.Body>
                        <Card.Title style={{ fontSize: "15px" }}>
                          No. of Booking
                        </Card.Title>
                        <Card.Text
                          style={{
                            backgroundColor: "#e22217",
                            width: "20%",
                            borderRadius: "50%",
                            textAlign: "center",
                            color: "white",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          {order?.length}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {user?.approved === "Rejected" && user?.update === false ? (
        <>
          <div className="p-3">
            {/* <div className="row">
              <Vendorheader />
            </div> */}
            <div style={{ marginTop: "20px" }}>
              <h6 style={{ textAlign: "center" }}>
                Your profile has been rejected
              </h6>
              <p style={{ textAlign: "center" }}>{user?.reasonFR}</p>
            </div>
          </div>
          <div
            style={{
              marginBottom: "100px",
              justifyContent: "center",
              display: "flex",
              gap: "30px",
            }}
          >
            <Button
              variant="secondary"
              onClick={() => window.location.assign("myprofile")}
            >
              My Profile
            </Button>
            <Button
              variant="light"
              onClick={() => window.location.assign("updateprofile")}
              style={{ color: "#e22217" }}
            >
              Update Profile
            </Button>
          </div>
        </>
      ) : (
        <></>
      )}

      {user?.approved === "Rejected" && user?.update === true ? (
        <>
          <div className="never-sspa p-3">
            {/* <div className="row">
              <Vendorheader />
            </div> */}
            <div style={{ marginTop: "250px", marginBottom: "250px" }}>
              <h6 style={{ textAlign: "center" }}>
                You have successfully updated your profile. <br></br>
                Your profile is under verification, after verification you can
                add your service's and it will display in our website
              </h6>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {user?.approved === "Pending" ? (
        <>
          <div className="never-sspa p-3">
            {/* <div className="row">
              <Vendorheader />
            </div> */}
            <div style={{ marginTop: "250px", marginBottom: "250px" }}>
              <h6 style={{ textAlign: "center" }}>
                You have successfully updated your profile. <br></br>
                Your profile is under verification, after verification you can
                add your service's and it will display in our website
              </h6>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default Vendordashboard;
