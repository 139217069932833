import React from "react";
import Vendorsidebar from "./Vendorsidebar";
import "../../Admin/admin.css";
import Vendorheader from "./Vendorheader";

function Vendordashbordmain(props) {
  const user = JSON.parse(sessionStorage.getItem("user"));

  return (
    <>
      <div className="dash">
        {/* <div className="row me-0"> */}
        <div className="row">
          {user?.approved === "Approved" ? (
            <>
              <div className="col-md-2 p-0">
                <div className="left-side">
                  <Vendorsidebar />
                </div>
              </div>
              <div className="col-md-10 p-0 right-h">
                <Vendorheader />
                {props.children}
              </div>
            </>
          ) : (
            <div>
              <Vendorheader />
              {props.children}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Vendordashbordmain;
