import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment/moment";

function Reviews() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);

  const [data, setdata] = useState([]);

  const [show2, setShow2] = useState(false);
  const [deletedata, setdeletedata] = useState({});

  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setdeletedata(item);
  };

  console.log("catname", data);

  useEffect(() => {
    getreview();
  }, []);

  const getreview = () => {
    axios
      .get("https://spazo.co.in/api/customer/getReview")
      .then(function (response) {
        console.log(response.data);
        setdata(
          response.data.Review?.filter(
            (item) => item?.vendors[0]?.type === "Spa"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteReview = async () => {
    try {
      const config = {
        url: "/adminRemoveReview/" + deletedata?._id,
        method: "delete",
        baseURL: "https://spazo.co.in/api/customer",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          notify("Data deleted successfully");
          // alert("Data deleted successfully");
          window.location.reload();
          getreview();
        } else {
          // alert(res.status.error);
          notify1(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      notify1("Unable to delete Details, Please try after some time");
      // alert("Unable to add Details");
    }
  };

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "3",
        value: 3,
      },
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "createdAt",
      text: "Date",
      formatter: (value, row) => (
        <p>
          {row?.createdAt ? moment(row?.createdAt).format("DD/MM/YYYY") : "-"}
        </p>
      ),
    },
    {
      dataField: "businessName",
      text: "Vendor Name",
      formatter: (value, row) => (
        <p>
          {row?.vendors[0]?.businessName ? row?.vendors[0]?.businessName : "-"}
        </p>
      ),
    },
    {
      dataField: "name",
      text: "Customer Name",
      formatter: (value, row) => <p>{row?.name ? row?.name : "-"}</p>,
    },
    {
      dataField: "review",
      text: "Review",
      formatter: (value, row) => <p>{row?.review ? row?.review : "-"}</p>,
    },
    {
      dataField: "rating",
      text: "Rating",
      formatter: (value, row) => <p>{row?.rating ? row?.rating : "-"}</p>,
    },

    {
      dataField: "",
      text: "Action",
      formatter: (value, row) => (
        <div
          style={{
            display: "flex",
            gap: "21px",
          }}
        >
          <span className="dite">
            <RiDeleteBin2Fill onClick={() => handleShow2(row)} />
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="never-sspa">
        <section>
          <div className="spa-admin-0 mb-2">
            <div className="diff-admin px-3">
              <div className="admin-name-text">
                <h4>Reviews</h4>
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={30000}
            closeOnClick
            pauseOnHover
            draggable
          />
          <div
            style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}
          >
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              // rowClasses={rowClasses}
              search
              exportCSV
            >
              {(props) => (
                <div>
                  <SearchBar {...props.searchProps} />
                  {/* <ExportCSVButton
                    {...props.csvProps}
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                    }}
                  >
                    Download Excel
                  </ExportCSVButton> */}

                  <hr></hr>
                  <br></br>
                  <div style={{ overflowX: "scroll", width: "100%" }}>
                    <BootstrapTable
                      striped
                      hover
                      condensed
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </section>

        {/* Delete */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Reviews</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, you want to delete data</Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={deleteReview}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Reviews;
