import React from "react";
import "../Styles/budget.css";
import { Link } from "react-router-dom";

function Budget() {
  return (
    <section>
      <div className="budget">
        <div className="container-fluid de-top">
          <h4>Massage In Your Budget</h4>
        </div>
        <div className="spa-budget">
          <ul>
            <li>
              <Link to="/budgetProduct" state={{ Price: "UNDER500" }}>
                <div className="budget-spa-0">
                  <div
                    className="spa-text-0"
                    style={{
                      backgroundImage:
                        "url('../../Assets/images/va-spa3.webp')",
                    }}
                  >
                    <div className="dudget-diff">
                      <h5>UNDER500</h5>
                      <div>(one person)</div>
                    </div>
                  </div>
                </div>
              </Link>
            </li>

            <li>
              <Link to="/budgetProduct" state={{ Price: "600-1000" }}>
                <div className="budget-spa-0">
                  <div
                    className="spa-text-0"
                    style={{
                      backgroundImage:
                        "url('../../Assets/images/va-spa3.webp')",
                    }}
                  >
                    <div className="dudget-diff">
                      <h5>600-1000</h5>
                      <div>(one person)</div>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/budgetProduct" state={{ Price: "1100-1500" }}>
                <div className="budget-spa-0">
                  <div
                    className="spa-text-0"
                    style={{
                      backgroundImage:
                        "url('../../Assets/images/va-spa3.webp')",
                    }}
                  >
                    <div className="dudget-diff">
                      <h5>1100-1500</h5>
                      <div>(one person)</div>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/budgetProduct" state={{ Price: "1600-2000" }}>
                <div className="budget-spa-0">
                  <div
                    className="spa-text-0"
                    style={{
                      backgroundImage:
                        "url('../../Assets/images/va-spa3.webp')",
                    }}
                  >
                    <div className="dudget-diff">
                      <h5>1600-2000</h5>
                      <div>(one person)</div>
                    </div>
                  </div>
                </div>
              </Link>
            </li>

            <li>
              <Link to="/budgetProduct" state={{ Price: "2100-2500" }}>
                <div className="budget-spa-0">
                  <div
                    className="spa-text-0"
                    style={{
                      backgroundImage:
                        "url('../../Assets/images/va-spa3.webp')",
                    }}
                  >
                    <div className="dudget-diff">
                      <h5>2100-2500</h5>
                      <div>(one person)</div>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Budget;
