import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Card } from "react-bootstrap";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";

function AdminDashboard() {
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>;

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const [customer, setcustomer] = useState([]);
  const [Vendor, setVendor] = useState([]);
  const [SpaVendor, setSpaVendor] = useState([]);
  const [SalonVendor, setSalonVendor] = useState([]);
  const [BCPVendor, setBCPVendor] = useState([]);
  const [category, setcategory] = useState([]);
  const [Service, setService] = useState([]);
  const [spabooking, setspabooking] = useState([]);
  const [salonbooking, setsalonbooking] = useState([]);
  const [bcpbooking, setbcpbooking] = useState([]);

  useEffect(() => {
    getCustomer();
    getallVendor();
    getallCategory();
    // getallservices();
    getAdminservice();
    getOrder();
  }, []);

  const getCustomer = () => {
    axios
      .get("https://spazo.co.in/api/customer/getCustomer")
      .then(function (response) {
        console.log(response.data);
        setcustomer(response.data.Customer);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getallCategory = () => {
    axios
      .get("https://spazo.co.in/api/admin/getcategory")
      .then(function (response) {
        console.log(response.data);
        setcategory(response.data.category);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getallVendor = () => {
    axios
      .get("https://spazo.co.in/api/vendor/allVendors")
      .then(function (response) {
        console.log(response.data);
        setVendor(response.data.allVendors);
        setSpaVendor(
          response.data.allVendors?.filter((item) => item.type === "Spa")
        );
        setSalonVendor(
          response.data.allVendors?.filter((item) => item.type === "Salon")
        );
        setBCPVendor(
          response.data.allVendors?.filter(
            (item) => item.type !== "Spa" && item.type !== "Salon"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getallservices = () => {
    axios
      .get("https://spazo.co.in/api/vendor/getService")
      .then(function (response) {
        console.log(response.data);
        setService(response.data.Service);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getAdminservice = () => {
    axios
      .get("https://spazo.co.in/api/admin/getAdminservice")
      .then(function (response) {
        console.log(response.data);
        setService(response.data.Adminservice);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getOrder = () => {
    axios
      .get("https://spazo.co.in/api/customer/getOrder")
      .then(function (response) {
        console.log(response.data);
        setspabooking(
          response.data.Order?.filter(
            (item) => item?.vendors[0]?.type === "Spa"
          )
        );
        setsalonbooking(
          response.data.Order?.filter(
            (item) => item?.vendors[0]?.type === "Salon"
          )
        );
        setbcpbooking(
          response.data.Order?.filter(
            (item) => item?.vendors[0]?.type === "BCP"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div className="never-sspa">
        <div className="admin-spa p-3">
          <div className="row me-0">
            <h4>Dashboard</h4>
            {admin.Customers ? (
              <div className="col-md-3">
                <div className="px-3 py-3">
                  <Card
                    style={{
                      width: "200px",
                      boxShadow: "0px 0px 22px rgb(226, 34, 23)",
                    }}
                    onClick={() => window.location.assign("customers")}
                  >
                    <Card.Body>
                      <Card.Title
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        No. of Customers
                      </Card.Title>
                      <Card.Text
                        style={{
                          backgroundColor: "#e22217",
                          width: "20%",
                          borderRadius: "50%",
                          textAlign: "center",
                          color: "white",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {customer?.length}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            ) : (
              ""
            )}
            {admin.Vendor ? (
              <div className="col-md-3">
                <div className="px-3 py-3">
                  <Card
                    style={{
                      width: "200px",
                      boxShadow: "0px 0px 22px rgb(226, 34, 23)",
                    }}
                    onClick={() => window.location.assign("vendor")}
                  >
                    <Card.Body>
                      <Card.Title style={{ fontSize: "15px" }}>
                        No. of Spa-Vendors
                      </Card.Title>
                      <Card.Text
                        style={{
                          backgroundColor: "#e22217",
                          width: "20%",
                          borderRadius: "50%",
                          textAlign: "center",
                          color: "white",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {SpaVendor?.length}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            ) : (
              ""
            )}
            {admin.SalonVendor ? (
              <div className="col-md-3">
                <div className="px-3 py-3">
                  <Card
                    style={{
                      width: "200px",
                      boxShadow: "0px 0px 22px rgb(226, 34, 23)",
                    }}
                    onClick={() => window.location.assign("SalonVendor")}
                  >
                    <Card.Body>
                      <Card.Title style={{ fontSize: "15px" }}>
                        No. of Salon-Vendors
                      </Card.Title>
                      <Card.Text
                        style={{
                          backgroundColor: "#e22217",
                          width: "20%",
                          borderRadius: "50%",
                          textAlign: "center",
                          color: "white",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {SalonVendor?.length}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="col-md-3">
              <div className="px-3 py-3">
                <Card
                  style={{
                    width: "200px",
                    boxShadow: "0px 0px 22px rgb(226, 34, 23)",
                  }}
                  onClick={() => window.location.assign("")}
                >
                  <Card.Body>
                    <Card.Title style={{ fontSize: "15px" }}>
                      No. of BCP-Vendors
                    </Card.Title>
                    <Card.Text
                      style={{
                        backgroundColor: "#e22217",
                        width: "20%",
                        borderRadius: "50%",
                        textAlign: "center",
                        color: "white",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      {BCPVendor?.length}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </div>
            {admin.category ? (
              <div className="col-md-3">
                <div className="px-3 py-3">
                  <Card
                    style={{
                      width: "200px",
                      boxShadow: "0px 0px 22px rgb(226, 34, 23)",
                    }}
                    onClick={() => window.location.assign("category")}
                  >
                    <Card.Body>
                      <Card.Title style={{ fontSize: "15px" }}>
                        No. of Category
                      </Card.Title>
                      <Card.Text
                        style={{
                          backgroundColor: "#e22217",
                          width: "20%",
                          borderRadius: "50%",
                          textAlign: "center",
                          color: "white",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {category?.length}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            ) : (
              ""
            )}
            {admin.Adminservice ? (
              <div className="col-md-3">
                <div className="px-3 py-3">
                  <Card
                    style={{
                      width: "200px",
                      boxShadow: "0px 0px 22px rgb(226, 34, 23)",
                    }}
                    onClick={() => window.location.assign("Adminservice")}
                  >
                    <Card.Body>
                      <Card.Title style={{ fontSize: "15px" }}>
                        No. of Services
                      </Card.Title>
                      <Card.Text
                        style={{
                          backgroundColor: "#e22217",
                          width: "20%",
                          borderRadius: "50%",
                          textAlign: "center",
                          color: "white",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {Service?.length}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            ) : (
              ""
            )}
            {admin.Booking ? (
              <div className="col-md-3">
                <div className="px-3 py-3">
                  <Card
                    style={{
                      width: "200px",
                      boxShadow: "0px 0px 22px rgb(226, 34, 23)",
                    }}
                    onClick={() => window.location.assign("booking")}
                  >
                    <Card.Body>
                      <Card.Title style={{ fontSize: "15px" }}>
                        No. of Spa-Booking
                      </Card.Title>
                      <Card.Text
                        style={{
                          backgroundColor: "#e22217",
                          width: "20%",
                          borderRadius: "50%",
                          textAlign: "center",
                          color: "white",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {spabooking?.length}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            ) : (
              ""
            )}
            {admin.SalonBooking ? (
              <div className="col-md-3">
                <div className="px-3 py-3">
                  <Card
                    style={{
                      width: "200px",
                      boxShadow: "0px 0px 22px rgb(226, 34, 23)",
                    }}
                    onClick={() => window.location.assign("SalonBooking")}
                  >
                    <Card.Body>
                      <Card.Title style={{ fontSize: "15px" }}>
                        No. of Salon-Booking
                      </Card.Title>
                      <Card.Text
                        style={{
                          backgroundColor: "#e22217",
                          width: "20%",
                          borderRadius: "50%",
                          textAlign: "center",
                          color: "white",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {salonbooking?.length}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="col-md-3">
              <div className="px-3 py-3">
                <Card
                  style={{
                    width: "200px",
                    boxShadow: "0px 0px 22px rgb(226, 34, 23)",
                  }}
                  onClick={() => window.location.assign("")}
                >
                  <Card.Body>
                    <Card.Title style={{ fontSize: "15px" }}>
                      No. of BCP-Booking
                    </Card.Title>
                    <Card.Text
                      style={{
                        backgroundColor: "#e22217",
                        width: "20%",
                        borderRadius: "50%",
                        textAlign: "center",
                        color: "white",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      {bcpbooking?.length}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminDashboard;
