import React from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

function CheckoutPage() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const location = useLocation();
  const { slots } = location.state;
  const d = new Date();

  const [slotbooked, setslotbooked] = useState("");
  const [paymentID, setpaymentID] = useState("");

  console.log("slots", slots);

  // const totalamount =
  //   slots?.length > 0 ? slots?.reduce((a, item) => a + item?.Price, 0) : 0;

  // const totaldiscount =
  //   slots?.length > 0
  //     ? slots?.reduce((a, item) => a + (item?.Price * item?.Discount) / 100, 0)
  //     : 0;

  // const Razorpay = useRazorpay();

  // const loadScript = (src) => {
  //   return new Promise((resolve) => {
  //     const script = document.createElement("script");
  //     script.src = src;
  //     script.onload = () => {
  //       resolve(true);
  //     };
  //     script.error = () => {
  //       resolve(false);
  //     };
  //     document.body.appendChild(script);
  //   });
  // };
  // const [payid, setpayid] = useState("");
  // const posttransaction = async () => {
  //   try {
  //     const config = {
  //       data: {
  //         key: "rzp_test_5vCRZ6rLM2wGN4",
  //         amount: 100,
  //         currency: "INR",
  //         name: "SPA",
  //         description: "Order Amount",
  //         image: "../logo.png",
  //         // customerId: user.,
  //         handler: function (response) {
  //           alert(response.razorpay_payment_id);
  //           // setpayid(response.razorpay_payment_id);
  //         },
  //       },
  //     };
  //     const res = await loadScript(
  //       "https://checkout.razorpay.com/v1/checkout.js"
  //     );

  //     const paymentObject = new Razorpay(config.data);
  //     paymentObject.open();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const placeorder = async (e) => {
    if (!user) {
      alert("Please login to book a appointment");
    } else if (!slotbooked) {
      alert("Please select Booking Date");
    } else {
      try {
        const config = {
          url: "/AddOrder",
          method: "post",
          baseURL: "https://spazo.co.in/api/customer",
          headers: { "content-type": "application/json" },
          data: {
            slotbooked: slotbooked,
            Service_id: slots?._id,
            ServiceName: slots?.ServiceName,
            Service_Category: slots?.Service_Category,
            Service_Duration: slots?.Service_Duration,
            ValidFor: slots?.ValidFor,
            slots: slots?.slots,
            Price: slots?.Price,
            Discount: slots?.Discount,
            totalamount: slots?.Price - (slots?.Price * slots?.Discount) / 100,
            vendorId: slots?.VendorId,
            VendorBusinessName: slots?.VendorBusinessName,
            VendorName: slots?.VendorName,
            VendorEmailID: slots?.VendorEmailID,
            VendorContactNo: slots?.VendorContactNo,
            VendorAddress: slots?.VendorAddress,
            VendorPincode: slots?.VendorPincode,
            VendorGlink: slots?.VendorGlink,
            userID: user?._id,
            userName: user?.name,
            userEmailID: user?.email,
            userContactNumber: user?.phoneNumber,
            paymentMethod: "Online Payment",
            paymentID: paymentID ? paymentID : "",
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("Your appointment booked successfully");
          window.location.assign("home");
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };

  const placeorder1 = async (e) => {
    if (!user) {
      alert("Please login to book a appointment");
    } else if (!slotbooked) {
      alert("Please select Booking Date");
    } else {
      try {
        const config = {
          url: "/AddOrder",
          method: "post",
          baseURL: "https://spazo.co.in/api/customer",
          headers: { "content-type": "application/json" },
          data: {
            slotbooked: slotbooked,
            Service_id: slots?._id,
            ServiceName: slots?.ServiceName,
            Service_Category: slots?.Service_Category,
            Service_Duration: slots?.Service_Duration,
            ValidFor: slots?.ValidFor,
            slots: slots?.slots,
            Price: slots?.Price,
            Discount: slots?.Discount,
            totalamount: slots?.Price - (slots?.Price * slots?.Discount) / 100,
            vendorId: slots?.VendorId,
            VendorBusinessName: slots?.VendorBusinessName,
            VendorName: slots?.VendorName,
            VendorEmailID: slots?.VendorEmailID,
            VendorContactNo: slots?.VendorContactNo,
            VendorAddress: slots?.VendorAddress,
            VendorPincode: slots?.VendorPincode,
            VendorGlink: slots?.VendorGlink,
            userID: user?._id,
            userName: user?.name,
            userEmailID: user?.email,
            userContactNumber: user?.phoneNumber,
            paymentMethod: "Offline Payment",
            paymentID: "",
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("Your appointment booked successfully");
          window.location.assign("home");
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };

  console.log("slotbooked", slotbooked);
  return (
    <>
      <div className="palce-order">
        <section>
          <div className="row me-0">
            <div className="col-md-2"></div>
            <div
              className="col-md-8"
              style={{
                backgroundColor: "#ffda77",
                padding: "20px",
                marginBottom: "60px",
              }}
            >
              <div className="time-spa" style={{ gap: "20px" }}>
                <div className="cancel">
                  <h6>Service Name</h6>
                  <div className="how-0">
                    <div
                      className="freee"
                      style={{
                        backgroundColor: "white",
                        padding: "12px 167px",
                      }}
                    >
                      {slots?.ServiceName}
                    </div>
                  </div>
                </div>
                <div className="cancel">
                  <h6>Date Book</h6>
                  <div className="how-0">
                    <div className="freee">
                      <select
                        style={{
                          width: "397px",
                          padding: "12px",
                          border: "none",
                        }}
                        onChange={(e) => setslotbooked(e.target.value)}
                      >
                        <option>Select</option>
                        {slots?.slots?.map((dat) =>
                          dat?.no_Booking_slot > 0 ? (
                            <option
                              value={`${dat?._id}|${dat?.slotdate}|${dat?.slot_from_time}|${dat?.slot_To_time}`}
                            >
                              {dat?.slotdate}&nbsp; / &nbsp;
                              {dat?.slot_from_time} -{dat?.slot_To_time}
                            </option>
                          ) : (
                            ""
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                {/* <div className="cancel">
                      <h6>Select Timing</h6>
                      <div className="how-0">
                        <div className="freee">
                          <Form.Select aria-label="Default select example">
                            <option>Open this select menu</option>
                            <option value="1">10.00am-10.30am</option>
                            <option value="2">10.30am-11.00am</option>
                            <option value="3">11.00am-11.30am</option>
                            <option value="3">11.30am-12.00pm</option>
                            <option value="3">12.00pm-12.30pm</option>
                            <option value="3">12.30pm-01.00pm</option>
                            <option value="3">01.00pm-1.30pm</option>
                            <option value="3">01.30pm-02.00pm</option>
                            <option value="3">02.00pm-02.30pm</option>
                            <option value="3">02.30pm-03.00pm</option>
                            <option value="3">03.00pm-03.30pm</option>
                            <option value="3">03.30pm-04.00pm</option>
                            <option value="3">04.00pm-04.30pm</option>
                            <option value="3">04.30pm-05.00pm</option>
                            <option value="3">05.00pm-05.30pm</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div> */}
              </div>

              <div
                className="book-cart"
                style={{ backgroundColor: "white", margin: "50px" }}
              >
                <div className="book-f">YOUR ORDERS</div>

                <div
                  className="order-details"
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  <div className="title-p">{slots?.ServiceName}</div>
                  <div className="title-p">{slots?.Price}</div>
                </div>

                {/* <div
                  className="order-details"
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  <div className="title-p">Swedish Full Bod..</div>
                  <div className="sspa-incre">
                    <div className="spa-plus">
                      <AiOutlineMinus
                        style={{
                          fontSize: "25px",
                          fontWeight: "800",
                        }}
                      />
                    </div>
                    <div className="spa-plus-1">1</div>

                    <div className="spa-plus">
                      <AiOutlinePlus
                        style={{
                          fontSize: "25px",
                          fontWeight: "800",
                        }}
                      />
                    </div>
                  </div>

                  <div className="title-p">1398</div>
                </div> */}
                <hr />
                <div className="order-details p-0">
                  {/* <div></div> */}
                  <div className="title-pp">Price</div>
                  <div className="title-pp">{(slots?.Price).toFixed(2)}</div>
                </div>
                <div className="order-details p-0">
                  {/* <div></div> */}
                  <div className="title-pp">GST (%)</div>
                  <div className="title-pp">{(slots?.Price).toFixed(2)}</div>
                </div>
                <div className="order-details p-0">
                  {/* <div></div> */}
                  <div className="title-pp">Service Tax</div>
                  <div className="title-pp">{(slots?.Price).toFixed(2)}</div>
                </div>
                <div className="order-details p-0">
                  {/* <div></div> */}
                  <div className="title-pp">Discount (%)</div>
                  <div
                    className="title-pp"
                    style={{ borderBottom: "1px solid black" }}
                  >
                    {((slots?.Price * slots?.Discount) / 100).toFixed(2)}
                  </div>
                </div>

                <div className="order-details p-0">
                  {/* <div></div> */}
                  <div className="title-pp">Total</div>
                  <div className="title-pp">
                    {(
                      slots?.Price -
                      (slots?.Price * slots?.Discount) / 100
                    ).toFixed(2)}
                  </div>
                </div>
                <div
                  className="cust-login-0 mt-3"
                  style={{ display: "flex", gap: "10px" }}
                >
                  <button style={{ width: "387px" }} onClick={placeorder}>
                    <a
                      // href="/order-summary"
                      style={{ textDecoration: "none", color: "white" }}
                      // onClick={posttransaction}
                    >
                      Pay 20 %
                    </a>
                  </button>
                  <button style={{ width: "387px" }} onClick={placeorder}>
                    <a
                      // href="/order-summary"
                      style={{ textDecoration: "none", color: "white" }}
                      // onClick={posttransaction}
                    >
                      Pay Now
                    </a>
                  </button>
                  <button style={{ width: "387px" }} onClick={placeorder1}>
                    <a
                      // href="#"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Pay Later
                    </a>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </section>{" "}
      </div>
    </>
  );
}

export default CheckoutPage;
