import React, { useEffect } from "react";
import "../Styles/topvendor.css";
import OwlCarousel from "react-owl-carousel";
import Spajson from "./Spa";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function TopDiscount() {
  const responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 4,
    },
  };

  const [product, setproduct] = useState(Spajson);
  const [vendors, setvendors] = useState([]);

  const topdiscount = vendors.filter((item) => item.mindiscount >= 50);
  useEffect(() => {
    getallVendor();
  }, []);

  const getallVendor = () => {
    axios
      .get("https://spazo.co.in/api/vendor/allVendors")
      .then(function (response) {
        // console.log(response.data);
        setvendors(
          response.data.allVendors?.filter(
            (item) => item.approved === "Approved"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <section>
        <div
          className="container-fluid"
          data-aos="fade-up"
          data-aos-delay="800"
          data-aos-duration="4000"
        >
          <div className="item-d">
            <div className="de-top">
              <h4>Top Discount Spas In Your City</h4>
            </div>
            <div className="de-top-1">
              <a href="/product">
                <h6>View All offers</h6>
              </a>
            </div>
          </div>
          <div className="spa-ve">
            <div className="default-spa">
              <OwlCarousel
                className="owl-theme"
                margin={15}
                items={4}
                nav={false}
                dots={false}
                responsive={responsive}
              >
                {topdiscount?.map((item, index) => {
                  return (
                    <div class="item mt-3 mb-3">
                      <div className="massage-0">
                        <Link to="/product-details" state={{ dis: item }}>
                          <div className="customer">
                            <img
                              src={
                                "https://spazo.co.in/Vendor/" + item?.spaimage1
                              }
                              alt="spa-images"
                            />
                            <div className="cust-ti pt-2">
                              <h6>{item.businessName}</h6>
                              <div
                                className="location"
                                style={{ color: "black" }}
                              >
                                {" "}
                                {item.address}
                              </div>
                              {/* <div className="massage-type">{item.vendor}</div>  */}
                              <div className="tit-p">
                                <div className="price">
                                  <div>
                                    <s style={{ color: "black" }}>
                                      ₹{item.basicprice}
                                    </s>
                                  </div>
                                  <div className="discount-price">
                                    ₹
                                    {item?.basicprice -
                                      (item?.basicprice * item?.mindiscount) /
                                        100}
                                  </div>
                                </div>
                              </div>
                              <div className="discount">
                                {item.mindiscount}% off
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TopDiscount;
