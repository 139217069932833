import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BsTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { IoLocationSharp } from "react-icons/io5";

function ContactUS() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");

  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    alert("You have entered an invalid email address!");
    return false;
  }

  function validatename(inputtxt) {
    var phoneno = /^[a-zA-Z ]{2,30}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid name!");
      return false;
    }
  }

  const addcontactus = async (e) => {
    if (!name || !email || !message) {
      alert("Please fill all the details");
    } else {
      try {
        if (validatename(name) && ValidateEmail(email)) {
          const config = {
            url: "/AddContactus",
            method: "post",
            baseURL: "https://spazo.co.in/api/customer",
            headers: { "content-type": "application/json" },
            data: {
              name: name,
              email: email,
              message: message,
            },
          };
          let res = await axios(config);
          if (res.status === 200) {
            alert("Your details added successfully, Will getback to you...");
            window.location.assign("/home");
          }
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#ffe8f4",
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        display: "grid",
      }}
    >
      <Row style={{ width: "50%", marginLeft: "auto", marginRight: "auto" }}>
        <Col md={8} style={{ backgroundColor: "white", padding: "30px" }}>
          <h6>want us to contact you</h6>
          <p style={{ marginBottom: "0px" }}>Leave a Message</p>
          <input
            placeholder="Name"
            className="contactinput"
            onChange={(e) => setname(e.target.value)}
          />
          <br></br>
          <input
            placeholder="Email ID"
            className="contactinput mt-3"
            onChange={(e) => setemail(e.target.value)}
          />
          <br></br>
          <textarea
            placeholder="Message"
            className="contactinput mt-3"
            onChange={(e) => setmessage(e.target.value)}
          />
          <br></br>
          <button
            style={{
              marginTop: "20px",
              backgroundColor: "#e22217",
              border: "none",
              padding: "10px 20px",
              color: "white",
            }}
            onClick={addcontactus}
          >
            Send
          </button>
        </Col>
        <Col md={4} style={{ backgroundColor: "#e22217" }}>
          <p style={{ backgroundColor: "white" }}>Contact Us</p>
          <div style={{ margin: "10px" }}>
            <Row className="mt-2">
              <Col md={2}>
                <IoLocationSharp style={{ color: "white" }} />
              </Col>
              <Col md={8} style={{ color: "white" }}>
                Singapura, Bengalore, Karnataka - 670001
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={2}>
                <GrMail style={{ color: "white" }} />
              </Col>
              <Col md={8} style={{ color: "white" }}>
                Spa@gmail.com
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={2}>
                <BsTelephoneFill style={{ color: "white" }} />
              </Col>
              <Col md={10} style={{ color: "white" }}>
                98767 78976
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ContactUS;
