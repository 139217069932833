import React, { useEffect } from "react";
import "../Styles/header.css";
import { Wave, Random } from "react-animated-text";

function Screen() {
  useEffect(() => {
    setTimeout(() => {
      window.location.assign("/NewHome");
    }, 4000);
  }, []);

  return (
    <>
      <div
        className=""
        style={{
          height: "100vh",
          // backgroundColor: "#EA906C",
        }}
      >
        <div
          className=""
          style={{
            position: " absolute",
            top: "50%",
            left: "50%",
            transform: " translate(-50%, -50%)",
          }}
        >
          <img
            src="./logo.jpg"
            alt="spa"
            style={{ width: "200px", height: "100px" }}
          />
          {/* <div
            className=""
            style={{
              fontSize: "40px",
              fontWeight: "500",
              color: "white",
            }}
          >
            SPA CENTER
          </div> */}
          <div className="exampleStyle">
            <Wave text="LOADING DATA" effect="fadeOut" effectChange={1.0} />

            {/* <div className="codeDivStyle">
              <pre className="codeStyle">
                &lt;Wave
                <br />
                &nbsp;&nbsp;text="LOADING DATA"
                <br />
                &nbsp;&nbsp;effect="stretch"
                <br />
                &nbsp;&nbsp;effectChange={2.0}
                <br />
              </pre>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Screen;
