import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaEye, FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Booking() {
  const Input = styled("input")({
    display: "none",
  });

  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const data = [];
  const [Order, setOrder] = useState([]);
  const [paymentType, setpaymentType] = useState("All");
  const [show, setShow] = useState(false);
  const [viewdata, setviewdata] = useState({});
  console.log("viewdata", viewdata);

  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setShow(true);
    setviewdata(item);
  };

  const [canceldata, setcanceldata] = useState(false);
  const [Bookingrejectionreason, setBookingrejectionreason] = useState("");

  const cancelOrder = async (data) => {
    if (!Bookingrejectionreason) {
      notify2("Please enter reason for cancelation");
    } else {
      try {
        const config = {
          url: "/cancelOrder",
          method: "post",
          baseURL: "https://spazo.co.in/api/customer",
          headers: { "content-type": "application/json" },
          data: {
            Booking_Id: data?.Booking_Id,
            Bookingrejectionreason: Bookingrejectionreason,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          notify("Booking cancelation done succesfully");
          window.location.reload();
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          notify1(error.response.data.error);
        }
      }
    }
  };

  useEffect(() => {
    getOrder();
  }, []);

  const getOrder = () => {
    axios
      .get("https://spazo.co.in/api/customer/getOrder")
      .then(function (response) {
        console.log(response.data);
        setOrder(
          response.data.Order?.filter(
            (item) => item?.vendors[0]?.type === "Spa"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const reAssign = async (data) => {
    try {
      const config = {
        url: "/reAssign",
        method: "post",
        baseURL: "https://spazo.co.in/api/customer",
        headers: { "content-type": "application/json" },
        data: {
          Booking_Id: data?.Booking_Id,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        notify("Booking re-assign enabled succesfully");
        window.location.reload();
      }
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        notify1(error.response.data.error);
      }
    }
  };

  for (let i = 0; i < Order.length; i++) {
    const vendorshare =
      Order[i].TotalAmount -
      Order[i].Discount -
      ((Order[i].TotalAmount - Order[i].Discount) *
        Order[i].vendors[0]?.Vendorpercentage) /
        100;

    //Online payment 5%
    const adminshare = ((Order[i].TotalAmount - Order[i].Discount) * 5) / 100;
    // vendor %
    const adminshare1 =
      ((Order[i].TotalAmount - Order[i].Discount) *
        Order[i].vendors[0]?.Vendorpercentage) /
      100;
    const adminshare2 =
      Order[i].paymentMethod === "Pay At Spa"
        ? adminshare1 - Order[i].CouponDiscount
        : adminshare1 - Order[i].CouponDiscount - adminshare;

    const Slot = Order[i].slotbooked ? Order[i]?.slotbooked?.split("|") : [];
    data.push({
      Booking_Id: Order[i]._id,
      Booking_Date: moment(Order[i].createdAt).format("DD/MM/YYYY"),
      Category: Order[i].Service_Category,
      Service_Name: Order[i].ServiceName,
      Duration: Order[i].Service_Duration,
      ValidFor: Order[i].ValidFor,
      date: Order[i].date,
      fromTime: Order[i].fromTime,
      toTime: Order[i].toTime,
      Customer_Name: Order[i].userName,
      Customer_Email_ID: Order[i].userEmailID,
      Customer_Contact_No: Order[i].userContactNumber,
      Therapist_id: Order[i].Therapist,
      Assined_Therapist: Order[i]?.Assined_Therapist,
      // Therapist1_id: Order[i].Therapist1,
      Spa_Name: Order[i].VendorBusinessName,
      Spa_Email: Order[i].VendorEmailID,
      Contact_Number: Order[i].VendorContactNo,
      Spa_Address: Order[i].VendorAddress,
      Spa_Discount: Order[i].Discount,
      Basic_Price: Order[i].Price,
      Total_Price: Order[i].TotalAmount,
      TotalPayableAmount: Order[i].TotalPayableAmount,
      TotalAmountPaid: Order[i].TotalAmountPaid,
      CouponDiscount: Order[i].CouponDiscount,
      ThierapistBookingStatus: Order[i]?.ThierapistBookingStatus,
      rejectReason: Order[i]?.rejectReason,
      OnlinePayment_Discount:
        Order[i].paymentMethod === "Pay At Spa"
          ? 0
          : ((Order[i].TotalAmount - Order[i].Discount) * 5) / 100,

      Vendor_percentage: Order[i].vendors[0]?.Vendorpercentage,
      Payment_Method: Order[i].paymentMethod,
      Admin_Discount_Percentage:
        Order[i].vendors[0]?.Vendorpercentage -
        (Order[i].paymentMethod === "Pay At Spa" ? 0 : 5),

      Total_Amount: vendorshare,
      Admin_Share: adminshare2,
      BookingStatus: Order[i].BookingStatus,
      BookingStatusForTracker:
        Order[i].BookingStatus === "Cancelled"
          ? Order[i].BookingStatus
          : Order[i]?.ThierapistBookingStatus === "Assigned"
          ? Order[i]?.ThierapistBookingStatus
          : Order[i]?.ThierapistBookingStatus === "Pending"
          ? "Not yet Assigned"
          : Order[i]?.ThierapistBookingStatus === "Accepted"
          ? Order[i]?.ThierapistBookingStatus
          : Order[i]?.ThierapistBookingStatus,
    });
  }

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (startDate && endDate) {
      const filteredData = data.filter((dtt) => {
        const bookingDate = moment(dtt.Booking_Date, "DD/MM/YYYY");
        return (
          bookingDate.isSameOrAfter(startDate, "day") &&
          bookingDate.isSameOrBefore(endDate, "day")
        );
      });
      setFilteredData(filteredData);
    }
  }, [startDate, endDate, data]);

  const offlinepaymentdata = data?.filter(
    (item) => item.Payment_Method === "Pay At Spa"
  );

  const onlinepaymentdata = data?.filter(
    (item) => item.Payment_Method === "Online Payment"
  );

  const pay20Pdata = data?.filter((item) => item.Payment_Method === "Pay 20%");

  console.log("data", data);
  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "3",
        value: 3,
      },
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data?.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "Booking_Id",
      text: "Booking Id",
      formatter: (value, row) => <p>{row?.Booking_Id}</p>,
    },
    {
      dataField: "Booking_Date",
      text: "Booking Date",
      formatter: (value, row) => <p>{row?.Booking_Date}</p>,
    },
    {
      dataField: "Category",
      text: "Category",
      formatter: (value, row) => <p>{row?.Category ? row?.Category : "-"}</p>,
    },
    {
      dataField: "Service_Name",
      text: "Service Name",
      formatter: (value, row) => (
        <p>{row?.Service_Name ? row?.Service_Name : "-"}</p>
      ),
    },
    {
      dataField: "Duration",
      text: "Duration",
      formatter: (value, row) => <p>{row?.Duration ? row?.Duration : "-"}</p>,
    },
    {
      dataField: "ValidFor",
      text: "ValidFor",
      formatter: (value, row) => <p>{row?.ValidFor ? row?.ValidFor : "-"}</p>,
    },
    {
      dataField: "date",
      text: "Slot Date",
      formatter: (value, row) => {
        return (
          <>
            <p>{row?.date}</p>
          </>
        );
      },
    },
    {
      dataField: "fromTime",
      text: "Slot From Time",
      formatter: (value, row) => {
        return (
          <>
            <p>{row?.fromTime}</p>
          </>
        );
      },
    },
    {
      dataField: "toTime",
      text: "Slot End Time",
      formatter: (value, row) => {
        return (
          <>
            <p>{row?.toTime}</p>
          </>
        );
      },
    },
    {
      dataField: "Therapist_id",
      text: "Therapist id",
      formatter: (value, row) => (
        <p>{row?.Therapist_id ? row?.Therapist_id : "-"}</p>
      ),
    },
    {
      dataField: "Assined_Therapist",
      text: "Assigned Therapist id",
      formatter: (value, row) => (
        <p>{row?.Assined_Therapist ? row?.Assined_Therapist : "-"}</p>
      ),
    },
    // {
    //   dataField: "Therapist1_id",
    //   text: "Therapist id",
    //   formatter: (value, row) => (
    //     <p>
    //       {row?.Therapist1_id && row?.ValidFor === "Couple"
    //         ? row?.Therapist1_id
    //         : "-"}
    //     </p>
    //   ),
    // },
    {
      dataField: "Customer_Name",
      text: "Customer Name",
      formatter: (value, row) => (
        <p>{row?.Customer_Name ? row?.Customer_Name : "-"}</p>
      ),
    },
    {
      dataField: "Customer_Email_ID",
      text: "Customer Email ID",
      formatter: (value, row) => (
        <p>{row?.Customer_Email_ID ? row?.Customer_Email_ID : "-"}</p>
      ),
    },
    {
      dataField: "Customer_Contact_No",
      text: "Customer Contact No.",
      formatter: (value, row) => (
        <p>{row?.Customer_Contact_No ? row?.Customer_Contact_No : "-"}</p>
      ),
    },
    {
      dataField: "Spa_Name",
      text: "Spa Name",
      formatter: (value, row) => (
        <p style={{ overflowY: "scroll", height: "100px" }}>
          {row?.Spa_Name ? row?.Spa_Name : "-"}
        </p>
      ),
    },
    {
      dataField: "Spa_Email",
      text: "Spa Email",
      formatter: (value, row) => <p>{row?.Spa_Email ? row?.Spa_Email : "-"}</p>,
    },
    {
      dataField: "Spa_Address",
      text: "Spa Address",
      formatter: (value, row) => (
        <p style={{ overflowY: "scroll", height: "100px" }}>
          {row?.Spa_Address ? row?.Spa_Address : "-"}
        </p>
      ),
    },
    {
      dataField: "Spa_Discount",
      text: "Spa Discount",
      formatter: (value, row) => (
        <p>{row?.Spa_Discount ? row?.Spa_Discount : "-"}</p>
      ),
    },
    {
      dataField: "Total_Price",
      text: "Total Price",
      formatter: (value, row) => (
        <p>{row?.Total_Price ? row?.Total_Price : "-"}</p>
      ),
    },
    {
      dataField: "CouponDiscount",
      text: "Coupon Discount",
      formatter: (value, row) => <p>{row?.CouponDiscount}</p>,
    },
    {
      dataField: "OnlinePayment_Discount",
      text: "Online Payment Discount",
      formatter: (value, row) => <p>{row?.OnlinePayment_Discount}</p>,
    },
    {
      dataField: "TotalPayableAmount",
      text: "Total Amount Should Pay",
      formatter: (value, row) => (
        <p>{row?.TotalPayableAmount ? row?.TotalPayableAmount : "-"}</p>
      ),
    },
    {
      dataField: "TotalAmountPaid",
      text: "Total Amount Paid",
      formatter: (value, row) => (
        <p>{row?.TotalAmountPaid ? row?.TotalAmountPaid : "-"}</p>
      ),
    },
    {
      dataField: "Payment_Method",
      text: "Payment Method",
      formatter: (value, row) => (
        <p
          style={{
            backgroundColor:
              row?.Payment_Method === "Pay At Spa"
                ? "#e22217"
                : row?.Payment_Method === "Online Payment"
                ? "Green"
                : "rgb(132, 207, 7)",
            color: "white",
          }}
        >
          {row?.Payment_Method}
        </p>
      ),
    },
    {
      dataField: "BookingStatusForTracker",
      text: "Booking Status",
      formatter: (value, row) => {
        console.log("row?.BookingStatus", row?.BookingStatus);

        return (
          <>
            {row?.BookingStatus === "Cancelled" ? (
              <>
                <p
                  style={{
                    backgroundColor: "#e22217",
                    color: "white",
                    marginBottom: "0px",
                  }}
                >
                  {row?.BookingStatus}
                </p>
              </>
            ) : (
              <>
                {row?.ThierapistBookingStatus === "Assigned" ? (
                  <p
                    style={{
                      backgroundColor: "#84cc07",
                      color: "white",
                    }}
                  >
                    {row?.ThierapistBookingStatus}
                  </p>
                ) : row?.ThierapistBookingStatus === "Pending" ? (
                  <p
                    style={{
                      backgroundColor: "Blue",
                      color: "white",
                    }}
                  >
                    Not yet Assigned
                  </p>
                ) : row?.ThierapistBookingStatus === "Accepted" ? (
                  <p
                    style={{
                      backgroundColor: "Green",
                      color: "white",
                    }}
                  >
                    {row?.ThierapistBookingStatus}
                  </p>
                ) : (
                  <>
                    <p
                      style={{
                        backgroundColor: "#e22217",
                        color: "white",
                        marginBottom: "0px",
                      }}
                    >
                      {row?.ThierapistBookingStatus}
                    </p>
                    <FaEye
                      style={{
                        color: "#e84141",
                        marginLeft: "30px",
                      }}
                      onClick={() => handleShow(row)}
                    />
                  </>
                )}
              </>
            )}
          </>
        );
      },
    },
    // {
    //   dataField: "",
    //   text: "View",
    //   formatter: (value, row) => (
    //     <FaEye
    //       style={{ color: "#e84141" }}
    //       // onClick={() => handleViewShow(row)}
    //     />
    //   ),
    // },
    // {
    //   dataField: "",
    //   text: "Action",
    //   formatter: (value, row) => (
    //     <RiDeleteBin2Fill
    //       // onClick={() => deleteService(row)}
    //       style={{ color: "red" }}
    //     />
    //   ),
    // },
  ];

  return (
    <div className="never-sspa">
      <section>
        <div
          className="spa-admin-0 mb-2"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="diff-admin px-3">
            <div className="admin-name-text m-3">
              <h4>Booking</h4>
            </div>
          </div>
          <div
            style={{ padding: "30px", paddingBottom: "0px", display: "flex" }}
          >
            <div style={{ margin: "10px", marginBottom: "0px" }}>
              <p style={{ marginBottom: "0px" }}>Start Date</p>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div style={{ margin: "10px" }}>
              <p style={{ marginBottom: "0px" }}>End Date</p>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={30000}
          closeOnClick
          pauseOnHover
          draggable
        />
        <div style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}>
          <ToolkitProvider
            keyField="id"
            data={
              startDate && endDate
                ? filteredData
                : paymentType === "All"
                ? data
                : paymentType === "Offline Payment"
                ? offlinepaymentdata
                : paymentType === "Online Payment"
                ? onlinepaymentdata
                : paymentType === "20% Payment"
                ? pay20Pdata
                : ""
            }
            columns={columns}
            // rowClasses={rowClasses}
            search
            exportCSV
          >
            {(props) => (
              <div>
                <SearchBar {...props.searchProps} />
                <ExportCSVButton
                  {...props.csvProps}
                  style={{
                    backgroundColor: "#e22217",
                    color: "white",
                    marginLeft: "10px",
                  }}
                >
                  Download Excel
                </ExportCSVButton>
                <button
                  style={{
                    marginLeft: "100px",
                    border: "none",
                    color: "white",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor: "Blue",
                  }}
                  onClick={() => setpaymentType("All")}
                >
                  All
                </button>
                <button
                  style={{
                    marginLeft: "30px",
                    border: "none",
                    color: "white",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor: "#e22217",
                  }}
                  onClick={() => setpaymentType("Offline Payment")}
                >
                  Offline Payment
                </button>
                <button
                  style={{
                    marginLeft: "30px",
                    border: "none",
                    color: "white",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor: "Green",
                  }}
                  onClick={() => setpaymentType("Online Payment")}
                >
                  Online Payment
                </button>

                <button
                  style={{
                    marginLeft: "30px",
                    border: "none",
                    color: "white",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor: "#84cf07",
                  }}
                  onClick={() => setpaymentType("20% Payment")}
                >
                  20% Payment
                </button>
                <hr></hr>
                <br></br>
                <div style={{ overflowX: "scroll", width: "100%" }}>
                  <BootstrapTable
                    striped
                    hover
                    condensed
                    {...props.baseProps}
                    pagination={paginationFactory(options)}
                  />
                </div>
              </div>
            )}
          </ToolkitProvider>
        </div>
        <Modal show={show} onHide={handleClose} className="cancelmodel">
          <Modal.Header closeButton style={{ fontWeight: "bold" }}>
            Reason for rejection :
          </Modal.Header>
          <Modal.Body style={{ alignItems: "center", display: "contents" }}>
            <p
              style={{ textAlign: "center", color: "red", fontWeight: "bold" }}
            >
              {viewdata?.rejectReason}
            </p>

            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "20px",
              }}
            >
              {canceldata ? (
                <div className="m-5">
                  <label style={{ color: "black", fontSize: "16px" }}>
                    Enter reason for cancelation
                  </label>
                  <br></br>
                  <textarea
                    placeholder="Enter reason for cancelation"
                    style={{ width: "450px", height: "100px" }}
                    onChange={(e) => setBookingrejectionreason(e.target.value)}
                  />
                  <Button
                    style={{ alignSelf: "center" }}
                    variant="secondary"
                    onClick={() => cancelOrder(viewdata)}
                  >
                    Send
                  </Button>
                </div>
              ) : (
                <div style={{ gap: "20px", display: "flex" }}>
                  {viewdata?.Therapist_id === "No Preference" ? (
                    ""
                  ) : (
                    // <button
                    //   style={{
                    //     backgroundColor: "Green",
                    //     padding: "6px",
                    //     border: "none",
                    //     color: "white",
                    //     borderRadius: "4px",
                    //   }}
                    //   onClick={() => reAssign(viewdata)}
                    // >
                    //   Re-assign
                    // </button>
                    <Button
                      variant="success"
                      onClick={() => reAssign(viewdata)}
                    >
                      Re-assign
                    </Button>
                  )}
                  <Button
                    variant="secondary"
                    onClick={() => setcanceldata(!canceldata)}
                  >
                    Send cancelation Mail to Customer
                  </Button>
                </div>
              )}
            </div>
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Modal>
      </section>
    </div>
  );
}

export default Booking;
