import React from "react";
import { IoLocationOutline } from "react-icons/io5";
import { Select, Space } from "antd";
import "../../Styles/header.css";
import colors from ".././Constant/Color";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Vendorheader() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const user = JSON.parse(sessionStorage.getItem("user"));

  const Vendorsignout = () => {
    try {
      axios
        .get("https://spazo.co.in/api/vendor/Vendorsignout/" + user._id)
        .then(function (res) {
          if (res.status == 200) {
            sessionStorage.removeItem("user");
            // alert("Signout Success....!");
            notify("Signout Success....!");
            window.location.assign("/");
            return;
          } else {
            // alert("Signout Unsuccessfully");
            notify1("Signout Unsuccessfully");
            return;
          }
        });
    } catch (error) {
      console.warn(error);
      // alert("Signout Unsuccessfully");
      notify1("Signout Unsuccessfully");
    }
  };
  return (
    <>
      <div
        className=""
        style={{ position: "relative", borderBottom: "1px solid #e0e0e0" }}
      >
        <div
          className=""
          style={{
            backgroundColor: `${colors.header}`,
            // backgroundColor: "white",
            // position: "fixed",
            width: "100%",
            zIndex: "999",
            borderBottom: "1px solid rgb(226, 34, 23)",
          }}
        >
          <ToastContainer
            position="top-center"
            autoClose={30000}
            closeOnClick
            pauseOnHover
            draggable
          />
          <div className="top-h p-2">
            <div className="spa">
              <div className="city d-flex">
                <div className="lo-2 d-flex">
                  <div
                    className="mt-1"
                    style={{
                      // color: "rgb(226, 34, 23)",
                      color: "white",
                      fontWeight: 700,
                      fontSize: "20px",
                    }}
                  >
                    Vendor Panel
                  </div>
                </div>
              </div>
              <div className="righ-f d-flex">
                <div className="business px-3">
                  <div className="log-dpa">
                    <a
                      style={{
                        // color: "rgb(226, 34, 23)",
                        color: "white",
                        fontWeight: 700,
                        fontSize: "20px",
                      }}
                      onClick={Vendorsignout}
                    >
                      Logout
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Vendorheader;
