import React from "react";
import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

import { styled } from "@mui/material/styles";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Updateprofile1() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const Input = styled("input")({
    display: "none",
  });

  const user = JSON.parse(sessionStorage.getItem("user"));
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [noofEmp, setnoofEmp] = useState();
  const [starttime, setstarttime] = useState();
  const [endtime, setendtime] = useState();
  const [basicprice, setbasicprice] = useState();
  const [servicetax, setservicetax] = useState();
  const [GST, setGST] = useState(0);
  const [mindiscount, setmindiscount] = useState();
  const [parking, setparking] = useState("");

  const [sun, setsun] = useState(false);
  const [mon, setmon] = useState(false);
  const [tue, settue] = useState(false);
  const [wed, setwed] = useState(false);
  const [turs, setturs] = useState(false);
  const [frid, setfrid] = useState(false);
  const [sat, setsat] = useState(false);

  const formatTimeTo12hr = (time) => {
    const [hours, minutes] = time?.split(":");
    const parsedHours = parseInt(hours, 10);

    // Determine AM or PM
    const period = parsedHours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    const displayHours = parsedHours % 12 || 12;

    // Combine the formatted time
    const formattedTime = `${displayHours}:${minutes} ${period}`;
    return formattedTime;
  };

  const formattedStartTime = starttime ? formatTimeTo12hr(starttime) : "";
  const formattedEndTime = endtime ? formatTimeTo12hr(endtime) : "";

  const update2 = async () => {
    if (!sun && !mon && !tue && !wed && !turs && !frid && !sat) {
      notify2("Please Select working days");
    } else if (!formattedStartTime) {
      notify2("Please Select Start Time");
    } else if (!formattedEndTime) {
      notify2("Please Select End Time");
    } else if (!parking) {
      notify2("Please Select Parking type");
    } else {
      try {
        const config = {
          url: "/vendorupdate2",
          method: "post",
          baseURL: "https://spazo.co.in/api/vendor",
          data: {
            userId: user._id,
            // noofEmp: noofEmp,
            sun: sun,
            mon: mon,
            tue: tue,
            wed: wed,
            turs: turs,
            frid: frid,
            sat: sat,
            formattedStartTime: formattedStartTime,
            formattedEndTime: formattedEndTime,
            // basicprice: basicprice,
            // servicetax: servicetax,
            // GSTP: GST,
            // mindiscount: mindiscount,
            parking: parking,
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            // alert("Data Added successfully");
            notify("Data Added successfully");
            sessionStorage.setItem("user", JSON.stringify(res.data.user));
            window.location.assign("updateprofile2");
          } else {
            // alert(res.status.error);
            notify1(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        // alert("Unable to add Details");
        notify1("Unable to add Details, Please try after some time");
      }
    }
  };

  console.log("sdcsd", sun, mon, tue, wed, turs, frid, sat);
  return (
    <>
      <div
        style={{
          // backgroundColor: "#ffda77",
          // height: "100vh",
          paddingTop: "8%",
          paddingBottom: "8%",
        }}
      >
        <ToastContainer
          position="top-center"
          autoClose={30000}
          closeOnClick
          pauseOnHover
          draggable
        />
        <div className="admin-name-text">
          <h4 style={{ textDecoration: "underline", textAlign: "center" }}>
            Update Your Profile Details
          </h4>
        </div>
        <div className="mt-4 updateprofile">
          {/* <Row>
            <Col md={4}>Number of Employee working</Col>
            <Col md={8}>
              <input
                type="number"
                placeholder="No.of Employee working"
                className="upbtn"
                onChange={(e) => setnoofEmp(e.target.value)}
              />
            </Col>
          </Row> */}
          <Row className="mt-3">
            <Col md={4}>Select working days</Col>
            <Col md={8}>
              <p style={{ marginBottom: "0px" }}>
                {sun ? "sun, " : ""}
                {mon ? "mon, " : ""}
                {tue ? "tue, " : ""}
                {wed ? "wed, " : ""}
                {turs ? "turs, " : ""}
                {frid ? "frid, " : ""}
                {sat ? "sat" : ""}
              </p>
              <button className="upbtn" onClick={handleShow}>
                Select
              </button>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4}>Start Time</Col>
            <Col md={8}>
              <input
                type="time"
                className="upbtn"
                onChange={(e) => setstarttime(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4}>End Time</Col>
            <Col md={8}>
              <input
                type="time"
                className="upbtn"
                onChange={(e) => setendtime(e.target.value)}
              />
            </Col>
          </Row>
          {/* <Row className="mt-3">
            <Col md={4}>Basic Price</Col>
            <Col md={8}>
              <input
                type="Number"
                className="upbtn"
                onChange={(e) => setbasicprice(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4}>GST (%)</Col>
            <Col md={8}>
              <input
                type="Number"
                className="upbtn"
                onChange={(e) => setGST(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4}>Service Tax(%)</Col>
            <Col md={8}>
              <input
                type="Number"
                className="upbtn"
                onChange={(e) => setservicetax(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4}>Min Discount (%)</Col>
            <Col md={8}>
              <input
                type="Number"
                className="upbtn"
                onChange={(e) => setmindiscount(e.target.value)}
              />
            </Col>
          </Row> */}
          <Row className="mt-3">
            <Col md={4}>Parking</Col>
            <Col md={8}>
              <select
                onChange={(e) => setparking(e.target.value)}
                className="upselect"
              >
                <option>Select</option>
                <option value="2-Wheeler">2-Wheeler</option>
                <option value="4-Wheeler">4-Wheeler</option>
                <option value="Both">Both</option>
                <option value="No Parking">No Parking</option>
              </select>
            </Col>
          </Row>

          <Button variant="secondary" className="savbtn" onClick={update2}>
            <i class="fas fa-save"></i>&nbsp;Save & Continue
          </Button>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select working days</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              type="checkbox"
              id="Sunday"
              checked={sun}
              onChange={(e) => setsun(!sun)}
            />
            &nbsp;
            <label for="Sunday">Sunday</label>
            <br></br>
            <input
              type="checkbox"
              id="monday"
              checked={mon}
              onChange={(e) => setmon(!mon)}
            />{" "}
            &nbsp;
            <label for="monday">Monday</label>
            <br></br>
            <input
              type="checkbox"
              id="Tuesday"
              checked={tue}
              onChange={(e) => settue(!tue)}
            />{" "}
            &nbsp;
            <label for="Tuesday">Tuesday</label>
            <br></br>
            <input
              type="checkbox"
              id="Wednesday"
              checked={wed}
              onChange={(e) => setwed(!wed)}
            />{" "}
            &nbsp;
            <label for="Wednesday">Wednesday</label>
            <br></br>
            <input
              type="checkbox"
              id="Thursday"
              checked={turs}
              onChange={(e) => setturs(!turs)}
            />{" "}
            &nbsp;
            <label for="Thursday">Thursday</label>
            <br></br>
            <input
              type="checkbox"
              id="Friday"
              checked={frid}
              onChange={(e) => setfrid(!frid)}
            />{" "}
            &nbsp;
            <label for="Friday">Friday</label>
            <br></br>
            <input
              type="checkbox"
              id="Saturday"
              checked={sat}
              onChange={(e) => setsat(!sat)}
            />{" "}
            &nbsp;
            <label for="Saturday">Saturday </label>
            <br></br>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Updateprofile1;
