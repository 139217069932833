import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ReactStars from "react-rating-stars-component";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { FaRegEdit } from "react-icons/fa";
import { AiFillCheckCircle, AiFillStar } from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { Col, Row } from "react-bootstrap";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RiDeleteBin5Fill } from "react-icons/ri";

function SalonDeleteTherapist() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);

  const [data, setdata] = useState([]);

  const [show, setShow] = useState(false);

  const [approvedata, setapprovedata] = useState({});
  const [rejectdata, setrejectdata] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setShow(true);
    setapprovedata(item);
  };

  const [showrevive, setShowrevive] = useState(false);
  const [reviwe, setreviwe] = useState({});

  const handleCloserevive = () => setShowrevive(false);
  const handleShowrevive = (item) => {
    setShowrevive(true);
    setreviwe(item);
  };

  useEffect(() => {
    getDeletedEmployee();
  }, []);

  const getDeletedEmployee = () => {
    axios
      .get("https://spazo.co.in/api/Vendor/getDeletedEmployee")
      .then(function (response) {
        console.log("allDeletedEmployee", response.data.allDeletedEmployee);

        setdata(
          response.data.allDeletedEmployee?.filter(
            (item) =>
              item?.therapist[0]?.vendor[item.therapist[0]?.vendor?.length - 1]
                ?.Type === "Salon"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteEmployee = async (data) => {
    console.log("hgjg", data?.TherapistId);

    try {
      const config = {
        url: "/DeleteEmployee/" + data?.TherapistId + "/" + data?.id,
        method: "post",
        baseURL: "https://spazo.co.in/api/Vendor",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          notify("Data deleted successfully");
          window.location.reload();
        } else {
          // alert(res.status.error);
          notify1(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      // alert("Unable to add Details");
      notify1("Unable to delete Details, Please try after some time");
    }
  };

  const newdata = [];

  for (let i = 0; i < data?.length; i++) {
    newdata.push({
      id: data[i]?._id,
      profile: data[i]?.therapist[0]?.profile,
      TherapistId: data[i]?.TherapistId,
      VendorId: data[i]?.VendorId,
      EmpId: data[i]?.therapist[0]?.EmpId,
      name: data[i]?.therapist[0]?.name,
      Email: data[i]?.therapist[0]?.Email,
      contactnumber: data[i]?.therapist[0]?.contactnumber,
      Specialization: data[i]?.therapist[0]?.Specialization,
      Gender: data[i]?.therapist[0]?.Gender,
      Status: data[i]?.therapist[0]?.Status,
      VendorName:
        data[i]?.therapist[0]?.vendor[data[i]?.therapist[0]?.vendor?.length - 1]
          ?.Vendor_Name,
      VendorBusinessName:
        data[i]?.therapist[0]?.vendor[data[i]?.therapist[0]?.vendor?.length - 1]
          ?.Spa_Name,
      VendorContactNumber:
        data[i]?.therapist[0]?.vendor[data[i]?.therapist[0]?.vendor?.length - 1]
          ?.Spa_Contact_Number,
      VendorEmailId:
        data[i]?.therapist[0]?.vendor[data[i]?.therapist[0]?.vendor?.length - 1]
          ?.SPa_Email_ID,
      therapistreviews: data[i]?.therapist[0]?.therapistreviews,
      Admin_Delete_Approval: data[i]?.Status,
      ReasonForDelete: data[i]?.ReasonForDelete,
    });
  }

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "3",
        value: 3,
      },
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: newdata.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },

    {
      dataField: "Image",
      text: "Emp Profile",
      formatter: (value, row) => (
        <img
          src={"https://spazo.co.in/Employee/" + row?.profile}
          width={100}
          height={80}
          onClick={() =>
            window.open("https://spazo.co.in/Employee/" + row.profile)
          }
        />
      ),
    },
    {
      dataField: "EmpId",
      text: "EmpId",
      formatter: (value, row) => <p>{row?.EmpId ? row?.EmpId : "-"}</p>,
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (value, row) => {
        const rev = row?.therapistreviews?.reduce((a, b) => a + b.rating, 0);
        return (
          <>
            <p>{row?.name ? row?.name : "-"}</p>
            <div style={{ display: "flex" }}>
              <AiFillStar color="Gold" style={{ marginTop: "7px" }} /> :
              <p style={{ marginBottom: "0px", fontWeight: "bold" }}>
                {rev?.toFixed(1)}
              </p>
            </div>
            <button
              style={{ border: "none" }}
              onClick={() => handleShowrevive(row)}
            >
              <i
                class="fa fa-eye"
                aria-hidden="true"
                style={{ color: "#e22217" }}
              ></i>
            </button>
          </>
        );
      },
    },

    {
      dataField: "Email",
      text: "Email",
      formatter: (value, row) => {
        return <p>{row?.Email ? row?.Email : "-"}</p>;
      },
    },
    {
      dataField: "contactnumber",
      text: "Contact Number",
      formatter: (value, row) => (
        <p>{row?.contactnumber ? row?.contactnumber : "-"}</p>
      ),
    },

    {
      dataField: "VendorBusinessName",
      text: "Spa Name",
      formatter: (value, row) => (
        <p>{row?.VendorBusinessName ? row?.VendorBusinessName : "-"}</p>
      ),
    },
    {
      dataField: "VendorName",
      text: "Vendor Name",
      formatter: (value, row) => (
        <p>{row?.VendorName ? row?.VendorName : "-"}</p>
      ),
    },
    {
      dataField: "VendorContactNumber",
      text: "Spa Contact Number",
      formatter: (value, row) => (
        <p>{row?.VendorContactNumber ? row?.VendorContactNumber : "-"}</p>
      ),
    },
    {
      dataField: "VendorEmailId",
      text: "Spa Email ID",
      formatter: (value, row) => (
        <p>{row?.VendorEmailId ? row?.VendorEmailId : "-"}</p>
      ),
    },
    {
      dataField: "ReasonForDelete",
      text: "Reason",
      formatter: (value, row) => (
        <p>{row?.ReasonForDelete ? row?.ReasonForDelete : "-"}</p>
      ),
    },
    {
      dataField: "-",
      text: "Action",
      formatter: (value, row) => (
        <>
          {row.Admin_Delete_Approval === "Deleted" ? (
            <p>Deleted</p>
          ) : (
            <button
              style={{ backgroundColor: "transparent", border: "none" }}
              onClick={() => handleShow(row)}
            >
              <RiDeleteBin5Fill
                style={{ color: "rgb(226, 34, 23)", fontSize: "25px" }}
              />
            </button>
          )}
        </>
      ),
    },
  ];

  console.log("newdata", newdata);

  return (
    <>
      <div className="never-sspa">
        <section>
          <div className="spa-admin-0 mb-2">
            <div className="diff-admin px-3">
              <div className="admin-name-text">
                <h4>Request For Therapist Delete</h4>
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={30000}
            closeOnClick
            pauseOnHover
            draggable
          />
          <div
            style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}
          >
            <ToolkitProvider
              keyField="id"
              data={newdata}
              columns={columns}
              // rowClasses={rowClasses}
              search
              exportCSV
            >
              {(props) => (
                <div>
                  <SearchBar {...props.searchProps} />
                  <ExportCSVButton
                    {...props.csvProps}
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                    }}
                  >
                    Download Excel
                  </ExportCSVButton>

                  <hr></hr>
                  <br></br>

                  <div style={{ overflowX: "scroll", width: "100%" }}>
                    <BootstrapTable
                      striped
                      hover
                      condensed
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </section>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ alignItems: "center", display: "contents" }}>
          <p style={{ textAlign: "center" }}>
            Are you sure you want to Delete the Therapist{" "}
          </p>
          <Button
            variant="secondary"
            onClick={() => deleteEmployee(approvedata)}
          >
            Yes
          </Button>
        </Modal.Body>
      </Modal>

      <Modal show={showrevive} onHide={handleCloserevive}>
        <Modal.Header closeButton>
          <Modal.Title> Therapist Reviews - {reviwe?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {reviwe?.therapistreviews?.map((rev) => (
              <>
                <div
                  style={{
                    padding: "10px",
                    borderBottom: "1px solid gray",
                  }}
                >
                  <h6>{rev?.Username}</h6>
                  <p style={{ marginBottom: "0px" }}>{rev?.review}</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <ReactStars
                      count={5}
                      value={rev?.rating}
                      size={20}
                      activeColor="#ffd700"
                    />
                    <p
                      style={{
                        textAlign: "right",
                        color: "#e84141",
                        fontWeight: 600,
                      }}
                    >
                      {moment(rev?.createdAt).format("DD-MM-YYYY")}
                    </p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloserevive}>Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SalonDeleteTherapist;
