import React from "react";
import SideBar from "./SideBar";
import AdminHeader from "./AdminHeader";
import "./admin.css";

function Dashboard(props) {
  const admin = JSON.parse(sessionStorage.getItem("admin"));

  return (
    <>
      {admin ? (
        <div className="dash">
          <div className="row me-0">
            <div className="col-md-2 p-0">
              <div className="left-side">
                <SideBar />
              </div>
            </div>

            <div className="col-md-10 p-0 right-h ">
              <AdminHeader />
              {props.children}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Dashboard;
