import React from "react";
import Button from "react-bootstrap/Button";

function VHome() {
  const user = JSON.parse(sessionStorage.getItem("user"));

  return (
    <>
      <div
        style={{
          display: "grid",
          width: "100vw",
          height: "100vh",
          // backgroundColor: "rgb(226, 34, 23)",
          backgroundImage: `url(
            "https://cdn.pixabay.com/photo/2018/09/19/10/35/wellness-3688169_1280.jpg"
          )`,
        }}
      >
        {user?.approved === "Rejected" ? (
          <marquee
            width="100%"
            direction="left"
            height="100px"
            style={{
              textAlign: "center",
              fontSize: "20px",
              marginTop: "2%",
              color: "white",
            }}
          >
            Your Profile has been rejected due to :{" "}
            <span style={{ color: "rgb(226, 34, 23)" }}>
              "{user?.reasonFR}",
            </span>{" "}
            Kindly update your profile once again.
          </marquee>
        ) : (
          ""
        )}
        {/* <img src="/rose1.png" /> */}
        <div className="vhome">
          <Button
            variant="secondary"
            onClick={() => window.location.assign("myprofile")}
            style={{
              color: "#e22217",
              backgroundColor: "white",
              height: "50px",
              fontWeight: "bold",
            }}
          >
            My Profile
          </Button>
          <Button
            variant="light"
            onClick={() => window.location.assign("updateprofile")}
            style={{
              color: "white",
              backgroundColor: "#1ab64f",
              height: "50px",
              fontWeight: "bold",
              border: "none",
            }}
          >
            Update Profile
          </Button>
        </div>
        {/* <img
          src="/rose1.png"
          style={{ alignSelf: "flex-end", right: 0, position: "absolute" }}
        /> */}
      </div>
    </>
  );
}

export default VHome;
