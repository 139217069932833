import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Banner2() {
  const formdata = new FormData();
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);

  const [show, setShow] = useState(false);
  const [editdata, seteditdata] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    seteditdata(item);
  };

  const [title, settitle] = useState("");
  const [subtitle, setsubtitle] = useState("");
  const [bannerimg, setbannerimg] = useState();
  const [bannertype, setbannertype] = useState();

  const [title1, settitle1] = useState("");
  const [subtitle1, setsubtitle1] = useState("");
  const [bannerimg1, setbannerimg1] = useState();

  const [data, setdata] = useState([]);

  console.log("title", data);

  useEffect(() => {
    getallBanners();
  }, []);

  const addbanner = async () => {
    if (!title || !subtitle || !bannerimg || !bannertype) {
      alert("Please fill all the fields");
    } else {
      formdata.append("title", title);
      formdata.append("subtitle", subtitle);
      formdata.append("bannerimg", bannerimg);
      formdata.append("bannertype", bannertype);
      try {
        const config = {
          url: "/postBanner2",
          method: "post",
          baseURL: "https://spazo.co.in/api/admin",
          data: formdata,
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            notify("Data Added successfully");
            // alert("Data Added successfully");
            // window.location.reload();
            getallBanners();
            handleClose();
          } else {
            notify1(res.status.error);
            // alert(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        notify1("Unable to Delete Details, Please try after some time");
        // alert("Unable to add Details");
      }
    }
  };

  const getallBanners = () => {
    axios
      .get("https://spazo.co.in/api/admin/getBanner2")
      .then(function (response) {
        console.log(response.data);
        setdata(response.data.bannerList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editbanner = async () => {
    formdata.append("title", title1 ? title1 : editdata?.title);
    formdata.append("subtitle", subtitle1 ? subtitle1 : editdata?.subtitle);
    formdata.append("bannerimg", bannerimg1 ? bannerimg1 : editdata?.bannerimg);
    try {
      const config = {
        url: "/editBanner2/" + editdata?._id,
        method: "put",
        baseURL: "https://spazo.co.in/api/admin",
        data: formdata,
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          notify("Data Updated successfully");
          // alert("Data Added successfully");
          // window.location.reload();
          getallBanners();
          handleClose1();
        } else {
          notify1(res.status.error);
          // alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      notify1("Unable to Delete Details, Please try after some time");
      // alert("Unable to add Details");
    }
  };

  const deletebanner = async (data) => {
    try {
      const config = {
        url: "/deleteBanner2/" + data?._id,
        method: "delete",
        baseURL: "https://spazo.co.in/api/admin",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          notify("Data deleted successfully");
          // alert("Data deleted successfully");
          // window.location.reload();
          getallBanners();
        } else {
          notify1(res.status.error);
          // alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      notify1("Unable to Delete Details, Please try after some time");
      // alert("Unable to add Details");
    }
  };

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "3",
        value: 3,
      },
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "Image",
      text: "Image",
      formatter: (value, row) => (
        <img
          src={"https://spazo.co.in/Banner2/" + row.bannerimg}
          width={100}
          height={80}
          onClick={() =>
            window.open("https://spazo.co.in/Banner2/" + row.bannerimg)
          }
        />
      ),
    },
    {
      dataField: "bannertype",
      text: "Type",
      formatter: (value, row) => (
        <p>{row?.bannertype ? row?.bannertype : "-"}</p>
      ),
    },
    {
      dataField: "title",
      text: "Title",
      formatter: (value, row) => <p>{row?.title ? row?.title : "-"}</p>,
    },
    {
      dataField: "subtitle",
      text: "Sub-Title",
      formatter: (value, row) => <p>{row?.subtitle ? row?.subtitle : ""}</p>,
    },
    {
      dataField: "type",
      text: "Action",
      formatter: (value, row) => (
        <div
          style={{
            display: "flex",
            gap: "21px",
          }}
        >
          <span className="dite">
            <FaRegEdit onClick={() => handleShow1(row)} />
          </span>
          <span className="dite">
            <RiDeleteBin2Fill onClick={() => deletebanner(row)} />
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="never-sspa">
        <section>
          <div className="spa-admin-0 mb-2">
            <div className="diff-admin px-3">
              <div className="admin-name-text">
                <h4>Banner-3</h4>
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={30000}
            closeOnClick
            pauseOnHover
            draggable
          />
          <div
            style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}
          >
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              // rowClasses={rowClasses}
              search
              exportCSV
            >
              {(props) => (
                <div>
                  <SearchBar {...props.searchProps} />
                  <ExportCSVButton
                    {...props.csvProps}
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                    }}
                  >
                    Download Excel
                  </ExportCSVButton>
                  <button
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                      border: "none",
                      padding: "6px",
                      borderRadius: "5px",
                      marginLeft: "467px",
                    }}
                    onClick={handleShow}
                  >
                    +Add
                  </button>
                  <hr></hr>
                  <br></br>
                  <div style={{ overflowX: "scroll", width: "100%" }}>
                    <BootstrapTable
                      striped
                      hover
                      condensed
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>

          {/* <div className="spa-table px-3">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S.N.</th>
                  <th>Images</th>
                  <th>Title</th>
                  <th>Sub-Title</th>
                  <th style={{ width: "100px" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <img
                        src={"https://spazo.co.in/Banner/" + item.bannerimg}
                        width={100}
                        height={80}
                      />
                    </td>
                    <td>{item.title}</td>
                    <td>{item.subtitle}</td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          gap: "21px",
                        }}
                      >
                        <span className="dite">
                          <FaRegEdit onClick={() => handleShow1(item)} />
                        </span>
                        <span className="dite">
                          <RiDeleteBin2Fill
                            onClick={() => deletebanner(item)}
                          />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div style={{ display: "flex" }}>
            <p style={{ width: "100%", marginTop: "20px" }}>
              Total Count: {data.length}
            </p>
            <ReactPaginate
              previousLabel={"Back"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div> */}
        </section>
      </div>

      {/* modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-spa">
            <div className="differ-spa mb-2">
              <label>Type</label>
              <br />
              <select
                onChange={(e) => setbannertype(e.target.value)}
                style={{ width: "80%", padding: "10px", borderRadius: "4px" }}
              >
                <option>Select</option>
                <option value="Spa">Spa</option>
                <option value="Salon">Salon</option>
                <option value="Beauty Care Products">
                  Beauty Care Products
                </option>
              </select>
            </div>
            <div className="differ-spa mb-2">
              <label>Title</label>
              <br />
              <input
                type="text"
                placeholder="Title"
                onChange={(e) => settitle(e.target.value)}
              />
            </div>
            <div className="differ-spa mb-2">
              <label>Sub-Title</label>
              <br />
              <input
                type="text"
                placeholder="Sub-Title"
                onChange={(e) => setsubtitle(e.target.value)}
              />
            </div>
            <div className="differ-spa mb-2">
              <label>Image</label>
              <br />
              <input
                type="file"
                accept="image/jpeg, image/png"
                onChange={(e) => setbannerimg(e.target.files[0])}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addbanner}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-spa">
            <div className="differ-spa mb-2">
              <label>Title</label>
              <br />
              <input
                type="text"
                placeholder={editdata.title}
                onChange={(e) => settitle1(e.target.value)}
              />
            </div>
            <div className="differ-spa mb-2">
              <label>Sub-Title</label>
              <br />
              <input
                type="text"
                placeholder={editdata.subtitle}
                onChange={(e) => setsubtitle1(e.target.value)}
              />
            </div>
            <div className="differ-spa mb-2">
              <label>Image</label>
              <br />
              <input
                type="file"
                accept="image/jpeg, image/png"
                onChange={(e) => setbannerimg1(e.target.files[0])}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={editbanner}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Banner2;
