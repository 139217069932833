import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Row } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";

function Promocode() {
  const formdata = new FormData();
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const [show, setShow] = useState(false);
  const [editdata, seteditdata] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    seteditdata(item);
  };

  const [show2, setShow2] = useState(false);
  const [deletedata, setdeletedata] = useState({});

  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setdeletedata(item);
  };

  const [data, setdata] = useState([]);

  const [promocodeType, setpromocodeType] = useState("");
  const [Promocode, setPromocode] = useState("");
  const [amount, setamount] = useState();
  const [Startdate, setStartdate] = useState("");
  const [Enddate, setEnddate] = useState("");
  const [TandC, setTandC] = useState("");

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setTandC(data);
  };

  const addPromocode = async () => {
    if (
      !promocodeType ||
      !Promocode ||
      !amount ||
      !Startdate ||
      !Enddate
      // ||
      // !TandC
    ) {
      alert("Please fill all the fields");
    } else {
      try {
        const config = {
          url: "/AddPromocode",
          method: "post",
          baseURL: "https://spazo.co.in/api/admin",
          data: {
            promocodeType: promocodeType,
            Promocode: Promocode,
            amount: amount,
            Startdate: Startdate,
            Enddate: Enddate,
            // TandC: TandC,
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            notify("Data Added successfully");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            notify1(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        notify1("Unable to add Details, Please try after some time");
      }
    }
  };

  useEffect(() => {
    getPromocode();
  }, []);

  const getPromocode = () => {
    axios
      .get("https://spazo.co.in/api/admin/getPromocode")
      .then(function (response) {
        console.log(response.data);
        setdata(response.data.PromocodeList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deletePromocode = async () => {
    try {
      const config = {
        url: "/deletePromocode/" + deletedata?._id,
        method: "post",
        baseURL: "https://spazo.co.in/api/admin",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          notify("Data deleted successfully");
          getPromocode();
          // alert("Data deleted successfully");
          window.location.reload();
        } else {
          notify1(res.status.error);
          // alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      notify1("Unable to delete Details, Please try after some time");
      // alert("Unable to add Details");
    }
  };

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "3",
        value: 3,
      },
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "promocodeType",
      text: "Promocode Type",
      formatter: (value, row) => (
        <p>{row?.promocodeType ? row?.promocodeType : "-"}</p>
      ),
    },
    {
      dataField: "Promocode",
      text: "Promo code",
      formatter: (value, row) => <p>{row?.Promocode ? row?.Promocode : "-"}</p>,
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: (value, row) => <p>{row?.amount ? row?.amount : "-"}</p>,
    },
    {
      dataField: "Startdate",
      text: "Start date",
      formatter: (value, row) => <p>{row?.Startdate ? row?.Startdate : "-"}</p>,
    },
    {
      dataField: "Enddate",
      text: "End date",
      formatter: (value, row) => <p>{row?.Enddate ? row?.Enddate : "-"}</p>,
    },

    {
      dataField: "TandC",
      text: "TandC",
      formatter: (value, row) => (
        <p style={{ overflowY: "scroll", height: "150px" }}>
          {row?.TandC ? parse(row?.TandC) : "-"}
        </p>
      ),
    },
    {
      dataField: "Status",
      text: "Status",
      formatter: (value, row) => {
        const today = new Date(); // Get today's date
        const endDate = new Date(row?.Enddate); // Convert Enddate to a Date object

        // Set the time of both dates to 00:00:00 to only compare the date part
        today.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);

        const status = endDate < today ? "Expired" : "Valid";
        return (
          <p
            style={{
              backgroundColor: status === "Valid" ? "Green" : "Red",
              color: "white",
              textAlign: "center",
            }}
          >
            {status}
          </p>
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      formatter: (value, row) => (
        <div
          style={{
            display: "flex",
            gap: "21px",
          }}
        >
          {/* <span className="dite">
            <FaRegEdit onClick={() => handleShow1(row)} />
          </span> */}
          <span className="dite">
            <RiDeleteBin2Fill onClick={() => handleShow2(row)} />
          </span>
        </div>
      ),
    },
  ];

  const editorConfiguration = {
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "bulletedList",
      "numberedList",
      "|",
      "link",
      "|",
      "undo",
      "redo",
    ],
    removePlugins: ["ImageUpload"],
  };

  return (
    <>
      <div className="never-sspa">
        <section>
          <div className="spa-admin-0 mb-2">
            <div className="diff-admin px-3">
              <div className="admin-name-text">
                <h4>Promo Code</h4>
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={30000}
            closeOnClick
            pauseOnHover
            draggable
          />
          <div
            style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}
          >
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              // rowClasses={rowClasses}
              search
              exportCSV
            >
              {(props) => (
                <div>
                  <SearchBar {...props.searchProps} />
                  {/* <ExportCSVButton
                    {...props.csvProps}
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                    }}
                  >
                    Download Excel
                  </ExportCSVButton> */}
                  <button
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                      border: "none",
                      padding: "6px",
                      borderRadius: "5px",
                      marginLeft: "467px",
                    }}
                    onClick={handleShow}
                  >
                    +Add
                  </button>
                  <hr></hr>
                  <br></br>
                  <div style={{ overflowX: "scroll", width: "100%" }}>
                    <BootstrapTable
                      striped
                      hover
                      condensed
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </section>
      </div>

      {/* modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Promocode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col md={6}>
                <label>Promocode For *</label>
              </Col>
              <Col md={6}>
                <select onChange={(e) => setpromocodeType(e.target.value)}>
                  <option>Select</option>
                  <option value="Spa">Spa</option>
                  <option value="Salon">Salon</option>
                  <option value="Beauty Care Products">
                    Beauty Care Products
                  </option>
                </select>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <label>Promocode *</label>
              </Col>
              <Col md={6}>
                <input
                  placeholder="Promocode"
                  onChange={(e) => setPromocode(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={6}>
                <label>Amount *</label>
              </Col>
              <Col md={6}>
                <input
                  placeholder="Amount"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => setamount(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={6}>
                <label>Start Date *</label>
              </Col>
              <Col md={6}>
                <input
                  placeholder="Start Date"
                  type="date"
                  style={{ width: "81%" }}
                  onChange={(e) => setStartdate(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={6}>
                <label>End Date *</label>
              </Col>
              <Col md={6}>
                <input
                  placeholder="End Date"
                  type="date"
                  style={{ width: "81%" }}
                  onChange={(e) => setEnddate(e.target.value)}
                />
              </Col>
            </Row>
            {/* <Row className="mt-2">
              <label>Terms & Conditions *</label>
            </Row>
            <Row className="mt-2">
              <CKEditor
                editor={ClassicEditor}
                data={TandC}
                editorConfig={editorConfiguration}
                onChange={handleEditorChange}
              />
            </Row> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addPromocode}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary">Save Changes</Button>
        </Modal.Footer>
      </Modal>

      {/* Delete */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Promo Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure, you want to delete data</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={deletePromocode}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Promocode;
