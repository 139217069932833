import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { AiOutlineLeft } from "react-icons/ai";
import { BiCalendar } from "react-icons/bi";
import {
  BsFillFilePersonFill,
  BsFillPersonFill,
  BsShieldCheck,
} from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import { isAfter, isBefore, isToday, addDays } from "date-fns";
import moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-horizontal-datepicker";
import { format } from "date-fns-tz";

function Selectdate() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const location = useLocation();
  const { item, service, Therapist, type } = location.state;
  const user = JSON.parse(sessionStorage.getItem("user"));

  console.log("item", item);

  const price = service?.Price;
  const GST = (price * service?.GST) / 100;
  const ServiceTax = (price * service?.servicetax) / 100;
  const Discount = (price * service?.Discount) / 100;
  const TotalAmount = price + GST + ServiceTax;

  const [Order, setOrder] = useState([]);
  const [selecteddate, setselecteddate] = useState(new Date());
  const [pickuptime, setpickuptime] = useState();

  const selectedDay = (val) => {
    setselecteddate(val);
  };

  useEffect(() => {
    getVendorOrder();
  }, []);

  const getVendorOrder = () => {
    if (type === "New") {
      axios
        .get("https://spazo.co.in/api/customer/getVendorOrder/" + item._id)
        .then(function (response) {
          setOrder(response.data.Order);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (type === "brand") {
      axios
        .get(
          "https://spazo.co.in/api/customer/getVendorOrder/" +
            item?.vendors[0]?._id
        )
        .then(function (response) {
          setOrder(response.data.Order);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  // const alldate = [
  //   { id: 0, time: "00:00" },
  //   { id: 15, time: "00:15" },
  //   { id: 30, time: "00:30" },
  //   { id: 45, time: "00:45" },
  //   { id: 100, time: "01:00" },
  //   { id: 115, time: "01:15" },
  //   { id: 130, time: "01:30" },
  //   { id: 145, time: "01:45" },
  //   { id: 200, time: "02:00" },
  //   { id: 215, time: "02:15" },
  //   { id: 230, time: "02:30" },
  //   { id: 245, time: "02:45" },
  //   { id: 300, time: "03:00" },
  //   { id: 315, time: "03:15" },
  //   { id: 330, time: "03:30" },
  //   { id: 345, time: "03:45" },
  //   { id: 400, time: "04:00" },
  //   { id: 415, time: "04:15" },
  //   { id: 430, time: "04:30" },
  //   { id: 445, time: "04:45" },
  //   { id: 500, time: "05:00" },
  //   { id: 515, time: "05:15" },
  //   { id: 530, time: "05:30" },
  //   { id: 545, time: "05:45" },
  //   { id: 600, time: "06:00" },
  //   { id: 615, time: "06:15" },
  //   { id: 630, time: "06:30" },
  //   { id: 645, time: "06:45" },
  //   { id: 700, time: "07:00" },
  //   { id: 715, time: "07:15" },
  //   { id: 730, time: "07:30" },
  //   { id: 745, time: "07:45" },
  //   { id: 800, time: "08:00" },
  //   { id: 815, time: "08:15" },
  //   { id: 830, time: "08:30" },
  //   { id: 845, time: "08:45" },
  //   { id: 900, time: "09:00" },
  //   { id: 915, time: "09:15" },
  //   { id: 930, time: "09:30" },
  //   { id: 945, time: "09:45" },
  //   { id: 1000, time: "10:00" },
  //   { id: 1015, time: "10:15" },
  //   { id: 1030, time: "10:30" },
  //   { id: 1045, time: "10:45" },
  //   { id: 1100, time: "11:00" },
  //   { id: 1115, time: "11:15" },
  //   { id: 1130, time: "11:30" },
  //   { id: 1145, time: "11:45" },
  //   { id: 1200, time: "12:00" },
  //   { id: 1215, time: "12:15" },
  //   { id: 1230, time: "12:30" },
  //   { id: 1245, time: "12:45" },
  //   { id: 1300, time: "13:00" },
  //   { id: 1315, time: "13:15" },
  //   { id: 1330, time: "13:30" },
  //   { id: 1345, time: "13:45" },
  //   { id: 1400, time: "14:00" },
  //   { id: 1415, time: "14:15" },
  //   { id: 1430, time: "14:30" },
  //   { id: 1445, time: "14:45" },
  //   { id: 1500, time: "15:00" },
  //   { id: 1515, time: "15:15" },
  //   { id: 1530, time: "15:30" },
  //   { id: 1545, time: "15:45" },
  //   { id: 1600, time: "16:00" },
  //   { id: 1615, time: "16:15" },
  //   { id: 1630, time: "16:30" },
  //   { id: 1645, time: "16:45" },
  //   { id: 1700, time: "17:00" },
  //   { id: 1715, time: "17:15" },
  //   { id: 1730, time: "17:30" },
  //   { id: 1745, time: "17:45" },
  //   { id: 1800, time: "18:00" },
  //   { id: 1815, time: "18:15" },
  //   { id: 1830, time: "18:30" },
  //   { id: 1845, time: "18:45" },
  //   { id: 1900, time: "19:00" },
  //   { id: 1915, time: "19:15" },
  //   { id: 1930, time: "19:30" },
  //   { id: 1945, time: "19:45" },
  //   { id: 2000, time: "20:00" },
  //   { id: 2015, time: "20:15" },
  //   { id: 2030, time: "20:30" },
  //   { id: 2045, time: "20:45" },
  //   { id: 2100, time: "21:00" },
  //   { id: 2115, time: "21:15" },
  //   { id: 2130, time: "21:30" },
  //   { id: 2145, time: "21:45" },
  //   { id: 2200, time: "22:00" },
  //   { id: 2215, time: "22:15" },
  //   { id: 2230, time: "22:30" },
  //   { id: 2245, time: "22:45" },
  //   { id: 2300, time: "23:00" },
  //   { id: 2315, time: "23:15" },
  //   { id: 2330, time: "23:30" },
  //   { id: 2345, time: "23:45" },
  // ];

  const alldate = [
    { id: 0, time: "0000" },
    { id: 15, time: "0015" },
    { id: 30, time: "0030" },
    { id: 45, time: "0045" },
    { id: 100, time: "0100" },
    { id: 115, time: "0115" },
    { id: 130, time: "0130" },
    { id: 145, time: "0145" },
    { id: 200, time: "0200" },
    { id: 215, time: "0215" },
    { id: 230, time: "0230" },
    { id: 245, time: "0245" },
    { id: 300, time: "0300" },
    { id: 315, time: "0315" },
    { id: 330, time: "0330" },
    { id: 345, time: "0345" },
    { id: 400, time: "0400" },
    { id: 415, time: "0415" },
    { id: 430, time: "0430" },
    { id: 445, time: "0445" },
    { id: 500, time: "0500" },
    { id: 515, time: "0515" },
    { id: 530, time: "0530" },
    { id: 545, time: "0545" },
    { id: 600, time: "0600" },
    { id: 615, time: "0615" },
    { id: 630, time: "0630" },
    { id: 645, time: "0645" },
    { id: 700, time: "0700" },
    { id: 715, time: "0715" },
    { id: 730, time: "0730" },
    { id: 745, time: "0745" },
    { id: 800, time: "0800" },
    { id: 815, time: "0815" },
    { id: 830, time: "0830" },
    { id: 845, time: "0845" },
    { id: 900, time: "0900" },
    { id: 915, time: "0915" },
    { id: 930, time: "0930" },
    { id: 945, time: "0945" },
    { id: 1000, time: "1000" },
    { id: 1015, time: "1015" },
    { id: 1030, time: "1030" },
    { id: 1045, time: "1045" },
    { id: 1100, time: "1100" },
    { id: 1115, time: "1115" },
    { id: 1130, time: "1130" },
    { id: 1145, time: "1145" },
    { id: 1200, time: "1200" },
    { id: 1215, time: "1215" },
    { id: 1230, time: "1230" },
    { id: 1245, time: "1245" },
    { id: 1300, time: "1300" },
    { id: 1315, time: "1315" },
    { id: 1330, time: "1330" },
    { id: 1345, time: "1345" },
    { id: 1400, time: "1400" },
    { id: 1415, time: "1415" },
    { id: 1430, time: "1430" },
    { id: 1445, time: "1445" },
    { id: 1500, time: "1500" },
    { id: 1515, time: "1515" },
    { id: 1530, time: "1530" },
    { id: 1545, time: "1545" },
    { id: 1600, time: "1600" },
    { id: 1615, time: "1615" },
    { id: 1630, time: "1630" },
    { id: 1645, time: "1645" },
    { id: 1700, time: "1700" },
    { id: 1715, time: "1715" },
    { id: 1730, time: "1730" },
    { id: 1745, time: "1745" },
    { id: 1800, time: "1800" },
    { id: 1815, time: "1815" },
    { id: 1830, time: "1830" },
    { id: 1845, time: "1845" },
    { id: 1900, time: "1900" },
    { id: 1915, time: "1915" },
    { id: 1930, time: "1930" },
    { id: 1945, time: "1945" },
    { id: 2000, time: "2000" },
    { id: 2015, time: "2015" },
    { id: 2030, time: "2030" },
    { id: 2045, time: "2045" },
    { id: 2100, time: "2100" },
    { id: 2115, time: "2115" },
    { id: 2130, time: "2130" },
    { id: 2145, time: "2145" },
    { id: 2200, time: "2200" },
    { id: 2215, time: "2215" },
    { id: 2230, time: "2230" },
    { id: 2245, time: "2245" },
    { id: 2300, time: "2300" },
    { id: 2315, time: "2315" },
    { id: 2330, time: "2330" },
    { id: 2345, time: "2345" },
  ];
  //Display time should be within spa start time and end time
  const [amm, setamm1] = useState([]);

  useEffect(() => {
    let today = new Date();
    const [timePart, period] =
      type === "New"
        ? item?.formattedStartTime?.split(" ")
        : item?.vendors[0]?.formattedStartTime?.split(" ");
    const [hours, minutes] = timePart?.split(":");
    let hour = parseInt(hours, 10);
    const minute = parseInt(minutes, 10);

    // Handle AM and PM
    if (period === "PM" && hour < 12) {
      hour += 12;
    }
    const time24Hour = `${hour.toString().padStart(2, "0")}${minute
      .toString()
      .padStart(2, "0")}`;

    const [timePart1, period1] =
      type === "New"
        ? item?.formattedEndTime.split(" ")
        : item?.vendors[0]?.formattedEndTime.split(" ");

    const [hours1, minutes1] = timePart1.split(":");
    let hours2 = parseInt(hours1, 10);
    const minute1 = parseInt(minutes1, 10);

    if (period1 === "PM" && hours2 < 12) {
      hours2 += 12;
    }
    const time24Hour1 = `${hours2.toString().padStart(2, "0")}${minute1
      .toString()
      .padStart(2, "0")}`;

    if (
      moment(today).format("DD/MM/YYYY") ==
      moment(selecteddate).format("DD/MM/YYYY")
    ) {
      const later = moment(today.getTime()).format("hh:mm A");
      const [timePart2, period2] = later.split(" ");
      const [hours2, minutes2] = timePart2.split(":");
      let hours3 = parseInt(hours2, 10);
      const minute3 = parseInt(minutes2, 10);

      if (period2 === "PM" && hours3 < 12) {
        hours3 += 12;
      }
      const time24Hour2 = `${hours3.toString().padStart(2, "0")}${minute3
        .toString()
        .padStart(2, "0")}`;

      if (time24Hour <= time24Hour2) {
        setamm1(
          alldate?.filter((ele) => {
            return ele.time >= time24Hour2 && ele.time <= time24Hour1;
          })
        );
      } else {
        setamm1(
          alldate?.filter(
            (ele) => ele.time >= time24Hour && ele.time <= time24Hour1
          )
        );
      }
    } else {
      setamm1(
        alldate?.filter(
          (ele) => ele.time >= time24Hour && ele.time <= time24Hour1
        )
      );
    }
  }, [selecteddate]);

  const [endTimeperiod, setendTimeperiod] = useState("");
  const [ishr, setishr] = useState();
  const [ismin, setismin] = useState();

  // console.log("pickuptime", pickuptime);
  //caluclate End Time

  useEffect(() => {
    if (pickuptime) {
      const [timePart, period] = pickuptime.split(" ");
      const [hours, minutes] = timePart.split(":");

      const hour = parseInt(hours, 10);
      const minute = parseInt(minutes, 10);

      if (period === "PM" && hour < 12) {
        var hour1 = hour + 12;
        var totalMinutes =
          hour1 * 60 +
          minute +
          parseInt(service.Service_Duration) +
          service?.advantages?.reduce(
            (a, b) => a + parseInt(b.adv_duration),
            0
          );

        const istHour = Math.floor(totalMinutes / 60);

        console.log("istHour", istHour);
        setismin(totalMinutes % 60);
        if (istHour >= 12) {
          setendTimeperiod("PM");
          if (istHour === 12) {
            // If the resulting hour is 12, set it to 12 instead of 0
            setishr(12);
          } else {
            setishr(istHour - 12);
          }
        } else {
          setendTimeperiod("AM");
          setishr(istHour);
        }
      } else {
        var totalMinutes1 =
          hour * 60 +
          minute +
          parseInt(service.Service_Duration) +
          parseInt(
            service?.advantages?.reduce(
              (a, b) => a + parseInt(b.adv_duration),
              0
            )
          );
        const istHour = Math.floor(totalMinutes1 / 60);

        // console.log("istHour", istHour);
        setismin(totalMinutes1 % 60);
        if (istHour >= 12) {
          setendTimeperiod("PM");
          if (istHour === 12) {
            setishr(12); // Set the hour to 12 for the case of 12 PM to 1 PM
          } else {
            setishr(istHour - 12);
          }
        } else {
          setendTimeperiod("AM");
          setishr(istHour);
        }
      }
    }
  }, [pickuptime]);

  const formattedTime = `${ishr?.toString().padStart(2, "0")}:${ismin
    ?.toString()
    .padStart(2, "0")} ${endTimeperiod}`;

  //For selected date therapist is available or not
  const therapistavailability = Therapist?.vailability?.find(
    (item) =>
      moment(item.date1).format("DD/MM/YYYY") ===
      moment(selecteddate).format("DD/MM/YYYY")
  );

  //slot caluclation : if perticular time is booked more than available slot it should not display
  var arraycount = [];
  // console.log("amm", amm);

  for (let i = 0; i < amm?.length; i++) {
    const xyz = Order?.filter((item) => {
      const [timePart, period] = item.fromTime.split(" ");
      const [hours, minutes] = timePart.split(":");

      const hour = parseInt(hours, 10);
      const minute = parseInt(minutes, 10);
      var hour1 = "";
      if (period === "PM" && hour < 12) {
        hour1 = hour + 12;
      } else {
        hour1 = hour;
      }

      var fromtime = hour1.toString() + minutes.toString();

      return (
        Number(fromtime) === Number(amm[i].time) &&
        item?.date === moment(selecteddate).format("DD/MM/YYYY")
      );
    });
    console.log("xyz", xyz?.length, service?.slots);
    if (xyz?.length < service?.slots) {
      console.log("amm[i]", amm[i]);

      arraycount?.push(amm[i]);
    }
  }

  let timearray = [];

  for (let i = 0; i < amm?.length; i++) {
    let matchFound = false; // Flag to track if a match is found

    if (Therapist?.orders) {
      for (const order of Therapist.orders) {
        // console.log("order.date", order.date);
        if (order.date === moment(selecteddate).format("DD/MM/YYYY")) {
          const [timePart, period] = order.fromTime.split(" ");
          const [hours, minutes] = timePart.split(":");
          const hour = parseInt(hours, 10);

          const minute = parseInt(minutes, 10);
          var hour1 = period === "PM" && hour < 12 ? hour + 12 : hours;
          var fromtime = hour1.toString() + minutes.toString();

          const [EtimePart, Eperiod] = order.toTime.split(" ");
          const [Ehours, Eminutes] = EtimePart.split(":");
          const Ehour = parseInt(Ehours, 10);
          const Eminute = parseInt(Eminutes, 10);
          var Ehour1 = Eperiod === "PM" && Ehour < 12 ? Ehour + 12 : Ehours;
          var Endtime = Ehour1.toString() + Eminutes.toString();

          // console.log(
          //   "yes",
          //   hour,
          //   minute,
          //   fromtime,
          //   Endtime,
          //   Number(amm[i].time)
          // );

          // Check if the time in amm[i] falls within the time range of any order
          if (
            Number(fromtime) <= Number(amm[i].time) &&
            Number(amm[i].time) <= Number(Endtime)
          ) {
            matchFound = true;
            break; // Exit the loop, as we found a match
          }
        }
      }
    }
    if (!matchFound) {
      // Push amm[i] into timearray if no match was found
      timearray.push(amm[i]);
    }
  }

  // console.log("Therapist", Therapist);
  // console.log("therapistavailability", therapistavailability);
  // console.log("arraycount", arraycount);
  // console.log("selecteddate", selecteddate);
  // console.log("item", item);
  // console.log("amm", amm);
  // console.log(
  //   "ndbcfnbd",
  //   Therapist?.orders?.find(
  //     (item) => item.date === moment(selecteddate).format("DD/MM/YYYY")
  //   )
  // );

  // console.log("type", arraycount);
  // console.log("Order", Order);

  return (
    <>
      <div
        className="row me-0 pt-2 pb-2"
        style={{
          boxShadow: "3px 4px 8px #cbc6c6",
          justifyContent: "space-between",
        }}
      >
        <Col md={2}>
          <div
            className="log0"
            style={{ paddingLeft: "20px" }}
            onClick={() => window.location.assign("/NewHome")}
          >
            <img src="./logo.jpg" style={{ width: "110px", height: "38px" }} />
          </div>
        </Col>
        {!user ? (
          <Col md={2}>
            <div
              style={{ display: "flex", gap: "10px" }}
              onClick={() => window.location.assign("/login")}
            >
              <BsFillFilePersonFill size={25} />
              <p
                style={{
                  marginBottom: "0px",
                  fontWeight: "bold",
                }}
              >
                Login / Signup
              </p>
            </div>
          </Col>
        ) : (
          ""
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={30000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container style={{ margin: "5%" }}>
        <Link
          to="/SelectTherapist"
          state={{ item: item, service: service, type: type }}
        >
          <AiOutlineLeft
            style={{ color: "#e22217", fontSize: "28px", marginBottom: "4px" }}
          />
          <span
            style={{
              color: "#e22217",
              fontSize: "22px",
              fontWeight: "bold",
            }}
          >
            Select time
          </span>
        </Link>

        <Row className="me-0">
          <Col md={8} className="mt-2">
            <DatePicker
              getSelectedDay={selectedDay}
              endDate={1000}
              labelFormat={"MMMM yyyy"}
              color={"#e22217"}
            />
            {Therapist === "No Preference" ? (
              type === "New" ? (
                <>
                  {moment(selecteddate).format("dddd") == "Sunday" &&
                  item.sun ? (
                    <>
                      {arraycount?.map((item) => (
                        <div
                          className="timeselect"
                          onClick={() =>
                            setpickuptime(
                              moment(item?.time, ["h:mm A"]).format("hh:mm A")
                            )
                          }
                        >
                          <p>
                            {moment(item?.time, ["h:mm A"]).format("hh:mm A")}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : moment(selecteddate).format("dddd") == "Monday" &&
                    item.mon ? (
                    <>
                      {arraycount?.map((item) => (
                        <div
                          className="timeselect"
                          onClick={() =>
                            setpickuptime(
                              moment(item?.time, ["h:mm A"]).format("hh:mm A")
                            )
                          }
                        >
                          <p>
                            {moment(item?.time, ["h:mm A"]).format("hh:mm A")}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : moment(selecteddate).format("dddd") == "Tuesday" &&
                    item.tue ? (
                    <>
                      {arraycount?.map((item) => (
                        <div
                          className="timeselect"
                          onClick={() =>
                            setpickuptime(
                              moment(item?.time, ["h:mm A"]).format("hh:mm A")
                            )
                          }
                        >
                          <p>
                            {moment(item?.time, ["h:mm A"]).format("hh:mm A")}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : moment(selecteddate).format("dddd") == "Wednesday" &&
                    item.wed ? (
                    <>
                      {arraycount?.map((item) => (
                        <div
                          className="timeselect"
                          onClick={() =>
                            setpickuptime(
                              moment(item?.time, ["h:mm A"]).format("hh:mm A")
                            )
                          }
                        >
                          <p>
                            {moment(item?.time, ["h:mm A"]).format("hh:mm A")}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : moment(selecteddate).format("dddd") == "Thursday" &&
                    item.turs ? (
                    <>
                      {arraycount?.map((item) => (
                        <div
                          className="timeselect"
                          onClick={() =>
                            setpickuptime(
                              moment(item?.time, ["h:mm A"]).format("hh:mm A")
                            )
                          }
                        >
                          <p>
                            {moment(item?.time, ["h:mm A"]).format("hh:mm A")}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : moment(selecteddate).format("dddd") == "Friday" &&
                    item.frid ? (
                    <>
                      {arraycount?.map((item) => (
                        <div
                          className="timeselect"
                          onClick={() =>
                            setpickuptime(
                              moment(item?.time, ["h:mm A"]).format("hh:mm A")
                            )
                          }
                        >
                          <p>
                            {moment(item?.time, ["h:mm A"]).format("hh:mm A")}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : moment(selecteddate).format("dddd") == "Saturday" &&
                    item.sat ? (
                    <>
                      {arraycount?.map((item) => (
                        <div
                          className="timeselect"
                          onClick={() =>
                            setpickuptime(
                              moment(item?.time, ["h:mm A"]).format("hh:mm A")
                            )
                          }
                        >
                          <p>
                            {moment(item?.time, ["h:mm A"]).format("hh:mm A")}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        margin: "15%",
                      }}
                    >
                      <div
                        style={{
                          margin: "20px",
                          display: "grid",
                          justifyContent: "center",
                        }}
                      >
                        <i
                          class="fas fa-calendar-times"
                          style={{
                            fontSize: "30px",
                            color: "rgb(226, 34, 23)",
                          }}
                        ></i>
                      </div>
                      <p
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          marginBottom: "0px",
                          textAlign: "center",
                        }}
                      >
                        Week-Off
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                          textAlign: "center",
                        }}
                      >
                        but you can book for another day
                      </p>
                    </div>
                  )}
                </>
              ) : type === "brand" ? (
                <>
                  {moment(selecteddate).format("dddd") == "Sunday" &&
                  item?.vendors[0]?.sun ? (
                    <>
                      {arraycount?.map((item) => (
                        <div
                          className="timeselect"
                          onClick={() =>
                            setpickuptime(
                              moment(item?.time, ["h:mm A"]).format("hh:mm A")
                            )
                          }
                        >
                          <p>
                            {moment(item?.time, ["h:mm A"]).format("hh:mm A")}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : moment(selecteddate).format("dddd") == "Monday" &&
                    item?.vendors[0]?.mon ? (
                    <>
                      {arraycount?.map((item) => (
                        <div
                          className="timeselect"
                          onClick={() =>
                            setpickuptime(
                              moment(item?.time, ["h:mm A"]).format("hh:mm A")
                            )
                          }
                        >
                          <p>
                            {moment(item?.time, ["h:mm A"]).format("hh:mm A")}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : moment(selecteddate).format("dddd") == "Tuesday" &&
                    item?.vendors[0]?.tue ? (
                    <>
                      {arraycount?.map((item) => (
                        <div
                          className="timeselect"
                          onClick={() =>
                            setpickuptime(
                              moment(item?.time, ["h:mm A"]).format("hh:mm A")
                            )
                          }
                        >
                          <p>
                            {moment(item?.time, ["h:mm A"]).format("hh:mm A")}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : moment(selecteddate).format("dddd") == "Wednesday" &&
                    item?.vendors[0]?.wed ? (
                    <>
                      {arraycount?.map((item) => (
                        <div
                          className="timeselect"
                          onClick={() =>
                            setpickuptime(
                              moment(item?.time, ["h:mm A"]).format("hh:mm A")
                            )
                          }
                        >
                          <p>
                            {moment(item?.time, ["h:mm A"]).format("hh:mm A")}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : moment(selecteddate).format("dddd") == "Thursday" &&
                    item?.vendors[0]?.turs ? (
                    <>
                      {arraycount?.map((item) => (
                        <div
                          className="timeselect"
                          onClick={() =>
                            setpickuptime(
                              moment(item?.time, ["h:mm A"]).format("hh:mm A")
                            )
                          }
                        >
                          <p>
                            {moment(item?.time, ["h:mm A"]).format("hh:mm A")}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : moment(selecteddate).format("dddd") == "Friday" &&
                    item?.vendors[0]?.frid ? (
                    <>
                      {arraycount?.map((item) => (
                        <div
                          className="timeselect"
                          onClick={() =>
                            setpickuptime(
                              moment(item?.time, ["h:mm A"]).format("hh:mm A")
                            )
                          }
                        >
                          <p>
                            {moment(item?.time, ["h:mm A"]).format("hh:mm A")}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : moment(selecteddate).format("dddd") == "Saturday" &&
                    item?.vendors[0]?.sat ? (
                    <>
                      {arraycount?.map((item) => (
                        <div
                          className="timeselect"
                          onClick={() =>
                            setpickuptime(
                              moment(item?.time, ["h:mm A"]).format("hh:mm A")
                            )
                          }
                        >
                          <p>
                            {moment(item?.time, ["h:mm A"]).format("hh:mm A")}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        margin: "15%",
                      }}
                    >
                      <div
                        style={{
                          margin: "20px",
                          display: "grid",
                          justifyContent: "center",
                        }}
                      >
                        <i
                          class="fas fa-calendar-times"
                          style={{
                            fontSize: "30px",
                            color: "rgb(226, 34, 23)",
                          }}
                        ></i>
                      </div>
                      <p
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          marginBottom: "0px",
                          textAlign: "center",
                        }}
                      >
                        Week-Off
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                          textAlign: "center",
                        }}
                      >
                        but you can book for another day
                      </p>
                    </div>
                  )}
                </>
              ) : (
                ""
              )
            ) : (
              <>
                {therapistavailability?.Availability === "Available" ? (
                  <>
                    {moment().format("DD/MM/YYYY") ===
                    moment(selecteddate).format("DD/MM/YYYY") ? (
                      <>
                        {Therapist?.Duties[
                          Therapist?.Duties?.length - 1
                        ]?.workingDate.split(", ")[0] ===
                          moment().format("DD/MM/YYYY") &&
                        Therapist?.Duties[Therapist?.Duties?.length - 1]
                          ?.Duty === true ? (
                          <>
                            {Therapist?.orders?.find(
                              (item) =>
                                item.date ===
                                moment(selecteddate).format("DD/MM/YYYY")
                            ) ? (
                              <>
                                {timearray?.length !== 0 ? (
                                  <>
                                    {timearray?.map((item) => (
                                      <div
                                        className="timeselect"
                                        onClick={() =>
                                          setpickuptime(
                                            moment(item?.time, [
                                              "h:mm A",
                                            ]).format("hh:mm A")
                                          )
                                        }
                                      >
                                        <p>
                                          {moment(item?.time, [
                                            "h:mm A",
                                          ]).format("hh:mm A")}
                                        </p>
                                      </div>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    <div
                                      style={{
                                        display: "grid",
                                        justifyContent: "center",
                                        margin: "15%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          margin: "20px",
                                          display: "grid",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <i
                                          class="fas fa-calendar-times"
                                          style={{
                                            fontSize: "30px",
                                            color: "rgb(226, 34, 23)",
                                          }}
                                        ></i>
                                      </div>
                                      <p
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "bold",
                                          marginBottom: "0px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {Therapist?.name}&nbsp;is fully booked
                                        on&nbsp;
                                        {moment(selecteddate).format(
                                          "MMMM Do YYYY"
                                        )}
                                      </p>
                                      <p
                                        style={{
                                          marginBottom: "0px",
                                          textAlign: "center",
                                        }}
                                      >
                                        but you can book for another day
                                      </p>
                                      <Link
                                        to="/SelectTherapist"
                                        state={{
                                          item: item,
                                          service: service,
                                          type: type,
                                        }}
                                        style={{
                                          color: "black",
                                          textAlign: "center",
                                          border: "1px solid black",
                                          margin: "20px",
                                          backgroundColor: "transparent",
                                          padding: "10px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Try with other Therapist
                                      </Link>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {arraycount?.map((item) => (
                                  <div
                                    className="timeselect"
                                    onClick={() =>
                                      setpickuptime(
                                        moment(item?.time, ["h:mm A"]).format(
                                          "hh:mm A"
                                        )
                                      )
                                    }
                                  >
                                    <p>
                                      {moment(item?.time, ["h:mm A"]).format(
                                        "hh:mm A"
                                      )}
                                    </p>
                                  </div>
                                ))}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                display: "grid",
                                justifyContent: "center",
                                margin: "15%",
                              }}
                            >
                              <div
                                style={{
                                  margin: "20px",
                                  display: "grid",
                                  justifyContent: "center",
                                }}
                              >
                                <i
                                  class="fas fa-calendar-times"
                                  style={{
                                    fontSize: "30px",
                                    color: "rgb(226, 34, 23)",
                                  }}
                                ></i>
                              </div>
                              <p
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  marginBottom: "0px",
                                  textAlign: "center",
                                }}
                              >
                                {Therapist?.name}&nbsp;is fully booked on&nbsp;
                                {moment(selecteddate).format("MMMM Do YYYY")}
                              </p>
                              <p
                                style={{
                                  marginBottom: "0px",
                                  textAlign: "center",
                                }}
                              >
                                but you can book for another day
                              </p>
                              <Link
                                to="/SelectTherapist"
                                state={{
                                  item: item,
                                  service: service,
                                  type: type,
                                }}
                                style={{
                                  color: "black",
                                  textAlign: "center",
                                  border: "1px solid black",
                                  margin: "20px",
                                  backgroundColor: "transparent",
                                  padding: "10px",
                                  fontWeight: "bold",
                                }}
                              >
                                Try with other Therapist
                              </Link>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <>
                          {Therapist?.orders?.find(
                            (item) =>
                              item.date ===
                              moment(selecteddate).format("DD/MM/YYYY")
                          ) ? (
                            <>
                              {timearray?.length !== 0 ? (
                                <>
                                  {timearray?.map((item) => (
                                    <div
                                      className="timeselect"
                                      onClick={() =>
                                        setpickuptime(
                                          moment(item?.time, ["h:mm A"]).format(
                                            "hh:mm A"
                                          )
                                        )
                                      }
                                    >
                                      <p>
                                        {moment(item?.time, ["h:mm A"]).format(
                                          "hh:mm A"
                                        )}
                                      </p>
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      display: "grid",
                                      justifyContent: "center",
                                      margin: "15%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        margin: "20px",
                                        display: "grid",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i
                                        class="fas fa-calendar-times"
                                        style={{
                                          fontSize: "30px",
                                          color: "rgb(226, 34, 23)",
                                        }}
                                      ></i>
                                    </div>
                                    <p
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                        marginBottom: "0px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {Therapist?.name}&nbsp;is fully booked
                                      on&nbsp;
                                      {moment(selecteddate).format(
                                        "MMMM Do YYYY"
                                      )}
                                    </p>
                                    <p
                                      style={{
                                        marginBottom: "0px",
                                        textAlign: "center",
                                      }}
                                    >
                                      but you can book for another day
                                    </p>
                                    <Link
                                      to="/SelectTherapist"
                                      state={{
                                        item: item,
                                        service: service,
                                        type: type,
                                      }}
                                      style={{
                                        color: "black",
                                        textAlign: "center",
                                        border: "1px solid black",
                                        margin: "20px",
                                        backgroundColor: "transparent",
                                        padding: "10px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Try with other Therapist
                                    </Link>
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {arraycount?.map((item) => (
                                <div
                                  className="timeselect"
                                  onClick={() =>
                                    setpickuptime(
                                      moment(item?.time, ["h:mm A"]).format(
                                        "hh:mm A"
                                      )
                                    )
                                  }
                                >
                                  <p>
                                    {moment(item?.time, ["h:mm A"]).format(
                                      "hh:mm A"
                                    )}
                                  </p>
                                </div>
                              ))}
                            </>
                          )}
                        </>
                      </>
                    )}
                  </>
                ) : therapistavailability?.Availability === "Not" ? (
                  <>
                    <div
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        margin: "15%",
                      }}
                    >
                      <div
                        style={{
                          margin: "20px",
                          display: "grid",
                          justifyContent: "center",
                        }}
                      >
                        <i
                          class="fas fa-calendar-times"
                          style={{
                            fontSize: "30px",
                            color: "rgb(226, 34, 23)",
                          }}
                        ></i>
                      </div>
                      <p
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          marginBottom: "0px",
                          textAlign: "center",
                        }}
                      >
                        {Therapist?.name}&nbsp;is fully booked on&nbsp;
                        {moment(selecteddate).format("MMMM Do YYYY")}
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                          textAlign: "center",
                        }}
                      >
                        but you can book for another day
                      </p>
                      <Link
                        to="/SelectTherapist"
                        state={{ item: item, service: service, type: type }}
                        style={{
                          color: "black",
                          textAlign: "center",
                          border: "1px solid black",
                          margin: "20px",
                          backgroundColor: "transparent",
                          padding: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        Try with other Therapist
                      </Link>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </Col>
          <Col md={4}>
            <div
              style={{
                border: "1px solid rgb(217 212 208)",
                padding: "10px",
                margin: "10px",
                borderRadius: "5px",
                // position: "fixed",
                // width: "25%",
              }}
            >
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginBottom: "0px",
                  color: "#e22217",
                }}
              >
                {service?.VendorBusinessName}
              </p>
              <p
                style={{
                  fontSize: "16px",
                  marginBottom: "0px",
                }}
              >
                {service?.VendorAddress}
              </p>
              <div style={{ marginTop: "8px" }}>
                {/* <span>Ratings :</span>&nbsp; */}
                <span
                  style={{
                    backgroundColor: "#1ab64f",
                    color: "white",
                    padding: "0px 5px",
                    width: "10%",
                  }}
                >
                  {type === "New"
                    ? isNaN(
                        item?.reviews?.reduce((a, rev) => a + rev?.rating, 0) /
                          item?.reviews?.length
                      )
                      ? 0
                      : (
                          item?.reviews?.reduce(
                            (a, rev) => a + rev?.rating,
                            0
                          ) / item?.reviews?.length
                        )?.toFixed(1)
                    : isNaN(
                        item?.vendors[0]?.reviews?.reduce(
                          (a, rev) => a + rev.rating,
                          0
                        ) / item?.vendors[0]?.reviews?.length
                      )
                    ? 0
                    : (
                        item?.vendors[0]?.reviews?.reduce(
                          (a, rev) => a + rev.rating,
                          0
                        ) / item?.vendors[0]?.reviews?.length
                      )?.toFixed(1)}
                  &nbsp;
                  <i class="fas fa-star"></i>
                </span>
                <h5 style={{ marginTop: "20px" }}>
                  {" "}
                  {service?.ServiceName}({service?.Service_Duration} min)
                  {service?.advantages?.map((adv) => (
                    <>
                      <span>
                        + {adv.adv_name} ({adv.adv_duration} min)
                      </span>
                    </>
                  ))}
                </h5>

                {Therapist == "No Preference" ? (
                  <>
                    <div style={{ display: "flex" }}>
                      <i class="fas fa-user" style={{ margin: "5px" }}></i>
                      <p style={{ marginBottom: "0px" }}>No Preference</p>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    {Therapist?.ViewProfileImageToCustomer ? (
                      <img
                        src={
                          "https://spazo.co.in/Employee/" + Therapist?.profile
                        }
                        width={50}
                        height={50}
                        onClick={() =>
                          window.open(
                            "https://spazo.co.in/Employee/" + Therapist.profile
                          )
                        }
                        style={{ borderRadius: "50%" }}
                      />
                    ) : (
                      <img
                        src="/Assets/profile.jpg"
                        width={50}
                        height={50}
                        style={{
                          borderRadius: "50%",
                        }}
                      />
                    )}
                    <div>
                      <p style={{ marginBottom: "0px", fontWeight: "700" }}>
                        {Therapist?.name}
                      </p>
                      <p style={{ marginBottom: "0px" }}>
                        {Therapist?.Specialization}
                      </p>
                    </div>
                  </div>
                )}

                <div style={{ display: "flex" }}>
                  <i class="fas fa-calendar-week" style={{ margin: "5px" }}></i>
                  <div>
                    <p style={{ marginBottom: "0px" }}>
                      {moment(selecteddate).format("MMMM Do YYYY")}
                    </p>
                    {pickuptime ? (
                      <p style={{ marginBottom: "0px" }}>
                        {pickuptime} - {formattedTime ? formattedTime : ""}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <hr></hr>
                <div>
                  <Row className="me-0">
                    <Col md={6}>Price</Col>
                    <Col md={6}>
                      <p
                        style={{
                          textAlign: "right",
                          marginBottom: "0px",
                          fontWeight: "bold",
                        }}
                      >
                        {price}
                      </p>
                    </Col>
                  </Row>{" "}
                  <Row className="me-0">
                    <Col md={6}>GST</Col>
                    <Col md={6}>
                      <p
                        style={{
                          textAlign: "right",
                          fontWeight: "bold",
                          marginBottom: "0px",
                        }}
                      >
                        {GST}
                      </p>
                    </Col>
                  </Row>{" "}
                  <Row className="me-0">
                    <Col md={6}>Service Tax</Col>
                    <Col md={6}>
                      <p
                        style={{
                          textAlign: "right",
                          fontWeight: "bold",
                          marginBottom: "0px",
                        }}
                      >
                        {ServiceTax}
                      </p>
                    </Col>
                  </Row>{" "}
                  <Row className="me-0">
                    <Col md={6}>Discount</Col>
                    <Col md={6}>
                      <p
                        style={{
                          textAlign: "right",
                          marginBottom: "0px",
                          fontWeight: "bold",
                        }}
                      >
                        {Discount}
                      </p>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row className="me-0">
                    <Col md={6}>Total Amount</Col>
                    <Col md={6}>
                      <p
                        style={{
                          textAlign: "right",
                          marginBottom: "0px",
                          fontWeight: "bold",
                        }}
                      >
                        {TotalAmount}
                      </p>
                    </Col>
                  </Row>
                  <Row className="me-0">
                    <Col md={6}>Total Discount</Col>
                    <Col md={6}>
                      <p
                        style={{
                          textAlign: "right",
                          marginBottom: "0px",
                          fontWeight: "bold",
                        }}
                      >
                        {Discount}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
              {pickuptime ? (
                <Link
                  style={{
                    backgroundColor: "rgb(226, 34, 23)",
                    color: "white",
                    border: "none",
                    marginTop: "30px",
                    padding: "5px 20px",
                    borderRadius: "5px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  to="/Checkout"
                  state={{
                    item: item,
                    service: service,
                    Therapist: Therapist,
                    date: selecteddate,
                    fromTime: pickuptime,
                    toTime: formattedTime,
                    type: type,
                  }}
                >
                  Continue
                </Link>
              ) : (
                <button
                  style={{
                    backgroundColor: "transparent",
                    color: "gray",
                    border: "1px solid gray",
                    marginTop: "30px",
                    padding: "5px 20px",
                    borderRadius: "5px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Continue
                </button>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Selectdate;
