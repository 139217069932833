import React from "react";
import { Country, State, City } from "country-state-city";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import axios from "axios";
import OtpInput from "react-otp-input";

function AdminverifyOtp() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const email = JSON.parse(sessionStorage.getItem("Aemail"));
  const [otp, setOtp] = useState("");

  const AdminVerifyOtp = async (e) => {
    if (!otp) {
      notify2("Please enter OTP");
    } else {
      try {
        const config = {
          url: "/AdminVerifyOtp",
          method: "post",
          baseURL: "https://spazo.co.in/api/admin",
          headers: { "content-type": "application/json" },
          data: {
            email: email,
            otp: otp,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          notify("OTP Varified");
          sessionStorage.setItem("admindata", JSON.stringify(res.data.success));
          window.location.assign("/AdminChangepassword");
        } else {
          notify1(res.data.error);
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          notify1(error.response.data.error);
        }
      }
    }
  };

  console.log("otp", otp);

  return (
    <>
      <div className="admincontainer">
        <img
          src="../../Assets/images/loginimage1.jpg"
          alt="Snow"
          style={{ width: "100%", height: "100vh" }}
        />
        <ToastContainer
          position="top-center"
          autoClose={30000}
          closeOnClick
          pauseOnHover
          draggable
        />

        <div className="centered12345">
          <p style={{ color: "white", fontSize: "25px", marginBottom: "0px" }}>
            Enter OTP sent to your registered email id
          </p>
          <div className="codeverify">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>&nbsp;&nbsp;</span>}
              renderInput={(props) => (
                <input {...props} style={{ width: "2rem" }} />
              )}
            />
          </div>
          <button
            style={{
              borderColor: "#e22217",
              backgroundColor: "#e22217",
              color: "white",
              padding: "0px 10px 0px 10px",
            }}
            onClick={AdminVerifyOtp}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
}

export default AdminverifyOtp;
