import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { AiOutlineLeft } from "react-icons/ai";
import { BiCalendar } from "react-icons/bi";
import {
  BsFillFilePersonFill,
  BsFillPersonFill,
  BsShieldCheck,
} from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import { isAfter, isBefore, isToday, addDays, format } from "date-fns";
import moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import parse from "html-react-parser";

function Checkout() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const location = useLocation();
  const { item, service, Therapist, date, fromTime, toTime, type } =
    location.state;
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [paymentType, setpaymentType] = useState("Pay At Spa");

  const [Employee, setEmployee] = useState([]);
  const [slotbooked, setslotbooked] = useState("");
  // const [Therapist, setTherapist] = useState({});
  // const [Therapist1, setTherapist1] = useState({});
  const [promocode, setpromocode] = useState("");
  const [promocodearray, setpromocodearray] = useState([]);
  const [paymentID, setpaymentID] = useState("");

  const slotbookedArray = slotbooked ? slotbooked?.split("|") : [];
  const currentDate = new Date();

  const [cupondata, setcupondata] = useState({});

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  // copy to clipboard
  const [isCopied, setIsCopied] = React.useState(false);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(text);
      // alert("Copied!");
      notify("Copied!");
    });
  };

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (data) => {
    setcupondata(data);
    setShow1(true);
  };

  useEffect(() => {
    getVendorEmployee();
    getPromocode();
  }, []);

  useEffect(() => {
    if (user && Object.keys(user)?.length > 0) {
      // Fetch data immediately on component mount
      getalluser();

      // Set up interval to fetch data every minute (60000 ms)
      const intervalId = setInterval(getalluser, 30000);

      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [user]);
  const [data, setdata] = useState();

  useEffect(() => {
    if (data?.length > 0) {
      sessionStorage.setItem("user", JSON.stringify(data[0]));
    }
  }, [data]);

  const getalluser = () => {
    axios
      .get("https://spazo.co.in/api/customer/getCustomer")
      .then(function (response) {
        // console.log(response.data.user);
        setdata(
          response.data.Customer?.filter((item) => item?._id === user?._id)
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getVendorEmployee = () => {
    if (type === "New") {
      axios
        .get("https://spazo.co.in/api/Vendor/getVendorEmployee/" + item._id)
        .then(function (response) {
          console.log(response.data);
          setEmployee(response.data.Employee);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios
        .get(
          "https://spazo.co.in/api/Vendor/getVendorEmployee/" +
            item?.vendors[0]?._id
        )
        .then(function (response) {
          console.log(response.data);
          setEmployee(response.data.Employee);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const newEmployee = Employee?.filter((person) =>
    person?.vailability?.some(
      (hobby) =>
        moment(hobby.date1).format("DD/MM/YYYY") ==
          moment(slotbookedArray[1]).format("DD/MM/YYYY") &&
        hobby.From_Time === slotbookedArray[2] &&
        hobby.To_Time === slotbookedArray[3] &&
        hobby.Availability === "Available" &&
        hobby.BookingStatus === "Available"
    )
  );

  const getPromocode = () => {
    axios
      .get("https://spazo.co.in/api/admin/getPromocode")
      .then(function (response) {
        console.log(response.data);
        setpromocodearray(
          response.data.PromocodeList.filter(
            (itm) =>
              itm.Status === "Valid" &&
              new Date(itm.Startdate) <= currentDate &&
              itm?.promocodeType === item?.type
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [promocodeamount, setpromocodeamount] = useState({});

  // useEffect(() => {
  //   if (promocodearray?.length > 0) {
  //     const abc = promocodearray?.find((item) => item?.Promocode === promocode);
  //     setpromocodeamount(abc);
  //   }
  // }, [promocodearray]);

  const promocodevalue = () => {
    const abc = promocodearray?.find((item) => item?.Promocode === promocode);

    const today = new Date(); // Get today's date
    const endDate = new Date(abc?.Enddate); // Convert Enddate to a Date object

    // Set the time of both dates to 00:00:00 to only compare the date part
    today.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    const status = endDate < today ? "Expired" : "Valid";

    if (abc) {
      if (status === "Expired") {
        notify1("Sorry...! Promocode validity Expired");
      } else {
        setpromocodeamount(abc);
      }
    } else {
      notify1("You have entred invalid Promocode");
    }
  };

  const price = service?.Price;
  const GST = (price * service?.GST) / 100;
  const ServiceTax = (price * service?.servicetax) / 100;
  const Discount = (price * service?.Discount) / 100;
  const CouponDiscount = promocodeamount
    ? Object.keys(promocodeamount)?.length > 0
      ? promocodeamount?.amount
      : 0
    : 0;
  const TotalAmount = price + GST + ServiceTax;
  const onlinepaymentdiscount = ((TotalAmount - Discount) * 5) / 100;
  const TotalDiscount =
    paymentType !== "Pay At Spa"
      ? Discount + CouponDiscount + ((TotalAmount - Discount) * 5) / 100
      : Discount + CouponDiscount;
  const PayableAmount = TotalAmount - TotalDiscount;
  const pay20 = (PayableAmount * 20) / 100;

  const placeorder = async (e) => {
    if (!user) {
      notify2("Please login to book a appointment");
    } else if (user.Blockstatus) {
      notify2("Sorry...!!!, You are blocked from website owner");
    } else {
      try {
        const config = {
          url: "/AddOrder",
          method: "post",
          baseURL: "https://spazo.co.in/api/customer",
          headers: { "content-type": "application/json" },
          data: {
            // slotbooked: slotbooked,
            Service_id: service?._id,
            ServiceName: service?.ServiceName,
            Service_Category: service?.Service_Category,
            Service_Duration: service?.Service_Duration,
            ValidFor: service?.ValidFor,
            date: moment(date).format("DD/MM/YYYY"),
            fromTime: fromTime,
            toTime: toTime,
            Therapist:
              Therapist === "No Preference"
                ? "No Preference"
                : Therapist?.EmpId,
            Booking_assined_date:
              Therapist === "No Preference"
                ? ""
                : moment().format("DD/MM/YYYY"),
            // Therapist1: Therapist1
            //   ? Object.keys(Therapist1)?.length > 0
            //     ? Therapist1?.EmpId
            //       ? Therapist1?.EmpId
            //       : Therapist
            //     : "No Preference"
            //   : "No Preference",
            slots: service?.slots,
            Price: service?.Price,
            Discount: Discount,
            promocode: promocode ? promocode : promocode,
            CouponDiscount: CouponDiscount,
            TotalAmount: TotalAmount,
            TotalDiscount: TotalDiscount,
            TotalPayableAmount: PayableAmount,
            TotalAmountPaid:
              paymentType === "Pay 20%"
                ? pay20
                : paymentType === "Pay At Spa"
                ? 0
                : PayableAmount,
            vendorId: service?.VendorId,
            VendorBusinessName: service?.VendorBusinessName,
            VendorName: service?.VendorName,
            VendorEmailID: service?.VendorEmailID,
            VendorContactNo: service?.VendorContactNo,
            VendorAddress: service?.VendorAddress,
            VendorPincode: service?.VendorPincode,
            VendorGlink: service?.VendorGlink,
            userID: user?._id,
            userName: user?.name,
            userEmailID: user?.email,
            userContactNumber: user?.phoneNumber,
            paymentMethod: paymentType,
            paymentID: paymentID ? paymentID : "",
            Type: "OnlineBooking",
            ReasonForBlock: "",
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          // alert("Your appointment booked successfully");
          notify("Your appointment booked successfully");
          window.location.assign("NewHome");
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          // alert(error.response.data.error);
          notify(error.response.data.error);
        }
      }
    }
  };

  // console.log("Therapist", item, service, Therapist, date, fromTime, toTime);
  console.log("item", item);

  return (
    <>
      <div
        className="row me-0 pt-2 pb-2"
        style={{
          boxShadow: "3px 4px 8px #cbc6c6",
          justifyContent: "space-between",
        }}
      >
        <Col md={2}>
          <div
            className="log0"
            style={{ paddingLeft: "20px" }}
            onClick={() => window.location.assign("/NewHome")}
          >
            <img src="./logo.jpg" style={{ width: "110px", height: "38px" }} />
          </div>
        </Col>
        {!user ? (
          <Col md={2}>
            <div
              style={{ display: "flex", gap: "10px" }}
              onClick={() => window.location.assign("/login")}
            >
              <BsFillFilePersonFill size={25} />
              <p
                style={{
                  marginBottom: "0px",
                  fontWeight: "bold",
                }}
              >
                Login / Signup
              </p>
            </div>
          </Col>
        ) : (
          ""
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={30000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container style={{ margin: "5%" }}>
        {type === "New" ? (
          <Link to="/Newproductdetails" state={{ item: item }}>
            <AiOutlineLeft
              style={{
                color: "#e22217",
                fontSize: "28px",
                marginBottom: "4px",
              }}
            />
            <span
              style={{
                color: "#e22217",
                fontSize: "22px",
                fontWeight: "bold",
              }}
            >
              Modify your booking
            </span>
          </Link>
        ) : (
          <Link to="/Brandproductdetails" state={{ item: item }}>
            <AiOutlineLeft
              style={{
                color: "#e22217",
                fontSize: "28px",
                marginBottom: "4px",
              }}
            />
            <span
              style={{
                color: "#e22217",
                fontSize: "22px",
                fontWeight: "bold",
              }}
            >
              Modify your booking
            </span>
          </Link>
        )}

        <Row className="me-0">
          <Col md={6}>
            <div
              style={{
                backgroundColor: "#fef6e9",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
                borderColor: "#e22217",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  color: "#e22217",
                  fontSize: "18px",
                }}
              >
                Yay! you just saved ₹ {TotalDiscount?.toFixed(2)} on this
                booking!
              </p>
            </div>
            <div
              style={{
                border: "1px solid rgb(217 212 208)",
                padding: "15px",
                margin: "10px",
                borderRadius: "5px",
              }}
            >
              <Row className="me-0 mt-2">
                <Col md={6} style={{ fontWeight: "700" }}>
                  Service Name :{" "}
                </Col>
                <Col md={6}>{service?.ServiceName}</Col>
              </Row>
              <Row className="me-0 mt-2">
                <Col md={6} style={{ fontWeight: "700" }}>
                  Valid For :{" "}
                </Col>
                <Col md={6}>{service?.ValidFor}</Col>
              </Row>
              {/* 
              <Row className="me-0 mt-2">
                <Col md={6} style={{ fontWeight: "700" }}>
                  Select Date :{" "}
                </Col>
                <Col md={6}>
                  <select
                    style={{ width: "100%", padding: "3px" }}
                    onChange={(e) => setslotbooked(e.target.value)}
                  >
                    <option>Select</option>
                    {service?.slots?.map((dat) => {
                      const formattedDate1 = moment(dat?.slotdate).format(
                        "DD/MM/YYYY"
                      );
                      const formattedDate2 = moment().format("DD/MM/YYYY");

                      const momentDate1 = moment(formattedDate1, "DD/MM/YYYY");
                      const momentDate2 = moment(formattedDate2, "DD/MM/YYYY");
                      const isDate1BeforeDate2 =
                        momentDate1.isBefore(momentDate2);
                      const isDate1AfterDate2 =
                        momentDate1.isAfter(momentDate2);
                      const isSameDate = momentDate1.isSame(momentDate2);

                      // Filter dates that are today or later
                      // const futureDates = datesToDisplay.filter((date) => moment(date).isSameOrAfter(today));

                      console.log(
                        "Date 1 is after Date 2:",
                        isDate1BeforeDate2,
                        isDate1AfterDate2,
                        isSameDate,
                        momentDate1,
                        momentDate2
                      );

                      return service?.ValidFor === "Person" ? (
                        dat?.no_Booking_slot > 0 &&
                        (isDate1AfterDate2 || isSameDate) ? (
                          <option
                            value={`${dat?._id}|${dat?.slotdate}|${dat?.slot_from_time}|${dat?.slot_To_time}`}
                          >
                            {dat?.slotdate}&nbsp; / &nbsp;
                            {dat?.slot_from_time} -{dat?.slot_To_time}
                          </option>
                        ) : (
                          ""
                        )
                      ) : dat?.no_Booking_slot > 1 &&
                        (isDate1AfterDate2 || isSameDate) ? (
                        <option
                          value={`${dat?._id}|${dat?.slotdate}|${dat?.slot_from_time}|${dat?.slot_To_time}`}
                        >
                          {dat?.slotdate}&nbsp; / &nbsp;
                          {dat?.slot_from_time} -{dat?.slot_To_time}
                        </option>
                      ) : (
                        ""
                      );
                    })}
                  </select>
                </Col>
              </Row>
              {service?.ValidFor === "Person" ? (
                <Row className="me-0 mt-2">
                  <Col md={6} style={{ fontWeight: "700" }}>
                    Select Therapist :{" "}
                  </Col>
                  <Col md={6}>
                    <div
                      style={{ border: "1px solid gray" }}
                      onClick={handleShow}
                    >
                      <p style={{ marginBottom: "0px", marginLeft: "6px" }}>
                        Select
                      </p>
                    </div>
                  </Col>
                </Row>
              ) : (
                <>
                  <Row className="me-0 mt-2">
                    <Col md={6} style={{ fontWeight: "700" }}>
                      Select Therapist1 :{" "}
                    </Col>
                    <Col md={6}>
                      <div
                        style={{ border: "1px solid gray" }}
                        onClick={handleShow}
                      >
                        <p style={{ marginBottom: "0px", marginLeft: "6px" }}>
                          Select
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="me-0 mt-2">
                    <Col md={6} style={{ fontWeight: "700" }}>
                      Select Therapist2 :{" "}
                    </Col>
                    <Col md={6}>
                      <div
                        style={{ border: "1px solid gray" }}
                        onClick={handleShow1}
                      >
                        <p style={{ marginBottom: "0px", marginLeft: "6px" }}>
                          Select
                        </p>
                      </div>
                    </Col>
                  </Row>
                </>
              )} */}

              <Row className="me-0 mt-2">
                <Col md={6} style={{ fontWeight: "700" }}>
                  Add Promocode :{" "}
                </Col>
                <Col md={4}>
                  <p
                    style={{
                      marginBottom: "0px",
                      fontSize: "12px",
                      color: "rgb(226, 34, 23)",
                      fontWeight: "bold",
                    }}
                    onClick={handleShow}
                  >
                    View & Add Promocode
                  </p>
                  <input
                    placeholder="Promocode"
                    style={{ width: "100%" }}
                    onChange={(e) => setpromocode(e.target.value)}
                  />
                </Col>
                <Col md={2}>
                  <button
                    style={{
                      width: "100%",
                      backgroundColor: "rgb(226, 34, 23)",
                      color: "white",
                      border: "none",
                      marginTop: "18px",
                    }}
                    onClick={promocodevalue}
                  >
                    Add
                  </button>
                </Col>
              </Row>
            </div>

            <div
              style={{
                border: "1px solid rgb(217 212 208)",
                padding: "15px",
                margin: "10px",
                borderRadius: "5px",
              }}
            >
              <h5>Choose payment method to pay</h5>
              <div style={{ backgroundColor: "#f3f5f7" }}>
                <BsShieldCheck /> &nbsp;
                <span>100% safe and secure payments</span>
              </div>
              <div style={{ marginTop: "20px" }}>
                <span style={{ color: "rgb(26, 182, 79)" }}>
                  Pay online to get 5% Extra discount
                </span>
              </div>
              <div style={{ marginTop: "30px" }}>
                <button
                  style={{
                    padding: item?.type === "Spa" ? "2% 8%" : "2% 5%",
                    backgroundColor:
                      paymentType === "Pay At Spa"
                        ? "rgb(26, 182, 79)"
                        : "transparent",
                    borderRadius: "5px",
                    margin: "8px",
                    borderColor:
                      paymentType === "Pay At Spa"
                        ? "rgb(26, 182, 79)"
                        : "black",
                    color: paymentType === "Pay At Spa" ? "white" : "black",
                    fontWeight: paymentType === "Pay At Spa" ? "bold" : "200",
                  }}
                  onClick={() => setpaymentType("Pay At Spa")}
                >
                  {item?.type === "Spa" ? "Pay At Spa" : "Pay At Salon"}
                </button>
                <button
                  style={{
                    padding: "2% 8%",
                    backgroundColor:
                      paymentType === "Online Payment"
                        ? "rgb(26, 182, 79)"
                        : "transparent",
                    borderRadius: "5px",
                    margin: "8px",
                    borderColor:
                      paymentType === "Online Payment"
                        ? "rgb(26, 182, 79)"
                        : "black",
                    color: paymentType === "Online Payment" ? "white" : "black",
                    fontWeight:
                      paymentType === "Online Payment" ? "bold" : "200",
                  }}
                  onClick={() => setpaymentType("Online Payment")}
                >
                  Pay Now
                </button>
                <button
                  style={{
                    padding: "2% 8%",
                    backgroundColor:
                      paymentType === "Pay 20%"
                        ? "rgb(26, 182, 79)"
                        : "transparent",
                    borderRadius: "5px",
                    margin: "8px",
                    borderColor:
                      paymentType === "Pay 20%" ? "rgb(26, 182, 79)" : "black",
                    color: paymentType === "Pay 20%" ? "white" : "black",
                    fontWeight: paymentType === "Pay 20%" ? "bold" : "200",
                  }}
                  onClick={() => setpaymentType("Pay 20%")}
                >
                  Pay 20%
                </button>
              </div>

              {paymentType === "Pay At Spa" ? (
                <div style={{ textAlign: "center", margin: "10px" }}>
                  <i class="fas fa-star" style={{ color: "gold" }}></i>{" "}
                  <span style={{ fontWeight: "bold" }}>
                    No payment needed today
                  </span>
                  <p style={{ textAlign: "center" }}>
                    We will confirm your Booking without any charge. Pay
                    directly at the Spa during your Service.
                  </p>
                  <button
                    style={{
                      backgroundColor: "#1ab64f",
                      color: "white",
                      border: "none",
                      marginLeft: "20px",
                      padding: "2% 10%",
                      borderRadius: "5px",
                      fontWeight: "bold",
                    }}
                    onClick={placeorder}
                  >
                    Book Now
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col md={6}>
            <div
              style={{
                border: "1px solid rgb(217 212 208)",
                padding: "15px",
                margin: "10px",
                borderRadius: "5px",
              }}
            >
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginBottom: "0px",
                  color: "#e22217",
                }}
              >
                {service?.VendorBusinessName}
              </p>
              <p
                style={{
                  fontSize: "16px",
                  marginBottom: "0px",
                }}
              >
                {service?.VendorAddress}
              </p>
              <div style={{ marginTop: "8px" }}>
                {/* <span>Ratings :</span>&nbsp; */}
                <span
                  style={{
                    backgroundColor: "#1ab64f",
                    color: "white",
                    padding: "0px 5px",
                    width: "10%",
                  }}
                >
                  {type === "New"
                    ? isNaN(
                        item?.reviews?.reduce((a, rev) => a + rev.rating, 0) /
                          item?.reviews?.length
                      )
                      ? 0
                      : (
                          item?.reviews?.reduce((a, rev) => a + rev.rating, 0) /
                          item?.reviews?.length
                        )?.toFixed(1)
                    : isNaN(
                        item?.vendors[0]?.reviews?.reduce(
                          (a, rev) => a + rev.rating,
                          0
                        ) / item?.vendors[0]?.reviews?.length
                      )
                    ? 0
                    : (
                        item?.vendors[0]?.reviews?.reduce(
                          (a, rev) => a + rev.rating,
                          0
                        ) / item?.vendors[0]?.reviews?.length
                      )?.toFixed(1)}
                  &nbsp;
                  <i class="fas fa-star"></i>
                </span>
                <h5 style={{ marginTop: "20px" }}>
                  {" "}
                  {service?.ServiceName}({service?.Service_Duration} min)
                  {service?.advantages?.map((adv) => (
                    <>
                      <span>
                        + {adv.adv_name} ({adv.adv_duration} min)
                      </span>
                    </>
                  ))}
                </h5>
                {/* <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    marginBottom: "0px",
                  }}
                >
                  Name:9 OYOs in Ooty, Tamil Nadu, India
                </p> */}
                {slotbookedArray?.length > 0 ? (
                  <div>
                    <BiCalendar />
                    &nbsp;
                    <span style={{ fontWeight: "500" }}>
                      {slotbookedArray[1]}, {slotbookedArray[2]} -{" "}
                      {slotbookedArray[3]}
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {Therapist == "No Preference" ? (
                  <>
                    <div style={{ display: "flex" }}>
                      <i class="fas fa-user" style={{ margin: "5px" }}></i>
                      <p style={{ marginBottom: "0px" }}>No Preference</p>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    {Therapist?.ViewProfileImageToCustomer ? (
                      <img
                        src={
                          "https://spazo.co.in/Employee/" + Therapist?.profile
                        }
                        width={50}
                        height={50}
                        onClick={() =>
                          window.open(
                            "https://spazo.co.in/Employee/" + Therapist.profile
                          )
                        }
                        style={{ borderRadius: "50%" }}
                      />
                    ) : (
                      <img
                        src="/Assets/profile.jpg"
                        width={50}
                        height={50}
                        style={{
                          borderRadius: "50%",
                        }}
                      />
                    )}

                    <div>
                      <p style={{ marginBottom: "0px", fontWeight: "700" }}>
                        {Therapist?.name}
                      </p>
                      <p style={{ marginBottom: "0px" }}>
                        {Therapist?.Specialization}
                      </p>
                    </div>
                  </div>
                )}
                <div style={{ display: "flex" }}>
                  <i class="fas fa-calendar-week" style={{ margin: "5px" }}></i>
                  <div>
                    <p style={{ marginBottom: "0px" }}>
                      {moment(date).format("MMMM Do YYYY")}
                    </p>

                    <p style={{ marginBottom: "0px" }}>
                      {fromTime} - {toTime}
                    </p>
                  </div>
                </div>
                {/* {Therapist1 === "" ? (
                  ""
                ) : Therapist1 == "No Preference" ? (
                  "No Preference"
                ) : Therapist1 ? (
                  Object.keys(Therapist1)?.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginTop: "10px",
                      }}
                    >
                    
                      <img
                        src={
                          "https://spazo.co.in/Employee/" +
                          Therapist1?.profile
                        }
                        width={50}
                        height={50}
                        onClick={() =>
                          window.open(
                            "https://spazo.co.in/Employee/" +
                              Therapist1.profile
                          )
                        }
                        style={{ borderRadius: "50%" }}
                      />
                      <div>
                        <p style={{ marginBottom: "0px", fontWeight: "700" }}>
                          {Therapist1?.name}
                        </p>
                        <p style={{ marginBottom: "0px" }}>
                          {Therapist1?.Specialization}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )} */}
              </div>
              <hr></hr>
              <div>
                <Row className="me-0">
                  <Col md={6}>Price</Col>
                  <Col md={6}>
                    <p style={{ textAlign: "right", fontWeight: "bold" }}>
                      ₹{price}
                    </p>
                  </Col>
                </Row>{" "}
                <Row className="me-0">
                  <Col md={6}>GST</Col>
                  <Col md={6}>
                    <p style={{ textAlign: "right", fontWeight: "bold" }}>
                      ₹{GST}
                    </p>
                  </Col>
                </Row>{" "}
                <Row className="me-0">
                  <Col md={6}>Service Tax</Col>
                  <Col md={6}>
                    <p style={{ textAlign: "right", fontWeight: "bold" }}>
                      ₹{ServiceTax}
                    </p>
                  </Col>
                </Row>{" "}
                <Row className="me-0">
                  <Col md={6}>Discount</Col>
                  <Col md={6}>
                    <p style={{ textAlign: "right", fontWeight: "bold" }}>
                      ₹{Discount}
                    </p>
                  </Col>
                </Row>{" "}
                {promocodeamount ? (
                  Object.keys(promocodeamount)?.length > 0 ? (
                    <Row className="me-0">
                      <Col md={6}>Coupon Discount</Col>
                      <Col md={6}>
                        <p style={{ textAlign: "right", fontWeight: "bold" }}>
                          ₹{promocodeamount?.amount}
                        </p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {paymentType !== "Pay At Spa" ? (
                  <Row className="me-0">
                    <Col md={6}>Online Payment Discount</Col>
                    <Col md={6}>
                      <p style={{ textAlign: "right", fontWeight: "bold" }}>
                        ₹{onlinepaymentdiscount}
                      </p>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <hr></hr>
                <Row className="me-0">
                  <Col md={6}>Total Amount</Col>
                  <Col md={6}>
                    <p style={{ textAlign: "right", fontWeight: "bold" }}>
                      ₹{TotalAmount}
                    </p>
                  </Col>
                </Row>
                <Row className="me-0">
                  <Col md={6}>Total Discount</Col>
                  <Col md={6}>
                    <p style={{ textAlign: "right", fontWeight: "bold" }}>
                      ₹{TotalDiscount?.toFixed(2)}
                    </p>
                  </Col>
                </Row>
                <hr></hr>
                <Row
                  className="me-0"
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  <Col md={6}>Payable Amount</Col>
                  <Col md={6}>
                    <p style={{ textAlign: "right", fontWeight: "bold" }}>
                      ₹
                      {paymentType === "Pay 20%"
                        ? pay20?.toFixed(2)
                        : PayableAmount?.toFixed(2)}
                    </p>
                  </Col>
                </Row>
              </div>
              {paymentType !== "Pay At Spa" ? (
                <div style={{ textAlign: "center", marginTop: "32px" }}>
                  <button
                    style={{
                      backgroundColor: "#1ab64f",
                      color: "white",
                      border: "none",
                      marginLeft: "20px",
                      padding: "2% 10%",
                      borderRadius: "5px",
                      fontWeight: "bold",
                    }}
                    onClick={placeorder}
                  >
                    Book Now
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Promocode's</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {promocodearray?.map((prom) => {
                const today = new Date(); // Get today's date
                const endDate = new Date(prom?.Enddate); // Convert Enddate to a Date object

                // Set the time of both dates to 00:00:00 to only compare the date part
                today.setHours(0, 0, 0, 0);
                endDate.setHours(0, 0, 0, 0);

                const status = endDate < today ? "Expired" : "Valid";
                return (
                  <>
                    <div
                      style={{
                        boxShadow: "3px 4px 10px #cbc6c6",
                        padding: "10px",
                        backgroundColor: "white",
                        margin: "10px",
                      }}
                    >
                      <p>Win Rs {prom?.amount} instant off code</p>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            border: "1px dotted gray",
                            padding: "10px",
                            marginBottom: "0px",
                            color: "#e22217",
                          }}
                        >
                          {prom?.Promocode}
                        </p>
                        {status === "Expired" ? (
                          ""
                        ) : (
                          <button
                            style={{ borderColor: "gray", padding: "10px" }}
                            onClick={() => handleCopy(prom?.Promocode)}
                          >
                            Copy
                          </button>
                        )}
                      </div>

                      <div className="mt-3">
                        {status === "Expired" ? (
                          <span>Sorry..! Validity Expired</span>
                        ) : (
                          <span>
                            Valid till{" "}
                            {moment(prom?.Enddate).format("MMM Do YY")}
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show1} onHide={handleClose1} className="modal1111">
          <Modal.Header closeButton style={{ backgroundColor: "#f3f5f7" }}>
            <Modal.Title>Promocode's</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ border: "1px dotted gray", margin: "10px" }}>
              <Row>
                <Col
                  md={4}
                  style={{
                    borderRight: "1px dotted gray",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                      marginTop: "100%",
                    }}
                  >
                    Win Rs {cupondata?.amount} instant off code
                  </p>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        border: "1px dotted gray",
                        padding: "10px",
                        marginBottom: "0px",
                        color: "#e22217",
                      }}
                    >
                      {cupondata?.Promocode}
                    </p>
                    <button
                      style={{ borderColor: "gray", padding: "10px" }}
                      onClick={() => handleCopy(cupondata?.Promocode)}
                    >
                      Copy
                    </button>
                  </div>
                </Col>
                <Col md={8} style={{ padding: "2px" }}>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    Terms & Conditions
                  </p>
                  <div style={{ textAlign: "justify", fontSize: "16px" }}>
                    {cupondata?.TandC ? parse(cupondata?.TandC) : ""}
                  </div>
                </Col>
              </Row>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* <Modal show={show} onHide={handleClose}>
          <Modal.Header
            closeButton
            style={{ backgroundColor: "rgb(226, 34, 23)", color: "white" }}
          >
            <Modal.Title>Select Staff</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <>
                <div
                  style={{
                    border: "1px solid gray",
                    padding: "20px",
                    borderRadius: "10px 10px 0px 0px",
                    justifyContent: "center",
                    backgroundColor: "#f3f5f7",
                  }}
                  onClick={() => {
                    setTherapist("No Preference");
                    handleClose();
                  }}
                >
                  <Row>
                    <Col md={2}>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid gray",
                          padding: "15px 12px",
                          borderRadius: "50%",
                        }}
                      >
                        <i class="fas fa-users"></i>
                      </div>
                    </Col>
                    <Col md={8}>
                      <div>
                        <p
                          style={{
                            marginBottom: "0px",
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          No Preference
                        </p>
                        <p>Maximum availability</p>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          padding: "15px 12px",
                        }}
                      >
                        <i class="fas fa-chevron-right"></i>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>

              <div
                style={{
                  border: "1px solid gray",
                  borderRadius: "0px 0px 10px 10px",
                  justifyContent: "center",
                  padding: "1%",
                  overflowY: "scroll",
                  height: "500px",
                  // overflowX: "hidden",
                }}
              >
                {newEmployee?.map((item) => (
                  <div
                    style={{
                      borderBottom: "1px solid gray",
                      justifyContent: "center",
                      padding: "1%",
                    }}
                    onClick={() => {
                      setTherapist(item);
                      handleClose();
                    }}
                  >
                    <Row>
                      <Col md={2}>
                        <div
                        // style={{
                        //   justifyContent: "center",
                        //   display: "flex",
                        //   alignItems: "center",
                        //   border: "1px solid gray",
                        //   padding: "15px 12px",
                        //   borderRadius: "50%",
                        // }}
                        >
                          <img
                            src={
                              "https://spazo.co.in/Employee/" + item?.profile
                            }
                            width={50}
                            height={50}
                            onClick={() =>
                              window.open(
                                "https://spazo.co.in/Employee/" + item.profile
                              )
                            }
                            style={{ borderRadius: "50%" }}
                          />
                        </div>
                      </Col>
                      <Col md={8}>
                        <div>
                          <p
                            style={{
                              marginBottom: "0px",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            {item?.name}
                          </p>
                          <p>{item?.Specialization}</p>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                            padding: "15px 12px",
                          }}
                        >
                          <i class="fas fa-chevron-right"></i>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
             
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header
            closeButton
            style={{ backgroundColor: "rgb(226, 34, 23)", color: "white" }}
          >
            <Modal.Title>Select Staff</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <>
                <div
                  style={{
                    border: "1px solid gray",
                    padding: "20px",
                    borderRadius: "10px 10px 0px 0px",
                    justifyContent: "center",
                    backgroundColor: "#f3f5f7",
                  }}
                  onClick={() => {
                    setTherapist1("No Preference");
                    handleClose1();
                  }}
                >
                  <Row>
                    <Col md={2}>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid gray",
                          padding: "15px 12px",
                          borderRadius: "50%",
                        }}
                      >
                        <i class="fas fa-users"></i>
                      </div>
                    </Col>
                    <Col md={8}>
                      <div>
                        <p
                          style={{
                            marginBottom: "0px",
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          No Preference
                        </p>
                        <p>Maximum availability</p>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          padding: "15px 12px",
                        }}
                      >
                        <i class="fas fa-chevron-right"></i>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>

              <div
                style={{
                  border: "1px solid gray",
                  borderRadius: "0px 0px 10px 10px",
                  justifyContent: "center",
                  padding: "1%",
                  overflowY: "scroll",
                  height: "500px",
                  // overflowX: "hidden",
                }}
              >
                {newEmployee?.map((item) => (
                  <div
                    style={{
                      borderBottom: "1px solid gray",
                      justifyContent: "center",
                      padding: "1%",
                    }}
                    onClick={() => {
                      setTherapist1(item);
                      handleClose1();
                    }}
                  >
                    <Row>
                      <Col md={2}>
                        <div>
                          <img
                            src={
                              "https://spazo.co.in/Employee/" + item?.profile
                            }
                            width={50}
                            height={50}
                            onClick={() =>
                              window.open(
                                "https://spazo.co.in/Employee/" + item.profile
                              )
                            }
                            style={{ borderRadius: "50%" }}
                          />
                        </div>
                      </Col>
                      <Col md={8}>
                        <div>
                          <p
                            style={{
                              marginBottom: "0px",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            {item?.name}
                          </p>
                          <p>{item?.Specialization}</p>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                            padding: "15px 12px",
                          }}
                        >
                          <i class="fas fa-chevron-right"></i>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>
        </Modal> */}
      </Container>
    </>
  );
}

export default Checkout;
