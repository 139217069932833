import React from "react";
import { IoLocationOutline } from "react-icons/io5";
import { Select, Space } from "antd";
import "../Styles/header.css";
import colors from "./Constant/Color";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

function Topheader() {
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const user = JSON.parse(sessionStorage.getItem("user"));
  const [search, setsearch] = useState([]);
  const [data, setdata] = useState([]);
  const [location, setlocation] = useState("");

  function handlefilter(event) {
    const searchword = event.target.value;
    const newfilter = data.filter((value) => {
      return value.ServiceName.toLowerCase().includes(searchword.toLowerCase());
    });
    if (searchword == "") {
      setsearch([]);
    } else {
      setsearch(newfilter);
    }
  }

  useEffect(() => {
    getallservices();
  }, []);

  const getallservices = () => {
    axios
      .get("https://spazo.co.in/api/vendor/getService")
      .then(function (response) {
        console.log(response.data);
        setdata(response.data.Service);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const signout = () => {
    try {
      axios
        .get("https://spazo.co.in/api/customer/signout/" + user._id)
        .then(function (res) {
          if (res.status == 200) {
            sessionStorage.removeItem("user");
            alert("Signout Success....!");
            window.location.assign("/");
            return;
          } else {
            alert("Signout Unsuccessfully");
            return;
          }
        });
    } catch (error) {
      console.warn(error);
      alert("Signout Unsuccessfully");
    }
  };

  const Vendorsignout = () => {
    try {
      axios
        .get("https://spazo.co.in/api/vendor/Vendorsignout/" + user._id)
        .then(function (res) {
          if (res.status == 200) {
            sessionStorage.removeItem("user");
            alert("Signout Success....!");
            window.location.assign("/");
            return;
          } else {
            alert("Signout Unsuccessfully");
            return;
          }
        });
    } catch (error) {
      console.warn(error);
      alert("Signout Unsuccessfully");
    }
  };
  return (
    <>
      <div
        className=""
        style={{ position: "relative", borderBottom: "1px solid #e0e0e0" }}
      >
        <div
          className=""
          style={{
            // backgroundColor: `${colors.header}`,
            // position: "fixed",
            width: "100%",
            zIndex: "999",
          }}
        >
          <div className="top-h p-2">
            <div className="spa">
              {/* <div className="city d-flex">
                <div className="lo-2 d-flex">
                  <span
                    className="px-2"
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <IoLocationOutline />
                  </span>{" "}
                  <div className="mt-1">Select Location</div>
                </div>
                <div className="sels px-3">
                  <Space wrap>
                    <Select
                      defaultValue="Bangaluru"
                      style={{
                        width: 130,
                      }}
                      onChange={handleChange}
                      options={[
                        {
                          value: "Bangaluru",
                          label: "Bangaluru",
                        },
                        {
                          value: "Pune",
                          label: "Pune",
                        },
                        {
                          value: "Chennai",
                          label: "Chennai",
                        },
                      ]}
                    />
                  </Space>
                </div>
              </div> */}
              <div className="log0">
                <img
                  src="./logo.jpg"
                  style={{ width: "110px", height: "38px" }}
                />
              </div>
              <div className="sea" style={{ border: "none" }}>
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyACW1po0qU1jptIybBPGdFY-_MrycQPjfk"
                  selectProps={{
                    isMulti: true,
                    placeholder: "Select Location",
                    isClearable: true,
                    value: location,
                    onChange: (val) => {
                      setlocation(val);
                    },
                  }}
                  style={{ width: "100%" }}
                />
                {/* <span
                  className="sea-0"
                  style={{ backgroundColor: "white", color: "#e22217" }}
                  // onClick={handlefilter}
                >
                  <IoLocationOutline />
                </span> */}
                {search.length !== 0 ? (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 1000,
                      background: "white",
                      border: "1px solid #e5e5e5",
                      padding: "2px",
                      marginTop: "137px",
                      width: "527px",
                      marginLeft: "-6px",
                      borderTop: "none",
                      borderBottomLeftRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                  >
                    {search.slice(0, 2)?.map((item) => {
                      return (
                        <div>
                          <Link
                            to="/serviceProduct"
                            state={{ ser: item.ServiceName }}
                            style={{
                              textDecoration: "none",
                              color: "#e22217",
                            }}
                          >
                            <p>{item.ServiceName}</p>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="righ-f d-flex">
                <div className="business px-3">
                  {user && user?.role === "Vendor" ? (
                    <div className="log-dpa">
                      <a
                        onClick={Vendorsignout}
                        style={{ color: "#e22217", fontWeight: 800 }}
                      >
                        Logout
                      </a>
                    </div>
                  ) : user && user?.role === "Customer" ? (
                    <></>
                  ) : (
                    <div className="log-dpa">
                      {/* <a href="/business">List your Business</a> */}
                      <a href="/business">For Business</a>
                    </div>
                  )}
                </div>
                <div className="log-sig">
                  {user && user?.role === "Customer" ? (
                    <div className="log-dpa">
                      <a
                        onClick={signout}
                        style={{ color: "#e22217", fontWeight: 800 }}
                      >
                        Logout
                      </a>
                    </div>
                  ) : user && user?.role === "Vendor" ? (
                    <></>
                  ) : (
                    <div className="log-dpa">
                      {/* <a href="/login">Login / Signup</a> */}
                      <a href="/login">For Customer</a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mid header */}

        {/* <div style={{ backgroundColor: `${colors.deepyellow}` }}>
          <div className="conatiner">
            <div className="mid-h ">
              <div className="log0">
                <img
                  src="./logo.jpg"
                  style={{ width: "315px", height: "92px" }}
                />
              </div>
              <div className="sea">
                <input
                  type="text"
                  placeholder="Search spa event ..."
                  style={{
                    padding: "6px 25px 6px 11px",
                    border: "none",
                    outlineWidth: "0px",
                    width: "100%",
                    backgroundColor: `${colors.deepyellow}`,
                  }}
                  onChange={(e) => handlefilter(e)}
                />
                <span
                  className="sea-0"
                  style={{ backgroundColor: `${colors.header}` }}
                  // onClick={handlefilter}
                >
                  Search
                </span>
                {search.length !== 0 ? (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 1000,
                      background: "white",
                      border: "1px solid #e5e5e5",
                      padding: "2px",
                      marginTop: "137px",
                      width: "527px",
                      marginLeft: "-6px",
                      borderTop: "none",
                      borderBottomLeftRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                  >
                    {search.slice(0, 2)?.map((item) => {
                      return (
                        <div>
                          <Link
                            to="/serviceProduct"
                            state={{ ser: item.ServiceName }}
                            style={{
                              textDecoration: "none",
                              color: "#e22217",
                            }}
                          >
                            <p>{item.ServiceName}</p>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Topheader;
