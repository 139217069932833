import React, { useEffect, useState } from "react";
import Newheader from "./Newheader";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import OwlCarousel from "react-owl-carousel";
import NewFooter from "./NewFooter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiCurrentLocation } from "react-icons/bi";
import ReactStars from "react-rating-stars-component";
import GoogleMaps from "react-google-maps";
import { Container } from "react-bootstrap";
import { Blurhash } from "react-blurhash";
// import "googlemaps/dist/googlemaps.min.js";

function NewHome() {
  const notify2 = (data) => toast.warn(data);
  const [type, settype] = useState("Spa");

  document.title = "SPA-Home";
  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 8,
  //   slidesToScroll: 1,
  // };

  const [currentaddress, setcurrentaddress] = useState("");
  const [currentlatitude, setcurrentlatitude] = useState();
  const [currentlongitude, setcurrentlongitude] = useState();

  const [address, setaddress] = useState("");
  const [banner, setbanner] = useState([]);
  const [category, setcategory] = useState([]);
  const [Service, setService] = useState([]);
  const [Spaservice, setSpaservice] = useState([]);
  const [Salonservice, setSalonservice] = useState([]);
  const [Vendor, setVendor] = useState([]);

  const [SpaVendor, setSpaVendor] = useState([]);
  const [SalonVendor, setSalonVendor] = useState([]);

  const [SpaTopbrands, setSpaTopbrands] = useState([]);
  const [salonTopbrands, setsalonTopbrands] = useState([]);
  const [BCPTopbrands, setBCPTopbrands] = useState([]);

  const [SpaStarhotels, setSpaStarhotels] = useState([]);
  const [salonStarhotels, setsalonStarhotels] = useState([]);
  const [BCPStarhotels, setBCPStarhotels] = useState([]);

  const [SpaMallsandshopping, setSpaMallsandshopping] = useState([]);
  const [salonMallsandshopping, setsalonMallsandshopping] = useState([]);
  const [BCPMallsandshopping, setBCPMallsandshopping] = useState([]);

  const [Bangalore, setbangalore] = useState([]);
  const [Mysore, setMysore] = useState([]);
  const [Chennai, setChennai] = useState([]);
  const [Delhi, setDelhi] = useState([]);
  const [Gurugon, setGurugon] = useState([]);
  const [Hyderabad, setHyderabad] = useState([]);
  const [Kolkata, setKolkata] = useState([]);
  const [Mumbai, setMumbai] = useState([]);
  const [Noida, setNoida] = useState([]);
  const [Pune, setPune] = useState([]);

  const SpauniqueArray = [];
  const SalonuniqueArray = [];
  const SpaVendorToprated = [];
  const SalonVendorToprated = [];
  const seenIdsAndNames = new Set();
  const seenIdsAndNames1 = new Set();

  for (const obj of Spaservice) {
    const key = obj.servicename;
    if (!seenIdsAndNames.has(key)) {
      seenIdsAndNames.add(key);
      SpauniqueArray.push(obj);
    }
  }

  for (const obj of Salonservice) {
    const key = obj.servicename;
    if (!seenIdsAndNames.has(key)) {
      seenIdsAndNames.add(key);
      SalonuniqueArray.push(obj);
    }
  }

  for (const obj of SpaVendor) {
    const ratingsss =
      obj?.reviews?.reduce((a, rev) => a + rev.rating, 0) /
      obj?.reviews?.length;
    const key = obj.businessName;
    if (!seenIdsAndNames1.has(key) && !isNaN(ratingsss) && ratingsss >= 4) {
      seenIdsAndNames1.add(key);
      SpaVendorToprated.push(obj);
    }
  }

  for (const obj of SalonVendor) {
    const ratingsss =
      obj?.reviews?.reduce((a, rev) => a + rev.rating, 0) /
      obj?.reviews?.length;
    const key = obj.businessName;
    if (!seenIdsAndNames1.has(key) && !isNaN(ratingsss) && ratingsss >= 4) {
      seenIdsAndNames1.add(key);
      SalonVendorToprated.push(obj);
    }
  }

  const Spacat = category?.filter((item) => item.cattype === "Spa");
  const Saloncat = category?.filter((item) => item.cattype === "Salon");
  const Bcpcat = category?.filter(
    (item) => item.cattype === "Beauty Care Products"
  );

  const Spabanner = banner?.filter((item) => item.bannertype === "Spa");
  const Salonbanner = banner?.filter((item) => item.bannertype === "Salon");
  const Bcpbanner = banner?.filter(
    (item) => item.bannertype === "Beauty Care Products"
  );

  useEffect(() => {
    getallBanners();
    getallCategory();
    getallservices();
    getallVendor();
    getTopbrands();
    getstarhotels();
    getMallandShopping();
  }, []);

  const getallBanners = () => {
    axios
      .get("https://spazo.co.in/api/admin/getbanner")
      .then(function (response) {
        console.log(response.data);
        setbanner(response.data.bannerList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getallCategory = () => {
    axios
      .get("https://spazo.co.in/api/admin/getcategory")
      .then(function (response) {
        console.log(response.data);
        setcategory(response.data.category);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // const getallservices = () => {
  //   axios
  //     .get("https://spazo.co.in/api/vendor/getService")
  //     .then(function (response) {
  //       console.log(response.data);
  //       setService(
  //         response.data.Service?.filter((item) => item.Approve === "Approved")
  //       );
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const getallservices = () => {
    axios
      .get("https://spazo.co.in/api/admin/getAdminservice")
      .then(function (response) {
        console.log("Service", response.data);
        setService(response.data.Adminservice);
        setSpaservice(
          response.data.Adminservice?.filter((item) => item.type === "Spa")
        );
        setSalonservice(
          response.data.Adminservice?.filter((item) => item.type === "Salon")
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getTopbrands = () => {
    axios
      .get("https://spazo.co.in/api/admin/getTopbrands")
      .then(function (response) {
        console.log("Topbrand", response.data.Topbrand);
        setSpaTopbrands(
          response.data.Topbrand?.filter((item) => item.type === "Spa")
        );
        setsalonTopbrands(
          response.data.Topbrand?.filter((item) => item.type === "Salon")
        );
        setBCPTopbrands(
          response.data.Topbrand?.filter(
            (item) => item.type === "Beauty Care Products"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getstarhotels = () => {
    axios
      .get("https://spazo.co.in/api/admin/getStarHotels")
      .then(function (response) {
        setSpaStarhotels(
          response.data.starHotels?.filter((item) => item.type === "Spa")
        );
        setsalonStarhotels(
          response.data.starHotels?.filter((item) => item.type === "Salon")
        );
        setBCPStarhotels(
          response.data.starHotels?.filter(
            (item) => item.type === "Beauty Care Products"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getMallandShopping = () => {
    axios
      .get("https://spazo.co.in/api/admin/getMallsandShopping")
      .then(function (response) {
        setSpaMallsandshopping(
          response.data.MallsandShopping?.filter((item) => item.type === "Spa")
        );
        setsalonMallsandshopping(
          response.data.MallsandShopping?.filter(
            (item) => item.type === "Salon"
          )
        );
        setBCPMallsandshopping(
          response.data.MallsandShopping?.filter(
            (item) => item.type === "Beauty Care Products"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getallVendor = () => {
    axios
      .get("https://spazo.co.in/api/vendor/allVendors")
      .then(function (response) {
        // console.log(response.data);
        setVendor(
          response.data.allVendors?.filter(
            (item) => item.approved === "Approved" && item.isBlocked === false
          )
        );
        setSpaVendor(
          response.data.allVendors?.filter(
            (item) =>
              item.approved === "Approved" &&
              item.isBlocked === false &&
              item.type === "Spa"
          )
        );
        setSalonVendor(
          response.data.allVendors?.filter(
            (item) =>
              item.approved === "Approved" &&
              item.isBlocked === false &&
              item.type === "Salon"
          )
        );
        setbangalore(
          response.data.allVendors?.filter(
            (item) =>
              item.address.toLowerCase().includes("bengaluru") ||
              item.address.toLowerCase().includes("bangalore")
          )
        );
        setMysore(
          response.data.allVendors?.filter(
            (item) =>
              item.address.toLowerCase().includes("mysore") ||
              item.address.toLowerCase().includes("mysuru")
          )
        );
        setChennai(
          response.data.allVendors?.filter((item) =>
            item.address.toLowerCase().includes("chennai")
          )
        );
        setDelhi(
          response.data.allVendors?.filter((item) =>
            item.address.toLowerCase().includes("delhi")
          )
        );
        setGurugon(
          response.data.allVendors?.filter(
            (item) =>
              item.address.toLowerCase().includes("gurugram") ||
              item.address.toLowerCase().includes("gurgaon")
          )
        );
        setHyderabad(
          response.data.allVendors?.filter((item) =>
            item.address.toLowerCase().includes("hyderabad")
          )
        );
        setKolkata(
          response.data.allVendors?.filter((item) =>
            item.address.toLowerCase().includes("kolkata")
          )
        );
        setMumbai(
          response.data.allVendors?.filter((item) =>
            item.address.toLowerCase().includes("mumbai")
          )
        );
        setNoida(
          response.data.allVendors?.filter((item) =>
            item.address.toLowerCase().includes("noida")
          )
        );
        setPune(
          response.data.allVendors?.filter((item) =>
            item.address.toLowerCase().includes("pune")
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          // Get the address from latitude and longitude using a reverse geocoding API
          const apiKey = "AIzaSyAeVFaPIlv5BTjUqMrBIVRfsM_SvDPegHg"; // Replace with your own API key
          const reverseGeocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
          try {
            const response = await axios.get(reverseGeocodingUrl);
            const address = response.data.results[0].formatted_address;
            console.log("response", response);
            setcurrentaddress(address);
            setcurrentlatitude(latitude);
            setcurrentlongitude(longitude);
          } catch (error) {
            console.error("Error fetching address:", error);
          }
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        },
        { enableHighAccuracy: true }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  };

  // console.log(
  //   "address",
  //   currentlatitude,
  //   currentlongitude,
  //   currentaddress,
  //   address
  // );

  // console.log("Spaservice", Spaservice);
  // console.log("Salonservice", Salonservice);
  // console.log("type", type);
  // console.log("SpaVendor", SpaVendor);

  const [filtereddata, setfiltereddata] = useState([]);
  const [Topratedfiltereddata, setTopratedfiltereddata] = useState([]);
  var citysettings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  var settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  var settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      type === "Spa"
        ? SpaVendorToprated?.length < 6
          ? SpaVendorToprated?.length
          : 6
        : type === "Salon"
        ? SalonVendorToprated?.length < 6
          ? SalonVendorToprated?.length
          : 6
        : type === "Beauty Care Products"
        ? ""
        : "",
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      type === "Spa"
        ? Spacat?.length < 10
          ? Spacat?.length
          : 6
        : type === "Salon"
        ? Saloncat?.length < 10
          ? Saloncat?.length
          : 6
        : type === "Beauty Care Products"
        ? ""
        : "",
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  var settings3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <>
      <div className="container-fluid p-0">
        <Newheader />
        {/* 
        <div className="cityrow">
          <Link
            style={{ color: "black" }}
            to="/Newproduct"
            state={{
              type: "Spa",
              address: "Bangalore",
            }}
          >
            <img
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5jgEsvy6MY1zCZDpJFKJ4kUJLCYCN57LbOQ&usqp=CAU"
            />
            &nbsp;
            <span>Bangalore</span>
          </Link>
          <Link
            style={{ color: "black" }}
            to="/Newproduct"
            state={{
              type: "Spa",
              address: "Mysore",
            }}
          >
            <img
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGpYFPGhnZcA9C6ON_Rhz4ch9hXQnL0jVluA&usqp=CAU"
            />
            &nbsp;
            <span>Mysore</span>
          </Link>
          <Link
            style={{ color: "black" }}
            to="/Newproduct"
            state={{
              type: "Spa",
              address: "Chennai",
            }}
          >
            <img
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPjVaDu5tYyiBE_9h6doPmU1drvWZgKEDg1ID6mgtJMZBcc6_lXLhHKNOvutx9B-d-y0E&usqp=CAU"
            />
            &nbsp;
            <span>Chennai</span>
          </Link>
          <Link
            style={{ color: "black" }}
            to="/Newproduct"
            state={{
              type: "Spa",
              address: "Delhi",
            }}
          >
            <img
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTzGyxAfpakfki8D26T0H1QLFcrsO19HT3r1gg5GaVAiV3JEZ2CTv5GSJCq_jvn3UNGQM&usqp=CAU"
            />
            &nbsp;
            <span>Delhi</span>
          </Link>
          <Link
            style={{ color: "black" }}
            to="/Newproduct"
            state={{
              type: "Spa",
              address: "Gurgaon",
            }}
          >
            <img
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEKuThcRvQeFtuc5pJrYWu-rL5VlsxP9RHSQ&usqp=CAU"
            />
            &nbsp;
            <span>Gurgaon</span>
          </Link>
          <Link
            style={{ color: "black" }}
            to="/Newproduct"
            state={{
              type: "Spa",
              address: "Hyderabad",
            }}
          >
            <img
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaAxK5yvjdiZFHps2MeBV1g4JH6_96xTl4fg&usqp=CAU"
            />
            &nbsp;
            <span>Hyderabad</span>
          </Link>
          <Link
            style={{ color: "black" }}
            to="/Newproduct"
            state={{
              type: "Spa",
              address: "Kolkata",
            }}
          >
            <img
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4IEdJgLkpe-AprM9i-Z8G0l4c7UvPrLZXeg&usqp=CAU"
            />
            &nbsp;
            <span>Kolkata</span>
          </Link>
          <Link
            style={{ color: "black" }}
            to="/Newproduct"
            state={{
              type: "Spa",
              address: "Mumbai",
            }}
          >
            <img
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfCzBL3oOq8fwC-yYeLGrcuo-qvnhRCLtnyg&usqp=CAU"
            />
            &nbsp;
            <span>Mumbai</span>
          </Link>
          <Link
            style={{ color: "black" }}
            to="/Newproduct"
            state={{
              type: "Spa",
              address: "Noida",
            }}
          >
            <img
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdIWH5ncEuyu7B41NNLbFjvsCi-mnypiYvnQ&usqp=CAU"
            />
            &nbsp;
            <span>Noida</span>
          </Link>
          <Link
            style={{ color: "black" }}
            to="/Newproduct"
            state={{
              type: "Spa",
              address: "Pune",
            }}
          >
            <img
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSHOv81wqGqxzCQ7KOgoRymaOmVoj53preaA&usqp=CAU"
            />
            &nbsp;
            <span>Pune</span>
          </Link>
        </div> */}
        {/* City */}
        {type === "Spa" ? (
          <div className="p-2" style={{ backgroundColor: "#f3f5f7" }}>
            <Slider {...citysettings}>
              <Link
                style={{ color: "black" }}
                to="/Cityproduct"
                state={{
                  type: "Spa",
                  address: "Bengaluru",
                }}
              >
                <div style={{ display: "flex" }}>
                  {Bangalore?.length > 0 ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "inline",
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGpYFPGhnZcA9C6ON_Rhz4ch9hXQnL0jVluA&usqp=CAU"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <Blurhash
                        hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                        width="100%"
                        height="100%"
                        resolutionX={30}
                        resolutionY={32}
                        punch={1}
                      />
                    </div>
                  )}
                  &nbsp;
                  <span
                    style={{
                      color: Bangalore?.length > 0 ? "black" : "gray",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Bangalore
                  </span>
                </div>
              </Link>
              <Link
                style={{ color: "black" }}
                to="/Cityproduct"
                state={{
                  type: "Spa",
                  address: "Mysore",
                }}
              >
                <div style={{ display: "flex" }}>
                  {Mysore?.length > 0 ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "inline",
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThjpwXUfjjBhareOeP0cyKgstH1tGavubkrw&s"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <Blurhash
                        hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                        width="100%"
                        height="100%"
                        resolutionX={30}
                        resolutionY={32}
                        punch={1}
                      />
                    </div>
                  )}
                  &nbsp;
                  <span
                    style={{
                      color: Mysore?.length > 0 ? "black" : "gray",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Mysore
                  </span>
                </div>
              </Link>
              <Link
                style={{ color: "black" }}
                to="/Cityproduct"
                state={{
                  type: "Spa",
                  address: "Chennai",
                }}
              >
                <div style={{ display: "flex" }}>
                  {Chennai?.length > 0 ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "inline",
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPjVaDu5tYyiBE_9h6doPmU1drvWZgKEDg1ID6mgtJMZBcc6_lXLhHKNOvutx9B-d-y0E&usqp=CAU"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <Blurhash
                        hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                        width="100%"
                        height="100%"
                        resolutionX={30}
                        resolutionY={32}
                        punch={1}
                      />
                    </div>
                  )}
                  &nbsp;
                  <span
                    style={{
                      color: Chennai?.length > 0 ? "black" : "gray",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Chennai
                  </span>
                </div>
              </Link>
              <Link
                style={{ color: "black" }}
                to="/Cityproduct"
                state={{
                  type: "Spa",
                  address: "Delhi",
                }}
              >
                <div style={{ display: "flex" }}>
                  {Delhi?.length > 0 ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "inline",
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBztIlDzvxvgMG5DJLUIkhUfeMzo0JoJbquw&s"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <Blurhash
                        hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                        width="100%"
                        height="100%"
                        resolutionX={30}
                        resolutionY={32}
                        punch={1}
                      />
                    </div>
                  )}
                  &nbsp;
                  <span
                    style={{
                      color: Delhi?.length > 0 ? "black" : "gray",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Delhi
                  </span>
                </div>
              </Link>
              <Link
                style={{ color: "black" }}
                to="/Cityproduct"
                state={{
                  type: "Spa",
                  address: "Gurgaon",
                }}
              >
                <div style={{ display: "flex" }}>
                  {Gurugon?.length > 0 ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "inline",
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEKuThcRvQeFtuc5pJrYWu-rL5VlsxP9RHSQ&usqp=CAU"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <Blurhash
                        hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                        width="100%"
                        height="100%"
                        resolutionX={30}
                        resolutionY={32}
                        punch={1}
                      />
                    </div>
                  )}
                  &nbsp;
                  <span
                    style={{
                      color: Gurugon?.length > 0 ? "black" : "gray",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Gurgaon
                  </span>
                </div>
              </Link>
              <Link
                style={{ color: "black" }}
                to="/Cityproduct"
                state={{
                  type: "Spa",
                  address: "Hyderabad",
                }}
              >
                <div style={{ display: "flex" }}>
                  {Hyderabad?.length > 0 ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "inline",
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaAxK5yvjdiZFHps2MeBV1g4JH6_96xTl4fg&usqp=CAU"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <Blurhash
                        hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                        width="100%"
                        height="100%"
                        resolutionX={30}
                        resolutionY={32}
                        punch={1}
                      />
                    </div>
                  )}
                  &nbsp;
                  <span
                    style={{
                      color: Hyderabad?.length > 0 ? "black" : "gray",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Hyderabad
                  </span>
                </div>
              </Link>
              <Link
                style={{ color: "black" }}
                to="/Cityproduct"
                state={{
                  type: "Spa",
                  address: "Kolkata",
                }}
              >
                <div style={{ display: "flex" }}>
                  {Kolkata?.length > 0 ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "inline",
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4IEdJgLkpe-AprM9i-Z8G0l4c7UvPrLZXeg&usqp=CAU"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <Blurhash
                        hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                        width="100%"
                        height="100%"
                        resolutionX={30}
                        resolutionY={32}
                        punch={1}
                      />
                    </div>
                  )}
                  &nbsp;
                  <span
                    style={{
                      color: Kolkata?.length > 0 ? "black" : "gray",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Kolkata
                  </span>
                </div>
              </Link>
              <Link
                style={{ color: "black" }}
                to="/Cityproduct"
                state={{
                  type: "Spa",
                  address: "Mumbai",
                }}
              >
                <div style={{ display: "flex" }}>
                  {Mumbai?.length > 0 ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "inline",
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfCzBL3oOq8fwC-yYeLGrcuo-qvnhRCLtnyg&usqp=CAU"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <Blurhash
                        hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                        width="100%"
                        height="100%"
                        resolutionX={30}
                        resolutionY={32}
                        punch={1}
                      />
                    </div>
                  )}
                  &nbsp;
                  <span
                    style={{
                      color: Mumbai?.length > 0 ? "black" : "gray",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Mumbai
                  </span>
                </div>
              </Link>
              <Link
                style={{ color: "black" }}
                to="/Cityproduct"
                state={{
                  type: "Spa",
                  address: "Noida",
                }}
              >
                <div style={{ display: "flex" }}>
                  {Noida?.length > 0 ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "inline",
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdIWH5ncEuyu7B41NNLbFjvsCi-mnypiYvnQ&usqp=CAU"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <Blurhash
                        hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                        width="100%"
                        height="100%"
                        resolutionX={30}
                        resolutionY={32}
                        punch={1}
                      />
                    </div>
                  )}
                  &nbsp;
                  <span
                    style={{
                      color: Noida?.length > 0 ? "black" : "gray",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Noida
                  </span>
                </div>
              </Link>
              <Link
                style={{ color: "black" }}
                to="/Cityproduct"
                state={{
                  type: "Spa",
                  address: "Pune",
                }}
              >
                <div style={{ display: "flex" }}>
                  {Pune?.length > 0 ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "inline",
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSHOv81wqGqxzCQ7KOgoRymaOmVoj53preaA&usqp=CAU"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <Blurhash
                        hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                        width="100%"
                        height="100%"
                        resolutionX={30}
                        resolutionY={32}
                        punch={1}
                      />
                    </div>
                  )}
                  &nbsp;
                  <span
                    style={{
                      color: Pune?.length > 0 ? "black" : "gray",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Pune
                  </span>
                </div>
              </Link>
            </Slider>
          </div>
        ) : type === "Salon" ? (
          <div className="p-2" style={{ backgroundColor: "#f3f5f7" }}>
            <Slider {...citysettings}>
              <Link
                style={{ color: "black" }}
                to="/Cityproduct"
                state={{
                  type: "Salon",
                  address: "Bengaluru",
                }}
              >
                <div style={{ display: "flex" }}>
                  {Bangalore?.length > 0 ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "inline",
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGpYFPGhnZcA9C6ON_Rhz4ch9hXQnL0jVluA&usqp=CAU"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <Blurhash
                        hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                        width="100%"
                        height="100%"
                        resolutionX={30}
                        resolutionY={32}
                        punch={1}
                      />
                    </div>
                  )}
                  &nbsp;
                  <span
                    style={{
                      color: Bangalore?.length > 0 ? "black" : "gray",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Bangalore
                  </span>
                </div>
              </Link>
              <Link
                style={{ color: "black" }}
                to="/Cityproduct"
                state={{
                  type: "Salon",
                  address: "Mysore",
                }}
              >
                <div style={{ display: "flex" }}>
                  {Mysore?.length > 0 ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "inline",
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThjpwXUfjjBhareOeP0cyKgstH1tGavubkrw&s"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <Blurhash
                        hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                        width="100%"
                        height="100%"
                        resolutionX={30}
                        resolutionY={32}
                        punch={1}
                      />
                    </div>
                  )}
                  &nbsp;
                  <span
                    style={{
                      color: Mysore?.length > 0 ? "black" : "gray",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Mysore
                  </span>
                </div>
              </Link>
              <Link
                style={{ color: "black" }}
                to="/Cityproduct"
                state={{
                  type: "Salon",
                  address: "Chennai",
                }}
              >
                <div style={{ display: "flex" }}>
                  {Chennai?.length > 0 ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "inline",
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPjVaDu5tYyiBE_9h6doPmU1drvWZgKEDg1ID6mgtJMZBcc6_lXLhHKNOvutx9B-d-y0E&usqp=CAU"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <Blurhash
                        hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                        width="100%"
                        height="100%"
                        resolutionX={30}
                        resolutionY={32}
                        punch={1}
                      />
                    </div>
                  )}
                  &nbsp;
                  <span
                    style={{
                      color: Chennai?.length > 0 ? "black" : "gray",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Chennai
                  </span>
                </div>
              </Link>
              <Link
                style={{ color: "black" }}
                to="/Cityproduct"
                state={{
                  type: "Salon",
                  address: "Delhi",
                }}
              >
                <div style={{ display: "flex" }}>
                  {Delhi?.length > 0 ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "inline",
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBztIlDzvxvgMG5DJLUIkhUfeMzo0JoJbquw&s"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <Blurhash
                        hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                        width="100%"
                        height="100%"
                        resolutionX={30}
                        resolutionY={32}
                        punch={1}
                      />
                    </div>
                  )}
                  &nbsp;
                  <span
                    style={{
                      color: Delhi?.length > 0 ? "black" : "gray",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Delhi
                  </span>
                </div>
              </Link>
              <Link
                style={{ color: "black" }}
                to="/Cityproduct"
                state={{
                  type: "Salon",
                  address: "Gurgaon",
                }}
              >
                <div style={{ display: "flex" }}>
                  {Gurugon?.length > 0 ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "inline",
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEKuThcRvQeFtuc5pJrYWu-rL5VlsxP9RHSQ&usqp=CAU"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <Blurhash
                        hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                        width="100%"
                        height="100%"
                        resolutionX={30}
                        resolutionY={32}
                        punch={1}
                      />
                    </div>
                  )}
                  &nbsp;
                  <span
                    style={{
                      color: Gurugon?.length > 0 ? "black" : "gray",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Gurgaon
                  </span>
                </div>
              </Link>
              <Link
                style={{ color: "black" }}
                to="/Cityproduct"
                state={{
                  type: "Salon",
                  address: "Hyderabad",
                }}
              >
                <div style={{ display: "flex" }}>
                  {Hyderabad?.length > 0 ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "inline",
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaAxK5yvjdiZFHps2MeBV1g4JH6_96xTl4fg&usqp=CAU"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <Blurhash
                        hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                        width="100%"
                        height="100%"
                        resolutionX={30}
                        resolutionY={32}
                        punch={1}
                      />
                    </div>
                  )}
                  &nbsp;
                  <span
                    style={{
                      color: Hyderabad?.length > 0 ? "black" : "gray",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Hyderabad
                  </span>
                </div>
              </Link>
              <Link
                style={{ color: "black" }}
                to="/Cityproduct"
                state={{
                  type: "Salon",
                  address: "Kolkata",
                }}
              >
                <div style={{ display: "flex" }}>
                  {Kolkata?.length > 0 ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "inline",
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4IEdJgLkpe-AprM9i-Z8G0l4c7UvPrLZXeg&usqp=CAU"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <Blurhash
                        hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                        width="100%"
                        height="100%"
                        resolutionX={30}
                        resolutionY={32}
                        punch={1}
                      />
                    </div>
                  )}
                  &nbsp;
                  <span
                    style={{
                      color: Kolkata?.length > 0 ? "black" : "gray",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Kolkata
                  </span>
                </div>
              </Link>
              <Link
                style={{ color: "black" }}
                to="/Cityproduct"
                state={{
                  type: "Salon",
                  address: "Mumbai",
                }}
              >
                <div style={{ display: "flex" }}>
                  {Mumbai?.length > 0 ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "inline",
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfCzBL3oOq8fwC-yYeLGrcuo-qvnhRCLtnyg&usqp=CAU"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <Blurhash
                        hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                        width="100%"
                        height="100%"
                        resolutionX={30}
                        resolutionY={32}
                        punch={1}
                      />
                    </div>
                  )}
                  &nbsp;
                  <span
                    style={{
                      color: Mumbai?.length > 0 ? "black" : "gray",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Mumbai
                  </span>
                </div>
              </Link>
              <Link
                style={{ color: "black" }}
                to="/Cityproduct"
                state={{
                  type: "Salon",
                  address: "Noida",
                }}
              >
                <div style={{ display: "flex" }}>
                  {Noida?.length > 0 ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "inline",
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdIWH5ncEuyu7B41NNLbFjvsCi-mnypiYvnQ&usqp=CAU"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <Blurhash
                        hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                        width="100%"
                        height="100%"
                        resolutionX={30}
                        resolutionY={32}
                        punch={1}
                      />
                    </div>
                  )}
                  &nbsp;
                  <span
                    style={{
                      color: Noida?.length > 0 ? "black" : "gray",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Noida
                  </span>
                </div>
              </Link>
              <Link
                style={{ color: "black" }}
                to="/Cityproduct"
                state={{
                  type: "Salon",
                  address: "Pune",
                }}
              >
                <div style={{ display: "flex" }}>
                  {Pune?.length > 0 ? (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "inline",
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSHOv81wqGqxzCQ7KOgoRymaOmVoj53preaA&usqp=CAU"
                    />
                  ) : (
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <Blurhash
                        hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                        width="100%"
                        height="100%"
                        resolutionX={30}
                        resolutionY={32}
                        punch={1}
                      />
                    </div>
                  )}
                  &nbsp;
                  <span
                    style={{
                      color: Pune?.length > 0 ? "black" : "gray",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Pune
                  </span>
                </div>
              </Link>
            </Slider>
          </div>
        ) : (
          ""
        )}
        <ToastContainer
          position="top-center"
          autoClose={30000}
          closeOnClick
          pauseOnHover
          draggable
        />
        {/* Search */}
        <div className="searchh">
          {/* <p
            style={{ color: "white", textAlign: "center", marginBottom: "0px" }}
          >
            Please Select a type before redirecting to other page
          </p> */}
          <div className="searchh1">
            <select
              className="searchselect"
              onChange={(e) => settype(e.target.value)}
            >
              {/* <option>select</option> */}
              <option value="Spa">Spa</option>
              <option value="Salon">Salon</option>
              <option value="Beauty Care Products">Beauty Care Products</option>
            </select>
            <div style={{ width: "40%", backgroundColor: "white" }}>
              {currentaddress ? (
                <span>{currentaddress}</span>
              ) : (
                <GooglePlacesAutocomplete
                  // apiKey="AIzaSyACW1po0qU1jptIybBPGdFY-_MrycQPjfk"
                  apiKey="AIzaSyAeVFaPIlv5BTjUqMrBIVRfsM_SvDPegHg"
                  selectProps={{
                    isMulti: false,
                    placeholder: "Enter your location",
                    isClearable: true,
                    value: address,
                    onChange: (val) => {
                      setaddress(val);
                    },
                  }}
                  style={{ width: "100%", border: "none", padding: "10px" }}
                />
              )}
            </div>
            <div
              style={{ backgroundColor: "white", padding: "10px" }}
              onClick={getLocation}
            >
              <BiCurrentLocation style={{ fontSize: "20px" }} />
              &nbsp;<span>Near Me</span>
            </div>
            {address || currentaddress ? (
              <Link
                className="homesearch"
                to="/Newproduct"
                state={{
                  type: type,
                  address: currentaddress ? currentaddress : address?.label,
                }}
              >
                Search
              </Link>
            ) : (
              <button
                className="homesearch"
                onClick={() => notify2("Please Enter Address")}
              >
                Search
              </button>
            )}
          </div>
        </div>

        <Carousel>
          {type === "Spa"
            ? Spabanner?.map((item) => (
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={"https://spazo.co.in/Banner/" + item.bannerimg}
                    alt="First slide"
                    style={{
                      backgroundPosition: "center",
                      backggroundSize: "cover",
                      height: "200px",
                      width: "100%",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  />
                  {/* <Carousel.Caption>
                    <div className="text-p">
                      <h3 className="animate__animated animate__fadeInDown">
                        {item.title}
                      </h3>
                      <h2 className="animate__animated tracking-in-contract">
                        {item.subtitle}
                      </h2>
                    </div>
                  </Carousel.Caption> */}
                </Carousel.Item>
              ))
            : type === "Salon"
            ? Salonbanner?.map((item) => (
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={"https://spazo.co.in/Banner/" + item.bannerimg}
                    alt="First slide"
                    style={{
                      backgroundPosition: "center",
                      backggroundSize: "cover",
                      height: "200px",
                      width: "100%",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  />
                  {/* <Carousel.Caption>
                    <div className="text-p">
                      <h3 className="animate__animated animate__fadeInDown">
                        {item.title}
                      </h3>
                      <h2 className="animate__animated tracking-in-contract">
                        {item.subtitle}
                      </h2>
                    </div>
                  </Carousel.Caption> */}
                </Carousel.Item>
              ))
            : type === "Beauty Care Products"
            ? Bcpbanner?.map((item) => (
                <Carousel.Item>
                  <img
                    className="d-block w-70"
                    src={"https://spazo.co.in/Banner/" + item.bannerimg}
                    alt="First slide"
                    style={{
                      backgroundPosition: "center",
                      backggroundSize: "cover",
                      height: "200px",
                      width: "100%",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  />
                  {/* <Carousel.Caption>
                    <div className="text-p">
                      <h3 className="animate__animated animate__fadeInDown">
                        {item.title}
                      </h3>
                      <h2 className="animate__animated tracking-in-contract">
                        {item.subtitle}
                      </h2>
                    </div>
                  </Carousel.Caption> */}
                </Carousel.Item>
              ))
            : ""}
        </Carousel>
        <Container fluid>
          {/* Brands To Look Out For! */}
          <div className="budget mt-2">
            <div className="container-fluid de-top">
              <h4>Brands To Look Out For! 👀</h4>
            </div>
            <Slider {...settings}>
              {type === "Spa"
                ? SpaTopbrands?.map((brand) => (
                    <Link to="/Brandproductdetails" state={{ item: brand }}>
                      <div className="brands">
                        <div className="brandsinside">
                          <img
                            src={
                              "https://spazo.co.in/Vendor/" +
                              brand?.vendors[0]?.logo
                            }
                            alt="spa-category"
                            style={{
                              width: "90px",
                              height: "90px",
                              borderRadius: "50px",
                            }}
                          />
                        </div>
                      </div>
                    </Link>
                  ))
                : type === "Salon"
                ? salonTopbrands?.map((brand) => (
                    <Link to="/Brandproductdetails" state={{ item: brand }}>
                      <div className="brands">
                        <div className="brandsinside">
                          <img
                            src={
                              "https://spazo.co.in/Vendor/" +
                              brand?.vendors[0]?.logo
                            }
                            alt="spa-category"
                            style={{
                              width: "90px",
                              height: "90px",
                              borderRadius: "50px",
                            }}
                          />
                        </div>
                      </div>
                    </Link>
                  ))
                : type === "Beauty Care Products"
                ? BCPTopbrands?.map((brand) => (
                    <Link to="/Brandproductdetails" state={{ item: brand }}>
                      <div className="brands">
                        <div className="brandsinside">
                          <img
                            src={
                              "https://spazo.co.in/Vendor/" +
                              brand?.vendors[0]?.logo
                            }
                            alt="spa-category"
                            style={{
                              width: "90px",
                              height: "90px",
                              borderRadius: "50px",
                            }}
                          />
                        </div>
                      </div>
                    </Link>
                  ))
                : ""}
            </Slider>
          </div>
          {/* Category */}
          <div className="budget mt-2">
            <div className="container-fluid de-top">
              <h4>Popular Categories</h4>
            </div>
            {/* <div style={{ margin: "0px 20px" }}> */}
            <Slider {...settings2}>
              {type === "Spa"
                ? Spacat?.map((cat) => (
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/Catproduct"
                      state={{ cat: cat?.catname, type: type }}
                    >
                      <div className="rslick">
                        <img
                          src={"https://spazo.co.in/Category/" + cat.catimage}
                          alt="spa-category"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "10px",
                          }}
                        />
                        <div
                          className="mt-2"
                          style={{
                            whiteSpace: "nowrap",
                            fontWeight: "bold",
                          }}
                        >
                          {cat.catname}
                        </div>
                      </div>
                    </Link>
                  ))
                : type === "Salon"
                ? Saloncat?.map((cat) => (
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/Catproduct"
                      state={{ cat: cat?.catname, type: type }}
                    >
                      <div className="rslick">
                        <img
                          src={"https://spazo.co.in/Category/" + cat.catimage}
                          alt="spa-category"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "10px",
                          }}
                        />
                        <div
                          className="mt-2"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {cat.catname}
                        </div>
                      </div>
                    </Link>
                  ))
                : type === "Beauty Care Products"
                ? Bcpcat?.map((cat) => (
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/CategoryProduct"
                      state={{ cat: cat?.catname }}
                    >
                      <div className="rslick">
                        <img
                          src={"https://spazo.co.in/Category/" + cat.catimage}
                          alt="spa-category"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "10px",
                          }}
                        />
                        <div
                          className="mt-2"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {cat.catname}
                        </div>
                      </div>
                    </Link>
                  ))
                : ""}
            </Slider>
            {/* </div> */}
          </div>
          {/* Service */}
          {type === "Spa" ? (
            <div className="budget mt-2">
              <div className="container-fluid de-top">
                {/* <h4>Relaxing Massages</h4> */}
                <h4>Services</h4>
              </div>
              {/* <div style={{ margin: "0px 10px" }}> */}
              <Slider {...settings}>
                {SpauniqueArray?.map((item) => (
                  <div class="item mt-3 mb-3">
                    <div className="spa-budget">
                      <ul>
                        <Link
                          to="/Serviceproduct"
                          state={{
                            ServiceName: item.servicename,
                            type: type,
                          }}
                          // style={{alignItems:"center",display:"grid"}}
                        >
                          <li>
                            <div className="budget-spa-0">
                              <div
                                className="spa-text-0"
                                style={{
                                  backgroundImage: `url("https://spazo.co.in/Adminservice/${item.serviceimage}")`,
                                  width: "131px",
                                  height: "131px",
                                  borderRadius: "50%",
                                }}
                              >
                                {/* <div className="dudget-diff-0">
                                  <h6>{item.servicename}</h6>
                                </div> */}
                              </div>
                            </div>
                          </li>
                          <div style={{ marginLeft: "5%" }}>
                            <span
                              style={{
                                textDecoration: "none",
                                color: "black",
                                textAlign: "center",
                              }}
                            >
                              {item.servicename}
                            </span>
                            &nbsp;
                            {SpaVendor?.filter((ven) =>
                              ven?.service?.find(
                                (ser) => ser?.ServiceName === item.servicename
                              )
                            )?.length > 0 ? (
                              <span
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                  textAlign: "center",
                                }}
                              >
                                (
                                {
                                  SpaVendor?.filter((ven) =>
                                    ven?.service?.find(
                                      (ser) =>
                                        ser?.ServiceName === item.servicename
                                    )
                                  )?.length
                                }
                                )
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </Link>
                      </ul>
                    </div>
                  </div>
                ))}
              </Slider>
              {/* </div> */}
            </div>
          ) : (
            ""
          )}

          {type === "Salon" ? (
            <div className="budget mt-2">
              <div className="container-fluid de-top">
                {/* <h4>Hair Treatment</h4> */}
                <h4>Services</h4>
              </div>
              <Slider {...settings}>
                {SalonuniqueArray?.map((item) => (
                  <div class="item mt-3 mb-3">
                    <div className="spa-budget">
                      <ul>
                        <Link
                          to="/Serviceproduct"
                          state={{
                            ServiceName: item.servicename,
                            type: type,
                          }}
                        >
                          <li>
                            <div className="budget-spa-0">
                              <div
                                className="spa-text-0"
                                style={{
                                  backgroundImage: `url("https://spazo.co.in/Adminservice/${item.serviceimage}")`,
                                  width: "131px",
                                  height: "131px",
                                  borderRadius: "50%",
                                }}
                              >
                                {/* <div className="dudget-diff-0">
                                  <h6>{item.servicename}</h6>
                                </div> */}
                              </div>
                            </div>
                          </li>
                          <div style={{ marginLeft: "5%" }}>
                            <span
                              style={{
                                textDecoration: "none",
                                color: "black",
                                textAlign: "center",
                              }}
                            >
                              {item.servicename}
                            </span>
                            <span
                              style={{
                                textDecoration: "none",
                                color: "black",
                                textAlign: "center",
                              }}
                            >
                              {SalonVendor?.filter((ven) =>
                                ven?.service?.find(
                                  (ser) => ser?.ServiceName === item.servicename
                                )
                              )?.length > 0
                                ? SalonVendor?.filter((ven) =>
                                    ven?.service?.find(
                                      (ser) =>
                                        ser?.ServiceName === item.servicename
                                    )
                                  )?.length
                                : ""}
                            </span>
                          </div>
                        </Link>
                      </ul>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          ) : (
            ""
          )}

          {/* In Malls And Shopping Hubs */}
          <div className="budget mt-2">
            <div className="container-fluid de-top">
              <h4>In Malls And Shopping Hubs</h4>
            </div>
            <Slider {...settings}>
              {type === "Spa"
                ? SpaMallsandshopping?.map((brand) => (
                    <Link to="/Brandproductdetails" state={{ item: brand }}>
                      <div style={{ margin: "10px" }}>
                        {/* <div
                          style={{
                            width: "200px",
                            height: "50px",
                            borderRadius: "10px 10px 0px 0px",
                            backgroundColor: "#f3f5f7",
                            display: "grid",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "hidden",
                            color: "#e22217",
                            fontWeight: "bold",
                          }}
                        >
                          {brand?.vendors[0]?.businessName}
                        </div> */}
                        <img
                          src={
                            "https://spazo.co.in/Vendor/" +
                            brand?.vendors[0]?.logo
                          }
                          alt="spa-category"
                          style={{
                            width: "140px",
                            height: "150px",
                            borderRadius: "10px",
                          }}
                        />
                      </div>
                    </Link>
                  ))
                : type === "Salon"
                ? salonMallsandshopping?.map((brand) => (
                    <Link to="/Brandproductdetails" state={{ item: brand }}>
                      <div style={{ margin: "10px" }}>
                        {/* <div
                      style={{
                        width: "200px",
                        height: "50px",
                        borderRadius: "10px 10px 0px 0px",
                        backgroundColor: "#f3f5f7",
                        display: "grid",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                        color: "#e22217",
                        fontWeight: "bold",
                      }}
                    >
                      {brand?.vendors[0]?.businessName}
                    </div> */}
                        <img
                          src={
                            "https://spazo.co.in/Vendor/" +
                            brand?.vendors[0]?.logo
                          }
                          alt="spa-category"
                          style={{
                            width: "140px",
                            height: "150px",
                            borderRadius: "10px",
                          }}
                        />
                      </div>
                    </Link>
                  ))
                : type === "Beauty Care Products"
                ? BCPMallsandshopping?.map((brand) => (
                    <Link to="/Brandproductdetails" state={{ item: brand }}>
                      <div style={{ margin: "10px" }}>
                        <div
                          style={{
                            width: "200px",
                            height: "50px",
                            borderRadius: "10px 10px 0px 0px",
                            backgroundColor: "#f3f5f7",
                            display: "grid",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "hidden",
                            color: "#e22217",
                            fontWeight: "bold",
                          }}
                        >
                          {brand?.vendors[0]?.businessName}
                        </div>
                        <img
                          src={
                            "https://spazo.co.in/Vendor/" +
                            brand?.vendors[0]?.logo
                          }
                          alt="spa-category"
                          style={{
                            width: "140px",
                            height: "150px",
                            borderRadius: "10px",
                          }}
                        />
                      </div>
                    </Link>
                  ))
                : ""}
            </Slider>
          </div>

          {/* In Your Budget! */}
          <div className="budget mt-2">
            <div className="container-fluid de-top">
              <h4>In Your Budget! 💰</h4>
            </div>
            <Slider {...settings3}>
              <Link
                to="/NewbudgetProducts"
                state={{ Minamount: 0, Maxamount: 499, type: type }}
              >
                <div className="budget-spa-0">
                  <div
                    className="spa-text-0"
                    style={{
                      backgroundImage: "url(/Assets/images/budget1.webp)",
                      width: "95%",
                      height: "150px",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="dudget11">
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        Under ₹500
                      </p>
                      <p style={{ marginBottom: "0px", color: "black" }}>
                        (Per Person)
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                to="/NewbudgetProducts"
                state={{ Minamount: 500, Maxamount: 999, type: type }}
              >
                <div className="budget-spa-0">
                  <div
                    className="spa-text-0"
                    style={{
                      backgroundImage: "url(/Assets/images/Budget2.jpg)",
                      width: "95%",
                      height: "150px",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="dudget11">
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        ₹500 - ₹999
                      </p>
                      <p style={{ marginBottom: "0px", color: "black" }}>
                        (Per Person)
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                to="/NewbudgetProducts"
                state={{ Minamount: 1000, Maxamount: 1499, type: type }}
              >
                <div className="budget-spa-0">
                  <div
                    className="spa-text-0"
                    style={{
                      backgroundImage: "url(/Assets/images/spa-beauty.jpg)",
                      width: "95%",
                      height: "150px",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="dudget11">
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        ₹1000 - ₹1499
                      </p>
                      <p style={{ marginBottom: "0px", color: "black" }}>
                        (Per Person)
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                to="/NewbudgetProducts"
                state={{ Minamount: 1500, Maxamount: 1999, type: type }}
              >
                <div className="budget-spa-0">
                  <div
                    className="spa-text-0"
                    style={{
                      backgroundImage: "url(/Assets/images/budget3.webp)",
                      width: "95%",
                      height: "150px",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="dudget11">
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        ₹1500 - ₹1999
                      </p>
                      <p style={{ marginBottom: "0px", color: "black" }}>
                        (Per Person)
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                to="/NewbudgetProducts"
                state={{ Minamount: 2000, Maxamount: 2499, type: type }}
              >
                <div className="budget-spa-0">
                  <div
                    className="spa-text-0"
                    style={{
                      backgroundImage: "url(/Assets/images/register.jpg)",
                      width: "95%",
                      height: "150px",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="dudget11">
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        ₹2000 - ₹2499
                      </p>
                      <p style={{ marginBottom: "0px", color: "black" }}>
                        (Per Person)
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                to="/NewbudgetProducts"
                state={{ Minamount: 2500, Maxamount: 1000000, type: type }}
              >
                <div className="budget-spa-0">
                  <div
                    className="spa-text-0"
                    style={{
                      backgroundImage: "url(/Assets/images/budget4.webp)",
                      width: "95%",
                      height: "150px",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="dudget11">
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        <i class="fas fa-greater-than"></i> ₹2500
                      </p>
                      <p style={{ marginBottom: "0px", color: "black" }}>
                        (Per Person)
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </Slider>
          </div>

          {/* At 5 Star Hotels 🌟 */}
          <div className="budget mt-2">
            <div className="container-fluid de-top">
              <h4>At 5 Star Hotels 🌟</h4>
            </div>
            <Slider {...settings}>
              {type === "Spa"
                ? SpaStarhotels?.map((brand) => (
                    <Link to="/Brandproductdetails" state={{ item: brand }}>
                      <div>
                        <img
                          src={
                            "https://spazo.co.in/Vendor/" +
                            brand?.vendors[0]?.logo
                          }
                          alt="spa-category"
                          style={{
                            width: "150px",
                            height: "100px",
                            borderRadius: "30px 3px 30px 3px",
                            margin: "10px",
                          }}
                        />
                      </div>
                    </Link>
                  ))
                : type === "Salon"
                ? salonStarhotels?.map((brand) => (
                    <Link to="/Brandproductdetails" state={{ item: brand }}>
                      <div>
                        <img
                          src={
                            "https://spazo.co.in/Vendor/" +
                            brand?.vendors[0]?.logo
                          }
                          alt="spa-category"
                          style={{
                            width: "150px",
                            height: "100px",
                            borderRadius: "30px 3px 30px 3px",
                            margin: "10px",
                          }}
                        />
                      </div>
                    </Link>
                  ))
                : type === "Beauty Care Products"
                ? BCPStarhotels?.map((brand) => (
                    <Link to="/Brandproductdetails" state={{ item: brand }}>
                      <div>
                        <img
                          src={
                            "https://spazo.co.in/Vendor/" +
                            brand?.vendors[0]?.logo
                          }
                          alt="spa-category"
                          style={{
                            width: "150px",
                            height: "100px",
                            borderRadius: "30px 3px 30px 3px",
                            margin: "10px",
                          }}
                        />
                      </div>
                    </Link>
                  ))
                : ""}
            </Slider>
          </div>

          {/* Top Rated */}
          <div className="budget mt-2">
            <div className="container-fluid de-top">
              <h4>Top Rated</h4>
            </div>
            <Slider {...settings1}>
              {type === "Spa"
                ? SpaVendorToprated?.map((item) => (
                    <div class="item mt-3 mb-3">
                      <div className="spa-budget">
                        <ul>
                          <Link to="/Newproductdetails" state={{ item: item }}>
                            <li>
                              <div className="budget-spa-0">
                                <div
                                  className="topratedimage"
                                  style={{
                                    backgroundImage: `url("https://spazo.co.in/Vendor/${item.spaimage1}")`,
                                    borderRadius: "5%",
                                    height: "280px",
                                  }}
                                >
                                  <div className="dudget-diff-10">
                                    <h5
                                      style={{
                                        color: "white",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item.businessName}
                                    </h5>
                                  </div>

                                  <div className="topratedstar">
                                    <ReactStars
                                      count={5}
                                      value={
                                        isNaN(
                                          item?.reviews?.reduce(
                                            (a, rev) => a + rev.rating,
                                            0
                                          ) / item?.reviews?.length
                                        )
                                          ? 0
                                          : (
                                              item?.reviews?.reduce(
                                                (a, rev) => a + rev.rating,
                                                0
                                              ) / item?.reviews?.length
                                            )?.toFixed(1)
                                      }
                                      size={25}
                                      activeColor="#ffd700"
                                    />
                                  </div>
                                </div>
                              </div>
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </div>
                  ))
                : type === "Salon"
                ? SalonVendorToprated?.map((item) => (
                    <div class="item mt-3 mb-3">
                      <div className="spa-budget">
                        <ul>
                          <Link to="/Newproductdetails" state={{ item: item }}>
                            <li>
                              <div className="budget-spa-0">
                                <div
                                  className="topratedimage"
                                  style={{
                                    backgroundImage: `url("https://spazo.co.in/Vendor/${item.spaimage1}")`,
                                    borderRadius: "5%",
                                  }}
                                >
                                  <div className="dudget-diff-10">
                                    <h5
                                      style={{
                                        color: "white",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item.businessName}
                                    </h5>
                                  </div>

                                  <div className="topratedstar">
                                    <ReactStars
                                      count={5}
                                      value={
                                        isNaN(
                                          item?.reviews?.reduce(
                                            (a, rev) => a + rev.rating,
                                            0
                                          ) / item?.reviews?.length
                                        )
                                          ? 0
                                          : (
                                              item?.reviews?.reduce(
                                                (a, rev) => a + rev.rating,
                                                0
                                              ) / item?.reviews?.length
                                            )?.toFixed(1)
                                      }
                                      size={25}
                                      activeColor="#ffd700"
                                    />
                                    {/* <span
                                    style={{
                                      backgroundColor: "#e22217",
                                      color: "white",
                                      padding: "6px 5px",
                                      width: "34%",
                                      bottom: "40px",
                                      position: "absolute",
                                    }}
                                  >
                                    {isNaN(
                                      item?.reviews?.reduce(
                                        (a, rev) => a + rev.rating,
                                        0
                                      ) / item?.reviews?.length
                                    )
                                      ? 0
                                      : (
                                          item?.reviews?.reduce(
                                            (a, rev) => a + rev.rating,
                                            0
                                          ) / item?.reviews?.length
                                        )?.toFixed(1)}
                                    &nbsp;
                                    <i class="fas fa-star"></i>
                                  </span> */}
                                  </div>
                                </div>
                              </div>
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </div>
                  ))
                : type === "Beauty Care Products"
                ? ""
                : ""}
            </Slider>
          </div>
        </Container>
        {/* {type === "Spa" ? (
            <div
              className="budget mt-2"
              // data-aos="fade-up"
              // data-aos-delay="800"
              // data-aos-duration="3000"
            >
              <div className="container-fluid de-top">
                <h4>Service{type}</h4>
              </div>
              <div className="default-spa">
                <OwlCarousel
                  className="owl-theme"
                  // loop
                  autoplay={true}
                  margin={15}
                  items={6}
                  nav={false}
                  dots={false}
                  responsive={responsive1}
                >
                  {SpauniqueArray?.map((item) => (
                    <div class="item mt-3 mb-3">
                      <div className="spa-budget">
                        <ul>
                          <Link
                            to="/Serviceproduct"
                            state={{ ServiceName: item.servicename }}
                          >
                            <li>
                              <div className="budget-spa-0">
                                <div
                                  className="spa-text-0"
                                  style={{
                                    backgroundImage: `url("https://spazo.co.in/Adminservice/${item.serviceimage}")`,
                                    width: "131px",
                                    height: "131px",
                                    borderRadius: "50%",
                                  }}
                                >
                                  <div className="dudget-diff-0">
                                    <h6>{item.servicename}</h6>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          ) : type === "Salon" ? (
            <div
              className="budget mt-2"
              // data-aos="fade-up"
              // data-aos-delay="800"
              // data-aos-duration="3000"
            >
              <div className="container-fluid de-top">
                <h4>Service{type}</h4>
              </div>
              <div className="default-spa">
                <OwlCarousel
                  className="owl-theme"
                  // loop
                  autoplay={true}
                  margin={15}
                  items={6}
                  nav={false}
                  dots={false}
                  responsive={responsive1}
                >
                  {SalonuniqueArray?.map((item) => (
                    <div class="item mt-3 mb-3">
                      <div className="spa-budget">
                        <ul>
                          <Link
                            to="/Serviceproduct"
                            state={{ ServiceName: item.servicename }}
                          >
                            <li>
                              <div className="budget-spa-0">
                                <div
                                  className="spa-text-0"
                                  style={{
                                    backgroundImage: `url("https://spazo.co.in/Adminservice/${item.serviceimage}")`,
                                    width: "131px",
                                    height: "131px",
                                    borderRadius: "50%",
                                  }}
                                >
                                  <div className="dudget-diff-0">
                                    <h6>{item.servicename}</h6>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          ) : (
            ""
          )} */}

        {/* {type === "Spa" ? (
          <div
            className="budget mt-2"
            data-aos="fade-up"
            data-aos-delay="800"
            data-aos-duration="3000"
          >
            <div className="container-fluid de-top">
              <h4>Top Rated Spa </h4>
            </div>
            <div className="default-spa">
              <OwlCarousel
                className="owl-theme"
                autoplay={true}
                margin={15}
                items={6}
                nav={false}
                dots={false}
                responsive={responsive1}
              >
                {SpaVendorToprated?.map((item) => (
                  <div class="item mt-3 mb-3">
                    <div className="spa-budget">
                      <ul>
                        <Link to="/Newproductdetails" state={{ item: item }}>
                          <li>
                            <div className="budget-spa-0">
                              <div
                                className="topratedimage"
                                style={{
                                  backgroundImage: `url("https://spazo.co.in/Vendor/${item.spaimage1}")`,
                                  borderRadius: "5%",
                                }}
                              >
                                <div className="dudget-diff-10">
                                  <h5
                                    style={{
                                      color: "white",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item.businessName}
                                  </h5>
                                </div>

                                <div className="topratedstar">
                                  <ReactStars
                                    count={5}
                                    value={
                                      isNaN(
                                        item?.reviews?.reduce(
                                          (a, rev) => a + rev.rating,
                                          0
                                        ) / item?.reviews?.length
                                      )
                                        ? 0
                                        : (
                                            item?.reviews?.reduce(
                                              (a, rev) => a + rev.rating,
                                              0
                                            ) / item?.reviews?.length
                                          )?.toFixed(1)
                                    }
                                    size={25}
                                    activeColor="#ffd700"
                                  />
                                 
                                </div>
                              </div>
                            </div>
                          </li>
                        </Link>
                      </ul>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        ) : type === "Salon" ? (
          <div
            className="budget mt-2"
            data-aos="fade-up"
            data-aos-delay="800"
            data-aos-duration="3000"
          >
            <div className="container-fluid de-top">
              <h4>Top Rated Spa </h4>
            </div>
            <div className="default-spa">
              <OwlCarousel
                className="owl-theme"
                // loop
                autoplay={true}
                margin={15}
                items={6}
                nav={false}
                dots={false}
                responsive={responsive1}
              >
                {SalonVendorToprated?.map((item) => (
                  <div class="item mt-3 mb-3">
                    <div className="spa-budget">
                      <ul>
                        <Link to="/Newproductdetails" state={{ item: item }}>
                          <li>
                            <div className="budget-spa-0">
                              <div
                                className="topratedimage"
                                style={{
                                  backgroundImage: `url("https://spazo.co.in/Vendor/${item.spaimage1}")`,
                                  borderRadius: "5%",
                                }}
                              >
                                <div className="dudget-diff-10">
                                  <h5
                                    style={{
                                      color: "white",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item.businessName}
                                  </h5>
                                </div>

                                <div className="topratedstar">
                                  <ReactStars
                                    count={5}
                                    value={
                                      isNaN(
                                        item?.reviews?.reduce(
                                          (a, rev) => a + rev.rating,
                                          0
                                        ) / item?.reviews?.length
                                      )
                                        ? 0
                                        : (
                                            item?.reviews?.reduce(
                                              (a, rev) => a + rev.rating,
                                              0
                                            ) / item?.reviews?.length
                                          )?.toFixed(1)
                                    }
                                    size={25}
                                    activeColor="#ffd700"
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                        </Link>
                      </ul>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        ) : (
          ""
        )} */}
        <NewFooter />
      </div>
    </>
  );
}

export default NewHome;
