import React from "react";
import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { AiFillPhone, AiTwotoneMail } from "react-icons/ai";
import { BiCurrentLocation } from "react-icons/bi";
import {
  IoBusiness,
  IoLocationSharp,
  IoPersonCircleSharp,
} from "react-icons/io5";
import { RiUserLocationFill } from "react-icons/ri";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Updateprofile2() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const Input = styled("input")({
    display: "none",
  });

  const user = JSON.parse(sessionStorage.getItem("user"));
  const [about, setabout] = useState("");

  const handleEditorChange = (event, editor) => {
    // Get the updated data from CKEditor
    const data = editor.getData();
    setabout(data);
  };

  const update3 = async () => {
    if (!about) {
      notify2("Please add something about your Spa");
    } else {
      try {
        const config = {
          url: "/vendorupdate3",
          method: "post",
          baseURL: "https://spazo.co.in/api/vendor",
          data: {
            userId: user._id,
            about: about,
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            // alert("Data Added successfully");
            notify("Data Added successfully");
            sessionStorage.setItem("user", JSON.stringify(res.data.user));
            window.location.assign("updateprofile3");
          } else {
            // alert(res.status.error);
            notify1(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        // alert("Unable to add Details");
        notify1("Unable to add Details, Please try after some time");
      }
    }
  };

  return (
    <>
      <div
        style={{
          // backgroundColor: "#ffda77",
          // height: "100vh",
          paddingTop: "8%",
          paddingBottom: "8%",
        }}
      >
        <ToastContainer
          position="top-center"
          autoClose={30000}
          closeOnClick
          pauseOnHover
          draggable
        />
        <div className="admin-name-text">
          <h4 style={{ textDecoration: "underline", textAlign: "center" }}>
            Update Your Profile Details
          </h4>
        </div>
        <div className="mt-4 updateprofile1">
          <h6>
            About Your{" "}
            {user?.type === "Spa"
              ? "Spa"
              : user?.type === "Salon"
              ? "Salon"
              : "Beauty Care Products"}
          </h6>
          <CKEditor
            editor={ClassicEditor}
            data={about}
            onChange={handleEditorChange}
          />

          <Button
            variant="secondary"
            style={{
              margin: "20px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            onClick={update3}
          >
            <i class="fas fa-save"></i>&nbsp;Save & Continue
          </Button>
        </div>

        {/* <div>{parse(about)}</div> */}
      </div>
    </>
  );
}

export default Updateprofile2;
