import React, { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { BiLogoFacebook } from "react-icons/bi";
import axios from "axios";
import { Country, State, City } from "country-state-city";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AdminChangepassword() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  function CheckPassword(inputtxt) {
    var decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    if (inputtxt.match(decimal)) {
      return true;
    } else {
      alert(
        "Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character!"
      );
      return false;
    }
  }

  const [password, setpassword] = useState("");
  const admindata = JSON.parse(sessionStorage.getItem("admindata"));

  const ChangeadminPassword = async (e) => {
    if (!password) {
      notify2("Please enter OTP");
    } else {
      try {
        if (CheckPassword(password)) {
          const config = {
            url: "/ChangeadminPassword",
            method: "post",
            baseURL: "https://spazo.co.in/api/admin",
            headers: { "content-type": "application/json" },
            data: {
              AdminId: admindata?._id,
              password: password,
            },
          };
          let res = await axios(config);
          if (res.status === 200) {
            notify("Password updated successfully");
            sessionStorage.removeItem("Aemail");
            sessionStorage.removeItem("admindata");
            window.location.assign("/admin");
          } else {
            notify1(res.data.error);
          }
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          notify1(error.response.data.error);
        }
      }
    }
  };

  return (
    <>
      <div className="admincontainer">
        <img
          src="../../Assets/images/loginimage1.jpg"
          alt="Snow"
          style={{ width: "100%", height: "100vh" }}
        />
        <ToastContainer
          position="top-center"
          autoClose={30000}
          closeOnClick
          pauseOnHover
          draggable
        />

        <div className="centered12345">
          <p style={{ color: "white", fontSize: "25px", marginBottom: "0px" }}>
            Enter your new password
          </p>
          <p style={{ color: "white" }}>(Regenerate new password)</p>
          <input
            placeholder="Enter new password"
            style={{ width: "200px" }}
            onChange={(e) => setpassword(e.target.value)}
          />
          <button
            style={{
              borderColor: "#e22217",
              backgroundColor: "#e22217",
              color: "white",
              padding: "0px 10px 0px 10px",
            }}
            onClick={ChangeadminPassword}
          >
            Confirm
          </button>
        </div>
      </div>
    </>
  );
}

export default AdminChangepassword;
