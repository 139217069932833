import React, { useEffect, useState } from "react";
import Newheader2 from "./Newheader2";
import { Col, Modal, Row } from "react-bootstrap";
import NewFooter from "./NewFooter";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import axios from "axios";
import { BiSolidCarGarage } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import parse from "html-react-parser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function Newproduct() {
  const location = useLocation();
  const { type, address } = location.state;

  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const currentDate = new Date();
  const [catdata, setcatdata] = useState([]);
  const [latitude, setlatitude] = useState();
  const [longitude, setlongitude] = useState();
  const radius = 5;
  const radius1 = 10;
  const radius2 = 15;
  const radius3 = 20;
  const [error, seterror] = useState("");
  const [selectedType, setselectedType] = useState();
  const [category, setcategory] = useState([]);
  const [categoryfilter, setcategoryfilter] = useState("");

  const [seletedAmount, setseletedAmount] = useState();

  const [value, setValue] = React.useState([0, 10000]);

  const [Vendor, setVendor] = useState([]);

  const degreesToRadians = (degrees) => {
    return (degrees * Math.PI) / 180;
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Earth's radius in kilometers
    const dLat = degreesToRadians(lat2 - lat1);
    const dLon = degreesToRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(degreesToRadians(lat1)) *
        Math.cos(degreesToRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  };

  const filteredAdd = Vendor?.filter((address) => {
    const distance = calculateDistance(
      latitude,
      longitude,
      address.latitude,
      address.longitude
    );
    // console.log("distance", distance);
    return distance <= radius;
  });

  // Step 1: Calculate average rating and return updated items
  const updatedCatdata = filteredAdd?.map((item) => {
    if (item?.reviews && item?.reviews?.length > 0) {
      const totalRatings = item?.reviews?.reduce(
        (sum, review) => sum + review?.rating,
        0
      );
      item.averageRating = totalRatings / item?.reviews?.length; // Add averageRating to item
    } else {
      item.averageRating = 0; // Default if no reviews
    }
    return item;
  });

  // Step 2: Remove duplicates by converting the `reviews` array to a JSON string for comparison
  const uniqueCatdata = updatedCatdata?.filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          JSON.stringify(t.businessName) === JSON.stringify(item.businessName)
      )
  );

  // Step 3: Sort by the average ratings in descending order
  const filteredAddresses = uniqueCatdata?.sort(
    (a, b) => b.averageRating - a.averageRating
  );

  const filteredAdd1 = Vendor?.filter((address) => {
    const distance = calculateDistance(
      latitude,
      longitude,
      address.latitude,
      address.longitude
    );
    // console.log("distance", distance);
    return distance <= radius1 && distance > radius;
  });

  // Step 1: Calculate average rating and return updated items
  const updatedCatdata1 = filteredAdd1?.map((item) => {
    if (item?.reviews && item?.reviews?.length > 0) {
      const totalRatings = item?.reviews?.reduce(
        (sum, review) => sum + review?.rating,
        0
      );
      item.averageRating = totalRatings / item?.reviews?.length; // Add averageRating to item
    } else {
      item.averageRating = 0; // Default if no reviews
    }
    return item;
  });

  // Step 2: Remove duplicates by converting the `reviews` array to a JSON string for comparison
  const uniqueCatdata1 = updatedCatdata1?.filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          JSON.stringify(t.businessName) === JSON.stringify(item.businessName)
      )
  );

  // Step 3: Sort by the average ratings in descending order
  const filteredAddresses1 = uniqueCatdata1?.sort(
    (a, b) => b.averageRating - a.averageRating
  );

  const filteredAdd2 = Vendor?.filter((address) => {
    const distance = calculateDistance(
      latitude,
      longitude,
      address.latitude,
      address.longitude
    );
    // console.log("distance", distance);
    return distance <= radius2 && distance > radius1;
  });

  // Step 1: Calculate average rating and return updated items
  const updatedCatdata2 = filteredAdd2?.map((item) => {
    if (item?.reviews && item?.reviews?.length > 0) {
      const totalRatings = item?.reviews?.reduce(
        (sum, review) => sum + review?.rating,
        0
      );
      item.averageRating = totalRatings / item?.reviews?.length; // Add averageRating to item
    } else {
      item.averageRating = 0; // Default if no reviews
    }
    return item;
  });

  // Step 2: Remove duplicates by converting the `reviews` array to a JSON string for comparison
  const uniqueCatdata2 = updatedCatdata2?.filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          JSON.stringify(t.businessName) === JSON.stringify(item.businessName)
      )
  );

  // Step 3: Sort by the average ratings in descending order
  const filteredAddresses2 = uniqueCatdata2?.sort(
    (a, b) => b.averageRating - a.averageRating
  );

  const filteredAdd3 = Vendor?.filter((address) => {
    const distance = calculateDistance(
      latitude,
      longitude,
      address.latitude,
      address.longitude
    );
    // console.log("distance", distance);
    return distance <= radius3 && distance > radius2;
  });

  // Step 1: Calculate average rating and return updated items
  const updatedCatdata3 = filteredAdd3?.map((item) => {
    if (item?.reviews && item?.reviews?.length > 0) {
      const totalRatings = item?.reviews?.reduce(
        (sum, review) => sum + review?.rating,
        0
      );
      item.averageRating = totalRatings / item?.reviews?.length; // Add averageRating to item
    } else {
      item.averageRating = 0; // Default if no reviews
    }
    return item;
  });

  // Step 2: Remove duplicates by converting the `reviews` array to a JSON string for comparison
  const uniqueCatdata3 = updatedCatdata3?.filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          JSON.stringify(t.businessName) === JSON.stringify(item.businessName)
      )
  );

  // Step 3: Sort by the average ratings in descending order
  const filteredAddresses3 = uniqueCatdata3?.sort(
    (a, b) => b.averageRating - a.averageRating
  );

  const filteredAdd4 = Vendor?.filter((address) => {
    const distance = calculateDistance(
      latitude,
      longitude,
      address.latitude,
      address.longitude
    );
    // console.log("distance", distance);
    return distance > radius3;
  });

  // Step 1: Calculate average rating and return updated items
  const updatedCatdata4 = filteredAdd4?.map((item) => {
    if (item?.reviews && item?.reviews?.length > 0) {
      const totalRatings = item?.reviews?.reduce(
        (sum, review) => sum + review?.rating,
        0
      );
      item.averageRating = totalRatings / item?.reviews?.length; // Add averageRating to item
    } else {
      item.averageRating = 0; // Default if no reviews
    }
    return item;
  });

  // Step 2: Remove duplicates by converting the `reviews` array to a JSON string for comparison
  const uniqueCatdata4 = updatedCatdata4?.filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          JSON.stringify(t.businessName) === JSON.stringify(item.businessName)
      )
  );

  // Step 3: Sort by the average ratings in descending order
  const filteredAddresses4 = uniqueCatdata4?.sort(
    (a, b) => b.averageRating - a.averageRating
  );

  // Servicefilter

  const [Service, setService] = useState([]);
  const [Servicefilter, setServicefilter] = useState("");
  const [servicearray, setservicearray] = useState([]);

  const [Topbrands, setTopbrands] = useState([]);
  const [Starhotels, setStarhotels] = useState([]);
  const [Mallsandshopping, setMallsandshopping] = useState([]);

  const [SelectTopBrands, setSelectTopBrands] = useState("");
  const [SelectTopRated, setSelectTopRated] = useState(false);

  useEffect(() => {
    if (SelectTopBrands === "Top Brands") {
      if (seletedAmount && Servicefilter && categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];
        const catfilter = Topbrands?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );
        console.log("catfilter", catfilter);

        const serfilter = catfilter?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        var filterarray = [];
        if (serfilter?.length > 0) {
          for (let i = 0; i < serfilter?.length; i++) {
            for (let j = 0; j < serfilter[i]?.service?.length; j++) {
              const gst =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                serfilter[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(serfilter[i]);
              }
            }
          }
        }

        setcatdata(filterarray);
      } else if (Servicefilter && categoryfilter && !seletedAmount) {
        const xyz = Topbrands?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );

        const xyz1 = xyz?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );

        setcatdata(xyz1);
      } else if (categoryfilter && !Servicefilter && !seletedAmount) {
        const xyz = Topbrands?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );
        setcatdata(xyz);
      } else if (Servicefilter && !categoryfilter && !seletedAmount) {
        const xyz = Topbrands?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        console.log("xyz", Servicefilter, xyz);
        setcatdata(xyz);
      } else if (seletedAmount && !Servicefilter && !categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];
        var filterarray = [];

        if (Topbrands?.length > 0) {
          for (let i = 0; i < Topbrands?.length; i++) {
            for (let j = 0; j < Topbrands[i]?.service?.length; j++) {
              const gst =
                (Topbrands[i]?.service[j]?.Price *
                  Topbrands[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (Topbrands[i]?.service[j]?.Price *
                  Topbrands[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (Topbrands[i]?.service[j]?.Price *
                  Topbrands[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                Topbrands[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(Topbrands[i]);
              }
            }
          }
        }

        // console.log("filterarray", filterarray);

        setcatdata(filterarray);
      } else if (seletedAmount && !Servicefilter && categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];

        const catfilter = Topbrands?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );
        var filterarray = [];
        if (catfilter?.length > 0) {
          for (let i = 0; i < catfilter?.length; i++) {
            for (let j = 0; j < catfilter[i]?.service?.length; j++) {
              const gst =
                (catfilter[i]?.service[j]?.Price *
                  catfilter[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (catfilter[i]?.service[j]?.Price *
                  catfilter[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (catfilter[i]?.service[j]?.Price *
                  catfilter[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                catfilter[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(catfilter[i]);
              }
            }
          }
        }

        setcatdata(filterarray);
      } else if (seletedAmount && Servicefilter && !categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];

        const serfilter = Topbrands?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        var filterarray = [];
        if (serfilter?.length > 0) {
          for (let i = 0; i < serfilter?.length; i++) {
            for (let j = 0; j < serfilter[i]?.service?.length; j++) {
              const gst =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                serfilter[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(serfilter[i]);
              }
            }
          }
        }

        setcatdata(filterarray);
      } else if (!Servicefilter && !categoryfilter && !seletedAmount) {
        setcatdata(Topbrands);
      }
    } else if (SelectTopBrands === "In Malls And Shopping Hubs") {
      if (seletedAmount && Servicefilter && categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];
        const catfilter = Mallsandshopping?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );

        const serfilter = catfilter?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        var filterarray = [];
        if (serfilter?.length > 0) {
          for (let i = 0; i < serfilter?.length; i++) {
            for (let j = 0; j < serfilter[i]?.service?.length; j++) {
              const gst =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                serfilter[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(serfilter[i]);
              }
            }
          }
        }

        setcatdata(filterarray);
      } else if (Servicefilter && categoryfilter && !seletedAmount) {
        const xyz = Mallsandshopping?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );

        const xyz1 = xyz?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );

        setcatdata(xyz1);
      } else if (categoryfilter && !Servicefilter && !seletedAmount) {
        const xyz = Mallsandshopping?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );
        setcatdata(xyz);
      } else if (Servicefilter && !categoryfilter && !seletedAmount) {
        const xyz = Mallsandshopping?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        console.log("xyz", Servicefilter, xyz);
        setcatdata(xyz);
      } else if (seletedAmount && !Servicefilter && !categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];
        var filterarray = [];

        if (Mallsandshopping?.length > 0) {
          for (let i = 0; i < Mallsandshopping?.length; i++) {
            for (let j = 0; j < Mallsandshopping[i]?.service?.length; j++) {
              const gst =
                (Mallsandshopping[i]?.service[j]?.Price *
                  Mallsandshopping[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (Mallsandshopping[i]?.service[j]?.Price *
                  Mallsandshopping[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (Mallsandshopping[i]?.service[j]?.Price *
                  Mallsandshopping[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                Mallsandshopping[i]?.service[j]?.Price +
                gst +
                servicetax -
                discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(Mallsandshopping[i]);
              }
            }
          }
        }

        // console.log("filterarray", filterarray);

        setcatdata(filterarray);
      } else if (seletedAmount && !Servicefilter && categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];

        const catfilter = Mallsandshopping?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );
        var filterarray = [];
        if (catfilter?.length > 0) {
          for (let i = 0; i < catfilter?.length; i++) {
            for (let j = 0; j < catfilter[i]?.service?.length; j++) {
              const gst =
                (catfilter[i]?.service[j]?.Price *
                  catfilter[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (catfilter[i]?.service[j]?.Price *
                  catfilter[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (catfilter[i]?.service[j]?.Price *
                  catfilter[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                catfilter[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(catfilter[i]);
              }
            }
          }
        }

        setcatdata(filterarray);
      } else if (seletedAmount && Servicefilter && !categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];

        const serfilter = Mallsandshopping?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        var filterarray = [];
        if (serfilter?.length > 0) {
          for (let i = 0; i < serfilter?.length; i++) {
            for (let j = 0; j < serfilter[i]?.service?.length; j++) {
              const gst =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                serfilter[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(serfilter[i]);
              }
            }
          }
        }

        setcatdata(filterarray);
      } else if (!Servicefilter && !categoryfilter && !seletedAmount) {
        setcatdata(Mallsandshopping);
      }
    } else if (SelectTopBrands === "At 5 Star Hotels") {
      if (seletedAmount && Servicefilter && categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];
        const catfilter = Starhotels?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );

        const serfilter = catfilter?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        var filterarray = [];
        if (serfilter?.length > 0) {
          for (let i = 0; i < serfilter?.length; i++) {
            for (let j = 0; j < serfilter[i]?.service?.length; j++) {
              const gst =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                serfilter[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(serfilter[i]);
              }
            }
          }
        }

        setcatdata(filterarray);
      } else if (Servicefilter && categoryfilter && !seletedAmount) {
        const xyz = Starhotels?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );

        const xyz1 = xyz?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );

        setcatdata(xyz1);
      } else if (categoryfilter && !Servicefilter && !seletedAmount) {
        const xyz = Starhotels?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );
        setcatdata(xyz);
      } else if (Servicefilter && !categoryfilter && !seletedAmount) {
        const xyz = Starhotels?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        console.log("xyz", Servicefilter, xyz);
        setcatdata(xyz);
      } else if (seletedAmount && !Servicefilter && !categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];
        var filterarray = [];

        if (Starhotels?.length > 0) {
          for (let i = 0; i < Starhotels?.length; i++) {
            for (let j = 0; j < Starhotels[i]?.service?.length; j++) {
              const gst =
                (Starhotels[i]?.service[j]?.Price *
                  Starhotels[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (Starhotels[i]?.service[j]?.Price *
                  Starhotels[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (Starhotels[i]?.service[j]?.Price *
                  Starhotels[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                Starhotels[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(Starhotels[i]);
              }
            }
          }
        }

        // console.log("filterarray", filterarray);

        setcatdata(filterarray);
      } else if (seletedAmount && !Servicefilter && categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];

        const catfilter = Starhotels?.filter((person) =>
          person.service.some(
            (hobby) => hobby.Service_Category === categoryfilter
          )
        );
        var filterarray = [];
        if (catfilter?.length > 0) {
          for (let i = 0; i < catfilter?.length; i++) {
            for (let j = 0; j < catfilter[i]?.service?.length; j++) {
              const gst =
                (catfilter[i]?.service[j]?.Price *
                  catfilter[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (catfilter[i]?.service[j]?.Price *
                  catfilter[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (catfilter[i]?.service[j]?.Price *
                  catfilter[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                catfilter[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(catfilter[i]);
              }
            }
          }
        }

        setcatdata(filterarray);
      } else if (seletedAmount && Servicefilter && !categoryfilter) {
        var xyz = seletedAmount?.split("-");
        var seletedMinAmount = xyz[0];
        var seletedMaxAmount = xyz[1];

        const serfilter = Starhotels?.filter((person) =>
          person.service.some((hobby) => hobby.ServiceName === Servicefilter)
        );
        var filterarray = [];
        if (serfilter?.length > 0) {
          for (let i = 0; i < serfilter?.length; i++) {
            for (let j = 0; j < serfilter[i]?.service?.length; j++) {
              const gst =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.GST) /
                100;
              const servicetax =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.servicetax) /
                100;
              const discount =
                (serfilter[i]?.service[j]?.Price *
                  serfilter[i]?.service[j]?.Discount) /
                100;

              const total_amount =
                serfilter[i]?.service[j]?.Price + gst + servicetax - discount;

              if (
                parseInt(seletedMinAmount) <= total_amount &&
                total_amount <= parseInt(seletedMaxAmount)
              ) {
                filterarray?.push(serfilter[i]);
              }
            }
          }
        }

        setcatdata(filterarray);
      } else if (!Servicefilter && !categoryfilter && !seletedAmount) {
        setcatdata(Starhotels);
      }
    } else if (seletedAmount && Servicefilter && categoryfilter) {
      var xyz = seletedAmount?.split("-");
      var seletedMinAmount = xyz[0];
      var seletedMaxAmount = xyz[1];
      const catfilter = Vendor?.filter((person) =>
        person.service.some(
          (hobby) => hobby.Service_Category === categoryfilter
        )
      );

      const serfilter = catfilter?.filter((person) =>
        person.service.some((hobby) => hobby.ServiceName === Servicefilter)
      );
      var filterarray = [];
      if (serfilter?.length > 0) {
        for (let i = 0; i < serfilter?.length; i++) {
          for (let j = 0; j < serfilter[i]?.service?.length; j++) {
            const gst =
              (serfilter[i]?.service[j]?.Price *
                serfilter[i]?.service[j]?.GST) /
              100;
            const servicetax =
              (serfilter[i]?.service[j]?.Price *
                serfilter[i]?.service[j]?.servicetax) /
              100;
            const discount =
              (serfilter[i]?.service[j]?.Price *
                serfilter[i]?.service[j]?.Discount) /
              100;

            const total_amount =
              serfilter[i]?.service[j]?.Price + gst + servicetax - discount;

            if (
              parseInt(seletedMinAmount) <= total_amount &&
              total_amount <= parseInt(seletedMaxAmount)
            ) {
              filterarray?.push(serfilter[i]);
            }
          }
        }
      }

      setcatdata(filterarray);
    } else if (Servicefilter && categoryfilter && !seletedAmount) {
      const xyz = Vendor?.filter((person) =>
        person.service.some(
          (hobby) => hobby.Service_Category === categoryfilter
        )
      );

      const xyz1 = xyz?.filter((person) =>
        person.service.some((hobby) => hobby.ServiceName === Servicefilter)
      );

      setcatdata(xyz1);
    } else if (categoryfilter && !Servicefilter && !seletedAmount) {
      const xyz = Vendor?.filter((person) =>
        person.service.some(
          (hobby) => hobby.Service_Category === categoryfilter
        )
      );
      setcatdata(xyz);
    } else if (Servicefilter && !categoryfilter && !seletedAmount) {
      const xyz = Vendor?.filter((person) =>
        person.service.some((hobby) => hobby.ServiceName === Servicefilter)
      );
      console.log("xyz", Servicefilter, xyz);
      setcatdata(xyz);
    } else if (seletedAmount && !Servicefilter && !categoryfilter) {
      var xyz = seletedAmount?.split("-");
      var seletedMinAmount = xyz[0];
      var seletedMaxAmount = xyz[1];
      var filterarray = [];

      if (Vendor?.length > 0) {
        for (let i = 0; i < Vendor?.length; i++) {
          for (let j = 0; j < Vendor[i]?.service?.length; j++) {
            const gst =
              (Vendor[i]?.service[j]?.Price * Vendor[i]?.service[j]?.GST) / 100;
            const servicetax =
              (Vendor[i]?.service[j]?.Price *
                Vendor[i]?.service[j]?.servicetax) /
              100;
            const discount =
              (Vendor[i]?.service[j]?.Price * Vendor[i]?.service[j]?.Discount) /
              100;

            const total_amount =
              Vendor[i]?.service[j]?.Price + gst + servicetax - discount;

            if (
              parseInt(seletedMinAmount) <= total_amount &&
              total_amount <= parseInt(seletedMaxAmount)
            ) {
              filterarray?.push(Vendor[i]);
            }
          }
        }
      }

      // console.log("filterarray", filterarray);

      setcatdata(filterarray);
    } else if (seletedAmount && !Servicefilter && categoryfilter) {
      var xyz = seletedAmount?.split("-");
      var seletedMinAmount = xyz[0];
      var seletedMaxAmount = xyz[1];

      const catfilter = Vendor?.filter((person) =>
        person.service.some(
          (hobby) => hobby.Service_Category === categoryfilter
        )
      );
      var filterarray = [];
      if (catfilter?.length > 0) {
        for (let i = 0; i < catfilter?.length; i++) {
          for (let j = 0; j < catfilter[i]?.service?.length; j++) {
            const gst =
              (catfilter[i]?.service[j]?.Price *
                catfilter[i]?.service[j]?.GST) /
              100;
            const servicetax =
              (catfilter[i]?.service[j]?.Price *
                catfilter[i]?.service[j]?.servicetax) /
              100;
            const discount =
              (catfilter[i]?.service[j]?.Price *
                catfilter[i]?.service[j]?.Discount) /
              100;

            const total_amount =
              catfilter[i]?.service[j]?.Price + gst + servicetax - discount;

            if (
              parseInt(seletedMinAmount) <= total_amount &&
              total_amount <= parseInt(seletedMaxAmount)
            ) {
              filterarray?.push(catfilter[i]);
            }
          }
        }
      }

      setcatdata(filterarray);
    } else if (seletedAmount && Servicefilter && !categoryfilter) {
      var xyz = seletedAmount?.split("-");
      var seletedMinAmount = xyz[0];
      var seletedMaxAmount = xyz[1];

      const serfilter = Vendor?.filter((person) =>
        person.service.some((hobby) => hobby.ServiceName === Servicefilter)
      );
      var filterarray = [];
      if (serfilter?.length > 0) {
        for (let i = 0; i < serfilter?.length; i++) {
          for (let j = 0; j < serfilter[i]?.service?.length; j++) {
            const gst =
              (serfilter[i]?.service[j]?.Price *
                serfilter[i]?.service[j]?.GST) /
              100;
            const servicetax =
              (serfilter[i]?.service[j]?.Price *
                serfilter[i]?.service[j]?.servicetax) /
              100;
            const discount =
              (serfilter[i]?.service[j]?.Price *
                serfilter[i]?.service[j]?.Discount) /
              100;

            const total_amount =
              serfilter[i]?.service[j]?.Price + gst + servicetax - discount;

            if (
              parseInt(seletedMinAmount) <= total_amount &&
              total_amount <= parseInt(seletedMaxAmount)
            ) {
              filterarray?.push(serfilter[i]);
            }
          }
        }
      }

      setcatdata(filterarray);
    } else if (!Servicefilter && !categoryfilter && !seletedAmount) {
      setcatdata(Vendor);
    }
  }, [
    SelectTopBrands,
    SelectTopRated,
    seletedAmount,
    categoryfilter,
    Servicefilter,
  ]);

  const filteredcat = catdata?.filter((address) => {
    const distance = calculateDistance(
      latitude,
      longitude,
      address.latitude,
      address.longitude
    );
    // console.log("distance", distance);
    return distance <= radius;
  });

  // Step 1: Calculate average rating and return updated items
  const updatedCat = filteredcat?.map((item) => {
    if (item?.reviews && item?.reviews?.length > 0) {
      const totalRatings = item?.reviews?.reduce(
        (sum, review) => sum + review?.rating,
        0
      );
      item.averageRating = totalRatings / item?.reviews?.length; // Add averageRating to item
    } else {
      item.averageRating = 0; // Default if no reviews
    }
    return item;
  });

  // Step 2: Remove duplicates by converting the `reviews` array to a JSON string for comparison
  const uniqueCat = updatedCat?.filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          JSON.stringify(t.businessName) === JSON.stringify(item.businessName)
      )
  );

  // Step 3: Sort by the average ratings in descending order
  const filteredcatdata = uniqueCat?.sort(
    (a, b) => b.averageRating - a.averageRating
  );

  const filteredcat1 = catdata?.filter((address) => {
    const distance = calculateDistance(
      latitude,
      longitude,
      address.latitude,
      address.longitude
    );
    // console.log("distance", distance);
    return distance <= radius1 && distance > radius;
  });

  // Step 1: Calculate average rating and return updated items
  const updatedCat1 = filteredcat1?.map((item) => {
    if (item?.reviews && item?.reviews?.length > 0) {
      const totalRatings = item?.reviews?.reduce(
        (sum, review) => sum + review?.rating,
        0
      );
      item.averageRating = totalRatings / item?.reviews?.length; // Add averageRating to item
    } else {
      item.averageRating = 0; // Default if no reviews
    }
    return item;
  });

  // Step 2: Remove duplicates by converting the `reviews` array to a JSON string for comparison
  const uniqueCat1 = updatedCat1?.filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          JSON.stringify(t.businessName) === JSON.stringify(item.businessName)
      )
  );

  // Step 3: Sort by the average ratings in descending order
  const filteredcatdata1 = uniqueCat1?.sort(
    (a, b) => b.averageRating - a.averageRating
  );

  const filteredcat2 = catdata?.filter((address) => {
    const distance = calculateDistance(
      latitude,
      longitude,
      address.latitude,
      address.longitude
    );
    // console.log("distance", distance);
    return distance <= radius2 && distance > radius1;
  });

  // Step 1: Calculate average rating and return updated items
  const updatedCat2 = filteredcat2?.map((item) => {
    if (item?.reviews && item?.reviews?.length > 0) {
      const totalRatings = item?.reviews?.reduce(
        (sum, review) => sum + review?.rating,
        0
      );
      item.averageRating = totalRatings / item?.reviews?.length; // Add averageRating to item
    } else {
      item.averageRating = 0; // Default if no reviews
    }
    return item;
  });

  // Step 2: Remove duplicates by converting the `reviews` array to a JSON string for comparison
  const uniqueCat2 = updatedCat2?.filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          JSON.stringify(t.businessName) === JSON.stringify(item.businessName)
      )
  );

  // Step 3: Sort by the average ratings in descending order
  const filteredcatdata2 = uniqueCat2?.sort(
    (a, b) => b.averageRating - a.averageRating
  );

  const filteredcat3 = catdata?.filter((address) => {
    const distance = calculateDistance(
      latitude,
      longitude,
      address.latitude,
      address.longitude
    );
    // console.log("distance", distance);
    return distance <= radius3 && distance > radius2;
  });

  // Step 1: Calculate average rating and return updated items
  const updatedCat3 = filteredcat3?.map((item) => {
    if (item?.reviews && item?.reviews?.length > 0) {
      const totalRatings = item?.reviews?.reduce(
        (sum, review) => sum + review?.rating,
        0
      );
      item.averageRating = totalRatings / item?.reviews?.length; // Add averageRating to item
    } else {
      item.averageRating = 0; // Default if no reviews
    }
    return item;
  });

  // Step 2: Remove duplicates by converting the `reviews` array to a JSON string for comparison
  const uniqueCat3 = updatedCat3?.filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          JSON.stringify(t.businessName) === JSON.stringify(item.businessName)
      )
  );

  // Step 3: Sort by the average ratings in descending order
  const filteredcatdata3 = uniqueCat3?.sort(
    (a, b) => b.averageRating - a.averageRating
  );

  const filteredcat4 = catdata?.filter((address) => {
    const distance = calculateDistance(
      latitude,
      longitude,
      address.latitude,
      address.longitude
    );
    // console.log("distance", distance);
    return distance > radius3;
  });

  // Step 1: Calculate average rating and return updated items
  const updatedCat4 = filteredcat4?.map((item) => {
    if (item?.reviews && item?.reviews?.length > 0) {
      const totalRatings = item?.reviews?.reduce(
        (sum, review) => sum + review?.rating,
        0
      );
      item.averageRating = totalRatings / item?.reviews?.length; // Add averageRating to item
    } else {
      item.averageRating = 0; // Default if no reviews
    }
    return item;
  });

  // Step 2: Remove duplicates by converting the `reviews` array to a JSON string for comparison
  const uniqueCat4 = updatedCat4?.filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          JSON.stringify(t.businessName) === JSON.stringify(item.businessName)
      )
  );

  // Step 3: Sort by the average ratings in descending order
  const filteredcatdata4 = uniqueCat4?.sort(
    (a, b) => b.averageRating - a.averageRating
  );

  // console.log("newarry", categoryfilter);

  // console.log("servicearray", servicearray);
  const filteredservicedata = servicearray?.filter((address) => {
    const distance = calculateDistance(
      latitude,
      longitude,
      address.latitude,
      address.longitude
    );
    // console.log("distance", distance);
    return distance <= radius;
  });

  const filteredservicedata1 = servicearray.filter((address) => {
    const distance = calculateDistance(
      latitude,
      longitude,
      address.latitude,
      address.longitude
    );
    // console.log("distance", distance);
    return distance <= radius1 && distance > radius;
  });

  const filteredservicedata2 = servicearray.filter((address) => {
    const distance = calculateDistance(
      latitude,
      longitude,
      address.latitude,
      address.longitude
    );
    // console.log("distance", distance);
    return distance <= radius2 && distance > radius1;
  });

  const filteredservicedata3 = servicearray.filter((address) => {
    const distance = calculateDistance(
      latitude,
      longitude,
      address.latitude,
      address.longitude
    );
    // console.log("distance", distance);
    return distance <= radius3 && distance > radius2;
  });

  const filteredservicedata4 = servicearray.filter((address) => {
    const distance = calculateDistance(
      latitude,
      longitude,
      address.latitude,
      address.longitude
    );
    // console.log("distance", distance);
    return distance > radius3;
  });

  const [show, setShow] = useState(false);
  const [cupondata, setcupondata] = useState({});
  const [promocode, setpromocode] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setcupondata(data);
  };

  const [image, setimage] = useState();
  const [iddata, setiddata] = useState({});
  const changePrice = (event, newValue) => {
    setValue(newValue);
  };

  // copy to clipboard
  const [isCopied, setIsCopied] = React.useState(false);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(text);
      // alert("Copied!");
      notify("Copied!");
    });
  };

  useEffect(() => {
    if (type) {
      getallVendor();
      getallCategory();
      getallservices();
    }
    getPromocode();
    if (address) {
      getMallandShopping();
      getstarhotels();
      getTopbrands();
      handleGeocode();
    }
  }, [address, selectedType]);

  const getallCategory = () => {
    axios
      .get("https://spazo.co.in/api/admin/getcategory")
      .then(function (response) {
        console.log(response.data);
        setcategory(
          response.data.category?.filter(
            (cat) => cat?.cattype === (selectedType ? selectedType : type)
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getallservices = () => {
    axios
      .get("https://spazo.co.in/api/admin/getAdminservice")
      .then(function (response) {
        console.log("Service", response.data);
        setService(
          response.data.Adminservice?.filter(
            (item) => item?.type === (selectedType ? selectedType : type)
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getTopbrands = () => {
    axios
      .get("https://spazo.co.in/api/admin/getTopbrandswithserveice")
      .then(function (response) {
        // console.log("Topbrandsdsdfdsfds", response.data.Topbrand);

        const newtypefilterarray = [];
        if (response.data.Topbrand?.length > 0) {
          for (let i = 0; i < response.data.Topbrand?.length; i++) {
            newtypefilterarray.push(response.data.Topbrand[i].vendors[0]);
          }
        }

        // console.log("newtypefilterarray", newtypefilterarray);

        const typefilter = newtypefilterarray?.filter(
          (item) => item.type === (selectedType ? selectedType : type)
        );

        // console.log("typefilter", typefilter);

        const locationFilter =
          address === "Bengaluru"
            ? typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  (item.address.toLowerCase().includes("bengaluru") ||
                    item.address.toLowerCase().includes("bangalore"))
              )
            : address === "Mysore"
            ? typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  (item.address.toLowerCase().includes("mysore") ||
                    item.address.toLowerCase().includes("mysuru"))
              )
            : address === "Gurgaon"
            ? typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  (item.address.toLowerCase().includes("gurugram") ||
                    item.address.toLowerCase().includes("gurgaon"))
              )
            : (locationFilter = typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  item.address.toLowerCase().includes(address.toLowerCase())
              ));

        // console.log("locationFilter", locationFilter);

        setTopbrands(locationFilter);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getstarhotels = () => {
    axios
      .get("https://spazo.co.in/api/admin/getStarHotelswithserveice")
      .then(function (response) {
        // console.log("response.data.starHotels", response.data.starHotels);

        const newtypefilterarray = [];
        if (response.data.starHotels?.length > 0) {
          for (let i = 0; i < response.data.starHotels?.length; i++) {
            newtypefilterarray.push(response.data.starHotels[i]?.vendors[0]);
          }
        }

        const typefilter = newtypefilterarray?.filter(
          (item) => item.type === (selectedType ? selectedType : type)
        );

        // console.log("newtypefilterarray", newtypefilterarray);

        const locationFilter =
          address === "Bengaluru"
            ? typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  (item.address.toLowerCase().includes("bengaluru") ||
                    item.address.toLowerCase().includes("bangalore"))
              )
            : address === "Mysore"
            ? typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  (item.address.toLowerCase().includes("mysore") ||
                    item.address.toLowerCase().includes("mysuru"))
              )
            : address === "Gurgaon"
            ? typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  (item.address.toLowerCase().includes("gurugram") ||
                    item.address.toLowerCase().includes("gurgaon"))
              )
            : (locationFilter = typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  item.address.toLowerCase().includes(address.toLowerCase())
              ));

        // console.log("locationFilter", locationFilter);

        setStarhotels(locationFilter);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getMallandShopping = () => {
    axios
      .get("https://spazo.co.in/api/admin/getMallsandShoppingwithserveice")
      .then(function (response) {
        const newtypefilterarray = [];
        if (response.data.MallsandShopping?.length > 0) {
          for (let i = 0; i < response.data.MallsandShopping?.length; i++) {
            newtypefilterarray.push(
              response.data.MallsandShopping[i].vendors[0]
            );
          }
        }

        const typefilter = newtypefilterarray?.filter(
          (item) => item.type === (selectedType ? selectedType : type)
        );

        // console.log("newtypefilterarray", newtypefilterarray);

        const locationFilter =
          address === "Bengaluru"
            ? typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  (item.address.toLowerCase().includes("bengaluru") ||
                    item.address.toLowerCase().includes("bangalore"))
              )
            : address === "Mysore"
            ? typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  (item.address.toLowerCase().includes("mysore") ||
                    item.address.toLowerCase().includes("mysuru"))
              )
            : address === "Gurgaon"
            ? typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  (item.address.toLowerCase().includes("gurugram") ||
                    item.address.toLowerCase().includes("gurgaon"))
              )
            : (locationFilter = typefilter?.filter(
                (item) =>
                  item.approved === "Approved" &&
                  item.isBlocked === false &&
                  item?.type === (selectedType ? selectedType : type) &&
                  item.address.toLowerCase().includes(address.toLowerCase())
              ));

        // console.log("locationFilter", locationFilter);

        setMallsandshopping(locationFilter);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleGeocode = () => {
    const apiKey = "AIzaSyAeVFaPIlv5BTjUqMrBIVRfsM_SvDPegHg"; // Replace with your Google Maps API key
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`
      )
      .then((response) => {
        const { results, status } = response.data;

        if (status === "OK" && results.length > 0) {
          const { lat, lng } = results[0].geometry.location;
          setlatitude(lat);
          setlongitude(lng);
          seterror(null);
        } else {
          seterror("Address not found");
        }
      })
      .catch((error) => {
        seterror("Error occurred while fetching data");
      });
  };

  const getallVendor = () => {
    axios
      .get("https://spazo.co.in/api/vendor/allVendors")
      .then(function (response) {
        console.log(response.data);
        setVendor(
          response.data.allVendors?.filter(
            (item) =>
              item.approved === "Approved" &&
              item.isBlocked === false &&
              item?.type === (selectedType ? selectedType : type)
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getPromocode = () => {
    axios
      .get("https://spazo.co.in/api/admin/getPromocode")
      .then(function (response) {
        console.log(response.data);
        setpromocode(
          response.data.PromocodeList.filter(
            (item) =>
              item.Status === "Valid" && new Date(item.Startdate) <= currentDate
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log("promocode", promocode);
  return (
    <>
      <div>
        <Newheader2 />
        <Row className="me-0 ">
          <ToastContainer
            position="top-center"
            autoClose={30000}
            closeOnClick
            pauseOnHover
            draggable
          />
          <Col
            className="p-3"
            md={3}
            style={{
              borderRight: "1px solid rgb(217 212 208)",
              // height: "100vh",
            }}
          >
            {promocode?.length > 0 ? (
              <>
                <p
                  style={{
                    color: "#e22217",
                    fontWeight: "700",
                    fontSize: "22px",
                  }}
                >
                  Promos & Filters
                </p>
                <Carousel
                  autoPlay={true}
                  infiniteLoop={true}
                  showIndicators={false}
                >
                  {promocode?.map((prom) => {
                    const today = new Date(); // Get today's date
                    const endDate = new Date(prom?.Enddate); // Convert Enddate to a Date object

                    // Set the time of both dates to 00:00:00 to only compare the date part
                    today.setHours(0, 0, 0, 0);
                    endDate.setHours(0, 0, 0, 0);

                    const status = endDate < today ? "Expired" : "Valid";
                    return (
                      <div
                        style={{
                          boxShadow: "3px 4px 10px #cbc6c6",
                          padding: "10px",
                          backgroundColor: "white",
                        }}
                      >
                        <p>Win Rs {prom?.amount} instant off code</p>
                        {/* <p
                style={{
                  backgroundColor: "#e22217",
                  color: "white",
                  padding: "3px",
                  textAlign: "center",
                }}
              >
                NBLUCKY
              </p> */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              border: "1px dotted gray",
                              padding: "10px",
                              marginBottom: "0px",
                              color: "#e22217",
                            }}
                          >
                            {prom?.Promocode}
                          </p>
                          {status === "Expired" ? (
                            ""
                          ) : (
                            <button
                              style={{ borderColor: "gray", padding: "10px" }}
                              onClick={() => handleCopy(prom?.Promocode)}
                            >
                              Copy
                            </button>
                          )}
                        </div>

                        <div className="mt-3">
                          {status === "Expired" ? (
                            <span>Sorry..! Validity Expired</span>
                          ) : (
                            <span>
                              Valid till{" "}
                              {moment(prom?.Enddate).format("MMM Do YY")}
                            </span>
                          )}
                          {/* <span
                          style={{ color: "#e22217", marginLeft: "10px" }}
                          onClick={() => handleShow(prom)}
                        >
                          Know more
                        </span> */}
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
                <hr></hr>
              </>
            ) : (
              <></>
            )}
            {/* <div
            style={{
              boxShadow: "3px 4px 8px #cbc6c6",
              padding: "20px",
              backgroundColor: "#f3f5f7",
              marginTop: "20px",
            }}
            >
              <div
                style={{
                  display: "block",
                  width: "fit-content",
                }}
              >
                <p
                  style={{
                    color: "#e22217",
                    fontWeight: "700",
                    fontSize: "2"2px"",
                  }}
                >
                  Price Filter
                </p>
                <Slider
                  style={{ paddingTop: "30px", marginLeft: "30px" }}
                  value={value}
                  onChange={changePrice}
                  valueLabelDisplay="auto"
                  min={0}
                  max={10000}
                />
                <p style={{ marginLeft: "20px" }}>
                  {value[0]}/-
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {value[1]}/-
                </p>
              </div>
            </div>
            <hr></hr> */}
            <div
              style={{
                height: "400px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <p
                style={{
                  color: "#e22217",
                  fontWeight: "700",
                  fontSize: "22px",
                }}
              >
                Categories
              </p>
              <ul className="example">
                {category?.map((cat) => (
                  <li
                    onClick={() => setcategoryfilter(cat?.catname)}
                    style={{
                      fontWeight:
                        categoryfilter === cat?.catname ? "bold" : "500",
                      color:
                        categoryfilter === cat?.catname ? "#e22217" : "black",
                    }}
                  >
                    {cat?.catname}
                  </li>
                ))}
              </ul>
            </div>
            <hr></hr>
            <div
              style={{
                height: "400px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <p
                style={{
                  color: "#e22217",
                  fontWeight: "700",
                  fontSize: "22px",
                }}
              >
                Service's
              </p>
              <ul className="example">
                {Service?.map((ser) => (
                  <li
                    onClick={() => setServicefilter(ser?.servicename)}
                    style={{
                      fontWeight:
                        Servicefilter === ser?.servicename ? "bold" : "500",
                      color:
                        Servicefilter === ser?.servicename
                          ? "#e22217"
                          : "black",
                    }}
                  >
                    {ser?.servicename}
                  </li>
                ))}
              </ul>
            </div>
            <hr></hr>
            <div>
              <p
                style={{
                  color: "#e22217",
                  fontWeight: "700",
                  fontSize: "22px",
                }}
              >
                Price
              </p>
              <input
                type="radio"
                id="html"
                name="price"
                value="0-499"
                onChange={(e) => setseletedAmount(e.target.value)}
              />
              &nbsp;
              <label for="html" style={{ color: "black", fontSize: "16px" }}>
                Under ₹ 500
              </label>
              <br />
              <input
                type="radio"
                id="css"
                name="price"
                value="500-999"
                onChange={(e) => setseletedAmount(e.target.value)}
              />
              &nbsp;
              <label for="css" style={{ color: "black", fontSize: "16px" }}>
                ₹ 500 - ₹ 999
              </label>
              <br />
              <input
                type="radio"
                id="javascript"
                name="price"
                value="1000-1499"
                onChange={(e) => setseletedAmount(e.target.value)}
              />
              &nbsp;
              <label
                for="javascript"
                style={{ color: "black", fontSize: "16px" }}
              >
                ₹ 1000 - ₹ 1499
              </label>
              <br />
              <input
                type="radio"
                id="css"
                name="price"
                value="1500-1999"
                onChange={(e) => setseletedAmount(e.target.value)}
              />
              &nbsp;
              <label for="css" style={{ color: "black", fontSize: "16px" }}>
                ₹ 1500 - ₹ 1999
              </label>
              <br />
              <input
                type="radio"
                id="css"
                name="price"
                value="2000-2499"
                onChange={(e) => setseletedAmount(e.target.value)}
              />
              &nbsp;
              <label for="css" style={{ color: "black", fontSize: "16px" }}>
                ₹ 2000 - ₹ 2499
              </label>
              <br />
              <input
                type="radio"
                id="css"
                name="price"
                value="2500-1000000"
                onChange={(e) => setseletedAmount(e.target.value)}
              />
              &nbsp;
              <label for="css" style={{ color: "black", fontSize: "16px" }}>
                Greater than ₹ 2500
              </label>
            </div>
            {/* <hr></hr>
            <div>
              <p
                style={{
                  color: "#e22217",
                  fontWeight: "700",
                  fontSize: "22px",
                }}
              >
                Filter By
              </p>
              <input
                type="radio"
                id="TopBrands"
                name="Brands"
                value="Top Brands"
                onChange={(e) => setSelectTopBrands(e.target.value)}
              />
              <label
                htmlFor="TopBrands"
                style={{ color: "black", fontSize: "16px", marginLeft: "10px" }}
              >
                Top Brands
              </label>
              <br></br>
              <input
                type="radio"
                id="Malls"
                name="Brands"
                value="In Malls And Shopping Hubs"
                onChange={(e) => setSelectTopBrands(e.target.value)}
              />
              <label
                htmlFor="Malls"
                style={{ color: "black", fontSize: "16px", marginLeft: "10px" }}
              >
                In Malls And Shopping Hubs
              </label>
              <br></br>
              <input
                type="radio"
                id="StarHotels"
                name="Brands"
                value="At 5 Star Hotels"
                onChange={(e) => setSelectTopBrands(e.target.value)}
              />
              <label
                htmlFor="StarHotels"
                style={{ color: "black", fontSize: "16px", marginLeft: "10px" }}
              >
                At 5 Star Hotels
              </label>
              <br></br>
              <input
                type="checkbox"
                value={SelectTopRated}
                onChange={() => setSelectTopRated(!SelectTopRated)}
              />
              <label
                style={{ color: "black", fontSize: "16px", marginLeft: "10px" }}
              >
                Top Rated
              </label>
            </div> */}
          </Col>
          <Col md={9}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: "25px", fontWeight: "bold" }}>{address}</p>
              <div style={{ marginTop: "15px" }}>
                <select onChange={(e) => setselectedType(e.target.value)}>
                  <option>{type}</option>
                  {type === "Spa" ? "" : <option value="Spa">Spa</option>}
                  {type === "Salon" ? "" : <option value="Salon">Salon</option>}
                  {type === "Beauty Care Products" ? (
                    ""
                  ) : (
                    <option value="Beauty Care Products">
                      Beauty Care Products
                    </option>
                  )}
                </select>
              </div>
            </div>
            <hr></hr>
            {!categoryfilter && !Servicefilter && !seletedAmount ? (
              <>
                {filteredAddresses?.length > 0 ? (
                  <>
                    <p className="producttext">Less Than 5 Km</p>
                    {filteredAddresses?.map((item) => (
                      <>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <Col md={2}></Col>
                              <Col md={8} style={{ padding: "2px" }}>
                                <img
                                  src={
                                    iddata._id === item._id
                                      ? image
                                        ? image
                                        : "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                      : "https://spazo.co.in/Vendor/" +
                                        item?.spaimage1
                                  }
                                  style={{
                                    width: "100%",
                                    height: "300px",
                                    // objectFit: "cover",
                                    // overflow: "hidden",
                                  }}
                                />
                              </Col>
                              <Col md={2}>
                                <div>
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage1
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage2
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage2
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage3
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage3
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage4
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage4
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <img></img>
                          </Col>
                          <Col md={6}>
                            <p
                              style={{
                                fontSize: "25px",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {item?.businessName}
                            </p>
                            <p>{item?.address}</p>
                            <div className="ratingparking">
                              <div style={{ marginTop: "15px" }}>
                                <span>Ratings :</span>&nbsp;
                                <span
                                  style={{
                                    backgroundColor: "#e22217 ",
                                    color: "white",
                                    padding: "0px 5px",
                                    width: "10%",
                                  }}
                                >
                                  {isNaN(
                                    item?.reviews?.reduce(
                                      (a, rev) => a + rev.rating,
                                      0
                                    ) / item?.reviews?.length
                                  )
                                    ? 0
                                    : (
                                        item?.reviews?.reduce(
                                          (a, rev) => a + rev.rating,
                                          0
                                        ) / item?.reviews?.length
                                      )?.toFixed(1)}
                                  &nbsp;
                                  <i class="fas fa-star"></i>
                                </span>
                              </div>
                              <div>
                                <span>Parking :</span>
                                {item?.parking === "4-Wheeler" ? (
                                  <i
                                    class="fas fa-car"
                                    style={{ fontSize: "30px", margin: "3px" }}
                                  ></i>
                                ) : item?.parking === "2-Wheeler" ? (
                                  <i
                                    class="fas fa-motorcycle"
                                    style={{ fontSize: "30px", margin: "3px" }}
                                  ></i>
                                ) : item?.parking === "Both" ? (
                                  <>
                                    <i
                                      class="fas fa-car"
                                      style={{
                                        fontSize: "30px",
                                        margin: "3px",
                                      }}
                                    ></i>
                                    <i
                                      class="fas fa-motorcycle"
                                      style={{
                                        fontSize: "30px",
                                        margin: "3px",
                                      }}
                                    ></i>
                                  </>
                                ) : (
                                  item?.parking
                                )}
                              </div>
                            </div>

                            {/* <div className="mt-5">
                      <span
                        style={{
                          color: "#e22217",
                          fontSize: "30px",
                          fontWeight: "700",
                        }}
                      >
                        ₹2000
                      </span>
                      &nbsp;&nbsp;
                      <span
                        style={{
                          fontSize: "18px",
                          textDecoration: "line-through",
                        }}
                      >
                        ₹4000
                      </span>
                    </div> */}
                            <div className="mt-5">
                              <Link
                                style={{
                                  padding: "10px 20px",
                                  fontWeight: "700",
                                  textDecoration: "none",
                                  border: "1px solid black",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                }}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                View Details
                              </Link>
                              {/* <Link
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor: "transparent",
                                  fontWeight: "700",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                  margin: "10px",
                                }}
                                // onClick={() => window.location.assign("/Checkout")}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                Book Now
                              </Link> */}
                            </div>
                          </Col>
                        </Row>
                        <hr></hr>
                      </>
                    ))}
                  </>
                ) : (
                  ""
                )}
                {filteredAddresses1?.length > 0 ? (
                  <>
                    <p className="producttext">5 Km to 10 km</p>
                    {filteredAddresses1?.map((item) => (
                      <>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <Col md={2}></Col>
                              <Col md={8} style={{ padding: "2px" }}>
                                <img
                                  src={
                                    iddata._id === item._id
                                      ? image
                                        ? image
                                        : "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                      : "https://spazo.co.in/Vendor/" +
                                        item?.spaimage1
                                  }
                                  style={{
                                    width: "100%",
                                    height: "300px",
                                    // objectFit: "cover",
                                    // overflow: "hidden",
                                  }}
                                />
                              </Col>
                              <Col md={2}>
                                <div>
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage1
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage2
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage2
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage3
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage3
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage4
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage4
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <img></img>
                          </Col>
                          <Col md={6}>
                            <p
                              style={{
                                fontSize: "25px",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {item?.businessName}
                            </p>
                            <p>{item?.address}</p>
                            <div className="ratingparking">
                              <div style={{ marginTop: "15px" }}>
                                <span>Ratings :</span>&nbsp;
                                <span
                                  style={{
                                    backgroundColor: "#e22217 ",
                                    color: "white",
                                    padding: "0px 5px",
                                    width: "10%",
                                  }}
                                >
                                  {isNaN(
                                    item?.reviews?.reduce(
                                      (a, rev) => a + rev.rating,
                                      0
                                    ) / item?.reviews?.length
                                  )
                                    ? 0
                                    : (
                                        item?.reviews?.reduce(
                                          (a, rev) => a + rev.rating,
                                          0
                                        ) / item?.reviews?.length
                                      )?.toFixed(1)}
                                  &nbsp;
                                  <i class="fas fa-star"></i>
                                </span>
                              </div>
                              <div>
                                <span>Parking :</span>
                                {item?.parking === "4-Wheeler" ? (
                                  <i
                                    class="fas fa-car"
                                    style={{ fontSize: "30px", margin: "3px" }}
                                  ></i>
                                ) : item?.parking === "2-Wheeler" ? (
                                  <i
                                    class="fas fa-motorcycle"
                                    style={{ fontSize: "30px", margin: "3px" }}
                                  ></i>
                                ) : item?.parking === "Both" ? (
                                  <>
                                    <i
                                      class="fas fa-car"
                                      style={{
                                        fontSize: "30px",
                                        margin: "3px",
                                      }}
                                    ></i>
                                    <i
                                      class="fas fa-motorcycle"
                                      style={{
                                        fontSize: "30px",
                                        margin: "3px",
                                      }}
                                    ></i>
                                  </>
                                ) : (
                                  item?.parking
                                )}
                              </div>
                            </div>

                            {/* <div className="mt-5">
                      <span
                        style={{
                          color: "#e22217",
                          fontSize: "30px",
                          fontWeight: "700",
                        }}
                      >
                        ₹2000
                      </span>
                      &nbsp;&nbsp;
                      <span
                        style={{
                          fontSize: "18px",
                          textDecoration: "line-through",
                        }}
                      >
                        ₹4000
                      </span>
                    </div> */}
                            <div className="mt-5">
                              <Link
                                style={{
                                  padding: "10px 20px",
                                  fontWeight: "700",
                                  textDecoration: "none",
                                  border: "1px solid black",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                }}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                View Details
                              </Link>
                              {/* <Link
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor: "transparent",
                                  fontWeight: "700",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                  margin: "10px",
                                }}
                                // onClick={() => window.location.assign("/Checkout")}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                Book Now
                              </Link> */}
                            </div>
                          </Col>
                        </Row>
                        <hr></hr>
                      </>
                    ))}
                  </>
                ) : (
                  ""
                )}
                {filteredAddresses2?.length > 0 ? (
                  <>
                    <p className="producttext">10 Km to 15 km</p>
                    {filteredAddresses2?.map((item) => (
                      <>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <Col md={2}></Col>
                              <Col md={8} style={{ padding: "2px" }}>
                                <img
                                  src={
                                    iddata._id === item._id
                                      ? image
                                        ? image
                                        : "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                      : "https://spazo.co.in/Vendor/" +
                                        item?.spaimage1
                                  }
                                  style={{
                                    width: "100%",
                                    height: "300px",
                                    // objectFit: "cover",
                                    // overflow: "hidden",
                                  }}
                                />
                              </Col>
                              <Col md={2}>
                                <div>
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage1
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage2
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage2
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage3
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage3
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage4
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage4
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <img></img>
                          </Col>
                          <Col md={6}>
                            <p
                              style={{
                                fontSize: "25px",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {item?.businessName}
                            </p>
                            <p>{item?.address}</p>
                            <div className="ratingparking">
                              <div style={{ marginTop: "15px" }}>
                                <span>Ratings :</span>&nbsp;
                                <span
                                  style={{
                                    backgroundColor: "#e22217 ",
                                    color: "white",
                                    padding: "0px 5px",
                                    width: "10%",
                                  }}
                                >
                                  {isNaN(
                                    item?.reviews?.reduce(
                                      (a, rev) => a + rev.rating,
                                      0
                                    ) / item?.reviews?.length
                                  )
                                    ? 0
                                    : (
                                        item?.reviews?.reduce(
                                          (a, rev) => a + rev.rating,
                                          0
                                        ) / item?.reviews?.length
                                      )?.toFixed(1)}
                                  &nbsp;
                                  <i class="fas fa-star"></i>
                                </span>
                              </div>
                              <div>
                                <span>Parking :</span>
                                {item?.parking === "4-Wheeler" ? (
                                  <i
                                    class="fas fa-car"
                                    style={{ fontSize: "30px", margin: "3px" }}
                                  ></i>
                                ) : item?.parking === "2-Wheeler" ? (
                                  <i
                                    class="fas fa-motorcycle"
                                    style={{ fontSize: "30px", margin: "3px" }}
                                  ></i>
                                ) : item?.parking === "Both" ? (
                                  <>
                                    <i
                                      class="fas fa-car"
                                      style={{
                                        fontSize: "30px",
                                        margin: "3px",
                                      }}
                                    ></i>
                                    <i
                                      class="fas fa-motorcycle"
                                      style={{
                                        fontSize: "30px",
                                        margin: "3px",
                                      }}
                                    ></i>
                                  </>
                                ) : (
                                  item?.parking
                                )}
                              </div>
                            </div>

                            {/* <div className="mt-5">
                      <span
                        style={{
                          color: "#e22217",
                          fontSize: "30px",
                          fontWeight: "700",
                        }}
                      >
                        ₹2000
                      </span>
                      &nbsp;&nbsp;
                      <span
                        style={{
                          fontSize: "18px",
                          textDecoration: "line-through",
                        }}
                      >
                        ₹4000
                      </span>
                    </div> */}
                            <div className="mt-5">
                              <Link
                                style={{
                                  padding: "10px 20px",
                                  fontWeight: "700",
                                  textDecoration: "none",
                                  border: "1px solid black",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                }}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                View Details
                              </Link>
                              {/* <Link
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor: "transparent",
                                  fontWeight: "700",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                  margin: "10px",
                                }}
                                // onClick={() => window.location.assign("/Checkout")}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                Book Now
                              </Link> */}
                            </div>
                          </Col>
                        </Row>
                        <hr></hr>
                      </>
                    ))}
                  </>
                ) : (
                  ""
                )}
                {filteredAddresses3?.length > 0 ? (
                  <>
                    <p className="producttext">15 Km to 20 km</p>
                    {filteredAddresses3?.map((item) => (
                      <>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <Col md={2}></Col>
                              <Col md={8} style={{ padding: "2px" }}>
                                <img
                                  src={
                                    iddata._id === item._id
                                      ? image
                                        ? image
                                        : "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                      : "https://spazo.co.in/Vendor/" +
                                        item?.spaimage1
                                  }
                                  style={{
                                    width: "100%",
                                    height: "300px",
                                    // objectFit: "cover",
                                    // overflow: "hidden",
                                  }}
                                />
                              </Col>
                              <Col md={2}>
                                <div>
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage1
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage2
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage2
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage3
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage3
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage4
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage4
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <img></img>
                          </Col>
                          <Col md={6}>
                            <p
                              style={{
                                fontSize: "25px",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {item?.businessName}
                            </p>
                            <p>{item?.address}</p>
                            <div className="ratingparking">
                              <div style={{ marginTop: "15px" }}>
                                <span>Ratings :</span>&nbsp;
                                <span
                                  style={{
                                    backgroundColor: "#e22217 ",
                                    color: "white",
                                    padding: "0px 5px",
                                    width: "10%",
                                  }}
                                >
                                  {isNaN(
                                    item?.reviews?.reduce(
                                      (a, rev) => a + rev.rating,
                                      0
                                    ) / item?.reviews?.length
                                  )
                                    ? 0
                                    : (
                                        item?.reviews?.reduce(
                                          (a, rev) => a + rev.rating,
                                          0
                                        ) / item?.reviews?.length
                                      )?.toFixed(1)}
                                  &nbsp;
                                  <i class="fas fa-star"></i>
                                </span>
                              </div>
                              <div>
                                <span>Parking :</span>
                                {item?.parking === "4-Wheeler" ? (
                                  <i
                                    class="fas fa-car"
                                    style={{ fontSize: "30px", margin: "3px" }}
                                  ></i>
                                ) : item?.parking === "2-Wheeler" ? (
                                  <i
                                    class="fas fa-motorcycle"
                                    style={{ fontSize: "30px", margin: "3px" }}
                                  ></i>
                                ) : item?.parking === "Both" ? (
                                  <>
                                    <i
                                      class="fas fa-car"
                                      style={{
                                        fontSize: "30px",
                                        margin: "3px",
                                      }}
                                    ></i>
                                    <i
                                      class="fas fa-motorcycle"
                                      style={{
                                        fontSize: "30px",
                                        margin: "3px",
                                      }}
                                    ></i>
                                  </>
                                ) : (
                                  item?.parking
                                )}
                              </div>
                            </div>

                            {/* <div className="mt-5">
                      <span
                        style={{
                          color: "#e22217",
                          fontSize: "30px",
                          fontWeight: "700",
                        }}
                      >
                        ₹2000
                      </span>
                      &nbsp;&nbsp;
                      <span
                        style={{
                          fontSize: "18px",
                          textDecoration: "line-through",
                        }}
                      >
                        ₹4000
                      </span>
                    </div> */}
                            <div className="mt-5">
                              <Link
                                style={{
                                  padding: "10px 20px",
                                  fontWeight: "700",
                                  textDecoration: "none",
                                  border: "1px solid black",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                }}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                View Details
                              </Link>
                              {/* <Link
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor: "transparent",
                                  fontWeight: "700",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                  margin: "10px",
                                }}
                                // onClick={() => window.location.assign("/Checkout")}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                Book Now
                              </Link> */}
                            </div>
                          </Col>
                        </Row>
                        <hr></hr>
                      </>
                    ))}
                  </>
                ) : (
                  ""
                )}
                {filteredAddresses4?.length > 0 ? (
                  <>
                    <p className="producttext">More than 20 km</p>
                    {filteredAddresses4?.map((item) => (
                      <>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <Col md={2}></Col>
                              <Col md={8} style={{ padding: "2px" }}>
                                <img
                                  src={
                                    iddata._id === item._id
                                      ? image
                                        ? image
                                        : "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                      : "https://spazo.co.in/Vendor/" +
                                        item?.spaimage1
                                  }
                                  style={{
                                    width: "100%",
                                    height: "300px",
                                    // objectFit: "cover",
                                    // overflow: "hidden",
                                  }}
                                />
                              </Col>
                              <Col md={2}>
                                <div>
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage1
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage2
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage2
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage3
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage3
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage4
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage4
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <img></img>
                          </Col>
                          <Col md={6}>
                            <p
                              style={{
                                fontSize: "25px",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {item?.businessName}
                            </p>
                            <p>{item?.address}</p>
                            <div className="ratingparking">
                              <div style={{ marginTop: "15px" }}>
                                <span>Ratings :</span>&nbsp;
                                <span
                                  style={{
                                    backgroundColor: "#e22217 ",
                                    color: "white",
                                    padding: "0px 5px",
                                    width: "10%",
                                  }}
                                >
                                  {isNaN(
                                    item?.reviews?.reduce(
                                      (a, rev) => a + rev.rating,
                                      0
                                    ) / item?.reviews?.length
                                  )
                                    ? 0
                                    : (
                                        item?.reviews?.reduce(
                                          (a, rev) => a + rev.rating,
                                          0
                                        ) / item?.reviews?.length
                                      )?.toFixed(1)}
                                  &nbsp;
                                  <i class="fas fa-star"></i>
                                </span>
                              </div>
                              <div>
                                <span>Parking :</span>
                                {item?.parking === "4-Wheeler" ? (
                                  <i
                                    class="fas fa-car"
                                    style={{ fontSize: "30px", margin: "3px" }}
                                  ></i>
                                ) : item?.parking === "2-Wheeler" ? (
                                  <i
                                    class="fas fa-motorcycle"
                                    style={{ fontSize: "30px", margin: "3px" }}
                                  ></i>
                                ) : item?.parking === "Both" ? (
                                  <>
                                    <i
                                      class="fas fa-car"
                                      style={{
                                        fontSize: "30px",
                                        margin: "3px",
                                      }}
                                    ></i>
                                    <i
                                      class="fas fa-motorcycle"
                                      style={{
                                        fontSize: "30px",
                                        margin: "3px",
                                      }}
                                    ></i>
                                  </>
                                ) : (
                                  item?.parking
                                )}
                              </div>
                            </div>

                            {/* <div className="mt-5">
                      <span
                        style={{
                          color: "#e22217",
                          fontSize: "30px",
                          fontWeight: "700",
                        }}
                      >
                        ₹2000
                      </span>
                      &nbsp;&nbsp;
                      <span
                        style={{
                          fontSize: "18px",
                          textDecoration: "line-through",
                        }}
                      >
                        ₹4000
                      </span>
                    </div> */}
                            <div className="mt-5">
                              <Link
                                style={{
                                  padding: "10px 20px",
                                  fontWeight: "700",
                                  textDecoration: "none",
                                  border: "1px solid black",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                }}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                View Details
                              </Link>
                              {/* <Link
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor: "transparent",
                                  fontWeight: "700",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                  margin: "10px",
                                }}
                                // onClick={() => window.location.assign("/Checkout")}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                Book Now
                              </Link> */}
                            </div>
                          </Col>
                        </Row>
                        <hr></hr>
                      </>
                    ))}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                {filteredcatdata?.length > 0 ? (
                  <>
                    <p className="producttext">Less Than 5 Km</p>
                    {filteredcatdata?.map((item) => (
                      <>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <Col md={2}></Col>
                              <Col md={8} style={{ padding: "2px" }}>
                                <img
                                  src={
                                    iddata._id === item._id
                                      ? image
                                        ? image
                                        : "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                      : "https://spazo.co.in/Vendor/" +
                                        item?.spaimage1
                                  }
                                  style={{
                                    width: "100%",
                                    height: "300px",
                                    // objectFit: "cover",
                                    // overflow: "hidden",
                                  }}
                                />
                              </Col>
                              <Col md={2}>
                                <div>
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage1
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage2
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage2
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage3
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage3
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage4
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage4
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <img></img>
                          </Col>
                          <Col md={6}>
                            <p
                              style={{
                                fontSize: "25px",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {item?.businessName}
                            </p>
                            <p>{item?.address}</p>
                            <div className="ratingparking">
                              <div style={{ marginTop: "15px" }}>
                                <span>Ratings :</span>&nbsp;
                                <span
                                  style={{
                                    backgroundColor: "#e22217 ",
                                    color: "white",
                                    padding: "0px 5px",
                                    width: "10%",
                                  }}
                                >
                                  {isNaN(
                                    item?.reviews?.reduce(
                                      (a, rev) => a + rev.rating,
                                      0
                                    ) / item?.reviews?.length
                                  )
                                    ? 0
                                    : (
                                        item?.reviews?.reduce(
                                          (a, rev) => a + rev.rating,
                                          0
                                        ) / item?.reviews?.length
                                      )?.toFixed(1)}
                                  &nbsp;
                                  <i class="fas fa-star"></i>
                                </span>
                              </div>
                              <div>
                                <span>Parking :</span>
                                {item?.parking === "4-Wheeler" ? (
                                  <i
                                    class="fas fa-car"
                                    style={{ fontSize: "30px", margin: "3px" }}
                                  ></i>
                                ) : item?.parking === "2-Wheeler" ? (
                                  <i
                                    class="fas fa-motorcycle"
                                    style={{ fontSize: "30px", margin: "3px" }}
                                  ></i>
                                ) : item?.parking === "Both" ? (
                                  <>
                                    <i
                                      class="fas fa-car"
                                      style={{
                                        fontSize: "30px",
                                        margin: "3px",
                                      }}
                                    ></i>
                                    <i
                                      class="fas fa-motorcycle"
                                      style={{
                                        fontSize: "30px",
                                        margin: "3px",
                                      }}
                                    ></i>
                                  </>
                                ) : (
                                  item?.parking
                                )}
                              </div>
                            </div>

                            {/* <div className="mt-5">
                      <span
                        style={{
                          color: "#e22217",
                          fontSize: "30px",
                          fontWeight: "700",
                        }}
                      >
                        ₹2000
                      </span>
                      &nbsp;&nbsp;
                      <span
                        style={{
                          fontSize: "18px",
                          textDecoration: "line-through",
                        }}
                      >
                        ₹4000
                      </span>
                    </div> */}
                            <div className="mt-5">
                              <Link
                                style={{
                                  padding: "10px 20px",
                                  fontWeight: "700",
                                  textDecoration: "none",
                                  border: "1px solid black",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                }}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                View Details
                              </Link>
                              {/* <Link
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor: "transparent",
                                  fontWeight: "700",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                  margin: "10px",
                                }}
                                // onClick={() => window.location.assign("/Checkout")}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                Book Now
                              </Link> */}
                            </div>
                          </Col>
                        </Row>
                        <hr></hr>
                      </>
                    ))}
                  </>
                ) : (
                  ""
                )}
                {filteredcatdata1?.length > 0 ? (
                  <>
                    <p className="producttext">5 Km to 10 km</p>
                    {filteredcatdata1?.map((item) => (
                      <>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <Col md={2}></Col>
                              <Col md={8} style={{ padding: "2px" }}>
                                <img
                                  src={
                                    iddata._id === item._id
                                      ? image
                                        ? image
                                        : "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                      : "https://spazo.co.in/Vendor/" +
                                        item?.spaimage1
                                  }
                                  style={{
                                    width: "100%",
                                    height: "300px",
                                    // objectFit: "cover",
                                    // overflow: "hidden",
                                  }}
                                />
                              </Col>
                              <Col md={2}>
                                <div>
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage1
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage2
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage2
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage3
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage3
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage4
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage4
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <img></img>
                          </Col>
                          <Col md={6}>
                            <p
                              style={{
                                fontSize: "25px",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {item?.businessName}
                            </p>
                            <p>{item?.address}</p>
                            <div className="ratingparking">
                              <div style={{ marginTop: "15px" }}>
                                <span>Ratings :</span>&nbsp;
                                <span
                                  style={{
                                    backgroundColor: "#e22217 ",
                                    color: "white",
                                    padding: "0px 5px",
                                    width: "10%",
                                  }}
                                >
                                  {isNaN(
                                    item?.reviews?.reduce(
                                      (a, rev) => a + rev.rating,
                                      0
                                    ) / item?.reviews?.length
                                  )
                                    ? 0
                                    : (
                                        item?.reviews?.reduce(
                                          (a, rev) => a + rev.rating,
                                          0
                                        ) / item?.reviews?.length
                                      )?.toFixed(1)}
                                  &nbsp;
                                  <i class="fas fa-star"></i>
                                </span>
                              </div>
                              <div>
                                <span>Parking :</span>
                                {item?.parking === "4-Wheeler" ? (
                                  <i
                                    class="fas fa-car"
                                    style={{ fontSize: "30px", margin: "3px" }}
                                  ></i>
                                ) : item?.parking === "2-Wheeler" ? (
                                  <i
                                    class="fas fa-motorcycle"
                                    style={{ fontSize: "30px", margin: "3px" }}
                                  ></i>
                                ) : item?.parking === "Both" ? (
                                  <>
                                    <i
                                      class="fas fa-car"
                                      style={{
                                        fontSize: "30px",
                                        margin: "3px",
                                      }}
                                    ></i>
                                    <i
                                      class="fas fa-motorcycle"
                                      style={{
                                        fontSize: "30px",
                                        margin: "3px",
                                      }}
                                    ></i>
                                  </>
                                ) : (
                                  item?.parking
                                )}
                              </div>
                            </div>

                            {/* <div className="mt-5">
                      <span
                        style={{
                          color: "#e22217",
                          fontSize: "30px",
                          fontWeight: "700",
                        }}
                      >
                        ₹2000
                      </span>
                      &nbsp;&nbsp;
                      <span
                        style={{
                          fontSize: "18px",
                          textDecoration: "line-through",
                        }}
                      >
                        ₹4000
                      </span>
                    </div> */}
                            <div className="mt-5">
                              <Link
                                style={{
                                  padding: "10px 20px",
                                  fontWeight: "700",
                                  textDecoration: "none",
                                  border: "1px solid black",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                }}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                View Details
                              </Link>
                              {/* <Link
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor: "transparent",
                                  fontWeight: "700",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                  margin: "10px",
                                }}
                                // onClick={() => window.location.assign("/Checkout")}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                Book Now
                              </Link> */}
                            </div>
                          </Col>
                        </Row>
                        <hr></hr>
                      </>
                    ))}
                  </>
                ) : (
                  ""
                )}
                {filteredcatdata2?.length > 0 ? (
                  <>
                    <p className="producttext">10 Km to 15 km</p>
                    {filteredcatdata2?.map((item) => (
                      <>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <Col md={2}></Col>
                              <Col md={8} style={{ padding: "2px" }}>
                                <img
                                  src={
                                    iddata._id === item._id
                                      ? image
                                        ? image
                                        : "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                      : "https://spazo.co.in/Vendor/" +
                                        item?.spaimage1
                                  }
                                  style={{
                                    width: "100%",
                                    height: "300px",
                                    // objectFit: "cover",
                                    // overflow: "hidden",
                                  }}
                                />
                              </Col>
                              <Col md={2}>
                                <div>
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage1
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage2
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage2
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage3
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage3
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage4
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage4
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <img></img>
                          </Col>
                          <Col md={6}>
                            <p
                              style={{
                                fontSize: "25px",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {item?.businessName}
                            </p>
                            <p>{item?.address}</p>
                            <div className="ratingparking">
                              <div style={{ marginTop: "15px" }}>
                                <span>Ratings :</span>&nbsp;
                                <span
                                  style={{
                                    backgroundColor: "#e22217 ",
                                    color: "white",
                                    padding: "0px 5px",
                                    width: "10%",
                                  }}
                                >
                                  {isNaN(
                                    item?.reviews?.reduce(
                                      (a, rev) => a + rev.rating,
                                      0
                                    ) / item?.reviews?.length
                                  )
                                    ? 0
                                    : (
                                        item?.reviews?.reduce(
                                          (a, rev) => a + rev.rating,
                                          0
                                        ) / item?.reviews?.length
                                      )?.toFixed(1)}
                                  &nbsp;
                                  <i class="fas fa-star"></i>
                                </span>
                              </div>
                              <div>
                                <span>Parking :</span>
                                {item?.parking === "4-Wheeler" ? (
                                  <i
                                    class="fas fa-car"
                                    style={{ fontSize: "30px", margin: "3px" }}
                                  ></i>
                                ) : item?.parking === "2-Wheeler" ? (
                                  <i
                                    class="fas fa-motorcycle"
                                    style={{ fontSize: "30px", margin: "3px" }}
                                  ></i>
                                ) : item?.parking === "Both" ? (
                                  <>
                                    <i
                                      class="fas fa-car"
                                      style={{
                                        fontSize: "30px",
                                        margin: "3px",
                                      }}
                                    ></i>
                                    <i
                                      class="fas fa-motorcycle"
                                      style={{
                                        fontSize: "30px",
                                        margin: "3px",
                                      }}
                                    ></i>
                                  </>
                                ) : (
                                  item?.parking
                                )}
                              </div>
                            </div>

                            {/* <div className="mt-5">
                      <span
                        style={{
                          color: "#e22217",
                          fontSize: "30px",
                          fontWeight: "700",
                        }}
                      >
                        ₹2000
                      </span>
                      &nbsp;&nbsp;
                      <span
                        style={{
                          fontSize: "18px",
                          textDecoration: "line-through",
                        }}
                      >
                        ₹4000
                      </span>
                    </div> */}
                            <div className="mt-5">
                              <Link
                                style={{
                                  padding: "10px 20px",
                                  fontWeight: "700",
                                  textDecoration: "none",
                                  border: "1px solid black",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                }}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                View Details
                              </Link>
                              {/* <Link
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor: "transparent",
                                  fontWeight: "700",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                  margin: "10px",
                                }}
                                // onClick={() => window.location.assign("/Checkout")}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                Book Now
                              </Link> */}
                            </div>
                          </Col>
                        </Row>
                        <hr></hr>
                      </>
                    ))}
                  </>
                ) : (
                  ""
                )}
                {filteredcatdata3?.length > 0 ? (
                  <>
                    <p className="producttext">15 Km to 20 km</p>
                    {filteredcatdata3?.map((item) => (
                      <>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <Col md={2}></Col>
                              <Col md={8} style={{ padding: "2px" }}>
                                <img
                                  src={
                                    iddata._id === item._id
                                      ? image
                                        ? image
                                        : "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                      : "https://spazo.co.in/Vendor/" +
                                        item?.spaimage1
                                  }
                                  style={{
                                    width: "100%",
                                    height: "300px",
                                    // objectFit: "cover",
                                    // overflow: "hidden",
                                  }}
                                />
                              </Col>
                              <Col md={2}>
                                <div>
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage1
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage2
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage2
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage3
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage3
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage4
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage4
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <img></img>
                          </Col>
                          <Col md={6}>
                            <p
                              style={{
                                fontSize: "25px",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {item?.businessName}
                            </p>
                            <p>{item?.address}</p>
                            <div className="ratingparking">
                              <div style={{ marginTop: "15px" }}>
                                <span>Ratings :</span>&nbsp;
                                <span
                                  style={{
                                    backgroundColor: "#e22217 ",
                                    color: "white",
                                    padding: "0px 5px",
                                    width: "10%",
                                  }}
                                >
                                  {isNaN(
                                    item?.reviews?.reduce(
                                      (a, rev) => a + rev.rating,
                                      0
                                    ) / item?.reviews?.length
                                  )
                                    ? 0
                                    : (
                                        item?.reviews?.reduce(
                                          (a, rev) => a + rev.rating,
                                          0
                                        ) / item?.reviews?.length
                                      )?.toFixed(1)}
                                  &nbsp;
                                  <i class="fas fa-star"></i>
                                </span>
                              </div>
                              <div>
                                <span>Parking :</span>
                                {item?.parking === "4-Wheeler" ? (
                                  <i
                                    class="fas fa-car"
                                    style={{ fontSize: "30px", margin: "3px" }}
                                  ></i>
                                ) : item?.parking === "2-Wheeler" ? (
                                  <i
                                    class="fas fa-motorcycle"
                                    style={{ fontSize: "30px", margin: "3px" }}
                                  ></i>
                                ) : item?.parking === "Both" ? (
                                  <>
                                    <i
                                      class="fas fa-car"
                                      style={{
                                        fontSize: "30px",
                                        margin: "3px",
                                      }}
                                    ></i>
                                    <i
                                      class="fas fa-motorcycle"
                                      style={{
                                        fontSize: "30px",
                                        margin: "3px",
                                      }}
                                    ></i>
                                  </>
                                ) : (
                                  item?.parking
                                )}
                              </div>
                            </div>

                            {/* <div className="mt-5">
                      <span
                        style={{
                          color: "#e22217",
                          fontSize: "30px",
                          fontWeight: "700",
                        }}
                      >
                        ₹2000
                      </span>
                      &nbsp;&nbsp;
                      <span
                        style={{
                          fontSize: "18px",
                          textDecoration: "line-through",
                        }}
                      >
                        ₹4000
                      </span>
                    </div> */}
                            <div className="mt-5">
                              <Link
                                style={{
                                  padding: "10px 20px",
                                  fontWeight: "700",
                                  textDecoration: "none",
                                  border: "1px solid black",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                }}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                View Details
                              </Link>
                              {/* <Link
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor: "transparent",
                                  fontWeight: "700",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                  margin: "10px",
                                }}
                                // onClick={() => window.location.assign("/Checkout")}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                Book Now
                              </Link> */}
                            </div>
                          </Col>
                        </Row>
                        <hr></hr>
                      </>
                    ))}
                  </>
                ) : (
                  ""
                )}
                {filteredcatdata4?.length > 0 ? (
                  <>
                    <p className="producttext">More than 20 km</p>
                    {filteredcatdata4?.map((item) => (
                      <>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <Col md={2}></Col>
                              <Col md={8} style={{ padding: "2px" }}>
                                <img
                                  src={
                                    iddata._id === item._id
                                      ? image
                                        ? image
                                        : "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                      : "https://spazo.co.in/Vendor/" +
                                        item?.spaimage1
                                  }
                                  style={{
                                    width: "100%",
                                    height: "300px",
                                    // objectFit: "cover",
                                    // overflow: "hidden",
                                  }}
                                />
                              </Col>
                              <Col md={2}>
                                <div>
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage1
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage1
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage2
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage2
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage3
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage3
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                  <img
                                    src={
                                      "https://spazo.co.in/Vendor/" +
                                      item?.spaimage4
                                    }
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      margin: "2px",
                                      // objectFit: "cover",
                                      // overflow: "hidden",
                                    }}
                                    onClick={(e) => {
                                      setimage(
                                        "https://spazo.co.in/Vendor/" +
                                          item?.spaimage4
                                      );
                                      setiddata(item);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <img></img>
                          </Col>
                          <Col md={6}>
                            <p
                              style={{
                                fontSize: "25px",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {item?.businessName}
                            </p>
                            <p>{item?.address}</p>
                            <div className="ratingparking">
                              <div style={{ marginTop: "15px" }}>
                                <span>Ratings :</span>&nbsp;
                                <span
                                  style={{
                                    backgroundColor: "#e22217 ",
                                    color: "white",
                                    padding: "0px 5px",
                                    width: "10%",
                                  }}
                                >
                                  {isNaN(
                                    item?.reviews?.reduce(
                                      (a, rev) => a + rev.rating,
                                      0
                                    ) / item?.reviews?.length
                                  )
                                    ? 0
                                    : (
                                        item?.reviews?.reduce(
                                          (a, rev) => a + rev.rating,
                                          0
                                        ) / item?.reviews?.length
                                      )?.toFixed(1)}
                                  &nbsp;
                                  <i class="fas fa-star"></i>
                                </span>
                              </div>
                              <div>
                                <span>Parking :</span>
                                {item?.parking === "4-Wheeler" ? (
                                  <i
                                    class="fas fa-car"
                                    style={{ fontSize: "30px", margin: "3px" }}
                                  ></i>
                                ) : item?.parking === "2-Wheeler" ? (
                                  <i
                                    class="fas fa-motorcycle"
                                    style={{ fontSize: "30px", margin: "3px" }}
                                  ></i>
                                ) : item?.parking === "Both" ? (
                                  <>
                                    <i
                                      class="fas fa-car"
                                      style={{
                                        fontSize: "30px",
                                        margin: "3px",
                                      }}
                                    ></i>
                                    <i
                                      class="fas fa-motorcycle"
                                      style={{
                                        fontSize: "30px",
                                        margin: "3px",
                                      }}
                                    ></i>
                                  </>
                                ) : (
                                  item?.parking
                                )}
                              </div>
                            </div>

                            {/* <div className="mt-5">
                      <span
                        style={{
                          color: "#e22217",
                          fontSize: "30px",
                          fontWeight: "700",
                        }}
                      >
                        ₹2000
                      </span>
                      &nbsp;&nbsp;
                      <span
                        style={{
                          fontSize: "18px",
                          textDecoration: "line-through",
                        }}
                      >
                        ₹4000
                      </span>
                    </div> */}
                            <div className="mt-5">
                              <Link
                                style={{
                                  padding: "10px 20px",
                                  fontWeight: "700",
                                  textDecoration: "none",
                                  border: "1px solid black",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                }}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                View Details
                              </Link>
                              {/* <Link
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor: "transparent",
                                  fontWeight: "700",
                                  backgroundColor: "#1ab64f",
                                  color: "white",
                                  borderColor: "#1ab64f",
                                  margin: "10px",
                                }}
                                // onClick={() => window.location.assign("/Checkout")}
                                to="/Newproductdetails"
                                state={{ item: item }}
                              >
                                Book Now
                              </Link> */}
                            </div>
                          </Col>
                        </Row>
                        <hr></hr>
                      </>
                    ))}
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
      <Modal show={show} onHide={handleClose} className="modal1111">
        <Modal.Header closeButton style={{ backgroundColor: "#f3f5f7" }}>
          <Modal.Title>Offers details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ border: "1px dotted gray", margin: "10px" }}>
            <Row>
              <Col
                md={4}
                style={{
                  borderRight: "1px dotted gray",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "25px",
                    fontWeight: "bold",
                    marginTop: "100%",
                  }}
                >
                  Win Rs {cupondata?.amount} instant off code
                </p>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      border: "1px dotted gray",
                      padding: "10px",
                      marginBottom: "0px",
                      color: "#e22217",
                    }}
                  >
                    {cupondata?.Promocode}
                  </p>
                  <button
                    style={{ borderColor: "gray", padding: "10px" }}
                    onClick={() => handleCopy(cupondata?.Promocode)}
                  >
                    Copy
                  </button>
                </div>
              </Col>
              <Col md={8} style={{ padding: "2px" }}>
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  Terms & Conditions
                </p>
                <div style={{ textAlign: "justify", fontSize: "16px" }}>
                  {cupondata?.TandC ? parse(cupondata?.TandC) : ""}
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <NewFooter />
    </>
  );
}

export default Newproduct;
