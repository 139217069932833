import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function StarHotels() {
  const formdata = new FormData();
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const [show, setShow] = useState(false);
  const [editdata, seteditdata] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    seteditdata(item);
  };

  const [SpaVendor, setSpaVendor] = useState([]);
  const [SalonVendor, setSalonVendor] = useState([]);
  const [BCPVednor, setBCPVednor] = useState([]);
  const [type, settype] = useState([]);
  const [vendorId, setvendorId] = useState("");
  const [starhotelsarray, setstarhotelsarray] = useState([]);

  useEffect(() => {
    getallVendor();
    getstarhotels();
  }, []);

  const getallVendor = () => {
    axios
      .get("https://spazo.co.in/api/vendor/allVendors")
      .then(function (response) {
        console.log(response.data);
        setSpaVendor(
          response.data.allVendors?.filter(
            (item) => item?.type === "Spa" && item?.approved === "Approved"
          )
        );
        setSalonVendor(
          response.data.allVendors?.filter(
            (item) => item?.type === "Salon" && item?.approved === "Approved"
          )
        );
        setBCPVednor(
          response.data.allVendors?.filter(
            (item) =>
              item?.type === "Beauty Care Products" &&
              item?.approved === "Approved"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addstarhotels = async () => {
    if (!type) {
      notify2("Please select Type");
    } else if (!vendorId) {
      notify2("Please select vendor");
    } else {
      try {
        const config = {
          url: "/AddStarHotels",
          method: "post",
          baseURL: "https://spazo.co.in/api/admin",
          data: {
            type: type,
            vendorId: vendorId,
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            notify("Data Added successfully");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            notify1(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        notify1("Unable to add Details, Please try after some time");
      }
    }
  };

  const getstarhotels = () => {
    axios
      .get("https://spazo.co.in/api/admin/getStarHotels")
      .then(function (response) {
        setstarhotelsarray(response.data.starHotels);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deletestarhotels = async (data) => {
    try {
      const config = {
        url: "/deleteStarHotels/" + data?._id,
        method: "post",
        baseURL: "https://spazo.co.in/api/admin",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          notify("Data deleted successfully");
          getstarhotels();
        } else {
          notify1(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      notify1("Unable to delete Details, Please try after some time");
    }
  };

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: starhotelsarray.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "Image",
      text: "Image",
      formatter: (value, row) => (
        <img
          src={"https://spazo.co.in/Vendor/" + row.vendors[0]?.logo}
          width={100}
          height={80}
          onClick={() =>
            window.open("https://spazo.co.in/Vendor/" + row.vendors[0]?.logo)
          }
        />
      ),
    },
    {
      dataField: "type",
      text: "Type",
      formatter: (value, row) => <p>{row?.type ? row?.type : "-"}</p>,
    },
    {
      dataField: "vendors[0]?.businessName",
      text: "Name",
      formatter: (value, row) => (
        <p>
          {row?.vendors[0]?.businessName ? row?.vendors[0]?.businessName : "-"}
        </p>
      ),
    },
    {
      dataField: "vendors[0]?.email",
      text: "Email",
      formatter: (value, row) => (
        <p>{row?.vendors[0]?.email ? row?.vendors[0]?.email : "-"}</p>
      ),
    },
    {
      dataField: "vendors[0]?.phoneNumber",
      text: "Contact Number",
      formatter: (value, row) => (
        <p>
          {row?.vendors[0]?.phoneNumber ? row?.vendors[0]?.phoneNumber : "-"}
        </p>
      ),
    },

    {
      dataField: "",
      text: "Action",
      formatter: (value, row) => (
        <div
          style={{
            display: "flex",
            gap: "21px",
          }}
        >
          {/* <span className="dite">
            <FaRegEdit onClick={() => handleShow1(row)} />
          </span> */}
          <span className="dite">
            <RiDeleteBin2Fill onClick={() => deletestarhotels(row)} />
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="never-sspa">
        <section>
          <div className="spa-admin-0 mb-2">
            <div className="diff-admin px-3">
              <div className="admin-name-text">
                <h4>Star Hotels</h4>
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={30000}
            closeOnClick
            pauseOnHover
            draggable
          />
          <div
            style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}
          >
            <ToolkitProvider
              keyField="id"
              data={starhotelsarray}
              columns={columns}
              // rowClasses={rowClasses}
              search
              exportCSV
            >
              {(props) => (
                <div>
                  <SearchBar {...props.searchProps} />
                  {/* <ExportCSVButton
                    {...props.csvProps}
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                    }}
                  >
                    Download Excel
                  </ExportCSVButton> */}
                  <button
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                      border: "none",
                      padding: "6px",
                      borderRadius: "5px",
                      marginLeft: "467px",
                    }}
                    onClick={handleShow}
                  >
                    +Add
                  </button>
                  <hr></hr>
                  <br></br>
                  <div style={{ overflowX: "scroll", width: "100%" }}>
                    <BootstrapTable
                      striped
                      hover
                      condensed
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </section>
      </div>

      {/* modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Star Hotels</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-spa">
            <div className="differ-spa mb-2">
              <label>Type</label>
              <br />
              <select
                onChange={(e) => settype(e.target.value)}
                style={{ width: "80%", padding: "10px", borderRadius: "4px" }}
              >
                <option>Select</option>
                <option value="Spa">Spa</option>
                <option value="Salon">Salon</option>
                <option value="Beauty Care Products">
                  Beauty Care Products
                </option>
              </select>
            </div>

            <div className="differ-spa mb-2">
              <label>Vendor</label>
              <br />
              <select
                onChange={(e) => setvendorId(e.target.value)}
                style={{ width: "80%", padding: "10px", borderRadius: "4px" }}
              >
                <option>Select</option>
                {type === "Spa"
                  ? SpaVendor?.map((item) => (
                      <option value={item?._id}>{item?.businessName}</option>
                    ))
                  : type === "Salon"
                  ? SalonVendor?.map((item) => (
                      <option value={item?._id}>{item?.businessName}</option>
                    ))
                  : type === "Beauty Care Products"
                  ? BCPVednor?.map((item) => (
                      <option value={item?._id}>{item?.businessName}</option>
                    ))
                  : ""}
              </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addstarhotels}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default StarHotels;
