import React, { useEffect, useState } from "react";
import Vendorheader from "./Vendorheader";
import Vendorsidebar from "./Vendorsidebar";
import { Col, Row } from "react-bootstrap";
import parse from "html-react-parser";
import axios from "axios";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Vendorprofile() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const Input = styled("input")({
    display: "none",
  });

  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    alert("You have entered an invalid email address!");
    return false;
  }

  function validatename(inputtxt) {
    var phoneno = /^[a-zA-Z ]{2,30}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid name!");
      return false;
    }
  }

  function phonenumber(inputtxt) {
    var phoneno = /^[6-9]\d{9}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid mobile number!");
      return false;
    }
  }

  const [data, setdata] = useState([]);
  const getallVendor = () => {
    axios
      .get("https://spazo.co.in/api/vendor/allVendors")
      .then(function (response) {
        console.log(response.data);
        setdata(response.data.allVendors);
        sessionStorage.setItem(
          "user",
          JSON.stringify(
            response.data.allVendors.find((item) => item._id === user._id)
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    // Make an initial API call
    getallVendor();

    // Start the interval for subsequent API calls
    const intervalId = setInterval(getallVendor, 60000); // 60000ms = 1 minute

    return () => {
      // Clear the interval when the component unmounts
      clearInterval(intervalId);
    };
  }, []);

  const user = JSON.parse(sessionStorage.getItem("user"));
  const [Employee, setEmployee] = useState([]);
  const [editdata, seteditdata] = useState([]);
  const [edit, setedit] = useState(false);

  const newdata = editdata?.filter((item) => item.VendorID === user?._id);

  const [profile, setprofile] = useState();
  const [logo, setlogo] = useState();
  const [businessName, setbusinessName] = useState("");
  const [name, setname] = useState("");
  const [Email, setEmail] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [address, setaddress] = useState("");
  const [pincode, setpincode] = useState("");
  const [Glink, setGlink] = useState("");

  const [shoptype, setshoptype] = useState("");
  const [adharF1, setadharF1] = useState("");
  const [adharB1, setadharB1] = useState("");

  const [uploadPan, setuploadPan] = useState(false);
  const [uploadpassbook, setuploadpassbook] = useState(false);
  const [uploadgst, setuploadgst] = useState(false);
  const [uploadspa, setuploadspa] = useState(false);
  const [uploadanyother, setuploadanyother] = useState(false);

  const [Pan, setPan] = useState("");
  const [passbook, setpassbook] = useState("");
  const [gst, setgst] = useState("");
  const [spa, setspa] = useState("");
  const [other, setother] = useState("");
  const [starttime, setstarttime] = useState();
  const [endtime, setendtime] = useState();
  const [parking, setparking] = useState("");

  const [sun, setsun] = useState(user?.sun);
  const [mon, setmon] = useState(user?.mon);
  const [tue, settue] = useState(user?.tue);
  const [wed, setwed] = useState(user?.wed);
  const [turs, setturs] = useState(user?.turs);
  const [frid, setfrid] = useState(user?.frid);
  const [sat, setsat] = useState(user?.sat);
  const [workingdaysChange, setworkingdaysChange] = useState(false);

  const [image1, setimage1] = useState("");
  const [image2, setimage2] = useState("");
  const [image3, setimage3] = useState("");
  const [image4, setimage4] = useState("");

  const formatTimeTo12hr = (time) => {
    const [hours, minutes] = time?.split(":");
    const parsedHours = parseInt(hours, 10);

    // Determine AM or PM
    const period = parsedHours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    const displayHours = parsedHours % 12 || 12;

    // Combine the formatted time
    const formattedTime = `${displayHours}:${minutes} ${period}`;
    return formattedTime;
  };

  const handleEditorChange = (event, editor) => {
    // Get the updated data from CKEditor
    const data = editor.getData();
    setabout(data);
  };

  const formattedStartTime = starttime ? formatTimeTo12hr(starttime) : "";
  const formattedEndTime = endtime ? formatTimeTo12hr(endtime) : "";

  const [about, setabout] = useState("");

  const [array1, setarray1] = useState([]);
  const [Adhar, setAdhar] = useState([]);

  const addAdhar = () => {
    if (!adharF1 || !adharB1) {
      // alert("Please Upload Both the sides");
      notify1("Unable to add Details, Please try after some time");
    } else {
      let obj = {
        adharF1: adharF1,
        adharB1: adharB1,
      };
      array1.push(obj);
      setAdhar(array1);
      setadharF1("");
      setadharB1("");
    }
  };

  useEffect(() => {
    setAdhar([]);
  }, [shoptype]);

  const [AdditionalImage, setAdditionalImage] = useState();

  const [array11, setarray11] = useState("");
  const [AdditionalImageArray, setAdditionalImageArray] = useState([]);
  const [displayimage, setdisplayimage] = useState([]);

  const addAdditionalImage = () => {
    if (!AdditionalImage) {
      notify2("Please Upload Image");
    } else {
      let obj = {
        AdditionalImage: AdditionalImage,
      };
      // setarray11(obj);
      AdditionalImageArray.push(obj);
      setAdditionalImage("");
    }
  };

  // useEffect(() => {
  //   if (AdditionalImage) {
  //     const xyz = [];
  //     for (let i = 0; i < Object.keys(AdditionalImage).length; i++) {
  //       xyz.push({ AdditionalImage: AdditionalImage[i] });
  //     }

  //     setdisplayimage(xyz);
  //   }
  // }, [AdditionalImage]);

  useEffect(() => {
    getVendorEmployee();
    getprofileupdaterequest();
  }, []);

  const getVendorEmployee = () => {
    axios
      .get("https://spazo.co.in/api/Vendor/getVendorEmployee/" + user._id)
      .then(function (response) {
        console.log(response.data);
        setEmployee(response.data.Employee);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getprofileupdaterequest = () => {
    axios
      .get("https://spazo.co.in/api/Vendor/getprofileupdaterequest")
      .then(function (response) {
        console.log(response.data);
        seteditdata(response.data.profile);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const formdata = new FormData();
  const form2 = new FormData();
  const form3 = new FormData();

  console.log("AdditionalImage", AdditionalImage);
  console.log("logo", logo);

  const VendorProfileedit = async () => {
    if (!sun && !mon && !tue && !wed && !turs && !frid && !sat)
      // alert("Please select the working days");
      notify2("Please select the working days");
    else
      try {
        if (
          validatename(name ? name : user?.name) &&
          ValidateEmail(Email ? Email : user?.email)
          // && phonenumber(phoneNumber ? phoneNumber : user?.phoneNumber)
        ) {
          const config = {
            url: "/VendorProfileedit",
            method: "post",
            baseURL: "https://spazo.co.in/api/vendor",
            headers: { "content-type": "multipart/form-data" },
            data: {
              VendorID: user?._id,
              profile: profile ? profile : user?.profile,
              logo: logo ? logo : user?.logo,
              businessName: businessName ? businessName : user?.businessName,
              name: name ? name : user?.name,
              email: Email ? Email : user?.email,
              phoneNumber: phoneNumber ? phoneNumber : user?.phoneNumber,
              address: address[0]?.label.toString()
                ? address[0]?.label.toString()
                : user?.address,
              pincode: pincode ? pincode : user?.pincode,
              Glink: Glink ? Glink : user?.Glink,
              // shoptype: shoptype ? shoptype : user?.shoptype,
              Pan: Pan ? Pan : user?.Pan,
              passbook: passbook ? passbook : user?.passbook,
              gst: gst ? gst : user?.gst,
              spa: spa ? spa : user?.spa,
              other: other ? other : user?.other,
              sun: sun,
              mon: mon,
              tue: tue,
              wed: wed,
              turs: turs,
              frid: frid,
              sat: sat,
              formattedStartTime: formattedStartTime
                ? formattedStartTime
                : user?.formattedStartTime,
              formattedEndTime: formattedEndTime
                ? formattedEndTime
                : user?.formattedEndTime,
              parking: parking ? parking : user?.parking,
              about: about ? about : user?.about,
              // Adhar: Adhar?.length > 0 ? Adhar : user?.Adhar,
              spaimage1: image1 ? image1 : user?.spaimage1,
              spaimage2: image2 ? image2 : user?.spaimage2,
              spaimage3: image3 ? image3 : user?.spaimage3,
              spaimage4: image4 ? image4 : user?.spaimage4,
              // AdditionalImageArray: user?.AdditionalImageArray,

              businessNameChange: businessName ? true : false,
              nameChange: name ? true : false,
              emailChange: Email ? true : false,
              phoneNumberChange: phoneNumber ? true : false,
              addressChange: address ? true : false,
              pincodeChange: pincode ? true : false,
              GlinkChange: Glink ? true : false,
              PanChange: Pan ? true : false,
              passbookChange: passbook ? true : false,
              gstChange: gst ? true : false,
              spaChange: spa ? true : false,
              otherChange: other ? true : false,
              workingdaysChange: workingdaysChange,
              formattedStartTimeChange: formattedStartTime ? true : false,
              formattedEndTimeChange: formattedEndTime ? true : false,
              parkingChange: parking ? true : false,
              aboutChange: about ? true : false,
              profileChange: profile ? true : false,
              logoChange: logo ? true : false,
              spaimage1Change: image1 ? true : false,
              spaimage2Change: image2 ? true : false,
              spaimage3Change: image3 ? true : false,
              spaimage4Change: image4 ? true : false,
              AdditionalImageChange:
                AdditionalImageArray?.length > 0 ? true : false,
              type: user?.type,
            },
          };
          let res = await axios(config);
          if (res.status === 200) {
            // alert("Edit Request sent Succusfully");
            // window.location.reload();
            console.log("id", res.data.data, res.data.data._id);
            if (AdditionalImageArray?.length > 0) {
              editAdditionalImage(res.data.data._id);
            }
            notify("Edit Request sent Succusfully");
            getVendorEmployee();
            getprofileupdaterequest();
            setedit(!edit);
          }
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          // alert(error.response.data.error);
          notify1(error.response.data.error);
        }
      }
  };

  console.log("AdditionalImageArray", AdditionalImageArray);
  const editAdditionalImage = async (id) => {
    AdditionalImageArray.forEach(async (adharObj, index) => {
      alert("editimage");
      form3.append(`id`, id);
      form3.append(`AdditionalImage`, adharObj.AdditionalImage);
      const config = {
        url: "/editAdditionalImage",
        method: "post",
        baseURL: "https://spazo.co.in/api/vendor",
        data: form3,
      };
      await axios(config);
    });
  };

  const deleteAdditionalImage = async (UserID, ImageId) => {
    try {
      const config = {
        url: "/deleteAdditionalImage/" + UserID?._id + "/" + ImageId?._id,
        method: "delete",
        baseURL: "https://spazo.co.in/api/vendor",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          window.location.reload();
          console.log(res);
        } else {
          // alert(res.status.error);
          notify1(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      // alert("Unable to add Details");
      notify1("Unable to add Details, Please try after some time");
    }
  };

  // const VendorProfileedit = async () => {
  //   formdata.append("VendorID", user?._id);
  //   formdata.append("profile", profile ? profile : user?.profile);
  //   formdata.append(
  //     "businessName",
  //     businessName ? businessName : user?.businessName
  //   );
  //   formdata.append("name", name ? name : user?.name);
  //   formdata.append("email", Email ? Email : user?.email);
  //   formdata.append(
  //     "phoneNumber",
  //     phoneNumber ? phoneNumber : user?.phoneNumber
  //   );
  //   formdata.append(
  //     "address",
  //     address[0]?.label.toString()
  //       ? address[0]?.label.toString()
  //       : user?.address
  //   );
  //   formdata.append("pincode", pincode ? pincode : user?.pincode);
  //   formdata.append("Glink", Glink ? Glink : user?.Glink);
  //   formdata.append("shoptype", shoptype ? shoptype : user?.shoptype);
  //   formdata.append("Pan", Pan ? Pan : user?.Pan);
  //   formdata.append("passbook", passbook ? passbook : user?.passbook);
  //   formdata.append("gst", gst ? gst : user?.gst);
  //   formdata.append("spa", spa ? spa : user?.spa);
  //   formdata.append("other", other ? other : user?.other);
  //   formdata.append("sun", sun);
  //   formdata.append("mon", mon);
  //   formdata.append("tue", tue);
  //   formdata.append("wed", wed);
  //   formdata.append("turs", turs);
  //   formdata.append("frid", frid);
  //   formdata.append("sat", sat);
  //   formdata.append(
  //     "formattedStartTime",
  //     formattedStartTime ? formattedStartTime : user?.formattedStartTime
  //   );
  //   formdata.append(
  //     "formattedEndTime",
  //     formattedEndTime ? formattedEndTime : user?.formattedEndTime
  //   );
  //   formdata.append("parking", parking ? parking : user?.parking);
  //   formdata.append("about", about ? about : user?.about);
  //   Adhar?.length > 0
  //     ? Adhar.forEach(async (adharObj, index) => {
  //         form2.append(`id`, user._id);
  //         form2.append(`adharF1`, adharObj.adharF1);
  //         form2.append(`adharB1`, adharObj.adharB1);
  //         const config = {
  //           url: "/editAdharCard",
  //           method: "post",
  //           baseURL: "https://spazo.co.in/api/vendor",
  //           data: form2,
  //         };
  //         await axios(config);
  //       })
  //     : user?.Adhar.forEach(async (adharObj, index) => {
  //         form2.append(`id`, user._id);
  //         form2.append(`adharF1`, adharObj.adharF1);
  //         form2.append(`adharB1`, adharObj.adharB1);
  //         const config = {
  //           url: "/editAdharCard",
  //           method: "post",
  //           baseURL: "https://spazo.co.in/api/vendor",
  //           data: form2,
  //         };
  //         await axios(config);
  //       });
  //   formdata.append("spaimage1", image1 ? image1 : user?.spaimage1);
  //   formdata.append("spaimage2", image2 ? image2 : user?.spaimage2);
  //   formdata.append("spaimage3", image3 ? image3 : user?.spaimage3);
  //   formdata.append("spaimage4", image4 ? image4 : user?.spaimage4);
  //   AdditionalImageArray.forEach(async (adharObj, index) => {
  //     form3.append(`id`, user._id);
  //     form3.append(`AdditionalImage`, adharObj.AdditionalImage);
  //     const config = {
  //       url: "/editAdditionalImage",
  //       method: "post",
  //       baseURL: "https://spazo.co.in/api/vendor",
  //       data: form3,
  //     };
  //     await axios(config);
  //   });

  //   try {
  //     if (
  //       validatename(name ? name : user?.name) &&
  //       ValidateEmail(Email ? Email : user?.email)
  //     ) {
  //       const config = {
  //         url: "/VendorProfileedit",
  //         method: "post",
  //         baseURL: "https://spazo.co.in/api/vendor",
  //         headers: { "content-type": "multipart/form-data" },
  //         data: formdata,
  //       };
  //       let res = await axios(config);
  //       if (res.status === 200) {
  //         notify("Edit Request sent Succusfully");
  //         getVendorEmployee();
  //         getprofileupdaterequest();
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error.response);
  //     if (error.response) {
  //       notify1(error.response.data.error);
  //     }
  //   }
  // };

  return (
    <div className="never-sspa">
      <section>
        <div className="spa-admin-0 mb-1">
          <div className="diff-admin px-3">
            <div className="admin-name-text m-1 d-flex">
              <h4>Profile</h4>
              <ToastContainer
                position="top-center"
                autoClose={30000}
                closeOnClick
                pauseOnHover
                draggable
              />
              {newdata[newdata?.length - 1]?.Editapproved === "Pending" ? (
                <>
                  <p
                    style={{
                      backgroundColor: "Green",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "3px",
                      right: "5px",
                      position: "absolute",
                    }}
                  >
                    Edit Request sent successfully
                  </p>
                </>
              ) : newdata[newdata?.length - 1]?.Editapproved === "Rejected" ? (
                <>
                  <marquee
                    width="100%"
                    direction="left"
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      color: "rgb(226, 34, 23)",
                    }}
                  >
                    {/* <p
                    style={{
                      backgroundColor: "rgb(226, 34, 23)",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "3px",
                    
                    }}
                  > */}
                    Edit Request rejected due to :{" "}
                    {newdata[newdata?.length - 1]?.reasonFR}
                    {/* </p>  */}
                  </marquee>
                  {!edit ? (
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "rgb(226, 34, 23)",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "3px",
                        right: "5px",
                        position: "absolute",
                      }}
                      onClick={() => setedit(!edit)}
                    >
                      Edit
                    </button>
                  ) : (
                    ""
                  )}
                </>
              ) : !edit ? (
                <>
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "rgb(226, 34, 23)",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "3px",
                      right: "5px",
                      position: "absolute",
                    }}
                    onClick={() => setedit(!edit)}
                  >
                    Edit
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        {!edit ? (
          <Row>
            <Col md={8}>
              <div
                style={{
                  display: "grid",
                  alignItems: "center",
                  borderBottom: "1px solid gray",
                  padding: "10px",
                  margin: "10px",
                }}
              >
                <img
                  src={"https://spazo.co.in/Vendor/" + user?.logo}
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    alignSelf: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  onClick={() =>
                    window.open("https://spazo.co.in/Vendor/" + user?.logo)
                  }
                />
                <h5 style={{ textAlign: "center" }}>{user.businessName}</h5>
                <p style={{ textAlign: "center", marginBottom: "0px" }}>
                  {user.email}
                </p>
                <p style={{ textAlign: "center", marginBottom: "0px" }}>
                  {user.address}-{user.pincode}
                </p>
                <a href={user.Glink} style={{ textAlign: "center" }}>
                  {user.Glink}
                </a>
              </div>
              <div style={{ margin: "10px" }}>
                <p style={{ textAlign: "justify" }}>{parse(user?.about)}</p>
              </div>
              <div style={{ margin: "10px" }}>
                <p
                  style={{
                    color: "rgb(226, 34, 23)",
                    fontWeight: "bold",
                  }}
                >
                  Documents
                </p>
                <p
                  style={{
                    // color: "rgb(226, 34, 23)",
                    marginBottom: "0px",
                    fontWeight: "bold",
                  }}
                >
                  Aadhaar Card's
                </p>
                {user?.Adhar?.map((item) => (
                  <>
                    <img
                      src={"https://spazo.co.in/Vendor/" + item?.adharF1}
                      style={{
                        width: "100px",
                        height: "100px",
                        margin: "10px",
                        marginLeft: "0px",
                      }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + item?.adharF1
                        )
                      }
                    />
                    <img
                      src={"https://spazo.co.in/Vendor/" + item?.adharB1}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + item?.adharB1
                        )
                      }
                    />
                  </>
                ))}
              </div>
              <div style={{ margin: "10px" }}>
                <Row>
                  <Col md={2}>
                    <p
                      style={{
                        // color: "rgb(226, 34, 23)",
                        textAlign: "center",
                        marginBottom: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      Pan Card
                    </p>

                    <img
                      src={"https://spazo.co.in/Vendor/" + user?.Pan}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() =>
                        window.open("https://spazo.co.in/Vendor/" + user?.Pan)
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <p
                      style={{
                        // color: "rgb(226, 34, 23)",
                        textAlign: "center",
                        marginBottom: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      PassBook
                    </p>

                    <img
                      src={"https://spazo.co.in/Vendor/" + user?.passbook}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + user?.passbook
                        )
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <p
                      style={{
                        // color: "rgb(226, 34, 23)",
                        textAlign: "center",
                        marginBottom: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      GST
                    </p>

                    <img
                      src={"https://spazo.co.in/Vendor/" + user?.gst}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() =>
                        window.open("https://spazo.co.in/Vendor/" + user?.gst)
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <p
                      style={{
                        // color: "rgb(226, 34, 23)",
                        textAlign: "center",
                        marginBottom: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      {user?.type}
                    </p>

                    <img
                      src={"https://spazo.co.in/Vendor/" + user?.spa}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() =>
                        window.open("https://spazo.co.in/Vendor/" + user?.spa)
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <p
                      style={{
                        // color: "rgb(226, 34, 23)",
                        marginBottom: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      Other Certificate
                    </p>

                    <img
                      src={"https://spazo.co.in/Vendor/" + user?.Pan}
                      style={{ width: "100px", height: "100px" }}
                      onClick={() =>
                        window.open("https://spazo.co.in/Vendor/" + user?.Pan)
                      }
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={4}>
              <div
                style={{
                  display: "grid",
                  alignItems: "center",
                  borderBottom: "1px solid gray",
                  margin: "10px",
                }}
              >
                <img
                  src={"https://spazo.co.in/Vendor/" + user?.profile}
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    alignSelf: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "10px",
                  }}
                  onClick={() =>
                    window.open("https://spazo.co.in/Vendor/" + user?.profile)
                  }
                />
                <h5 style={{ textAlign: "center" }}>{user.name}</h5>
                <p style={{ textAlign: "center" }}>{user.phoneNumber}</p>
              </div>
              <div
                style={{
                  margin: "10px",
                  borderBottom: "1px solid gray",
                  margin: "10px",
                  padding: "10px",
                }}
              >
                <Row>
                  <Col md={6}>Shop category :</Col>
                  <Col md={6}>{user?.type}</Col>
                </Row>
                <Row>
                  <Col md={6}>{user?.type} Type :</Col>
                  <Col md={6}>{user?.shoptype}</Col>
                </Row>
                <Row>
                  <Col md={6}>Working Time :</Col>
                  <Col md={6}>
                    {user.formattedStartTime} - {user.formattedEndTime}
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>Working Days :</Col>
                  <Col md={6}>
                    <p style={{ marginBottom: "0px" }}>
                      {user.sun ? "Sunday" : ""}
                    </p>
                    <p style={{ marginBottom: "0px" }}>
                      {user.mon ? "Monday" : ""}
                    </p>
                    <p style={{ marginBottom: "0px" }}>
                      {user.tue ? "Tuesday" : ""}
                    </p>
                    <p style={{ marginBottom: "0px" }}>
                      {user.wed ? "Wednesday" : ""}
                    </p>
                    <p style={{ marginBottom: "0px" }}>
                      {user.turs ? "Thursday" : ""}
                    </p>
                    <p style={{ marginBottom: "0px" }}>
                      {user.frid ? "Friday" : ""}
                    </p>
                    <p style={{ marginBottom: "0px" }}>
                      {user.sat ? "Saturday" : ""}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>Parking :</Col>
                  <Col md={6}>
                    {user.parking === "2-Wheeler" ? (
                      <i class="fas fa-motorcycle"></i>
                    ) : user.parking === "4-Wheeler" ? (
                      <i class="fas fa-car"></i>
                    ) : user.parking === "Both" ? (
                      <>
                        <i
                          class="fas fa-car"
                          style={{ marginRight: "10px" }}
                        ></i>
                        <i class="fas fa-motorcycle"></i>
                      </>
                    ) : (
                      "No Parking"
                    )}
                  </Col>
                </Row>
              </div>
              <div style={{ display: "grid", alignItems: "center" }}>
                <p
                  style={{
                    color: "rgb(226, 34, 23)",
                    fontWeight: "bold",
                  }}
                  className="mt-1"
                >
                  Gallery
                </p>
                <Row>
                  <Col md={6}>
                    <p
                      style={{
                        marginBottom: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      Road side View
                    </p>
                    <img
                      src={"https://spazo.co.in/Vendor/" + user?.spaimage1}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                      }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + user?.spaimage1
                        )
                      }
                    />
                  </Col>
                  <Col md={6}>
                    <p
                      style={{
                        marginBottom: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      Lobby View
                    </p>
                    <img
                      src={"https://spazo.co.in/Vendor/" + user?.spaimage2}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                      }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + user?.spaimage2
                        )
                      }
                    />
                  </Col>
                  <Col md={6}>
                    <p
                      style={{
                        marginBottom: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      Corner View
                    </p>
                    <img
                      src={"https://spazo.co.in/Vendor/" + user?.spaimage3}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                      }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + user?.spaimage3
                        )
                      }
                    />
                  </Col>
                  <Col md={6}>
                    <p
                      style={{
                        marginBottom: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      Therapy Room View
                    </p>
                    <img
                      src={"https://spazo.co.in/Vendor/" + user?.spaimage4}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                      }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + user?.spaimage4
                        )
                      }
                    />
                  </Col>
                </Row>
              </div>
              <div style={{ margin: "10px" }}>
                <p
                  style={{
                    marginBottom: "0px",
                    fontWeight: "bold",
                  }}
                >
                  Additional Image's
                </p>
                <Row>
                  {user?.AdditionalImageArray?.map((item) => (
                    <Col md={2} className="mt-1">
                      <img
                        src={
                          "https://spazo.co.in/Vendor/" + item?.AdditionalImage
                        }
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                        }}
                        onClick={() =>
                          window.open(
                            "https://spazo.co.in/Vendor/" +
                              item?.AdditionalImage
                          )
                        }
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        ) : (
          <div
            style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}
          >
            <Row>
              <Col md={6}>
                {" "}
                <Row className="mt-2">
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Shop-Name
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    <input
                      placeholder={user.businessName}
                      onChange={(e) => setbusinessName(e.target.value)}
                      style={{ width: "95%" }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Owner-Name
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    <input
                      placeholder={user.name}
                      onChange={(e) => setname(e.target.value)}
                      style={{ width: "95%" }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2 me-0">
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Address
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    <div
                      style={{
                        border: "1px solid gray",
                        width: "97%",
                        marginLeft: "4px",
                      }}
                    >
                      <GooglePlacesAutocomplete
                        apiKey="AIzaSyAeVFaPIlv5BTjUqMrBIVRfsM_SvDPegHg"
                        selectProps={{
                          isMulti: true,
                          placeholder: user?.address,
                          isClearable: true,
                          value: address,
                          onChange: (val) => {
                            setaddress(val);
                          },
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Location
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    <input
                      placeholder={user.Glink}
                      onChange={(e) => setGlink(e.target.value)}
                      style={{ width: "95%" }}
                    />
                  </Col>
                </Row>
              </Col>

              <Col md={6}>
                <Row className="mt-2">
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Email-Id
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    <input
                      placeholder={user.email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ width: "95%" }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Contact No.
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    <input
                      maxLength={10}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder={user.phoneNumber}
                      onChange={(e) => setphoneNumber(e.target.value)}
                      style={{ width: "95%" }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Pincode
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    <input
                      placeholder={user.pincode}
                      maxLength={6}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => setpincode(e.target.value)}
                      style={{ width: "95%" }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Parking
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    <select
                      onChange={(e) => setparking(e.target.value)}
                      className="upselect"
                      style={{ width: "95%" }}
                    >
                      <option>{user.parking}</option>
                      <option value="2-Wheeler">2-Wheeler</option>
                      <option value="4-Wheeler">4-Wheeler</option>
                      <option value="Both">Both</option>
                      <option value="NO Parking">No Parking</option>
                    </select>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col
                md={2}
                style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
              >
                working days
              </Col>
              <Col md={8} style={{ fontWeight: "500" }}>
                <div style={{ display: "flex" }}>
                  <input
                    type="checkbox"
                    id="Sunday"
                    checked={sun}
                    onChange={(e) => {
                      setsun(!sun);
                      setworkingdaysChange(true);
                    }}
                  />{" "}
                  &nbsp;
                  <label for="Sunday">Sunday</label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    id="monday"
                    checked={mon}
                    onChange={(e) => {
                      setmon(!mon);
                      setworkingdaysChange(true);
                    }}
                  />{" "}
                  &nbsp;
                  <label for="monday">Monday</label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    id="Tuesday"
                    checked={tue}
                    onChange={(e) => {
                      settue(!tue);
                      setworkingdaysChange(true);
                    }}
                  />{" "}
                  &nbsp;
                  <label for="Tuesday">Tuesday</label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    id="Wednesday"
                    checked={wed}
                    onChange={(e) => {
                      setwed(!wed);
                      setworkingdaysChange(true);
                    }}
                  />{" "}
                  &nbsp;
                  <label for="Wednesday">Wednesday</label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    id="Thursday"
                    checked={turs}
                    onChange={(e) => {
                      setturs(!turs);
                      setworkingdaysChange(true);
                    }}
                  />{" "}
                  &nbsp;
                  <label for="Thursday">Thursday</label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    id="Friday"
                    checked={frid}
                    onChange={(e) => {
                      setfrid(!frid);
                      setworkingdaysChange(true);
                    }}
                  />{" "}
                  &nbsp;
                  <label for="Friday">Friday</label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    id="Saturday"
                    checked={sat}
                    onChange={(e) => {
                      setsat(!sat);
                      setworkingdaysChange(true);
                    }}
                  />{" "}
                  &nbsp;
                  <label for="Saturday">Saturday </label>
                  &nbsp;&nbsp;&nbsp;
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col
                md={2}
                style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
              >
                working Time
              </Col>
              <Col md={8} style={{ fontWeight: "500" }}>
                <Row>
                  <Col md={6}>
                    {" "}
                    <Row>
                      <Col md={4}>Start Time</Col>
                      <Col md={8}>
                        <input
                          type="time"
                          className="upbtn"
                          onChange={(e) => setstarttime(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={4}>End Time</Col>
                      <Col md={8}>
                        <input
                          type="time"
                          className="upbtn"
                          onChange={(e) => setendtime(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={6}>
                {" "}
                <Row>
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    {user.type} Type
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    <select
                      onChange={(e) => setshoptype(e.target.value)}
                      style={{ width: "75%" }}
                    >
                      <option>{user.shoptype}</option>
                      {/* <option value="Pvt Ltd">Pvt Ltd</option>
                      <option value="Partner Ship">Partner Ship</option>
                      <option value="Properiter Ship">Properiter Ship</option> */}
                    </select>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>{""}</Col>
            </Row>
            {shoptype === "Pvt Ltd" ? (
              <>
                <div>
                  <h5 style={{ color: "white" }}>
                    Upload your Aadhaar card (Min 2 pair)
                  </h5>
                  <div
                  // className="adhharr"
                  // style={{ justifyContent: "space-between" }}
                  >
                    {Adhar?.map((item) => (
                      <>
                        <img
                          src={URL.createObjectURL(item.adharF1)}
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <img
                          src={URL.createObjectURL(item.adharB1)}
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                            marginRight: "20px",
                          }}
                        />
                      </>
                    ))}
                    <br></br>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <div>
                        <label htmlFor="icon-button-file1">
                          <Input
                            accept="image/*"
                            id="icon-button-file1"
                            type="file"
                            onChange={(e) => setadharF1(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                adharF1
                                  ? URL.createObjectURL(adharF1)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                                //  `https://otutee.in/${

                                //   }`
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>AdharFront1</p>
                      </div>
                      <div>
                        <label htmlFor="icon-button-file2">
                          <Input
                            accept="image/*"
                            id="icon-button-file2"
                            type="file"
                            onChange={(e) => setadharB1(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                adharB1
                                  ? URL.createObjectURL(adharB1)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>AdharBack1</p>
                      </div>
                    </div>

                    <button
                      style={{
                        border: "none",
                        padding: "8px",
                        height: "30px",
                        backgroundColor: "#e22217",
                        color: "white",
                        marginTop: "10px",
                        marginLeft: "30px",
                      }}
                      onClick={addAdhar}
                    >
                      Add
                    </button>
                    {/* <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <label htmlFor="icon-button-file3">
                        <Input
                          accept="image/*"
                          id="icon-button-file3"
                          type="file"
                          onChange={(e) => setadharF2(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharF2
                                ? URL.createObjectURL(adharF2)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharFront2</p>
                    </div>
                    <div>
                      <label htmlFor="icon-button-file4">
                        <Input
                          accept="image/*"
                          id="icon-button-file4"
                          type="file"
                          onChange={(e) => setadharB2(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharB2
                                ? URL.createObjectURL(adharB2)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharBack2</p>
                    </div>
                  </div>

                  <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <label htmlFor="icon-button-file5">
                        <Input
                          accept="image/*"
                          id="icon-button-file5"
                          type="file"
                          onChange={(e) => setadharF3(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharF3
                                ? URL.createObjectURL(adharF3)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharFront3</p>
                    </div>
                    <div>
                      <label htmlFor="icon-button-file6">
                        <Input
                          accept="image/*"
                          id="icon-button-file6"
                          type="file"
                          onChange={(e) => setadharB3(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharB3
                                ? URL.createObjectURL(adharB3)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharBack3</p>
                    </div>
                  </div> */}

                    {/* <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <label htmlFor="icon-button-file7">
                        <Input
                          accept="image/*"
                          id="icon-button-file7"
                          type="file"
                          onChange={(e) => setadharF4(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharF4
                                ? URL.createObjectURL(adharF4)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharFront4</p>
                    </div>
                    <div>
                      <label htmlFor="icon-button-file8">
                        <Input
                          accept="image/*"
                          id="icon-button-file8"
                          type="file"
                          onChange={(e) => setadharB4(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharB4
                                ? URL.createObjectURL(adharB4)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharBack4</p>
                    </div>
                  </div> */}
                  </div>
                </div>
              </>
            ) : shoptype === "Partner Ship" ? (
              <>
                {/* <div>
                <h6>Upload your Adhar card</h6>
                <div
                  className="adhharr"
                  style={{ justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <label htmlFor="icon-button-file1">
                        <Input
                          accept="image/*"
                          id="icon-button-file1"
                          type="file"
                          onChange={(e) => setadharF1(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharF1
                                ? URL.createObjectURL(adharF1)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharFront1</p>
                    </div>
                    <div>
                      <label htmlFor="icon-button-file2">
                        <Input
                          accept="image/*"
                          id="icon-button-file2"
                          type="file"
                          onChange={(e) => setadharB1(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharB1
                                ? URL.createObjectURL(adharB1)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharBack1</p>
                    </div>
                  </div>

                  <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <label htmlFor="icon-button-file3">
                        <Input
                          accept="image/*"
                          id="icon-button-file3"
                          type="file"
                          onChange={(e) => setadharF2(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharF2
                                ? URL.createObjectURL(adharF2)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharFront2</p>
                    </div>
                    <div>
                      <label htmlFor="icon-button-file4">
                        <Input
                          accept="image/*"
                          id="icon-button-file4"
                          type="file"
                          onChange={(e) => setadharB2(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharB2
                                ? URL.createObjectURL(adharB2)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharBack2</p>
                    </div>
                  </div>

                  <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <label htmlFor="icon-button-file5">
                        <Input
                          accept="image/*"
                          id="icon-button-file5"
                          type="file"
                          onChange={(e) => setadharF3(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharF3
                                ? URL.createObjectURL(adharF3)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharFront3</p>
                    </div>
                    <div>
                      <label htmlFor="icon-button-file6">
                        <Input
                          accept="image/*"
                          id="icon-button-file6"
                          type="file"
                          onChange={(e) => setadharB3(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharB3
                                ? URL.createObjectURL(adharB3)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharBack3</p>
                    </div>
                  </div>
                </div>
              </div> */}
                <div>
                  <h5 style={{ color: "white" }}>
                    Upload your Aadhaar card (Min 2 pair)
                  </h5>
                  <div
                  // className="adhharr"
                  // style={{ justifyContent: "space-between" }}
                  >
                    {Adhar?.map((item) => (
                      <>
                        <img
                          src={URL.createObjectURL(item.adharF1)}
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <img
                          src={URL.createObjectURL(item.adharB1)}
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                            marginRight: "20px",
                          }}
                        />
                      </>
                    ))}
                    <br></br>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <div>
                        <label htmlFor="icon-button-file1">
                          <Input
                            accept="image/*"
                            id="icon-button-file1"
                            type="file"
                            onChange={(e) => setadharF1(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                adharF1
                                  ? URL.createObjectURL(adharF1)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                                //  `https://otutee.in/${

                                //   }`
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>AdharFront1</p>
                      </div>
                      <div>
                        <label htmlFor="icon-button-file2">
                          <Input
                            accept="image/*"
                            id="icon-button-file2"
                            type="file"
                            onChange={(e) => setadharB1(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                adharB1
                                  ? URL.createObjectURL(adharB1)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>AdharBack1</p>
                      </div>
                    </div>

                    <button
                      style={{
                        border: "none",
                        padding: "8px",
                        height: "30px",
                        backgroundColor: "#e22217",
                        color: "white",
                        marginTop: "50px",
                        marginLeft: "30px",
                      }}
                      onClick={addAdhar}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </>
            ) : shoptype === "Properiter Ship" ? (
              <>
                {/* <div>
                <h6>Upload your Adhar card</h6>
                <div className="adhharr">
                  <div style={{ display: "flex", gap: "10px", margin: "20px" }}>
                    <div>
                      <label htmlFor="icon-button-file1">
                        <Input
                          accept="image/*"
                          id="icon-button-file1"
                          type="file"
                          onChange={(e) => setadharF1(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharF1
                                ? URL.createObjectURL(adharF1)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharFront1</p>
                    </div>
                    <div>
                      <label htmlFor="icon-button-file2">
                        <Input
                          accept="image/*"
                          id="icon-button-file2"
                          type="file"
                          onChange={(e) => setadharB1(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharB1
                                ? URL.createObjectURL(adharB1)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharBack1</p>
                    </div>
                  </div>

                  <div style={{ display: "flex", gap: "10px", margin: "20px" }}>
                    <div>
                      <label htmlFor="icon-button-file3">
                        <Input
                          accept="image/*"
                          id="icon-button-file3"
                          type="file"
                          onChange={(e) => setadharF2(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharF2
                                ? URL.createObjectURL(adharF2)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharFront2</p>
                    </div>
                    <div>
                      <label htmlFor="icon-button-file4">
                        <Input
                          accept="image/*"
                          id="icon-button-file4"
                          type="file"
                          onChange={(e) => setadharB2(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <img
                            src={
                              adharB2
                                ? URL.createObjectURL(adharB2)
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                          />
                          <PhotoCamera
                            style={{
                              color: "black",
                              margin: "40px 0px 0px -20px",
                            }}
                          />
                        </IconButton>
                      </label>
                      <p>AdharBack2</p>
                    </div>
                  </div>
                </div>
              </div> */}
                <div>
                  <h5 style={{ color: "white" }}>
                    Upload your Aadhaar card (Min 1 pair)
                  </h5>
                  <div
                  // className="adhharr"
                  // style={{ justifyContent: "space-between" }}
                  >
                    {Adhar?.map((item) => (
                      <>
                        <img
                          src={URL.createObjectURL(item.adharF1)}
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <img
                          src={URL.createObjectURL(item.adharB1)}
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                            marginRight: "20px",
                          }}
                        />
                      </>
                    ))}
                    <br></br>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <div>
                        <label htmlFor="icon-button-file1">
                          <Input
                            accept="image/*"
                            id="icon-button-file1"
                            type="file"
                            onChange={(e) => setadharF1(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                adharF1
                                  ? URL.createObjectURL(adharF1)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                                //  `https://otutee.in/${

                                //   }`
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>AdharFront1</p>
                      </div>
                      <div>
                        <label htmlFor="icon-button-file2">
                          <Input
                            accept="image/*"
                            id="icon-button-file2"
                            type="file"
                            onChange={(e) => setadharB1(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                adharB1
                                  ? URL.createObjectURL(adharB1)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>AdharBack1</p>
                      </div>
                    </div>

                    <button
                      style={{
                        border: "none",
                        padding: "8px",
                        height: "30px",
                        backgroundColor: "#e22217",
                        color: "white",
                        marginTop: "50px",
                        marginLeft: "30px",
                      }}
                      onClick={addAdhar}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            <Row>
              <Col md={6}>
                <Row className="mt-3">
                  <Col md={4}>Upload Pan Card</Col>
                  <Col md={8}>
                    {uploadPan ? (
                      <div>
                        <div className="adhharr">
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              margin: "20px",
                            }}
                          >
                            <div>
                              <label htmlFor="pan">
                                <Input
                                  accept="image/*"
                                  id="pan"
                                  type="file"
                                  onChange={(e) => setPan(e.target.files[0])}
                                />
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <img
                                    src={
                                      Pan
                                        ? URL.createObjectURL(Pan)
                                        : "https://spazo.co.in/Vendor/" +
                                          user?.Pan
                                    }
                                    alt="avathar"
                                    style={{
                                      width: "80px",
                                      height: "80px",
                                      borderRadius: "50px",
                                    }}
                                  />
                                  <PhotoCamera
                                    style={{
                                      color: "black",
                                      margin: "40px 0px 0px -20px",
                                    }}
                                  />
                                </IconButton>
                              </label>
                              <p>PanCard</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <button
                        className="upbtn"
                        onClick={() => setuploadPan(true)}
                      >
                        Upload Pan
                      </button>
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>Upload Bank Passbook</Col>
                  <Col md={8}>
                    {uploadpassbook ? (
                      <div>
                        <div className="adhharr">
                          <div>
                            <label htmlFor="passbook">
                              <Input
                                accept="image/*"
                                id="passbook"
                                type="file"
                                onChange={(e) => setpassbook(e.target.files[0])}
                              />
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              >
                                <img
                                  src={
                                    passbook
                                      ? URL.createObjectURL(passbook)
                                      : "https://spazo.co.in/Vendor/" +
                                        user?.passbook
                                  }
                                  alt="avathar"
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    borderRadius: "50px",
                                  }}
                                />
                                <PhotoCamera
                                  style={{
                                    color: "black",
                                    margin: "40px 0px 0px -20px",
                                  }}
                                />
                              </IconButton>
                            </label>
                            <p>Bank Passbook</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <button
                        className="upbtn"
                        onClick={() => setuploadpassbook(true)}
                      >
                        Upload Bank Passbook
                      </button>
                    )}
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={4}>Upload GST Certificate</Col>
                  <Col md={8}>
                    {uploadgst ? (
                      <div>
                        <div className="adhharr">
                          <div>
                            <label htmlFor="gstc">
                              <Input
                                accept="image/*"
                                id="gstc"
                                type="file"
                                onChange={(e) => setgst(e.target.files[0])}
                              />
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              >
                                <img
                                  src={
                                    gst
                                      ? URL.createObjectURL(gst)
                                      : "https://spazo.co.in/Vendor/" +
                                        user?.gst
                                  }
                                  alt="avathar"
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    borderRadius: "50px",
                                  }}
                                />
                                <PhotoCamera
                                  style={{
                                    color: "black",
                                    margin: "40px 0px 0px -20px",
                                  }}
                                />
                              </IconButton>
                            </label>
                            <p>GST Certificate</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <button
                        className="upbtn"
                        onClick={() => setuploadgst(true)}
                      >
                        Upload GST Certificate
                      </button>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                {" "}
                <Row className="mt-3">
                  <Col md={4}>Upload {user?.type} Certificate</Col>
                  <Col md={8}>
                    {uploadspa ? (
                      <div>
                        <div className="adhharr">
                          <div>
                            <label htmlFor="spac">
                              <Input
                                accept="image/*"
                                id="spac"
                                type="file"
                                onChange={(e) => setspa(e.target.files[0])}
                              />
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              >
                                <img
                                  src={
                                    spa
                                      ? URL.createObjectURL(spa)
                                      : "https://spazo.co.in/Vendor/" +
                                        user?.spa
                                  }
                                  alt="avathar"
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    borderRadius: "50px",
                                  }}
                                />
                                <PhotoCamera
                                  style={{
                                    color: "black",
                                    margin: "40px 0px 0px -20px",
                                  }}
                                />
                              </IconButton>
                            </label>
                            <p>Spa Certificate</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <button
                        className="upbtn"
                        onClick={() => setuploadspa(true)}
                      >
                        Upload Spa Certificate
                      </button>
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>Upload if any other Certificate</Col>
                  <Col md={8}>
                    {uploadanyother ? (
                      <div>
                        <div className="adhharr">
                          <div>
                            <label htmlFor="other">
                              <Input
                                accept="image/*"
                                id="other"
                                type="file"
                                onChange={(e) => setother(e.target.files[0])}
                              />
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              >
                                <img
                                  src={
                                    other
                                      ? URL.createObjectURL(other)
                                      : "https://spazo.co.in/Vendor/" +
                                        user?.other
                                  }
                                  alt="avathar"
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    borderRadius: "50px",
                                  }}
                                />
                                <PhotoCamera
                                  style={{
                                    color: "black",
                                    margin: "40px 0px 0px -20px",
                                  }}
                                />
                              </IconButton>
                            </label>
                            <p>Certificate</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <button
                        className="upbtn"
                        onClick={() => setuploadanyother(true)}
                      >
                        Upload if any other Certificate
                      </button>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-4">
              <h6>Upload {user?.type} Images</h6>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <label htmlFor="image1">
                    <Input
                      accept="image/*"
                      id="image1"
                      type="file"
                      onChange={(e) => setimage1(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          image1
                            ? URL.createObjectURL(image1)
                            : "https://spazo.co.in/Vendor/" + user?.spaimage1
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                  <p style={{ textAlign: "center" }}>Road side View</p>
                </div>
                <div>
                  <label htmlFor="image2">
                    <Input
                      accept="image/*"
                      id="image2"
                      type="file"
                      onChange={(e) => setimage2(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          image2
                            ? URL.createObjectURL(image2)
                            : "https://spazo.co.in/Vendor/" + user?.spaimage2
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                  <p style={{ textAlign: "center" }}>
                    Lobby Reception Desk View
                  </p>
                </div>
                <div>
                  <label htmlFor="image3">
                    <Input
                      accept="image/*"
                      id="image3"
                      type="file"
                      onChange={(e) => setimage3(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          image3
                            ? URL.createObjectURL(image3)
                            : "https://spazo.co.in/Vendor/" + user?.spaimage3
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                  <p style={{ textAlign: "center" }}>Corner View</p>
                </div>
                <div>
                  <label htmlFor="image4">
                    <Input
                      accept="image/*"
                      id="image4"
                      type="file"
                      onChange={(e) => setimage4(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          image4
                            ? URL.createObjectURL(image4)
                            : "https://spazo.co.in/Vendor/" + user?.spaimage4
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                  <p style={{ textAlign: "center" }}>Therapy Room View</p>
                </div>
              </div>
            </Row>
            <div>
              <h6>{user?.type} Other Images</h6>
              <Row className="mt-2">
                {user?.AdditionalImageArray?.length > 0 ? (
                  <>
                    {user?.AdditionalImageArray?.map((item) => (
                      <Col md={3}>
                        <img
                          src={
                            "https://spazo.co.in/Vendor/" +
                            item?.AdditionalImage
                          }
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50%",
                          }}
                          onClick={() =>
                            window.open(
                              "https://spazo.co.in/Vendor/" +
                                item?.AdditionalImage
                            )
                          }
                        />
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            position: "absolute",
                            marginLeft: "-20px",
                          }}
                          onClick={() => deleteAdditionalImage(user, item)}
                        >
                          <i
                            class="fas fa-times-circle"
                            size={30}
                            style={{ color: "#e22217" }}
                          ></i>
                        </button>
                      </Col>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </Row>

              <div>
                {AdditionalImageArray?.map((item) => (
                  <>
                    <img
                      src={URL.createObjectURL(item.AdditionalImage)}
                      alt="avathar"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50px",
                      }}
                    />
                  </>
                ))}
                <br></br>
                <div style={{ display: "flex", gap: "10px" }}>
                  <div>
                    <label htmlFor="aditionalimage">
                      <Input
                        accept="image/*"
                        id="aditionalimage"
                        type="file"
                        // multiple
                        onChange={(e) => setAdditionalImage(e.target.files[0])}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <img
                          src={
                            AdditionalImage
                              ? URL.createObjectURL(AdditionalImage)
                              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQueVhIdH7EKL6sLlHuCK4etHw1nctOIza_Zg&usqp=CAU"
                          }
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                            alignSelf: "center",
                          }}
                        />
                        <PhotoCamera
                          style={{
                            color: "black",
                            margin: "40px 0px 0px -20px",
                          }}
                        />
                      </IconButton>
                    </label>
                    <p>Add Other Images</p>
                  </div>
                </div>

                <button
                  style={{
                    border: "none",
                    padding: "0px 18px 8px 18px",
                    backgroundColor: "#e22217",
                    color: "white",
                    marginLeft: "30px",
                    borderRadius: "50%",
                    fontSize: "30px",
                    textAlign: "center",
                  }}
                  onClick={addAdditionalImage}
                >
                  +
                </button>
              </div>
            </div>
            <Row className="mt-4">
              <Col md={6}>
                {" "}
                <h6>About Your {user?.type}</h6>
                <CKEditor
                  editor={ClassicEditor}
                  data={about}
                  onChange={handleEditorChange}
                />
              </Col>
              <Col md={6}>
                <div style={{ display: "flex", gap: "20px" }}>
                  <div>
                    <label htmlFor="image5">
                      <Input
                        accept="image/*"
                        id="image5"
                        type="file"
                        onChange={(e) => setprofile(e.target.files[0])}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <img
                          src={
                            profile
                              ? URL.createObjectURL(profile)
                              : "https://spazo.co.in/Vendor/" + user?.profile
                          }
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <PhotoCamera
                          style={{
                            color: "black",
                            margin: "40px 0px 0px -20px",
                          }}
                        />
                      </IconButton>
                    </label>
                    <p>Owner Profile</p>
                  </div>
                  <div>
                    <label htmlFor="logo">
                      <Input
                        accept="image/*"
                        id="logo"
                        type="file"
                        onChange={(e) => setlogo(e.target.files[0])}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <img
                          src={
                            logo
                              ? URL.createObjectURL(logo)
                              : "https://spazo.co.in/Vendor/" + user?.logo
                          }
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <PhotoCamera
                          style={{
                            color: "black",
                            margin: "40px 0px 0px -20px",
                          }}
                        />
                      </IconButton>
                    </label>
                    <p>Business Logo</p>
                  </div>
                </div>
              </Col>
            </Row>

            <button
              style={{
                border: "none",
                right: "10px",
                color: "white",
                backgroundColor: "rgb(226, 34, 23)",
                position: "absolute",
                padding: "2px 10px",
                borderRadius: "3px",
              }}
              onClick={VendorProfileedit}
            >
              Update
            </button>
          </div>
        )}

        {/* {!edit ? (
          <div
            style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}
          >
            <Row style={{ justifyContent: "space-between" }}>
              <Col md={6} className="profile-col">
                <Row>
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Shop-Name
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    {user.businessName}
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Owner-Name
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    {user.name}
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Email-Id
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    {user.email}
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Contact No.
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    {user.phoneNumber}
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Address
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    {user.address}
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Pincode
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    {user.pincode}
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Location
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    <a href={user.Glink}>{user.Glink}</a>
                  </Col>
                </Row>
              </Col>
              <Col md={6} className="profile-col" style={{ width: "49%" }}>
                <Row>
                  <Col
                    md={6}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Spa Type
                  </Col>
                  <Col md={6} style={{ fontWeight: "500" }}>
                    {user?.shoptype}
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={6}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Type
                  </Col>
                  <Col md={6} style={{ fontWeight: "500" }}>
                    {user?.type}
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={6}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Working Time
                  </Col>
                  <Col md={6} style={{ fontWeight: "500" }}>
                    {user.formattedStartTime} - {user.formattedEndTime}
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={6}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Working Days
                  </Col>
                  <Col md={6} style={{ fontWeight: "500" }}>
                    <input type="checkbox" checked={user.mon} />
                    &nbsp;
                    <label
                      style={{
                        color: "black",
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                    >
                      Mon
                    </label>{" "}
                    &nbsp;
                    <input type="checkbox" checked={user.tue} />
                    &nbsp;
                    <label
                      style={{
                        color: "black",
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                    >
                      Tue
                    </label>{" "}
                    &nbsp;
                    <input type="checkbox" checked={user.wed} />
                    &nbsp;
                    <label
                      style={{
                        color: "black",
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                    >
                      wed
                    </label>{" "}
                    <br></br>
                    <input type="checkbox" checked={user.turs} />
                    &nbsp;
                    <label
                      style={{
                        color: "black",
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                    >
                      Tur
                    </label>{" "}
                    &nbsp;
                    <input type="checkbox" checked={user.frid} />
                    &nbsp;
                    <label
                      style={{
                        color: "black",
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                    >
                      Fri
                    </label>{" "}
                    &nbsp;
                    <input type="checkbox" checked={user.sat} />
                    &nbsp;
                    <label
                      style={{
                        color: "black",
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                    >
                      Sat
                    </label>{" "}
                    <br></br>
                    <input type="checkbox" checked={user.sun} />
                    &nbsp;
                    <label
                      style={{
                        color: "black",
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                    >
                      Sun
                    </label>{" "}
                    &nbsp;
                  </Col>
                </Row>
               
                <Row>
                  <Col
                    md={6}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Parking
                  </Col>
                  <Col md={6} style={{ fontWeight: "500" }}>
                    {user.parking}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3 profile-col">
              <p
                style={{
                  color: "rgb(226, 34, 23)",
                  fontWeight: "bold",
                }}
              >
                Aadhaar Card
              </p>
              {user?.Adhar?.map((item) => (
                <>
                  <img
                    src={"https://spazo.co.in/Vendor/" + item?.adharF1}
                    style={{ width: "100px", height: "100px" }}
                    onClick={() =>
                      window.open(
                        "https://spazo.co.in/Vendor/" + item?.adharF1
                      )
                    }
                  />
                  <img
                    src={"https://spazo.co.in/Vendor/" + item?.adharB1}
                    style={{ width: "100px", height: "100px" }}
                    onClick={() =>
                      window.open(
                        "https://spazo.co.in/Vendor/" + item?.adharB1
                      )
                    }
                  />
                </>
              ))}
            </Row>
            <Row className="mt-3 profile-col">
              <Col md={2}>
                <p
                  style={{
                    color: "rgb(226, 34, 23)",
                    fontWeight: "bold",
                  }}
                >
                  Pan Card
                </p>

                <img
                  src={"https://spazo.co.in/Vendor/" + user?.Pan}
                  style={{ width: "100px", height: "100px" }}
                  onClick={() =>
                    window.open("https://spazo.co.in/Vendor/" + user?.Pan)
                  }
                />
              </Col>

              <Col md={2}>
                <p
                  style={{
                    color: "rgb(226, 34, 23)",
                    fontWeight: "bold",
                  }}
                >
                  Bank PassBook
                </p>

                <img
                  src={"https://spazo.co.in/Vendor/" + user?.passbook}
                  style={{ width: "100px", height: "100px" }}
                  onClick={() =>
                    window.open(
                      "https://spazo.co.in/Vendor/" + user?.passbook
                    )
                  }
                />
              </Col>

              <Col md={2}>
                <p
                  style={{
                    color: "rgb(226, 34, 23)",
                    fontWeight: "bold",
                  }}
                >
                  GST Certificate
                </p>

                <img
                  src={"https://spazo.co.in/Vendor/" + user?.gst}
                  style={{ width: "100px", height: "100px" }}
                  onClick={() =>
                    window.open("https://spazo.co.in/Vendor/" + user?.gst)
                  }
                />
              </Col>

              <Col md={2}>
                <p
                  style={{
                    color: "rgb(226, 34, 23)",
                    fontWeight: "bold",
                  }}
                >
                  Spa Certificate
                </p>

                <img
                  src={"https://spazo.co.in/Vendor/" + user?.spa}
                  style={{ width: "100px", height: "100px" }}
                  onClick={() =>
                    window.open("https://spazo.co.in/Vendor/" + user?.spa)
                  }
                />
              </Col>

              <Col md={4}>
                <p
                  style={{
                    color: "rgb(226, 34, 23)",
                    fontWeight: "bold",
                  }}
                >
                  Other Certificate
                </p>

                <img
                  src={"https://spazo.co.in/Vendor/" + user?.other}
                  style={{ width: "100px", height: "100px" }}
                  onClick={() =>
                    window.open("https://spazo.co.in/Vendor/" + user?.other)
                  }
                />
              </Col>
            </Row>

            <Row style={{ justifyContent: "space-between" }} className="mb-5">
              <Col md={8}>
                <Row className="mt-3 profile-col">
                

                  <Col md={3}>
                    <p
                      style={{
                        color: "rgb(226, 34, 23)",
                        fontWeight: "bold",
                      }}
                    >
                      Road side View
                    </p>
                    <img
                      src={"https://spazo.co.in/Vendor/" + user?.spaimage1}
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                      }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + user?.spaimage1
                        )
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <p
                      style={{
                        color: "rgb(226, 34, 23)",
                        fontWeight: "bold",
                      }}
                    >
                      Lobby View
                    </p>
                    <img
                      src={"https://spazo.co.in/Vendor/" + user?.spaimage2}
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                      }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + user?.spaimage2
                        )
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <p
                      style={{
                        color: "rgb(226, 34, 23)",
                        fontWeight: "bold",
                      }}
                    >
                      Corner View
                    </p>
                    <img
                      src={"https://spazo.co.in/Vendor/" + user?.spaimage3}
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                      }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + user?.spaimage3
                        )
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <p
                      style={{
                        color: "rgb(226, 34, 23)",
                        fontWeight: "bold",
                      }}
                    >
                      Therapy Room View
                    </p>
                    <img
                      src={"https://spazo.co.in/Vendor/" + user?.spaimage4}
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                      }}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Vendor/" + user?.spaimage4
                        )
                      }
                    />
                  </Col>
                  <p
                    style={{
                      color: "rgb(226, 34, 23)",
                      fontWeight: "bold",
                    }}
                    className="mt-1"
                  >
                    Other Images
                  </p>
                  <Row>
                    {user?.AdditionalImageArray?.length > 0 ? (
                      <>
                        {user?.AdditionalImageArray?.map((item) => (
                          <Col md={3} className="mt-1">
                            <img
                              src={
                                "https://spazo.co.in/Vendor/" +
                                item?.AdditionalImage
                              }
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "50%",
                              }}
                              onClick={() =>
                                window.open(
                                  "https://spazo.co.in/Vendor/" +
                                    item?.AdditionalImage
                                )
                              }
                            />
                          </Col>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </Row>
                </Row>
              </Col>
              <Col md={4} style={{ width: "32%" }}>
                {" "}
                <Row className="mt-3 profile-col">
                  <p
                    style={{
                      color: "rgb(226, 34, 23)",
                      fontWeight: "bold",
                    }}
                  >
                    About
                  </p>
                  <p style={{ overflowY: "scroll", height: "190px" }}>
                    {parse(user?.about)}
                  </p>
                </Row>
              </Col>
            </Row>
          </div>
        ) : (
          <div
            style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}
          >
            <Row>
              <Col md={6}>
                {" "}
                <Row className="mt-2">
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Shop-Name
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    <input
                      placeholder={user.businessName}
                      onChange={(e) => setbusinessName(e.target.value)}
                      style={{ width: "95%" }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Owner-Name
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    <input
                      placeholder={user.name}
                      onChange={(e) => setname(e.target.value)}
                      style={{ width: "95%" }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2 me-0">
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Address
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    <div
                      style={{
                        border: "1px solid gray",
                        width: "97%",
                        marginLeft: "4px",
                      }}
                    >
                      <GooglePlacesAutocomplete
                        apiKey="AIzaSyAeVFaPIlv5BTjUqMrBIVRfsM_SvDPegHg"
                        selectProps={{
                          isMulti: true,
                          placeholder: user?.address,
                          isClearable: true,
                          value: address,
                          onChange: (val) => {
                            setaddress(val);
                          },
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Location
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    <input
                      placeholder={user.Glink}
                      onChange={(e) => setGlink(e.target.value)}
                      style={{ width: "95%" }}
                    />
                  </Col>
                </Row>
              </Col>

              <Col md={6}>
                <Row className="mt-2">
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Email-Id
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    <input
                      placeholder={user.email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ width: "95%" }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Contact No.
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    <input
                      maxLength={10}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder={user.phoneNumber}
                      onChange={(e) => setphoneNumber(e.target.value)}
                      style={{ width: "95%" }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Pincode
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    <input
                      placeholder={user.pincode}
                      maxLength={6}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => setpincode(e.target.value)}
                      style={{ width: "95%" }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Parking
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    <select
                      onChange={(e) => setparking(e.target.value)}
                      className="upselect"
                      style={{ width: "95%" }}
                    >
                      <option>{user.parking}</option>
                      <option value="2-Wheeler">2-Wheeler</option>
                      <option value="4-Wheeler">4-Wheeler</option>
                      <option value="Both">Both</option>
                      <option value="NO Parking">No Parking</option>
                    </select>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col
                md={2}
                style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
              >
                working days
              </Col>
              <Col md={8} style={{ fontWeight: "500" }}>
                <div style={{ display: "flex" }}>
                  <input
                    type="checkbox"
                    id="Sunday"
                    checked={sun}
                    onChange={(e) => {
                      setsun(!sun);
                      setworkingdaysChange(true);
                    }}
                  />{" "}
                  &nbsp;
                  <label for="Sunday">Sunday</label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    id="monday"
                    checked={mon}
                    onChange={(e) => {
                      setmon(!mon);
                      setworkingdaysChange(true);
                    }}
                  />{" "}
                  &nbsp;
                  <label for="monday">Monday</label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    id="Tuesday"
                    checked={tue}
                    onChange={(e) => {
                      settue(!tue);
                      setworkingdaysChange(true);
                    }}
                  />{" "}
                  &nbsp;
                  <label for="Tuesday">Tuesday</label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    id="Wednesday"
                    checked={wed}
                    onChange={(e) => {
                      setwed(!wed);
                      setworkingdaysChange(true);
                    }}
                  />{" "}
                  &nbsp;
                  <label for="Wednesday">Wednesday</label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    id="Thursday"
                    checked={turs}
                    onChange={(e) => {
                      setturs(!turs);
                      setworkingdaysChange(true);
                    }}
                  />{" "}
                  &nbsp;
                  <label for="Thursday">Thursday</label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    id="Friday"
                    checked={frid}
                    onChange={(e) => {
                      setfrid(!frid);
                      setworkingdaysChange(true);
                    }}
                  />{" "}
                  &nbsp;
                  <label for="Friday">Friday</label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    id="Saturday"
                    checked={sat}
                    onChange={(e) => {
                      setsat(!sat);
                      setworkingdaysChange(true);
                    }}
                  />{" "}
                  &nbsp;
                  <label for="Saturday">Saturday </label>
                  &nbsp;&nbsp;&nbsp;
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col
                md={2}
                style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
              >
                working Time
              </Col>
              <Col md={8} style={{ fontWeight: "500" }}>
                <Row>
                  <Col md={6}>
                    {" "}
                    <Row>
                      <Col md={4}>Start Time</Col>
                      <Col md={8}>
                        <input
                          type="time"
                          className="upbtn"
                          onChange={(e) => setstarttime(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={4}>End Time</Col>
                      <Col md={8}>
                        <input
                          type="time"
                          className="upbtn"
                          onChange={(e) => setendtime(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={6}>
                {" "}
                <Row>
                  <Col
                    md={4}
                    style={{ color: "rgb(226, 34, 23)", fontWeight: "bold" }}
                  >
                    Spa Type
                  </Col>
                  <Col md={8} style={{ fontWeight: "500" }}>
                    <select
                      onChange={(e) => setshoptype(e.target.value)}
                      style={{ width: "75%" }}
                    >
                      <option>{user.shoptype}</option>
                 
                    </select>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>{""}</Col>
            </Row>
            {shoptype === "Pvt Ltd" ? (
              <>
                <div>
                  <h5 style={{ color: "white" }}>
                    Upload your Aadhaar card (Min 2 pair)
                  </h5>
                  <div
                 
                  >
                    {Adhar?.map((item) => (
                      <>
                        <img
                          src={URL.createObjectURL(item.adharF1)}
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <img
                          src={URL.createObjectURL(item.adharB1)}
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                            marginRight: "20px",
                          }}
                        />
                      </>
                    ))}
                    <br></br>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <div>
                        <label htmlFor="icon-button-file1">
                          <Input
                            accept="image/*"
                            id="icon-button-file1"
                            type="file"
                            onChange={(e) => setadharF1(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                adharF1
                                  ? URL.createObjectURL(adharF1)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                             
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>AdharFront1</p>
                      </div>
                      <div>
                        <label htmlFor="icon-button-file2">
                          <Input
                            accept="image/*"
                            id="icon-button-file2"
                            type="file"
                            onChange={(e) => setadharB1(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                adharB1
                                  ? URL.createObjectURL(adharB1)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>AdharBack1</p>
                      </div>
                    </div>

                    <button
                      style={{
                        border: "none",
                        padding: "8px",
                        height: "30px",
                        backgroundColor: "#e22217",
                        color: "white",
                        marginTop: "10px",
                        marginLeft: "30px",
                      }}
                      onClick={addAdhar}
                    >
                      Add
                    </button>
                    

                 
                  </div>
                </div>
              </>
            ) : shoptype === "Partner Ship" ? (
              <>
                
                <div>
                  <h5 style={{ color: "white" }}>
                    Upload your Aadhaar card (Min 2 pair)
                  </h5>
                  <div
                
                  >
                    {Adhar?.map((item) => (
                      <>
                        <img
                          src={URL.createObjectURL(item.adharF1)}
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <img
                          src={URL.createObjectURL(item.adharB1)}
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                            marginRight: "20px",
                          }}
                        />
                      </>
                    ))}
                    <br></br>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <div>
                        <label htmlFor="icon-button-file1">
                          <Input
                            accept="image/*"
                            id="icon-button-file1"
                            type="file"
                            onChange={(e) => setadharF1(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                adharF1
                                  ? URL.createObjectURL(adharF1)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                               
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>AdharFront1</p>
                      </div>
                      <div>
                        <label htmlFor="icon-button-file2">
                          <Input
                            accept="image/*"
                            id="icon-button-file2"
                            type="file"
                            onChange={(e) => setadharB1(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                adharB1
                                  ? URL.createObjectURL(adharB1)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>AdharBack1</p>
                      </div>
                    </div>

                    <button
                      style={{
                        border: "none",
                        padding: "8px",
                        height: "30px",
                        backgroundColor: "#e22217",
                        color: "white",
                        marginTop: "50px",
                        marginLeft: "30px",
                      }}
                      onClick={addAdhar}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </>
            ) : shoptype === "Properiter Ship" ? (
              <>
                
                <div>
                  <h5 style={{ color: "white" }}>
                    Upload your Aadhaar card (Min 1 pair)
                  </h5>
                  <div
               
                  >
                    {Adhar?.map((item) => (
                      <>
                        <img
                          src={URL.createObjectURL(item.adharF1)}
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <img
                          src={URL.createObjectURL(item.adharB1)}
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                            marginRight: "20px",
                          }}
                        />
                      </>
                    ))}
                    <br></br>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <div>
                        <label htmlFor="icon-button-file1">
                          <Input
                            accept="image/*"
                            id="icon-button-file1"
                            type="file"
                            onChange={(e) => setadharF1(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                adharF1
                                  ? URL.createObjectURL(adharF1)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                               
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>AdharFront1</p>
                      </div>
                      <div>
                        <label htmlFor="icon-button-file2">
                          <Input
                            accept="image/*"
                            id="icon-button-file2"
                            type="file"
                            onChange={(e) => setadharB1(e.target.files[0])}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <img
                              src={
                                adharB1
                                  ? URL.createObjectURL(adharB1)
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                              }
                              alt="avathar"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                              }}
                            />
                            <PhotoCamera
                              style={{
                                color: "black",
                                margin: "40px 0px 0px -20px",
                              }}
                            />
                          </IconButton>
                        </label>
                        <p>AdharBack1</p>
                      </div>
                    </div>

                    <button
                      style={{
                        border: "none",
                        padding: "8px",
                        height: "30px",
                        backgroundColor: "#e22217",
                        color: "white",
                        marginTop: "50px",
                        marginLeft: "30px",
                      }}
                      onClick={addAdhar}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            <Row>
              <Col md={6}>
                <Row className="mt-3">
                  <Col md={4}>Upload Pan Card</Col>
                  <Col md={8}>
                    {uploadPan ? (
                      <div>
                        <div className="adhharr">
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              margin: "20px",
                            }}
                          >
                            <div>
                              <label htmlFor="pan">
                                <Input
                                  accept="image/*"
                                  id="pan"
                                  type="file"
                                  onChange={(e) => setPan(e.target.files[0])}
                                />
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <img
                                    src={
                                      Pan
                                        ? URL.createObjectURL(Pan)
                                        : "https://spazo.co.in/Vendor/" +
                                          user?.Pan
                                    }
                                    alt="avathar"
                                    style={{
                                      width: "80px",
                                      height: "80px",
                                      borderRadius: "50px",
                                    }}
                                  />
                                  <PhotoCamera
                                    style={{
                                      color: "black",
                                      margin: "40px 0px 0px -20px",
                                    }}
                                  />
                                </IconButton>
                              </label>
                              <p>PanCard</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <button
                        className="upbtn"
                        onClick={() => setuploadPan(true)}
                      >
                        Upload Pan
                      </button>
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>Upload Bank Passbook</Col>
                  <Col md={8}>
                    {uploadpassbook ? (
                      <div>
                        <div className="adhharr">
                          <div>
                            <label htmlFor="passbook">
                              <Input
                                accept="image/*"
                                id="passbook"
                                type="file"
                                onChange={(e) => setpassbook(e.target.files[0])}
                              />
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              >
                                <img
                                  src={
                                    passbook
                                      ? URL.createObjectURL(passbook)
                                      : "https://spazo.co.in/Vendor/" +
                                        user?.passbook
                                  }
                                  alt="avathar"
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    borderRadius: "50px",
                                  }}
                                />
                                <PhotoCamera
                                  style={{
                                    color: "black",
                                    margin: "40px 0px 0px -20px",
                                  }}
                                />
                              </IconButton>
                            </label>
                            <p>Bank Passbook</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <button
                        className="upbtn"
                        onClick={() => setuploadpassbook(true)}
                      >
                        Upload Bank Passbook
                      </button>
                    )}
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={4}>Upload GST Certificate</Col>
                  <Col md={8}>
                    {uploadgst ? (
                      <div>
                        <div className="adhharr">
                          <div>
                            <label htmlFor="gstc">
                              <Input
                                accept="image/*"
                                id="gstc"
                                type="file"
                                onChange={(e) => setgst(e.target.files[0])}
                              />
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              >
                                <img
                                  src={
                                    gst
                                      ? URL.createObjectURL(gst)
                                      : "https://spazo.co.in/Vendor/" +
                                        user?.gst
                                  }
                                  alt="avathar"
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    borderRadius: "50px",
                                  }}
                                />
                                <PhotoCamera
                                  style={{
                                    color: "black",
                                    margin: "40px 0px 0px -20px",
                                  }}
                                />
                              </IconButton>
                            </label>
                            <p>GST Certificate</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <button
                        className="upbtn"
                        onClick={() => setuploadgst(true)}
                      >
                        Upload GST Certificate
                      </button>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mt-3">
                  <Col md={4}>Upload Spa Certificate</Col>
                  <Col md={8}>
                    {uploadspa ? (
                      <div>
                        <div className="adhharr">
                          <div>
                            <label htmlFor="spac">
                              <Input
                                accept="image/*"
                                id="spac"
                                type="file"
                                onChange={(e) => setspa(e.target.files[0])}
                              />
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              >
                                <img
                                  src={
                                    spa
                                      ? URL.createObjectURL(spa)
                                      : "https://spazo.co.in/Vendor/" +
                                        user?.spa
                                  }
                                  alt="avathar"
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    borderRadius: "50px",
                                  }}
                                />
                                <PhotoCamera
                                  style={{
                                    color: "black",
                                    margin: "40px 0px 0px -20px",
                                  }}
                                />
                              </IconButton>
                            </label>
                            <p>Spa Certificate</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <button
                        className="upbtn"
                        onClick={() => setuploadspa(true)}
                      >
                        Upload Spa Certificate
                      </button>
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>Upload if any other Certificate</Col>
                  <Col md={8}>
                    {uploadanyother ? (
                      <div>
                        <div className="adhharr">
                          <div>
                            <label htmlFor="other">
                              <Input
                                accept="image/*"
                                id="other"
                                type="file"
                                onChange={(e) => setother(e.target.files[0])}
                              />
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              >
                                <img
                                  src={
                                    other
                                      ? URL.createObjectURL(other)
                                      : "https://spazo.co.in/Vendor/" +
                                        user?.other
                                  }
                                  alt="avathar"
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    borderRadius: "50px",
                                  }}
                                />
                                <PhotoCamera
                                  style={{
                                    color: "black",
                                    margin: "40px 0px 0px -20px",
                                  }}
                                />
                              </IconButton>
                            </label>
                            <p>Certificate</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <button
                        className="upbtn"
                        onClick={() => setuploadanyother(true)}
                      >
                        Upload if any other Certificate
                      </button>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-4">
              <h6>Upload Spa Images</h6>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <label htmlFor="image1">
                    <Input
                      accept="image/*"
                      id="image1"
                      type="file"
                      onChange={(e) => setimage1(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          image1
                            ? URL.createObjectURL(image1)
                            : "https://spazo.co.in/Vendor/" + user?.spaimage1
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                  <p style={{ textAlign: "center" }}>Road side View</p>
                </div>
                <div>
                  <label htmlFor="image2">
                    <Input
                      accept="image/*"
                      id="image2"
                      type="file"
                      onChange={(e) => setimage2(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          image2
                            ? URL.createObjectURL(image2)
                            : "https://spazo.co.in/Vendor/" + user?.spaimage2
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                  <p style={{ textAlign: "center" }}>
                    Lobby Reception Desk View
                  </p>
                </div>
                <div>
                  <label htmlFor="image3">
                    <Input
                      accept="image/*"
                      id="image3"
                      type="file"
                      onChange={(e) => setimage3(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          image3
                            ? URL.createObjectURL(image3)
                            : "https://spazo.co.in/Vendor/" + user?.spaimage3
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                  <p style={{ textAlign: "center" }}>Corner View</p>
                </div>
                <div>
                  <label htmlFor="image4">
                    <Input
                      accept="image/*"
                      id="image4"
                      type="file"
                      onChange={(e) => setimage4(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          image4
                            ? URL.createObjectURL(image4)
                            : "https://spazo.co.in/Vendor/" + user?.spaimage4
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                  <p style={{ textAlign: "center" }}>Therapy Room View</p>
                </div>
              </div>
            </Row>
            <div>
              <h6>Spa Other Images</h6>
              <Row className="mt-2">
                {user?.AdditionalImageArray?.length > 0 ? (
                  <>
                    {user?.AdditionalImageArray?.map((item) => (
                      <Col md={3}>
                        <img
                          src={
                            "https://spazo.co.in/Vendor/" +
                            item?.AdditionalImage
                          }
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50%",
                          }}
                          onClick={() =>
                            window.open(
                              "https://spazo.co.in/Vendor/" +
                                item?.AdditionalImage
                            )
                          }
                        />
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            position: "absolute",
                            marginLeft: "-20px",
                          }}
                          onClick={() => deleteAdditionalImage(user, item)}
                        >
                          <i
                            class="fas fa-times-circle"
                            size={30}
                            style={{ color: "#e22217" }}
                          ></i>
                        </button>
                      </Col>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </Row>

              <div>
                {displayimage?.map((item) => (
                  <>
                    <img
                      src={URL.createObjectURL(item.AdditionalImage)}
                      alt="avathar"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50px",
                      }}
                    />
                  </>
                ))}
                <br></br>
                <div style={{ display: "flex", gap: "10px" }}>
                  <div>
                    <label htmlFor="icon-button-file1">
                      <Input
                        accept="image/*"
                        id="icon-button-file1"
                        type="file"
                        multiple
                        onChange={(e) => setAdditionalImage(e.target.files)}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <img
                          src={
                            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQueVhIdH7EKL6sLlHuCK4etHw1nctOIza_Zg&usqp=CAU"
                          }
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                            alignSelf: "center",
                          }}
                        />
                        <PhotoCamera
                          style={{
                            color: "black",
                            margin: "40px 0px 0px -20px",
                          }}
                        />
                      </IconButton>
                    </label>
                    <p>Change Other Images(You can select Multiple Images)</p>
                  </div>
                </div>

              </div>
            </div>
            <Row className="mt-4">
              <Col md={6}>
                {" "}
                <h6>About Your Spa</h6>
                <CKEditor
                  editor={ClassicEditor}
                  data={about}
                  onChange={handleEditorChange}
                />
              </Col>
              <Col md={6}>
                <div style={{ display: "flex", gap: "20px" }}>
                  <div>
                    <label htmlFor="image5">
                      <Input
                        accept="image/*"
                        id="image5"
                        type="file"
                        onChange={(e) => setprofile(e.target.files[0])}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <img
                          src={
                            profile
                              ? URL.createObjectURL(profile)
                              : "https://spazo.co.in/Vendor/" + user?.profile
                          }
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <PhotoCamera
                          style={{
                            color: "black",
                            margin: "40px 0px 0px -20px",
                          }}
                        />
                      </IconButton>
                    </label>
                    <p>Owner Profile</p>
                  </div>
                  <div>
                    <label htmlFor="logo">
                      <Input
                        accept="image/*"
                        id="logo"
                        type="file"
                        onChange={(e) => setlogo(e.target.files[0])}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <img
                          src={
                            logo
                              ? URL.createObjectURL(logo)
                              : "https://spazo.co.in/Vendor/" + user?.logo
                          }
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <PhotoCamera
                          style={{
                            color: "black",
                            margin: "40px 0px 0px -20px",
                          }}
                        />
                      </IconButton>
                    </label>
                    <p>Business Logo</p>
                  </div>
                </div>
              </Col>
            </Row>

            <button
              style={{
                border: "none",
                right: "10px",
                color: "white",
                backgroundColor: "rgb(226, 34, 23)",
                position: "absolute",
                padding: "2px 10px",
                borderRadius: "3px",
              }}
              onClick={VendorProfileedit}
            >
              Update
            </button>
          </div>
        )} */}
      </section>
    </div>
  );
}

export default Vendorprofile;
