import React, { useEffect, useState } from "react";
import "../Styles/login.css";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { BiLogoFacebook } from "react-icons/bi";
import axios from "axios";
import { Country, State, City } from "country-state-city";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  document.title = "SPA-Login";
  const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    "& > :not(style) + :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));

  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    notify2("You have entered an invalid email address!");
    return false;
  }

  function validatename(inputtxt) {
    var phoneno = /^[a-zA-Z ]{2,30}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      notify2("You have entered an invalid name!");
      return false;
    }
  }

  function phonenumber(inputtxt) {
    var phoneno = /^[6-9]\d{9}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      notify2("You have entered an invalid mobile number!");
      return false;
    }
  }

  function CheckPassword(inputtxt) {
    var decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    if (inputtxt.match(decimal)) {
      return true;
    } else {
      notify2(
        "Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, one special character and minimum 8 digit!"
      );
      return false;
    }
  }

  const [login, setlogin] = useState(true);

  const [loginEmail, setloginEmail] = useState("");
  const [loginPassword, setloginPassword] = useState("");

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phoneNumber, setphoneNumber] = useState();
  const [password, setpassword] = useState("");
  const [cpassword, setcpassword] = useState("");
  const [country, setcountry] = useState("");

  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [Pincode, setPincode] = useState();

  const CountryList = Country.getAllCountries();
  const StateList = State.getStatesOfCountry(country);
  const CityList = City.getCitiesOfState(country, state);

  const [loginPasswordShown, setloginPasswordShown] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [cpasswordShown, setcpasswordShown] = useState(false);

  const toggleloginPassword = () => {
    setloginPasswordShown(!loginPasswordShown);
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPassword = () => {
    setcpasswordShown(!cpasswordShown);
  };

  useEffect(() => {
    if (loginEmail || loginPassword) {
      setloginEmail("");
      setloginPassword("");
    }
  }, [login]);

  const Customerlogin = async (e) => {
    if (!loginEmail || !loginPassword) {
      // alert("Please Fill All The Field");
      notify2("Please Fill All The Field");
    } else {
      try {
        const config = {
          url: "/Signin",
          method: "post",
          baseURL: "https://spazo.co.in/api/customer",
          headers: { "content-type": "application/json" },
          data: {
            email: loginEmail,
            password: loginPassword,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          if (res.data.user.Blockstatus) {
            notify1(
              "You are blocked by Admin, Due to " + res.data.user.blockreason
            );
            // alert(
            //   "You are blocked by Admin, Due to " + res.data.user.blockreason
            // );
          } else {
            // alert("Login Success");
            sessionStorage.setItem("user", JSON.stringify(res.data.user));
            notify("Login Success");
            window.location.assign("/NewHome");
          }
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          // alert(error.response.data.error);
          notify1(error.response.data.error);
        }
      }
    }
  };

  const Customersignup = async (e) => {
    if (
      !name ||
      !email ||
      !phoneNumber ||
      !password ||
      !cpassword ||
      !country ||
      !state ||
      !city ||
      !Pincode
    ) {
      // alert("Please Fill All The Field");
      notify2("Please Fill All The Field");
    } else {
      try {
        if (
          validatename(name) &&
          ValidateEmail(email) &&
          phonenumber(phoneNumber) &&
          CheckPassword(password)
        ) {
          const config = {
            url: "/signup",
            method: "post",
            baseURL: "https://spazo.co.in/api/customer",
            headers: { "content-type": "application/json" },
            data: {
              name: name,
              email: email,
              phoneNumber: phoneNumber,
              password: password,
              cpassword: cpassword,
              country: country,
              state: state,
              city: city,
              Pincode: Pincode,
            },
          };
          let res = await axios(config);
          if (res.status === 200) {
            // alert("Signup Success, Please login");
            notify("Signup Success, Please login");
            window.location.reload();
          }
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          // alert(error.response.data.error);
          notify1(error.response.data.error);
        }
      }
    }
  };

  return (
    <>
      {/* <div className="login">
        <div className="row me-0">
          <div
            className="col-md-6 p-0"
            style={{ flexWrap: "nowrap", overflowY: "scroll", height: "600px" }}
          >
            <div className="fresh">
              <img
                src="./logo.jpg"
                alt="logo"
                style={{ width: "167px", height: "67px" }}
              />
              <div className="customers">
                <h6>SPA for customers</h6>
                <p>
                  Create an account or log in to book and manage your
                  appointments.
                </p>
              </div>
              {login ? (
                <div>
                  <div className="cust-login mb-3">
                    <input
                      type="email"
                      placeholder="Enter your Email address"
                      onChange={(e) => setloginEmail(e.target.value)}
                    />
                  </div>
                  <div className="cust-login mb-3">
                    <input
                      type={loginPasswordShown ? "text" : "password"}
                      placeholder="Enter your Password"
                      onChange={(e) => setloginPassword(e.target.value)}
                    />
                    {loginPasswordShown ? (
                      <button
                        onClick={toggleloginPassword}
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          marginLeft: "-27px",
                          paddingRight: "10px",
                        }}
                      >
                        <i
                          class="fa fa-eye-slash"
                          aria-hidden="true"
                          style={{ color: "#e22217" }}
                        ></i>
                      </button>
                    ) : (
                      <button
                        onClick={toggleloginPassword}
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          marginLeft: "-27px",
                          paddingRight: "10px",
                        }}
                      >
                        <i
                          class="fa fa-eye"
                          aria-hidden="true"
                          style={{ color: "#e22217" }}
                        ></i>
                      </button>
                    )}
                  </div>
                  <div className="cust-login-0 mb-3">
                    <button onClick={Customerlogin}>Login</button>
                  </div>
                  <p style={{ textAlign: "center", marginBottom: "0px" }}>
                    Don't have a account ?
                  </p>
                  <a
                    href="#"
                    onClick={() => setlogin(!login)}
                    style={{ textAlign: "center", display: "block" }}
                  >
                    Sign up now
                  </a>
                </div>
              ) : (
                <div>
                  <div className="cust-login mb-3">
                    <input
                      type="text"
                      placeholder="Enter your Name"
                      onChange={(e) => setname(e.target.value)}
                    />
                  </div>
                  <div className="cust-login mb-3">
                    <input
                      type="tele"
                      maxLength={10}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Enter your Contact Number"
                      onChange={(e) => setphoneNumber(e.target.value)}
                    />
                  </div>
                  <div className="cust-login mb-3">
                    <input
                      type="email"
                      placeholder="Enter your Email address"
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </div>
                  <div className="cust-login mb-3">
                    <select
                      id="datalistOptions2"
                      className="form-control"
                      list="datalistOptions2"
                      placeholder="Country *"
                      name="Country"
                      required
                      onChange={(e) => {
                        setcountry(e.target.value);
                      }}
                    >
                      <option value="">Select Country</option>
                      {CountryList.map((Country) => (
                        <option value={Country.isoCode}>{Country.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="cust-login mb-3">
                    <select
                      id="datalistOptions2"
                      className="form-control"
                      list="datalistOptions2"
                      placeholder="Country *"
                      name="Country"
                      required
                      onChange={(e) => setstate(e.target.value)}
                    >
                      <option value="">Select State</option>
                      {StateList.map((state1) => (
                        <option value={state1.isoCode}>{state1.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="cust-login mb-3">
                    <select
                      id="datalistOptions2"
                      className="form-control"
                      list="datalistOptions2"
                      placeholder="Country *"
                      name="Country"
                      required
                      onChange={(e) => setcity(e.target.value)}
                    >
                      <option value="">Select City</option>
                      {CityList.map((city1) => (
                        <option value={city1.name}>{city1.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="cust-login mb-3">
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      maxLength={6}
                      placeholder="Enter your Pincode"
                      onChange={(e) => setPincode(e.target.value)}
                    />
                  </div>
                  <div className="cust-login mb-3">
                    <input
                      type={passwordShown ? "text" : "password"}
                      placeholder="Enter your Password"
                      onChange={(e) => setpassword(e.target.value)}
                    />
                    {passwordShown ? (
                      <button
                        onClick={togglePassword}
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          marginLeft: "-27px",
                          paddingRight: "10px",
                        }}
                      >
                        <i
                          class="fa fa-eye-slash"
                          aria-hidden="true"
                          style={{ color: "#e22217" }}
                        ></i>
                      </button>
                    ) : (
                      <button
                        onClick={togglePassword}
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          marginLeft: "-27px",
                          paddingRight: "10px",
                        }}
                      >
                        <i
                          class="fa fa-eye"
                          aria-hidden="true"
                          style={{ color: "#e22217" }}
                        ></i>
                      </button>
                    )}
                  </div>
                  <div className="cust-login mb-3">
                    <input
                      type={cpasswordShown ? "text" : "password"}
                      placeholder="Enter Confirm Password"
                      onChange={(e) => setcpassword(e.target.value)}
                    />
                    {cpasswordShown ? (
                      <button
                        onClick={toggleConfirmPassword}
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          marginLeft: "-27px",
                          paddingRight: "10px",
                        }}
                      >
                        <i
                          class="fa fa-eye-slash"
                          aria-hidden="true"
                          style={{ color: "#e22217" }}
                        ></i>
                      </button>
                    ) : (
                      <button
                        onClick={toggleConfirmPassword}
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          marginLeft: "-27px",
                          paddingRight: "10px",
                        }}
                      >
                        <i
                          class="fa fa-eye"
                          aria-hidden="true"
                          style={{ color: "#e22217" }}
                        ></i>
                      </button>
                    )}
                  </div>
                  <div className="cust-login-0 mb-3">
                    <button onClick={Customersignup}>Sign Up</button>
                  </div>
                  <p style={{ textAlign: "center", marginBottom: "0px" }}>
                    Already have a account
                  </p>
                  <a
                    href="#"
                    onClick={() => setlogin(!login)}
                    style={{
                      textAlign: "center",
                      display: "block",
                      marginBottom: "20px",
                    }}
                  >
                    Sign in now
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 p-0">
            <div className="spa-login">
              <img
                src="../../Assets/images/login.jpg"
                alt="spa-login"
                style={{ width: "100%", height: "100vh" }}
              />
            </div>
          </div>
        </div>
      </div> */}

      <div className="admincontainer">
        <img
          src="../../Assets/images/loginimage1.jpg"
          alt="Snow"
          style={{ width: "100%", height: "100vh" }}
        />
        <ToastContainer
          position="top-center"
          autoClose={30000}
          closeOnClick
          pauseOnHover
          draggable
        />
        {login ? (
          <div className="top-right">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <img
                src="./logo.jpg"
                alt="logo"
                style={{ width: "167px", height: "67px" }}
              />
            </div>

            <div className="mt-5">
              <div className="cust-login1 mb-3">
                <input
                  type="email"
                  placeholder="Enter your Email address"
                  onChange={(e) => setloginEmail(e.target.value)}
                />
              </div>
              <div className="cust-login1 mb-3">
                <input
                  type={loginPasswordShown ? "text" : "password"}
                  placeholder="Enter your Password"
                  onChange={(e) => setloginPassword(e.target.value)}
                />
                {loginPasswordShown ? (
                  <button
                    onClick={toggleloginPassword}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      marginLeft: "-27px",
                      paddingRight: "10px",
                    }}
                  >
                    <i
                      class="fa fa-eye"
                      aria-hidden="true"
                      style={{ color: "#e22217" }}
                    ></i>
                  </button>
                ) : (
                  <button
                    onClick={toggleloginPassword}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      marginLeft: "-27px",
                      paddingRight: "10px",
                    }}
                  >
                    <i
                      class="fa fa-eye-slash"
                      aria-hidden="true"
                      style={{ color: "#e22217" }}
                    ></i>
                  </button>
                )}
              </div>
              <div className="cust-login-0 mb-3">
                <button onClick={Customerlogin}>Login</button>
              </div>
              <div
                style={{
                  gap: "10px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                {/* <span style={{ marginBottom: "0px" }}>
                  Don't have a account ? */}
                <a
                  href="#"
                  onClick={() => setlogin(!login)}
                  // style={{ textAlign: "center", display: "block" }}
                >
                  Create new account
                </a>
                {/* </span> */}
                <a
                  href="#"
                  onClick={() => window.location.assign("/EnterEmail")}
                >
                  Forgot Password..?
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="top-right1">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "1px",
              }}
            >
              <img
                src="./logo.jpg"
                alt="logo"
                style={{ width: "167px", height: "67px" }}
              />
            </div>
            <div style={{ overflowY: "scroll", height: "480px" }}>
              <div className="cust-login12 mb-3 mt-5">
                <input
                  type="text"
                  placeholder="Enter your Name *"
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
              <div className="cust-login12 mb-3">
                <input
                  type="tele"
                  maxLength={10}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Enter your Contact Number *"
                  onChange={(e) => setphoneNumber(e.target.value)}
                />
              </div>
              <div className="cust-login12 mb-3">
                <input
                  type="email"
                  placeholder="Enter your Email address *"
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
              <div className="cust-login12 mb-3">
                <select
                  id="datalistOptions2"
                  className="form-control111"
                  list="datalistOptions2"
                  placeholder="Country *"
                  name="Country"
                  required
                  onChange={(e) => {
                    setcountry(e.target.value);
                  }}
                >
                  <option value="">Select Country *</option>
                  {CountryList.map((Country) => (
                    <option value={Country.isoCode}>{Country.name}</option>
                  ))}
                </select>
              </div>
              <div className="cust-login12 mb-3">
                <select
                  id="datalistOptions2"
                  className="form-control111"
                  list="datalistOptions2"
                  placeholder="Country *"
                  name="Country"
                  required
                  onChange={(e) => setstate(e.target.value)}
                >
                  <option value="">Select State *</option>
                  {StateList.map((state1) => (
                    <option value={state1.isoCode}>{state1.name}</option>
                  ))}
                </select>
              </div>
              <div className="cust-login12 mb-3">
                <select
                  id="datalistOptions2"
                  className="form-control111"
                  list="datalistOptions2"
                  placeholder="Country *"
                  name="Country"
                  required
                  onChange={(e) => setcity(e.target.value)}
                >
                  <option value="">Select City *</option>
                  {CityList.map((city1) => (
                    <option value={city1.name}>{city1.name}</option>
                  ))}
                </select>
              </div>
              <div className="cust-login12 mb-3">
                <input
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  maxLength={6}
                  placeholder="Enter your Pincode *"
                  onChange={(e) => setPincode(e.target.value)}
                />
              </div>
              <div className="cust-login12 mb-3">
                <input
                  type={passwordShown ? "text" : "password"}
                  placeholder="Enter your Password *"
                  onChange={(e) => setpassword(e.target.value)}
                />
                {passwordShown ? (
                  <button
                    onClick={togglePassword}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      marginLeft: "-27px",
                      paddingRight: "10px",
                    }}
                  >
                    <i
                      class="fa fa-eye"
                      aria-hidden="true"
                      style={{ color: "#e22217" }}
                    ></i>
                  </button>
                ) : (
                  <button
                    onClick={togglePassword}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      marginLeft: "-27px",
                      paddingRight: "10px",
                    }}
                  >
                    <i
                      class="fa fa-eye-slash"
                      aria-hidden="true"
                      style={{ color: "#e22217" }}
                    ></i>
                  </button>
                )}
              </div>
              <div className="cust-login12 mb-3">
                <input
                  type={cpasswordShown ? "text" : "password"}
                  placeholder="Enter Confirm Password *"
                  onChange={(e) => setcpassword(e.target.value)}
                />
                {cpasswordShown ? (
                  <button
                    onClick={toggleConfirmPassword}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      marginLeft: "-27px",
                      paddingRight: "10px",
                    }}
                  >
                    <i
                      class="fa fa-eye"
                      aria-hidden="true"
                      style={{ color: "#e22217" }}
                    ></i>
                  </button>
                ) : (
                  <button
                    onClick={toggleConfirmPassword}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      marginLeft: "-27px",
                      paddingRight: "10px",
                    }}
                  >
                    <i
                      class="fa fa-eye-slash"
                      aria-hidden="true"
                      style={{ color: "#e22217" }}
                    ></i>
                  </button>
                )}
              </div>
              <div className="cust-login-0 mb-3">
                <button onClick={Customersignup}>Sign Up</button>
              </div>
              <p style={{ textAlign: "center", marginBottom: "0px" }}>
                Already have a account
              </p>
              <a
                href="#"
                onClick={() => setlogin(!login)}
                style={{
                  textAlign: "center",
                  display: "block",
                  marginBottom: "20px",
                }}
              >
                Sign in now
              </a>
            </div>
          </div>
        )}

        <div className="top-left">
          <div className="customers">
            <h5
              style={{ color: "white", fontSize: "38px", fontWeight: "bold" }}
            >
              SPAZO for customers
            </h5>
            <p style={{ color: "white", fontSize: "20px" }}>
              Create an account or log in to book and manage your appointments.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
