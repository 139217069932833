import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Category() {
  const formdata = new FormData();
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);

  const [show, setShow] = useState(false);
  const [editdata, seteditdata] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    seteditdata(item);
  };

  const [show2, setShow2] = useState(false);
  const [deletedata, setdeletedata] = useState({});

  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setdeletedata(item);
  };

  const [cattype, setcattype] = useState("");
  const [catname, setcatname] = useState("");
  const [catimage, setcatimage] = useState();

  const [catname1, setcatname1] = useState("");
  const [catimage1, setcatimage1] = useState();

  const [data, setdata] = useState([]);

  console.log("catname", data);

  useEffect(() => {
    getallCategory();
  }, []);

  const addCategory = async () => {
    if (!cattype) {
      alert("Please select the Type");
    } else if (!catname || !catimage) {
      alert("Please fill all the fields");
    } else {
      formdata.append("cattype", cattype);
      formdata.append("catname", catname);
      formdata.append("catimage", catimage);
      try {
        const config = {
          url: "/addcat",
          method: "post",
          baseURL: "https://spazo.co.in/api/admin",
          data: formdata,
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            notify("Data Added successfully");
            // alert("Data Added successfully");
            // window.location.reload();
            getallCategory();
            handleClose();
          } else {
            notify1(res.status.error);
            // alert(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        notify1(error.response.data.error);
        // alert("Unable to add Details");
      }
    }
  };

  const getallCategory = () => {
    axios
      .get("https://spazo.co.in/api/admin/getcategory")
      .then(function (response) {
        console.log(response.data);
        setdata(response.data.category);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editCategory = async () => {
    formdata.append("id", editdata?._id);
    formdata.append("catname", catname1 ? catname1 : editdata?.catname);
    formdata.append("catimage", catimage1 ? catimage1 : editdata?.catimage);
    try {
      const config = {
        url: "/editCategory",
        method: "post",
        baseURL: "https://spazo.co.in/api/admin",
        data: formdata,
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          notify("Data Updated successfully");
          // alert("Data edited successfully");
          // window.location.reload();
          getallCategory();
          handleClose1();
        } else {
          notify1(res.status.error);
          // alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      notify1("Unable to add Details, Please try after some time");
      // alert("Unable to add Details");
    }
  };

  const deleteCategory = async () => {
    try {
      const config = {
        url: "/removecat/" + deletedata?._id,
        method: "post",
        baseURL: "https://spazo.co.in/api/admin",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          notify1("Data deleted successfully");
          // alert("Data deleted successfully");
          window.location.reload();
          getallCategory();
        } else {
          notify1(res.status.error);
          // alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      notify1("Unable to delete Details, Please try after some time");
      // alert("Unable to add Details");
    }
  };

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "catimage",
      text: "Image",
      formatter: (value, row) => (
        <img
          src={"https://spazo.co.in/Category/" + row.catimage}
          width={100}
          height={80}
        />
      ),
    },
    {
      dataField: "cattype",
      text: "Type",
      formatter: (value, row) => <p>{row?.cattype ? row?.cattype : "-"}</p>,
    },
    {
      dataField: "catname",
      text: "Name",
      formatter: (value, row) => <p>{row?.catname ? row?.catname : "-"}</p>,
    },

    {
      dataField: "",
      text: "Action",
      formatter: (value, row) => (
        <div
          style={{
            display: "flex",
            gap: "21px",
          }}
        >
          <span className="dite">
            <FaRegEdit onClick={() => handleShow1(row)} />
          </span>
          <span className="dite">
            <RiDeleteBin2Fill onClick={() => handleShow2(row)} />
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="never-sspa">
        <section>
          <div className="spa-admin-0 mb-2">
            <div className="diff-admin px-3">
              <div className="admin-name-text">
                <h4>Category</h4>
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={30000}
            closeOnClick
            pauseOnHover
            draggable
          />
          <div
            style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}
          >
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              // rowClasses={rowClasses}
              search
              exportCSV
            >
              {(props) => (
                <div>
                  <SearchBar {...props.searchProps} />
                  <ExportCSVButton
                    {...props.csvProps}
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                    }}
                  >
                    Download Excel
                  </ExportCSVButton>
                  <button
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                      border: "none",
                      padding: "6px",
                      borderRadius: "5px",
                      marginLeft: "467px",
                    }}
                    onClick={handleShow}
                  >
                    +Add
                  </button>
                  <hr></hr>
                  <br></br>
                  <div
                    style={{
                      overflowX: "scroll",
                      width: "100%",
                      paddingBottom: "12%",
                    }}
                  >
                    <BootstrapTable
                      striped
                      hover
                      condensed
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </section>
      </div>

      {/* modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-spa">
            <div className="differ-spa mb-2">
              <label>Type</label>
              <br />
              <select
                onChange={(e) => setcattype(e.target.value)}
                style={{ width: "80%", padding: "10px", borderRadius: "4px" }}
              >
                <option>Select</option>
                <option value="Spa">Spa</option>
                <option value="Salon">Salon</option>
                <option value="Beauty Care Products">
                  Beauty Care Products
                </option>
              </select>
            </div>

            <div className="differ-spa mb-2">
              <label>Name</label>
              <br />
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => setcatname(e.target.value)}
              />
            </div>

            <div className="differ-spa mb-2">
              <label>Image</label>
              <br />
              <input
                type="file"
                accept="image/jpeg, image/png"
                onChange={(e) => setcatimage(e.target.files[0])}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addCategory}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-spa">
            <div className="differ-spa mb-2">
              <label>Name</label>
              <br />
              <input
                type="text"
                placeholder={editdata.catname}
                onChange={(e) => setcatname1(e.target.value)}
              />
            </div>

            <div className="differ-spa mb-2">
              <label>Image</label>
              <br />
              <input
                type="file"
                accept="image/jpeg, image/png"
                onChange={(e) => setcatimage1(e.target.files[0])}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={editCategory}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure, you want to delete data</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteCategory}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Category;
