import React, { useEffect, useState } from "react";
import Newheader2 from "./Newheader2";
import NewFooter from "./NewFooter";
import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import parse from "html-react-parser";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { RiMotorbikeFill } from "react-icons/ri";
import { AiFillCar } from "react-icons/ai";
import { IoAddCircleSharp, IoLocationSharp } from "react-icons/io5";
import ReactStars from "react-rating-stars-component";
import axios from "axios";
import moment from "moment/moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Brandproductdetails() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      // {
      //   breakpoint: 600,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 2,
      //     initialSlide: 2,
      //   },
      // },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const location = useLocation();
  const { item } = location.state;

  const user = JSON.parse(sessionStorage.getItem("user"));

  console.log("item", item);
  const [ratingsFor, setratingsFor] = useState("");
  const [Therapist, setTherapist] = useState([]);

  const TherapistRatings = Therapist?.filter(
    (item) => item.therapistreviews?.length > 0
  );
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [TRdata, setTRdata] = useState({});
  const [TRshow, setTRShow] = useState(false);
  const handleCloseTR = () => setTRShow(false);
  const handleShowTR = (item) => {
    setTRShow(true);
    setTRdata(item);
  };

  const [deals, setdeals] = useState(true);
  const [about, setabout] = useState(false);
  const [gallary, setgallary] = useState(false);
  const [parking, setparking] = useState(false);
  const [reviews, setreviews] = useState(false);

  const [workingdays, setworkingdays] = useState(false);

  const [array1, setarray1] = useState([]);
  const [slots, setslots] = useState([]);
  const [test, settest] = useState(false);

  const [rating, setrating] = useState();
  const [review, setreview] = useState("");

  const [reviewarray, setreviewarray] = useState([]);

  const ratingChanged = (newRating) => {
    console.log(newRating);
    setrating(newRating);
  };

  const [displayType, setdisplayType] = useState("Spa");
  const [Therapistrating, setTherapistrating] = useState();
  const [Therapistreview, setTherapistreview] = useState("");
  const [TherapistID, setTherapistID] = useState({});

  const ratingChanged1 = (newRating) => {
    console.log(newRating);
    setTherapistrating(newRating);
  };

  useEffect(() => {
    getreview();
    getVendorEmployee();
  }, []);

  // const getVendorEmployee = () => {
  //   axios
  //     .get("https://spazo.co.in/api/Vendor/getVendorEmployee/" + item?._id)
  //     .then(function (response) {
  //       // console.log(response.data);
  //       setTherapist(response.data.Employee);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const getVendorEmployee = () => {
    axios
      .get("https://spazo.co.in/api/Vendor/getAllEmployee")
      .then(function (response) {
        // console.log(response.data);
        setTherapist(
          response.data?.Employee?.filter((emp) => {
            return (
              emp?.EmpId &&
              emp?.vendor[emp.vendor?.length - 1]?.VendorId === item?.vendorId
            );
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addreview = async (e) => {
    if (!user) {
      notify2("Please login to give feedback");
    } else {
      if (!rating) {
        notify2("Please give a ratings");
      } else if (!review) {
        notify2("Please add your valuable feedback");
      } else {
        try {
          const config = {
            url: "/AddReview",
            method: "post",
            baseURL: "https://spazo.co.in/api/customer",
            headers: { "content-type": "application/json" },
            data: {
              rating: rating,
              review: review,
              name: user?.name,
              userID: user?._id,
              vendorID: item?.vendorId,
            },
          };
          let res = await axios(config);
          if (res.status === 200) {
            notify("Your feedback added successfully");
            window.location.reload();
          }
        } catch (error) {
          console.log(error.response);
          if (error.response) {
            notify1(error.response.data.error);
          }
        }
      }
    }
  };

  const getreview = () => {
    axios
      .get("https://spazo.co.in/api/customer/getVendorReview/" + item.vendorId)
      .then(function (response) {
        // console.log(response.data);
        setreviewarray(response.data.Review);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addTherapistreview = async () => {
    if (!user) {
      notify2("Please login to give feedback");
    } else {
      if (Object.keys(TherapistID)?.length === 0) {
        notify2("Please select Therapist");
      } else if (!Therapistrating) {
        notify2("Please give a ratings");
      } else if (!Therapistreview) {
        notify2("Please add your valuable feedback");
      } else {
        try {
          const config = {
            url: "/AddtherapistReview",
            method: "post",
            baseURL: "https://spazo.co.in/api/customer",
            // headers: { "content-type": "application/json" },
            data: {
              rating: Therapistrating,
              review: Therapistreview,
              Username: user?.name,
              userID: user?._id,
              TherapistID: TherapistID?._id,
            },
          };
          let res = await axios(config);
          if (res.status === 200) {
            notify("Your feedback added successfully");
            window.location.reload();
          }
        } catch (error) {
          console.log(error.response);
          if (error.response) {
            notify1(error.response.data.error);
          }
        }
      }
    }
  };

  console.log("Therapist", Therapist);
  console.log("TRdata", TRdata);
  return (
    <>
      <div>
        <Newheader2 />
        <div>
          <Row className="me-0">
            <p
              style={{
                fontSize: "25px",
                fontWeight: "bold",
                marginBottom: "0px",
              }}
            >
              {item?.vendors[0]?.businessName}
            </p>
          </Row>

          <Slider {...settings}>
            <div>
              <img
                src={
                  "https://spazo.co.in/Vendor/" + item?.vendors[0]?.spaimage1
                }
                style={{
                  width: "90%",
                  height: "300px",
                  // objectFit: "cover",
                  // overflow: "hidden",
                }}
              />
            </div>
            <div>
              <img
                src={
                  "https://spazo.co.in/Vendor/" + item?.vendors[0]?.spaimage2
                }
                style={{
                  width: "90%",
                  height: "300px",
                  // objectFit: "cover",
                  // overflow: "hidden",
                }}
              />
            </div>
            <div>
              <img
                src={
                  "https://spazo.co.in/Vendor/" + item?.vendors[0]?.spaimage3
                }
                style={{
                  width: "90%",
                  height: "300px",
                  // objectFit: "cover",
                  // overflow: "hidden",
                }}
              />
            </div>
            <div>
              <img
                src={
                  "https://spazo.co.in/Vendor/" + item?.vendors[0]?.spaimage4
                }
                style={{
                  width: "90%",
                  height: "300px",
                  // objectFit: "cover",
                  // overflow: "hidden",
                }}
              />
            </div>
          </Slider>
          <ToastContainer
            position="top-center"
            autoClose={30000}
            closeOnClick
            pauseOnHover
            draggable
          />

          <div className="feature">
            <div className="spa-feat">
              <ul style={{ padding: "0px" }}>
                <li
                  className={`tab-feature ${deals ? "active-spa" : "null"}`}
                  onClick={() => {
                    setdeals(true);
                    setabout(false);
                    setgallary(false);
                    setparking(false);
                    setreviews(false);
                  }}
                >
                  Deals
                </li>
                <li
                  className={`tab-feature ${about ? "active-spa" : "null"}`}
                  onClick={() => {
                    setdeals(false);
                    setabout(true);
                    setgallary(false);
                    setparking(false);
                    setreviews(false);
                  }}
                >
                  About
                </li>
                <li
                  className={`tab-feature ${gallary ? "active-spa" : "null"}`}
                  onClick={() => {
                    setdeals(false);
                    setabout(false);
                    setgallary(true);
                    setparking(false);
                    setreviews(false);
                  }}
                >
                  Gallery
                </li>
                <li
                  className={`tab-feature ${parking ? "active-spa" : "null"}`}
                  onClick={() => {
                    setdeals(false);
                    setabout(false);
                    setgallary(false);
                    setparking(true);
                    setreviews(false);
                  }}
                >
                  Parking
                </li>
                <li
                  className={`tab-feature ${reviews ? "active-spa" : "null"}`}
                  onClick={() => {
                    setdeals(false);
                    setabout(false);
                    setgallary(false);
                    setparking(false);
                    setreviews(true);
                  }}
                >
                  Reviews
                </li>
              </ul>
            </div>
            <div className="row">
              <div className="col-md-8">
                {deals ? (
                  <>
                    <div className="dealscroll">
                      {item?.service
                        ?.filter((item) => item.Approve === "Approved")
                        ?.map((service) => (
                          <>
                            <div className="feature-details-spa">
                              <div className="feature-details mb-2">
                                <div className="body-diff">
                                  <h6>
                                    {service?.ServiceName} (
                                    {service?.Service_Duration} mins)
                                    {service?.advantages?.map((adv) => (
                                      <span>
                                        + {adv?.adv_name} ({adv?.adv_duration}{" "}
                                        mins)
                                      </span>
                                    ))}
                                  </h6>

                                  <div className="feture-valide mb-2">
                                    <div className="free-diff">
                                      Valid for :{" "}
                                      <span>{service?.ValidFor}</span>
                                    </div>
                                    <div className="free-diff">
                                      Valid on :{" "}
                                      <span
                                        onClick={() => setworkingdays(true)}
                                      >
                                        Click Here to Check
                                      </span>
                                      {workingdays ? (
                                        <>
                                          <div
                                            style={{
                                              border:
                                                "1px solid rgb(255, 72, 165)",
                                              padding: "10px",
                                              borderRadius: "10px",
                                              backgroundColor: "#e22217",
                                            }}
                                          >
                                            <Row className="me-0">
                                              <Col md={4}>
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    item?.vendors[0]?.sun
                                                  }
                                                />
                                                <label
                                                  style={{
                                                    color: "white",
                                                    fontSize: "18px",
                                                  }}
                                                >
                                                  Sun
                                                </label>
                                              </Col>
                                              <Col md={4}>
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    item?.vendors[0]?.mon
                                                  }
                                                />
                                                <label
                                                  style={{
                                                    color: "white",
                                                    fontSize: "18px",
                                                  }}
                                                >
                                                  Mon
                                                </label>
                                              </Col>
                                              <Col md={4}>
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    item?.vendors[0]?.tue
                                                  }
                                                />
                                                <label
                                                  style={{
                                                    color: "white",
                                                    fontSize: "18px",
                                                  }}
                                                >
                                                  Tue
                                                </label>
                                              </Col>
                                            </Row>

                                            <Row>
                                              <Col md={4}>
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    item?.vendors[0]?.wed
                                                  }
                                                />
                                                <label
                                                  style={{
                                                    color: "white",
                                                    fontSize: "18px",
                                                  }}
                                                >
                                                  Wed
                                                </label>
                                              </Col>
                                              <Col md={4}>
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    item?.vendors[0]?.turs
                                                  }
                                                />
                                                <label
                                                  style={{
                                                    color: "white",
                                                    fontSize: "18px",
                                                  }}
                                                >
                                                  Thu
                                                </label>
                                              </Col>
                                              <Col md={4}>
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    item?.vendors[0]?.frid
                                                  }
                                                />
                                                <label
                                                  style={{
                                                    color: "white",
                                                    fontSize: "18px",
                                                  }}
                                                >
                                                  Fri
                                                </label>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col md={4}>
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    item?.vendors[0]?.sat
                                                  }
                                                />
                                                <label
                                                  style={{
                                                    color: "white",
                                                    fontSize: "18px",
                                                  }}
                                                >
                                                  Sat
                                                </label>
                                              </Col>
                                              <Col md={4}>{""}</Col>
                                              <Col md={4}>
                                                <span
                                                  style={{ color: "white" }}
                                                  onClick={() =>
                                                    setworkingdays(!workingdays)
                                                  }
                                                >
                                                  Close
                                                </span>
                                              </Col>
                                            </Row>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="free-diff">
                                      Timings :
                                      <span>
                                        {item?.vendors[0]?.formattedStartTime} -{" "}
                                        {item?.vendors[0]?.formattedEndTime}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="price">
                                  <div
                                    className="discount-p"
                                    style={{ fontSize: "15px" }}
                                  >
                                    {service?.Discount} % OFF
                                  </div>
                                  <div>
                                    <s>
                                      {" "}
                                      ₹
                                      {service?.Price +
                                        (service?.Price * service?.GST) / 100 +
                                        (service?.Price * service?.servicetax) /
                                          100}
                                    </s>
                                  </div>
                                  <div className="discount-price">
                                    ₹{" "}
                                    {service?.Price +
                                      (service?.Price * service?.GST) / 100 +
                                      (service?.Price * service?.servicetax) /
                                        100 -
                                      (service?.Price * service?.Discount) /
                                        100}
                                  </div>
                                </div>
                              </div>
                              <div className="iop-0">
                                <div

                                // onClick={() => bookslot(serv)}
                                >
                                  <Link
                                    to="/SelectTherapist"
                                    state={{
                                      item: item,
                                      service: service,
                                      type: "brand",
                                    }}
                                    //   state={{ slots: serv }}
                                    style={{
                                      textDecoration: "none",
                                      padding: "10px 20px",
                                      backgroundColor: "transparent",
                                      fontWeight: "800",
                                      backgroundColor: "#1ab64f",
                                      color: "white",
                                      borderColor: "#1ab64f",
                                      margin: "10px",
                                    }}
                                  >
                                    Book Slot
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  </>
                ) : (
                  <>
                    {about ? (
                      <>
                        <div className="efrig dealscroll">
                          {parse(item.vendors[0]?.about)}
                        </div>
                      </>
                    ) : (
                      <>
                        {gallary ? (
                          <>
                            {item?.vendors[0]?.spaimage1 ? (
                              <img
                                src={
                                  "https://spazo.co.in/Vendor/" +
                                  item?.vendors[0]?.spaimage1
                                }
                                width="150px"
                                height="100px"
                                style={{ margin: "10px" }}
                              />
                            ) : (
                              ""
                            )}
                            {item?.vendors[0]?.spaimage2 ? (
                              <img
                                src={
                                  "https://spazo.co.in/Vendor/" +
                                  item?.vendors[0]?.spaimage2
                                }
                                width="150px"
                                height="100px"
                                style={{ margin: "10px" }}
                              />
                            ) : (
                              ""
                            )}
                            {item?.vendors[0]?.spaimage3 ? (
                              <img
                                src={
                                  "https://spazo.co.in/Vendor/" +
                                  item?.vendors[0]?.spaimage3
                                }
                                width="150px"
                                height="100px"
                                style={{ margin: "10px" }}
                              />
                            ) : (
                              ""
                            )}
                            {item?.vendors[0]?.spaimage4 ? (
                              <img
                                src={
                                  "https://spazo.co.in/Vendor/" +
                                  item?.vendors[0]?.spaimage4
                                }
                                width="150px"
                                height="100px"
                                style={{ margin: "10px" }}
                              />
                            ) : (
                              ""
                            )}
                            {item?.vendors[0]?.AdditionalImageArray?.map(
                              (sev) => (
                                <img
                                  src={
                                    "https://spazo.co.in/Vendor/" +
                                    sev?.AdditionalImage
                                  }
                                  width="150px"
                                  height="100px"
                                  style={{ margin: "10px" }}
                                />
                              )
                            )}

                            {item?.service?.map((sev) => (
                              <>
                                {sev?.Service_ImageArray?.map((im) => (
                                  <img
                                    src={
                                      "https://spazo.co.in/Service/" +
                                      im?.Service_Image
                                    }
                                    width="150px"
                                    height="100px"
                                    style={{ margin: "10px" }}
                                  />
                                ))}
                              </>
                            ))}
                          </>
                        ) : (
                          <>
                            {parking ? (
                              <>
                                {item?.vendors[0]?.parking === "4-Wheeler" ? (
                                  <div>
                                    <AiFillCar
                                      style={{
                                        fontSize: "80px",
                                        color: "#e84141",
                                      }}
                                    />
                                    <p style={{ color: "#e84141" }}>
                                      4-Wheeler
                                    </p>
                                  </div>
                                ) : item?.vendors[0]?.parking ===
                                  "2-Wheeler" ? (
                                  <div>
                                    <RiMotorbikeFill
                                      style={{
                                        fontSize: "80px",
                                        color: "#e84141",
                                      }}
                                    />
                                    <p style={{ color: "#e84141" }}>
                                      2-Wheeler
                                    </p>
                                  </div>
                                ) : item?.vendors[0]?.parking === "Both" ? (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        margin: "10px",
                                        gap: "20px",
                                      }}
                                    >
                                      <div>
                                        <RiMotorbikeFill
                                          style={{
                                            fontSize: "80px",
                                            color: "#e84141",
                                          }}
                                        />
                                        <p style={{ color: "#e84141" }}>
                                          2-Wheeler
                                        </p>
                                      </div>
                                      <div>
                                        <AiFillCar
                                          style={{
                                            fontSize: "80px",
                                            color: "#e84141",
                                          }}
                                        />
                                        <p style={{ color: "#e84141" }}>
                                          4-Wheeler
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  item?.vendors[0]?.parking
                                )}
                              </>
                            ) : (
                              <>
                                {reviews ? (
                                  <>
                                    <button
                                      onClick={handleShow}
                                      style={{
                                        backgroundColor: "#e22217",
                                        color: "white",
                                        border: "none",
                                        marginLeft: "20px",
                                        padding: "5px",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <IoAddCircleSharp /> Add Reviews
                                    </button>

                                    <div
                                      style={{
                                        margin: "35px",
                                        justifyContent: "space-around",
                                        display: "flex",
                                        borderBottom: "1px solid",
                                      }}
                                    >
                                      <button
                                        style={{
                                          margin: "10px",
                                          padding: "10px",
                                          border: "none",
                                          fontWeight: "bold",
                                          backgroundColor: "transparent",
                                          color:
                                            displayType === "Spa"
                                              ? "#e22217"
                                              : "black",
                                        }}
                                        onClick={() => setdisplayType("Spa")}
                                      >
                                        Spa Review
                                      </button>
                                      <button
                                        style={{
                                          margin: "10px",
                                          padding: "10px",
                                          border: "none",
                                          fontWeight: "bold",
                                          backgroundColor: "transparent",
                                          color:
                                            displayType === "Therapist"
                                              ? "#e22217"
                                              : "black",
                                        }}
                                        onClick={() =>
                                          setdisplayType("Therapist")
                                        }
                                      >
                                        Therapist Review
                                      </button>
                                    </div>
                                    {displayType === "Spa" ? (
                                      <>
                                        {reviewarray?.map((rev) => (
                                          <>
                                            <div style={{ padding: "20px" }}>
                                              <h6>{rev?.name}</h6>
                                              <p>{rev?.review}</p>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <ReactStars
                                                  count={5}
                                                  value={rev?.rating}
                                                  size={20}
                                                  activeColor="#ffd700"
                                                />
                                                <p
                                                  style={{
                                                    textAlign: "right",
                                                    color: "#e84141",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {moment(
                                                    rev?.createdAt
                                                  ).format("DD-MM-YYYY")}
                                                </p>
                                              </div>
                                            </div>
                                            <hr></hr>
                                          </>
                                        ))}
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          style={{
                                            overflowY: "scroll",
                                            height: "300px",
                                          }}
                                        >
                                          <Row>
                                            {Therapist?.map((item) => (
                                              <Col md={6}>
                                                <div
                                                  style={{
                                                    border: "1px solid gray",
                                                    justifyContent: "center",
                                                    padding: "1%",
                                                    margin: "2%",
                                                  }}
                                                  onClick={() => {
                                                    setTherapistID(item);
                                                  }}
                                                >
                                                  <Row>
                                                    <Col md={2}>
                                                      <div>
                                                        <img
                                                          src={
                                                            "https://spazo.co.in/Employee/" +
                                                            item?.profile
                                                          }
                                                          width={50}
                                                          height={50}
                                                          onClick={() =>
                                                            window.open(
                                                              "https://spazo.co.in/Employee/" +
                                                                item.profile
                                                            )
                                                          }
                                                          style={{
                                                            borderRadius: "50%",
                                                          }}
                                                        />
                                                      </div>
                                                    </Col>
                                                    <Col md={8}>
                                                      <div>
                                                        <p
                                                          style={{
                                                            marginBottom: "0px",
                                                            fontWeight: "bold",
                                                            fontSize: "20px",
                                                          }}
                                                        >
                                                          {item?.name}
                                                        </p>
                                                        <p
                                                          style={{
                                                            marginBottom: "0px",
                                                          }}
                                                        >
                                                          {item?.Specialization}
                                                        </p>
                                                        <div
                                                          style={{
                                                            alignItems:
                                                              "flex-start",
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <ReactStars
                                                            count={5}
                                                            value={item?.therapistreviews?.reduce(
                                                              (a, b) =>
                                                                a + b.rating,
                                                              0
                                                            )}
                                                            size={20}
                                                            activeColor="#ffd700"
                                                          />
                                                        </div>
                                                      </div>
                                                    </Col>
                                                    <Col md={2}>
                                                      <div
                                                        style={{
                                                          justifyContent:
                                                            "center",
                                                          display: "flex",
                                                          alignItems: "center",
                                                          padding: "15px 12px",
                                                        }}
                                                        onClick={() =>
                                                          handleShowTR(item)
                                                        }
                                                      >
                                                        <i class="fas fa-chevron-right"></i>
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Col>
                                            ))}
                                          </Row>
                                        </div>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>

              <div className="col-md-4">
                <div className="book-cart">
                  <div className="book-f">Contact Us</div>
                  <div>
                    <p style={{ textAlign: "center" }}>
                      {item?.vendors[0]?.address}
                    </p>
                    <p style={{ textAlign: "center" }}>
                      - {item?.vendors[0]?.pincode}
                    </p>
                    <p style={{ textAlign: "center" }}>
                      {item?.vendors[0]?.email}
                    </p>
                    <p style={{ textAlign: "center" }}>
                      {item?.vendors[0]?.phoneNumber}
                    </p>
                  </div>
                  <hr />
                  <div style={{ overflow: "hidden" }}>
                    {/* <IoLocationSharp /> */}
                    <a href={item?.vendors[0]?.Glink} target="_blank">
                      {item?.vendors[0]?.Glink}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose} className="modalreview">
          <h3 style={{ color: "#e22217", textAlign: "center" }}>Add Reviews</h3>
          <Modal.Body>
            {ratingsFor === "Spa" ? (
              <div>
                <h5 style={{ textAlign: "center" }}>For Spa</h5>
                <h6 style={{ textAlign: "center" }}>{user?.name}</h6>

                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={24}
                  activeColor="#ffd700"
                />
                <textarea
                  placeholder="Additional Comments..."
                  style={{
                    backgroundColor: "#fbd7d4",
                    border: "1px solid #e22217",
                    borderRadius: "10px",
                    width: "80%",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex",
                  }}
                  onChange={(e) => setreview(e.target.value)}
                />
                <br></br>
                <div
                  style={{
                    width: "80%",
                    justifyContent: "space-between",
                    display: "flex",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Button variant="primary" onClick={handleClose}>
                    Not Now
                  </Button>
                  <Button variant="secondary" onClick={addreview}>
                    Submit Review
                  </Button>
                </div>
              </div>
            ) : ratingsFor === "Therapist" ? (
              <div>
                <h5 style={{ textAlign: "center" }}>For Therapist</h5>
                <h6 style={{ textAlign: "center" }}>{user?.name}</h6>

                {/* <div style={{ overflow: "scroll", height: "500px" }}> */}
                <div>
                  {Therapist?.map((item) => (
                    <div
                      style={{
                        border: "1px solid gray",
                        justifyContent: "center",
                        padding: "1%",
                        margin: "2%",
                        backgroundColor:
                          TherapistID?._id === item?._id ? "#e22217" : "white",
                        color:
                          TherapistID?._id === item?._id ? "white" : "black",
                      }}
                      onClick={() => {
                        setTherapistID(item);
                      }}
                    >
                      <Row>
                        <Col md={2}>
                          <div>
                            <img
                              src={
                                "https://spazo.co.in/Employee/" + item?.profile
                              }
                              width={50}
                              height={50}
                              onClick={() =>
                                window.open(
                                  "https://spazo.co.in/Employee/" + item.profile
                                )
                              }
                              style={{ borderRadius: "50%" }}
                            />
                          </div>
                        </Col>
                        <Col md={8}>
                          <div>
                            <p
                              style={{
                                marginBottom: "0px",
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              {item?.name}
                            </p>
                            <p>{item?.Specialization}</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div
                            style={{
                              justifyContent: "center",
                              display: "flex",
                              alignItems: "center",
                              padding: "15px 12px",
                            }}
                          >
                            <i class="fas fa-chevron-right"></i>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
                <ReactStars
                  count={5}
                  onChange={ratingChanged1}
                  size={24}
                  activeColor="#ffd700"
                />
                <textarea
                  placeholder="Additional Comments..."
                  style={{
                    backgroundColor: "#fbd7d4",
                    border: "1px solid #e22217",
                    borderRadius: "10px",
                    width: "80%",
                    height: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex",
                  }}
                  onChange={(e) => setTherapistreview(e.target.value)}
                />
                <br></br>
                <div
                  style={{
                    width: "80%",
                    justifyContent: "space-between",
                    display: "flex",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Button variant="primary" onClick={handleClose}>
                    Not Now
                  </Button>
                  <Button variant="secondary" onClick={addTherapistreview}>
                    Submit Review
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <img
                  src="./logo.jpg"
                  style={{
                    width: "200px",
                    height: "80px",
                    display: "grid",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
                <div
                  style={{
                    width: "80%",
                    justifyContent: "space-between",
                    display: "flex",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "30px",
                  }}
                >
                  <Button
                    variant="primary"
                    onClick={() => setratingsFor("Spa")}
                  >
                    For Spa
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => setratingsFor("Therapist")}
                  >
                    For Therapist
                  </Button>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>

        <Modal show={TRshow} onHide={handleCloseTR} className="modalreview">
          <h3
            style={{
              color: "white",
              textAlign: "center",
              backgroundColor: "#e22217",
            }}
          >
            Therapist Reviews - {TRdata?.name}
          </h3>
          <Modal.Body>
            <div>
              {TRdata?.therapistreviews?.map((rev) => (
                <>
                  <div
                    style={{ padding: "10px", borderBottom: "1px solid gray" }}
                  >
                    <h6>{rev?.Username}</h6>
                    <p style={{ marginBottom: "0px" }}>{rev?.review}</p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <ReactStars
                        count={5}
                        value={rev?.rating}
                        size={20}
                        activeColor="#ffd700"
                      />
                      <p
                        style={{
                          textAlign: "right",
                          color: "#e84141",
                          fontWeight: 600,
                        }}
                      >
                        {moment(rev?.createdAt).format("DD-MM-YYYY")}
                      </p>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleCloseTR}>Ok</Button>
          </Modal.Footer>
        </Modal>
        <NewFooter />
      </div>
    </>
  );
}

export default Brandproductdetails;
