import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { AiFillPhone, AiTwotoneMail } from "react-icons/ai";
import { BiCurrentLocation } from "react-icons/bi";
import {
  IoBusiness,
  IoLocationSharp,
  IoPersonCircleSharp,
} from "react-icons/io5";
import { RiUserLocationFill } from "react-icons/ri";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import axios from "axios";

function Myprofile() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [edit, setedit] = useState(false);

  const [businessName, setbusinessName] = useState("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phoneNumber, setphoneNumber] = useState();
  const [address, setaddress] = useState("");
  const [pincode, setpincode] = useState();
  const [Glink, setGlink] = useState("");
  const [latitude, setlatitude] = useState();
  const [longitude, setlongitude] = useState();
  const [error, seterror] = useState("");
  const handleGeocode = (address) => {
    const apiKey = "AIzaSyAeVFaPIlv5BTjUqMrBIVRfsM_SvDPegHg"; // Replace with your Google Maps API key

    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address[0]?.label}&key=${apiKey}`
      )
      .then((response) => {
        const { results, status } = response.data;

        if (status === "OK" && results.length > 0) {
          const { lat, lng } = results[0].geometry.location;
          setlatitude(lat);
          setlongitude(lng);
          seterror(null);
        } else {
          seterror("Address not found");
        }
      })
      .catch((error) => {
        seterror("Error occurred while fetching data");
      });
  };

  const updateProfile = async () => {
    try {
      const config = {
        url: "/updateProfile",
        method: "post",
        baseURL: "https://spazo.co.in/api/vendor",
        data: {
          userId: user._id,
          businessName: businessName ? businessName : user?.businessName,
          name: name ? name : user?.name,
          email: email ? email : user?.email,
          phoneNumber: phoneNumber ? phoneNumber : user?.phoneNumber,
          address: address ? address : user?.address,
          pincode: pincode ? pincode : user?.pincode,
          Glink: Glink ? Glink : user?.Glink,
          latitude: address ? latitude : user?.latitude,
          longitude: address ? longitude : user?.longitude,
        },
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          alert("Data Added successfully");
          sessionStorage.setItem("user", JSON.stringify(res.data.user));
          window.location.reload();
        } else {
          alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      alert("Unable to add Details");
    }
  };

  return (
    <>
      <div
        style={{
          // backgroundColor: "#ffda77",
          height: "100vh",
          paddingTop: "8%",
        }}
      >
        <div className="Vprofile">
          <div className="admin-name-text">
            <h4 style={{ textDecoration: "underline", textAlign: "center" }}>
              Profile-Details
            </h4>
          </div>

          {!edit ? (
            <>
              <div className="mt-4">
                <Row>
                  <Col md={6} style={{ color: "#e22217" }}>
                    <IoBusiness color="#e22217" />
                    &nbsp;Business Name
                  </Col>
                  <Col md={6}>{user.businessName}</Col>
                </Row>
                <Row>
                  <Col md={6} style={{ color: "#e22217" }}>
                    <IoPersonCircleSharp color="#e22217" />
                    &nbsp;Name
                  </Col>
                  <Col md={6}>{user.name}</Col>
                </Row>
                <Row>
                  <Col md={6} style={{ color: "#e22217" }}>
                    <AiTwotoneMail />
                    &nbsp;Email Id
                  </Col>
                  <Col md={6}>{user.email}</Col>
                </Row>
                <Row>
                  <Col md={6} style={{ color: "#e22217" }}>
                    <AiFillPhone />
                    &nbsp;Phone Number
                  </Col>
                  <Col md={6}>{user.phoneNumber}</Col>
                </Row>
                <Row>
                  <Col md={6} style={{ color: "#e22217" }}>
                    <BiCurrentLocation />
                    &nbsp;Address
                  </Col>
                  <Col md={6}>{user.address}</Col>
                </Row>
                <Row>
                  <Col md={6} style={{ color: "#e22217" }}>
                    <RiUserLocationFill />
                    &nbsp;pincode
                  </Col>
                  <Col md={6}>{user.pincode}</Col>
                </Row>
                <Row>
                  <Col md={6} style={{ color: "#e22217" }}>
                    <IoLocationSharp />
                    &nbsp;Direction
                  </Col>
                  <Col md={6}>{user.Glink}</Col>
                </Row>
              </div>
              <Button
                variant="secondary"
                style={{
                  margin: "20px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                onClick={() => setedit(!edit)}
              >
                Edit
              </Button>
            </>
          ) : (
            <>
              <div className="mt-4">
                <Row>
                  <Col md={6} style={{ color: "#e22217" }}>
                    <IoBusiness color="#e22217" />
                    &nbsp;Business Name
                  </Col>
                  <Col md={6}>
                    <input
                      style={{ width: "100%" }}
                      placeholder={user?.businessName}
                      onChange={(e) => setbusinessName(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col md={6} style={{ color: "#e22217" }}>
                    <IoPersonCircleSharp color="#e22217" />
                    &nbsp;Name
                  </Col>
                  <Col md={6}>
                    <input
                      style={{ width: "100%" }}
                      placeholder={user?.name}
                      onChange={(e) => setname(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col md={6} style={{ color: "#e22217" }}>
                    <AiTwotoneMail />
                    &nbsp;Email Id
                  </Col>
                  <Col md={6}>
                    <input
                      style={{ width: "100%" }}
                      placeholder={user?.email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col md={6} style={{ color: "#e22217" }}>
                    <AiFillPhone />
                    &nbsp;Phone Number
                  </Col>
                  <Col md={6}>
                    <input
                      style={{ width: "100%" }}
                      type="tele"
                      maxLength={10}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder={user?.phoneNumber}
                      onChange={(e) => setphoneNumber(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col md={6} style={{ color: "#e22217" }}>
                    <BiCurrentLocation />
                    &nbsp;Address
                  </Col>
                  <Col md={6}>
                    <div style={{ border: "1px solid gray" }}>
                      <GooglePlacesAutocomplete
                        className="css-b62m3t-container1"
                        apiKey="AIzaSyACW1po0qU1jptIybBPGdFY-_MrycQPjfk"
                        selectProps={{
                          isMulti: true,
                          placeholder: user?.address,
                          isClearable: true,
                          value: address,
                          onChange: (val) => {
                            setaddress(val);
                            handleGeocode(val);
                          },
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col md={6} style={{ color: "#e22217" }}>
                    <RiUserLocationFill />
                    &nbsp;pincode
                  </Col>
                  <Col md={6}>
                    <input
                      type="tele"
                      maxLength={6}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      style={{ width: "100%" }}
                      placeholder={user?.pincode}
                      onChange={(e) => setpincode(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col md={6} style={{ color: "#e22217" }}>
                    <IoLocationSharp />
                    &nbsp;Direction
                  </Col>
                  <Col md={6}>
                    <input
                      placeholder={user?.Glink}
                      onChange={(e) => setGlink(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
              </div>
              <Button
                variant="secondary"
                style={{
                  margin: "20px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                onClick={updateProfile}
              >
                Update
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Myprofile;
