import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Table from "react-bootstrap/Table";
import exportFromJSON from "export-from-json";
import ReactPaginate from "react-paginate";

function OffdutyTherapist() {
  const formdata = new FormData();
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const [Employee, setEmployee] = useState([]);
  const [show, setShow] = useState(false);
  const [viewdata, setviewdata] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setShow(true);
    setviewdata(item);
  };

  const [show1, setShow1] = useState(false);
  const [canceldata, setcanceldata] = useState({});
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setcanceldata(item);
  };

  const [Bookingrejectionreason, setBookingrejectionreason] = useState("");

  const cancelOrder = async () => {
    if (!Bookingrejectionreason) {
      notify2("Please enter reason for cancelation");
    } else {
      try {
        const config = {
          url: "/cancelOrder",
          method: "post",
          baseURL: "https://spazo.co.in/api/customer",
          headers: { "content-type": "application/json" },
          data: {
            Booking_Id: canceldata?._id,
            Bookingrejectionreason: Bookingrejectionreason,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          notify("Booking cancelation done succesfully");
          window.location.reload();
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          notify1(error.response.data.error);
        }
      }
    }
  };

  useEffect(() => {
    getAllEmployee();
  }, []);

  const getAllEmployee = () => {
    axios
      .get("https://spazo.co.in/api/Vendor/getAllEmployee")
      .then(function (response) {
        console.log("employee", response.data);
        setEmployee(response.data.Employee);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const newdata = Employee?.filter(
    (item) =>
      item?.Duties[item?.Duties?.length - 1]?.workingDate?.split(", ")[0] ===
        moment().format("DD/MM/YYYY") &&
      item?.Duties[item?.Duties?.length - 1]?.Duty === false
  );

  console.log("newdata", newdata);
  const data = [];

  for (let i = 0; i < newdata.length; i++) {
    data.push({
      Emp_ID: newdata[i]?.EmpId,
      Emp_Name: newdata[i]?.name,
      Emp_Contact_number: newdata[i]?.contactnumber,
      Vendor_Name: newdata[i]?.vendor[0]?.Spa_Name,
      Vendor_Contact_Number: newdata[i]?.vendor[0]?.Spa_Contact_Number,
      Vendor_Type: newdata[i]?.vendor[0]?.Type,
    });
  }

  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(data.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = data.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
    }
  };

  const exportType = "xls";

  const [fileName, setfileName] = useState("Booking");

  const ExportToExcel = () => {
    if (fileName) {
      if (data.length != 0) {
        exportFromJSON({ data, fileName, exportType });
        // setfileName("");
      } else {
        alert("There is no data to export");
        // setfileName("");
      }
    } else {
      alert("Enter file name to export");
    }
  };

  const [order, setorder] = useState([]);

  useEffect(() => {
    if (viewdata) {
      const todayorder = viewdata?.orders?.filter(
        (item) => item?.date === moment().format("DD/MM/YYYY")
      );

      const order = todayorder?.filter((item) => {
        console.log("item?.fromTime", item?.fromTime);
        console.log(
          "item?.Duties",
          viewdata?.Duties[viewdata?.Duties?.length - 1]?.workingDate?.split(
            ", "
          )[1]
        );

        const xyz =
          viewdata?.Duties[viewdata?.Duties?.length - 1]?.workingDate?.split(
            ", "
          )[1];
        const [timePart, period] = item?.fromTime.split(" ");
        const [hours, minutes] = timePart.split(":");
        const hour = parseInt(hours, 10);
        const minute = parseInt(minutes, 10);
        var hour1 = period === "PM" && hour < 12 ? hour + 12 : hours;
        var ordertime = hour1 + ":" + minute;
        console.log("ordertime", ordertime);

        const [EtimePart, Eperiod] = xyz.split(" ");
        const [Ehours, Eminutes] = EtimePart.split(":");
        const Ehour = parseInt(Ehours, 10);
        const Eminute = parseInt(Eminutes, 10);
        var Ehour1 = Eperiod === "PM" && Ehour < 12 ? Ehour + 12 : Ehours;
        var Dutytime = Ehour1 + ":" + Eminute;
        console.log("Dutytime", Dutytime);

        return ordertime > Dutytime;
      });
      setorder(order);
    }
  }, [viewdata]);

  console.log("viewdata", viewdata);
  console.log("order", order);
  return (
    <>
      <div className="never-sspa">
        <section>
          <div className="spa-admin-0 mb-2">
            <div className="diff-admin px-3">
              <div className="admin-name-text">
                <h4>Off Duty Therapist</h4>
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={30000}
            closeOnClick
            pauseOnHover
            draggable
          />
          <div
            style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}
          >
            <input
              placeholder="search"
              style={{
                padding: "5px",
                borderRadius: "10px",
              }}
              onChange={handleFilter}
            />
            <button
              style={{
                marginLeft: "30px",
                border: "none",
                color: "white",
                padding: "6px",
                borderRadius: "5px",
                backgroundColor: "#e22217",
              }}
              onClick={() => ExportToExcel()}
            >
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                {/* <AiFillFileWord /> */}
              </span>
              Download Excel
            </button>
            <div
              style={{ overflowX: "scroll", width: "100%", marginTop: "20px" }}
            >
              <Table striped responsive>
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #dee2e6" }}>Sl No</th>
                    <th style={{ border: "1px solid #dee2e6" }}>Profile</th>
                    <th style={{ border: "1px solid #dee2e6" }}>Emp ID</th>
                    <th style={{ border: "1px solid #dee2e6" }}>Emp Name</th>
                    <th style={{ border: "1px solid #dee2e6" }}>
                      Emp Contact number
                    </th>
                    <th style={{ border: "1px solid #dee2e6" }}>Vendor Name</th>
                    <th style={{ border: "1px solid #dee2e6" }}>
                      Vendor Contact Number
                    </th>
                    <th style={{ border: "1px solid #dee2e6" }}>Vendor Type</th>
                    <th style={{ border: "1px solid #dee2e6" }}>View</th>
                  </tr>
                </thead>
                <tbody>
                  {search.length > 0 ? (
                    tableFilter
                      .slice(pagesVisited, pagesVisited + usersPerPage)
                      .map((item, index) => (
                        <>
                          <tr>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {index + 1}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              <img
                                src={
                                  "https://spazo.co.in/Employee/" + item.profile
                                }
                                width={100}
                                height={80}
                                onClick={() =>
                                  window.open(
                                    "https://spazo.co.in/Employee/" +
                                      item.profile
                                  )
                                }
                              />
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.EmpId}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.name}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.contactnumber}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.vendor[0]?.Spa_Name}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.vendor[0]?.Spa_Contact_Number}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.vendor[0]?.Type}
                            </td>
                            <td>
                              <button
                                style={{ border: "none" }}
                                onClick={() => handleShow(item)}
                              >
                                <i
                                  class="fa fa-eye"
                                  aria-hidden="true"
                                  style={{ color: "#e22217" }}
                                ></i>
                              </button>
                            </td>
                          </tr>
                        </>
                      ))
                  ) : (
                    <>
                      {newdata
                        .slice(pagesVisited, pagesVisited + usersPerPage)
                        .map((item, index) => (
                          <tr>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {index + 1}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              <img
                                src={
                                  "https://spazo.co.in/Employee/" + item.profile
                                }
                                width={100}
                                height={80}
                                onClick={() =>
                                  window.open(
                                    "https://spazo.co.in/Employee/" +
                                      item.profile
                                  )
                                }
                              />
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.EmpId}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.name}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.contactnumber}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.vendor[0]?.Spa_Name}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.vendor[0]?.Spa_Contact_Number}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.vendor[0]?.Type}
                            </td>
                            <td>
                              <button
                                style={{ border: "none" }}
                                onClick={() => handleShow(item)}
                              >
                                <i
                                  class="fa fa-eye"
                                  aria-hidden="true"
                                  style={{ color: "#e22217" }}
                                ></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </section>
      </div>

      {/* modal */}

      <Modal show={show} onHide={handleClose} className="servicemodal1">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#e22217" }}>
            Off Duty Therapist
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-spa">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Emp ID : {viewdata?.EmpId}</p>
              <p>Emp Name : {viewdata?.name}</p>
            </div>

            <Table striped responsive>
              <thead style={{ fontWeight: "bold" }}>
                <tr>
                  <td style={{ border: "1px solid #dee2e6" }}>Sl No</td>
                  <td style={{ border: "1px solid #dee2e6" }}>Booking ID</td>
                  <td style={{ border: "1px solid #dee2e6" }}>Slot Date</td>
                  <td style={{ border: "1px solid #dee2e6" }}>From Time</td>
                  <td style={{ border: "1px solid #dee2e6" }}>End Time</td>
                  <td style={{ border: "1px solid #dee2e6" }}>
                    Booking Therapist
                  </td>
                  <td style={{ border: "1px solid #dee2e6" }}>
                    Assigned Therapist
                  </td>
                  <td style={{ border: "1px solid #dee2e6" }}>Action</td>
                </tr>
              </thead>
              <tbody>
                {order?.map((item, index) => (
                  <tr>
                    <td style={{ border: "1px solid #dee2e6" }}>{index + 1}</td>
                    <td style={{ border: "1px solid #dee2e6" }}>{item?._id}</td>
                    <td style={{ border: "1px solid #dee2e6" }}>
                      {item?.date}
                    </td>
                    <td style={{ border: "1px solid #dee2e6" }}>
                      {item?.fromTime}
                    </td>
                    <td style={{ border: "1px solid #dee2e6" }}>
                      {item?.toTime}
                    </td>
                    <td style={{ border: "1px solid #dee2e6" }}>
                      {item?.Therapist}
                    </td>
                    <td style={{ border: "1px solid #dee2e6" }}>
                      {item?.Assined_Therapist}
                    </td>
                    <td style={{ border: "1px solid #dee2e6" }}>
                      {item?.BookingStatus === "Cancelled" ? (
                        <>{item?.BookingStatus}</>
                      ) : item?.Therapist === "No Preference" ? (
                        <p>Waiting for reasign</p>
                      ) : (
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "#e22217",
                            color: "white",
                          }}
                          onClick={() => handleShow1(item)}
                        >
                          send cancellation Mail
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={addbanner}>
            Add
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1} className="servicemodal1">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#e22217" }}>
            Off-Duty Therapist
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="m-5">
            <label style={{ color: "black", fontSize: "16px" }}>
              Enter reason for cancelation
            </label>
            <br></br>
            <textarea
              placeholder="Enter reason for cancelation"
              style={{ width: "450px", height: "100px" }}
              onChange={(e) => setBookingrejectionreason(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={cancelOrder}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OffdutyTherapist;
