import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { BiBuildingHouse } from "react-icons/bi";
import { BsFillFilePersonFill } from "react-icons/bs";
import { IoCallOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Newheader2() {
  const notify2 = (data) => toast.warn(data);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [logout, setlogout] = useState(false);

  const logout1 = () => {
    sessionStorage.removeItem("user");
    alert("Signout Success....!");
    window.location.assign("/");
  };

  const [address, setaddress] = useState("");
  const [type, settype] = useState("");

  console.log("type", type);
  return (
    <>
      <div>
        <ToastContainer
          position="top-center"
          autoClose={30000}
          closeOnClick
          pauseOnHover
          draggable
        />
        <div
          className="row me-0 pt-2 pb-2"
          style={{ boxShadow: "3px 4px 8px #cbc6c6" }}
        >
          <Col md={2}>
            <div
              className="log0"
              style={{ paddingLeft: "20px" }}
              onClick={() => window.location.assign("/NewHome")}
            >
              <img
                src="./logo.jpg"
                style={{ width: "110px", height: "38px" }}
              />
            </div>
          </Col>
          <Col md={10}>
            <Row>
              <Col md={10}>
                <div>
                  <div className="searchh1">
                    <select
                      className="searchselect"
                      style={{ border: "1px solid rgb(217, 212, 208)" }}
                      onChange={(e) => settype(e.target.value)}
                    >
                      <option>select</option>
                      <option value="Spa">Spa</option>
                      <option value="Salon">Salon</option>
                      <option value="Beauty Care Products">
                        Beauty Care Products
                      </option>
                    </select>
                    <div
                      style={{
                        // padding: "3px",
                        // borderRadius: "10px 0px 0px 10px",
                        border: "1px solid rgb(217 212 208)",
                        width: "80%",
                      }}
                    >
                      <GooglePlacesAutocomplete
                        apiKey="AIzaSyAeVFaPIlv5BTjUqMrBIVRfsM_SvDPegHg"
                        selectProps={{
                          isMulti: false,
                          placeholder: "Enter Your Location",
                          isClearable: true,
                          value: address,
                          onChange: (val) => {
                            setaddress(val);
                          },
                        }}
                        style={{
                          width: "100%",
                          padding: "10px",
                          borderRadius: "3px 0px 0px 3px",
                          border: "1px solid gray",
                        }}
                      />
                    </div>
                    {address && type ? (
                      <Link
                        // style={{
                        //   backgroundColor: "#1ab64f",
                        //   color: "white",
                        //   border: "none",
                        //   padding: "10px",
                        //   fontWeight: "bold",
                        //   borderRadius: "0px 10px 10px 0px",
                        // }}
                        className="homesearch"
                        to="/Newproduct"
                        state={{ type: type, address: address?.label }}
                        onClick={() => {
                          settype("");
                          setaddress("");
                        }}
                      >
                        Search
                      </Link>
                    ) : (
                      <button
                        // style={{
                        //   backgroundColor: "#1ab64f",
                        //   color: "white",
                        //   border: "none",
                        //   padding: "10px",
                        //   fontWeight: "bold",
                        //   borderRadius: "0px 10px 10px 0px",
                        // }}
                        className="homesearch"
                        onClick={() =>
                          notify2("Please select type and Enter Address")
                        }
                      >
                        Search
                      </button>
                    )}
                  </div>
                </div>
              </Col>
              <Col
                md={2}
                className="pt-2"
                style={{ borderLeft: "1px solid #eeeeee" }}
              >
                {user ? (
                  <>
                    <div
                      style={{ display: "flex", gap: "10px" }}
                      onClick={() => setlogout(!logout)}
                    >
                      <BsFillFilePersonFill size={25} />
                      <>Welcome, {user?.name}</>
                    </div>
                    {logout ? (
                      <>
                        <div
                          style={{
                            backgroundColor: "#e22217",
                            color: "white",
                            margin: "10px",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                          onClick={() =>
                            window.location.assign("/Customerprofile")
                          }
                        >
                          My-Profile
                        </div>
                        <div
                          style={{
                            backgroundColor: "#e22217",
                            color: "white",
                            margin: "10px",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                          onClick={logout1}
                        >
                          Logout
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <div
                    style={{ display: "flex", gap: "10px" }}
                    onClick={() => window.location.assign("/login")}
                  >
                    <BsFillFilePersonFill size={25} />

                    <p
                      style={{
                        marginBottom: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      Login / Signup
                    </p>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    </>
  );
}

export default Newheader2;
