import React from "react";
import "../../Styles/login.css";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { Select, Space } from "antd";
import { Country, State, City } from "country-state-city";
import axios from "axios";
import { useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Business() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const Input = styled("input")({
    display: "none",
  });

  document.title = "SPA-Business Account";
  const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    "& > :not(style) + :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    alert("You have entered an invalid email address!");
    return false;
  }

  function validatename(inputtxt) {
    var phoneno = /^[a-zA-Z ]{2,30}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid name!");
      return false;
    }
  }

  function phonenumber(inputtxt) {
    var phoneno = /^[6-9]\d{9}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid mobile number!");
      return false;
    }
  }

  function CheckPassword(inputtxt) {
    var decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    if (inputtxt.match(decimal)) {
      return true;
    } else {
      alert(
        "Password must be 8 letter, contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character!"
      );
      return false;
    }
  }

  const formdata = new FormData();
  const [login, setlogin] = useState(true);

  const [loginEmail, setloginEmail] = useState("");
  const [loginPassword, setloginPassword] = useState("");

  const [profile, setprofile] = useState("");
  const [logo, setlogo] = useState("");
  const [businessName, setbusinessName] = useState("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phoneNumber, setphoneNumber] = useState();
  const [password, setpassword] = useState("");
  const [cpassword, setcpassword] = useState("");
  const [address, setaddress] = useState("");
  const [pincode, setpincode] = useState();
  const [Glink, setGlink] = useState("");
  const [check, setcheck] = useState(false);

  const [loginPasswordShown, setloginPasswordShown] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [cpasswordShown, setcpasswordShown] = useState(false);

  const toggleloginPassword = () => {
    setloginPasswordShown(!loginPasswordShown);
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPassword = () => {
    setcpasswordShown(!cpasswordShown);
  };

  const [latitude, setlatitude] = useState();
  const [longitude, setlongitude] = useState();
  const [error, seterror] = useState("");
  const handleGeocode = (address) => {
    const apiKey = "AIzaSyAeVFaPIlv5BTjUqMrBIVRfsM_SvDPegHg"; // Replace with your Google Maps API key

    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address?.label}&key=${apiKey}`
      )
      .then((response) => {
        const { results, status } = response.data;

        if (status === "OK" && results.length > 0) {
          const { lat, lng } = results[0].geometry.location;
          setlatitude(lat);
          setlongitude(lng);
          seterror(null);
        } else {
          seterror("Address not found");
        }
      })
      .catch((error) => {
        seterror("Error occurred while fetching data");
      });
  };

  console.log("address", address?.label);
  console.log("latitude", latitude, "longitude", longitude, "error", error);

  console.log("profile", logo);
  const Vendorsignup = async () => {
    if (!profile) {
      notify2("Please Upload your Profile Photo");
      // alert("Please Upload your Profile Photo");
    } else if (!logo) {
      notify2("Please Upload your Business logo");
    } else if (
      !businessName ||
      !name ||
      !email ||
      !phoneNumber ||
      !password ||
      !cpassword ||
      !address ||
      !pincode ||
      !Glink
    ) {
      notify2("Please Fill All The Field");
      // alert("Please Fill All The Field");
    } else if (!check) {
      notify2("Please Read Terms and Conditions");
      // alert("Please Read Terms and Conditions");
    } else {
      formdata.append("profile", profile);
      formdata.append("logo", logo);
      formdata.append("businessName", businessName);
      formdata.append("name", name);
      formdata.append("email", email);
      formdata.append("phoneNumber", phoneNumber);
      formdata.append("password", password);
      formdata.append("cpassword", cpassword);
      formdata.append(
        "address",
        address?.label.toString() ? address?.label.toString() : "Test"
      );
      formdata.append("latitude", latitude);
      formdata.append("longitude", longitude);

      formdata.append("pincode", pincode);
      formdata.append("Glink", Glink);

      try {
        if (
          validatename(name) &&
          ValidateEmail(email) &&
          phonenumber(phoneNumber) &&
          CheckPassword(password)
        ) {
          const config = {
            url: "/vendorReg",
            method: "post",
            baseURL: "https://spazo.co.in/api/vendor",
            // headers: { "content-type": "application/json" },
            data: formdata,
          };
          let res = await axios(config);
          if (res.status === 200) {
            notify("Signup Success, Please login");
            // alert("Signup Success, Please login");
            window.location.assign("/business");
          }
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          notify1(error.response.data.error);
          // alert(error.response.data.error);
        }
      }
    }
  };

  const Vendorlogin = async (e) => {
    if (!loginEmail || !loginPassword) {
      notify2("Please Fill All The Field");
      // alert("Please Fill All The Field");
    } else {
      try {
        const config = {
          url: "/vendorLogin",
          method: "post",
          baseURL: "https://spazo.co.in/api/vendor",
          headers: { "content-type": "application/json" },
          data: {
            email: loginEmail,
            password: loginPassword,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          if (res.data.user.isBlocked) {
            notify2(
              "You are blocked by Admin, Due to " + res.data.user.blockreason
            );
            // alert(
            //   "You are blocked by Admin, Due to " + res.data.user.blockreason
            // );
          } else {
            notify("Login Success");
            // alert("Login Success");
            sessionStorage.setItem("user", JSON.stringify(res.data.user));
            if (res.data.user.update === true) {
              window.location.assign("/Vendordashboard");
            } else {
              window.location.assign("/VHome");
            }
          }
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          notify1(error.response.data.error);
          // alert(error.response.data.error);
        }
      }
    }
  };

  console.log("address", address);

  return (
    <>
      {/* <div className="login">
        <div className="row me-0">
          <div
            className="col-md-6 p-0"
            style={{ flexWrap: "nowrap", overflowY: "scroll", height: "600px" }}
          >
            <div className="fresh" style={{ padding: "20px" }}>
              <img
                src="./logo.jpg"
                alt="logo"
                style={{ width: "167px", height: "67px" }}
              />
              <div className="customers">
                <p
                  style={{
                    color: "#e22217",
                    fontWeight: "bold",
                    marginBottom: "0px",
                  }}
                >
                  SPA for Vendor
                </p>
                <p>Create Business an account .</p>
              </div>
              {login ? (
                <>
                  <div style={{ marginTop: "10%" }}>
                    <div className="cust-login mb-3">
                      <label>Enter your Email address</label>
                      <br />
                      <input
                        type="email"
                        placeholder="Enter your Email address"
                        onChange={(e) => setloginEmail(e.target.value)}
                      />
                    </div>
                    <div className="cust-login mb-3">
                      <label>Enter your Password</label>
                      <br />
                      <input
                        type={loginPasswordShown ? "text" : "password"}
                        placeholder="Enter your Password"
                        onChange={(e) => setloginPassword(e.target.value)}
                      />
                      {loginPasswordShown ? (
                        <button
                          onClick={toggleloginPassword}
                          style={{
                            border: "none",
                            backgroundColor: "white",
                            marginLeft: "-27px",
                            paddingRight: "10px",
                          }}
                        >
                          <i
                            class="fa fa-eye-slash"
                            aria-hidden="true"
                            style={{ color: "#e22217" }}
                          ></i>
                        </button>
                      ) : (
                        <button
                          onClick={toggleloginPassword}
                          style={{
                            border: "none",
                            backgroundColor: "white",
                            marginLeft: "-27px",
                            paddingRight: "10px",
                          }}
                        >
                          <i
                            class="fa fa-eye"
                            aria-hidden="true"
                            style={{ color: "#e22217" }}
                          ></i>
                        </button>
                      )}
                    </div>
                    <div className="cust-login-0 mb-3">
                      <button onClick={Vendorlogin}>Login</button>
                    </div>
                    <p style={{ textAlign: "center", marginBottom: "0px" }}>
                      Don't have a account ?
                    </p>
                    <a
                      onClick={() => setlogin(!login)}
                      style={{ textAlign: "center", display: "block" }}
                    >
                      Sign up now
                    </a>
                  </div>
                </>
              ) : (
                <div style={{ marginTop: "10%" }}>
                  <div style={{ gap: "20px" }}>
                    <label>upload Your Photo (Owner)</label>
                    <label htmlFor="pro" style={{ marginLeft: "9%" }}>
                      <Input
                        accept="image/*"
                        id="pro"
                        type="file"
                        onChange={(e) => setprofile(e.target.files[0])}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <img
                          src={
                            profile
                              ? URL.createObjectURL(profile)
                              : "https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
                            //  `https://otutee.in/${

                            //   }`
                          }
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <PhotoCamera
                          style={{
                            color: "black",
                            margin: "40px 0px 0px -20px",
                          }}
                        />
                      </IconButton>
                    </label>
                  </div>
                  <div className="cust-login mb-2">
                    <label>Enter name of your business</label>
                    <br />
                    <input
                      type="text"
                      placeholder="Enter name of your business"
                      onChange={(e) => setbusinessName(e.target.value)}
                    />
                  </div>
                  <div className="cust-login mb-2">
                    <label>Enter your name</label>
                    <br />
                    <input
                      type="text"
                      placeholder="Enter your name"
                      onChange={(e) => setname(e.target.value)}
                    />
                  </div>
                  <div className="cust-login mb-2">
                    <label>Enter your eamil address</label>
                    <br />
                    <input
                      type="text"
                      placeholder="Enter your eamil address"
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </div>
                  <div className="cust-login mb-2">
                    <label>Enter your Mobile Number</label>
                    <br />
                    <input
                      type="tele"
                      maxLength={10}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Enter your Mobile Number"
                      onChange={(e) => setphoneNumber(e.target.value)}
                    />
                  </div>
                  <div className="cust-login mb-2">
                    <label>Enter your Password</label>
                    <br />
                    <input
                      type={passwordShown ? "text" : "password"}
                      placeholder="Enter your Password"
                      onChange={(e) => setpassword(e.target.value)}
                    />
                    {passwordShown ? (
                      <button
                        onClick={togglePassword}
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          marginLeft: "-27px",
                          paddingRight: "10px",
                        }}
                      >
                        <i
                          class="fa fa-eye-slash"
                          aria-hidden="true"
                          style={{ color: "#e22217" }}
                        ></i>
                      </button>
                    ) : (
                      <button
                        onClick={togglePassword}
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          marginLeft: "-27px",
                          paddingRight: "10px",
                        }}
                      >
                        <i
                          class="fa fa-eye"
                          aria-hidden="true"
                          style={{ color: "#e22217" }}
                        ></i>
                      </button>
                    )}
                  </div>
                  <div className="cust-login mb-2">
                    <label>Enter your Confirm Password</label>
                    <br />
                    <input
                      type={cpasswordShown ? "text" : "password"}
                      placeholder="Enter your Confirm Password"
                      onChange={(e) => setcpassword(e.target.value)}
                    />
                    {cpasswordShown ? (
                      <button
                        onClick={toggleConfirmPassword}
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          marginLeft: "-27px",
                          paddingRight: "10px",
                        }}
                      >
                        <i
                          class="fa fa-eye-slash"
                          aria-hidden="true"
                          style={{ color: "#e22217" }}
                        ></i>
                      </button>
                    ) : (
                      <button
                        onClick={toggleConfirmPassword}
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          marginLeft: "-27px",
                          paddingRight: "10px",
                        }}
                      >
                        <i
                          class="fa fa-eye"
                          aria-hidden="true"
                          style={{ color: "#e22217" }}
                        ></i>
                      </button>
                    )}
                  </div>
                  <div className="cust-login mb-2">
                    <label>Select Your Address</label>
                    <br />
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyACW1po0qU1jptIybBPGdFY-_MrycQPjfk"
                      selectProps={{
                        isMulti: true,
                        placeholder: "Select Area",
                        isClearable: true,
                        value: address,
                        onChange: (val) => {
                          setaddress(val);
                        },
                      }}
                      className="geolocation"
                    />
                  </div>
                  <div className="cust-login mb-2">
                    <label>Enter your Pincode</label>
                    <br />
                    <input
                      placeholder="Enter your Pincode"
                      maxLength={6}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => setpincode(e.target.value)}
                    />
                  </div>
                  <div className="cust-login mb-2">
                    <label>Enter your Google Location Link</label>
                    <br />
                    <input
                      type="text"
                      placeholder="Enter your Google Location Link"
                      onChange={(e) => setGlink(e.target.value)}
                    />
                  </div>
                  <div className="mb-3 mt-3">
                    <input type="checkbox" onChange={() => setcheck(!check)} />
                    <label>
                      <a href="#">Agree to the Terms & Conditions</a>
                    </label>
                  </div>
                  <div className="cust-login-0 mb-2">
                    <button onClick={Vendorsignup}>Register now</button>
                  </div>
                  <p style={{ textAlign: "center", marginBottom: "0px" }}>
                    Already have a account
                  </p>
                  <a
                    href="#"
                    onClick={() => setlogin(!login)}
                    style={{
                      textAlign: "center",
                      display: "block",
                      marginBottom: "20px",
                    }}
                  >
                    Sign in now
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 p-0">
            <div className="spa-login">
              <img
                src="../../Assets/images/register.jpg"
                alt="spa-login"
                style={{ width: "100%", minHeight: "40rem", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div> */}
      <ToastContainer
        position="top-center"
        autoClose={30000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <div className="admincontainer">
        <img
          src="../../Assets/images/loginimage1.jpg"
          alt="Snow"
          style={{ width: "100%", height: "100vh" }}
        />

        {login ? (
          <div className="top-right">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <img
                src="./logo.jpg"
                alt="logo"
                style={{ width: "167px", height: "67px" }}
              />
            </div>

            <div style={{ marginTop: "10%" }}>
              <div className="cust-login1 mb-3">
                {/* <label>Enter your Email address</label>
                <br /> */}
                <input
                  type="email"
                  placeholder="Enter your Email address"
                  onChange={(e) => setloginEmail(e.target.value)}
                />
              </div>
              <div className="cust-login1 mb-3">
                {/* <label>Enter your Password</label>
                <br /> */}
                <input
                  type={loginPasswordShown ? "text" : "password"}
                  placeholder="Enter your Password"
                  onChange={(e) => setloginPassword(e.target.value)}
                />
                {loginPasswordShown ? (
                  <button
                    onClick={toggleloginPassword}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      marginLeft: "-27px",
                      paddingRight: "10px",
                    }}
                  >
                    <i
                      class="fa fa-eye"
                      aria-hidden="true"
                      style={{ color: "#e22217" }}
                    ></i>
                  </button>
                ) : (
                  <button
                    onClick={toggleloginPassword}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      marginLeft: "-27px",
                      paddingRight: "10px",
                    }}
                  >
                    <i
                      class="fa fa-eye-slash"
                      aria-hidden="true"
                      style={{ color: "#e22217" }}
                    ></i>
                  </button>
                )}
              </div>
              <div className="cust-login-0 mb-3">
                <button onClick={Vendorlogin}>Login</button>
              </div>
              {/* <p style={{ textAlign: "center", marginBottom: "0px" }}>
                Don't have a account ?
              </p>
              <a
                href="#"
                onClick={() => setlogin(!login)}
                style={{ textAlign: "center", display: "block" }}
              >
                Sign up now
              </a> */}

              <div
                style={{
                  gap: "10px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <a href="#" onClick={() => setlogin(!login)}>
                  Create new account
                </a>
                <a
                  href="#"
                  onClick={() => window.location.assign("/VendorEnterEmail")}
                >
                  Forgot Password..?
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="top-right1">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <img
                src="./logo.jpg"
                alt="logo"
                style={{ width: "167px", height: "67px" }}
              />
            </div>
            <div className="businessscroll">
              <div style={{ gap: "20px" }}>
                <label style={{ color: "gray" }}>
                  upload Your Photo (Owner) *
                </label>
                <label htmlFor="pro" style={{ marginLeft: "9%" }}>
                  <Input
                    accept="image/*"
                    id="pro"
                    type="file"
                    onChange={(e) => setprofile(e.target.files[0])}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <img
                      src={
                        profile
                          ? URL.createObjectURL(profile)
                          : "https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
                        //  `https://otutee.in/${

                        //   }`
                      }
                      alt="avathar"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50px",
                      }}
                    />
                    <PhotoCamera
                      style={{
                        color: "black",
                        margin: "40px 0px 0px -20px",
                      }}
                    />
                  </IconButton>
                </label>
              </div>

              <div style={{ gap: "20px" }}>
                <label style={{ color: "gray" }}>
                  upload Your Business logo *
                </label>
                <label htmlFor="pro1" style={{ marginLeft: "9%" }}>
                  <Input
                    accept="image/*"
                    id="pro1"
                    type="file"
                    onChange={(e) => setlogo(e.target.files[0])}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <img
                      src={
                        logo
                          ? URL.createObjectURL(logo)
                          : "https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
                        //  `https://otutee.in/${

                        //   }`
                      }
                      alt="avathar"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50px",
                      }}
                    />
                    <PhotoCamera
                      style={{
                        color: "black",
                        margin: "40px 0px 0px -20px",
                      }}
                    />
                  </IconButton>
                </label>
              </div>
              <div className="cust-login1 mb-2">
                <input
                  type="text"
                  placeholder="Enter name of your business *"
                  onChange={(e) => setbusinessName(e.target.value)}
                />
              </div>
              <div className="cust-login1 mb-2">
                <input
                  type="text"
                  placeholder="Enter your name *"
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
              <div className="cust-login1 mb-2">
                <input
                  type="text"
                  placeholder="Enter your email address *"
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
              <div className="cust-login1 mb-2">
                <input
                  type="tele"
                  maxLength={10}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Enter your Mobile Number *"
                  onChange={(e) => setphoneNumber(e.target.value)}
                />
              </div>
              <div className="cust-login1 mb-2">
                <input
                  type={passwordShown ? "text" : "password"}
                  placeholder="Enter your Password *"
                  onChange={(e) => setpassword(e.target.value)}
                />
                {passwordShown ? (
                  <button
                    onClick={togglePassword}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      marginLeft: "-27px",
                      paddingRight: "10px",
                    }}
                  >
                    <i
                      class="fa fa-eye"
                      aria-hidden="true"
                      style={{ color: "#e22217" }}
                    ></i>
                  </button>
                ) : (
                  <button
                    onClick={togglePassword}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      marginLeft: "-27px",
                      paddingRight: "10px",
                    }}
                  >
                    <i
                      class="fa fa-eye-slash"
                      aria-hidden="true"
                      style={{ color: "#e22217" }}
                    ></i>
                  </button>
                )}
              </div>
              <div className="cust-login1 mb-2">
                <input
                  type={cpasswordShown ? "text" : "password"}
                  placeholder="Enter your Confirm Password *"
                  onChange={(e) => setcpassword(e.target.value)}
                />
                {cpasswordShown ? (
                  <button
                    onClick={toggleConfirmPassword}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      marginLeft: "-27px",
                      paddingRight: "10px",
                    }}
                  >
                    <i
                      class="fa fa-eye"
                      aria-hidden="true"
                      style={{ color: "#e22217" }}
                    ></i>
                  </button>
                ) : (
                  <button
                    onClick={toggleConfirmPassword}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      marginLeft: "-27px",
                      paddingRight: "10px",
                    }}
                  >
                    <i
                      class="fa fa-eye-slash"
                      aria-hidden="true"
                      style={{ color: "#e22217" }}
                    ></i>
                  </button>
                )}
              </div>
              <div
                className="mb-2"
                style={{
                  borderBottom: "1px solid gray",
                  width: "69%",
                  // overflow: "hidden",
                  alignSelf: "center",
                  display: "inline-flex",
                }}
              >
                {/* <GooglePlacesAutocomplete
                  apiKey="AIzaSyACW1po0qU1jptIybBPGdFY-_MrycQPjfk"
                  selectProps={{
                    isMulti: true,
                    placeholder: "Select Area",
                    isClearable: true,
                    value: address,
                    onChange: (val) => {
                      setaddress(val);
                    },
                  }}
                  className="geolocation"
                /> */}
                <GooglePlacesAutocomplete
                  // placeholder="Select Area"
                  apiKey="AIzaSyAeVFaPIlv5BTjUqMrBIVRfsM_SvDPegHg"
                  selectProps={{
                    isMulti: false,
                    placeholder: "Select Location",
                    isClearable: true,
                    value: address,
                    onChange: (val) => {
                      setaddress(val);
                      handleGeocode(val);
                    },
                  }}
                />
              </div>
              <div className="cust-login1 mb-2">
                <input
                  placeholder="Enter your Pincode *"
                  maxLength={6}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => setpincode(e.target.value)}
                />
              </div>
              <div className="cust-login1 mb-2">
                <input
                  type="text"
                  placeholder="Enter your Google Location Link *"
                  onChange={(e) => setGlink(e.target.value)}
                />
              </div>
              <div className="mb-3 mt-3">
                <input type="checkbox" onChange={() => setcheck(!check)} />
                <label>
                  <a href="#">Agree to the Terms & Conditions</a>
                </label>
              </div>
              <div className="cust-login-0 mb-2">
                <button onClick={Vendorsignup}>Register now</button>
              </div>
              <p style={{ textAlign: "center", marginBottom: "0px" }}>
                Already have a account
              </p>
              <a
                href="#"
                onClick={() => setlogin(!login)}
                style={{
                  textAlign: "center",
                  display: "block",
                  marginBottom: "20px",
                }}
              >
                Sign in now
              </a>
            </div>
          </div>
        )}

        <div className="top-left">
          <div className="customers">
            <h5
              style={{ color: "white", fontSize: "38px", fontWeight: "bold" }}
            >
              SPAZO for Vendor
            </h5>
            <p style={{ color: "white", fontSize: "20px" }}>
              Create an account to make your business better.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Business;
