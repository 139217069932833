import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Subadmindetails() {
  const formdata = new FormData();
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);

  const [show, setShow] = useState(false);
  const [deletedata, setdeletedata] = useState({});

  const [edit, setedit] = useState(false);
  const [editdata, seteditdata] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setdeletedata(data);
  };

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    seteditdata(item);
  };

  const [show2, setShow2] = useState(false);
  const [subadminedit, setsubadminedit] = useState({});

  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setsubadminedit(item);
  };

  const [show5, setShow5] = useState(false);
  const [subadminB, setsubadminB] = useState({});

  const handleClose5 = () => setShow5(false);
  const handleShow5 = (item) => {
    setShow5(true);
    setsubadminB(item);
  };

  const [show6, setShow6] = useState(false);
  const [subadminUB, setsubadminUB] = useState({});

  const handleClose6 = () => setShow6(false);
  const handleShow6 = (item) => {
    setShow6(true);
    setsubadminUB(item);
  };

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [contactnum, setcontactnum] = useState();
  const [password, setpassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setPasswordVisible(!passwordVisible);
  };

  const [banner1, setbanner1] = useState(false);
  const [banner2, setbanner2] = useState(false);
  const [banner3, setbanner3] = useState(false);
  const [category, setcategory] = useState(false);
  const [Adminservice, setAdminservice] = useState(false);
  const [Advantages, setAdvantages] = useState(false);
  const [Promocode, setPromocode] = useState(false);
  const [TopBrands, setTopBrands] = useState(false);
  const [StarHotels, setStarHotels] = useState(false);
  const [MallandShopping, setMallandShopping] = useState(false);
  const [OffDutyTherapist, setOffDutyTherapist] = useState(false);
  const [Customers, setCustomers] = useState(false);
  const [Message, setMessage] = useState(false);
  //spa
  const [Vendor, setVendor] = useState(false);
  const [editVendorRequest, seteditVendorRequest] = useState(false);
  const [AdminTherapist, setAdminTherapist] = useState(false);
  const [SpaDeleteTherapist, setSpaDeleteTherapist] = useState(false);
  const [service, setservice] = useState(false);
  const [Reviews, setReviews] = useState(false);
  const [Booking, setBooking] = useState(false);
  const [Vendorpercentage, setVendorpercentage] = useState(false);
  const [Accountdetails, setAccountdetails] = useState(false);
  const [DaywiseAccountdetails, setDaywiseAccountdetails] = useState(false);

  //salon
  const [SalonVendor, setSalonVendor] = useState(false);
  const [SaloneditVendorRequest, setSaloneditVendorRequest] = useState(false);
  const [SalonAdminTherapist, setSalonAdminTherapist] = useState(false);
  const [SalonDeleteTherapist, setSalonDeleteTherapist] = useState(false);
  const [Salonservice, setSalonservice] = useState(false);
  const [SalonReviews, setSalonReviews] = useState(false);
  const [SalonBooking, setSalonBooking] = useState(false);
  const [SalonVendorpercentage, setSalonVendorpercentage] = useState(false);
  const [SalonAccountdetails, setSalonAccountdetails] = useState(false);
  const [SalonDaywiseAccountdetails, setSalonDaywiseAccountdetails] =
    useState(false);

  useEffect(() => {
    if (Object.keys(editdata)?.length > 0) {
      setbanner1(editdata?.banner1);
      setcategory(editdata?.category);
      setAdminservice(editdata?.Adminservice);
      setAdvantages(editdata?.Advantages);
      setPromocode(editdata?.Promocode);

      setTopBrands(editdata?.TopBrands);
      setStarHotels(editdata?.StarHotels);
      setMallandShopping(editdata?.MallandShopping);
      setOffDutyTherapist(editdata?.OffDutyTherapist);

      setCustomers(editdata?.Customers);
      setMessage(editdata?.Message);
      setVendor(editdata?.Vendor);
      seteditVendorRequest(editdata?.editVendorRequest);
      setAdminTherapist(editdata?.AdminTherapist);
      setSpaDeleteTherapist(editdata?.SpaDeleteTherapist);
      setservice(editdata?.service);
      setReviews(editdata?.Reviews);
      setBooking(editdata?.Booking);
      setVendorpercentage(editdata?.Vendorpercentage);
      setAccountdetails(editdata?.Accountdetails);
      setDaywiseAccountdetails(editdata?.DaywiseAccountdetails);
      setSalonVendor(editdata?.SalonVendor);
      setSaloneditVendorRequest(editdata?.SaloneditVendorRequest);
      setSalonAdminTherapist(editdata?.SalonAdminTherapist);
      setSalonDeleteTherapist(editdata?.SalonDeleteTherapist);
      setSalonservice(editdata?.Salonservice);
      setSalonReviews(editdata?.SalonReviews);
      setSalonBooking(editdata?.SalonBooking);
      setSalonVendorpercentage(editdata?.SalonVendorpercentage);
      setSalonAccountdetails(editdata?.SalonAccountdetails);
      setSalonDaywiseAccountdetails(editdata?.SalonDaywiseAccountdetails);
    }
  }, [editdata]);

  const [data, setdata] = useState([]);

  useEffect(() => {
    getallsubadmin();
  }, []);

  const getallsubadmin = () => {
    axios
      .get("https://spazo.co.in/api/admin/getallsubadmin")
      .then(function (response) {
        console.log(response.data);
        setdata(response.data.subadmin);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editsubadminaccess = async () => {
    try {
      const config = {
        url: "/editsubadminaccess",
        method: "post",
        baseURL: "https://spazo.co.in/api/admin",
        data: {
          id: editdata?._id,
          banner1: banner1,
          category: category,
          Adminservice: Adminservice,
          Advantages: Advantages,
          Promocode: Promocode,
          TopBrands: TopBrands,
          StarHotels: StarHotels,
          MallandShopping: MallandShopping,
          OffDutyTherapist: OffDutyTherapist,
          Customers: Customers,
          Message: Message,

          //spa
          Vendor: Vendor,
          editVendorRequest: editVendorRequest,
          AdminTherapist: AdminTherapist,
          SpaDeleteTherapist: SpaDeleteTherapist,
          service: service,
          Reviews: Reviews,
          Booking: Booking,
          Vendorpercentage: Vendorpercentage,
          Accountdetails: Accountdetails,
          DaywiseAccountdetails: DaywiseAccountdetails,
          //salon

          SalonVendor: SalonVendor,
          SaloneditVendorRequest: SaloneditVendorRequest,
          SalonAdminTherapist: SalonAdminTherapist,
          SalonDeleteTherapist: SalonDeleteTherapist,
          Salonservice: Salonservice,
          SalonReviews: SalonReviews,
          SalonBooking: SalonBooking,
          SalonVendorpercentage: SalonVendorpercentage,
          SalonAccountdetails: SalonAccountdetails,
          SalonDaywiseAccountdetails: SalonDaywiseAccountdetails,
        },
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          notify("Data Updated successfully");
          // alert("Data Added successfully");
          window.location.reload();
          getallsubadmin();
          handleClose1();
        } else {
          notify1(res.status.error);
          // alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      notify1("Unable to add Details, Please try after some time");
      // alert("Unable to add Details");
    }
  };

  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    notify1("You have entered an invalid email address!");
    return false;
  }

  function validatename(inputtxt) {
    var phoneno = /^[a-zA-Z ]{2,30}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      notify1("You have entered an invalid name!");
      return false;
    }
  }

  function phonenumbe(inputtxt) {
    const ph = inputtxt?.toString();
    var phoneno = /^[6-9]\d{9}$/;
    if (ph.match(phoneno)) {
      return true;
    } else {
      toast.show("You have entered an invalid mobile number!", {
        type: "warning",
        placement: "top",
      });
      return false;
    }
  }

  function CheckPassword(inputtxt) {
    var decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    if (inputtxt.match(decimal)) {
      return true;
    } else {
      notify1(
        "Password must contain at least one lowercase letter, one uppercase letter, one numeric digit,one special character and minimum 8 Characters!"
      );
      return false;
    }
  }

  const editsubadmin = async () => {
    if (!name && !email && !contactnum && !password) {
      notify1("There is no changes to update");
    } else {
      try {
        if (
          validatename(name ? name : subadminedit?.fullName) &&
          ValidateEmail(email ? email : subadminedit?.email) &&
          phonenumbe(contactnum ? contactnum : subadminedit?.mobileno) &&
          (password ? CheckPassword(password) : true)
        ) {
          const config = {
            url: "/editsubadmin",
            method: "post",
            baseURL: "https://spazo.co.in/api/admin",
            data: {
              id: subadminedit?._id,
              name: name ? name : subadminedit?.fullName,
              email: email ? email : subadminedit?.email,
              contactnum: contactnum ? contactnum : subadminedit?.mobileno,
              password: password ? password : subadminedit?.pwd,
            },
          };
          await axios(config).then(function (res) {
            if ((res.status = 200)) {
              notify("Data Updated successfully");
              window.location.reload();
              getallsubadmin();
              handleClose1();
            } else {
              notify1(res.status.error);
              // alert(res.status.error);
            }
          });
        }
      } catch (error) {
        console.log(error);
        notify1("Unable to add Details, Please try after some time");
        // alert("Unable to add Details");
      }
    }
  };

  const postdeletesubadmin = async () => {
    try {
      const config = {
        url: "/postdeletesubadmin/" + deletedata?._id,
        method: "post",
        baseURL: "https://spazo.co.in/api/admin",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          notify("Data deleted successfully");
          // alert("Data deleted successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1000); // Adjust delay as needed
          // getallsubadmin();
        } else {
          notify1(res.status.error);
          // alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      notify1("Unable to deleted Details, Please try after some time");
      // alert("Unable to add Details");
    }
  };

  const BlockSubadmin = async (data) => {
    try {
      const config = {
        url: "/BlockSubadmin",
        method: "post",
        baseURL: "https://spazo.co.in/api/admin",
        data: {
          id: data?._id,
        },
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          notify("Blocked Successfully");
          window.location.reload();
        } else {
          notify1(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      notify1("Unable to add Details");
    }
  };

  const UnBlockSubadmin = async (data) => {
    try {
      const config = {
        url: "/UnBlockSubadmin",
        method: "post",
        baseURL: "https://spazo.co.in/api/admin",
        data: {
          id: data?._id,
        },
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          notify("Un-Blocked Successfully");
          window.location.reload();
        } else {
          notify1(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      notify1("Unable to add Details");
    }
  };

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "3",
        value: 3,
      },
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },

    {
      dataField: "fullName",
      text: "Name",
      formatter: (value, row) => <p>{row?.fullName ? row?.fullName : "-"}</p>,
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (value, row) => <p>{row?.email ? row?.email : "-"}</p>,
    },
    {
      dataField: "mobileno",
      text: "Contact Number",
      formatter: (value, row) => <p>{row?.mobileno ? row?.mobileno : ""}</p>,
    },
    {
      dataField: "type",
      text: "Action",
      formatter: (value, row) => (
        <div
          style={{
            display: "flex",
            gap: "21px",
          }}
        >
          <span className="dite" onClick={() => handleShow2(row)}>
            <i class="fas fa-user-edit"></i>
          </span>
          <span className="dite" onClick={() => handleShow1(row)}>
            <i
              class="fa fa-eye"
              aria-hidden="true"
              style={{ color: "white" }}
            ></i>
          </span>
          <span className="dite">
            <RiDeleteBin2Fill onClick={() => handleShow(row)} />
          </span>
        </div>
      ),
    },
    {
      dataField: "isBlocked",
      text: "Action",
      formatter: (value, row) => (
        <>
          {row?.isBlocked ? (
            <>
              <div style={{ gap: "10px", display: "flex" }}>
                <button
                  style={{
                    backgroundColor: "#e22217",
                    color: "white",
                    border: "none",
                    padding: "10px",
                  }}
                  onClick={() => handleShow6(row)}
                >
                  Unblock
                </button>
              </div>
            </>
          ) : (
            <button
              style={{
                backgroundColor: "Green",
                color: "white",
                border: "none",
                padding: "10px",
              }}
              onClick={() => handleShow5(row)}
            >
              Block
            </button>
          )}
        </>
      ),
    },
  ];

  console.log("edit", edit);

  return (
    <>
      <div className="never-sspa">
        <section>
          <div className="spa-admin-0 mb-2">
            <div className="diff-admin px-3">
              <div className="admin-name-text">
                <h4>Sub - Admin</h4>
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={30000}
            closeOnClick
            pauseOnHover
            draggable
          />
          <div
            style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}
          >
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              // rowClasses={rowClasses}
              search
              exportCSV
            >
              {(props) => (
                <div>
                  <SearchBar {...props.searchProps} />
                  <ExportCSVButton
                    {...props.csvProps}
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                    }}
                  >
                    Download Excel
                  </ExportCSVButton>

                  <hr></hr>
                  <br></br>
                  <div
                    style={{
                      overflowX: "scroll",
                      width: "100%",
                      paddingBottom: "12%",
                    }}
                  >
                    <BootstrapTable
                      striped
                      hover
                      condensed
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>

          {/* <div className="spa-table px-3">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S.N.</th>
                  <th>Images</th>
                  <th>Title</th>
                  <th>Sub-Title</th>
                  <th style={{ width: "100px" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <img
                        src={"https://spazo.co.in/Banner/" + item.bannerimg}
                        width={100}
                        height={80}
                      />
                    </td>
                    <td>{item.title}</td>
                    <td>{item.subtitle}</td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          gap: "21px",
                        }}
                      >
                        <span className="dite">
                          <FaRegEdit onClick={() => handleShow1(item)} />
                        </span>
                        <span className="dite">
                          <RiDeleteBin2Fill
                            onClick={() => deletebanner(item)}
                          />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div style={{ display: "flex" }}>
            <p style={{ width: "100%", marginTop: "20px" }}>
              Total Count: {data.length}
            </p>
            <ReactPaginate
              previousLabel={"Back"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div> */}
        </section>
      </div>

      {/* modal */}

      {/* Edit */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Access Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!edit ? (
            <div className="form-spa">
              <Row>
                <Col md={6}>Banner</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.banner1}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              {/* <Row>
                <Col md={6}>Banner2</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.banner2}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Banner3</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.banner3}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row> */}
              <Row>
                <Col md={6}>Category</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.category}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Admin-Service</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.Adminservice}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Advantages</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.Advantages}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Promocode</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.Promocode}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>Top Brands</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.TopBrands}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Star Hotels</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.StarHotels}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Mall and Shopping</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.MallandShopping}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Off Duty Therapist</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.OffDutyTherapist}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>Customers</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.Customers}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Message</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.Message}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>

              <p
                style={{
                  marginTop: "10px",
                  color: "#e22217",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                Spa
              </p>
              <Row>
                <Col md={6}>Vendor</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.Vendor}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Edit Vendor Request</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.editVendorRequest}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Therapist</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.AdminTherapist}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Spa Delete Therapist</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.SpaDeleteTherapist}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>Service</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.service}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>Reviews</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.Reviews}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Booking</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.Booking}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Vendor %</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.Vendorpercentage}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Account Details</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.Accountdetails}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Daywise Account Details</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.DaywiseAccountdetails}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <p
                style={{
                  marginTop: "10px",
                  color: "#e22217",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                Salon
              </p>
              <Row>
                <Col md={6}>Vendor</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.SalonVendor}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Edit Vendor Request</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.SaloneditVendorRequest}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Therapist</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.SalonAdminTherapist}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>Salon Delete Therapist</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.SalonDeleteTherapist}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>Service</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.Salonservice}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>Reviews</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.SalonReviews}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Booking</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.SalonBooking}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Vendor %</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.SalonVendorpercentage}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Account Details</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.SalonAccountdetails}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Daywise Account Details</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.SalonDaywiseAccountdetails}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <div className="form-spa">
              <Row>
                <Col md={6}>Banner1</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={banner1}
                    onChange={() => setbanner1(!banner1)}
                  />
                </Col>
              </Row>
              {/* <Row>
                <Col md={6}>Banner2</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.banner2}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Banner3</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editdata?.banner3}
                    //   onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row> */}
              <Row>
                <Col md={6}>Category</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={category}
                    onChange={() => setcategory(!category)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Admin-Service</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={Adminservice}
                    onChange={() => setAdminservice(!Adminservice)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Advantages</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={Advantages}
                    onChange={() => setAdvantages(!Advantages)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Promocode</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={Promocode}
                    onChange={() => setPromocode(!Promocode)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Customers</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={Customers}
                    onChange={() => setCustomers(!Customers)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Message</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={Message}
                    onChange={() => setMessage(!Message)}
                  />
                </Col>
              </Row>
              <p
                style={{
                  marginTop: "10px",
                  color: "#e22217",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                Spa
              </p>
              <Row>
                <Col md={6}>Vendor</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={Vendor}
                    onChange={() => setVendor(!Vendor)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Edit Vendor Request</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={editVendorRequest}
                    onChange={() => seteditVendorRequest(!editVendorRequest)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Therapist</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={AdminTherapist}
                    onChange={() => setAdminTherapist(!AdminTherapist)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Service</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={service}
                    onChange={() => setservice(!service)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>Reviews</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={Reviews}
                    onChange={() => setReviews(!Reviews)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Booking</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={Booking}
                    onChange={() => setBooking(!Booking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Vendor %</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={Vendorpercentage}
                    onChange={() => setVendorpercentage(!Vendorpercentage)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Account Details</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={Accountdetails}
                    onChange={() => setAccountdetails(!Accountdetails)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Daywise Account Details</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={DaywiseAccountdetails}
                    onChange={() =>
                      setDaywiseAccountdetails(!DaywiseAccountdetails)
                    }
                  />
                </Col>
              </Row>
              <p
                style={{
                  marginTop: "10px",
                  color: "#e22217",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                Salon
              </p>

              <Row>
                <Col md={6}>Vendor</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={SalonVendor}
                    onChange={() => setSalonVendor(!SalonVendor)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Edit Vendor Request</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={SaloneditVendorRequest}
                    onChange={() =>
                      setSaloneditVendorRequest(!SaloneditVendorRequest)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Therapist</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={SalonAdminTherapist}
                    onChange={() =>
                      setSalonAdminTherapist(!SalonAdminTherapist)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Service</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={Salonservice}
                    onChange={() => setSalonservice(!Salonservice)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>Reviews</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={SalonReviews}
                    onChange={() => setSalonReviews(!SalonReviews)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Booking</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={SalonBooking}
                    onChange={() => setSalonBooking(!SalonBooking)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Vendor %</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={SalonVendorpercentage}
                    onChange={() =>
                      setSalonVendorpercentage(!SalonVendorpercentage)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Account Details</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={SalonAccountdetails}
                    onChange={() =>
                      setSalonAccountdetails(!SalonAccountdetails)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>Daywise Account Details</Col>
                <Col md={6}>
                  <input
                    type="checkbox"
                    checked={SalonDaywiseAccountdetails}
                    onChange={() =>
                      setSalonDaywiseAccountdetails(!SalonDaywiseAccountdetails)
                    }
                  />
                </Col>
              </Row>
            </div>
          )}
        </Modal.Body>
        {!edit ? (
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="primary" onClick={() => setedit(!edit)}>
              Edit
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="primary" onClick={editsubadminaccess}>
              Save Changes
            </Button>
          </Modal.Footer>
        )}
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sub-Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure, you want to delete data</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={postdeletesubadmin}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Subadmin Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addsubadmin1">
            <div>
              <Row className="mt-2">
                <Col md={6}>
                  <label style={{ fontSize: "16px", color: "black" }}>
                    Name <span style={{ color: "red" }}>*</span>
                  </label>
                </Col>
                <Col md={6}>
                  <input
                    placeholder={subadminedit?.fullName}
                    onChange={(e) => setname(e.target.value)}
                    style={{
                      border: "1px solid",
                      padding: "2px",
                      borderRadius: "5px",
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={6}>
                  <label style={{ fontSize: "16px", color: "black" }}>
                    Email<span style={{ color: "red" }}>*</span>
                  </label>
                </Col>
                <Col md={6}>
                  <input
                    placeholder={subadminedit?.email}
                    onChange={(e) => setemail(e.target.value)}
                    style={{
                      border: "1px solid",
                      padding: "2px",
                      borderRadius: "5px",
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={6}>
                  <label style={{ fontSize: "16px", color: "black" }}>
                    Contact No.<span style={{ color: "red" }}>*</span>
                  </label>
                </Col>
                <Col md={6}>
                  <input
                    placeholder={subadminedit?.mobileno}
                    maxlength="10"
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => setcontactnum(e.target.value)}
                    style={{
                      border: "1px solid",
                      padding: "2px",
                      borderRadius: "5px",
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={6}>
                  <label style={{ fontSize: "16px", color: "black" }}>
                    Password<span style={{ color: "red" }}>*</span>
                  </label>
                </Col>
                <Col md={6}>
                  <div style={{ display: "flex" }}>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      placeholder="*******"
                      onChange={(e) => setpassword(e.target.value)}
                      style={{
                        border: "1px solid",
                        padding: "2px",
                        borderRadius: "5px",
                      }}
                    />
                    {passwordVisible ? (
                      <button
                        style={{
                          border: "1px solid black",
                          borderLeft: "none",
                          paddingRight: "9px",
                          borderRadius: "0px 5px 5px 0px",
                          marginLeft: "-23px",
                          backgroundColor: "white",
                        }}
                        onClick={(e) => togglePasswordVisibility(e)}
                      >
                        <i
                          class="fa fa-eye"
                          aria-hidden="true"
                          style={{ color: "#e22217" }}
                        ></i>
                      </button>
                    ) : (
                      <button
                        style={{
                          border: "1px solid black",
                          borderLeft: "none",
                          paddingRight: "9px",
                          borderRadius: "0px 5px 5px 0px",
                          marginLeft: "-23px",
                          backgroundColor: "white",
                        }}
                        onClick={(e) => togglePasswordVisibility(e)}
                      >
                        <i
                          class="fa fa-eye-slash"
                          aria-hidden="true"
                          style={{ color: "#e22217" }}
                        ></i>
                      </button>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={editsubadmin}>
            Edit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show5} onHide={handleClose5}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>If you want to Block the Sub-admin</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => BlockSubadmin(subadminB)}>
            Block
          </Button>
          <Button variant="dark" onClick={handleClose5}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show6} onHide={handleClose6}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>Are you sure, You want to Unblock the Sub-admin</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              variant="secondary"
              onClick={() => UnBlockSubadmin(subadminUB)}
            >
              Yes
            </Button>
            <Button variant="dark" onClick={handleClose6}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Subadmindetails;
