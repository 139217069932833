import React, { useEffect, useState } from "react";
import Newheader2 from "./Newheader2";
import { Col, Modal, Row } from "react-bootstrap";
import NewFooter from "./NewFooter";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import axios from "axios";
import { BiSolidCarGarage } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import parse from "html-react-parser";
import "../../Styles/NewbudgetProducts.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function NewbudgetProducts() {
  const location = useLocation();
  const { Minamount, Maxamount, type } = location.state;

  console.log("amount, type", Minamount, Maxamount, type);
  const notify = (data) => toast.success(data);

  const [seletedAmount, setseletedAmount] = useState();

  const [Vendor, setVendor] = useState([]);
  const newarry = [];
  const filterarray = [];
  const [newarry1, setnewarry1] = useState([]);
  const [filterarray1, setfilterarray1] = useState([]);

  const currentDate = new Date();
  const [show, setShow] = useState(false);
  const [cupondata, setcupondata] = useState({});
  const [promocode, setpromocode] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setcupondata(data);
  };

  const [image, setimage] = useState();
  const [iddata, setiddata] = useState({});

  // copy to clipboard
  const [isCopied, setIsCopied] = React.useState(false);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(text);
      // alert("Copied!");
      notify("Copied!");
    });
  };

  useEffect(() => {
    if (type) {
      getallVendor();
    }
    getPromocode();
  }, [type]);

  const getallVendor = () => {
    axios
      .get("https://spazo.co.in/api/vendor/allVendors")
      .then(function (response) {
        console.log(response.data);
        setVendor(
          response.data.allVendors?.filter(
            (item) =>
              item.approved === "Approved" &&
              item.isBlocked === false &&
              item.type === type
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getPromocode = () => {
    axios
      .get("https://spazo.co.in/api/admin/getPromocode")
      .then(function (response) {
        console.log(response.data);
        setpromocode(
          response.data.PromocodeList.filter(
            (item) =>
              item.Status === "Valid" && new Date(item.Startdate) <= currentDate
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (seletedAmount) {
      var xyz = seletedAmount?.split("-");
      var seletedMinAmount = xyz[0];
      var seletedMaxAmount = xyz[1];
    }
    if (Vendor?.length > 0) {
      for (let i = 0; i < Vendor?.length; i++) {
        for (let j = 0; j < Vendor[i]?.service?.length; j++) {
          const gst =
            (Vendor[i]?.service[j]?.Price * Vendor[i]?.service[j]?.GST) / 100;
          const servicetax =
            (Vendor[i]?.service[j]?.Price * Vendor[i]?.service[j]?.servicetax) /
            100;
          const discount =
            (Vendor[i]?.service[j]?.Price * Vendor[i]?.service[j]?.Discount) /
            100;

          const total_amount =
            Vendor[i]?.service[j]?.Price + gst + servicetax - discount;

          if (!seletedAmount) {
            if (
              parseInt(Minamount) <= total_amount &&
              total_amount <= parseInt(Maxamount)
            ) {
              newarry?.push(Vendor[i]);
            }
          } else {
            if (
              parseInt(seletedMinAmount) <= total_amount &&
              total_amount <= parseInt(seletedMaxAmount)
            ) {
              filterarray?.push(Vendor[i]);
            }
          }
        }
      }
      setnewarry1(newarry);
      setfilterarray1(filterarray);
    }
  }, [seletedAmount, Vendor]);

  const uniqueArray = [...new Set(newarry1)];
  const uniquefilterArray = [...new Set(filterarray1)];

  //   console.log("newarry", newarry1);
  //   console.log("filterarray", filterarray1);
  //   console.log("seletedAmount", seletedAmount);

  return (
    <>
      <div>
        <Newheader2 />
        <Row className="me-0 p-3">
          <ToastContainer
            position="top-center"
            autoClose={30000}
            closeOnClick
            pauseOnHover
            draggable
          />
          <Col
            md={3}
            style={{
              borderRight: "1px solid rgb(217 212 208)",
              // height: "100vh",
            }}
          >
            {promocode?.length > 0 ? (
              <>
                <p
                  style={{
                    color: "#e22217",
                    fontWeight: "700",
                    fontSize: "22px",
                  }}
                >
                  Promos & Filters
                </p>
                <Carousel
                  autoPlay={true}
                  infiniteLoop={true}
                  showIndicators={false}
                >
                  {promocode?.map((prom) => {
                    const today = new Date(); // Get today's date
                    const endDate = new Date(prom?.Enddate); // Convert Enddate to a Date object

                    // Set the time of both dates to 00:00:00 to only compare the date part
                    today.setHours(0, 0, 0, 0);
                    endDate.setHours(0, 0, 0, 0);

                    const status = endDate < today ? "Expired" : "Valid";
                    return (
                      <div
                        style={{
                          boxShadow: "3px 4px 10px #cbc6c6",
                          padding: "10px",
                          backgroundColor: "white",
                        }}
                      >
                        <p>Win Rs {prom?.amount} instant off code</p>
                        {/* <p
                style={{
                  backgroundColor: "#e22217",
                  color: "white",
                  padding: "3px",
                  textAlign: "center",
                }}
              >
                NBLUCKY
              </p> */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              border: "1px dotted gray",
                              padding: "10px",
                              marginBottom: "0px",
                              color: "#e22217",
                            }}
                          >
                            {prom?.Promocode}
                          </p>
                          {status === "Expired" ? (
                            ""
                          ) : (
                            <button
                              style={{ borderColor: "gray", padding: "10px" }}
                              onClick={() => handleCopy(prom?.Promocode)}
                            >
                              Copy
                            </button>
                          )}
                        </div>

                        <div className="mt-3">
                          {status === "Expired" ? (
                            <span>Sorry..! Validity Expired</span>
                          ) : (
                            <span>
                              Valid till{" "}
                              {moment(prom?.Enddate).format("MMM Do YY")}
                            </span>
                          )}
                          {/* <span
                          style={{ color: "#e22217", marginLeft: "10px" }}
                          onClick={() => handleShow(prom)}
                        >
                          Know more
                        </span> */}
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
                <hr></hr>
              </>
            ) : (
              <></>
            )}

            <div>
              <p
                style={{
                  color: "#e22217",
                  fontWeight: "700",
                  fontSize: "22px",
                }}
              >
                Price
              </p>
              <input
                type="radio"
                id="html"
                name="price"
                value="0-499"
                onChange={(e) => setseletedAmount(e.target.value)}
              />
              &nbsp;
              <label for="html" style={{ color: "black", fontSize: "16px" }}>
                Under ₹ 500
              </label>
              <br />
              <input
                type="radio"
                id="css"
                name="price"
                value="500-999"
                onChange={(e) => setseletedAmount(e.target.value)}
              />
              &nbsp;
              <label for="css" style={{ color: "black", fontSize: "16px" }}>
                ₹ 500 - ₹ 999
              </label>
              <br />
              <input
                type="radio"
                id="javascript"
                name="price"
                value="1000-1499"
                onChange={(e) => setseletedAmount(e.target.value)}
              />
              &nbsp;
              <label
                for="javascript"
                style={{ color: "black", fontSize: "16px" }}
              >
                ₹ 1000 - ₹ 1499
              </label>
              <br />
              <input
                type="radio"
                id="css"
                name="price"
                value="1500-1999"
                onChange={(e) => setseletedAmount(e.target.value)}
              />
              &nbsp;
              <label for="css" style={{ color: "black", fontSize: "16px" }}>
                ₹ 1500 - ₹ 1999
              </label>
              <br />
              <input
                type="radio"
                id="css"
                name="price"
                value="2000-2499"
                onChange={(e) => setseletedAmount(e.target.value)}
              />
              &nbsp;
              <label for="css" style={{ color: "black", fontSize: "16px" }}>
                ₹ 2000 - ₹ 2499
              </label>
              <br />
              <input
                type="radio"
                id="css"
                name="price"
                value="2500-1000000"
                onChange={(e) => setseletedAmount(e.target.value)}
              />
              &nbsp;
              <label for="css" style={{ color: "black", fontSize: "16px" }}>
                Greater than ₹ 2500
              </label>
            </div>
          </Col>
          <Col md={9}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* <p style={{ fontSize: "25px", fontWeight: "bold" }}>{address}</p> */}
              {/* <div>
                <span>Sort By</span>&nbsp;&nbsp;
                <select>
                  <option>Price Low to Height</option>
                  <option>Price Height to Low</option>
                </select>
              </div> */}
            </div>
            <hr></hr>
            {!seletedAmount
              ? uniqueArray?.map((item) => (
                  <>
                    <Row>
                      <Col md={6}>
                        <Row>
                          <Col md={2}></Col>
                          <Col md={8} style={{ padding: "2px" }}>
                            <img
                              src={
                                iddata._id === item._id
                                  ? image
                                    ? image
                                    : "https://spazo.co.in/Vendor/" +
                                      item?.spaimage1
                                  : "https://spazo.co.in/Vendor/" +
                                    item?.spaimage1
                              }
                              style={{
                                width: "100%",
                                height: "300px",
                                // objectFit: "cover",
                                // overflow: "hidden",
                              }}
                            />
                          </Col>
                          <Col md={2}>
                            <div>
                              <img
                                src={
                                  "https://spazo.co.in/Vendor/" +
                                  item?.spaimage1
                                }
                                style={{
                                  width: "65px",
                                  height: "70px",
                                  margin: "2px",
                                  // objectFit: "cover",
                                  // overflow: "hidden",
                                }}
                                onClick={(e) => {
                                  setimage(
                                    "https://spazo.co.in/Vendor/" +
                                      item?.spaimage1
                                  );
                                  setiddata(item);
                                }}
                              />
                              <img
                                src={
                                  "https://spazo.co.in/Vendor/" +
                                  item?.spaimage2
                                }
                                style={{
                                  width: "65px",
                                  height: "70px",
                                  margin: "2px",
                                  // objectFit: "cover",
                                  // overflow: "hidden",
                                }}
                                onClick={(e) => {
                                  setimage(
                                    "https://spazo.co.in/Vendor/" +
                                      item?.spaimage2
                                  );
                                  setiddata(item);
                                }}
                              />
                              <img
                                src={
                                  "https://spazo.co.in/Vendor/" +
                                  item?.spaimage3
                                }
                                style={{
                                  width: "65px",
                                  height: "70px",
                                  margin: "2px",
                                  // objectFit: "cover",
                                  // overflow: "hidden",
                                }}
                                onClick={(e) => {
                                  setimage(
                                    "https://spazo.co.in/Vendor/" +
                                      item?.spaimage3
                                  );
                                  setiddata(item);
                                }}
                              />
                              <img
                                src={
                                  "https://spazo.co.in/Vendor/" +
                                  item?.spaimage4
                                }
                                style={{
                                  width: "65px",
                                  height: "70px",
                                  margin: "2px",
                                  // objectFit: "cover",
                                  // overflow: "hidden",
                                }}
                                onClick={(e) => {
                                  setimage(
                                    "https://spazo.co.in/Vendor/" +
                                      item?.spaimage4
                                  );
                                  setiddata(item);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <img></img>
                      </Col>
                      <Col md={6}>
                        <p
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                            marginBottom: "0px",
                          }}
                        >
                          {item?.businessName}
                        </p>
                        <p>{item?.address}</p>
                        <div className="ratingparking">
                          <div style={{ marginTop: "15px" }}>
                            <span>Ratings :</span>&nbsp;
                            <span
                              style={{
                                backgroundColor: "#e22217 ",
                                color: "white",
                                padding: "0px 5px",
                                width: "10%",
                              }}
                            >
                              {isNaN(
                                item?.reviews?.reduce(
                                  (a, rev) => a + rev.rating,
                                  0
                                ) / item?.reviews?.length
                              )
                                ? 0
                                : (
                                    item?.reviews?.reduce(
                                      (a, rev) => a + rev.rating,
                                      0
                                    ) / item?.reviews?.length
                                  )?.toFixed(1)}
                              &nbsp;
                              <i class="fas fa-star"></i>
                            </span>
                          </div>
                          <div>
                            <span>Parking :</span>
                            {item?.parking === "4-Wheeler" ? (
                              <i
                                class="fas fa-car"
                                style={{ fontSize: "30px", margin: "3px" }}
                              ></i>
                            ) : item?.parking === "2-Wheeler" ? (
                              <i
                                class="fas fa-motorcycle"
                                style={{ fontSize: "30px", margin: "3px" }}
                              ></i>
                            ) : item?.parking === "Both" ? (
                              <>
                                <i
                                  class="fas fa-car"
                                  style={{ fontSize: "30px", margin: "3px" }}
                                ></i>
                                <i
                                  class="fas fa-motorcycle"
                                  style={{ fontSize: "30px", margin: "3px" }}
                                ></i>
                              </>
                            ) : (
                              item?.parking
                            )}
                          </div>
                        </div>

                        {/* <div className="mt-5">
                    <span
                      style={{
                        color: "#e22217",
                        fontSize: "30px",
                        fontWeight: "800",
                      }}
                    >
                      ₹2000
                    </span>
                    &nbsp;&nbsp;
                    <span
                      style={{
                        fontSize: "18px",
                        textDecoration: "line-through",
                      }}
                    >
                      ₹4000
                    </span>
                  </div> */}
                        <div className="mt-5">
                          <Link
                            style={{
                              padding: "10px 20px",
                              fontWeight: "700",
                              textDecoration: "none",
                              border: "1px solid black",
                              backgroundColor: "#1ab64f",
                              color: "white",
                              borderColor: "#1ab64f",
                            }}
                            to="/Newproductdetails"
                            state={{ item: item }}
                          >
                            View Details
                          </Link>
                          {/* <Link
                            style={{
                              padding: "10px 20px",
                              backgroundColor: "transparent",
                              fontWeight: "800",
                              backgroundColor: "#1ab64f",
                              color: "white",
                              borderColor: "#1ab64f",
                              margin: "10px",
                            }}
                            to="/Newproductdetails"
                            state={{ item: item }}
                          >
                            Book Now
                          </Link> */}
                        </div>
                      </Col>
                    </Row>
                    <hr></hr>
                  </>
                ))
              : uniquefilterArray?.map((item) => (
                  <>
                    <Row>
                      <Col md={6}>
                        <Row>
                          <Col md={2}></Col>
                          <Col md={8} style={{ padding: "2px" }}>
                            <img
                              src={
                                iddata._id === item._id
                                  ? image
                                    ? image
                                    : "https://spazo.co.in/Vendor/" +
                                      item?.spaimage1
                                  : "https://spazo.co.in/Vendor/" +
                                    item?.spaimage1
                              }
                              style={{
                                width: "100%",
                                height: "300px",
                                // objectFit: "cover",
                                // overflow: "hidden",
                              }}
                            />
                          </Col>
                          <Col md={2}>
                            <div>
                              <img
                                src={
                                  "https://spazo.co.in/Vendor/" +
                                  item?.spaimage1
                                }
                                style={{
                                  width: "65px",
                                  height: "70px",
                                  margin: "2px",
                                  // objectFit: "cover",
                                  // overflow: "hidden",
                                }}
                                onClick={(e) => {
                                  setimage(
                                    "https://spazo.co.in/Vendor/" +
                                      item?.spaimage1
                                  );
                                  setiddata(item);
                                }}
                              />
                              <img
                                src={
                                  "https://spazo.co.in/Vendor/" +
                                  item?.spaimage2
                                }
                                style={{
                                  width: "65px",
                                  height: "70px",
                                  margin: "2px",
                                  // objectFit: "cover",
                                  // overflow: "hidden",
                                }}
                                onClick={(e) => {
                                  setimage(
                                    "https://spazo.co.in/Vendor/" +
                                      item?.spaimage2
                                  );
                                  setiddata(item);
                                }}
                              />
                              <img
                                src={
                                  "https://spazo.co.in/Vendor/" +
                                  item?.spaimage3
                                }
                                style={{
                                  width: "65px",
                                  height: "70px",
                                  margin: "2px",
                                  // objectFit: "cover",
                                  // overflow: "hidden",
                                }}
                                onClick={(e) => {
                                  setimage(
                                    "https://spazo.co.in/Vendor/" +
                                      item?.spaimage3
                                  );
                                  setiddata(item);
                                }}
                              />
                              <img
                                src={
                                  "https://spazo.co.in/Vendor/" +
                                  item?.spaimage4
                                }
                                style={{
                                  width: "65px",
                                  height: "70px",
                                  margin: "2px",
                                  // objectFit: "cover",
                                  // overflow: "hidden",
                                }}
                                onClick={(e) => {
                                  setimage(
                                    "https://spazo.co.in/Vendor/" +
                                      item?.spaimage4
                                  );
                                  setiddata(item);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <img></img>
                      </Col>
                      <Col md={6}>
                        <p
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                            marginBottom: "0px",
                          }}
                        >
                          {item?.businessName}
                        </p>
                        <p>{item?.address}</p>
                        <div className="ratingparking">
                          <div style={{ marginTop: "15px" }}>
                            <span>Ratings :</span>&nbsp;
                            <span
                              style={{
                                backgroundColor: "#e22217 ",
                                color: "white",
                                padding: "0px 5px",
                                width: "10%",
                              }}
                            >
                              {isNaN(
                                item?.reviews?.reduce(
                                  (a, rev) => a + rev.rating,
                                  0
                                ) / item?.reviews?.length
                              )
                                ? 0
                                : (
                                    item?.reviews?.reduce(
                                      (a, rev) => a + rev.rating,
                                      0
                                    ) / item?.reviews?.length
                                  )?.toFixed(1)}
                              &nbsp;
                              <i class="fas fa-star"></i>
                            </span>
                          </div>
                          <div>
                            <span>Parking :</span>
                            {item?.parking === "4-Wheeler" ? (
                              <i
                                class="fas fa-car"
                                style={{ fontSize: "30px", margin: "3px" }}
                              ></i>
                            ) : item?.parking === "2-Wheeler" ? (
                              <i
                                class="fas fa-motorcycle"
                                style={{ fontSize: "30px", margin: "3px" }}
                              ></i>
                            ) : item?.parking === "Both" ? (
                              <>
                                <i
                                  class="fas fa-car"
                                  style={{ fontSize: "30px", margin: "3px" }}
                                ></i>
                                <i
                                  class="fas fa-motorcycle"
                                  style={{ fontSize: "30px", margin: "3px" }}
                                ></i>
                              </>
                            ) : (
                              item?.parking
                            )}
                          </div>
                        </div>

                        {/* <div className="mt-5">
                  <span
                    style={{
                      color: "#e22217",
                      fontSize: "30px",
                      fontWeight: "800",
                    }}
                  >
                    ₹2000
                  </span>
                  &nbsp;&nbsp;
                  <span
                    style={{
                      fontSize: "18px",
                      textDecoration: "line-through",
                    }}
                  >
                    ₹4000
                  </span>
                </div> */}
                        <div className="mt-5">
                          <Link
                            style={{
                              padding: "10px 20px",
                              fontWeight: "700",
                              textDecoration: "none",
                              border: "1px solid black",
                              backgroundColor: "#1ab64f",
                              color: "white",
                              borderColor: "#1ab64f",
                            }}
                            to="/Newproductdetails"
                            state={{ item: item }}
                          >
                            View Details
                          </Link>
                          {/* <Link
                          style={{
                            padding: "10px 20px",
                            backgroundColor: "transparent",
                            fontWeight: "800",
                            backgroundColor: "#1ab64f",
                            color: "white",
                            borderColor: "#1ab64f",
                            margin: "10px",
                          }}
                          to="/Newproductdetails"
                          state={{ item: item }}
                        >
                          Book Now
                        </Link> */}
                        </div>
                      </Col>
                    </Row>
                    <hr></hr>
                  </>
                ))}
          </Col>
        </Row>
      </div>
      <Modal show={show} onHide={handleClose} className="modal1111">
        <Modal.Header closeButton style={{ backgroundColor: "#f3f5f7" }}>
          <Modal.Title>Offers details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ border: "1px dotted gray", margin: "10px" }}>
            <Row>
              <Col
                md={4}
                style={{
                  borderRight: "1px dotted gray",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "25px",
                    fontWeight: "bold",
                    marginTop: "100%",
                  }}
                >
                  Win Rs {cupondata?.amount} instant off code
                </p>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      border: "1px dotted gray",
                      padding: "10px",
                      marginBottom: "0px",
                      color: "#e22217",
                    }}
                  >
                    {cupondata?.Promocode}
                  </p>
                  <button
                    style={{ borderColor: "gray", padding: "10px" }}
                    onClick={() => handleCopy(cupondata?.Promocode)}
                  >
                    Copy
                  </button>
                </div>
              </Col>
              <Col md={8} style={{ padding: "2px" }}>
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  Terms & Conditions
                </p>
                <div style={{ textAlign: "justify", fontSize: "16px" }}>
                  {cupondata?.TandC ? parse(cupondata?.TandC) : ""}
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <NewFooter />
    </>
  );
}

export default NewbudgetProducts;
