import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaEye, FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import moment from "moment";

function AdminAccountdetails() {
  const Input = styled("input")({
    display: "none",
  });

  const [Order, setOrder] = useState([]);
  const [paymentType, setpaymentType] = useState("All");
  const data = [];

  useEffect(() => {
    getOrder();
  }, []);

  const getOrder = () => {
    axios
      .get("https://spazo.co.in/api/customer/getOrder")
      .then(function (response) {
        console.log("order", response.data);
        setOrder(
          response.data.Order?.filter(
            (item) =>
              item?.vendors[0]?.type === "Spa" &&
              item?.BookingStatus !== "Cancelled"
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  for (let i = 0; i < Order.length; i++) {
    const vendorshare =
      Order[i].TotalAmount -
      Order[i].Discount -
      ((Order[i].TotalAmount - Order[i].Discount) *
        Order[i].vendors[0]?.Vendorpercentage) /
        100;

    //Online payment 5%
    const adminshare = ((Order[i].TotalAmount - Order[i].Discount) * 5) / 100;
    // vendor %
    const adminshare1 =
      ((Order[i].TotalAmount - Order[i].Discount) *
        Order[i].vendors[0]?.Vendorpercentage) /
      100;
    const adminshare2 =
      Order[i].paymentMethod === "Pay At Spa"
        ? adminshare1 - Order[i].CouponDiscount
        : adminshare1 - Order[i].CouponDiscount - adminshare;

    data.push({
      Booking_Id: Order[i]._id,
      Booking_Date: moment(Order[i].createdAt).format("DD/MM/YYYY"),
      Spa_Name: Order[i].VendorBusinessName,
      Spa_Email: Order[i].VendorEmailID,
      Contact_Number: Order[i].VendorContactNo,
      Spa_Address: Order[i].VendorAddress,
      Spa_Discount: Order[i].Discount,
      Basic_Price: Order[i].Price,
      Total_Price: Order[i].TotalAmount,
      TotalPayableAmount: Order[i].TotalPayableAmount,
      TotalAmountPaid: Order[i].TotalAmountPaid,
      CouponDiscount: Order[i].CouponDiscount,
      OnlinePayment_Discount:
        Order[i].paymentMethod === "Pay At Spa"
          ? 0
          : ((Order[i].TotalAmount - Order[i].Discount) * 5) / 100,

      Vendor_percentage: Order[i].vendors[0]?.Vendorpercentage,
      Payment_Method: Order[i].paymentMethod,
      Admin_Discount_Percentage:
        Order[i].vendors[0]?.Vendorpercentage -
        (Order[i].paymentMethod === "Pay At Spa" ? 0 : 5),

      Total_Amount: vendorshare,
      Admin_Share: adminshare2,
    });
  }

  console.log("data", data);
  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "3",
        value: 3,
      },
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data?.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "Booking_Id",
      text: "Booking Id",
      formatter: (value, row) => <p>{row?.Booking_Id}</p>,
    },
    {
      dataField: "Booking_Date",
      text: "Booking Date",
      formatter: (value, row) => (
        <p>{row?.Booking_Date ? row?.Booking_Date : "-"}</p>
      ),
    },

    {
      dataField: "Spa_Name",
      text: "Spa Name",
      formatter: (value, row) => (
        <p style={{ overflowY: "scroll", height: "100px" }}>
          {row?.Spa_Name ? row?.Spa_Name : "-"}
        </p>
      ),
    },
    {
      dataField: "Spa_Email",
      text: "Spa Email",
      formatter: (value, row) => <p>{row?.Spa_Email ? row?.Spa_Email : "-"}</p>,
    },
    {
      dataField: "Contact_Number",
      text: "Contact Number",
      formatter: (value, row) => (
        <p>{row?.Contact_Number ? row?.Contact_Number : "-"}</p>
      ),
    },
    {
      dataField: "Spa_Address",
      text: "Spa Address",
      formatter: (value, row) => (
        <p style={{ overflowY: "scroll", height: "100px" }}>
          {row?.Spa_Address ? row?.Spa_Address : "-"}
        </p>
      ),
    },
    // {
    //   dataField: "Basic_Price",
    //   text: "Basic_Price",
    //   formatter: (value, row) => (
    //     <p>{row?.Basic_Price ? row?.Basic_Price : "-"}</p>
    //   ),
    // },
    {
      dataField: "Total_Price",
      text: "Total Price",
      formatter: (value, row) => (
        <p>{row?.Total_Price ? row?.Total_Price : "-"}</p>
      ),
    },

    {
      dataField: "Spa_Discount",
      text: "Spa Discount",
      formatter: (value, row) => (
        <p>{row?.Spa_Discount ? row?.Spa_Discount : "-"}</p>
      ),
    },
    {
      dataField: "Vendor_percentage",
      text: "Vendor Percentage",
      formatter: (value, row) => (
        <p>{row?.Vendor_percentage ? row?.Vendor_percentage : "-"}</p>
      ),
    },
    {
      dataField: "TotalPayableAmount",
      text: "Total Amount Should Pay",
      formatter: (value, row) => (
        <p>{row?.TotalPayableAmount ? row?.TotalPayableAmount : "-"}</p>
      ),
    },
    {
      dataField: "TotalAmountPaid",
      text: "Total Amount Paid",
      formatter: (value, row) => (
        <p>{row?.TotalAmountPaid ? row?.TotalAmountPaid : "-"}</p>
      ),
    },
    {
      dataField: "Total_Amount",
      text: "Vendor Share",
      formatter: (value, row) => (
        <p>{row?.Total_Amount ? row?.Total_Amount : "-"}</p>
      ),
    },
    {
      dataField: "CouponDiscount",
      text: "Coupon Discount",
      formatter: (value, row) => <p>{row?.CouponDiscount}</p>,
    },
    {
      dataField: "OnlinePayment_Discount",
      text: "Online Payment Discount",
      formatter: (value, row) => <p>{row?.OnlinePayment_Discount}</p>,
    },

    {
      dataField: "Admin_Share",
      text: "Admin Share",
      formatter: (value, row) => (
        <p>{row?.Admin_Share ? row?.Admin_Share : "-"}</p>
      ),
    },
    {
      dataField: "Payment_Method",
      text: "Payment Method",
      formatter: (value, row) => (
        <p
          style={{
            backgroundColor:
              row?.Payment_Method === "Pay At Spa"
                ? "#e22217"
                : row?.Payment_Method === "Online Payment"
                ? "Green"
                : "rgb(132, 207, 7)",
            color: "white",
          }}
        >
          {row?.Payment_Method}
        </p>
      ),
    },
    // {
    //   dataField: "",
    //   text: "View",
    //   formatter: (value, row) => (
    //     <FaEye
    //       style={{ color: "#e84141" }}
    //       // onClick={() => handleViewShow(row)}
    //     />
    //   ),
    // },
    // {
    //   dataField: "",
    //   text: "Action",
    //   formatter: (value, row) => (
    //     <RiDeleteBin2Fill
    //       // onClick={() => deleteService(row)}
    //       style={{ color: "red" }}
    //     />
    //   ),
    // },
  ];

  const columns1 = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "Booking_Id",
      text: "Booking Id",
      formatter: (value, row) => <p>{row?.Booking_Id}</p>,
    },
    {
      dataField: "Booking_Date",
      text: "Booking Date",
      formatter: (value, row) => (
        <p>{row?.Booking_Date ? row?.Booking_Date : "-"}</p>
      ),
    },

    {
      dataField: "Spa_Name",
      text: "Spa Name",
      formatter: (value, row) => (
        <p style={{ overflowY: "scroll", height: "100px" }}>
          {row?.Spa_Name ? row?.Spa_Name : "-"}
        </p>
      ),
    },
    {
      dataField: "Spa_Email",
      text: "Spa Email",
      formatter: (value, row) => <p>{row?.Spa_Email ? row?.Spa_Email : "-"}</p>,
    },
    {
      dataField: "Contact_Number",
      text: "Contact Number",
      formatter: (value, row) => (
        <p>{row?.Contact_Number ? row?.Contact_Number : "-"}</p>
      ),
    },
    {
      dataField: "Spa_Address",
      text: "Spa Address",
      formatter: (value, row) => (
        <p style={{ overflowY: "scroll", height: "100px" }}>
          {row?.Spa_Address ? row?.Spa_Address : "-"}
        </p>
      ),
    },
    // {
    //   dataField: "Basic_Price",
    //   text: "Basic_Price",
    //   formatter: (value, row) => (
    //     <p>{row?.Basic_Price ? row?.Basic_Price : "-"}</p>
    //   ),
    // },
    {
      dataField: "Total_Price",
      text: "Total Price",
      formatter: (value, row) => (
        <p>{row?.Total_Price ? row?.Total_Price : "-"}</p>
      ),
    },

    {
      dataField: "Spa_Discount",
      text: "Spa Discount",
      formatter: (value, row) => (
        <p>{row?.Spa_Discount ? row?.Spa_Discount : "-"}</p>
      ),
    },
    {
      dataField: "Vendor_percentage",
      text: "Vendor percentage",
      formatter: (value, row) => (
        <p>{row?.Vendor_percentage ? row?.Vendor_percentage : "-"}</p>
      ),
    },
    {
      dataField: "Paid_Amount",
      text: "Amount Paid",
      formatter: (value, row) => (
        <p>{row?.Paid_Amount ? row?.Paid_Amount : "-"}</p>
      ),
    },
    {
      dataField: "Total_Amount",
      text: "Vendor Share",
      formatter: (value, row) => (
        <p>{row?.Total_Amount ? row?.Total_Amount : "-"}</p>
      ),
    },
    {
      dataField: "CouponDiscount",
      text: "Coupon Discount",
      formatter: (value, row) => <p>{row?.CouponDiscount}</p>,
    },
    {
      dataField: "OnlinePayment_Discount",
      text: "Online Payment Discount",
      formatter: (value, row) => <p>{row?.OnlinePayment_Discount}</p>,
    },

    {
      dataField: "Admin_Share",
      text: "Admin Share",
      formatter: (value, row) => (
        <p>{row?.Admin_Share ? row?.Admin_Share : "-"}</p>
      ),
    },
    {
      dataField: "Payment_Method",
      text: "Payment Method",
      formatter: (value, row) => (
        <p
          style={{
            backgroundColor:
              row?.Payment_Method === "Pay At Spa"
                ? "#e22217"
                : row?.Payment_Method === "Online Payment"
                ? "Green"
                : "rgb(132, 207, 7)",
            color: "white",
          }}
        >
          {row?.Payment_Method}
        </p>
      ),
    },
    // {
    //   dataField: "",
    //   text: "View",
    //   formatter: (value, row) => (
    //     <FaEye
    //       style={{ color: "#e84141" }}
    //       // onClick={() => handleViewShow(row)}
    //     />
    //   ),
    // },
    // {
    //   dataField: "",
    //   text: "Action",
    //   formatter: (value, row) => (
    //     <RiDeleteBin2Fill
    //       // onClick={() => deleteService(row)}
    //       style={{ color: "red" }}
    //     />
    //   ),
    // },
  ];

  const offlinepaymentdata = data?.filter(
    (item) => item.Payment_Method === "Pay At Spa"
  );

  const onlinepaymentdata = data?.filter(
    (item) => item.Payment_Method === "Online Payment"
  );

  const pay20Pdata = data?.filter((item) => item.Payment_Method === "Pay 20%");

  console.log("paymentType", paymentType);
  return (
    <div className="never-sspa">
      <section>
        <div className="spa-admin-0 mb-2">
          <div className="diff-admin px-3">
            <div className="admin-name-text">
              <h4>Account Details</h4>
            </div>
          </div>
        </div>
        <div style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}>
          <ToolkitProvider
            keyField="id"
            data={
              paymentType === "All"
                ? data
                : paymentType === "Offline Payment"
                ? offlinepaymentdata
                : paymentType === "Online Payment"
                ? onlinepaymentdata
                : paymentType === "20% Payment"
                ? pay20Pdata
                : ""
            }
            columns={paymentType === "Offline Payment" ? columns1 : columns}
            // rowClasses={rowClasses}
            search
            exportCSV
          >
            {(props) => (
              <div>
                <SearchBar {...props.searchProps} />
                <ExportCSVButton
                  {...props.csvProps}
                  style={{
                    backgroundColor: "#e22217",
                    color: "white",
                    marginLeft: "10px",
                  }}
                >
                  Download Excel
                </ExportCSVButton>
                <button
                  style={{
                    marginLeft: "100px",
                    border: "none",
                    color: "white",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor: "Blue",
                  }}
                  onClick={() => setpaymentType("All")}
                >
                  All
                </button>
                <button
                  style={{
                    marginLeft: "30px",
                    border: "none",
                    color: "white",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor: "#e22217",
                  }}
                  onClick={() => setpaymentType("Offline Payment")}
                >
                  Offline Payment
                </button>
                <button
                  style={{
                    marginLeft: "30px",
                    border: "none",
                    color: "white",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor: "Green",
                  }}
                  onClick={() => setpaymentType("Online Payment")}
                >
                  Online Payment
                </button>

                <button
                  style={{
                    marginLeft: "30px",
                    border: "none",
                    color: "white",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor: "#84cf07",
                  }}
                  onClick={() => setpaymentType("20% Payment")}
                >
                  20% Payment
                </button>

                <hr></hr>
                <br></br>
                <div style={{ overflowX: "scroll", width: "100%" }}>
                  <BootstrapTable
                    striped
                    hover
                    condensed
                    {...props.baseProps}
                    pagination={paginationFactory(options)}
                  />
                </div>
              </div>
            )}
          </ToolkitProvider>
          {/* 
          <ToolkitProvider
            keyField="id"
            data={data}
            columns={columns1}
            // rowClasses={rowClasses}
            search
            exportCSV
          >
            {(props) => (
              <div>
                <SearchBar {...props.searchProps} />
                <ExportCSVButton
                  {...props.csvProps}
                  style={{
                    backgroundColor: "#e22217",
                    color: "white",
                    marginLeft: "10px",
                  }}
                >
                  Download Excel
                </ExportCSVButton>
                <button
                  style={{
                    marginLeft: "100px",
                    border: "none",
                    color: "white",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor: "Blue",
                  }}
                  onClick={() => setpaymentType("All")}
                >
                  All
                </button>
                <button
                  style={{
                    marginLeft: "30px",
                    border: "none",
                    color: "white",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor: "#e22217",
                  }}
                  onClick={() => setpaymentType("Offline Payment")}
                >
                  Offline Payment
                </button>
                <button
                  style={{
                    marginLeft: "30px",
                    border: "none",
                    color: "white",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor: "Green",
                  }}
                  onClick={() => setpaymentType("Online Payment")}
                >
                  Online Payment
                </button>

                <button
                  style={{
                    marginLeft: "30px",
                    border: "none",
                    color: "white",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor: "#84cf07",
                  }}
                  onClick={() => setpaymentType("20% Payment")}
                >
                  20% Payment
                </button>

                <hr></hr>
                <br></br>
                <div style={{ overflowX: "scroll", width: "100%" }}>
                  <BootstrapTable
                    striped
                    hover
                    condensed
                    {...props.baseProps}
                    pagination={paginationFactory(options)}
                  />
                </div>
              </div>
            )}
          </ToolkitProvider>

          <ToolkitProvider
            keyField="id"
            data={data}
            columns={columns1}
            // rowClasses={rowClasses}
            search
            exportCSV
          >
            {(props) => (
              <div>
                <SearchBar {...props.searchProps} />
                <ExportCSVButton
                  {...props.csvProps}
                  style={{
                    backgroundColor: "#e22217",
                    color: "white",
                    marginLeft: "10px",
                  }}
                >
                  Download Excel
                </ExportCSVButton>
                <button
                  style={{
                    marginLeft: "100px",
                    border: "none",
                    color: "white",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor: "Blue",
                  }}
                  onClick={() => setpaymentType("All")}
                >
                  All
                </button>
                <button
                  style={{
                    marginLeft: "30px",
                    border: "none",
                    color: "white",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor: "#e22217",
                  }}
                  onClick={() => setpaymentType("Offline Payment")}
                >
                  Offline Payment
                </button>
                <button
                  style={{
                    marginLeft: "30px",
                    border: "none",
                    color: "white",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor: "Green",
                  }}
                  onClick={() => setpaymentType("Online Payment")}
                >
                  Online Payment
                </button>

                <button
                  style={{
                    marginLeft: "30px",
                    border: "none",
                    color: "white",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor: "#84cf07",
                  }}
                  onClick={() => setpaymentType("20% Payment")}
                >
                  20% Payment
                </button>

                <hr></hr>
                <br></br>
                <div style={{ overflowX: "scroll", width: "100%" }}>
                  <BootstrapTable
                    striped
                    hover
                    condensed
                    {...props.baseProps}
                    pagination={paginationFactory(options)}
                  />
                </div>
              </div>
            )}
          </ToolkitProvider>

          <ToolkitProvider
            keyField="id"
            data={data}
            columns={columns1}
            // rowClasses={rowClasses}
            search
            exportCSV
          >
            {(props) => (
              <div>
                <SearchBar {...props.searchProps} />
                <ExportCSVButton
                  {...props.csvProps}
                  style={{
                    backgroundColor: "#e22217",
                    color: "white",
                    marginLeft: "10px",
                  }}
                >
                  Download Excel
                </ExportCSVButton>
                <button
                  style={{
                    marginLeft: "100px",
                    border: "none",
                    color: "white",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor: "Blue",
                  }}
                  onClick={() => setpaymentType("All")}
                >
                  All
                </button>
                <button
                  style={{
                    marginLeft: "30px",
                    border: "none",
                    color: "white",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor: "#e22217",
                  }}
                  onClick={() => setpaymentType("Offline Payment")}
                >
                  Offline Payment
                </button>
                <button
                  style={{
                    marginLeft: "30px",
                    border: "none",
                    color: "white",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor: "Green",
                  }}
                  onClick={() => setpaymentType("Online Payment")}
                >
                  Online Payment
                </button>

                <button
                  style={{
                    marginLeft: "30px",
                    border: "none",
                    color: "white",
                    padding: "6px",
                    borderRadius: "5px",
                    backgroundColor: "#84cf07",
                  }}
                  onClick={() => setpaymentType("20% Payment")}
                >
                  20% Payment
                </button>

                <hr></hr>
                <br></br>
                <div style={{ overflowX: "scroll", width: "100%" }}>
                  <BootstrapTable
                    striped
                    hover
                    condensed
                    {...props.baseProps}
                    pagination={paginationFactory(options)}
                  />
                </div>
              </div>
            )}
          </ToolkitProvider> */}
        </div>
      </section>
    </div>
  );
}

export default AdminAccountdetails;
