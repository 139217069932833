import React from "react";
import "../Styles/budget.css";
import OwlCarousel from "react-owl-carousel";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

function RelaxingMassage() {
  const responsive = {
    0: {
      items: 2,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 6,
    },
  };

  const [Service, setService] = useState([]);
  const uniqueArray = [];
  const seenIdsAndNames = new Set();

  for (const obj of Service) {
    const key = obj.ServiceName;
    if (!seenIdsAndNames.has(key)) {
      seenIdsAndNames.add(key);
      uniqueArray.push(obj);
    }
  }

  useEffect(() => {
    getallservices();
  }, []);

  const getallservices = () => {
    axios
      .get("https://spazo.co.in/api/vendor/getService")
      .then(function (response) {
        console.log(response.data);
        setService(response.data.Service);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log("Service", Service, uniqueArray);
  return (
    <section>
      <div
        className="budget"
        data-aos="fade-up"
        data-aos-delay="800"
        data-aos-duration="3000"
      >
        <div className="container-fluid de-top">
          <h4>Relaxing Massage </h4>
        </div>
        <div className="default-spa">
          <OwlCarousel
            className="owl-theme"
            // loop
            autoplay={true}
            margin={15}
            items={6}
            nav={false}
            dots={false}
            responsive={responsive}
          >
            {uniqueArray?.map((item) => (
              <div class="item mt-3 mb-3">
                <div className="spa-budget">
                  <ul>
                    <Link
                      to="/serviceProduct"
                      state={{ ser: item.ServiceName }}
                    >
                      <li>
                        <div className="budget-spa-0">
                          <div
                            className="spa-text-0"
                            style={{
                              backgroundImage: `url("https://spazo.co.in/Service/${item.Service_Image}")`,
                              width: "131px",
                              height: "131px",
                              borderRadius: "50%",
                            }}
                          >
                            <div className="dudget-diff-0">
                              <h6>{item.ServiceName}</h6>
                            </div>
                          </div>
                        </div>
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
            ))}
            {/* <div class="item mt-3 mb-3">
              <div className="spa-budget">
                <ul>
                  <li>
                    <a href="#">
                      <div className="budget-spa-0">
                        <div
                          className="spa-text-0"
                          style={{
                            backgroundImage:
                              "url('../../Assets/images/relax2.avif')",
                            width: "131px",
                            height: "131px",
                            borderRadius: "50%",
                          }}
                        >
                          <div className="dudget-diff-0">
                            <h6>THAI</h6>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
}

export default RelaxingMassage;
