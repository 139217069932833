import React from "react";
import Vendorheader from "./Vendorheader";
import Vendorsidebar from "./Vendorsidebar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaEye, FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "react-bootstrap/Table";
import exportFromJSON from "export-from-json";
import ReactPaginate from "react-paginate";

function VendorBooking() {
  const Input = styled("input")({
    display: "none",
  });
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const user = JSON.parse(sessionStorage.getItem("user"));
  const data = [];
  const [Order, setOrder] = useState([]);
  const [paymentType, setpaymentType] = useState("All");
  const [therapist, settherapist] = useState([]);
  const [selectedTherapist, setselectedTherapist] = useState("");

  const [show, setShow] = useState(false);
  const [viewdata, setviewdata] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setShow(true);
    setviewdata(item);
  };

  const selectfunction = (e) => {
    console.log("asas", e, e.target.value);
    setselectedTherapist(e.target.value);
  };

  useEffect(() => {
    getVendorOrder();
    getVendorEmployee();
  }, []);

  const getVendorEmployee = () => {
    axios
      .get("https://spazo.co.in/api/Vendor/getAllEmployee")
      .then(function (response) {
        console.log("therapist", response.data);
        settherapist(
          response.data?.Employee?.filter(
            (item) =>
              item?.isDeleted === false &&
              item?.vendor[item.vendor?.length - 1].VendorId === user?._id
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateOrder = async (data) => {
    console.log("fun", selectedTherapist);
    if (!selectedTherapist) {
      notify2("Please select therapist, whom you want to assign");
    } else {
      try {
        const config = {
          url: "/assignOrdertoTherapist",
          method: "post",
          baseURL: "https://spazo.co.in/api/customer",
          headers: { "content-type": "application/json" },
          data: {
            Assined_Therapist: selectedTherapist,
            Booking_Id: data?.Booking_Id,
            Booking_assined_date: moment().format("DD/MM/YYYY"),
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          notify("Booking assigned succesfully");
          window.location.reload();
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          notify1(error.response.data.error);
        }
      }
    }
  };

  const getVendorOrder = () => {
    axios
      .get("https://spazo.co.in/api/customer/getVendorOrder/" + user._id)
      .then(function (response) {
        console.log(response.data);
        setOrder(response.data.Order);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  for (let i = 0; i < Order.length; i++) {
    const vendorshare =
      Order[i].TotalAmount -
      Order[i].Discount -
      ((Order[i].TotalAmount - Order[i].Discount) *
        Order[i].vendors[0]?.Vendorpercentage) /
        100;

    //Online payment 5%
    const adminshare = ((Order[i].TotalAmount - Order[i].Discount) * 5) / 100;
    // vendor %
    const adminshare1 =
      ((Order[i].TotalAmount - Order[i].Discount) *
        Order[i].vendors[0]?.Vendorpercentage) /
      100;
    const adminshare2 =
      Order[i].paymentMethod === "Pay At Spa"
        ? adminshare1 - Order[i].CouponDiscount
        : adminshare1 - Order[i].CouponDiscount - adminshare;

    const Slot = Order[i].slotbooked ? Order[i]?.slotbooked?.split("|") : [];
    data.push({
      Booking_Id: Order[i]._id,
      Booking_Date: moment(Order[i].createdAt).format("DD/MM/YYYY"),
      Category: Order[i].Service_Category,
      Service_Name: Order[i].ServiceName,
      Duration: Order[i].Service_Duration,
      ValidFor: Order[i].ValidFor,
      date: Order[i].date,
      fromTime: Order[i].fromTime,
      toTime: Order[i].toTime,
      Therapist: Order[i].Therapist,
      Customer_Name: Order[i].userName,
      Customer_Email_ID: Order[i].userEmailID,
      Customer_Contact_No: Order[i].userContactNumber,
      Spa_Discount: Order[i].Discount,
      Total_Price: Order[i].TotalAmount,
      CouponDiscount: Order[i].CouponDiscount,
      OnlinePayment_Discount:
        Order[i].paymentMethod === "Pay At Spa"
          ? 0
          : ((Order[i].TotalAmount - Order[i].Discount) * 5) / 100,
      Total_Amount_Should_Pay: Order[i].TotalPayableAmount,
      TotalAmountPaid: Order[i].TotalAmountPaid,
      Payment_Method: Order[i].paymentMethod,
      ThierapistBookingStatus: Order[i]?.ThierapistBookingStatus,
      rejectReason: Order[i]?.rejectReason,
      Assined_Therapist: Order[i]?.Assined_Therapist,
      BookingStatus: Order[i]?.BookingStatus,
      // Slot_Date: Slot[1],
      // Slot_From_Time: Slot[2],
      // Slot_End_Time: Slot[3],
      // Therapist1_id: Order[i].Therapist1,
      // Spa_Name: Order[i].VendorBusinessName,
      // Spa_Email: Order[i].VendorEmailID,
      // Contact_Number: Order[i].VendorContactNo,
      // Spa_Address: Order[i].VendorAddress,
      // Basic_Price: Order[i].Price,
      // Vendor_percentage: Order[i].vendors[0]?.Vendorpercentage,
      // Admin_Discount_Percentage:
      //   Order[i].vendors[0]?.Vendorpercentage -
      //   (Order[i].paymentMethod === "Pay At Spa" ? 0 : 5),
      // Total_Amount: vendorshare,
      // Admin_Share: adminshare2,
    });
  }

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (startDate && endDate) {
      const filteredData = data.filter((dtt) => {
        const bookingDate = moment(dtt.Booking_Date, "DD/MM/YYYY");
        return (
          bookingDate.isSameOrAfter(startDate, "day") &&
          bookingDate.isSameOrBefore(endDate, "day")
        );
      });
      setFilteredData(filteredData);
    }
  }, [startDate, endDate, data]);

  const offlinepaymentdata = data?.filter(
    (item) => item.Payment_Method === "Pay At Spa"
  );

  const onlinepaymentdata = data?.filter(
    (item) => item.Payment_Method === "Online Payment"
  );

  const pay20Pdata = data?.filter((item) => item.Payment_Method === "Pay 20%");

  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(data.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = data.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
    }
  };

  const exportType = "xls";

  const [fileName, setfileName] = useState("Booking");

  const ExportToExcel = () => {
    if (fileName) {
      if (data.length != 0) {
        exportFromJSON({ data, fileName, exportType });
        // setfileName("");
      } else {
        alert("There is no data to export");
        // setfileName("");
      }
    } else {
      alert("Enter file name to export");
    }
  };

  // console.log("filteredData", filteredData);

  // const customTotal = (from, to, size) => {
  //   return (
  //     <span className="react-bootstrap-table-pagination-total">
  //       Showing {from} to {to} of {size} Results
  //     </span>
  //   );
  // };

  // const options = {
  //   paginationSize: 4,
  //   pageStartIndex: 1,
  //   firstPageText: "First",
  //   prePageText: "Back",
  //   nextPageText: "Next",
  //   lastPageText: "Last",
  //   nextPageTitle: "First page",
  //   prePageTitle: "Pre page",
  //   firstPageTitle: "Next page",
  //   lastPageTitle: "Last page",
  //   showTotal: true,
  //   paginationTotalRenderer: customTotal,
  //   disablePageTitle: true,
  //   sizePerPageList: [
  //     {
  //       text: "3",
  //       value: 3,
  //     },
  //     {
  //       text: "5",
  //       value: 5,
  //     },
  //     {
  //       text: "10",
  //       value: 10,
  //     },
  //     {
  //       text: "All",
  //       value: data?.length,
  //     },
  //   ], // A numeric array is also available. the purpose of above example is custom the text
  // };

  // const { SearchBar } = Search;
  // const { ExportCSVButton } = CSVExport;
  // const columns = [
  //   {
  //     dataField: "",
  //     text: "Sl No",
  //     formatter: (value, row, index) => (
  //       <>
  //         <p>{index + 1}</p>
  //       </>
  //     ),
  //   },
  //   {
  //     dataField: "Booking_Id",
  //     text: "Booking Id",
  //     formatter: (value, row) => <p>{row?.Booking_Id}</p>,
  //   },
  //   {
  //     dataField: "Booking_Date",
  //     text: "Booking Date",
  //     formatter: (value, row) => <p>{row?.Booking_Date}</p>,
  //   },
  //   {
  //     dataField: "Category",
  //     text: "Category",
  //     formatter: (value, row) => <p>{row?.Category ? row?.Category : "-"}</p>,
  //   },
  //   {
  //     dataField: "Service_Name",
  //     text: "Service Name",
  //     formatter: (value, row) => (
  //       <p>{row?.Service_Name ? row?.Service_Name : "-"}</p>
  //     ),
  //   },
  //   {
  //     dataField: "Duration",
  //     text: "Duration",
  //     formatter: (value, row) => <p>{row?.Duration ? row?.Duration : "-"}</p>,
  //   },
  //   {
  //     dataField: "ValidFor",
  //     text: "ValidFor",
  //     formatter: (value, row) => <p>{row?.ValidFor ? row?.ValidFor : "-"}</p>,
  //   },
  //   {
  //     dataField: "date",
  //     text: "Slot Date",
  //     formatter: (value, row) => {
  //       return (
  //         <>
  //           <p>{row?.date}</p>
  //         </>
  //       );
  //     },
  //   },
  //   {
  //     dataField: "fromTime",
  //     text: "Slot From Time",
  //     formatter: (value, row) => {
  //       return (
  //         <>
  //           <p>{row?.fromTime}</p>
  //         </>
  //       );
  //     },
  //   },
  //   {
  //     dataField: "toTime",
  //     text: "Slot End Time",
  //     formatter: (value, row) => {
  //       return (
  //         <>
  //           <p>{row?.toTime}</p>
  //         </>
  //       );
  //     },
  //   },
  //   {
  //     dataField: "Therapist_id",
  //     text: "Therapist id",
  //     formatter: (value, row) => <p>{row?.Therapist ? row?.Therapist : "-"}</p>,
  //   },
  //   // {
  //   //   dataField: "Therapist1_id",
  //   //   text: "Therapist id",
  //   //   formatter: (value, row) => (
  //   //     <p>
  //   //       {row?.Therapist1_id && row?.ValidFor === "Couple"
  //   //         ? row?.Therapist1_id
  //   //         : "-"}
  //   //     </p>
  //   //   ),
  //   // },
  //   {
  //     dataField: "Customer_Name",
  //     text: "Customer Name",
  //     formatter: (value, row) => (
  //       <p>{row?.Customer_Name ? row?.Customer_Name : "-"}</p>
  //     ),
  //   },
  //   {
  //     dataField: "Customer_Email_ID",
  //     text: "Customer Email ID",
  //     formatter: (value, row) => (
  //       <p>{row?.Customer_Email_ID ? row?.Customer_Email_ID : "-"}</p>
  //     ),
  //   },
  //   {
  //     dataField: "Customer_Contact_No",
  //     text: "Customer Contact No.",
  //     formatter: (value, row) => (
  //       <p>{row?.Customer_Contact_No ? row?.Customer_Contact_No : "-"}</p>
  //     ),
  //   },
  //   // {
  //   //   dataField: "Spa_Name",
  //   //   text: "Spa Name",
  //   //   formatter: (value, row) => (
  //   //     <p style={{ overflowY: "scroll", height: "100px" }}>
  //   //       {row?.Spa_Name ? row?.Spa_Name : "-"}
  //   //     </p>
  //   //   ),
  //   // },
  //   // {
  //   //   dataField: "Spa_Email",
  //   //   text: "Spa Email",
  //   //   formatter: (value, row) => <p>{row?.Spa_Email ? row?.Spa_Email : "-"}</p>,
  //   // },
  //   // {
  //   //   dataField: "Spa_Address",
  //   //   text: "Spa Address",
  //   //   formatter: (value, row) => (
  //   //     <p style={{ overflowY: "scroll", height: "100px" }}>
  //   //       {row?.Spa_Address ? row?.Spa_Address : "-"}
  //   //     </p>
  //   //   ),
  //   // },
  //   {
  //     dataField: "Spa_Discount",
  //     text: "Spa Discount",
  //     formatter: (value, row) => (
  //       <p>{row?.Spa_Discount ? row?.Spa_Discount : "-"}</p>
  //     ),
  //   },
  //   {
  //     dataField: "Total_Price",
  //     text: "Total Price",
  //     formatter: (value, row) => (
  //       <p>{row?.Total_Price ? row?.Total_Price : "-"}</p>
  //     ),
  //   },
  //   {
  //     dataField: "CouponDiscount",
  //     text: "Coupon Discount",
  //     formatter: (value, row) => <p>{row?.CouponDiscount}</p>,
  //   },
  //   {
  //     dataField: "OnlinePayment_Discount",
  //     text: "Online Payment Discount",
  //     formatter: (value, row) => <p>{row?.OnlinePayment_Discount}</p>,
  //   },
  //   {
  //     dataField: "TotalPayableAmount",
  //     text: "Total Amount Should Pay",
  //     formatter: (value, row) => (
  //       <p>{row?.TotalPayableAmount ? row?.TotalPayableAmount : "-"}</p>
  //     ),
  //   },
  //   {
  //     dataField: "TotalAmountPaid",
  //     text: "Total Amount Paid",
  //     formatter: (value, row) => (
  //       <p>{row?.TotalAmountPaid ? row?.TotalAmountPaid : "-"}</p>
  //     ),
  //   },
  //   {
  //     dataField: "Payment_Method",
  //     text: "Payment Method",
  //     formatter: (value, row) => (
  //       <p
  //         style={{
  //           backgroundColor:
  //             row?.Payment_Method === "Pay At Spa"
  //               ? "#e22217"
  //               : row?.Payment_Method === "Online Payment"
  //               ? "Green"
  //               : "rgb(132, 207, 7)",
  //           color: "white",
  //         }}
  //       >
  //         {row?.Payment_Method}
  //       </p>
  //     ),
  //   },
  //   {
  //     dataField: "",
  //     text: "Select Therapist",
  //     formatter: (value, row) => {
  //       const abc = [];
  //       for (let i = 0; i < therapist.length; i++) {
  //         const xyz =
  //           therapist[i]?.orders?.length > 0
  //             ? therapist[i]?.orders?.filter((item) => {
  //                 // console.log(
  //                 //   "hsch",
  //                 //   item.date === row.date &&
  //                 //     item?.fromTime === row?.date &&
  //                 //     item?.toTime === row?.toTime
  //                 // );
  //                 return !(
  //                   item.date === row.date &&
  //                   item?.fromTime === row?.date &&
  //                   item?.toTime === row?.toTime
  //                 )
  //                   ? therapist[i]
  //                   : "";
  //               })
  //             : therapist[i];
  //         if (Object.keys(xyz).length > 0) {
  //           abc.push(therapist[i]);
  //         }
  //         // console.log("therapist[i]", i, therapist[i], therapist[i]?.EmpId);
  //       }
  //       return (
  //         <>
  //           <select onChange={(e) => selectfunction(e)}>
  //             <option>Select</option>
  //             {abc?.map((item) => (
  //               <option value={item.EmpId}>{item.EmpId}</option>
  //             ))}
  //           </select>
  //         </>
  //       );
  //     },
  //   },
  //   {
  //     dataField: "",
  //     text: "Assign Booking",
  //     formatter: (value, row) => (
  //       <button
  //         style={{
  //           border: "none",
  //           backgroundColor: "Blue",
  //           color: "white",
  //           padding: "5px",
  //         }}
  //         onClick={updateOrder
  //       >
  //         Assign
  //       </button>
  //     ),
  //   },
  // ];

  // console.log("selectedTherapist", data);

  console.log("user", user);

  return (
    <div className="never-sspa">
      <section>
        <div
          className="spa-admin-0 mb-2"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="diff-admin px-3">
            <div className="admin-name-text m-3">
              <h4>Booking</h4>
            </div>
          </div>
          <div
            style={{ padding: "30px", paddingBottom: "0px", display: "flex" }}
          >
            <div style={{ margin: "10px", marginBottom: "0px" }}>
              <p style={{ marginBottom: "0px" }}>Start Date</p>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div style={{ margin: "10px" }}>
              <p style={{ marginBottom: "0px" }}>End Date</p>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={30000}
          closeOnClick
          pauseOnHover
          draggable
        />
        <div style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}>
          <div
            style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}
          >
            {/* <ToolkitProvider
              keyField="id"
              data={
                paymentType === "All"
                  ? data
                  : paymentType === "Offline Payment"
                  ? offlinepaymentdata
                  : paymentType === "Online Payment"
                  ? onlinepaymentdata
                  : paymentType === "20% Payment"
                  ? pay20Pdata
                  : ""
              }
              columns={columns}
              // rowClasses={rowClasses}
              search
              exportCSV
            >
              {(props) => (
                <div>
                  <SearchBar {...props.searchProps} />
                  <ExportCSVButton
                    {...props.csvProps}
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                    }}
                  >
                    Download Excel
                  </ExportCSVButton>
                </div>
              )}
            </ToolkitProvider> */}
            <input
              placeholder="search"
              style={{
                padding: "5px",
                borderRadius: "10px",
              }}
              onChange={handleFilter}
            />
            <button
              style={{
                marginLeft: "30px",
                border: "none",
                color: "white",
                padding: "6px",
                borderRadius: "5px",
                backgroundColor: "#e22217",
              }}
              onClick={() => ExportToExcel()}
            >
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                {/* <AiFillFileWord /> */}
              </span>
              Download Excel
            </button>

            <button
              style={{
                marginLeft: "30px",
                border: "none",
                color: "white",
                padding: "6px",
                borderRadius: "5px",
                backgroundColor: "Blue",
              }}
              onClick={() => {
                setpaymentType("All");
                setStartDate("");
                setEndDate("");
              }}
            >
              All
            </button>
            <button
              style={{
                marginLeft: "30px",
                border: "none",
                color: "white",
                padding: "6px",
                borderRadius: "5px",
                backgroundColor: "#e22217",
              }}
              onClick={() => {
                setpaymentType("Offline Payment");
                setStartDate("");
                setEndDate("");
              }}
            >
              Offline Payment
            </button>
            <button
              style={{
                marginLeft: "30px",
                border: "none",
                color: "white",
                padding: "6px",
                borderRadius: "5px",
                backgroundColor: "Green",
              }}
              onClick={() => {
                setpaymentType("Online Payment");
                setStartDate("");
                setEndDate("");
              }}
            >
              Online Payment
            </button>

            <button
              style={{
                marginLeft: "30px",
                border: "none",
                color: "white",
                padding: "6px",
                borderRadius: "5px",
                backgroundColor: "#84cf07",
              }}
              onClick={() => {
                setpaymentType("20% Payment");
                setStartDate("");
                setEndDate("");
              }}
            >
              20% Payment
            </button>
            <hr></hr>
            <br></br>
            {/* <div style={{ overflowX: "scroll", width: "100%" }}>
                  <BootstrapTable
                      striped
                      hover
                      condensed
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                    />
                  </div> */}
            <Table striped responsive>
              <thead>
                <tr>
                  <th style={{ border: "1px solid #dee2e6" }}>Sl No</th>
                  <th style={{ border: "1px solid #dee2e6" }}>Booking Id</th>
                  <th style={{ border: "1px solid #dee2e6" }}>Booking Date</th>
                  <th style={{ border: "1px solid #dee2e6" }}>Category</th>
                  <th style={{ border: "1px solid #dee2e6" }}>Service Name</th>
                  <th style={{ border: "1px solid #dee2e6" }}>Duration</th>
                  <th style={{ border: "1px solid #dee2e6" }}>Valid For</th>
                  <th style={{ border: "1px solid #dee2e6" }}>Slot Date</th>
                  <th style={{ border: "1px solid #dee2e6" }}>
                    Slot From Time
                  </th>
                  <th style={{ border: "1px solid #dee2e6" }}>Slot End Time</th>
                  <th style={{ border: "1px solid #dee2e6" }}>Therapist id</th>
                  <th style={{ border: "1px solid #dee2e6" }}>Customer Name</th>
                  <th style={{ border: "1px solid #dee2e6" }}>
                    Customer Email ID
                  </th>
                  <th style={{ border: "1px solid #dee2e6" }}>
                    Customer Contact No.
                  </th>
                  <th style={{ border: "1px solid #dee2e6" }}>
                    {user?.type} Discount
                  </th>
                  <th style={{ border: "1px solid #dee2e6" }}>Total Price</th>
                  <th style={{ border: "1px solid #dee2e6" }}>
                    Coupon Discount
                  </th>
                  <th style={{ border: "1px solid #dee2e6" }}>
                    Online Payment Discount
                  </th>
                  <th style={{ border: "1px solid #dee2e6" }}>
                    Total Amount Should Pay
                  </th>
                  <th style={{ border: "1px solid #dee2e6" }}>
                    Total Amount Paid
                  </th>
                  <th style={{ border: "1px solid #dee2e6" }}>
                    Payment Method
                  </th>
                  <th style={{ border: "1px solid #dee2e6" }}>
                    Select Therapist
                  </th>
                  <th style={{ border: "1px solid #dee2e6" }}>
                    Assign Booking
                  </th>
                  <th style={{ border: "1px solid #dee2e6" }}>
                    Booking Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {search.length > 0 ? (
                  tableFilter
                    .slice(pagesVisited, pagesVisited + usersPerPage)
                    .map((item, index) => (
                      <>
                        <tr>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {index + 1}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.Booking_Id}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.Booking_Date}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.Category}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.Service_Name}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.Duration}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.ValidFor}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.date}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.fromTime}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.toTime}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.Therapist}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.Customer_Name}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.Customer_Email_ID}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.Customer_Contact_No}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.Spa_Discount}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.Total_Price}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.CouponDiscount}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.OnlinePayment_Discount}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.Total_Amount_Should_Pay}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.TotalAmountPaid}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            <p
                              style={{
                                backgroundColor:
                                  item?.Payment_Method === "Pay At Spa"
                                    ? "#e22217"
                                    : item?.Payment_Method === "Online Payment"
                                    ? "Green"
                                    : "rgb(132, 207, 7)",
                                color: "white",
                              }}
                            >
                              {user?.type === "Salon" &&
                              item?.Payment_Method === "Pay At Spa"
                                ? "Pay At Salon"
                                : item?.Payment_Method}
                            </p>
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.ThierapistBookingStatus === "Pending" ? (
                              <select onChange={(e) => selectfunction(e)}>
                                <option>Select</option>
                                {therapist?.map((item) => (
                                  <option value={item.EmpId}>
                                    {item.EmpId}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              <p>{item?.Assined_Therapist}</p>
                            )}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            {item?.ThierapistBookingStatus === "Assigned" ? (
                              <p
                                style={{
                                  backgroundColor: "#84cc07",
                                  color: "white",
                                }}
                              >
                                {item?.ThierapistBookingStatus}
                              </p>
                            ) : item?.ThierapistBookingStatus === "Pending" ? (
                              <button
                                style={{
                                  border: "none",
                                  backgroundColor: "Blue",
                                  color: "white",
                                  padding: "5px",
                                }}
                                onClick={() => updateOrder(item)}
                              >
                                Assign
                              </button>
                            ) : item?.ThierapistBookingStatus === "Accepted" ? (
                              <p
                                style={{
                                  backgroundColor: "Green",
                                  color: "white",
                                }}
                              >
                                {item?.ThierapistBookingStatus}
                              </p>
                            ) : (
                              <>
                                <p
                                  style={{
                                    backgroundColor: "#e22217",
                                    color: "white",
                                    marginBottom: "0px",
                                  }}
                                >
                                  {item?.ThierapistBookingStatus}
                                </p>
                                <FaEye
                                  style={{
                                    color: "#e84141",
                                    marginLeft: "30px",
                                  }}
                                  onClick={() => handleShow(item)}
                                />
                              </>
                            )}
                          </td>
                          <td style={{ border: "1px solid #dee2e6" }}>
                            <p
                              style={{
                                backgroundColor:
                                  item?.BookingStatus === "Booked"
                                    ? "Green"
                                    : "#e22217",
                                color: "white",
                              }}
                            >
                              {item?.BookingStatus}
                            </p>
                          </td>
                        </tr>
                      </>
                    ))
                ) : startDate && endDate ? (
                  <>
                    {filteredData
                      ?.slice(pagesVisited, pagesVisited + usersPerPage)
                      ?.map((item, index) => (
                        <>
                          <tr>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {index + 1}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Booking_Id}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Booking_Date}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Category}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Service_Name}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Duration}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.ValidFor}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.date}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.fromTime}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.toTime}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Therapist}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Customer_Name}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Customer_Email_ID}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Customer_Contact_No}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Spa_Discount}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Total_Price}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.CouponDiscount}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.OnlinePayment_Discount}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Total_Amount_Should_Pay}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.TotalAmountPaid}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              <p
                                style={{
                                  backgroundColor:
                                    item?.Payment_Method === "Pay At Spa"
                                      ? "#e22217"
                                      : item?.Payment_Method ===
                                        "Online Payment"
                                      ? "Green"
                                      : "rgb(132, 207, 7)",
                                  color: "white",
                                }}
                              >
                                {user?.type === "Salon" &&
                                item?.Payment_Method === "Pay At Spa"
                                  ? "Pay At Salon"
                                  : item?.Payment_Method}
                              </p>
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.ThierapistBookingStatus === "Pending" ? (
                                <select onChange={(e) => selectfunction(e)}>
                                  <option>Select</option>
                                  {therapist?.map((item) => (
                                    <option value={item.EmpId}>
                                      {item.EmpId}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <p>{item?.Assined_Therapist}</p>
                              )}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.ThierapistBookingStatus === "Assigned" ? (
                                <p
                                  style={{
                                    backgroundColor: "#84cc07",
                                    color: "white",
                                  }}
                                >
                                  {item?.ThierapistBookingStatus}
                                </p>
                              ) : item?.ThierapistBookingStatus ===
                                "Pending" ? (
                                <button
                                  style={{
                                    border: "none",
                                    backgroundColor: "Blue",
                                    color: "white",
                                    padding: "5px",
                                  }}
                                  onClick={() => updateOrder(item)}
                                >
                                  Assign
                                </button>
                              ) : item?.ThierapistBookingStatus ===
                                "Accepted" ? (
                                <p
                                  style={{
                                    backgroundColor: "Green",
                                    color: "white",
                                  }}
                                >
                                  {item?.ThierapistBookingStatus}
                                </p>
                              ) : (
                                <>
                                  <p
                                    style={{
                                      backgroundColor: "#e22217",
                                      color: "white",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {item?.ThierapistBookingStatus}
                                  </p>
                                  <FaEye
                                    style={{
                                      color: "#e84141",
                                      marginLeft: "30px",
                                    }}
                                    onClick={() => handleShow(item)}
                                  />
                                </>
                              )}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              <p
                                style={{
                                  backgroundColor:
                                    item?.BookingStatus === "Booked"
                                      ? "Green"
                                      : "#e22217",
                                  color: "white",
                                }}
                              >
                                {item?.BookingStatus}
                              </p>
                            </td>
                          </tr>
                        </>
                      ))}
                  </>
                ) : paymentType === "All" ? (
                  <>
                    {data
                      ?.slice(pagesVisited, pagesVisited + usersPerPage)
                      ?.map((item, index) => (
                        <>
                          <tr>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {index + 1}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Booking_Id}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Booking_Date}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Category}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Service_Name}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Duration}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.ValidFor}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.date}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.fromTime}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.toTime}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Therapist}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Customer_Name}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Customer_Email_ID}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Customer_Contact_No}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Spa_Discount}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Total_Price}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.CouponDiscount}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.OnlinePayment_Discount}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Total_Amount_Should_Pay}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.TotalAmountPaid}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              <p
                                style={{
                                  backgroundColor:
                                    item?.Payment_Method === "Pay At Spa"
                                      ? "#e22217"
                                      : item?.Payment_Method ===
                                        "Online Payment"
                                      ? "Green"
                                      : "rgb(132, 207, 7)",
                                  color: "white",
                                }}
                              >
                                {user?.type === "Salon" &&
                                item?.Payment_Method === "Pay At Spa"
                                  ? "Pay At Salon"
                                  : item?.Payment_Method}
                              </p>
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.ThierapistBookingStatus === "Pending" ? (
                                <select onChange={(e) => selectfunction(e)}>
                                  <option>Select</option>
                                  {therapist?.map((item) => (
                                    <option value={item.EmpId}>
                                      {item.EmpId}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <p>{item?.Assined_Therapist}</p>
                              )}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.ThierapistBookingStatus === "Assigned" ? (
                                <p
                                  style={{
                                    backgroundColor: "#84cc07",
                                    color: "white",
                                  }}
                                >
                                  {item?.ThierapistBookingStatus}
                                </p>
                              ) : item?.ThierapistBookingStatus ===
                                "Pending" ? (
                                <button
                                  style={{
                                    border: "none",
                                    backgroundColor: "Blue",
                                    color: "white",
                                    padding: "5px",
                                  }}
                                  onClick={() => updateOrder(item)}
                                >
                                  Assign
                                </button>
                              ) : item?.ThierapistBookingStatus ===
                                "Accepted" ? (
                                <p
                                  style={{
                                    backgroundColor: "Green",
                                    color: "white",
                                  }}
                                >
                                  {item?.ThierapistBookingStatus}
                                </p>
                              ) : (
                                <>
                                  <p
                                    style={{
                                      backgroundColor: "#e22217",
                                      color: "white",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {item?.ThierapistBookingStatus}
                                  </p>
                                  <FaEye
                                    style={{
                                      color: "#e84141",
                                      marginLeft: "30px",
                                    }}
                                    onClick={() => handleShow(item)}
                                  />
                                </>
                              )}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              <p
                                style={{
                                  backgroundColor:
                                    item?.BookingStatus === "Booked"
                                      ? "Green"
                                      : "#e22217",
                                  color: "white",
                                }}
                              >
                                {item?.BookingStatus}
                              </p>
                            </td>
                          </tr>
                        </>
                      ))}
                  </>
                ) : paymentType === "Offline Payment" ? (
                  <>
                    {offlinepaymentdata
                      ?.slice(pagesVisited, pagesVisited + usersPerPage)
                      ?.map((item, index) => (
                        <>
                          <tr>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {index + 1}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Booking_Id}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Booking_Date}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Category}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Service_Name}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Duration}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.ValidFor}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.date}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.fromTime}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.toTime}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Therapist}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Customer_Name}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Customer_Email_ID}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Customer_Contact_No}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Spa_Discount}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Total_Price}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.CouponDiscount}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.OnlinePayment_Discount}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Total_Amount_Should_Pay}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.TotalAmountPaid}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              <p
                                style={{
                                  backgroundColor:
                                    item?.Payment_Method === "Pay At Spa"
                                      ? "#e22217"
                                      : item?.Payment_Method ===
                                        "Online Payment"
                                      ? "Green"
                                      : "rgb(132, 207, 7)",
                                  color: "white",
                                }}
                              >
                                {user?.type === "Salon" &&
                                item?.Payment_Method === "Pay At Spa"
                                  ? "Pay At Salon"
                                  : item?.Payment_Method}
                              </p>
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.ThierapistBookingStatus === "Pending" ? (
                                <select onChange={(e) => selectfunction(e)}>
                                  <option>Select</option>
                                  {therapist?.map((item) => (
                                    <option value={item.EmpId}>
                                      {item.EmpId}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <p>{item?.Assined_Therapist}</p>
                              )}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.ThierapistBookingStatus === "Assigned" ? (
                                <p
                                  style={{
                                    backgroundColor: "#84cc07",
                                    color: "white",
                                  }}
                                >
                                  {item?.ThierapistBookingStatus}
                                </p>
                              ) : item?.ThierapistBookingStatus ===
                                "Pending" ? (
                                <button
                                  style={{
                                    border: "none",
                                    backgroundColor: "Blue",
                                    color: "white",
                                    padding: "5px",
                                  }}
                                  onClick={() => updateOrder(item)}
                                >
                                  Assign
                                </button>
                              ) : item?.ThierapistBookingStatus ===
                                "Accepted" ? (
                                <p
                                  style={{
                                    backgroundColor: "Green",
                                    color: "white",
                                  }}
                                >
                                  {item?.ThierapistBookingStatus}
                                </p>
                              ) : (
                                <>
                                  <p
                                    style={{
                                      backgroundColor: "#e22217",
                                      color: "white",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {item?.ThierapistBookingStatus}
                                  </p>
                                  <FaEye
                                    style={{
                                      color: "#e84141",
                                      marginLeft: "30px",
                                    }}
                                    onClick={() => handleShow(item)}
                                  />
                                </>
                              )}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              <p
                                style={{
                                  backgroundColor:
                                    item?.BookingStatus === "Booked"
                                      ? "Green"
                                      : "#e22217",
                                  color: "white",
                                }}
                              >
                                {item?.BookingStatus}
                              </p>
                            </td>
                          </tr>
                        </>
                      ))}
                  </>
                ) : paymentType === "Online Payment" ? (
                  <>
                    {onlinepaymentdata
                      ?.slice(pagesVisited, pagesVisited + usersPerPage)
                      ?.map((item, index) => (
                        <>
                          <tr>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {index + 1}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Booking_Id}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Booking_Date}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Category}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Service_Name}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Duration}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.ValidFor}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.date}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.fromTime}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.toTime}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Therapist}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Customer_Name}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Customer_Email_ID}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Customer_Contact_No}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Spa_Discount}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Total_Price}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.CouponDiscount}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.OnlinePayment_Discount}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Total_Amount_Should_Pay}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.TotalAmountPaid}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              <p
                                style={{
                                  backgroundColor:
                                    item?.Payment_Method === "Pay At Spa"
                                      ? "#e22217"
                                      : item?.Payment_Method ===
                                        "Online Payment"
                                      ? "Green"
                                      : "rgb(132, 207, 7)",
                                  color: "white",
                                }}
                              >
                                {user?.type === "Salon" &&
                                item?.Payment_Method === "Pay At Spa"
                                  ? "Pay At Salon"
                                  : item?.Payment_Method}
                              </p>
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.ThierapistBookingStatus === "Pending" ? (
                                <select onChange={(e) => selectfunction(e)}>
                                  <option>Select</option>
                                  {therapist?.map((item) => (
                                    <option value={item.EmpId}>
                                      {item.EmpId}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <p>{item?.Assined_Therapist}</p>
                              )}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.ThierapistBookingStatus === "Assigned" ? (
                                <p
                                  style={{
                                    backgroundColor: "#84cc07",
                                    color: "white",
                                  }}
                                >
                                  {item?.ThierapistBookingStatus}
                                </p>
                              ) : item?.ThierapistBookingStatus ===
                                "Pending" ? (
                                <button
                                  style={{
                                    border: "none",
                                    backgroundColor: "Blue",
                                    color: "white",
                                    padding: "5px",
                                  }}
                                  onClick={() => updateOrder(item)}
                                >
                                  Assign
                                </button>
                              ) : item?.ThierapistBookingStatus ===
                                "Accepted" ? (
                                <p
                                  style={{
                                    backgroundColor: "Green",
                                    color: "white",
                                  }}
                                >
                                  {item?.ThierapistBookingStatus}
                                </p>
                              ) : (
                                <>
                                  <p
                                    style={{
                                      backgroundColor: "#e22217",
                                      color: "white",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {item?.ThierapistBookingStatus}
                                  </p>
                                  <FaEye
                                    style={{
                                      color: "#e84141",
                                      marginLeft: "30px",
                                    }}
                                    onClick={() => handleShow(item)}
                                  />
                                </>
                              )}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              <p
                                style={{
                                  backgroundColor:
                                    item?.BookingStatus === "Booked"
                                      ? "Green"
                                      : "#e22217",
                                  color: "white",
                                }}
                              >
                                {item?.BookingStatus}
                              </p>
                            </td>
                          </tr>
                        </>
                      ))}
                  </>
                ) : paymentType === "20% Payment" ? (
                  <>
                    {pay20Pdata
                      ?.slice(pagesVisited, pagesVisited + usersPerPage)
                      ?.map((item, index) => (
                        <>
                          <tr>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {index + 1}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Booking_Id}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Booking_Date}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Category}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Service_Name}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Duration}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.ValidFor}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.date}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.fromTime}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.toTime}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Therapist}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Customer_Name}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Customer_Email_ID}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Customer_Contact_No}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Spa_Discount}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Total_Price}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.CouponDiscount}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.OnlinePayment_Discount}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.Total_Amount_Should_Pay}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.TotalAmountPaid}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              <p
                                style={{
                                  backgroundColor:
                                    item?.Payment_Method === "Pay At Spa"
                                      ? "#e22217"
                                      : item?.Payment_Method ===
                                        "Online Payment"
                                      ? "Green"
                                      : "rgb(132, 207, 7)",
                                  color: "white",
                                }}
                              >
                                {user?.type === "Salon" &&
                                item?.Payment_Method === "Pay At Spa"
                                  ? "Pay At Salon"
                                  : item?.Payment_Method}
                              </p>
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.ThierapistBookingStatus === "Pending" ? (
                                <select onChange={(e) => selectfunction(e)}>
                                  <option>Select</option>
                                  {therapist?.map((item) => (
                                    <option value={item.EmpId}>
                                      {item.EmpId}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <p>{item?.Assined_Therapist}</p>
                              )}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              {item?.ThierapistBookingStatus === "Assigned" ? (
                                <p
                                  style={{
                                    backgroundColor: "#84cc07",
                                    color: "white",
                                  }}
                                >
                                  {item?.ThierapistBookingStatus}
                                </p>
                              ) : item?.ThierapistBookingStatus ===
                                "Pending" ? (
                                <button
                                  style={{
                                    border: "none",
                                    backgroundColor: "Blue",
                                    color: "white",
                                    padding: "5px",
                                  }}
                                  onClick={() => updateOrder(item)}
                                >
                                  Assign
                                </button>
                              ) : item?.ThierapistBookingStatus ===
                                "Accepted" ? (
                                <p
                                  style={{
                                    backgroundColor: "Green",
                                    color: "white",
                                  }}
                                >
                                  {item?.ThierapistBookingStatus}
                                </p>
                              ) : (
                                <>
                                  <p
                                    style={{
                                      backgroundColor: "#e22217",
                                      color: "white",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {item?.ThierapistBookingStatus}
                                  </p>
                                  <FaEye
                                    style={{
                                      color: "#e84141",
                                      marginLeft: "30px",
                                    }}
                                    onClick={() => handleShow(item)}
                                  />
                                </>
                              )}
                            </td>
                            <td style={{ border: "1px solid #dee2e6" }}>
                              <p
                                style={{
                                  backgroundColor:
                                    item?.BookingStatus === "Booked"
                                      ? "Green"
                                      : "#e22217",
                                  color: "white",
                                }}
                              >
                                {item?.BookingStatus}
                              </p>
                            </td>
                          </tr>
                        </>
                      ))}
                  </>
                ) : (
                  ""
                )}
              </tbody>
            </Table>

            <div style={{ display: "flex" }}>
              <p style={{ width: "50%", marginTop: "20px" }}>
                Total Count: {data.length}
              </p>
              <ReactPaginate
                previousLabel={"Back"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <b style={{ color: "#e22217" }}>Reason for rejection :</b>{" "}
          {viewdata?.rejectReason}
        </Modal.Header>
        {viewdata?.Therapist === "No Preference" ? (
          <Modal.Body style={{ alignItems: "center", display: "contents" }}>
            <p style={{ textAlign: "center" }}>
              If you want to reasign to another therapist, please select
              therapist below
            </p>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                margin: "20px",
              }}
            >
              <select
                onChange={(e) => selectfunction(e)}
                style={{ height: "34px" }}
              >
                <option>Select</option>
                {therapist?.map((item) => (
                  <option value={item.EmpId}>{item.EmpId}</option>
                ))}
              </select>
              <button
                style={{
                  border: "none",
                  backgroundColor: "Blue",
                  color: "white",
                  padding: "5px",
                }}
                onClick={() => updateOrder(viewdata)}
              >
                Assign
              </button>
            </div>
          </Modal.Body>
        ) : (
          ""
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default VendorBooking;
