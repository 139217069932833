import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { AiOutlineLeft } from "react-icons/ai";
import { BiCalendar } from "react-icons/bi";
import {
  BsFillFilePersonFill,
  BsFillPersonFill,
  BsShieldCheck,
} from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import { isAfter, isBefore, isToday, addDays, format } from "date-fns";
import moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function VendorSelectTherapist() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const location = useLocation();
  const { item } = location.state;
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [Employee, setEmployee] = useState([]);
  const [Therapist, setTherapist] = useState({});

  useEffect(() => {
    getVendorEmployee();
  }, []);

  // const getVendorEmployee = () => {
  //   axios
  //     .get("https://spazo.co.in/api/Vendor/getVendorEmployee/" + item._id)
  //     .then(function (response) {
  //       console.log(response.data);
  //       setEmployee(response.data.Employee?.filter((item) => item?.EmpId));
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const getVendorEmployee = () => {
    axios
      .get("https://spazo.co.in/api/Vendor/getAllEmployee")
      .then(function (response) {
        console.log(response.data);
        setEmployee(
          response.data?.Employee?.filter((emp) => {
            return (
              emp?.isDeleted === false &&
              emp?.vendor[emp.vendor?.length - 1].VendorId === item?._id &&
              emp?.EmpId
            );
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log("Therapist", Therapist);
  console.log("Employee", Employee);
  return (
    <>
      <div
        className="row me-0 pt-2 pb-2"
        style={{
          boxShadow: "3px 4px 8px #cbc6c6",
          justifyContent: "space-between",
        }}
      >
        <Col md={2}>
          <div
            className="log0"
            style={{ paddingLeft: "20px" }}
            onClick={() => window.location.assign("/VendorEmployee")}
          >
            <img src="./logo.jpg" style={{ width: "110px", height: "38px" }} />
          </div>
        </Col>
        {!user ? (
          <Col md={2}>
            <div
              style={{ display: "flex", gap: "10px" }}
              onClick={() => window.location.assign("/login")}
            >
              <BsFillFilePersonFill size={25} />
              <p
                style={{
                  marginBottom: "0px",
                  fontWeight: "bold",
                }}
              >
                Login / Signup
              </p>
            </div>
          </Col>
        ) : (
          ""
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={30000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container style={{ margin: "5%" }}>
        {/* <Link to="/Newproductdetails" state={{ item: item }}> */}
        {/* <AiOutlineLeft
            style={{ color: "#e22217", fontSize: "28px", marginBottom: "4px" }}
          /> */}
        <span
          style={{
            color: "#e22217",
            fontSize: "22px",
            fontWeight: "bold",
          }}
        >
          Select team member
        </span>
        {/* </Link> */}

        <Row className="me-0">
          <Col md={8}>
            <Row>
              {Employee?.map((item) => (
                <Col md={3} className="mt-4">
                  <div
                    style={{
                      border: "1px solid",
                      padding: "30px 5px",
                      justifyContent: "center",
                      display: "grid",
                      borderRadius: "10px",
                      borderColor:
                        Therapist?._id === item?._id ? "#e22217" : "#848585",
                    }}
                    onClick={() => setTherapist(item)}
                  >
                    <img
                      src={"https://spazo.co.in/Employee/" + item?.profile}
                      width={50}
                      height={50}
                      style={{
                        borderRadius: "50%",
                        marginRight: "auto",
                        marginLeft: "auto",
                      }}
                    />
                    <p
                      style={{
                        marginBottom: "0px",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {item?.name}
                    </p>
                    <p style={{ marginBottom: "0px", textAlign: "center" }}>
                      {item?.Specialization}
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
          {Object.keys(Therapist).length > 0 ? (
            <Col md={4}>
              <div
                style={{
                  border: "1px solid rgb(217 212 208)",
                  padding: "15px",
                  margin: "10px",
                  borderRadius: "5px",
                }}
              >
                <div style={{ marginTop: "8px" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <img
                      src={"https://spazo.co.in/Employee/" + Therapist?.profile}
                      width={50}
                      height={50}
                      onClick={() =>
                        window.open(
                          "https://spazo.co.in/Employee/" + Therapist.profile
                        )
                      }
                      style={{ borderRadius: "50%" }}
                    />
                    <div>
                      <p style={{ marginBottom: "0px", fontWeight: "700" }}>
                        {Therapist?.name}
                      </p>
                      <p style={{ marginBottom: "0px" }}>
                        {Therapist?.Specialization}
                      </p>
                    </div>
                  </div>

                  <hr></hr>
                </div>
                <Link
                  style={{
                    backgroundColor: "rgb(226, 34, 23)",
                    color: "white",
                    border: "none",
                    marginTop: "30px",
                    padding: "5px 20px",
                    borderRadius: "5px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  to="/VendorSelectDate"
                  state={{ item: item, Therapist: Therapist }}
                >
                  Continue
                </Link>
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Container>
    </>
  );
}

export default VendorSelectTherapist;
