import React from "react";
import { Col, Row } from "react-bootstrap";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { BsFacebook } from "react-icons/bs";

function NewFooter() {
  return (
    <>
      <div
        style={{ backgroundColor: "#2f3133", color: "white", padding: "20px" }}
      >
        {/* #6d787d */}
        <div className="row me-0">
          <Col md={6}>
            <Row>
              <Col md={4}>
                <div className="log0">
                  <img
                    src="./logo-removebg.png"
                    style={{ width: "130px", height: "80px" }}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <a style={{ color: "white" }}>About Us</a> <br></br>
                  <a href="/TermsCondition" style={{ color: "white" }}>
                    Terms and conditions
                  </a>
                  <br></br>
                  <a href="/Privacy" style={{ color: "white" }}>
                    Privacy Policy
                  </a>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <a href="/NewHome" style={{ color: "white" }}>
                    Home
                  </a>{" "}
                  <br></br>
                  <a style={{ color: "white" }}>About us</a>
                  <br></br>
                  <a href="/contactUS" style={{ color: "white" }}>
                    Contact us
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={2}></Col>
          <Col md={4}>
            <div className="app-o">
              <img
                src="../Assets/images/google-play.svg"
                alt="google-play"
                style={{
                  width: "151px",
                  height: "100px",
                  marginRight: "10px",
                }}
              />
              <img
                src="../Assets/images/app-store.svg"
                alt="google-play"
                style={{ width: "151px", height: "100px" }}
              />
            </div>
          </Col>
        </div>

        <hr style={{ color: "white" }}></hr>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ gap: "10px", display: "flex" }}>
            <i class="fab fa-facebook-square" style={{ fontSize: "30px" }}></i>
            <i class="fab fa-instagram-square" style={{ fontSize: "30px" }}></i>
            <i class="fab fa-twitter-square" style={{ fontSize: "30px" }}></i>
            <i class="fab fa-youtube" style={{ fontSize: "30px" }}></i>
          </div>
          <div>2023-2024 © Designed and Developed by Parnets</div>
        </div>
      </div>
    </>
  );
}

export default NewFooter;
