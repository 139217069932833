import React from "react";
import "swiper/css/navigation";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

function Slider1() {
  const [data, setdata] = useState([]);

  useEffect(() => {
    getallBanners();
  }, []);

  const getallBanners = () => {
    axios
      .get("https://spazo.co.in/api/admin/getBanner1")
      .then(function (response) {
        console.log(response.data);
        setdata(response.data.bannerList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log("datadatadata", data);
  return (
    <>
      <section>
        <div
          className="carsouel"
          data-aos="fade-up"
          data-aos-delay="600"
          data-aos-duration="3000"
        >
          <Swiper
            scrollbar={{ draggable: true }}
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            pagination={{
              clickable: true,
            }}
            // autoplay={{
            //   delay: 4000,
            //   disableOnInteraction: false,
            // }}
            navigation={true}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
          >
            {data?.map((row) => (
              <SwiperSlide>
                <div
                  style={{
                    backgroundImage: `url("https://spazo.co.in/Banner1/${row.bannerimg}")`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    height: "201px",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                ></div>
              </SwiperSlide>
            ))}
            {/* <SwiperSlide>
              {" "}
              <div
                style={{
                  backgroundImage: "url('../../assets/images/slider2.webp')",
                  backgroundPosition: "center",
                  backggroundSize: "cover",
                  height: "201px",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                style={{
                  backgroundImage: "url('../../assets/images/slider3.webp')",
                  backgroundPosition: "center",
                  backggroundSize: "cover",
                  height: "201px",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
            </SwiperSlide> */}
          </Swiper>
        </div>
      </section>
    </>
  );
}

export default Slider1;
