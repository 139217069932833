import { Col, Container, Row } from "react-bootstrap";
import Newheader2 from "./Newheader2";
import { React, useEffect, useState } from "react";
import NewFooter from "./NewFooter";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { FaEye, FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Customerprofile() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const user = JSON.parse(sessionStorage.getItem("user"));
  const [selectedType, setselectedType] = useState("My Profile");
  const [edit, setedit] = useState(false);
  const [data, setdata] = useState([]);

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phoneNumber, setphoneNumber] = useState();
  const [password, setpassword] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (user) {
      getOrder();
    }
  }, []);

  const getOrder = () => {
    axios
      .get("https://spazo.co.in/api/customer/getOrder")
      .then(function (response) {
        // console.log(response.data);
        setdata(
          response.data.Order?.filter((item) => item?.userID === user?._id)
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function CheckPassword(inputtxt) {
    var decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    if (inputtxt.match(decimal)) {
      return true;
    } else {
      alert(
        "Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character!"
      );
      return false;
    }
  }

  const Updateprofile = async (e) => {
    if (password) {
      if (CheckPassword(password)) {
        try {
          const config = {
            url: "/Updateprofile",
            method: "post",
            baseURL: "https://spazo.co.in/api/customer",
            headers: { "content-type": "application/json" },
            data: {
              userId: user?._id,
              name: name ? name : user?.name,
              email: email ? email : user?.email,
              phoneNumber: phoneNumber ? phoneNumber : user?.phoneNumber,
              password: password ? password : user?.password,
            },
          };
          let res = await axios(config);
          if (res.status === 200) {
            sessionStorage.setItem("user", JSON.stringify(res.data.user));
            notify("Profile updated successfully");
            window.location.reload();
          }
        } catch (error) {
          console.log(error.response);
          if (error.response) {
            notify1(error.response.data.error);
          }
        }
      }
    } else {
      try {
        const config = {
          url: "/Updateprofile",
          method: "post",
          baseURL: "https://spazo.co.in/api/customer",
          headers: { "content-type": "application/json" },
          data: {
            userId: user?._id,
            name: name ? name : user?.name,
            email: email ? email : user?.email,
            phoneNumber: phoneNumber ? phoneNumber : user?.phoneNumber,
            password: password ? password : user?.password,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          sessionStorage.setItem("user", JSON.stringify(res.data.user));
          notify("Profile updated successfully");
          window.location.reload();
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          notify1(error.response.data.error);
        }
      }
    }
  };

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "3",
        value: 3,
      },
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "Booking_Id",
      text: "Booking Id",
      formatter: (value, row) => <p>{row?._id}</p>,
    },
    {
      dataField: "Booking_Date",
      text: "Booking Date",
      formatter: (value, row) => (
        <p>{moment(row?.createdAt).format("DD/MM/YYYY")}</p>
      ),
    },
    {
      dataField: "Service_Category",
      text: "Category",
      formatter: (value, row) => (
        <p>{row?.Service_Category ? row?.Service_Category : "-"}</p>
      ),
    },
    {
      dataField: "ServiceName",
      text: "Service Name",
      formatter: (value, row) => (
        <p>{row?.ServiceName ? row?.ServiceName : "-"}</p>
      ),
    },
    {
      dataField: "Service_Duration",
      text: "Duration",
      formatter: (value, row) => (
        <p>{row?.Service_Duration ? row?.Service_Duration : "-"}</p>
      ),
    },
    {
      dataField: "ValidFor",
      text: "ValidFor",
      formatter: (value, row) => <p>{row?.ValidFor ? row?.ValidFor : "-"}</p>,
    },
    {
      dataField: "date",
      text: "Slot Date",
      formatter: (value, row) => {
        return (
          <>
            <p>{row?.date}</p>
          </>
        );
      },
    },
    {
      dataField: "fromTime",
      text: "Slot From Time",
      formatter: (value, row) => {
        return (
          <>
            <p>{row?.fromTime}</p>
          </>
        );
      },
    },
    {
      dataField: "toTime",
      text: "Slot End Time",
      formatter: (value, row) => {
        return (
          <>
            <p>{row?.toTime}</p>
          </>
        );
      },
    },
    {
      dataField: "Therapist",
      text: "Therapist Id",
      formatter: (value, row) => (
        <p>{row?.Therapist ? row?.Therapist : "No Preference"}</p>
      ),
    },

    {
      dataField: "VendorBusinessName",
      text: "Spa/Salon Name",
      formatter: (value, row) => (
        <p style={{ overflowY: "scroll", height: "100px" }}>
          {row?.VendorBusinessName ? row?.VendorBusinessName : "-"}
        </p>
      ),
    },
    {
      dataField: "VendorEmailID",
      text: "Spa/Salon Email",
      formatter: (value, row) => (
        <p>{row?.VendorEmailID ? row?.VendorEmailID : "-"}</p>
      ),
    },
    {
      dataField: "VendorAddress",
      text: "Spa/Salon Address",
      formatter: (value, row) => (
        <p style={{ overflowY: "scroll", height: "100px" }}>
          {row?.VendorAddress ? row?.VendorAddress : "-"}
        </p>
      ),
    },
    {
      dataField: "TotalDiscount",
      text: "Discount",
      formatter: (value, row) => (
        <p>{row?.TotalDiscount ? row?.TotalDiscount : "-"}</p>
      ),
    },
    {
      dataField: "TotalAmount",
      text: "Total Price",
      formatter: (value, row) => (
        <p>{row?.TotalAmount ? row?.TotalAmount : "-"}</p>
      ),
    },
    {
      dataField: "TotalPayableAmount",
      text: "Total Amount Should Pay",
      formatter: (value, row) => (
        <p>{row?.TotalPayableAmount ? row?.TotalPayableAmount : "-"}</p>
      ),
    },
    {
      dataField: "TotalAmountPaid",
      text: "Total Amount Paid",
      formatter: (value, row) => (
        <p>{row?.TotalAmountPaid ? row?.TotalAmountPaid : "-"}</p>
      ),
    },
    {
      dataField: "paymentMethod",
      text: "Payment Method",
      formatter: (value, row) => (
        <p
        // style={{
        //   backgroundColor:
        //     row?.paymentMethod === "Pay At Spa"
        //       ? "#e22217"
        //       : row?.paymentMethod === "Online Payment"
        //       ? "Green"
        //       : "rgb(132, 207, 7)",
        //   color: "white",
        // }}
        >
          {row?.paymentMethod}
        </p>
      ),
    },
    {
      dataField: "",
      text: "Booking Status",
      formatter: (value, row) => (
        <>
          <p
            style={{
              backgroundColor:
                row?.BookingStatus === "Booked" ? "Green" : "#e22217",
              color: "white",
              textAlign: "center",
            }}
          >
            {row?.BookingStatus}
          </p>
        </>
      ),
    },
  ];

  console.log("data", edit);
  return (
    <>
      <div>
        <Newheader2 />
        <Container fluid style={{ height: "100vh" }}>
          <Row className="mt-5">
            <Col md={2}>
              <div
                style={{
                  backgroundColor: "#e22217",
                  color: "white",
                  padding: "5px",
                  margin: "5px",
                  textAlign: "center",
                }}
                onClick={() => setselectedType("My Profile")}
              >
                My Profile
              </div>
              <div
                style={{
                  backgroundColor: "#e22217",
                  color: "white",
                  padding: "5px",
                  margin: "5px",
                  textAlign: "center",
                }}
                onClick={() => setselectedType("My Orders")}
              >
                My Orders
              </div>
            </Col>
            <Col md={10}>
              {selectedType === "My Profile" ? (
                <div>
                  {!edit ? (
                    <div>
                      <div
                        style={{
                          fontSize: "30px",
                          fontWeight: "bold",
                          color: "#e22217",
                        }}
                      >
                        My Profile
                      </div>
                      <Row style={{ alignItems: "center" }}>
                        <Col md={2}>Name :</Col>
                        <Col md={3}>{user?.name}</Col>
                      </Row>
                      <Row>
                        <Col md={2}>Email Address :</Col>
                        <Col md={3}>{user?.email}</Col>
                      </Row>
                      <Row>
                        <Col md={2}>Phone Number :</Col>
                        <Col md={3}>{user?.phoneNumber}</Col>
                      </Row>
                      <Row>
                        <Col md={2}>Password :</Col>
                        <Col md={3}>*********</Col>
                      </Row>

                      <button
                        style={{
                          backgroundColor: "rgb(226, 34, 23)",
                          border: "none",
                          color: "white",
                          padding: "5px 10px",
                          margin: "30px 80px",
                        }}
                        onClick={() => setedit(true)}
                      >
                        Edit Profile
                      </button>
                    </div>
                  ) : (
                    <div>
                      <div
                        style={{
                          fontSize: "30px",
                          fontWeight: "bold",
                          color: "#e22217",
                        }}
                      >
                        My Profile
                      </div>
                      <Row style={{ alignItems: "center" }}>
                        <Col md={2}>Name :</Col>
                        <Col md={3}>
                          <input
                            placeholder={user?.name}
                            onChange={(e) => setname(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row style={{ alignItems: "center" }} className="mt-3">
                        <Col md={2}>Email Address :</Col>
                        <Col md={3}>
                          <input
                            placeholder={user?.email}
                            onChange={(e) => setemail(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row style={{ alignItems: "center" }} className="mt-3">
                        <Col md={2}>Phone Number :</Col>
                        <Col md={3}>
                          <input
                            placeholder={user?.phoneNumber}
                            type="tele"
                            maxLength={10}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onChange={(e) => setphoneNumber(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row style={{ alignItems: "center" }} className="mt-3">
                        <Col md={2}>Password :</Col>
                        <Col md={3}>
                          <input
                            placeholder="********"
                            onChange={(e) => setpassword(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <button
                        style={{
                          backgroundColor: "rgb(226, 34, 23)",
                          border: "none",
                          color: "white",
                          padding: "5px 10px",
                          margin: "30px 80px",
                        }}
                        onClick={Updateprofile}
                      >
                        Update Profile
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
              {selectedType === "My Orders" ? (
                <div>
                  <div
                    style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      color: "#e22217",
                    }}
                  >
                    My Orders
                  </div>

                  <div
                    style={{
                      overflow: "hidden",
                      overflowY: "auto",
                      margin: "20px",
                      height: "500px",
                    }}
                  >
                    <ToolkitProvider
                      keyField="id"
                      data={data}
                      columns={columns}
                      // rowClasses={rowClasses}
                      search
                      exportCSV
                    >
                      {(props) => (
                        <div>
                          <SearchBar {...props.searchProps} />
                          {/* <ExportCSVButton
                            {...props.csvProps}
                            style={{
                              backgroundColor: "#e22217",
                              color: "white",
                              marginLeft: "10px",
                            }}
                          >
                            Download Excel
                          </ExportCSVButton> */}
                          <hr></hr>
                          <br></br>
                          <div
                            style={{
                              overflowX: "scroll",
                              width: "100%",
                            }}
                          >
                            <BootstrapTable
                              striped
                              hover
                              condensed
                              {...props.baseProps}
                              pagination={paginationFactory(options)}
                            />
                          </div>
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Customerprofile;
