import React from "react";
import Vendorheader from "./Vendorheader";
import Vendorsidebar from "./Vendorsidebar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaEye, FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Fill, RiDeleteBin5Fill } from "react-icons/ri";
import BootstrapTable from "react-bootstrap-table-next";
import ReactStars from "react-rating-stars-component";
import ToolkitProvider, {
  Search,
  CSVExport,
  transform,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { isAfter, isBefore, isToday, addDays, format } from "date-fns";
import { AiFillEdit, AiFillStar } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

function VendorEmployee() {
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const Input = styled("input")({
    display: "none",
  });

  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    alert("You have entered an invalid email address!");
    return false;
  }

  function validatename(inputtxt) {
    var phoneno = /^[a-zA-Z ]{2,30}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid name!");
      return false;
    }
  }

  function phonenumber(inputtxt) {
    var phoneno = /^[6-9]\d{9}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid mobile number!");
      return false;
    }
  }

  const formatTimeTo12hr = (time) => {
    const [hours, minutes] = time?.split(":");
    const parsedHours = parseInt(hours, 10);

    // Determine AM or PM
    const period = parsedHours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    const displayHours = parsedHours % 12 || 12;

    // Combine the formatted time
    const formattedTime = `${displayHours}:${minutes} ${period}`;
    return formattedTime;
  };

  const formdata = new FormData();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [profile, setprofile] = useState("");
  const [adharF, setadharF] = useState("");
  const [adharB, setadharB] = useState("");
  const [PanF, setPanF] = useState("");
  const [PanB, setPanB] = useState("");
  const [name, setname] = useState("");
  const [age, setage] = useState("");
  const [Email, setEmail] = useState("");
  const [contactnumber, setcontactnumber] = useState();
  const [Specialization, setSpecialization] = useState("");
  const [Gender, setGender] = useState("");
  const [AdditionalImage, setAdditionalImage] = useState("");

  const [array1, setarray1] = useState([]);
  const [AdditionalImageArray, setAdditionalImageArray] = useState([]);

  const addAdditionalImage = () => {
    if (!AdditionalImage) {
      notify2("Please Upload Image");
    } else {
      let obj = {
        AdditionalImage: AdditionalImage,
      };
      array1.push(obj);
      setAdditionalImageArray(array1);
      setAdditionalImage("");
    }
  };

  const [profile1, setprofile1] = useState("");
  const [adharF1, setadharF1] = useState("");
  const [adharB1, setadharB1] = useState("");
  const [PanF1, setPanF1] = useState("");
  const [PanB1, setPanB1] = useState("");
  const [name1, setname1] = useState("");
  const [age1, setage1] = useState("");
  const [Email1, setEmail1] = useState("");
  const [contactnumber1, setcontactnumber1] = useState();
  const [Specialization1, setSpecialization1] = useState("");
  const [Gender1, setGender1] = useState("");

  const [AdditionalImage1, setAdditionalImage1] = useState([]);

  const [array11, setarray11] = useState([]);
  const [AdditionalImageArray1, setAdditionalImageArray1] = useState([]);

  useEffect(() => {
    if (AdditionalImage1) {
      const xyz = [];
      for (let i = 0; i < Object.keys(AdditionalImage1).length; i++) {
        xyz.push({ AdditionalImage: AdditionalImage1[i] });
      }
      setAdditionalImageArray1(xyz);
    }
  }, [AdditionalImage1]);

  const today = new Date();
  const maxDate = addDays(today, 7); // Today + 7 days
  const [selectedDate, setSelectedDate] = useState(today);
  const [date1, setdate1] = useState();
  const [Availability, setAvailability] = useState("");
  const [From_Time, setFrom_Time] = useState("");
  const [To_Time, setTo_Time] = useState("");
  const [Reason, setReason] = useState("");

  const Employee_from_time = From_Time ? formatTimeTo12hr(From_Time) : "";
  const Employee_To_time = To_Time ? formatTimeTo12hr(To_Time) : "";

  const [data, setdata] = useState([]);
  const [deletedEmployee, setdeletedEmployee] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showExistingEmpReg, setShowExistingEmpReg] = useState(false);
  const handleCloseshowExistingEmpReg = () => setShowExistingEmpReg(false);
  const handleShowExistingEmpReg = () => setShowExistingEmpReg(true);

  const [showSelectTypeOfEmp, setShowSelectTypeOfEmp] = useState(false);
  const handleCloseSelectTypeOfEmp = () => setShowSelectTypeOfEmp(false);
  const handleShowSelectTypeOfEmp = () => setShowSelectTypeOfEmp(true);
  const [selectedEmpID, setselectedEmpID] = useState("");
  const [selectedEmpDetails, setselectedEmpDetails] = useState({});

  console.log(
    "deletedEmployee",
    deletedEmployee,
    selectedEmpID,
    selectedEmpDetails
  );

  const top100Films = [];
  for (let i = 0; i < deletedEmployee?.length; i++) {
    top100Films.push({ label: deletedEmployee[i]?.EmpId });
  }

  useEffect(() => {
    if (selectedEmpID) {
      const xyz = deletedEmployee?.find(
        (item) => item?.EmpId === selectedEmpID
      );
      setselectedEmpDetails(xyz);
    }
  }, [selectedEmpID]);

  const [selecttype, setselecttype] = useState("");

  const selectEmployee = () => {
    if (selecttype === "New Regitration") {
      handleShow();
      handleCloseSelectTypeOfEmp();
    } else if (selecttype === "Existing Therapist") {
      handleShowExistingEmpReg();
      handleCloseSelectTypeOfEmp();
    } else {
      notify2("Please select registration type");
    }
  };

  const [show1, setShow1] = useState(false);
  const [Empdata, setEmpdata] = useState({});
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setEmpdata(item);
  };

  const [show2, setShow2] = useState(false);
  const [Empvaliddata, setEmpvaliddata] = useState({});
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setEmpvaliddata(item);
  };

  const [showOrder, setShowOrder] = useState(false);
  const handleCloseOrder = () => setShowOrder(false);
  const handleShowOrder = (item) => {
    setShowOrder(true);
    setEmpvaliddata(item);
  };

  const [showduty, setShowduty] = useState(false);
  const [dutydata, setdutydata] = useState(false);

  const handleCloseduty = () => setShowduty(false);
  const handleShowduty = (item) => {
    setShowduty(true);
    setdutydata(item);
  };

  const [show3, setShow3] = useState(false);
  const [viewReason, setviewReason] = useState({});

  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setviewReason(item);
  };

  const [showEdit, setShowEdit] = useState(false);
  const [Edit, setEdit] = useState({});

  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = (item) => {
    setShowEdit(true);
    setEdit(item);
  };

  const [showDelete, setShowDelete] = useState(false);
  const [Delete, setDelete] = useState({});

  const [reasonForDelete, setreasonForDelete] = useState("");
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = (item) => {
    setShowDelete(true);
    setDelete(item);
  };

  const [showBlock, setShowBlock] = useState(false);

  const handleCloseBlock = () => setShowBlock(false);
  const handleShowBlock = () => setShowBlock(true);

  const [showrevive, setShowrevive] = useState(false);
  const [reviwe, setreviwe] = useState({});

  const handleCloserevive = () => setShowrevive(false);
  const handleShowrevive = (item) => {
    setShowrevive(true);
    setreviwe(item);
  };

  const handleDateChange = (event) => {
    const selected = new Date(event.target.value);

    if (isAfter(selected, today) || isToday(selected)) {
      setSelectedDate(selected);
      setdate1(selected);
      // if (isBefore(selected, maxDate) || isToday(selected)) {
      //   setSelectedDate(selected);
      //   setdate1(selected);
      // } else {
      //   // alert("Selected date must be within the next 7 days.");
      //   notify2("Selected date must be within the next 7 days.");
      // }
    } else {
      // alert("Selected date must be today or a future date.");
      notify2("Selected date must be today or a future date.");
    }
  };

  let form2 = new FormData();
  const addEmployee = async () => {
    if (!profile) {
      notify2("Please upload Employee Profile Photo");
    } else if (
      !name ||
      !age ||
      !Specialization ||
      !Gender ||
      !contactnumber ||
      !Email
    ) {
      notify2("Please fill all the fields");
    } else if (!adharF || !adharB) {
      notify2("Please upload both sides of Aadhaar card");
    } else if (!PanF || !PanB) {
      notify2("Please upload both sides of Pan card");
    } else {
      formdata.append("VendorId", user._id);
      formdata.append("Spa_Name", user.businessName);
      formdata.append("Vendor_Name", user.name);
      formdata.append("Spa_Contact_Number", user.phoneNumber);
      formdata.append("SPa_Email_ID", user.email);
      formdata.append("Type", user.type);
      // formdata.append(
      //   "EmpId",
      //   user?.businessName?.slice(0, 4) + " " + (data?.length + 1)
      // );
      formdata.append("profile", profile);
      formdata.append("adharF", adharF);
      formdata.append("adharB", adharB);
      formdata.append("PanF", PanF);
      formdata.append("PanB", PanB);
      formdata.append("name", name);
      formdata.append("age", age);
      formdata.append("contactnumber", contactnumber);
      formdata.append("Email", Email);
      formdata.append("Specialization", Specialization);
      formdata.append("isDeleted", "false");
      formdata.append("Gender", Gender);

      try {
        if (
          validatename(name) &&
          ValidateEmail(Email) &&
          phonenumber(contactnumber)
        ) {
          const config = {
            url: "/AddEmployee",
            method: "post",
            baseURL: "https://spazo.co.in/api/Vendor",
            data: formdata,
          };
          await axios(config).then(function (res) {
            if ((res.status = 200)) {
              // alert("Data Added successfully");
              // window.location.reload();
              console.log("id", res);
              addEmployeeAdditionalImage(res.data.data._id);
              notify("Data Added successfully");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              // alert(res.status.error);
              notify1(res.status.error);
            }
          });
        }
      } catch (error) {
        console.log(error);
        // alert("Unable to add Details");
        notify1(error?.response?.data?.error);
      }
    }
  };

  const addEmployeeAdditionalImage = async (data) => {
    try {
      AdditionalImageArray?.forEach(async (ImageObj, index) => {
        form2.append(`id`, data);
        form2.append(`AdditionalImage`, ImageObj.AdditionalImage);
        const config = {
          url: "/uploadEmpAdditionalImage",
          method: "post",
          baseURL: "https://spazo.co.in/api/vendor",
          data: form2,
        };
        await axios(config);
      });
    } catch (error) {
      console.log(error);
      notify1(error?.response?.data?.error);
    }
  };

  const addExistingEmp = async (data) => {
    // formdata.append("id", data?._id);
    // formdata.append("VendorId", user?._id);
    // formdata.append("Spa_Name", user?.businessName);
    // formdata.append("Vendor_Name", user?.name);
    // formdata.append("Spa_Contact_Number", user?.phoneNumber);
    // formdata.append("SPa_Email_ID", user?.email);
    // formdata.append("Type", user?.type);
    try {
      const config = {
        url: "/addExistingEmp",
        method: "post",
        baseURL: "https://spazo.co.in/api/Vendor",
        data: {
          id: data?._id,
          VendorId: user?._id,
          Spa_Name: user?.businessName,
          Vendor_Name: user?.name,
          Spa_Contact_Number: user?.phoneNumber,
          SPa_Email_ID: user?.email,
          Type: user?.type,
        },
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          // alert("Data Added successfully")
          notify("Data Added successfully");
          handleCloseEdit();
          getVendorEmployee();
          window.location.reload();
        } else {
          // alert(res.status.error);
          notify1(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      // alert("Unable to add Details");
      notify1(error?.response?.data?.error);
    }
  };

  const EditEmployee = async (data) => {
    formdata.append("id", data?._id);
    formdata.append("profile", profile1 ? profile1 : data?.profile);
    formdata.append("name", name1 ? name1 : data?.name);
    formdata.append("age", age1 ? age1 : data?.age);
    formdata.append("Email", Email1 ? Email1 : data?.Email);
    formdata.append(
      "contactnumber",
      contactnumber1 ? contactnumber1 : data?.contactnumber
    );
    formdata.append(
      "Specialization",
      Specialization1 ? Specialization1 : data?.Specialization
    );
    formdata.append("Gender", Gender1 ? Gender1 : data?.Gender);
    formdata.append("adharF", adharF1 ? adharF1 : data?.adharF);
    formdata.append("adharB", adharB1 ? adharB1 : data?.adharB);
    formdata.append("PanF", PanF1 ? PanF1 : data?.PanF);
    formdata.append("PanB", PanB1 ? PanB1 : data?.PanB);
    try {
      const config = {
        url: "/editEmployee",
        method: "post",
        baseURL: "https://spazo.co.in/api/Vendor",
        data: formdata,
        // {
        //   id: data?._id,
        //   profile: profile1 ? profile1 : data?.profile,
        //   name: name1 ? name1 : data?.name,
        //   age: age1 ? age1 : data?.age,
        //   Email: Email1 ? Email1 : data?.Email,
        //   contactnumber: contactnumber1 ? contactnumber1 : data?.contactnumber,
        //   Specialization: Specialization1
        //     ? Specialization1
        //     : data?.Specialization,
        //   Gender: Gender1 ? Gender1 : data?.Gender,
        //   adharF: adharF1 ? adharF1 : data?.adharF,
        //   adharB: adharB1 ? adharB1 : data?.adharB,
        //   PanF: PanF1 ? PanF1 : data?.PanF,
        //   PanB: PanB1 ? PanB1 : data?.PanB,
        // },
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          // alert("Data Added successfully");
          console.log("skdmkds", res);
          editEmpImage(res.data.data._id);
          notify("Data updated successfully");
          handleCloseEdit();
          getVendorEmployee();
          // window.location.reload();
        } else {
          // alert(res.status.error);
          notify1(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      // alert("Unable to add Details");
      notify1(error?.response?.data?.error);
    }
  };

  const editEmpImage = async (id) => {
    AdditionalImageArray1?.forEach(async (adharObj, index) => {
      form2.append("id", id);
      form2.append("AdditionalImage", adharObj.AdditionalImage);
      try {
        const config = {
          url: "/uploadEmpAdditionalImage",
          method: "post",
          baseURL: "https://spazo.co.in/api/vendor",
          data: form2,
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            // alert("Data Added successfully");
          } else {
            // alert(res.status.error);
            notify1(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        // alert("Unable to add Details");
        notify1("Unable to add Details, Please try after some time");
      }
    });
  };

  const deleteEmpImage = async (EmpId, ImageId) => {
    try {
      const config = {
        url: "/deleteEmpAdditionalImage/" + EmpId?._id + "/" + ImageId?._id,
        method: "delete",
        baseURL: "https://spazo.co.in/api/vendor",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          window.location.reload();
          getVendorEmployee();
          // setEdit(EmpId);
          console.log(res);
        } else {
          // alert(res.status.error);
          notify1(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      // alert("Unable to add Details");
      notify1("Unable to add Details, Please try after some time");
    }
  };

  useEffect(() => {
    getVendorEmployee();
  }, []);

  useEffect(() => {
    if (Empvaliddata) {
      getTherapistOrder();
    }
  }, [Empvaliddata]);

  const getVendorEmployee = () => {
    axios
      .get("https://spazo.co.in/api/Vendor/getAllEmployee")
      .then(function (response) {
        console.log(response.data);
        setdata(
          response.data?.Employee?.filter(
            (item) =>
              item?.isDeleted === false &&
              item?.vendor[item.vendor?.length - 1].VendorId === user?._id
          )
        );
        setdeletedEmployee(
          response.data?.Employee?.filter(
            (item) => item?.isDeleted === true && item?.EmpId
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [Order, setOrder] = useState([]);

  const getTherapistOrder = () => {
    axios
      .get(
        "https://spazo.co.in/api/customer/getTherapistOrder/" +
          Empvaliddata?.EmpId
      )
      .then(function (response) {
        console.log(response.data);
        setOrder(response.data?.Order);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const AddEmployeeAvailabilitydetails = async (data) => {
    if (!date1) {
      notify2("Please Select the date");
    } else if (!Availability) {
      notify2("Select Available or not");
    }
    // else if (Availability === "Available" && (!From_Time || !To_Time)) {
    //   notify2("Please Select Both From and To Time");
    // }
    else if (Availability === "Not" && !Reason) {
      notify2("Please give reason for not-available");
    } else {
      try {
        const config = {
          url: "/AddEmployeeAvailabilitydetails",
          method: "post",
          baseURL: "https://spazo.co.in/api/Vendor",
          data: {
            id: data?._id,
            date1: date1,
            Availability: Availability,
            // From_Time: Employee_from_time ? Employee_from_time : "",
            // To_Time: Employee_To_time ? Employee_To_time : "",
            Reason: Reason ? Reason : "",
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            // window.location.reload();
            notify("Details added Successfully");
            setTimeout(() => {
              window.location.reload();
            }, 1000); // Adjust delay as needed
            // handleClose1();
            // getVendorEmployee();
          } else {
            notify1(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        notify1("Unable to add Details, Please try after some time");
      }
    }
  };

  const deleteEmployee = async (data) => {
    if (!reasonForDelete) {
      alert("Please enter reason for delete");
    } else {
      try {
        const config = {
          url: "/addDeletedEmployee",
          method: "post",
          baseURL: "https://spazo.co.in/api/Vendor",
          data: {
            TherapistId: data?._id,
            VendorId: user?._id,
            ReasonForDelete: reasonForDelete,
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            // alert("Data deleted successfully");
            // window.location.reload();
            notify("Delete request sent successully");
            getVendorEmployee();
            handleCloseDelete();
          } else {
            // alert(res.status.error);
            notify1(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        // alert("Unable to add Details");
        notify1("Unable to delete Details, Please try after some time");
      }
    }
  };

  const [blockdate, setblockdate] = useState("");
  const [blockFromtime, setblockFromtime] = useState("");
  const [blockendTime, setblockendTime] = useState("");
  const [newEmployee, setnewEmployee] = useState([]);
  const [Therapist, setTherapist] = useState({});

  const Employee_from_time1 = blockFromtime
    ? formatTimeTo12hr(blockFromtime)
    : "";
  const Employee_To_time1 = blockendTime ? formatTimeTo12hr(blockendTime) : "";

  useEffect(() => {
    if (blockdate && blockFromtime && blockendTime) {
      const newEmployee1 = data?.filter((person) =>
        person?.vailability?.some((hobby) => {
          return (
            moment(hobby.date1).format("DD/MM/YYYY") ==
              moment(blockdate).format("DD/MM/YYYY") &&
            hobby.From_Time === Employee_from_time1 &&
            hobby.To_Time === Employee_To_time1 &&
            hobby.Availability === "Available" &&
            hobby.BookingStatus === "Available"
          );
        })
      );
      setnewEmployee(newEmployee1);
    }
  }, [blockdate, Employee_from_time1, Employee_To_time1]);

  const Blockslot = async () => {
    if (!blockdate) {
      notify2("Please Select the Date");
    } else if (!Employee_from_time1) {
      notify2("Please Select the From time");
    } else if (!Employee_To_time1) {
      notify2("Please Select the To time");
    } else if (Object.keys(Therapist)?.length === 0) {
      notify2("Please Select the Therapist");
    } else {
      try {
        const config = {
          url: "/BlockEmployeeslot",
          method: "post",
          baseURL: "https://spazo.co.in/api/Vendor",
          data: {
            blockdate: blockdate,
            Employee_from_time1: Employee_from_time1,
            Employee_To_time1: Employee_To_time1,
            Therapist_Id: Therapist._id,
          },
        };
        await axios(config).then(function (res) {
          if ((res.status = 200)) {
            notify("Slot blocked Successfully");
            handleCloseBlock();
            getVendorEmployee();
          } else {
            notify1(res.status.error);
          }
        });
      } catch (error) {
        console.log(error);
        notify1("Unable to add Details, Please try after some time");
      }
    }
  };

  const UpdateTherapistprofile = async (Therapist, status) => {
    try {
      const config = {
        url: "/UpdateTherapistprofile",
        method: "post",
        baseURL: "https://spazo.co.in/api/Vendor",
        data: {
          Therapist_Id: Therapist,
          ViewProfileImageToCustomer: status,
        },
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          notify("Therapist Profile pic status changed Successfully");
          getVendorEmployee();
        } else {
          notify1(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      notify1("Unable to add Details, Please try after some time");
    }
  };

  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "3",
        value: 3,
      },
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "",
      text: "Sl No",
      formatter: (value, row, index) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      dataField: "profile",
      text: "Image",
      formatter: (value, row) => (
        <img
          src={"https://spazo.co.in/Employee/" + row?.profile}
          width={100}
          height={80}
          onClick={() =>
            window.open("https://spazo.co.in/Employee/" + row.profile)
          }
        />
      ),
    },
    {
      dataField: "ViewProfileImageToCustomer",
      text: "View Profile Image To Customer",
      formatter: (value, row) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {row?.ViewProfileImageToCustomer ? (
            <>
              <div style={{ gap: "10px", display: "flex" }}>
                <p>Yes</p>
                <button
                  style={{
                    backgroundColor: "#e22217",
                    color: "white",
                    border: "none",
                    padding: "3px",
                  }}
                  onClick={() => UpdateTherapistprofile(row?._id, false)}
                >
                  <AiFillEdit style={{ color: "white", fontSize: "25px" }} />
                </button>
              </div>
            </>
          ) : (
            <>
              <div style={{ gap: "10px", display: "flex" }}>
                <p>No</p>
                <button
                  style={{
                    backgroundColor: "Green",
                    color: "white",
                    border: "none",
                    padding: "3px",
                  }}
                  onClick={() => UpdateTherapistprofile(row?._id, true)}
                >
                  <AiFillEdit style={{ color: "white", fontSize: "25px" }} />
                </button>
              </div>
            </>
          )}
        </div>
      ),
    },
    {
      dataField: "EmpId",
      text: "EmpId",
      formatter: (value, row) => <p>{row?.EmpId ? row?.EmpId : "-"}</p>,
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (value, row) => {
        const rev = row?.therapistreviews?.reduce((a, b) => a + b.rating, 0);
        return (
          <>
            <p>{row?.name ? row?.name : "-"}</p>
            <div style={{ display: "flex" }}>
              <AiFillStar color="Gold" style={{ marginTop: "7px" }} /> :
              <p style={{ marginBottom: "0px", fontWeight: "bold" }}>
                {rev?.toFixed(1)}
              </p>
            </div>
            <button
              style={{ border: "none" }}
              onClick={() => handleShowrevive(row)}
            >
              <i
                class="fa fa-eye"
                aria-hidden="true"
                style={{ color: "#e22217" }}
              ></i>
            </button>
          </>
        );
      },
    },
    {
      dataField: "age",
      text: "Age",
      formatter: (value, row) => <p>{row?.age ? row?.age : "-"}</p>,
    },
    {
      dataField: "Email",
      text: "Email",
      formatter: (value, row) => <p>{row?.Email ? row?.Email : "-"}</p>,
    },
    {
      dataField: "contactnumber",
      text: "Contact Number",
      formatter: (value, row) => (
        <p>{row?.contactnumber ? row?.contactnumber : "-"}</p>
      ),
    },
    {
      dataField: "Specialization",
      text: "Specialization",
      formatter: (value, row) => (
        <p>{row?.Specialization ? row?.Specialization : "-"}</p>
      ),
    },
    {
      dataField: "Gender",
      text: "Gender",
      formatter: (value, row) => <p>{row?.Gender ? row?.Gender : "-"}</p>,
    },
    {
      dataField: "adharF",
      text: "Aadhaar Card",
      formatter: (value, row) => (
        <>
          <div style={{ gap: "10px", display: "flex" }}>
            <img
              src={"https://spazo.co.in/Employee/" + row?.adharF}
              width={100}
              height={80}
              onClick={() =>
                window.open("https://spazo.co.in/Employee/" + row.adharF)
              }
            />
            <img
              src={"https://spazo.co.in/Employee/" + row?.adharB}
              width={100}
              height={80}
              onClick={() =>
                window.open("https://spazo.co.in/Employee/" + row.adharB)
              }
            />
          </div>
        </>
      ),
    },
    {
      dataField: "PanF",
      text: "Pan Card",
      formatter: (value, row) => (
        <>
          <div style={{ gap: "10px", display: "flex" }}>
            <img
              src={"https://spazo.co.in/Employee/" + row?.PanF}
              width={100}
              height={80}
              onClick={() =>
                window.open("https://spazo.co.in/Employee/" + row.PanF)
              }
            />
            <img
              src={"https://spazo.co.in/Employee/" + row?.PanB}
              width={100}
              height={80}
              onClick={() =>
                window.open("https://spazo.co.in/Employee/" + row.PanB)
              }
            />
          </div>
        </>
      ),
    },
    {
      dataField: "",
      text: "Additional Images",
      formatter: (value, row) => (
        <>
          <div style={{ gap: "10px", display: "flex" }}>
            {row?.AdditionalImageArray?.length > 0
              ? row?.AdditionalImageArray?.map((item) => (
                  <img
                    src={
                      "https://spazo.co.in/Employee/" + item?.AdditionalImage
                    }
                    width={100}
                    height={80}
                    onClick={() =>
                      window.open(
                        "https://spazo.co.in/Employee/" + item.AdditionalImage
                      )
                    }
                  />
                ))
              : "--"}
          </div>
        </>
      ),
    },
    {
      dataField: "Status",
      text: "Status",
      formatter: (value, row) => (
        <>
          <p
            style={{
              backgroundColor:
                row?.Status === "Pending"
                  ? "#FFB902"
                  : row?.Status === "Approved"
                  ? "Green"
                  : row?.Status === "Rejected"
                  ? "rgb(226, 34, 23)"
                  : "",
              color: "white",
              marginBottom: "0px",
              borderRadius: "3px",
            }}
          >
            {row?.Status ? row?.Status : "-"}
          </p>
          {row?.Status === "Approved" ? (
            <>
              <button
                style={{
                  border: "none",
                  backgroundColor: "blue",
                  margin: "10px",
                  color: "white",
                  padding: "5px 10px",
                  borderRadius: "3px",
                }}
                onClick={() => handleShow1(row)}
              >
                Add
              </button>
              <button
                style={{ border: "none", marginLeft: "25px" }}
                onClick={() => handleShow2(row)}
              >
                <i
                  class="fa fa-eye"
                  aria-hidden="true"
                  style={{
                    color: "#e22217",
                    backgroundColor: "transparent",
                    alignSelf: "center",
                  }}
                ></i>
              </button>
            </>
          ) : (
            ""
          )}
          {row?.Status === "Rejected" ? (
            <p onClick={() => handleShow3(row)} style={{ color: "blue" }}>
              Reason
            </p>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      dataField: "Status",
      text: "Orders",
      formatter: (value, row) => (
        <>
          <button
            style={{ border: "none", marginLeft: "25px" }}
            onClick={() => handleShowOrder(row)}
          >
            <i
              class="fa fa-eye"
              aria-hidden="true"
              style={{
                color: "#e22217",
                backgroundColor: "transparent",
                alignSelf: "center",
              }}
            ></i>
          </button>
        </>
      ),
    },
    {
      dataField: "",
      text: "Duty",
      formatter: (value, row) => (
        <>
          <button
            style={{ border: "none", marginLeft: "25px" }}
            onClick={() => handleShowduty(row)}
          >
            <i
              class="fa fa-eye"
              aria-hidden="true"
              style={{
                color: "#e22217",
                backgroundColor: "transparent",
                alignSelf: "center",
              }}
            ></i>
          </button>
        </>
      ),
    },
    {
      dataField: "",
      text: "Action",
      formatter: (value, row) => (
        <>
          {row?.DeletedEmployee === "sent" ? (
            <>
              <p>Request sent for Delete </p>
            </>
          ) : (
            <>
              <button
                style={{ backgroundColor: "transparent", border: "none" }}
                onClick={() => handleShowEdit(row)}
              >
                <AiFillEdit
                  style={{ color: "rgb(226, 34, 23)", fontSize: "25px" }}
                />
              </button>
              /
              <button
                style={{ backgroundColor: "transparent", border: "none" }}
                onClick={() => handleShowDelete(row)}
              >
                <RiDeleteBin5Fill
                  style={{ color: "rgb(226, 34, 23)", fontSize: "25px" }}
                />
              </button>
            </>
          )}
        </>
      ),
    },
  ];

  console.log("data", data);
  return (
    <div className="never-sspa">
      <section>
        <div className="spa-admin-0 mb-2">
          <div className="diff-admin px-3">
            <div className="admin-name-text m-3">
              <h4>Therapist</h4>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={30000}
          closeOnClick
          pauseOnHover
          draggable
        />
        <div style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}>
          <div
            style={{ overflow: "hidden", overflowY: "auto", margin: "20px" }}
          >
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columns}
              // rowClasses={rowClasses}
              search
              exportCSV
            >
              {(props) => (
                <div>
                  <SearchBar {...props.searchProps} />
                  <ExportCSVButton
                    {...props.csvProps}
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                    }}
                  >
                    Download Excel
                  </ExportCSVButton>
                  <button
                    style={{
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "10px",
                      border: "none",
                      padding: "6px",
                      borderRadius: "5px",
                      marginLeft: "45%",
                    }}
                    onClick={handleShowSelectTypeOfEmp}
                  >
                    +Add
                  </button>

                  <Link
                    style={{
                      backgroundColor: "Green",
                      color: "white",
                      marginLeft: "10px",
                      border: "none",
                      padding: "6px",
                      borderRadius: "5px",
                      marginLeft: "10px",
                      textDecoration: "none",
                    }}
                    to="/VendorSelectTherapist"
                    // target="_blank"
                    state={{ item: user }}
                  >
                    Block Slot
                  </Link>

                  <hr></hr>
                  <br></br>
                  <div style={{ overflowX: "scroll", width: "100%" }}>
                    <BootstrapTable
                      striped
                      hover
                      condensed
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>

          <Modal show={showSelectTypeOfEmp} onHide={handleCloseSelectTypeOfEmp}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h6>Therapist</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{ fontSize: "14px", textAlign: "center" }}>
                If the therapist already registered with Spazo, please select
                Existing Therapist or select New Registration
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  margin: "30px 20px",
                }}
              >
                <p
                  style={{
                    color:
                      selecttype === "New Regitration" ? "#e22217" : "black",
                    borderBottom:
                      selecttype === "New Regitration"
                        ? "3px solid #e22217"
                        : "none",
                  }}
                  onClick={() => setselecttype("New Regitration")}
                >
                  New Registration
                </p>
                <p
                  style={{
                    color:
                      selecttype === "Existing Therapist" ? "#e22217" : "black",
                    borderBottom:
                      selecttype === "Existing Therapist"
                        ? "3px solid #e22217"
                        : "none",
                  }}
                  onClick={() => setselecttype("Existing Therapist")}
                >
                  Existing Therapist
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={selectEmployee}>
                Continue
              </Button>
              <Button variant="dark" onClick={handleCloseSelectTypeOfEmp}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h6>Therapist</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div style={{ gap: "20px" }}>
                  <label>upload Therapist Photo *</label>
                  <label htmlFor="pro" style={{ marginLeft: "9%" }}>
                    <Input
                      accept="image/*"
                      id="pro"
                      type="file"
                      onChange={(e) => setprofile(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          profile
                            ? URL.createObjectURL(profile)
                            : "https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
                          //  `https://otutee.in/${

                          //   }`
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                </div>
                <Row className="mt-3">
                  <Col md={6}>
                    <label>Name *</label>
                  </Col>
                  <Col md={6}>
                    <input
                      placeholder="Name"
                      onChange={(e) => setname(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <label>Age *</label>
                  </Col>
                  <Col md={6}>
                    <input
                      type="number"
                      placeholder="Age"
                      onChange={(e) => setage(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <label>Email ID *</label>
                  </Col>
                  <Col md={6}>
                    <input
                      type="text"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <label>Contact Number *</label>
                  </Col>
                  <Col md={6}>
                    <input
                      type="tele"
                      maxLength={10}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Contact Number"
                      onChange={(e) => setcontactnumber(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <label>Specialization *</label>
                  </Col>
                  <Col md={6}>
                    <input
                      placeholder="Specialization"
                      onChange={(e) => setSpecialization(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <label>Gender *</label>
                  </Col>
                  <Col md={6}>
                    <select
                      onChange={(e) => setGender(e.target.value)}
                      style={{ width: "84%" }}
                    >
                      <option>Select</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </Col>
                </Row>
                <div style={{ gap: "20px", marginTop: "30px" }}>
                  <label>upload Aadhaar Card *</label>
                  <div style={{ display: "flex" }}>
                    <label htmlFor="ahr" style={{ marginLeft: "9%" }}>
                      <Input
                        accept="image/*"
                        id="ahr"
                        type="file"
                        onChange={(e) => setadharF(e.target.files[0])}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <img
                          src={
                            adharF
                              ? URL.createObjectURL(adharF)
                              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            //  `https://otutee.in/${

                            //   }`
                          }
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <PhotoCamera
                          style={{
                            color: "black",
                            margin: "40px 0px 0px -20px",
                          }}
                        />
                      </IconButton>
                    </label>
                    <label htmlFor="ahr1" style={{ marginLeft: "9%" }}>
                      <Input
                        accept="image/*"
                        id="ahr1"
                        type="file"
                        onChange={(e) => setadharB(e.target.files[0])}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <img
                          src={
                            adharB
                              ? URL.createObjectURL(adharB)
                              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6vKzo-hPol60TY179iCU7ueVXfPlknWmew&usqp=CAU"
                            //  `https://otutee.in/${

                            //   }`
                          }
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <PhotoCamera
                          style={{
                            color: "black",
                            margin: "40px 0px 0px -20px",
                          }}
                        />
                      </IconButton>
                    </label>
                  </div>
                </div>
                <div style={{ gap: "20px" }}>
                  <label>upload Pan Card *</label>
                  <div style={{ display: "flex" }}>
                    <label htmlFor="pan" style={{ marginLeft: "9%" }}>
                      <Input
                        accept="image/*"
                        id="pan"
                        type="file"
                        onChange={(e) => setPanF(e.target.files[0])}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <img
                          src={
                            PanF
                              ? URL.createObjectURL(PanF)
                              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuLZEq581LWPeg56csL0uAsZmXRs6fKolb3Q&usqp=CAU"
                            //  `https://otutee.in/${

                            //   }`
                          }
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <PhotoCamera
                          style={{
                            color: "black",
                            margin: "40px 0px 0px -20px",
                          }}
                        />
                      </IconButton>
                    </label>
                    <label htmlFor="PanB" style={{ marginLeft: "9%" }}>
                      <Input
                        accept="image/*"
                        id="PanB"
                        type="file"
                        onChange={(e) => setPanB(e.target.files[0])}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <img
                          src={
                            PanB
                              ? URL.createObjectURL(PanB)
                              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuLZEq581LWPeg56csL0uAsZmXRs6fKolb3Q&usqp=CAU"
                            //  `https://otutee.in/${

                            //   }`
                          }
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <PhotoCamera
                          style={{
                            color: "black",
                            margin: "40px 0px 0px -20px",
                          }}
                        />
                      </IconButton>
                    </label>
                  </div>
                </div>
                <div style={{ gap: "20px" }}>
                  <label>Upload additional image (if any)</label>
                  <br></br>
                  {AdditionalImageArray?.map((item) => (
                    <>
                      <img
                        src={URL.createObjectURL(item.AdditionalImage)}
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                    </>
                  ))}
                  <br></br>
                  <label htmlFor="icon-button-file1">
                    <Input
                      accept="image/*"
                      id="icon-button-file1"
                      type="file"
                      onChange={(e) => setAdditionalImage(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          AdditionalImage
                            ? URL.createObjectURL(AdditionalImage)
                            : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQueVhIdH7EKL6sLlHuCK4etHw1nctOIza_Zg&usqp=CAU"
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                  <br></br>
                  <button
                    style={{
                      border: "none",
                      padding: "5px 18px",
                      backgroundColor: "#e22217",
                      color: "white",
                      marginLeft: "15px",
                      borderRadius: "5%",
                      textAlign: "center",
                    }}
                    onClick={addAdditionalImage}
                  >
                    Add
                  </button>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={addEmployee}>
                Add
              </Button>
              <Button variant="dark" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showExistingEmpReg}
            onHide={handleCloseshowExistingEmpReg}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h6>Therapist</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={top100Films}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Employee ID" />
                  )}
                  onChange={(e, newValue) => setselectedEmpID(newValue.label)}
                />
                {Object.keys(selectedEmpDetails)?.length > 0 ? (
                  <>
                    <div style={{ gap: "20px", marginTop: "20px" }}>
                      <label>Therapist Photo</label>
                      <label htmlFor="pro" style={{ marginLeft: "9%" }}>
                        <img
                          src={
                            "https://spazo.co.in/Employee/" +
                            selectedEmpDetails?.profile
                          }
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                          onClick={() =>
                            window.open(
                              "https://spazo.co.in/Employee/" +
                                selectedEmpDetails?.profile
                            )
                          }
                        />
                      </label>
                    </div>
                    <Row className="mt-3">
                      <Col md={6}>
                        <label>Name</label>
                      </Col>
                      <Col md={6}>
                        <input
                          placeholder="Name"
                          value={selectedEmpDetails?.name}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={6}>
                        <label>Age</label>
                      </Col>
                      <Col md={6}>
                        <input
                          type="number"
                          placeholder="Age"
                          value={selectedEmpDetails?.age}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={6}>
                        <label>Email ID</label>
                      </Col>
                      <Col md={6}>
                        <input
                          type="text"
                          placeholder="Email"
                          value={selectedEmpDetails?.Email}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={6}>
                        <label>Contact Number</label>
                      </Col>
                      <Col md={6}>
                        <input
                          type="tele"
                          maxLength={10}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          placeholder="Contact Number"
                          value={selectedEmpDetails?.contactnumber}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={6}>
                        <label>Specialization</label>
                      </Col>
                      <Col md={6}>
                        <input
                          placeholder="Specialization"
                          value={selectedEmpDetails?.Specialization}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={6}>
                        <label>Gender *</label>
                      </Col>
                      <Col md={6}>
                        <input
                          placeholder="Specialization"
                          value={selectedEmpDetails?.Gender}
                        />
                      </Col>
                    </Row>
                    <div style={{ gap: "20px", marginTop: "30px" }}>
                      <label>Aadhaar Card</label>
                      <div style={{ display: "flex" }}>
                        <label htmlFor="ahr" style={{ marginLeft: "9%" }}>
                          <img
                            src={
                              "https://spazo.co.in/Employee/" +
                              selectedEmpDetails?.adharF
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                            onClick={() =>
                              window.open(
                                "https://spazo.co.in/Employee/" +
                                  selectedEmpDetails?.adharF
                              )
                            }
                          />
                        </label>
                        <label htmlFor="ahr1" style={{ marginLeft: "9%" }}>
                          <img
                            src={
                              "https://spazo.co.in/Employee/" +
                              selectedEmpDetails?.adharB
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                            onClick={() =>
                              window.open(
                                "https://spazo.co.in/Employee/" +
                                  selectedEmpDetails?.adharB
                              )
                            }
                          />
                        </label>
                      </div>
                    </div>
                    <div style={{ gap: "20px" }}>
                      <label>Pan Card</label>
                      <div style={{ display: "flex" }}>
                        <label htmlFor="pan" style={{ marginLeft: "9%" }}>
                          <img
                            src={
                              "https://spazo.co.in/Employee/" +
                              selectedEmpDetails?.PanF
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                            onClick={() =>
                              window.open(
                                "https://spazo.co.in/Employee/" +
                                  selectedEmpDetails?.PanF
                              )
                            }
                          />
                        </label>
                        <label htmlFor="PanB" style={{ marginLeft: "9%" }}>
                          <img
                            src={
                              "https://spazo.co.in/Employee/" +
                              selectedEmpDetails?.PanB
                            }
                            alt="avathar"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50px",
                            }}
                            onClick={() =>
                              window.open(
                                "https://spazo.co.in/Employee/" +
                                  selectedEmpDetails?.PanB
                              )
                            }
                          />
                        </label>
                      </div>
                    </div>
                    {selectedEmpDetails?.AdditionalImageArray?.length > 0 ? (
                      <div style={{ gap: "20px" }}>
                        <label>Additional Images</label>
                        <div style={{ display: "flex" }}>
                          {selectedEmpDetails?.AdditionalImageArray?.map(
                            (item) => (
                              <img
                                src={
                                  "https://spazo.co.in/Employee/" +
                                  item?.AdditionalImage
                                }
                                alt="avathar"
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  borderRadius: "50px",
                                }}
                                onClick={() =>
                                  window.open(
                                    "https://spazo.co.in/Employee/" +
                                      item?.AdditionalImage
                                  )
                                }
                              />
                            )
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => addExistingEmp(selectedEmpDetails)}
              >
                Add
              </Button>
              <Button variant="dark" onClick={handleCloseshowExistingEmpReg}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton style={{ color: "#e22217" }}>
              Add Therapist Availability
            </Modal.Header>
            <Modal.Body style={{ alignItems: "center", display: "contents" }}>
              <div style={{ margin: "20px" }}>
                <Row>
                  <Col md={6}>Date</Col>
                  <Col md={6}>
                    <input
                      type="date"
                      value={format(selectedDate, "yyyy-MM-dd")}
                      onChange={handleDateChange}
                      style={{ width: "85%" }}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>Availability</Col>
                  <Col md={6}>
                    <select
                      style={{ width: "85%" }}
                      onChange={(e) => setAvailability(e.target.value)}
                    >
                      <option>Select</option>
                      <option value="Available">Available</option>
                      <option value="Not">Not</option>
                    </select>
                  </Col>
                </Row>
                {/* {Availability === "Available" ? (
                  <>
                    <Row className="mt-3">
                      <Col md={6}>From Time</Col>
                      <Col md={6}>
                        <input
                          type="time"
                          style={{ width: "85%" }}
                          onChange={(e) => setFrom_Time(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={6}>To Time</Col>
                      <Col md={6}>
                        <input
                          type="time"
                          style={{ width: "85%" }}
                          onChange={(e) => setTo_Time(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )} */}

                {Availability === "Not" ? (
                  <Row className="mt-3">
                    <Col md={6}>Reason</Col>
                    <Col md={6}>
                      <textarea
                        type="text"
                        style={{ width: "85%" }}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => AddEmployeeAvailabilitydetails(Empdata)}
              >
                Add
              </Button>
              <Button variant="dark" onClick={handleClose1}>
                cancel
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={show2} onHide={handleClose2} className="servicemodal1">
            <Modal.Header
              closeButton
              style={{ color: "#e22217", fontSize: "20px" }}
            >
              Therapist Details
            </Modal.Header>
            <Modal.Body style={{ alignItems: "center", display: "contents" }}>
              <div style={{ margin: "20px" }}>
                <Row>
                  <Col md={6}>
                    <span style={{ fontWeight: "bold" }}>Name :</span>{" "}
                    {Empvaliddata?.name}
                  </Col>
                  <Col md={6}>
                    {" "}
                    <span style={{ fontWeight: "bold" }}>
                      Employee Id :
                    </span>{" "}
                    {Empvaliddata?.EmpId}
                  </Col>
                </Row>

                <Row>
                  <table style={{ margin: "5px" }}>
                    <thead
                      style={{ backgroundColor: "#e22217", color: "white" }}
                    >
                      <th className="tablexxxx">date1</th>
                      <th className="tablexxxx">Working-Status</th>
                      {/* <th className="tablexxxx">From-Time</th>
                      <th className="tablexxxx">To-Time</th> */}
                      {/* <th className="tablexxxx">Availability-Status</th> */}
                      <th className="tablexxxx">Reason</th>
                    </thead>
                    <tbody>
                      {Empvaliddata?.vailability?.map((item) => (
                        <>
                          <tr
                            style={{
                              color:
                                item?.Availability === "Not"
                                  ? "#e22217"
                                  : "Green",
                            }}
                          >
                            <td className="tablexxxx">
                              {moment(item?.date1).format("DD/MM/YYYY")}
                            </td>
                            <td className="tablexxxx">
                              {item?.Availability === "Not" ? (
                                <>
                                  &nbsp; <i class="fas fa-times"></i>
                                </>
                              ) : (
                                <>
                                  &nbsp; <i class="fas fa-check"></i>
                                </>
                              )}
                            </td>
                            {/* <td className="tablexxxx">
                              {item?.From_Time ? item?.From_Time : "-"}
                            </td>
                            <td className="tablexxxx">
                              {item?.To_Time ? item?.To_Time : "-"}
                            </td> */}
                            {/* <td
                              className="tablexxxx"
                              style={{
                                color:
                                  item?.Availability === "Available"
                                    ? "Green"
                                    : "rgb(226, 34, 23)",
                              }}
                            >
                              {item?.Availability
                                ? item?.Availability
                                : "Not-Available"}
                            </td> */}
                            <td className="tablexxxx">
                              {item?.Reason ? item?.Reason : "-"}
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose2}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showOrder}
            onHide={handleCloseOrder}
            className="servicemodal1"
          >
            <Modal.Header
              closeButton
              style={{ color: "#e22217", fontSize: "20px" }}
            >
              Therapist Order Details
            </Modal.Header>
            <Modal.Body style={{ alignItems: "center", display: "contents" }}>
              <div style={{ margin: "20px" }}>
                <Row>
                  <Col md={6}>
                    <span style={{ fontWeight: "bold" }}>Name :</span>{" "}
                    {Empvaliddata?.name}
                  </Col>
                  <Col md={6}>
                    <span style={{ fontWeight: "bold" }}>Employee Id :</span>
                    {Empvaliddata?.EmpId}
                  </Col>
                </Row>

                <Row>
                  <table style={{ margin: "5px" }}>
                    <thead
                      style={{ backgroundColor: "#e22217", color: "white" }}
                    >
                      <th className="tablexxxx">Booking Id</th>
                      <th className="tablexxxx">Booking Date</th>
                      <th className="tablexxxx">Category</th>
                      <th className="tablexxxx">Service Name</th>
                      <th className="tablexxxx">Slot Date</th>
                      <th className="tablexxxx">Slot From Time</th>
                      <th className="tablexxxx">Slot End Time</th>
                      <th className="tablexxxx">Blocked Reason</th>
                    </thead>
                    <tbody>
                      {Order?.map((item) => (
                        <>
                          <tr
                            style={{
                              color: item?.ReasonForBlock ? "#e22217" : "Green",
                            }}
                          >
                            <td className="tablexxxx">{item?._id}</td>
                            {/* <td className="tablexxxx">
                              {item?.Availability === "Not" ? (
                                <>
                                  &nbsp; <i class="fas fa-times"></i>
                                </>
                              ) : (
                                <>
                                  &nbsp; <i class="fas fa-check"></i>
                                </>
                              )}
                            </td> */}
                            <td className="tablexxxx">
                              {moment(item?.createdAt).format("DD/MM/YYYY")}
                            </td>
                            <td className="tablexxxx">
                              {item?.Service_Category
                                ? item?.Service_Category
                                : "-"}
                            </td>
                            <td className="tablexxxx">
                              {item?.ServiceName ? item?.ServiceName : "-"}
                            </td>
                            <td className="tablexxxx">
                              {item?.date ? item?.date : "-"}
                            </td>
                            <td className="tablexxxx">
                              {item?.fromTime ? item?.fromTime : "-"}
                            </td>
                            <td className="tablexxxx">
                              {item?.toTime ? item?.toTime : "-"}
                            </td>
                            <td className="tablexxxx">
                              {item?.ReasonForBlock
                                ? item?.ReasonForBlock
                                : "-"}
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseOrder}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton style={{ color: "#e22217" }}>
              Reason For Rejection
            </Modal.Header>
            <Modal.Body style={{ alignItems: "center", display: "contents" }}>
              <p style={{ textAlign: "center" }}>{viewReason?.reasonFR}</p>
              <Button variant="secondary" onClick={handleClose3}>
                Ok
              </Button>
            </Modal.Body>
          </Modal>

          <Modal show={showEdit} onHide={handleCloseEdit}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h6>Edit Therapist Details</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div style={{ gap: "20px" }}>
                  <label>Change Therapist Photo</label>
                  <label htmlFor="pro" style={{ marginLeft: "9%" }}>
                    <Input
                      accept="image/*"
                      id="pro"
                      type="file"
                      onChange={(e) => setprofile1(e.target.files[0])}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          profile1
                            ? URL.createObjectURL(profile1)
                            : "https://spazo.co.in/Employee/" + Edit?.profile
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                </div>
                <Row className="mt-3">
                  <Col md={6}>
                    <label>Name</label>
                  </Col>
                  <Col md={6}>
                    <input
                      placeholder={Edit?.name}
                      onChange={(e) => setname1(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <label>Age</label>
                  </Col>
                  <Col md={6}>
                    <input
                      type="number"
                      placeholder={Edit?.age}
                      onChange={(e) => setage1(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <label>Email</label>
                  </Col>
                  <Col md={6}>
                    <input
                      type="text"
                      placeholder={Edit?.Email}
                      onChange={(e) => setEmail1(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <label>Contact Number</label>
                  </Col>
                  <Col md={6}>
                    <input
                      type="number"
                      placeholder={Edit?.contactnumber}
                      onChange={(e) => setcontactnumber1(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <label>Specialization</label>
                  </Col>
                  <Col md={6}>
                    <input
                      placeholder={Edit?.Specialization}
                      onChange={(e) => setSpecialization1(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <label>Gender</label>
                  </Col>
                  <Col md={6}>
                    <select
                      onChange={(e) => setGender1(e.target.value)}
                      style={{ width: "84%" }}
                    >
                      <option>{Edit?.Gender}</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </Col>
                </Row>
                <Row>
                  <label> Aadhaar Card</label>
                  <div style={{ display: "flex" }}>
                    <label htmlFor="adharF1">
                      <Input
                        accept="image/*"
                        id="adharF1"
                        type="file"
                        onChange={(e) => setadharF1(e.target.files[0])}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <img
                          src={
                            adharF1
                              ? URL.createObjectURL(adharF1)
                              : "https://spazo.co.in/Employee/" + Edit?.adharF
                          }
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <PhotoCamera
                          style={{
                            color: "black",
                            margin: "40px 0px 0px -20px",
                          }}
                        />
                      </IconButton>
                    </label>
                    <label htmlFor="adharB1">
                      <Input
                        accept="image/*"
                        id="adharB1"
                        type="file"
                        onChange={(e) => setadharB1(e.target.files[0])}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <img
                          src={
                            adharB1
                              ? URL.createObjectURL(adharB1)
                              : "https://spazo.co.in/Employee/" + Edit?.adharB
                          }
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <PhotoCamera
                          style={{
                            color: "black",
                            margin: "40px 0px 0px -20px",
                          }}
                        />
                      </IconButton>
                    </label>
                  </div>
                </Row>
                <Row>
                  <label>Pan Card</label>
                  <div style={{ display: "flex" }}>
                    <label htmlFor="PanF1">
                      <Input
                        accept="image/*"
                        id="PanF1"
                        type="file"
                        onChange={(e) => setPanF1(e.target.files[0])}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <img
                          src={
                            PanF1
                              ? URL.createObjectURL(PanF1)
                              : "https://spazo.co.in/Employee/" + Edit?.PanF
                          }
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <PhotoCamera
                          style={{
                            color: "black",
                            margin: "40px 0px 0px -20px",
                          }}
                        />
                      </IconButton>
                    </label>
                    <label htmlFor="PanB1">
                      <Input
                        accept="image/*"
                        id="PanB1"
                        type="file"
                        onChange={(e) => setPanB1(e.target.files[0])}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <img
                          src={
                            PanB1
                              ? URL.createObjectURL(PanB1)
                              : "https://spazo.co.in/Employee/" + Edit?.PanB
                          }
                          alt="avathar"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                        />
                        <PhotoCamera
                          style={{
                            color: "black",
                            margin: "40px 0px 0px -20px",
                          }}
                        />
                      </IconButton>
                    </label>
                  </div>
                </Row>

                <Row className="mt-3">
                  <label>
                    Additional Images(you can select multiple images)
                  </label>
                  {Edit?.AdditionalImageArray?.map((item) => (
                    <Col md={3}>
                      <img
                        src={
                          "https://spazo.co.in/Employee/" +
                          item?.AdditionalImage
                        }
                        width={90}
                        height={90}
                        style={{ borderRadius: "50%" }}
                      />

                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                          position: "absolute",
                          marginLeft: "-20px",
                        }}
                        onClick={() => deleteEmpImage(Edit, item)}
                      >
                        <i
                          class="fas fa-times-circle"
                          size={30}
                          style={{ color: "#e22217" }}
                        ></i>
                      </button>
                    </Col>
                  ))}
                  {AdditionalImageArray1?.map((item) => (
                    <>
                      <img
                        src={URL.createObjectURL(item.AdditionalImage)}
                        alt="avathar"
                        style={{
                          width: "110px",
                          height: "90px",
                          borderRadius: "50px",
                        }}
                      />
                    </>
                  ))}

                  <label htmlFor="setService_Image">
                    <Input
                      accept="image/*"
                      id="setService_Image"
                      type="file"
                      multiple
                      onChange={(e) => setAdditionalImage1(e.target.files)}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={
                          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQueVhIdH7EKL6sLlHuCK4etHw1nctOIza_Zg&usqp=CAU"
                        }
                        alt="avathar"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50px",
                        }}
                      />
                      <PhotoCamera
                        style={{
                          color: "black",
                          margin: "40px 0px 0px -20px",
                        }}
                      />
                    </IconButton>
                  </label>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => EditEmployee(Edit)}>
                Update
              </Button>
              <Button variant="dark" onClick={handleCloseEdit}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showDelete} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h6>Are you Sure, you want to Delete Employee Details</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ display: "grid" }}>
                <p style={{ textAlign: "center" }}>
                  If yes, Please enter reason for Delete
                </p>
                <input
                  onChange={(e) => setreasonForDelete(e.target.value)}
                  style={{
                    alignSelf: "center",
                    margin: "20px",
                    width: "80%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
                <div
                  style={{
                    gap: "20px",
                    display: "flex",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Button
                    variant="secondary"
                    onClick={() => deleteEmployee(Delete)}
                  >
                    Delete
                  </Button>
                  <Button variant="dark" onClick={handleCloseDelete}>
                    Cancel
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={showBlock} onHide={handleCloseBlock}>
            <Modal.Header closeButton>
              <Modal.Title>Block slot for perticular Therapist</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <Row>
                  <Col md={6}>Date</Col>
                  <Col md={6}>
                    <input
                      type="date"
                      value={blockdate}
                      onChange={(e) => setblockdate(e.target.value)}
                      style={{ width: "85%" }}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>From Time</Col>
                  <Col md={6}>
                    <input
                      type="time"
                      style={{ width: "85%" }}
                      onChange={(e) => setblockFromtime(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>To Time</Col>
                  <Col md={6}>
                    <input
                      type="time"
                      style={{ width: "85%" }}
                      onChange={(e) => setblockendTime(e.target.value)}
                    />
                  </Col>
                </Row>
                {newEmployee?.length > 0 ? (
                  <div
                    style={{
                      border: "1px solid gray",
                      borderRadius: "0px 0px 10px 10px",
                      justifyContent: "center",
                      padding: "1%",
                      overflowY: "scroll",
                      height: "350px",
                      marginTop: "10px",
                      // overflowX: "hidden",
                    }}
                  >
                    {newEmployee?.map((item) => (
                      <div
                        style={{
                          borderBottom: "1px solid gray",
                          justifyContent: "center",
                          padding: "1%",
                          backgroundColor:
                            Therapist._id === item._id ? "gray" : "white",
                          color: Therapist._id === item._id ? "white" : "black",
                        }}
                        onClick={() => {
                          setTherapist(item);
                          // handleClose();
                        }}
                      >
                        <Row>
                          <Col md={2}>
                            <div
                            // style={{
                            //   justifyContent: "center",
                            //   display: "flex",
                            //   alignItems: "center",
                            //   border: "1px solid gray",
                            //   padding: "15px 12px",
                            //   borderRadius: "50%",
                            // }}
                            >
                              <img
                                src={
                                  "https://spazo.co.in/Employee/" +
                                  item?.profile
                                }
                                width={50}
                                height={50}
                                onClick={() =>
                                  window.open(
                                    "https://spazo.co.in/Employee/" +
                                      item.profile
                                  )
                                }
                                style={{ borderRadius: "50%" }}
                              />
                            </div>
                          </Col>
                          <Col md={8}>
                            <div>
                              <p
                                style={{
                                  marginBottom: "0px",
                                  fontWeight: "bold",
                                  fontSize: "20px",
                                }}
                              >
                                {item?.name}
                              </p>
                              <p>{item?.Specialization}</p>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div
                              style={{
                                justifyContent: "center",
                                display: "flex",
                                alignItems: "center",
                                padding: "15px 12px",
                              }}
                            >
                              <i class="fas fa-chevron-right"></i>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={Blockslot}>
                Block
              </Button>
              <Button onClick={handleCloseBlock}>Cancel</Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showrevive} onHide={handleCloserevive}>
            <Modal.Header closeButton>
              <Modal.Title> Therapist Reviews - {reviwe?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                {reviwe?.therapistreviews?.map((rev) => (
                  <>
                    <div
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <h6>{rev?.Username}</h6>
                      <p style={{ marginBottom: "0px" }}>{rev?.review}</p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <ReactStars
                          count={5}
                          value={rev?.rating}
                          size={20}
                          activeColor="#ffd700"
                        />
                        <p
                          style={{
                            textAlign: "right",
                            color: "#e84141",
                            fontWeight: 600,
                          }}
                        >
                          {moment(rev?.createdAt).format("DD-MM-YYYY")}
                        </p>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleCloserevive}>Ok</Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showduty}
            onHide={handleCloseduty}
            className="servicemodal1"
          >
            <Modal.Header
              closeButton
              style={{ color: "#e22217", fontSize: "20px" }}
            >
              Therapist Duty Details
            </Modal.Header>
            <Modal.Body style={{ alignItems: "center", display: "contents" }}>
              <div style={{ margin: "20px" }}>
                <Row>
                  <Col md={6}>
                    <span style={{ fontWeight: "bold" }}>Name :</span>{" "}
                    {dutydata?.name}
                  </Col>
                  <Col md={6}>
                    {" "}
                    <span style={{ fontWeight: "bold" }}>
                      Employee Id :
                    </span>{" "}
                    {dutydata?.EmpId}
                  </Col>
                </Row>

                <Row>
                  <table style={{ margin: "5px" }}>
                    <thead
                      style={{ backgroundColor: "#e22217", color: "white" }}
                    >
                      <th className="tablexxxx">Date / Time</th>
                      <th className="tablexxxx">Working Status</th>
                    </thead>
                    <tbody>
                      {dutydata?.Duties?.map((item) => (
                        <>
                          <tr>
                            <td className="tablexxxx">{item?.workingDate}</td>
                            <td className="tablexxxx">
                              {item?.Duty === true ? "On" : "Off"}
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseduty}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </section>
    </div>
  );
}

export default VendorEmployee;
